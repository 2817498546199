import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import DailyTableRow from './DailyTableRow'

const DailyTableBody = ({
    register, getValues, setValue, watch, reset, errors,
    defaultUrl, dailyWorkInfoList,workDivisions,handleOpenPunchHistoryFunc
  }) => {

  return (
    <tbody>
      {dailyWorkInfoList && dailyWorkInfoList.map((workInfo) => {
        return (
          <DailyTableRow key={"top_" + workInfo.orderId}
            register={register}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            reset={reset}
            errors={errors}
            workDivisions={workDivisions}
            defaultUrl={defaultUrl}
            workInfo={workInfo}
            handleOpenPunchHistoryFunc={handleOpenPunchHistoryFunc}
          >
          </DailyTableRow>
        )
      })}
    </tbody>
  )
}

DailyTableBody.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,
  workDivisions: PropTypes.array,
  defaultUrl: PropTypes.string,
  dailyWorkInfoList: PropTypes.array,
  handleOpenPunchHistoryFunc:PropTypes.func,
}

export default DailyTableBody
