import React from 'react'
import { FaSpinner } from 'react-icons/fa'
import '../../css/LoadSpinner.scss'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'

const customStyles = {
  overlay: {
    zIndex: 9999
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

/**
 * 処理待ちダイアログ
 */
Modal.setAppElement('#root')
const LoadSpinner = (props) => {
  const loadSpinner = useSelector(state => state.loadSpinner)
  return (
    <div>
      <Modal
        isOpen={loadSpinner.modalIsShow}
        style={customStyles}
      >
        <FaSpinner className='icon-spin' />
      </Modal>
    </div>
  )
}

export default LoadSpinner
