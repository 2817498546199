import { RestaurantMenuOutlined } from "@material-ui/icons";

/**
 * 指定された基準日時から 指定された日時の差を48時間形式の文字列で返す
 * 変換に失敗した場合はブランクを返す
 * @param {string} baseDt 基準日時
 * @param {string} dt 日時
 * @return {string} 48時間形式(HH:mm)の文字列
 */
export function diff48Time(baseDt, dt) {
  if (!baseDt || !dt)
    return ''

  //console.log(baseDt)
  //console.log(dt)
  let diff = new Date(dt).getTime() - new Date(baseDt).getTime()

  //HH部分取得
  let diffHour = diff / (1000 * 60 * 60);

  //MM部分取得
  //let diffMinute = (diffHour - Math.floor(diffHour)) * 60;
  let diffMinute = ((diff / 1000) - Math.floor(diffHour)*60*60)/60;
  //SS部分取得
  //let diffSecond = (diffMinute - Math.floor(diffMinute)) * 60;

  let time = ('00' + Math.floor(diffHour)).slice(-2) + ':' + ('00' + Math.floor(diffMinute)).slice(-2)

  return time
}

/**
 * 指定された48時間の文字列を48時間フォーマットの文字列にする
 * フォーマットに失敗した場合はブランクを返す
 * @param {string} time 48時間の文字列(HHmm or HH:mm)
 * @return {string} 48時間形式(HH:mm)の文字列
 */
export function format48Time(time) {
    if(!time)
        return ''

        time = time.replace(/:/g, '')
        time = ('0000' + time).slice(-4)
    let hour = time.slice(0, 2)
    let minute = time.slice(-2)

    //48時まで表記できるように変更
    // if (hour < '00' || hour > '39')
    //     return ''
    if (hour < '00' || hour > '35')
        return ''
    if (minute < '00' || minute > '59')
        return ''

    return hour + ':' + minute
}

/**
 * 指定された48時間の文字列を端数単位で丸めた48時間フォーマットの文字列にする
 * フォーマットに失敗した場合はブランクを返す
 * @param {string} time 48時間の文字列(HHmm or HH:mm)
 * @param {integer} roundMinute 端数の時間(正：切り上げ、負：切り捨て)
 * @return {string} 丸めた結果の48時間形式(HH:mm)の文字列
 */
 export function formatRound48Time(time,roundMinute) {
  if(!time)
      return ''

  time = time.replace(/:/g, '')
  time = ('0000' + time).slice(-4)
  
  let hour = time.slice(0, 2)
  let minute = time.slice(-2)

  let addMinute = 0;
  if (roundMinute > 0)
  {
    let diff = parseInt(minute) % roundMinute;
    if (diff != 0) {
        addMinute = parseInt(roundMinute) - parseInt(diff);
    }
  } else if (roundMinute < 0)
  {
      let tmpMinute = roundMinute*(-1);
      addMinute = (parseInt(minute) % parseInt(tmpMinute)) * (-1);
  }

  minute = parseInt(minute) + parseInt(addMinute)
  if(minute>='60')
  {
    hour = parseInt(hour) + Math.floor(minute/60)
    minute = parseInt(minute)%parseInt(60)
  }
  hour = ('00' + hour).slice(-2)
  minute = ('00' + minute).slice(-2)

  //48時まで表記できるように変更
  // if (hour < '00' || hour > '39')
  //     return ''
  if (hour < '00' || hour > '35')
      return ''
  if (minute < '00' || minute > '59')
      return ''

  return hour + ':' + minute
}

/**
 * 指定された基準日時から 指定された日時の差を端数単位で丸めた48時間フォーマットの文字列にする
 * 変換に失敗した場合はブランクを返す
 * @param {string} baseDt 基準日時
 * @param {string} dt 日時
 * @return {string} 丸めた結果の48時間形式(HH:mm)の文字列
 */
 export function diffFormatRound48Time(baseDt, dt,roundMinute) {
  if (!baseDt || !dt)
    return ''

  return formatRound48Time(diff48Time(baseDt,dt),roundMinute)
}

/**
 * 指定された秒数を工数のフォーマットの文字列にする
 * フォーマットに失敗した場合はブランクを返す
 * @param {double} seconds 秒
 * @return {string} 工数(例08.75)
 */
export function formatHour(seconds) {
  if (!seconds)
    return '0.00'

  return (seconds / 3600).toFixed(2);
}

/**
 * 1受注の勤務情報から入力フォームの項目で用いるIDを生成する
 * 生成に失敗した場合はブランクを返す
 * @param {object} workInfo 勤務情報
 * @return {string} ID
 */
export function makeElementId(workInfo) {
  if (workInfo) {
    return workInfo.staffId + '_' + workInfo.orderId + '_' + workInfo.baseDt
  }

  return ''
}

/**
 * 1日の勤務情報から入力フォームの項目で用いるIDを生成する
 * 生成に失敗した場合はブランクを返す
 * @param {object} dailyWorkInfo 1日の勤務情報
 * @return {string} ID
 */
export function makeElementIdByDailyWorkInfo(dailyWorkInfo) {
  if (dailyWorkInfo) {
    return dailyWorkInfo.staffId + '_' + dailyWorkInfo.baseDt + '_' + dailyWorkInfo.orderId
  }

  return ''
}

/**
 * 勤務時間(HH:mm)から工数を算出する
 * 算出に失敗した場合はブランクの配列を返す
 * @param {Array.<string>} arrayWorkTime [出勤時間, 退勤時間]
 * @param {Array.<string>} arrayRestTime [休憩開始1, 休憩終了1, 休憩開始2, 休憩終了2, 休憩開始3, 休憩終了3]
 * @return {Array.<string>} 配列[時間内工数, 休憩工数, 時間外工数, 深夜工数]
 */
export function calcWorkingHours(arrayWorkTime, arrayRestTime, attendanceBaseTime) {
  let workingHours = ['0.00', '0.00', '0.00', '0.00']
  if (!arrayWorkTime || arrayWorkTime.length < 2)
    return workingHours;
  if (!arrayRestTime || arrayRestTime.length < 6)
    return workingHours;

  let workStart = -1.0
  let workEnd = -1.0
  let restStart1 = -1.0
  let restEnd1 = -1.0
  let restStart2 = -1.0
  let restEnd2 = -1.0
  let restStart3 = -1.0
  let restEnd3 = -1.0
  if (arrayWorkTime[0]) {
    workStart = timeToSec(arrayWorkTime[0])
  }
  if (arrayWorkTime[1]) {
    workEnd = timeToSec(arrayWorkTime[1])
  }
  if (arrayRestTime[0]) {
    restStart1 = timeToSec(arrayRestTime[0])
  }
  if (arrayRestTime[1]) {
    restEnd1 = timeToSec(arrayRestTime[1])
  }
  if (arrayRestTime[2]) {
    restStart2 = timeToSec(arrayRestTime[2])
  }
  if (arrayRestTime[3]) {
    restEnd2 = timeToSec(arrayRestTime[3])
  }
  if (arrayRestTime[4]) {
    restStart3 = timeToSec(arrayRestTime[4])
  }
  if (arrayRestTime[5]) {
    restEnd3 = timeToSec(arrayRestTime[5])
  }

  let workSec = -1.0
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    workSec = workEnd - workStart
  }
  /* 深夜工数算出 */
  let nightStart = timeToSec('22:00')
  let nightEnd = timeToSec('29:00')
  let nightSec = 0.0
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    if(nightStart<workEnd && nightEnd>=workStart){
      if(nightStart < workStart){
        nightStart = workStart
      }
      if(nightEnd > workEnd){
        nightEnd = workEnd
      }
      nightSec += nightEnd -nightStart
    }
  }
  nightStart = timeToSec('00:00')
  nightEnd =  timeToSec('05:00')
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    if(nightStart<workEnd && nightEnd>=workStart){
      if(nightStart < workStart){
        nightStart = workStart
      }
      if(nightEnd > workEnd){
        nightEnd = workEnd
      }
      nightSec += nightEnd -nightStart
    }
  }
  /* */
  /* 深夜休憩工数算出 */
  let nightRestSec = 0

  let restSec = -1.0
  if (restStart1 !== -1 && restEnd1 !== -1 && restStart1 < restEnd1)
  {
    restSec = restEnd1 - restStart1

    /* 深夜休憩工数算出 */
    let nightStart = timeToSec('22:00')
    let nightEnd = timeToSec('29:00')
    if(nightStart<restEnd1 && nightEnd>=restStart1){
      if(nightStart < restStart1){
        nightStart = restStart1
      }
      if(nightEnd > restEnd1){
        nightEnd = restEnd1
      }
      nightRestSec += nightEnd -nightStart
    }
    nightStart = timeToSec('00:00')
    nightEnd =  timeToSec('05:00')
    if(nightStart<restEnd1 && nightEnd>=restStart1){
      if(nightStart < restStart1){
        nightStart = restStart1
      }
      if(nightEnd > restEnd1){
        nightEnd = restEnd1
      }
      nightRestSec += nightEnd -nightStart
    }
  }
  if (restStart2 !== -1 && restEnd2 !== -1 && restStart2 < restEnd2)
  {
    restSec += restEnd2 - restStart2

    /* 深夜休憩工数算出 */
    let nightStart = timeToSec('22:00')
    let nightEnd = timeToSec('29:00')
    if(nightStart<restEnd2 && nightEnd>=restStart2){
      if(nightStart < restStart2){
        nightStart = restStart2
      }
      if(nightEnd > restEnd2){
        nightEnd = restEnd2
      }
      nightRestSec += nightEnd -nightStart
    }
    nightStart = timeToSec('00:00')
    nightEnd =  timeToSec('05:00')
    if(nightStart<restEnd2 && nightEnd>=restStart2){
      if(nightStart < restStart2){
        nightStart = restStart2
      }
      if(nightEnd > restEnd2){
        nightEnd = restEnd2
      }
      nightRestSec += nightEnd -nightStart
    }

  }
  if (restStart3 !== -1 && restEnd3 !== -1 && restStart3 < restEnd3)
  {
    restSec += restEnd3 - restStart3

    /* 深夜休憩工数算出 */
    let nightStart = timeToSec('22:00')
    let nightEnd = timeToSec('29:00')
    if(nightStart<restEnd3 && nightEnd>=restStart3){
      if(nightStart < restStart3){
        nightStart = restStart3
      }
      if(nightEnd > restEnd3){
        nightEnd = restEnd3
      }
      nightRestSec += nightEnd -nightStart
    }
    nightStart = timeToSec('00:00')
    nightEnd =  timeToSec('05:00')
    if(nightStart<restEnd3 && nightEnd>=restStart3){
      if(nightStart < restStart3){
        nightStart = restStart3
      }
      if(nightEnd > restEnd3){
        nightEnd = restEnd3
      }
      nightRestSec += nightEnd -nightStart
    }
  }

  if (workSec !== -1.0 && restSec !== -1.0 && workSec > restSec) {
    workSec -= restSec
  }
  if (workSec !== -1.0) {
      workingHours[0] = formatHour(workSec)
  }
  if (restSec !== -1.0) {
      workingHours[1] = formatHour(restSec)
  }
  if (workSec !== -1.0 && workSec > attendanceBaseTime) {
    workingHours[2] = formatHour(workSec - attendanceBaseTime)
  }
  
  /* 深夜工数算出 */
  if (nightSec !== -1.0 && nightRestSec !== -1.0 && nightSec > nightRestSec) {
    nightSec -= nightRestSec
  }
  if (nightSec !== 0.0) {
    workingHours[3] =  formatHour(nightSec)
  }
  /* */

  return workingHours
}

/**
 * 勤務時間(HH:mm)から勤務時間・休憩時間を算出する
 * 算出に失敗した場合はブランクの配列を返す
 * @param {Array.<string>} arrayWorkTime [出勤時間, 退勤時間]
 * @param {Array.<string>} arrayRestTime [休憩開始1, 休憩終了1, 休憩開始2, 休憩終了2, 休憩開始3, 休憩終了3]
 * @return {Array.<string>} 配列[勤務時間, 休憩時間, 時間外時間, 深夜時間]
 */
 export function calcWorkingSeconds(arrayWorkTime, arrayRestTime, attendanceBaseTime,nightTime) {
  let workingHours = ['0.00', '0.00', '0.00', '0.00']
  if (!arrayWorkTime || arrayWorkTime.length < 2)
    return workingHours;
  if (!arrayRestTime || arrayRestTime.length < 6)
    return workingHours;

  let workStart = -1.0
  let workEnd = -1.0
  let restStart1 = -1.0
  let restEnd1 = -1.0
  let restStart2 = -1.0
  let restEnd2 = -1.0
  let restStart3 = -1.0
  let restEnd3 = -1.0
  if (arrayWorkTime[0]) {
    workStart = timeToSec(arrayWorkTime[0])
  }
  if (arrayWorkTime[1]) {
    workEnd = timeToSec(arrayWorkTime[1])
  }
  if (arrayRestTime[0]) {
    restStart1 = timeToSec(arrayRestTime[0])
  }
  if (arrayRestTime[1]) {
    restEnd1 = timeToSec(arrayRestTime[1])
  }
  if (arrayRestTime[2]) {
    restStart2 = timeToSec(arrayRestTime[2])
  }
  if (arrayRestTime[3]) {
    restEnd2 = timeToSec(arrayRestTime[3])
  }
  if (arrayRestTime[4]) {
    restStart3 = timeToSec(arrayRestTime[4])
  }
  if (arrayRestTime[5]) {
    restEnd3 = timeToSec(arrayRestTime[5])
  }

  let workSec = -1.0
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    workSec = workEnd - workStart
  }

  /* 深夜休憩工数算出 */
  let nightRestSec = 0

  let restSec = -1.0
  if (restStart1 !== -1 && restEnd1 !== -1 && restStart1 < restEnd1)
  {
    restSec = restEnd1 - restStart1

    /* 深夜休憩工数算出 */
    let nightStart = timeToSec('22:00')
    let nightEnd = timeToSec('29:00')
    if(nightStart<restEnd1 && nightEnd>=restStart1){
      if(nightStart < restStart1){
        nightStart = restStart1
      }
      if(nightEnd > restEnd1){
        nightEnd = restEnd1
      }
      nightRestSec += nightEnd -nightStart
    }
    nightStart = timeToSec('00:00')
    nightEnd =  timeToSec('05:00')
    if(nightStart<restEnd1 && nightEnd>=restStart1){
      if(nightStart < restStart1){
        nightStart = restStart1
      }
      if(nightEnd > restEnd1){
        nightEnd = restEnd1
      }
      nightRestSec += nightEnd -nightStart
    }
  }
  if (restStart2 !== -1 && restEnd2 !== -1 && restStart2 < restEnd2)
  {
    restSec += restEnd2 - restStart2

    /* 深夜休憩工数算出 */
    let nightStart = timeToSec('22:00')
    let nightEnd = timeToSec('29:00')
    if(nightStart<restEnd2 && nightEnd>=restStart2){
      if(nightStart < restStart2){
        nightStart = restStart2
      }
      if(nightEnd > restEnd2){
        nightEnd = restEnd2
      }
      nightRestSec += nightEnd -nightStart
    }
    nightStart = timeToSec('00:00')
    nightEnd =  timeToSec('05:00')
    if(nightStart<restEnd2 && nightEnd>=restStart2){
      if(nightStart < restStart2){
        nightStart = restStart2
      }
      if(nightEnd > restEnd2){
        nightEnd = restEnd2
      }
      nightRestSec += nightEnd -nightStart
    }

  }
  if (restStart3 !== -1 && restEnd3 !== -1 && restStart3 < restEnd3)
  {
    restSec += restEnd3 - restStart3

    /* 深夜休憩工数算出 */
    let nightStart = timeToSec('22:00')
    let nightEnd = timeToSec('29:00')
    if(nightStart<restEnd3 && nightEnd>=restStart3){
      if(nightStart < restStart3){
        nightStart = restStart3
      }
      if(nightEnd > restEnd3){
        nightEnd = restEnd3
      }
      nightRestSec += nightEnd -nightStart
    }
    nightStart = timeToSec('00:00')
    nightEnd =  timeToSec('05:00')
    if(nightStart<restEnd3 && nightEnd>=restStart3){
      if(nightStart < restStart3){
        nightStart = restStart3
      }
      if(nightEnd > restEnd3){
        nightEnd = restEnd3
      }
      nightRestSec += nightEnd -nightStart
    }
  }

  /* 深夜工数算出 */
  let nightStart = timeToSec('22:00')
  let nightEnd = timeToSec('29:00')
  let nightSec = 0.0
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    if(nightStart<workEnd && nightEnd>=workStart){
      if(nightStart < workStart){
        nightStart = workStart
      }
      if(nightEnd > workEnd){
        nightEnd = workEnd
      }
      nightSec += nightEnd -nightStart
    }
  }
  nightStart = timeToSec('00:00')
  nightEnd =  timeToSec('05:00')
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    if(nightStart<workEnd && nightEnd>=workStart){
      if(nightStart < workStart){
        nightStart = workStart
      }
      if(nightEnd > workEnd){
        nightEnd = workEnd
      }
      nightSec += nightEnd -nightStart
    }
  }
  /* */

  if (workSec !== -1.0 && restSec !== -1.0 && workSec > restSec) {
    workSec -= restSec
  }
  if (workSec !== -1.0) {
      workingHours[0] = workSec
  }
  if (restSec !== -1.0) {
      workingHours[1] = restSec
  }
  if (workSec !== -1.0 && workSec > attendanceBaseTime) {
    workingHours[2] = workSec - attendanceBaseTime
  }

  /* 深夜工数算出 */
  if (nightSec !== -1.0 && nightRestSec !== -1.0 && nightSec > nightRestSec) {
    nightSec -= nightRestSec
  }
  if (nightSec !== 0.0) {
    workingHours[3] =  (nightSec)
  }
  /* */

  return workingHours
}

/**
 * 勤務時間(HH:mm)から工数を算出する
 * 算出に失敗した場合はブランクの配列を返す
 * @param {Array.<string>} arrayWorkTime [出勤時間, 退勤時間]
 * @param {Array.<RestInfo>} arrayRestTime [休憩開始1, 休憩終了1,...]
 * @return {Array.<string>} 配列[時間内工数, 休憩工数, 時間外工数, 深夜工数]
 */
 export function calcWorkingRestFreeHours(arrayWorkTime, arrayRestTime, attendanceBaseTime) {
 
  let workingHours = ['0.00', '0.00', '0.00', '0.00']
  if (!arrayWorkTime || arrayWorkTime.length < 2)
    return workingHours;

  let workStart = -1.0
  let workEnd = -1.0

  if (arrayWorkTime[0]) {
    workStart = timeToSec(arrayWorkTime[0])
  }
  if (arrayWorkTime[1]) {
    workEnd = timeToSec(arrayWorkTime[1])
  }

  let restSec = 0
  /* 深夜休憩工数算出 */
  let nightRestSec = 0

  arrayRestTime.map((restData)=>{
    if(restData.isDel) return
    var restStart=timeToSec(restData.attendanceRestStartDt)
    var restEnd=timeToSec(restData.attendanceRestEndDt)
    if(restStart != null && restEnd != null && restStart < restEnd){
      restSec += restEnd - restStart

      /* 深夜休憩工数算出 */
      let nightStart = timeToSec('22:00')
      let nightEnd = timeToSec('29:00')
      if(nightStart<restEnd && nightEnd>=restStart){
        if(nightStart < restStart){
          nightStart = restStart
        }
        if(nightEnd > restEnd){
          nightEnd = restEnd
        }
        nightRestSec += nightEnd -nightStart
      }
      nightStart = timeToSec('00:00')
      nightEnd =  timeToSec('05:00')
      if(nightStart<restEnd && nightEnd>=restStart){
        if(nightStart < restStart){
          nightStart = restStart
        }
        if(nightEnd > restEnd){
          nightEnd = restEnd
        }
        nightRestSec += nightEnd -nightStart
      }
      /* */
    }
  })

  let workSec = -1.0
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    workSec = workEnd - workStart
  }

  /* 深夜工数算出 */
  let nightStart = timeToSec('22:00')
  let nightEnd = timeToSec('29:00')
  let nightSec = 0.0
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    if(nightStart<workEnd && nightEnd>=workStart){
      if(nightStart < workStart){
        nightStart = workStart
      }
      if(nightEnd > workEnd){
        nightEnd = workEnd
      }
      nightSec += nightEnd -nightStart
    }
  }
  nightStart = timeToSec('00:00')
  nightEnd =  timeToSec('05:00')
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    if(nightStart<workEnd && nightEnd>=workStart){
      if(nightStart < workStart){
        nightStart = workStart
      }
      if(nightEnd > workEnd){
        nightEnd = workEnd
      }
      nightSec += nightEnd -nightStart
    }
  }
  /* */

  if (workSec !== -1.0 && restSec !== -1.0 && workSec > restSec) {
    workSec -= restSec
  }
  if (workSec !== -1.0) {
      workingHours[0] = formatHour(workSec)
  }
  if (restSec !== -1.0) {
      workingHours[1] = formatHour(restSec)
  }
  if (workSec !== -1.0 && workSec > attendanceBaseTime) {
    workingHours[2] = formatHour(workSec - attendanceBaseTime)
  }

  /* 深夜工数算出 */
  if (nightSec !== -1.0 && nightRestSec !== -1.0 && nightSec > nightRestSec) {
    nightSec -= nightRestSec
  }
  if (nightSec !== 0.0) {
    workingHours[3] =  formatHour(nightSec)
  }
  /* */
  return workingHours
}
/**
 * 勤務時間(HH:mm)から工数を算出する
 * 算出に失敗した場合はブランクの配列を返す
 * @param {Array.<string>} arrayWorkTime [出勤時間, 退勤時間]
 * @param {Array.<RestInfo>} arrayRestTime [休憩開始1, 休憩終了1,...]
 * @return {Array.<string>} 配列[時間内工数, 休憩工数, 時間外工数, 深夜工数]
 */
 export function calcWorkingRestFreeHoursTime(arrayWorkTime, arrayRestTime, attendanceBaseTime) {
  let workingHours = ['0.00', '0.00', '0.00', '0.00']
  if (!arrayWorkTime || arrayWorkTime.length < 2)
    return workingHours;

  let workStart = -1.0
  let workEnd = -1.0

  if (arrayWorkTime[0]) {
    workStart = timeToSec(arrayWorkTime[0])
  }
  if (arrayWorkTime[1]) {
    workEnd = timeToSec(arrayWorkTime[1])
  }
  console.log(arrayWorkTime, arrayRestTime,)

  let restSec = 0
  /* 深夜休憩工数算出 */
  let nightRestSec = 0
  arrayRestTime.map((restData)=>{
    if(restData.isDel) return
    var restStart=timeToSec(restData.restStartDt)
    var restEnd=timeToSec(restData.restEndDt)
    if(restStart != null && restEnd != null && restStart < restEnd){
      restSec += restEnd - restStart

      /* 深夜休憩工数算出 */
      let nightStart = timeToSec('22:00')
      let nightEnd = timeToSec('29:00')
      if(nightStart<restEnd && nightEnd>=restStart){
        if(nightStart < restStart){
          nightStart = restStart
        }
        if(nightEnd > restEnd){
          nightEnd = restEnd
        }
        nightRestSec += nightEnd -nightStart
      }
      nightStart = timeToSec('00:00')
      nightEnd =  timeToSec('05:00')
      if(nightStart<restEnd && nightEnd>=restStart){
        if(nightStart < restStart){
          nightStart = restStart
        }
        if(nightEnd > restEnd){
          nightEnd = restEnd
        }
        nightRestSec += nightEnd -nightStart
      }
      /* */
    }
  })

  let workSec = -1.0
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    workSec = workEnd - workStart
  }

  /* 深夜工数算出 */
  let nightStart = timeToSec('22:00')
  let nightEnd = timeToSec('29:00')
  let nightSec = 0.0
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    if(nightStart<workEnd && nightEnd>=workStart){
      if(nightStart < workStart){
        nightStart = workStart
      }
      if(nightEnd > workEnd){
        nightEnd = workEnd
      }
      nightSec += nightEnd -nightStart
    }
  }
  nightStart = timeToSec('00:00')
  nightEnd =  timeToSec('05:00')
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    if(nightStart<workEnd && nightEnd>=workStart){
      if(nightStart < workStart){
        nightStart = workStart
      }
      if(nightEnd > workEnd){
        nightEnd = workEnd
      }
      nightSec += nightEnd -nightStart
    }
  }
  /* */

  if (workSec !== -1.0 && restSec !== -1.0 && workSec > restSec) {
    workSec -= restSec
  }
  if (workSec !== -1.0) {
      workingHours[0] = formatHour(workSec)
  }
  if (restSec !== -1.0) {
      workingHours[1] = formatHour(restSec)
  }
  if (workSec !== -1.0 && workSec > attendanceBaseTime) {
    workingHours[2] = formatHour(workSec - attendanceBaseTime)
  }
  /* 深夜工数算出 */
  if (nightSec !== -1.0 && nightRestSec !== -1.0 && nightSec > nightRestSec) {
    nightSec -= nightRestSec
  }
  if (nightSec !== 0.0) {
    workingHours[3] =  formatHour(nightSec)
  }
  /* */
  return workingHours
}

/**
 * 勤務時間(HH:mm)から勤務時間・休憩時間を算出する
 * 算出に失敗した場合はブランクの配列を返す
 * @param {Array.<string>} arrayWorkTime [出勤時間, 退勤時間]
 * @param {Array.<string>} arrayRestTime [休憩開始1, 休憩終了1,...]
 * @return {Array.<string>} 配列[勤務時間, 休憩時間, 時間外時間, 深夜時間]
 */
 export function calcWorkingRestFreeSeconds(arrayWorkTime, arrayRestTime, attendanceBaseTime,nightTime) {
  let workingHours = ['0.00', '0.00', '0.00', '0.00']
  if (!arrayWorkTime || arrayWorkTime.length < 2)
    return workingHours;
  // if (!arrayRestTime || arrayRestTime.length < 6)
  //   return workingHours;

  let workStart = -1.0
  let workEnd = -1.0


  if (arrayWorkTime[0]) {
    workStart = timeToSec(arrayWorkTime[0])
  }
  if (arrayWorkTime[1]) {
    workEnd = timeToSec(arrayWorkTime[1])
  }

  /* 深夜工数算出 */
  let nightStart = timeToSec('22:00')
  let nightEnd = timeToSec('29:00')
  let nightSec = 0.0
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    if(nightStart<workEnd && nightEnd>=workStart){
      if(nightStart < workStart){
        nightStart = workStart
      }
      if(nightEnd > workEnd){
        nightEnd = workEnd
      }
      nightSec += nightEnd -nightStart
    }
  }
  nightStart = timeToSec('00:00')
  nightEnd =  timeToSec('05:00')
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    if(nightStart<workEnd && nightEnd>=workStart){
      if(nightStart < workStart){
        nightStart = workStart
      }
      if(nightEnd > workEnd){
        nightEnd = workEnd
      }
      nightSec += nightEnd -nightStart
    }
  }
  /* */

  /* 深夜休憩工数算出 */
  let nightRestSec = 0
  let restSec = 0
  for(var i=0;i<arrayRestTime.length;i=i+2)
  {
    var restStart=timeToSec(arrayRestTime[i])
    var restEnd=timeToSec(arrayRestTime[i+1])
    if(restStart != null && restEnd != null && restStart < restEnd){
      restSec += restEnd - restStart
      console.log(restSec)
      
      /* 深夜休憩工数算出 */
      let nightStart = timeToSec('22:00')
      let nightEnd = timeToSec('29:00')
      if(nightStart<restEnd && nightEnd>=restStart){
        if(nightStart < restStart){
          nightStart = restStart
        }
        if(nightEnd > restEnd){
          nightEnd = restEnd
        }
        nightRestSec += nightEnd -nightStart
      }
      nightStart = timeToSec('00:00')
      nightEnd =  timeToSec('05:00')
      if(nightStart<restEnd && nightEnd>=restStart){
        if(nightStart < restStart){
          nightStart = restStart
        }
        if(nightEnd > restEnd){
          nightEnd = restEnd
        }
        nightRestSec += nightEnd -nightStart
      }
      /* */
    }
  }


  let workSec = -1.0
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    workSec = workEnd - workStart
  }

  if (workSec !== -1.0 && restSec !== -1.0 && workSec > restSec) {
    workSec -= restSec
  }
  if (workSec !== -1.0) {
      workingHours[0] = workSec
  }
  if (restSec !== -1.0) {
      workingHours[1] = restSec
  }
  if (workSec !== -1.0 && workSec > attendanceBaseTime) {
    workingHours[2] = workSec - attendanceBaseTime
  }
  /* 深夜工数算出 */
  if (nightSec !== -1.0 && nightRestSec !== -1.0 && nightSec > nightRestSec) {
    nightSec -= nightRestSec
  }
  if (nightSec !== 0.0) {
    workingHours[3] =  (nightSec)
  }
  /* */
  return workingHours
}
/**
 * 勤務時間(yyyy-MM-dd HH:mm:ss)から勤務時間・休憩時間を算出する
 * 算出に失敗した場合はブランクの配列を返す
 * @param {Array.<string>} arrayWorkTime [出勤時間, 退勤時間]
 * @param {Array.<string>} arrayRestTime [休憩開始1, 休憩終了1, 休憩開始2, 休憩終了2, 休憩開始3, 休憩終了3]
 * @return {Array.<string>} 配列[勤務時間, 休憩時間]
 */
 export function calcClockWorkingSeconds(arrayWorkTime, arrayRestTime) {
  let workingHours = ['0.00', '0.00']
  if (!arrayWorkTime || arrayWorkTime.length < 2)
    return workingHours;
  if (!arrayRestTime || arrayRestTime.length < 6)
    return workingHours;

  let workStart = -1.0
  let workEnd = -1.0
  let restStart1 = -1.0
  let restEnd1 = -1.0
  let restStart2 = -1.0
  let restEnd2 = -1.0
  let restStart3 = -1.0
  let restEnd3 = -1.0
  if (arrayWorkTime[0]) {
    workStart = new Date(arrayWorkTime[0]).getTime()
  }
  if (arrayWorkTime[1]) {
    workEnd = new Date(arrayWorkTime[1]).getTime()
  }
  if (arrayRestTime[0]) {
    restStart1 = new Date(arrayRestTime[0]).getTime()
  }
  if (arrayRestTime[1]) {
    restEnd1 = new Date(arrayRestTime[1]).getTime()
  }
  if (arrayRestTime[2]) {
    restStart2 = new Date(arrayRestTime[2]).getTime()
  }
  if (arrayRestTime[3]) {
    restEnd2 = new Date(arrayRestTime[3]).getTime()
  }
  if (arrayRestTime[4]) {
    restStart3 = new Date(arrayRestTime[4]).getTime()
  }
  if (arrayRestTime[5]) {
    restEnd3 = new Date(arrayRestTime[5]).getTime()
  }

  /* 深夜工数算出 */
  let nightStart = timeToSec('22:00')
  let nightEnd = timeToSec('29:00')
  let nightSec = 0.0
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    if(nightStart<workEnd && nightEnd>=workStart){
      if(nightStart < workStart){
        nightStart = workStart
      }
      if(nightEnd > workEnd){
        nightEnd = workEnd
      }
      nightSec += nightEnd -nightStart
    }
  }
  nightStart = timeToSec('00:00')
  nightEnd =  timeToSec('05:00')
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    if(nightStart<workEnd && nightEnd>=workStart){
      if(nightStart < workStart){
        nightStart = workStart
      }
      if(nightEnd > workEnd){
        nightEnd = workEnd
      }
      nightSec += nightEnd -nightStart
    }
  }
  /* */

  let workSec = -1.0
  if (workStart !== -1 && workEnd !== -1 && workStart < workEnd)
  {
    workSec = workEnd - workStart
  }

  /* 深夜休憩工数算出 */
  let nightRestSec = 0

  let restSec = -1.0
  if (restStart1 !== -1 && restEnd1 !== -1 && restStart1 < restEnd1)
  {
    restSec = restEnd1 - restStart1

    /* 深夜休憩工数算出 */
    let nightStart = timeToSec('22:00')
    let nightEnd = timeToSec('29:00')
    if(nightStart<restEnd1 && nightEnd>=restStart1){
      if(nightStart < restStart1){
        nightStart = restStart1
      }
      if(nightEnd > restEnd1){
        nightEnd = restEnd1
      }
      nightRestSec += nightEnd -nightStart
    }
    nightStart = timeToSec('00:00')
    nightEnd =  timeToSec('05:00')
    if(nightStart<restEnd1 && nightEnd>=restStart1){
      if(nightStart < restStart1){
        nightStart = restStart1
      }
      if(nightEnd > restEnd1){
        nightEnd = restEnd1
      }
      nightRestSec += nightEnd -nightStart
    }
  }
  if (restStart2 !== -1 && restEnd2 !== -1 && restStart2 < restEnd2)
  {
    restSec += restEnd2 - restStart2

    /* 深夜休憩工数算出 */
    let nightStart = timeToSec('22:00')
    let nightEnd = timeToSec('29:00')
    if(nightStart<restEnd2 && nightEnd>=restStart2){
      if(nightStart < restStart2){
        nightStart = restStart2
      }
      if(nightEnd > restEnd2){
        nightEnd = restEnd2
      }
      nightRestSec += nightEnd -nightStart
    }
    nightStart = timeToSec('00:00')
    nightEnd =  timeToSec('05:00')
    if(nightStart<restEnd2 && nightEnd>=restStart2){
      if(nightStart < restStart2){
        nightStart = restStart2
      }
      if(nightEnd > restEnd2){
        nightEnd = restEnd2
      }
      nightRestSec += nightEnd -nightStart
    }

  }
  if (restStart3 !== -1 && restEnd3 !== -1 && restStart3 < restEnd3)
  {
    restSec += restEnd3 - restStart3

    /* 深夜休憩工数算出 */
    let nightStart = timeToSec('22:00')
    let nightEnd = timeToSec('29:00')
    if(nightStart<restEnd3 && nightEnd>=restStart3){
      if(nightStart < restStart3){
        nightStart = restStart3
      }
      if(nightEnd > restEnd3){
        nightEnd = restEnd3
      }
      nightRestSec += nightEnd -nightStart
    }
    nightStart = timeToSec('00:00')
    nightEnd =  timeToSec('05:00')
    if(nightStart<restEnd3 && nightEnd>=restStart3){
      if(nightStart < restStart3){
        nightStart = restStart3
      }
      if(nightEnd > restEnd3){
        nightEnd = restEnd3
      }
      nightRestSec += nightEnd -nightStart
    }
  }

  if (workSec !== -1.0 && restSec !== -1.0 && workSec > restSec) {
    workSec -= restSec
  }
  if (workSec !== -1.0) {
      workingHours[0] = workSec
  }
  if (restSec !== -1.0) {
      workingHours[1] = restSec
  }

  /* 深夜工数算出 */
  if (nightSec !== -1.0 && nightRestSec !== -1.0 && nightSec > nightRestSec) {
    nightSec -= nightRestSec
  }
  if (nightSec !== 0.0) {
    workingHours[3] =  (nightSec)
  }
  /* */

  return workingHours
}

/**
 * 勤務時間(HH:mm)を秒数で返す
 * @param {string} time 勤務時間(HH:mm)
 * @return {int} 秒数
 */
export function timeToSec(time) {
    if(!time)
      return null
    let tokens = time.split(':')
    let sec = 0

    if (tokens[0]) sec += Number(tokens[0]) * 3600
    if (tokens[1]) sec += Number(tokens[1]) * 60

    return sec
}

/**
 * 秒数を時間(HHmm)の文字列で返す
 * @param {int} seconds 秒
 * @return {string} 時間(HHmm)の文字列
 */
export function secToTime(seconds) {
  var hour = seconds / 3600 | 0
  var minutes = seconds % 3600 / 60 | 0

  return ('00' + hour.toString()).slice(-2) + ('00' + minutes.toString()).slice(-2)
}

/**
 * 勤怠とシフトの入力項目の差異チェック
 * @param {dailyList} workInfo DBに登録されているデータ
 * @param {dailyList} data 更新用データ
 * @return {boolean} 差異フラグ(true:有,false:無)
 */
export function checkUpdate(workInfo,data) 
{
  let bRet = false
  //console.log(workInfo)
  //console.log(data)
  const restList = []
  restList[0] = data['restStart1Dt']
  restList[1] = data['restEnd1Dt']
  restList[2] = data['restStart2Dt']
  restList[3] = data['restEnd2Dt']
  restList[4] = data['restStart3Dt']
  restList[5] = data['restEnd3Dt']
  
  const workDivision = data['workDivision']
  const workEndDt = data.workEndDt
  const workStartDt = data.workStartDt
  const remark = data['remark']

  const restListDB = []
  restListDB[0] = workInfo['restStart1Dt']
  restListDB[1] = workInfo['restEnd1Dt']
  restListDB[2] = workInfo['restStart2Dt']
  restListDB[3] = workInfo['restEnd2Dt']
  restListDB[4] = workInfo['restStart3Dt']
  restListDB[5] = workInfo['restEnd3Dt']
  
  const workDivisionDB = workInfo['workDivision']
  const workEndDtDB = workInfo['workEndDt']
  const workStartDtDB = workInfo['workStartDt']
  const remarkDB = workInfo['remark']
  // if(!workStartDt)
  //   return false
  if(workStartDt!=workStartDtDB)
  {
    return true 
  }
  if(workEndDt!=workEndDtDB)
  {
    return true 
  }
  for(var i=parseInt(0);i<restList.length;i++)
  {
      if(restList[i] && restList[i]!=restListDB[i])
      {
        return true
      }
  }
  if(workDivisionDB!=workDivision)
  {
    return true 
  }

  if(remarkDB!=remark)
  {
    return true 
  }
  return bRet
}

/**
 * 勤怠とシフトの入力項目のエラーチェック
 * @param {List<dailyList>} data 更新用データ
 * @return {boolean} エラーフラグ(true:有,false:無)
 */
export function ValidUpdate(data) 
{
    let bRet = false

    for (var item in data) {
      const datamap = data[item];
      
      const restList = []
      restList[0] = datamap['restStart1Dt']
      restList[1] = datamap['restEnd1Dt']
      restList[2] = datamap['restStart2Dt']
      restList[3] = datamap['restEnd2Dt']
      restList[4] = datamap['restStart3Dt']
      restList[5] = datamap['restEnd3Dt']

      
      datamap.updateRestList.map((restData)=>{
        restList.unshift(restData.attendanceRestStartDt)
        restList.unshift(restData.attendanceRestEndDt)
      })
      datamap.addRestList.map((restData)=>{
        restList.unshift(restData.attendanceRestStartDt)
        restList.unshift(restData.attendanceRestEndDt)
      })
      console.log(restList)
      //restList[6] = datamap['restTotalDt']

      const workDivision = datamap['workDivision']
      const workEndDt = datamap['workEndDt']
      const workStartDt = datamap['workStartDt']
      const workTotalDt = datamap['workTotalDt']
      const baseDt = datamap['baseDt']
      const orderId = datamap['orderId']
      const stffId = datamap['stffId']

      // if(workDivision == 5)
      // {
      //   alert.error("出勤区分が間違っています")
      //   console.log("出勤区分が間違っています")
      //   console.log(workDivision)
      //   datamap['errorFlg'] = 1
      //   bRet = true
      // }
      if(workStartDt !='' && workEndDt == '')
      {
        //alert.error("出勤時間がありません")
        datamap['errorFlg'] = 1
        bRet = true
      }
      else if(workStartDt =='' && workEndDt != '')
      {
        //退勤時間がない
        datamap['errorFlg'] = 1
        bRet = true
      }
      else if(workStartDt =='' && workEndDt == '' && (workDivision==2 ||workDivision==3||workDivision==4))
      {
        //退勤時間がない
        datamap['errorFlg'] = 1
        bRet = true
      }
      else if(workStartDt == '' && workEndDt == '')
      {
        for(var num in restList)
        {
          if(restList[num]!="")
          {
            //alert.error("休憩時間に誤りがあります")
            datamap['errorFlg'] = 1
            bRet = true
          }
        }
      }
      else
      {
        const startDt = timeToSec(workStartDt)
        const endDt = timeToSec(workEndDt)

        //const starthour = parseInt(workStartDt.slice(0,2)) 
        //const startminute= parseInt(workStartDt.slice(-2))
        //const endhour = parseInt(workEndDt.slice(0,2))
        //const endminute = parseInt(workEndDt.slice(-2))
        //出勤より退勤時間が早ければエラー
        //if(starthour>endhour || (starthour==endhour && startminute>endminute))
        if(startDt>endDt)
        {
          //alert.error("出勤時間より退勤時間が早いです")
          datamap['errorFlg'] = 1
          bRet = true
        }

        for(var num in restList)
        {
          if(restList[num]=="")
          {
            for(var i=parseInt(num)+1;i<restList.length;i++)
            {
                if(restList[i]!="")
                {
                  //console.log(restList[i])
                  //alert.error("休憩時間に誤りがあります")
                  datamap['errorFlg'] = 1
                  bRet = true
                }
            }
            break
          }
          else
          {
            const firstRestDt = timeToSec(restList[num])
            // const firsthour = parseInt(restList[num].slice(0,2))
            // const firstminute = parseInt(restList[num].slice(-2))
            //出勤より休憩時間が早ければエラー
            //if(starthour>firsthour || (starthour==firsthour && startminute>firstminute))
            if(startDt>firstRestDt)
            {
              //alert.error("休憩時間が出勤時間外にあります")
              datamap['errorFlg'] = 1
              bRet = true
            }

            //退勤より休憩時間が遅ければエラー
            //if(firsthour>endhour || (firsthour==endhour && firstminute>endminute))
            if(firstRestDt>endDt)
            {
              //alert.error("休憩時間が退勤時間外にあります")
              datamap['errorFlg'] = 1
              bRet = true
            }

            const i = parseInt(num)+1;
            if(i<restList.length)
            {
              if(restList[i]=="")
              {
                if(i%2!=0)
                {
                  //alert.error("休憩時間に誤りがあります")
                  datamap['errorFlg'] = 1
                  bRet = true
                }
              }
              //ひとつ前の休憩時間より休憩時間が遅ければエラー
              // const lasthour = parseInt(restList[i].slice(0,2))
              // const lastminute = parseInt(restList[i].slice(-2))
              const lastRestDt = timeToSec(restList[num])
              if(firstRestDt>lastRestDt)
              {
                //alert.error("休憩時間が前より早くにあります")
                datamap['errorFlg'] = 1
                bRet = true
              }
            }
          }
        }
      }
    }

    return bRet
}

/**
 * 勤怠とシフトの入力項目のエラーチェック
 * @param {List<dailyList>} data 更新用データ
 * @return {boolean} エラーフラグ(true:有,false:無)
 */
 export function ValidUpdateShift(data) 
 {
     let bRet = false
 
     for (var item in data) {
       const datamap = data[item];
       
       const restList = []
       restList[0] = datamap['restStart1Dt']
       restList[1] = datamap['restEnd1Dt']
       restList[2] = datamap['restStart2Dt']
       restList[3] = datamap['restEnd2Dt']
       restList[4] = datamap['restStart3Dt']
       restList[5] = datamap['restEnd3Dt']
 
       
       datamap.updateRestList.map((restData)=>{
         restList.unshift(restData.restStartDt)
         restList.unshift(restData.restEndDt)
       })
       datamap.addRestList.map((restData)=>{
         restList.unshift(restData.restStartDt)
         restList.unshift(restData.restEndDt)
       })
       console.log(restList)
       //restList[6] = datamap['restTotalDt']
 
       const workDivision = datamap['workDivision']
       const workEndDt = datamap['workEndDt']
       const workStartDt = datamap['workStartDt']
       const workTotalDt = datamap['workTotalDt']
       const baseDt = datamap['baseDt']
       const orderId = datamap['orderId']
       const stffId = datamap['stffId']
 
       // if(workDivision == 5)
       // {
       //   alert.error("出勤区分が間違っています")
       //   console.log("出勤区分が間違っています")
       //   console.log(workDivision)
       //   datamap['errorFlg'] = 1
       //   bRet = true
       // }
       if(workStartDt !='' && workEndDt == '')
       {
         //alert.error("出勤時間がありません")
         datamap['errorFlg'] = 1
         bRet = true
       }
       else if(workStartDt =='' && workEndDt != '')
       {
         //退勤時間がない
         datamap['errorFlg'] = 1
         bRet = true
       }
       else if(workStartDt =='' && workEndDt == '' && (workDivision==2 ||workDivision==3||workDivision==4))
       {
         //退勤時間がない
         datamap['errorFlg'] = 1
         bRet = true
       }
       else if(workStartDt == '' && workEndDt == '')
       {
         for(var num in restList)
         {
           if(restList[num]!="")
           {
             //alert.error("休憩時間に誤りがあります")
             datamap['errorFlg'] = 1
             bRet = true
           }
         }
       }
       else
       {
         const startDt = timeToSec(workStartDt)
         const endDt = timeToSec(workEndDt)
 
         //const starthour = parseInt(workStartDt.slice(0,2)) 
         //const startminute= parseInt(workStartDt.slice(-2))
         //const endhour = parseInt(workEndDt.slice(0,2))
         //const endminute = parseInt(workEndDt.slice(-2))
         //出勤より退勤時間が早ければエラー
         //if(starthour>endhour || (starthour==endhour && startminute>endminute))
         if(startDt>endDt)
         {
           //alert.error("出勤時間より退勤時間が早いです")
           datamap['errorFlg'] = 1
           bRet = true
         }
 
         for(var num in restList)
         {
           if(restList[num]=="")
           {
             for(var i=parseInt(num)+1;i<restList.length;i++)
             {
                 if(restList[i]!="")
                 {
                   //console.log(restList[i])
                   //alert.error("休憩時間に誤りがあります")
                   datamap['errorFlg'] = 1
                   bRet = true
                 }
             }
             break
           }
           else
           {
             const firstRestDt = timeToSec(restList[num])
             // const firsthour = parseInt(restList[num].slice(0,2))
             // const firstminute = parseInt(restList[num].slice(-2))
             //出勤より休憩時間が早ければエラー
             //if(starthour>firsthour || (starthour==firsthour && startminute>firstminute))
             if(startDt>firstRestDt)
             {
               //alert.error("休憩時間が出勤時間外にあります")
               datamap['errorFlg'] = 1
               bRet = true
             }
 
             //退勤より休憩時間が遅ければエラー
             //if(firsthour>endhour || (firsthour==endhour && firstminute>endminute))
             if(firstRestDt>endDt)
             {
               //alert.error("休憩時間が退勤時間外にあります")
               datamap['errorFlg'] = 1
               bRet = true
             }
 
             const i = parseInt(num)+1;
             if(i<restList.length)
             {
               if(restList[i]=="")
               {
                 if(i%2!=0)
                 {
                   //alert.error("休憩時間に誤りがあります")
                   datamap['errorFlg'] = 1
                   bRet = true
                 }
               }
               //ひとつ前の休憩時間より休憩時間が遅ければエラー
               // const lasthour = parseInt(restList[i].slice(0,2))
               // const lastminute = parseInt(restList[i].slice(-2))
               const lastRestDt = timeToSec(restList[num])
               if(firstRestDt>lastRestDt)
               {
                 //alert.error("休憩時間が前より早くにあります")
                 datamap['errorFlg'] = 1
                 bRet = true
               }
             }
           }
         }
       }
     }
 
     return bRet
 }

/**
 * 勤怠とシフトの入力項目のエラーチェック
 * @param {dailyList} data 更新用データ
 * @param {dailyList} workInfo DBデータ
 * @return {boolean} エラーフラグ(true:有,false:無)
 */
 export function ValidUpdateRow(data,workInfo) 
 {
  let bRet = false

  const datamap = data;
      
  const restList = []
  restList[0] = datamap['restStart1Dt']
  restList[1] = datamap['restEnd1Dt']
  restList[2] = datamap['restStart2Dt']
  restList[3] = datamap['restEnd2Dt']
  restList[4] = datamap['restStart3Dt']
  restList[5] = datamap['restEnd3Dt']
  
  //restList[6] = datamap['restTotalDt']

  const workDivision = datamap['workDivision']
  const workEndDt = datamap['workEndDt']
  const workStartDt = datamap['workStartDt']
  const workTotalDt = datamap['workTotalDt']
  const baseDt = datamap['baseDt']
  const orderId = datamap['orderId']
  const stffId = datamap['stffId']

  console.log(workStartDt)
  console.log(workDivision)
  if(workDivision == 5)
  {
    //alert.error("出勤区分が間違っています")
    console.log("出勤区分が間違っています")
    console.log(workDivision)
    datamap['errorFlg'] = 1
    bRet = true
  }
  if(workStartDt !='' && workEndDt == '')
  {
    //alert.error("出勤時間がありません")
    workInfo['errorFlg'] = 1
    bRet = true
  }
  else if(workStartDt =='' && workEndDt != '')
  {
    //退勤時間がない
    workInfo['errorFlg'] = 1
    bRet = true
    return true
  }
  else if(workStartDt =='' && workEndDt == '' && (workDivision=>2 || workDivision<=4))
  {
    //退勤時間がない
    workInfo['errorFlg'] = 1
    bRet = true
    return true
  }
  else if(workStartDt == '' && workEndDt == '')
  {
    for(var num in restList)
    {
      if(restList[num]!="")
      {
        //alert.error("休憩時間に誤りがあります")
        workInfo['restStart'+(parseInt(num)+1)+'DtError'] = true
        bRet = true
        return true
      }
    }
  }
  else
  {
    const startDt = timeToSec(workStartDt)
    const endDt = timeToSec(workEndDt)
    //const starthour = parseInt(workStartDt.slice(0,2)) 
    //const startminute= parseInt(workStartDt.slice(-2))
    //const endhour = parseInt(workEndDt.slice(0,2))
    //const endminute = parseInt(workEndDt.slice(-2))
    //出勤より退勤時間が早ければエラー
    //if(starthour>endhour || (starthour==endhour && startminute>endminute))
    if(startDt>endDt)
    {
      //alert.error("出勤時間より退勤時間が早いです")
      datamap['errorFlg'] = 1
      return true
    }

    for(var num in restList)
    {
      if(restList[num]=="")
      {
        for(var i=parseInt(num)+1;i<restList.length;i++)
        {
            if(restList[i]!="")
            {
              //console.log(restList[i])
              //alert.error("休憩時間に誤りがあります")
              workInfo['restStart'+(i+1)+'DtError'] = true
              return true
            }
        }
        break
      }
      else
      {
        const firstRestDt = timeToSec(restList[num])
        // const firsthour = parseInt(restList[num].slice(0,2))
        // const firstminute = parseInt(restList[num].slice(-2))
        //出勤より休憩時間が早ければエラー
        //if(starthour>firsthour || (starthour==firsthour && startminute>firstminute))
        if(startDt>firstRestDt)
        {
          //alert.error("休憩時間が出勤時間外にあります")
          workInfo['restStart'+(parseInt(num)+1)+'DtError'] = true
          bRet = true
        }

        //退勤より休憩時間が遅ければエラー
        //if(firsthour>endhour || (firsthour==endhour && firstminute>endminute))
        if(firstRestDt>endDt)
        {
          //alert.error("休憩時間が退勤時間外にあります")
          workInfo['restStart'+(parseInt(num)+1)+'DtError'] = true
          bRet = true
        }

        const i = parseInt(num)+1;
        if(i<restList.length)
        {
          if(restList[i]=="")
          {
            if(i%2!=0)
            {
              //alert.error("休憩時間に誤りがあります")
              workInfo['restStart'+(i+1)+'DtError'] = true
              bRet = true
            }
          }
          //ひとつ前の休憩時間より休憩時間が遅ければエラー
          // const lasthour = parseInt(restList[i].slice(0,2))
          // const lastminute = parseInt(restList[i].slice(-2))
          const lastRestDt = timeToSec(restList[num])
          if(firstRestDt>lastRestDt)
          {
            //alert.error("休憩時間が前より早くにあります")
            workInfo['restStart'+(i+1)+'DtError'] = true
            bRet = true
          }
        }
      }
    }
  }
  return bRet
 }

 /**
 * シフトと勤怠の差異チェック
 * @param {List<dailyList>} data 更新用データ
 * @return {boolean} エラーフラグ(true:有,false:無)
 */
export function checkNomalShift(data) 
{
    let bRet = true

    data.map((datamap)=>{
      if(datamap.attendanceRestStart1Dt != datamap.shiftRestStart1Dt) bRet = false
      if(datamap.attendanceRestStart2Dt != datamap.shiftRestStart2Dt) bRet = false
      if(datamap.attendanceRestStart3Dt != datamap.shiftRestStart3Dt) bRet = false
      if(datamap.attendanceRestEnd1Dt != datamap.shiftRestEnd1Dt) bRet = false
      if(datamap.attendanceRestEnd2Dt != datamap.shiftRestEnd2Dt) bRet = false
      if(datamap.attendanceRestEnd3Dt != datamap.shiftRestEnd3Dt) bRet = false
      
      if(datamap.attendanceWorkStartDt != datamap.shiftWorkStartDt) bRet = false
      if(datamap.attendanceWorkEndDt != datamap.shiftWorkEndDt) bRet = false
      if(datamap.attendanceWorkDivision != datamap.shiftWorkDivision) bRet = false

      if(datamap.attendanceRestList.length != datamap.shiftRestList.length){
        bRet = false
      }else{
        datamap.attendanceRestList.map((rest,index)=>{
          if(rest.startDt != datamap.shiftRestList[index].startDt)bRet = false
          if(rest.endDt != datamap.shiftRestList[index].endDt)bRet = false
        })
      }


    })

    return bRet
}


 /**
 * 勤怠CSV出力用データ追加
 * @param {dailyList} workInfoList DBに登録されているデータ 
 * @param {String} header DBに登録されているデータ 
 * @return {csvExport} エクスポートデータ
 */
export function makeAttendanceCSV(workInfoList,header) 
{
  let Header = ''
  header.map((head)=>{
    //console.log(head)
    Header+=head
    
    Header+=','
  })
  let maxRestCount = 3
  let result = '';
  workInfoList.map((workInfo)=>{
    var lineResult =
        workInfo.orderId + ','

      + workInfo.companyName + ','
      + workInfo.comDepartmentId + ','
      + workInfo.comDepartmentName + ','
      + workInfo.customerName + ','
      + workInfo.cusDepartmentId + ','
      + workInfo.cusDepartmentName + ','
      
      + workInfo.staffId + ','
      + workInfo.staffName + ','
      + workInfo.baseDt + ','
      + workInfo.week + ','
      
      + diff48Time(workInfo.baseDt,workInfo.workStartDt) + ','
      + diff48Time(workInfo.baseDt,workInfo.workEndDt) + ','

      + secToTime(workInfo.normalTime) +','
      + secToTime(workInfo.overTime) +','
      + secToTime(workInfo.nightTime) +','
      + secToTime(workInfo.workTotalDt) +','
      + secToTime(workInfo.restTotalDt) +','
      + workInfo.workDivision + ','
      + workInfo.transferDt + ','
      + workInfo.remark + ',';

    if(workInfo.restList.length == 0){
      lineResult +=
          diff48Time(workInfo.baseDt,workInfo.restStart1Dt) + ','
        + diff48Time(workInfo.baseDt,workInfo.restEnd1Dt) + ','
        + diff48Time(workInfo.baseDt,workInfo.restStart2Dt) + ','
        + diff48Time(workInfo.baseDt,workInfo.restEnd2Dt) + ','
        + diff48Time(workInfo.baseDt,workInfo.restStart3Dt) + ','
        + diff48Time(workInfo.baseDt,workInfo.restEnd3Dt);
    }else{
      workInfo.restList.forEach((restInfo) => {
        lineResult +=
            diff48Time(workInfo.baseDt,restInfo.attendanceRestStartDt) + ','
          + diff48Time(workInfo.baseDt,restInfo.attendanceRestEndDt) + ','
      });
      for(var i=maxRestCount;i<workInfo.restList.length;i++){
        Header+="休憩開始時間"+ (i+1) +",";
        Header+="休憩終了時間"+ (i+1) +",";
      }
      if(maxRestCount>workInfo.restList.length){
        maxRestCount=workInfo.restList.length
      }
    }



    lineResult = lineResult.replaceAll('null','')
    result += lineResult
    
    result += '\r\n'
  })
  result = Header + '\r\n' + result
  return result
}
 /**
 * 勤怠CSV出力用データ追加
 * @param {dailyList} workInfoList DBに登録されているデータ 
 * @param {String} header DBに登録されているデータ 
 * @return {csvExport} エクスポートデータ
 */
 export function makeAttendanceRestCSV(workInfoList) 
 {
   let Header = '受注ID,スタッフID,勤怠日付,休憩開始日時,休憩終了日時'
   let result = Header + '\r\n'
   workInfoList.map((workInfo)=>{
     var lineResult =
         workInfo.orderId + ','
       + workInfo.staffId + ','
       + workInfo.baseDt + ','
       + diff48Time(workInfo.baseDt,workInfo.attendanceRestStartDt) + ','
       + diff48Time(workInfo.baseDt,workInfo.attendanceRestEndDt)
 
     lineResult = lineResult.replaceAll('null','')
     result += lineResult
     
     result += '\r\n'
   })
   return result
 }

 /**
 * シフトCSV出力用データ追加
 * @param {dailyList} workInfoList DBに登録されているデータ 
 * @param {String} header DBに登録されているデータ 
 * @return {csvExport} エクスポートデータ
 */
  export function makeShiftCSV(workInfoList,header) 
  {
    let Header = ''
    header.map((head)=>{
      //console.log(head)
      Header+=head
      
      Header+=','
    })

    let maxRestCount = 3
    let result = '';

    workInfoList.map((workInfo)=>{
      var lineResult =
      workInfo.orderId + ','
      + workInfo.occupation + ','
      + workInfo.companyId + ','
      + workInfo.companyName + ','
      + workInfo.comDepartmentId + ','
      + workInfo.comDepartmentName + ','
      + workInfo.customerId + ','
      + workInfo.customerName + ','
      + workInfo.cusDepartmentId + ','
      + workInfo.cusDepartmentName + ','
      + workInfo.staffId + ','
      + workInfo.staffName + ','
      + diff48Time('1900-01-01',workInfo.workStartAgreementDt) + ','
      + diff48Time('1900-01-01',workInfo.workEndAgreementDt) + ','
      + diff48Time('1900-01-01',workInfo.restStart1AgreementDt) + ','
      + diff48Time('1900-01-01',workInfo.restEnd1AgreementDt) + ','
      + diff48Time('1900-01-01',workInfo.restStart2AgreementDt) + ','
      + diff48Time('1900-01-01',workInfo.restEnd2AgreementDt) + ','
      + diff48Time('1900-01-01',workInfo.restStart3AgreementDt) + ','
      + diff48Time('1900-01-01',workInfo.restEnd3AgreementDt) + ','
      + workInfo.baseDt + ','
      + workInfo.week + ','
      + workInfo.workDivision + ','
      + diff48Time(workInfo.baseDt,workInfo.workStartDt) + ','
      + diff48Time(workInfo.baseDt,workInfo.workEndDt) + ','
      + workInfo.transferDt + ','
      + workInfo.remark + ','

      if(workInfo.restList.length == 0){
        lineResult +=
            diff48Time(workInfo.baseDt,workInfo.restStart1Dt) + ','
          + diff48Time(workInfo.baseDt,workInfo.restEnd1Dt) + ','
          + diff48Time(workInfo.baseDt,workInfo.restStart2Dt) + ','
          + diff48Time(workInfo.baseDt,workInfo.restEnd2Dt) + ','
          + diff48Time(workInfo.baseDt,workInfo.restStart3Dt) + ','
          + diff48Time(workInfo.baseDt,workInfo.restEnd3Dt);
      }else{
        workInfo.restList.forEach((restInfo) => {
          lineResult +=
              diff48Time(workInfo.baseDt,restInfo.restStartDt) + ','
            + diff48Time(workInfo.baseDt,restInfo.restEndDt) + ','
        });
        for(var i=maxRestCount;i<workInfo.restList.length;i++){
          Header+="休憩開始時間"+ (i+1) +",";
          Header+="休憩終了時間"+ (i+1) +",";
        }
        if(maxRestCount>workInfo.restList.length){
          maxRestCount=workInfo.restList.length
        }
      }

      lineResult = lineResult.replaceAll('null','')
      result += lineResult
      
      result += '\r\n'
    })
        
    result = Header + '\r\n' + result
    return result
  }
 /**
 * シフトCSV出力用データ追加
 * @param {dailyList} workInfoList DBに登録されているデータ 
 * @param {String} header DBに登録されているデータ 
 * @return {csvExport} エクスポートデータ
 */
 export function makeShiftRestCSV(workInfoList) 
 {
   let Header = '受注ID,スタッフID,日付,休憩開始日時,休憩終了日時'
   let result = Header + '\r\n'
   workInfoList.map((workInfo)=>{
     var lineResult =
         workInfo.orderId + ','
       + workInfo.staffId + ','
       + workInfo.baseDt + ','
       + diff48Time(workInfo.baseDt,workInfo.restStartDt) + ','
       + diff48Time(workInfo.baseDt,workInfo.restEndDt)
 
     lineResult = lineResult.replaceAll('null','')
     result += lineResult
     
     result += '\r\n'
   })
   return result
 }
/**
 * 日時情報を(yyyy-MM-dd HH:mm:ss)のフォーマットに変換
 * @param {string} dt 日時
 * @return {string} 日時(yyyy-MM-dd HH:mm:ss)の文字列
 */
export function dateFormat(dt) {
  if (!dt || dt == null)
    return null
  //console.log(dt)
  var date = new Date(dt)
  date = date.toLocaleString("ja-JP", { timeZone: "GMT" });
  date = new Date(date);
  //console.log(date)
  let time = 
  date.getFullYear() + "-" + 
  ('00'+(date.getMonth() + 1)).slice(-2) + "-" 
  + ('00'+date.getDate()).slice(-2) + " " 
  + ('00'+date.getHours()).slice(-2) + ":" 
  + ('00'+date.getMinutes()).slice(-2) + ":" 
  + ('00'+date.getSeconds()).slice(-2) 
  
  return time
}

/**
 * yyyy-MM-ddの文字列をyyyy年mm月dd日に変換
 * 変換に失敗した場合はブランクを返す
 * @param {string} dt 日時
 * @return {string} yyyy年mm月dd日の文字列
 */
 export function dtFormatYMD(dt) {
  if (!dt)
    return '--年--月--日'
  var dts = dt.split('-')
  console.log(dts)
  if(dts.length!=3){
    return '--年--月--日'
  }
  return  dts[0]+'年'+dts[1]+'月'+dts[2]+'日'
}

/**
 * 休憩時間を算出する
 * 算出に失敗した場合はブランクのを返す
 * @param {Array.<string>} arrayRestTime [休憩開始, 休憩終了]
 * @return {string} 休憩工数
 */
 export function calcRestHours( arrayRestTime) {
  let restHour = '0.00'

  if (!arrayRestTime || arrayRestTime.length < 2)
    return restHour;

  let restStart1 = -1.0
  let restEnd1 = -1.0

  if (arrayRestTime[0]) {
    restStart1 = timeToSec(arrayRestTime[0])
  }
  if (arrayRestTime[1]) {
    restEnd1 = timeToSec(arrayRestTime[1])
  }

  let restSec = -1.0
  if (restStart1 !== -1 && restEnd1 !== -1 && restStart1 < restEnd1)
  {
    restSec = restEnd1 - restStart1
  }
  if (restSec !== -1.0) {
    restHour = formatHour(restSec)
  }
  
  return restHour
}

/**
 * 遅早時間を算出する
 * 算出に失敗した場合は0.00を返す
 * @param {string} shiftWorkStart シフト開始時間
 * @param {string} shiftWorkEnd シフト終了時間
 * @param {string} attendanceWorkStart 開始時間
 * @param {string} attendanceWorkEnd 終了時間
 * @return {string} 遅早時間
 */
export function calcEarlyLateTime(shiftWorkStart, shiftWorkEnd, attendanceWorkStart,attendanceWorkEnd){
  var ret = 0.00
  
  if (!shiftWorkStart || !shiftWorkEnd || !attendanceWorkStart || !attendanceWorkEnd) return ret;

  var attendanceWorkStartTime = timeToSec(attendanceWorkStart)
  var attendanceWorkEndTime = timeToSec(attendanceWorkEnd)
  var shiftWorkStartTime = timeToSec(shiftWorkStart)
  var shiftWorkEndTime = timeToSec(shiftWorkEnd)
  if (attendanceWorkStartTime > shiftWorkStart) ret += Math.abs(attendanceWorkStartTime - shiftWorkStartTime);
  if (attendanceWorkEndTime < shiftWorkEndTime) ret += Math.abs(shiftWorkEndTime - attendanceWorkEndTime);

  return parseFloat(formatHour(ret));
}