import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'

import { GetApi,MultipartApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
import FUNC from '../../Common/FunctionEnum'
import { createOrderInfo } from '../../Common/Utilties/ObjectUtitlity'

import MasterPanelWrap from '../Wrap/MasterPanelWrap'
import MasterFlexWrap from '../Wrap/MasterFlexWrap'
import MasterListView from '../Molcules/MasterListView'
import Search from './Search'
import OrderDetail from './OrderDetail'

import {onCheckOrder} from '../../Common/Utilties/ImportUtility'
import {onExportCsvFile,makeOrderCSV,makeOrderErrorCSV} from '../../Common/Utilties/ExportUtility'
import {calcWorkingRestFreeSeconds} from '../../Common/Utilties/LaborUtility'
import MasterSelect from '../MasterSelect'
export const DefaultUrl = '/api/masterManagement/order'

const Header = "受注ID,契約区分,契約区分名,会社ID,会社名,会社部門ID,会社部門名,顧客ID,顧客名,顧客部門ID,顧客部門名,"
+"受注日,契約開始日付,契約終了日付,業務名,業務内容詳細1,業務内容詳細2,出勤時間,退勤時間,休憩開始時間1,休憩終了時間1,休憩開始時間2,休憩終了時間2,休憩開始時間3,休憩終了時間3,"
+"給与形態,給与形態名,時間内支給,時間外支給,深夜支給,休日時間内支給,休日時間外支給,休日深夜支給,法休時間内支給,法休時間外支給,法休深夜支給,"
+"請求形態,請求形態名,時間内請求,時間外請求,深夜請求,休日時間内請求,休日時間外請求,休日深夜請求,法休時間内請求,法休時間外請求,法休深夜請求,部門別計算区分,料率区分,備考"
const Order = (props) => {
    const agreementDateRef = useRef()
    const [dataList, setDataList] = useState([])
    const [detail, setDetail] = useState(null) // 未選択状態
    const [selectedId, setSelectedId] = useState(null)
    const [searchOption, setSearchOption] = useState({ orderId: '', orderName: '' })
    const { loginUserInfo } = useSelector(state => state)
    const [pagenation, setPagenation] = useState({})
    const dispatch = useDispatch()
    const alert = useAlert()
    const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.ORDER)

    const [postCompanyList,setPostCompanyList] = useState(new Array())
    const [postCustomerList,setPostCustomerList] = useState(new Array())
    const [postComDepartmentList,setPostComDepartmentList] = useState(new Array())
    const [postCusDepartmentList,setPostCusDepartmentList] = useState(new Array())
    
    const [comPostList,setComPostList] = useState(new Array())
    const [comDepartmentList,setComDepartmentList] = useState(new Array())
    const [cusPostList,setCusPostList] = useState(new Array())
    const [cusDepartmentList,setCusDepartmentList] = useState(new Array())
    //会社・顧客検索処理
    const setPost=()=>{
      const serachCompany = async()=>{
        const result = await GetApi('/api/masterManagement/company/all')
        if (result.errorDetail) {
          ErrorHandling(result, alert)
          return null
        }
        const resultList = result.data.map(item => {
          // return {
          //   index: item.companyId,
          //   name: item.companyName+'('+item.companyId+')',
          // }
          return <><option value={item.companyId} >{item.companyName+'('+item.companyId+')'}</option></> 
        })
        var  companyList = new Array()
        var  comDepartmentList = new Array()
        const resultDepartmentList = result.data.map(item => {
          companyList.push(item.companyId)
          item.departments.map((deapartment)=>{
            comDepartmentList.push(deapartment.comDepartmentId)
          })
          return {
            index: item.companyId,
            name: item.companyName+'('+item.companyId+')',
            departments: item.departments,
            departmentFlag: item.departmentFlag,
          }
        })
        setPostComDepartmentList(resultDepartmentList)
        setPostCompanyList(resultList)
        setComPostList(companyList)
        setComDepartmentList(comDepartmentList)
        return true
      }
      const serachCustomer = async()=>{
        const result = await GetApi('/api/masterManagement/customer/all')
        if (result.errorDetail) {
          ErrorHandling(result, alert)
          return null
        } 
        const resultList = result.data.map(item => {
          // return {
          //   index: item.customerId,
          //   name: item.customerName+'('+item.customerId+')',
          // }
          return <><option value={item.customerId} >{item.customerName+'('+item.customerId+')'}</option></> 
        })
        var  customerList = new Array()
        var  cusDepartmentList = new Array()
        const resultDepartmentList = result.data.map(item => {
          customerList.push(item.customerId)
          item.departments.map((deapartment)=>{
            cusDepartmentList.push(deapartment.cusDepartmentId)
          })
          return {
            index: item.customerId,
            name: item.customerName+'('+item.customerId+')',
            departments: item.departments,
            departmentFlag: item.departmentFlag,
          }
        })
        setCusPostList(customerList)
        setCusDepartmentList(cusDepartmentList)
        setPostCusDepartmentList(resultDepartmentList)
        setPostCustomerList(resultList)
        return true
      }
      serachCompany()
      serachCustomer()
    }

    const selectItem = async (id) => {
      dispatch(SetLoadSpinner(true))
      setSelectedId(id)
      const result = await GetApi(`${DefaultUrl}/${id}`)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert) || setDetail(result.data)
    }

    // 新規登録用初期化処理
    const createNew = (e) => {
      setDetail(createOrderInfo()) // 新規作成時の値
      setSelectedId(null)
    }

    // 検索APIの実行
    const onSearch = async (createId, page) => {
      dispatch(SetLoadSpinner(true))
      setSearchOption(
        {
          agreementDt: agreementDateRef.current,
        }
      )
      const data = {
        agreementDt: agreementDateRef.current,
        page: page
      }
      const result = await GetApi(DefaultUrl, data)
      dispatch(SetLoadSpinner(false))
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else {
        const resultList = result.data.responseList.map(item => {
          return {
            index: item.orderId,
            id: item.orderId,
            name: item.occupation
          }
        })
        setDataList(resultList)
        setPagenation(result.data)
        setSelectedId(null)
        setDetail(null) // 未選択状態
      }
    }
    const onKeyDown = (event) => {
      if (event.keyCode === 13) {
        onSearch(null, 1)
      }
    }

    const pageSelectFunc = (pageId) => {
      onSearch(null, pageId)
    }

    useEffect(() => {
      if (grants)
        document.title = grants.functionMenuName + ' | Core Time Cloud'
      else
        document.title = 'Core Time Cloud'
    }, [grants])

    //画面表示の時
    useEffect(()=>{
      onSearch(null,1)
      setPost()
      onSchema()
    },[])
    //スキーマ取得処理
    const [schema,setSchema] = useState([])
    const onSchema=async()=>{
      const data = {
        tableName: 't_order',
      }
      const result = await GetApi('/api/schema', data)
      console.log(result)
      if (result.errorDetail) {
        return null
      } else{        
        setSchema(
          result.data.map((schema)=>{
            let fieldName = schema.columnName.replace(/_[a-z]/g, function(match){return match.toUpperCase().slice(1)});
            
            let [type,maxLength] = schema.dataType.split('(')
            if(maxLength)
            {
              maxLength = maxLength.slice(0,maxLength.length-1)
            }      

            return{
              fieldName:fieldName,
              notNull:schema.notNull,
              maxLength:maxLength,
              type:type,
              comment:schema.comment,
            }
          })
        )
      }
    }
    const onClickFunction=()=>{
      setViewFunction(!viewFunction)
    }
    const [viewFunction,setViewFunction] = useState(false)


    //ダウンロード処理
    const onExport=async()=>{
      dispatch(SetLoadSpinner(true))
      const data = {
        agreementDt: agreementDateRef.current,
        contractDivision: 0,
        postType: 2,
        postId: '',
        departmentId: '',
        page: 1
      }
      const result = await GetApi(DefaultUrl+'/export', data)
      console.log(result)
      dispatch(SetLoadSpinner(false))
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else{
        var today = new Date();
        var day = (''+today.getFullYear()).slice(-2) + ('00' + (today.getMonth())).slice(-2) + ('00' + today.getDate()).slice(-2)
                + ('00' + (today.getHours())).slice(-2) + ('00' + today.getMinutes()).slice(-2) + ('00' + today.getSeconds()).slice(-2)
        var fileName = '受注管理('+day+')'
        onExportCsvFile(makeOrderCSV(result.data,Header),fileName)
      }
    }
    //インポート処理
    const onImport=(uploadData)=>{
      async function sendPost (requestBody, data) {
        var result
        dispatch(SetLoadSpinner(true))
        console.log(data)
        result = 
          await MultipartApi(`${DefaultUrl}/import`,'POST', requestBody, data)
        
        if (!ErrorHandling(result, alert)) {
          alert.success('保存しました')
   
          onSearch(null,1)
        }else{
          dispatch(SetLoadSpinner(false))
        }
        
      }

      var uploadDataList= new Array();
      console.log(uploadData)
      uploadData.forEach((data)=>{
        let dataRow = {
          orderId: data["受注ID"], 
          orderDt: data["受注日"], 
          contractDivision: data["契約区分"], 
          agreementStartDt: data["契約開始日付"],
          agreementEndDt: data["契約終了日付"],
          
          companyId: data["会社ID"], 
          comDepartmentId: data["会社部門ID"], 
          customerId: data["顧客ID"], 
          cusDepartmentId: data["顧客部門ID"], 

          occupation: data["業務名"], 
          occupationDetail1: data["業務内容詳細1"], 
          occupationDetail2: data["業務内容詳細2"],

          workStartDt: data["出勤時間"],
          workEndDt: data["退勤時間"],

          restStart1Dt:data["休憩開始時間1"],
          restEnd1Dt: data["休憩終了時間1"],
          restStart2Dt: data["休憩開始時間2"],
          restEnd2Dt: data["休憩終了時間2"],
          restStart3Dt: data["休憩開始時間3"],
          restEnd3Dt: data["休憩終了時間3"],

          wagesType: data["給与形態"], 
          normalPrice: data["時間内支給"], 
          overPrice: data["時間外支給"], 
          nightPrice: data["深夜支給"], 
          holNormalPrice: data["休日時間内支給"], 
          holOverPrice: data["休日時間外支給"], 
          holNightPrice: data["休日深夜支給"], 
          lawNormalPrice: data["法休時間内支給"], 
          lawOverPrice: data["法休時間外支給"], 
          lawNightPrice: data["法休深夜支給"], 
          billingType: data["請求形態"], 
          normalBillingPrice: data["時間内請求"], 
          overBillingPrice: data["時間外請求"], 
          nightBillingPrice: data["深夜請求"], 
          holNormalBillingPrice: data["休日時間内請求"], 
          holOverBillingPrice: data["休日時間外請求"], 
          holNightBillingPrice: data["休日深夜請求"], 
          lawNormalBillingPrice: data["法休時間内請求"], 
          lawOverBillingPrice: data["法休時間外請求"], 
          lawNightBillingPrice: data["法休深夜請求"], 
          departmentCalcDivision: data["部門別計算区分"], 
          rateDivision: data["料率区分"], 

          remark: data["備考"], 

          contractDivisionName: data["契約区分名"], 
          companyName: data["会社名"], 
          comDepartmentName: data["会社部門名"], 
          customerName: data["顧客名"], 
          cusDepartmentName: data["顧客部門名"], 
          wagesTypeName: data["給与形態名"], 
          billingTypeName: data["請求形態名"], 

        }

        {
          let result = calcWorkingRestFreeSeconds([dataRow.workStartDt, dataRow.workEndDt], 
            [dataRow.restStart1Dt, dataRow.restEnd1Dt, dataRow.restStart2Dt, dataRow.restEnd2Dt, dataRow.restStart3Dt, dataRow.restEnd3Dt],28800)
          dataRow.workTotalDt = result[0]
          dataRow.restTotalDt = result[1]
        }
        uploadDataList.push(dataRow)
      })
      
      
      var errorList = new Array();
      [uploadDataList,errorList]=onCheckOrder(uploadDataList,schema,
        comPostList,comDepartmentList,cusPostList,cusDepartmentList)
      console.log(uploadDataList,errorList)
      if(errorList.length!=0)
      {
          alert.error(<>エラーレコードがあります。<br/>エラーレコードをCSV出力します。</>)
          var today = new Date();
          var day = (''+today.getFullYear()).slice(-2) + ('00' + (today.getMonth())).slice(-2) + ('00' + today.getDate()).slice(-2)
                  + ('00' + (today.getHours())).slice(-2) + ('00' + today.getMinutes()).slice(-2) + ('00' + today.getSeconds()).slice(-2)
          var fileName = '【Error】受注管理マスタデータ_'+day+''
          onExportCsvFile(makeOrderErrorCSV(errorList,Header),fileName)
      }
      
      if(uploadDataList.length==0)
      {
          alert.error("更新するデータがありません")
          return
      }

      const submitData = new window.FormData()
      submitData.append('formData', new window.Blob([JSON.stringify(uploadDataList)], { type: 'application/json' }))

      sendPost(submitData, uploadDataList)
    }
    if (!agreementDateRef.current) {
      var today = new Date();
      agreementDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-01'
    }
    if (!grants) return null
    return (
        <MasterPanelWrap id='OrderWrap'>
        <Search
          title={grants.functionMenuName}
          showButton
          onClick={() => onSearch(null, 1)}
          onKeyDown={(e) => onKeyDown(e)}
          agreementDateRef={agreementDateRef}
          grants={grants}
          viewFunction={viewFunction}
          onClickFunction={()=>onClickFunction()}
          onExport={()=>onExport()}
          onImport={(uploadData)=>onImport(uploadData)}
        >
        </Search>
        <MasterFlexWrap>
            <MasterSelect/>
            <MasterListView
              dataList={dataList}
              setSelectedId={setSelectedId}
              selectFunction={(id) => selectItem(id)}
              createFunction={() => createNew()}
              canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
              pageSelectFunc={pageSelectFunc}
              pagenation={pagenation}
              isIdView
            />
            <OrderDetail
              onSearch={onSearch}
              setSelectedId={setSelectedId}
              setDetail={setDetail}
              orderDetail={detail}
              postCustomerList={postCustomerList}
              postCompanyList={postCompanyList}
              postComDepartmentList={postComDepartmentList}
              postCusDepartmentList={postCusDepartmentList} 
            >
            </OrderDetail>
        </MasterFlexWrap>
        </MasterPanelWrap>
    )
}

export default Order
