import React,{useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { DailyTableModalWrap } from '../../Wrap/DailyTableWrap'

import DailyTableHeader from './DailyTableHeader'
import DailyTableBody from './DailyTableBody'

const DailyTable = ({
    register, getValues, setValue, watch, reset, errors,
    defaultUrl, dailyWorkInfoList, selectedIndex, selectFunction,workDivisions,handleOpenPunchHistoryFunc
  }) => {

  return (
    <>
    <DailyTableModalWrap visible={dailyWorkInfoList.length ? true : false}>
      <DailyTableHeader
      >
      </DailyTableHeader>
      <DailyTableBody
        register={register}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        reset={reset}
        errors={errors}
        workDivisions={workDivisions}
        defaultUrl={defaultUrl}
        dailyWorkInfoList={dailyWorkInfoList}
        handleOpenPunchHistoryFunc={handleOpenPunchHistoryFunc}
      >
      </DailyTableBody>
    </DailyTableModalWrap>
    </>
  )
}

DailyTable.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,
  workDivisions: PropTypes.array,
  defaultUrl: PropTypes.string,
  dailyWorkInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func,
  handleOpenPunchHistoryFunc: PropTypes.func,
}

export default DailyTable
