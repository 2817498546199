import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import { GetApi, exportErrorLog, forceLogout } from './Common/ApiAxios'
import ShiftIcon from '../image/menu-shift.png'
import ShiftBulkIcon from '../image/menu-shift-bulk.png'
import AttendanceIcon from '../image/menu-attendance.png'
import SalariedIcon from '../image/menu-salaried.png'
import SalesIcon from '../image/menu-sales.png'
import DashboardIcon from '../image/menu-dashboard.png'
import BiometricIcon from '../image/menu-biometric.png'
import MasterIcon from '../image/menu-master.png'
import LogiPCoreIcon from '../image/logi-p-core.png'
import CoreTimeIcon from '../image/symbol.png'
import CoreFirstIcon from '../image/corefirst.png'

import OverTimeIcon from '../image/over-time-icon.png'
import ContractIcon from '../image/contract-icon.png'

const MenuNav = styled.nav`
  display: none;
  animation-name: menu;
  animation-duration: .5s;
  animation-timing-function: ease;
  width: 350px;
  background: #cfe2f3;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  position: absolute;
  left 15px;
  top: 60px;
  z-index: 1300;

  @keyframes menu {
    from {
      opacity: 0;
      transform: translateX(0);
    }
    
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`
const MenuContentWrap  = styled.div`
  position: relative;
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  width: 330px;
  margin-top: 10px;
  margin-bottom: 10px;
`

const SubMenuContentWrap = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* 幅3等分 */
  grid-template-rows: 60px 60px 60px; /* 60pxの3行 */
  grid-gap: 5px; /* 隙間5px */
`

const NavigatorItemContent  = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  width: 110px;
  font-size: 14px;
`

const NavigatorItem = (props) => {
  const {
    functionItem,
    onClick,
  } = props
  return (
    <NavigatorItemContent>
        { functionItem.data.functionMenuId !='2030101001' &&
          <Tooltip title={functionItem.data.functionMenuName}>
            <Link key={functionItem.data.functionMenuId} to={functionItem.linkTo} data-service-menu-id={functionItem.data.functionMenuId} onClick={onClick} >
              <img src={functionItem.img} width="32px" height="32px" />
              <div>{functionItem.data.functionMenuName}</div>
            </Link>
          </Tooltip>
        }
        {/* 売上管理Close */}
        { functionItem.data.functionMenuId =='2030101001' &&
          <div>
            <img src={functionItem.img} width="32px" height="32px" />
            <div>{functionItem.data.functionMenuName}</div>
          </div>
        }
    </NavigatorItemContent>
  )
}

NavigatorItem.propTypes = {
  functionItem: PropTypes.object,
  onClick: PropTypes.func
}

// 他サービスへ遷移する項目
const OtherServiceItem = (props) => {
  const {
    functionItem,
    onClick,
  } = props
  return (
    <NavigatorItemContent style={{cursor:'pointer'}}>
      <Tooltip title={functionItem.functionName}>
        <div  onClick={onClick}>
          <img src={functionItem.img} width="32px" height="32px" />
          <div>{functionItem.functionName}</div>
        </div>
      </Tooltip>
    </NavigatorItemContent>
  )
}

const Navigator = (props) => {
  const [serviceList, setServiceList] = useState([])
  const loginUserInfo = useSelector(state => state.loginUserInfo)
  const [functionList, setFunctionList] = useState([])
  const [masterFunctionList, setMasterFunctionList] = useState([])

  // メニューリスト
  async function getMenuList (cleanedUp) {
    const apiUrl = '/api/menuList'
    const grantNameId = loginUserInfo.grantNameId
    const data = { grantNameId }
    const result = await GetApi(apiUrl, data)
    console.log(result)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
          forceLogout()
      } else {
          alert('情報取得に失敗しました。')
      }
      window.location.href = '/login'
    } else {
      setServiceList(result.data)
      
      console.log(result.data)
      var list1 = [];
      result.data.map(service => {
        service.manageMenuList.map(manage => {
          manage.functionMenuList.map(item => {
            //console.log(item.functionMenuId);
            switch (item.functionMenuId) {
              case '2020201001':
                list1.push({ data:item, linkTo: '/attendance', img: AttendanceIcon, sort:0 });
                break;
              case '2020101001':
                list1.push({ data:item, linkTo: '/shift', img: ShiftIcon, sort:1 });
                break;
              case '2020102001':
                list1.push({ data:item, linkTo: '/shiftBulk', img: ShiftBulkIcon, sort:2 });
                break;
              case '2020301001':
                list1.push({ data:item, linkTo: '/salaried', img: SalariedIcon, sort:3 });
                break;
              case '2030101001':
                list1.push({ data:item, linkTo: '/sales', img: SalesIcon, sort:4 });
                break;
              case '2050101001':
                list1.push({ data:item, linkTo: '/dashboard', img: DashboardIcon, sort:5 });
                break;
              case '2040101002':
                list1.push({ data:item, linkTo: '/staffBiometric', img: BiometricIcon, sort:6 });
                break;
              case '2020401001':
                list1.push({ data:item, linkTo: '/overTimeWork', img: OverTimeIcon, sort:7 });
                break;
              case '2020501001':
                list1.push({ data:item, linkTo: '/employmentContract', img: ContractIcon, sort:8 });
                break;
              //マスタ管理
              case '2042201001':
                list1.push({ data:item, linkTo: '/Master', img: MasterIcon, sort:9 });
                break;
            }
          })
        })
      })
      console.log(list1);
      if (list1.length > 0)
        list1.sort(function(a, b){ return a.sort - b.sort; });
      setFunctionList(list1);

      var list2 = [];
      result.data.map(service => {
        service.manageMenuList.map(manage => {
          manage.functionMenuList.map(item => {
            switch (item.functionMenuId) {
              case '2040101001':
                list2.push({ data:item, linkTo: '/master/staff', img: MasterIcon, sort:0 });
                break;
              case '2040201001':
                list2.push({ data:item, linkTo: '/master/company', img: MasterIcon, sort:1 });
                break;
              case '2040301001':
                list2.push({ data:item, linkTo: '/master/customer', img: MasterIcon, sort:2 });
                break;
              case '2040401001':
                list2.push({ data:item, linkTo: '/master/order', img: MasterIcon, sort:3 });
                break;
              case '2040501001':
                list2.push({ data:item, linkTo: '/master/allocation', img: MasterIcon, sort:4 });
                break;
              case '2040601001':
                list2.push({ data:item, linkTo: '/master/grant', img: MasterIcon, sort:5 });
                break;
              case '2040901001':
                list2.push({ data:item, linkTo: '/master/calendar', img: MasterIcon, sort:6 });
                break;
            }
          })
        })
      })
      if (list2.length > 0)
        list2.sort(function(a, b){ return a.sort - b.sort; });
      setMasterFunctionList(list2);
    }
  }

  useEffect(() => {
    if (loginUserInfo.grantNameId) {
        getMenuList()
    }
  // ログインユーザー情報の変更検知時のみ実行する
  // eslint-disable-next-line
  }, [loginUserInfo])

  const showMenu = () => {
    //props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
    props.onCloseMenu();
  }

  const showLogiPCoreMenu = () => {
    //props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
    props.onCloseMenu();
    window.open('https://input.pal-style.co.jp/dashboard/', '_blank');
  }
  
  const showCoreFirstMenu = () => {
    //props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
    props.onCloseMenu();
    window.open('https://core-first-demo.pal-style.co.jp/whiteboard/positioning/top', '_blank');
  }
  
  const showCoreBoardMenu = () => {
    //props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
    props.onCloseMenu();
    window.open('https://coreboard-front-dev.pal-style.co.jp/top', '_blank');
  }

  const closeMenu = () => {
    //props.setShowFlg('block');
    props.onCloseMenu();
  }

  return (
  <MenuNav className='menu' style={{ display: props.showFlg }} onMouseLeave={closeMenu} >
      <MenuContentWrap>
        <SubMenuContentWrap>

          {!props.topPageFlg && 
          <>
          {functionList && functionList.map(item => (
            <NavigatorItem key={item.data.functionMenuName} functionItem={item} onClick={closeMenu} />
          ))}
          </>
          }
          {props.topPageFlg && 
          <>
            <OtherServiceItem key={'Logi P Core'} functionItem={{functionName:'Logi P Core', img:LogiPCoreIcon}} onClick={showLogiPCoreMenu}></OtherServiceItem>
            <OtherServiceItem key={'位置測位'} functionItem={{functionName:'位置測位', img:CoreFirstIcon}} onClick={showCoreFirstMenu}></OtherServiceItem>
            <OtherServiceItem key={'CoreBoard'} functionItem={{functionName:'CoreBoard', img:CoreTimeIcon}} onClick={showCoreBoardMenu}></OtherServiceItem>
          </>
          }
        </SubMenuContentWrap>
        {/* <hr/>
        <SubMenuContentWrap>
          {masterFunctionList && masterFunctionList.map(item => (
            <NavigatorItem functionItem={item} onClick={showMenu} />
          ))}
        </SubMenuContentWrap> */}
      </MenuContentWrap>
  </MenuNav>
  )
}

Navigator.propTypes = {
  showFlg: PropTypes.string,
  setShowFlg: PropTypes.func,
  topPageFlg: PropTypes.bool,
  onCloseMenu: PropTypes.func,
}

export default Navigator
