import styled from 'styled-components'

export const RowHeaderTop = styled.tr`
  background-color: #649cdf;
`

export const RowHeaderBottom = styled.tr`
  background-color: #FFE8E6;
`

export const ColumnStaffTH = styled.th`
  width: 13rem;
  vertical-align:middle;
`

export const ColumnWorkTH = styled.th`
  width: 4rem;
  vertical-align:middle;
`

export const ColumnWorkCell = styled.div`
  width: 3rem;
  vertical-align:middle;
`

export const ColumnTimeTH = styled.th`
  width: 5rem;
  vertical-align:middle;
`

export const ColumnTimeCell = styled.div`
  width: 5rem;
  vertical-align:middle;
`

export const ColumnStaffTD = styled.td`
  font-size:16px;
  width: 13rem;
  max-width: 13rem;
  vertical-align:middle;
  padding-left:1rem;
  height: 2rem;
  /* 未切れ対策 */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* end*/
`

export const ColumnWorkTD = styled.td`
  width: 3rem;
  vertical-align: middle;
  text-align: right;
`

export const ColumnWorkDiv = styled.div`
  width: 3rem;
  vertical-align: middle;
  text-align: right;
  height 1rem;
`

export const ColumnWorkCellTD = styled.td`
  width: 3rem;
  vertical-align: middle;
  text-align: right;
  padding-right: 1rem;
  height 1rem;
`

export const ColumnTimeTD = styled.td`
  width: 4rem;
  vertical-align: middle;
  text-align: right;
`


const MonthTableWrap = styled.table`
  font-size: 12px;
  border-collapse:separate;
  border-spacing: 1px;
  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(22rem + 2px + 16px + ${props => props.width})
  }

  tbody {
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(78vh - 6rem - 3rem - 4rem);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
    line-height:14px;
  }

  td {
    line-height:16px;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

export default MonthTableWrap
