import React from 'react'
//import styled from 'styled-components'
import PropTypes from 'prop-types'
import TableWrap from '../../Wrap/StaffBiometricTableWrap'
import TableHeader from './StaffBiometricTableHeader'
import TableBody from './StaffBiometricTableBody'
// import DailyTableBody from './DailyTableBody'

const StaffBiometricTable = ({
    register, getValues, setValue, watch, reset, errors,
    defaultUrl, workInfoList, selectedIndex, selectFunction,
    Reserch
  }) => {
    //
  return (
    <TableWrap visible={workInfoList.length ? true : false}>
      <TableHeader
      >
      </TableHeader>
      <TableBody
        staffInfoList={workInfoList}
        defaultUrl={defaultUrl}
        Reserch={Reserch}
      >
      </TableBody> 
    </TableWrap>
  )
}

StaffBiometricTable.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  Reserch: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func
}

export default StaffBiometricTable