import React, { useState, useEffect, useRef ,useCallback} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import { useHistory } from 'react-router'
import { Prompt } from 'react-router-dom';
import { GetApi ,MultipartApi} from '../Common/ApiAxios'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../Common/ErrorHandling'
import FUNC from '../Common/FunctionEnum'
import AttendancePanelWrap, { AttendanceFlexWrap,AttendanceCountPanelWrap,AttendancePagingPanelWrap,PagingIconImg,AttendancePagingIconPanelWrap,PagingIconNextImg,AttendancePageButtonWrap } from './Wrap/AttendancePanelWrap'

import Search from './Search'

import IconPageNextImg from '../../image/PageNext.png'
import IconPageBackImg from '../../image/PageBack.png'
import IconPageLastImg from '../../image/PageLast.png'
import IconPageTopImg from '../../image/PageTop.png'

import DailyTable from './Molcules/DailyTable/DailyTable'

export const DefaultUrl = '/api/employManagement/employ'

const EmploymentContract = (props) => {
  const fromDateRef = useRef()
  const toDateRef = useRef()
  const staffIdRef = useRef()
  const staffNameRef = useRef()
  const [searchOption, setSearchOption] = useState({ shiftDt: '', staffId: '', staffName: '',postType:'',postId:'',departmentId:'' })
  const { loginUserInfo } = useSelector(state => state)
  const [employmentList, setEmploymentList] = useState([])
  const [staffWorkInfoAllList, setStaffWorkInfoAllList] = useState([])
  const [dateDataList, setDateDataList] = useState([])
  const [pagenation, setPagenation] = useState({})
  const [staffCount, setStaffCount] = useState(0)
  const dispatch = useDispatch()
  const alert = useAlert()
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.EMPLOYMENT_CONTRACT)
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [viewFunction,setViewFunction] = useState(false)
  const pageRecord = 12
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()
  
  const [staffTypeOptions, setStaffTypeOptions] = useState([])

  // 検索APIの実行
  const onSearch = async (createId, page) => {
    dispatch(SetLoadSpinner(true))

    setSearchOption(
      {
        startDt: fromDateRef.current,
        endDt: toDateRef.current,
        staffId: (staffIdRef.current)?staffIdRef.current.value:'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        postType:postType,
        postId:postId,
        departmentId:departmentId
      }
    )
    const data = {
      startDt: fromDateRef.current,
      endDt: toDateRef.current,
      staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      page: page
    }

    const resultAttendance = await GetApi(DefaultUrl, data)
    console.log(resultAttendance)
    if (resultAttendance.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(resultAttendance, alert)
      return null
    } else {

      setStaffWorkInfoAllList(resultAttendance.data.slice())
      var count = resultAttendance.data.length

      setStaffCount(count)
      setPageLast(Math.ceil(count/pageRecord))
      console.log(page)
      setPageNum(page)
      setPagenation(resultAttendance.data)
      var tmp = resultAttendance.data.slice()
      makePage(tmp,page)
    }
    dispatch(SetLoadSpinner(false))
  }
  //ページング設定
  const makePage = (data,page) =>{
    if(page !== 0){
      //console.log(data)
      //console.log(page)
      var minRecord = (page-1)*pageRecord
      var maxRecord = (page*pageRecord)
      //console.log(minRecord,maxRecord)
      var tmpCount = 0
      var tmpList = new Array()
      data.forEach((staffWorkInfo)=>{
        if(tmpCount>=minRecord && tmpCount<maxRecord){
          tmpList.push(staffWorkInfo)
        }
        tmpCount+=1
      })
      setEmploymentList(tmpList)
      //console.log(tmpList)
    }else{
      setEmploymentList(data)
    }
    
  }

  const [actionType, setActionType] = useState('POST')
  // POST・PUT処理
  const onSubmit = (data) => {
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }

  const pageSelectFunc = (pageId) => {
    onSearch(null, pageId)
  }
  
  const updatedAttendanceFunc = () => {
    onSearch(null, pageNum)
  }

  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud'
    else
      document.title = 'Core Time Cloud'
  }, [grants])

  useEffect(() => {
    setPost()
    onSearch(null,1)
    getStaffType()
  }, [])


  // スタッフ区分取得
  const getStaffType = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/staffType')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setStaffTypeOptions(options)
    }
  }, [setStaffTypeOptions, alert])


  const setPost=()=>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      }
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.companyId,
          name: item.companyName+'('+item.companyId+')',
        }
      })
      setPostList(resultList)
      return true
    }
    const serachCustomer = async()=>{
      const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } 
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.customerId,
          name: item.customerName+'('+item.customerId+')',
        }
      })
      
      setPostList(resultList)
      return true
    }
    if(postType==0){
      serachCompany()
    }else{
      serachCustomer()
    }
  }
  
  //会社顧客変更処理
  useEffect(() => {
    setPost()
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postType])
  
  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
    console.log(viewSubSearch)
  }
  
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  const onPageChangeLabelClick = () => {
    // 機能メニューを非表示に変更
    setViewFunction(false);
    if(pageType==0){
      setPageType(1)
      setPageNum(0)
      makePage(staffWorkInfoAllList,0)
    }else{
      setPageType(0)
      setPageNum(1)
      makePage(staffWorkInfoAllList,1)
    }
  }
  
  //ページ遷移前の更新破棄の確認
  //更新キャンセル処理
  useEffect(() => {
    // イベントの設定
    window.addEventListener('beforeunload', onUnload);
  
    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload);
    }
  })
  const onUnload = (e) => {
    var updateFlag = false
    if(updateFlag){
      e.preventDefault();
      e.returnValue = '';
    }
  }
  const onPageSeletClick = (pageId) => {
    console.log(pageId)
    //onSearch(null,pageId)
    makePage(staffWorkInfoAllList,pageId)
    setPageNum(pageId)
  }
  const onPageNextSeletClick = (pageId) => {
    if(pageNum!=pageLast){
      onPageSeletClick(pageId)
    }
    //setStaffWorkInfoList(staffWorkInfoAllList)
    //makePage(new Array(),pageId)
  }
  const onPageBackSeletClick = (pageId) => {
    if(pageNum!=1){
      onPageSeletClick(pageId)
    }
    //console.log(staffWorkInfoAllList)
    //setStaffWorkInfoList(staffWorkInfoAllList)
    //makePage(new Array(),pageId)
  }

  if (!fromDateRef.current) {
    var today = new Date();
    fromDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-01'
    toDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-01'
  }

  if (!grants) return null
  return (
      <AttendancePanelWrap id='AttendanceWrap'>
        <Search
          title= {grants.functionMenuName}
          onClick={() => onSearch(null, pageType==0?1:0)}
          onClickOption={(e) => onClickOptionBtn(e)}
          onKeyDown={(e) => onKeyDown(e)}
          fromDateRef={fromDateRef}
          toDateRef={toDateRef}
          staffIdRef={staffIdRef}
          staffNameRef={staffNameRef}
          viewSubSearch={viewSubSearch}
          grants={grants}
          postList={postList}
          postId={postId}
          setPostId={setPostId}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          postType={postType}
          setPostType={setPostType}
          onClickFunction={(e)=>onClickFunction(e)}
          viewFunction={viewFunction}
          onPageTypeChangeClick={() => onPageChangeLabelClick()}
        >
        </Search>
        <AttendanceCountPanelWrap>
          <label>検索ヒット件数:{staffCount}件</label>
        </AttendanceCountPanelWrap>
        <AttendanceFlexWrap>
            <DailyTable
              register={register}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              reset={reset}
              errors={errors}

              workDivisions={staffTypeOptions}
              dateDataList={dateDataList}
              staffWorkInfoList={employmentList}
              updatedAttendanceFunc={updatedAttendanceFunc}
              defaultUrl={DefaultUrl}
              restFlag={true}
            >
            </DailyTable>
        </AttendanceFlexWrap>
        {
          employmentList.length>0 &&
          <AttendancePagingPanelWrap>
          { pageType==0 &&
            <AttendancePageButtonWrap>       
            <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
            <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
            <AttendancePagingIconPanelWrap>{pageNum}</AttendancePagingIconPanelWrap>
            <AttendancePagingIconPanelWrap>/</AttendancePagingIconPanelWrap>
            <AttendancePagingIconPanelWrap>{pageLast}</AttendancePagingIconPanelWrap>
            <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
            <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
            
            </AttendancePageButtonWrap>
          }
          </AttendancePagingPanelWrap>
        }
      </AttendancePanelWrap>
  )
}

export default EmploymentContract
