import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useAlert } from 'react-alert'
import Tooltip from '@material-ui/core/Tooltip'

import { InputGroup, SelectGroup } from '../../../Common/CommonInputGroup'
import Button from '../../../Common/Atoms/NormalButtonAttendance'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
import { diff48Time, formatHour, makeElementIdByDailyWorkInfo, calcWorkingHours ,formatRound48Time,calcWorkingRestFreeHours} from '../../../Common/Utilties/LaborUtility'

import { ColumnStaffTD, ColumnOccupationTD, ColumnDateTD, ColumnWorkTypeTD, ColumnTimeTD, ColumnRemarkTD, ColumnButtonsTD } from '../../Wrap/DailyTableWrap'
//import { min } from '../../../Common/Regex'
import RestDialog from '../AttendanceRestDialog/RestModel'

const DailyTableRow = ({ 
    register, getValues, setValue, watch, reset, errors, 
    workDivisions, dateDataList, dailyWorkInfo, selectFunction,
    handleOpenPunchHistoryFunc, handleClosePunchHistoryFunc, handleOpenDailyAttendanceFunc, handleCloseDailyAttendanceFunc,restFlag }) => {

  const elementId = makeElementIdByDailyWorkInfo(dailyWorkInfo.workInfoList[0])
  const [workDivision, setWorkDivision] = useState(0)
  const alert = useAlert()
  const findDateInfo = (dateList, date) => {
    var day = undefined
    dateList.forEach(dateInfo => {
        if (dateInfo.dateValue === date) {
            day = dateInfo
        }
    })

    return day
  }

  // 勤怠情報切り替え時
  useEffect(() => {
    // 勤怠情報が変更された場合のみ実行
    if (dailyWorkInfo.workInfoList) {
      if (dailyWorkInfo.workInfoList.length === 1) {
        var workInfo = dailyWorkInfo.workInfoList[0]
        setValue('orderId_' + elementId, workInfo.orderId)
        setValue('staffId_' + elementId, workInfo.staffId)
        setValue('baseDt_' + elementId, workInfo.baseDt)
        setValue('attendanceUpdatedAt_' + elementId, workInfo.attendanceUpdatedAt)
    
        setValue('attendanceWorkDivision_' + elementId, workInfo.attendanceWorkDivision)
        if(!workInfo.isDiff){
          setValue('attendanceWorkStartDt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceWorkStartDt))
          setValue('attendanceWorkEndDt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceWorkEndDt))
        }
        else{
          setValue('attendanceWorkStartDt_' + elementId, (workInfo.baseDt, workInfo.attendanceWorkStartDt))
          setValue('attendanceWorkEndDt_' + elementId, (workInfo.baseDt, workInfo.attendanceWorkEndDt))
        }
        setValue('attendanceWorkTotalDt_' + elementId, formatHour(workInfo.attendanceWorkTotalDt))
        setValue('attendanceRestTotalDt_' + elementId, formatHour(workInfo.attendanceRestTotalDt))

        let overTotal = 0.0
        if (workInfo.attendanceWorkTotalDt > workInfo.attendanceBaseTime)
          overTotal = workInfo.attendanceWorkTotalDt - workInfo.attendanceBaseTime
        setValue('attendanceOverTotalDt_' + elementId, formatHour(overTotal))

        if(workInfo.restFlag){
          let restStartList = new Array()
          let restEndList = new Array()
  
          workInfo.restList.map((restData)=>{
            if(restData.restNum==null) return
            restStartList.push(restData.attendanceRestStartDt)
            restEndList.push(restData.attendanceRestEndDt)
            if(restData.isDiff==undefined){
              restData.attendanceRestStartDt=diff48Time(workInfo.baseDt,restData.attendanceRestStartDt)
              restData.attendanceRestEndDt=diff48Time(workInfo.baseDt,restData.attendanceRestEndDt)
            }
          })
          for(var i=restStartList.length;i<3;i++){
            restStartList.push('')
          }
          for(var i=restEndList.length;i<3;i++){
            restEndList.push('')
          }

          if(!workInfo.isDiff){
            setValue('attendanceRestStart1Dt_' + elementId, diff48Time(workInfo.baseDt, restStartList[0]))
            setValue('attendanceRestEnd1Dt_' + elementId, diff48Time(workInfo.baseDt, restEndList[0]))
            setValue('attendanceRestStart2Dt_' + elementId, diff48Time(workInfo.baseDt, restStartList[1]))
            setValue('attendanceRestEnd2Dt_' + elementId, diff48Time(workInfo.baseDt, restEndList[1]))
            setValue('attendanceRestStart3Dt_' + elementId, diff48Time(workInfo.baseDt, restStartList[2]))
            setValue('attendanceRestEnd3Dt_' + elementId, diff48Time(workInfo.baseDt, restEndList[2]))
          }
          else{
            setValue('attendanceRestStart1Dt_' + elementId, (workInfo.baseDt, restStartList[0]))
            setValue('attendanceRestEnd1Dt_' + elementId, (workInfo.baseDt, restEndList[0]))
            setValue('attendanceRestStart2Dt_' + elementId, (workInfo.baseDt, restStartList[1]))
            setValue('attendanceRestEnd2Dt_' + elementId, (workInfo.baseDt, restEndList[1]))
            setValue('attendanceRestStart3Dt_' + elementId, (workInfo.baseDt, restStartList[2]))
            setValue('attendanceRestEnd3Dt_' + elementId, (workInfo.baseDt, restEndList[2]))
          }
        }else{
          if(!workInfo.isDiff){
            setValue('attendanceRestStart1Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart1Dt))
            setValue('attendanceRestEnd1Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd1Dt))
            setValue('attendanceRestStart2Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart2Dt))
            setValue('attendanceRestEnd2Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd2Dt))
            setValue('attendanceRestStart3Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart3Dt))
            setValue('attendanceRestEnd3Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd3Dt))
          }
          else{
            setValue('attendanceRestStart1Dt_' + elementId, (workInfo.baseDt, workInfo.attendanceRestStart1Dt))
            setValue('attendanceRestEnd1Dt_' + elementId, (workInfo.baseDt, workInfo.attendanceRestEnd1Dt))
            setValue('attendanceRestStart2Dt_' + elementId, (workInfo.baseDt, workInfo.attendanceRestStart2Dt))
            setValue('attendanceRestEnd2Dt_' + elementId, (workInfo.baseDt, workInfo.attendanceRestEnd2Dt))
            setValue('attendanceRestStart3Dt_' + elementId, (workInfo.baseDt, workInfo.attendanceRestStart3Dt))
            setValue('attendanceRestEnd3Dt_' + elementId, (workInfo.baseDt, workInfo.attendanceRestEnd3Dt))
          }
        }


        setValue('attendanceRemark_' + elementId, workInfo.attendanceRemark)

        setWorkDivision(workInfo.attendanceWorkDivision)
      }
      else if (dailyWorkInfo.workInfoList.length > 1) {
        let workTotal = 0.0
        let restTotal = 0.0
        let overTotal = 0.0

        let attendanceWorkDivision = 0
        
        let minStartDt = dailyWorkInfo.workInfoList[0].attendanceWorkStartDt
        let maxEndDt = dailyWorkInfo.workInfoList[0].attendanceWorkEndDt

        let restStartList = new Array()
        let restEndList = new Array()

        dailyWorkInfo.workInfoList.map((workInfo) => {
          workTotal += workInfo.attendanceWorkTotalDt
          restTotal += workInfo.attendanceRestTotalDt
          if (workInfo.attendanceWorkTotalDt > workInfo.attendanceBaseTime) {
            overTotal += workInfo.attendanceWorkTotalDt - workInfo.attendanceBaseTime
          }
          
          if(workInfo.attendanceWorkStartDt && minStartDt>workInfo.attendanceWorkStartDt)  minStartDt = workInfo.attendanceWorkStartDt
          if(workInfo.attendanceWorkEndDt && maxEndDt<workInfo.attendanceWorkEndDt)  maxEndDt = workInfo.attendanceWorkEndDt

          if(restFlag){
            if(workInfo.attendanceRestStart1Dt) {
              restStartList.push(workInfo.attendanceRestStart1Dt)
              restEndList.push(workInfo.attendanceRestEnd1Dt)
            }
            if(workInfo.attendanceRestStart2Dt) {
              restStartList.push(workInfo.attendanceRestStart2Dt)
              restEndList.push(workInfo.attendanceRestEnd2Dt)
            }
            if(workInfo.attendanceRestStart3Dt) {
              restStartList.push(workInfo.attendanceRestStart3Dt)
              restEndList.push(workInfo.attendanceRestEnd3Dt)
            }
          }else{
            workInfo.restList.map((restData)=>{
              if(restData.restNum==null) return
              restStartList.push(restData.attendanceRestStartDt)
              restEndList.push(restData.attendanceRestEndDt)
              if(restData.isDiff==undefined){
                restData.attendanceRestStartDt=diff48Time(workInfo.baseDt,restData.attendanceRestStartDt)
                restData.attendanceRestEndDt=diff48Time(workInfo.baseDt,restData.attendanceRestEndDt)
              }
            })
          }
            
          if(attendanceWorkDivision==0 && workInfo.attendanceWorkDivision!=0){ 
            attendanceWorkDivision= workInfo.attendanceWorkDivision
          }else if(!(2 > workInfo.attendanceWorkDivision || workInfo.attendanceWorkDivision > 4)){
            attendanceWorkDivision = workInfo.attendanceWorkDivision
          }
        });

        for(var i=restStartList.length;i<3;i++){
          restStartList.push('')
        }
        for(var i=restEndList.length;i<3;i++){
          restEndList.push('')
        }
        
        setValue('attendanceWorkDivision_' + elementId, attendanceWorkDivision)
        setWorkDivision(attendanceWorkDivision)

        setValue('attendanceWorkStartDt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, minStartDt))
        setValue('attendanceWorkEndDt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, maxEndDt))
        setValue('attendanceRestStart1Dt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, restStartList[0]))
        setValue('attendanceRestEnd1Dt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, restEndList[0]))
        setValue('attendanceRestStart2Dt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt,restStartList[1]))
        setValue('attendanceRestEnd2Dt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, restEndList[1]))
        setValue('attendanceRestStart3Dt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, restStartList[2]))
        setValue('attendanceRestEnd3Dt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, restEndList[2]))

        setValue('attendanceWorkTotalDt_' + elementId, formatHour(workTotal))
        setValue('attendanceRestTotalDt_' + elementId, formatHour(restTotal))
        setValue('attendanceOverTotalDt_' + elementId, formatHour(overTotal))
      }
    }
  // eslint-disable-next-line
  }, [dailyWorkInfo])

  const formatDate = (dateInfo) => {
    var date = new Date(dateInfo.dateValue);
    return ('00' + (date.getMonth() + 1)).slice(-2) + '/' + ('00' + date.getDate()).slice(-2)
  }

  const weekColor = (dateInfo) => {
    switch(dateInfo && dateInfo.publicHoliday) {
        case '0':
            switch(dateInfo.week)
            {
                case '土':
                    return '#00F'
                case '日':
                    return '#F00'
            }
            return '#000'
        case '1':
            return '#F00'
    }

    return '#000'
  }

  const createElementStaff = (staff) => {
    if (staff) {
      let text = staff.staffName
      let textPx = measurePxText(text)
      let textRem = convertPxToRem(textPx)
  
      let checkRem = 7
      if (textRem < checkRem)
      {
        return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...')
        textRem = convertPxToRem(textPx)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></span></Tooltip>
    }

    return <><br/><span>( - )</span></>
  }

  const createElementOccupation = (workInfoList) => {
    if (workInfoList && workInfoList.length === 1) {
      let attendanceInfo = workInfoList[0]
      let text = attendanceInfo.occupation
      let textPx = measurePxText(text)
      let textRem = convertPxToRem(textPx)
  
      let checkRem = 7
      if (textRem < checkRem)
      {
        return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({attendanceInfo.orderId})</span></>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...')
        textRem = convertPxToRem(textPx)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({attendanceInfo.orderId})</span></span></Tooltip>
    }

    return <><br/><span>( - )</span></>
  }

  const createElementDate = (dateList, date) => {
    if (!dateList || dateList.length === 0 || !date) {
        return <></>
    }

    var dateInfo = findDateInfo(dateList, date)
    if (!dateInfo) {
        return <></>
    }

    if (!dateInfo.note)
    {
      return <>
        {formatDate(dateInfo)} (<span style={{ color: weekColor(dateInfo) }}>{dateInfo.week}</span>)
      </>
    }

    return <Tooltip title={dateInfo.note}>
             <span>{formatDate(dateInfo)} (<span style={{ color: weekColor(dateInfo) }}>{dateInfo.week}</span>)</span>
           </Tooltip>
  }

  const createElementWorkType = (workInfoList) => {
    if (workInfoList && workInfoList.length === 1) {
        return <>
               <SelectGroup
                 name={'attendanceWorkDivision_' + elementId}
                 width='6rem'
                 margin='auto'
                 options={workDivisions}
                 register={register}
                 errors={errors}
                 onChange={onChange}
               />
               </>
    }else if(workInfoList){
      return <>
      <SelectGroup
        name={'attendanceWorkDivision_' + elementId}
        width='6rem'
        margin='auto'
        options={workDivisions}
        register={register}
        errors={errors}
        disabled={true}
      />
      </>
}
    
    return <></>
  }

  const createElementWorkTime = (workInfoList) => {
    if (workInfoList && workInfoList.length >= 1) {
        return <>
            <InputGroup
                name={'attendanceWorkTotalDt_' + elementId}
                width='3.5rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={true}
            />
           </>
    }

    return <></>
  }

  const createElementRestTime = (workInfoList) => {
    if (workInfoList && workInfoList.length >= 1) {
        return <>
            <InputGroup
                name={'attendanceRestTotalDt_' + elementId}
                width='3.5rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={true}
            />
           </>
    }

    return <></>
  }

  const createElementOverTime = (workInfoList) => {
    if (workInfoList && workInfoList.length >= 1) {
        return <>
            <InputGroup
                name={'attendanceOverTotalDt_' + elementId}
                width='3.5rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={true}
            />
           </>
    }

    return <></>
  }

  const createElementTime = (prefix, workInfoList) => {
    var rest = prefix.indexOf('Rest')
    if (workInfoList && workInfoList.length === 1 && (rest==-1 || !workInfoList[0].restFlag)) {
        return <>
            <InputGroup
                name={prefix + elementId}
                width='3.5rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                onBlur={onBlurTime}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={(2 > workDivision || workDivision > 4)}
            />
           </>
    }else if(workInfoList || rest>=0){
          return <>
            <InputGroup
                name={prefix + elementId}
                width='3.5rem'
                margin='auto'
                placeholder=''
                errors={errors}
                register={register}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={true}
            />
           </>
    }

    return <></>
  }

  const createElementButtons = (workInfoList) => {
    if (workInfoList && workInfoList.length === 1) {
        return <>
            <Button margin='auto' padding='0' width='3rem' name='履歴' onClick={() => onShowPunchHistoryModal(workInfoList[0])}/>
           </>
    }
    else if (workInfoList && workInfoList.length > 1) {
        return <>
            <Button margin='auto' padding='0' width='3rem' name='詳細' onClick={() => onShowDailyModal(workInfoList)}/>
           </>
    }

    return <></>
  }

  const onShowPunchHistoryModal = (workInfo) => {
    handleOpenPunchHistoryFunc(workInfo)
  }

  const onShowDailyModal = (workInfoList) => {
    handleOpenDailyAttendanceFunc(workInfoList)
  }

  const onBlurTime = ( event ) => {
    let value = event.target.value
    //日時の分単位を指定分で丸める
    let fractionUnit = parseInt(dailyWorkInfo.workInfoList[0].attendanceFractionUnit)
    //value = format48Time(value)
    let name = event.target.name 
    if(name.indexOf('WorkEnd') != -1 || name.indexOf('RestStart') != -1)
    {
      value = formatRound48Time(value,fractionUnit*(-1))
    }
    else
    {
      value = formatRound48Time(value,fractionUnit)
    }
    event.target.value = value

    let workStart = getValues("attendanceWorkStartDt_" + elementId)
    let workEnd = getValues("attendanceWorkEndDt_" + elementId)
    let restStart1 = getValues("attendanceRestStart1Dt_" + elementId)
    let restEnd1 = getValues("attendanceRestEnd1Dt_" + elementId)
    let restStart2 = getValues("attendanceRestStart2Dt_" + elementId)
    let restEnd2 = getValues("attendanceRestEnd2Dt_" + elementId)
    let restStart3 = getValues("attendanceRestStart3Dt_" + elementId)
    let restEnd3 = getValues("attendanceRestEnd3Dt_" + elementId)
    
    //日時の分単位を指定分で丸める
    workStart = formatRound48Time(workStart,fractionUnit)
    workEnd = formatRound48Time(workEnd,fractionUnit*(-1))
    restStart1 = formatRound48Time(restStart1,fractionUnit*(-1))
    restEnd1 = formatRound48Time(restEnd1,fractionUnit)
    restStart2 = formatRound48Time(restStart2,fractionUnit*(-1))
    restEnd2 = formatRound48Time(restEnd2,fractionUnit)
    restStart3 = formatRound48Time(restStart3,fractionUnit*(-1))
    restEnd3 = formatRound48Time(restEnd3,fractionUnit)

    let result = calcWorkingHours([workStart, workEnd], [restStart1, restEnd1, restStart2, restEnd2, restStart3, restEnd3], dailyWorkInfo.workInfoList[0].attendanceBaseTime)

    //追加休憩時間開放version
    if(dailyWorkInfo.workInfoList[0].restFlag){
      result = calcWorkingRestFreeHours([workStart, workEnd], dailyWorkInfo.workInfoList[0].restList, dailyWorkInfo.workInfoList[0].attendanceBaseTime)
    }
    

    setValue('attendanceWorkTotalDt_' + elementId, result[0])
    setValue('attendanceRestTotalDt_' + elementId, result[1])
    setValue('attendanceOverTotalDt_' + elementId, result[2])
    setValue('attendanceNightTotalDt_' + elementId, 0)

    {
      var workInfo = dailyWorkInfo.workInfoList[0]
      workInfo.attendanceWorkStartDt = workStart
      workInfo.attendanceWorkEndDt = workEnd
      workInfo.attendanceWorkTotalDt = parseFloat(result[0])*3600
      workInfo.attendanceRestTotalDt = parseFloat(result[1])*3600
      workInfo.attendanceOverTotalDt = parseFloat(result[2])*3600
      workInfo.attendanceRestStart1Dt = restStart1
      workInfo.attendanceRestEnd1Dt = restEnd1
      workInfo.attendanceRestStart2Dt = restStart2
      workInfo.attendanceRestEnd2Dt = restEnd2
      workInfo.attendanceRestStart3Dt = restStart3
      workInfo.attendanceRestEnd3Dt = restEnd3
      
      workInfo.isDiff = true
    }
  }

  //出勤区分変更イベント
  const onChange = ( event ) =>
  {
    let value = event.target.value

    var workInfo = dailyWorkInfo.workInfoList[0]

    if(!workInfo.isDiff){
      workInfo.attendanceWorkStartDt = getValues("attendanceWorkStartDt_" + elementId)
      workInfo.attendanceWorkEndDt = getValues("attendanceWorkEndDt_" + elementId)
      workInfo.attendanceWorkTotalDt = parseFloat(getValues('attendanceWorkTotalDt_' + elementId))*3600
      workInfo.attendanceRestTotalDt = parseFloat(getValues('attendanceRestTotalDt_' + elementId))*3600
      workInfo.attendanceOverTotalDt = parseFloat(getValues('attendanceOverTotalDt_' + elementId))*3600
      workInfo.attendanceRestStart1Dt = getValues("attendanceRestStart1Dt_" + elementId)
      workInfo.attendanceRestEnd1Dt = getValues("attendanceRestEnd1Dt_" + elementId)
      workInfo.attendanceRestStart2Dt = getValues("attendanceRestStart2Dt_" + elementId)
      workInfo.attendanceRestEnd2Dt = getValues("attendanceRestEnd2Dt_" + elementId)
      workInfo.attendanceRestStart3Dt = getValues("attendanceRestStart3Dt_" + elementId)
      workInfo.attendanceRestEnd3Dt = getValues("attendanceRestEnd3Dt_" + elementId)
    }

    if(2 > value || value > 4)
    {
      setValue('attendanceWorkStartDt_' + elementId, '')
      setValue('attendanceWorkEndDt_' + elementId, '')
      setValue('attendanceWorkTotalDt_' + elementId, '0.00')
      setValue('attendanceRestTotalDt_' + elementId, '0.00')
      setValue('attendanceOverTotalDt_' + elementId, '0.00')
      setValue('attendanceRestStart1Dt_' + elementId, '')
      setValue('attendanceRestEnd1Dt_' + elementId, '')
      setValue('attendanceRestStart2Dt_' + elementId, '')
      setValue('attendanceRestEnd2Dt_' + elementId, '')
      setValue('attendanceRestStart3Dt_' + elementId, '')
      setValue('attendanceRestEnd3Dt_' + elementId, '')

      workInfo.attendanceWorkStartDt = ''
      workInfo.attendanceWorkEndDt = ''
      workInfo.attendanceWorkTotalDt = parseFloat('0.00')
      workInfo.attendanceRestTotalDt = parseFloat('0.00')
      workInfo.attendanceOverTotalDt = parseFloat('0.00')
      workInfo.attendanceRestStart1Dt = ''
      workInfo.attendanceRestEnd1Dt = ''
      workInfo.attendanceRestStart2Dt = ''
      workInfo.attendanceRestEnd2Dt = ''
      workInfo.attendanceRestStart3Dt = ''
      workInfo.attendanceRestEnd3Dt = ''

      workInfo.restList.map((restData)=>{
        if(restData.isNew){
          restData.isDel = true
          restData.isDiff = false
        }else{
          restData.isDel = true
          restData.isDiff = true
        }
      })
    }
    setWorkDivision(value)
    console.log(workInfo)
    workInfo.attendanceWorkDivision = value
    workInfo.isDiff = true
  }

  const [openRest, setOpenRest] = useState(false);
  //ダイアログ閉じる処理
  const onClose=()=>{
    //日時の分単位を指定分で丸める
    let fractionUnit = parseInt(dailyWorkInfo.workInfoList[0].attendanceFractionUnit)
    let workStart = getValues("attendanceWorkStartDt_" + elementId)
    let workEnd = getValues("attendanceWorkEndDt_" + elementId)
    let restStart1 = getValues("attendanceRestStart1Dt_" + elementId)
    let restEnd1 = getValues("attendanceRestEnd1Dt_" + elementId)
    let restStart2 = getValues("attendanceRestStart2Dt_" + elementId)
    let restEnd2 = getValues("attendanceRestEnd2Dt_" + elementId)
    let restStart3 = getValues("attendanceRestStart3Dt_" + elementId)
    let restEnd3 = getValues("attendanceRestEnd3Dt_" + elementId)
    
     //休憩を新たにセット
    let restStartList = new Array()
    let restEndList = new Array()

    dailyWorkInfo.workInfoList[0].restList.map((restData)=>{
      if(restData.restNum==null || restData.isDel) return
      restStartList.push(restData.attendanceRestStartDt)
      restEndList.push(restData.attendanceRestEndDt)
    })
    for(var i=restStartList.length;i<3;i++){
      restStartList.push('')
    }
    for(var i=restEndList.length;i<3;i++){
      restEndList.push('')
    }

    //日時の分単位を指定分で丸める
    workStart = formatRound48Time(workStart,fractionUnit)
    workEnd = formatRound48Time(workEnd,fractionUnit*(-1))
    restStart1 = formatRound48Time(restStartList[0],fractionUnit*(-1))
    restEnd1 = formatRound48Time(restEndList[0],fractionUnit)
    restStart2 = formatRound48Time(restStartList[1],fractionUnit*(-1))
    restEnd2 = formatRound48Time(restEndList[1],fractionUnit)
    restStart3 = formatRound48Time(restStartList[2],fractionUnit*(-1))
    restEnd3 = formatRound48Time(restEndList[2],fractionUnit)

    let result = calcWorkingRestFreeHours([workStart, workEnd], dailyWorkInfo.workInfoList[0].restList, dailyWorkInfo.workInfoList[0].attendanceBaseTime)

    setValue('attendanceWorkTotalDt_' + elementId, result[0])
    setValue('attendanceRestTotalDt_' + elementId, result[1])
    setValue('attendanceOverTotalDt_' + elementId, result[2])
    setValue('attendanceNightTotalDt_' + elementId, 0)

    //休憩を新たにセット
    setValue('attendanceRestStart1Dt_' + elementId, restStartList[0])
    setValue('attendanceRestEnd1Dt_' + elementId, restEndList[0])
    setValue('attendanceRestStart2Dt_' + elementId, restStartList[1])
    setValue('attendanceRestEnd2Dt_' + elementId, restEndList[1])
    setValue('attendanceRestStart3Dt_' + elementId, restStartList[2])
    setValue('attendanceRestEnd3Dt_' + elementId, restEndList[2])
    {
      var workInfo = dailyWorkInfo.workInfoList[0]
      workInfo.attendanceWorkStartDt = workStart
      workInfo.attendanceWorkEndDt = workEnd
      workInfo.attendanceWorkTotalDt = parseFloat(result[0])*3600
      workInfo.attendanceRestTotalDt = parseFloat(result[1])*3600
      workInfo.attendanceOverTotalDt = parseFloat(result[2])*3600
      workInfo.attendanceRestStart1Dt = restStart1
      workInfo.attendanceRestEnd1Dt = restEnd1
      workInfo.attendanceRestStart2Dt = restStart2
      workInfo.attendanceRestEnd2Dt = restEnd2
      workInfo.attendanceRestStart3Dt = restStart3
      workInfo.attendanceRestEnd3Dt = restEnd3
      
      workInfo.isDiff = true

      //休憩反映
      // if(dailyWorkInfo.workInfoList[0].restList.length>0){
      //   var restData = dailyWorkInfo.workInfoList[0].restList[0];
      //   setValue('attendanceRestStart1Dt_' + elementId, restData.attendanceRestStartDt )
      //   setValue('attendanceRestEnd1Dt_' + elementId, restData.attendanceRestEndDt )
      // }
      // if(dailyWorkInfo.workInfoList[0].restList.length>1){
      //   var restData = dailyWorkInfo.workInfoList[0].restList[1];
      //   setValue('attendanceRestStart2Dt_' + elementId, restData.attendanceRestStartDt )
      //   setValue('attendanceRestEnd2Dt_' + elementId, restData.attendanceRestEndDt )
      // }
      // if(dailyWorkInfo.workInfoList[0].restList.length>2){
      //   var restData = dailyWorkInfo.workInfoList[0].restList[2];
      //   setValue('attendanceRestStart3Dt_' + elementId, restData.attendanceRestStartDt )
      //   setValue('attendanceRestEnd3Dt_' + elementId, restData.attendanceRestEndDt )
      // }
    }
    console.log(workInfo)
    setOpenRest(false)
  }
  const onOpen=()=>{
    if(2 > workDivision || workDivision > 4){
      alert.error("出勤区分が出勤・休出・法出ではありません")
    }else{
      setOpenRest(true)
    }
    
  }

  return (
    <tr>
      <ColumnStaffTD>{createElementStaff(dailyWorkInfo)}</ColumnStaffTD>
      <ColumnOccupationTD>{createElementOccupation(dailyWorkInfo.workInfoList)}</ColumnOccupationTD>
      <ColumnDateTD>{createElementDate(dateDataList, dailyWorkInfo.baseDt)}</ColumnDateTD>
      <ColumnWorkTypeTD>{createElementWorkType(dailyWorkInfo.workInfoList)}</ColumnWorkTypeTD>
      <ColumnTimeTD>{createElementWorkTime(dailyWorkInfo.workInfoList)}</ColumnTimeTD>
      <ColumnTimeTD>{createElementRestTime(dailyWorkInfo.workInfoList)}</ColumnTimeTD>
      <ColumnTimeTD>{createElementOverTime(dailyWorkInfo.workInfoList)}</ColumnTimeTD>
      <ColumnTimeTD>{createElementTime('attendanceWorkStartDt_', dailyWorkInfo.workInfoList)}</ColumnTimeTD>
      <ColumnTimeTD>{createElementTime('attendanceWorkEndDt_', dailyWorkInfo.workInfoList)}</ColumnTimeTD>
      <ColumnTimeTD>{createElementTime('attendanceRestStart1Dt_', dailyWorkInfo.workInfoList)}</ColumnTimeTD>
      <ColumnTimeTD>{createElementTime('attendanceRestEnd1Dt_', dailyWorkInfo.workInfoList)}</ColumnTimeTD>
      <ColumnTimeTD>{createElementTime('attendanceRestStart2Dt_', dailyWorkInfo.workInfoList)}</ColumnTimeTD>
      <ColumnTimeTD>{createElementTime('attendanceRestEnd2Dt_', dailyWorkInfo.workInfoList)}</ColumnTimeTD>
      <ColumnTimeTD>{createElementTime('attendanceRestStart3Dt_', dailyWorkInfo.workInfoList)}</ColumnTimeTD>
      <ColumnTimeTD>{createElementTime('attendanceRestEnd3Dt_', dailyWorkInfo.workInfoList)}</ColumnTimeTD>
      <ColumnButtonsTD><Button margin='auto' padding='0' width='3rem' name='休憩' onClick={()=>onOpen()} 
        disabled={(dailyWorkInfo.workInfoList && dailyWorkInfo.workInfoList.length !== 1)||!dailyWorkInfo.workInfoList[0].restFlag}/></ColumnButtonsTD>
      <ColumnRemarkTD></ColumnRemarkTD>
      <ColumnButtonsTD>{createElementButtons(dailyWorkInfo.workInfoList)}</ColumnButtonsTD>
      <RestDialog open={openRest} onCloseFunc={onClose} workInfo={dailyWorkInfo.workInfoList[0]}
      setRest={setValue} elementId={elementId}></RestDialog>
    </tr>
  )
}

DailyTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  workDivisions: PropTypes.any,
  dateDataList: PropTypes.array,
  dailyWorkInfo: PropTypes.object,
  selectFunction: PropTypes.func,

  handleOpenPunchHistoryFunc: PropTypes.func,
  handleClosePunchHistoryFunc: PropTypes.func,
  handleOpenDailyAttendanceFunc: PropTypes.func,
  handleCloseDailyAttendanceFunc: PropTypes.func,
}

export default DailyTableRow
