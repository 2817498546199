import React from 'react'
import PropTypes from 'prop-types'

import {DashboardBaseFormRow} from '../../../Wrap/DashboardBaseWrap'
import AttendanceBarGraph from './AttendanceBarGraph'
import AttendancePersentGraph from './AttendancePersentGraph'
const GraphBody = ({ dateDataList }) => {

//visible={staffWorkInfoList.length ? true : false}
  return (
    <DashboardBaseFormRow>
        <div>
        <AttendancePersentGraph title={'月別残業割合'} attendanceList={dateDataList.attendanceOvertime}></AttendancePersentGraph>
        <AttendanceBarGraph title={'月別４５時間超過人数'} 
          attendanceList={dateDataList.attendanceOverWorkTime}
          axisTitle={['month45OverCtSum','month45OverCt']}>
        </AttendanceBarGraph>
        </div>
        <div>
        <AttendanceBarGraph title={'月別平均労働時間'} 
          attendanceList={dateDataList.attendanceWorkTime} 
          axisTitle={['avgMonthWork','monthWorkerTotal']}>
        </AttendanceBarGraph>
        <AttendanceBarGraph title={'月別欠勤人数'} 
          attendanceList={dateDataList.attendanceAbsenceStaff}
          axisTitle={['absenceMonthlyWorkTotal','absenceMonthlyCt']}></AttendanceBarGraph>
        </div>
    </DashboardBaseFormRow>
  )
}

GraphBody.propTypes = {
  dateDataList: PropTypes.array,
}

export default GraphBody
