import styled from 'styled-components'

export const ColumnStaffTH = styled.th`
  min-width: 12rem;
  vertical-align:middle;
  background-color: #649cdf;
`
export const ColumnOccupationTH = styled.th`
  min-width: 10rem;
  vertical-align:middle;
  background-color: #649cdf;
`
export const ColumnWorkTH = styled.th`
  min-width: 5rem;
  vertical-align:middle;
  background-color: #649cdf;
`
export const ColumnWorkTD = styled.td`
  min-width: 5rem;
  padding-right:1rem;
  vertical-align:middle;
  text-align:right;
  background: ${props => props.rowNumber ? '#fff' : '#eee'};
`
export const ColumnTimeTD = styled.td`
  width: 7vW;
  vertical-align:middle;
  text-align:center;
  background: ${props => props.rowNumber ? '#fff' : '#eee'};
`

export const ColumnOccupationTD = styled.td`
  min-width: 10rem;
  vertical-align:middle;
  padding-left:1rem;
  background: ${props => props.rowNumber ? '#fff' : '#eee'};
`
export const ColumnSalariedCountTD = styled.td`
  width: 6rem;
  vertical-align:middle;
  text-align:center;
  color: red;
  background-color: MistyRose;
  background: ${props => props.rowNumber ? '#fff' : '#eee'};
`

export const ColumnStaffTD = styled.td`
  font-size: 16px;
  max-width: 12rem;
  min-width: 12rem;
  vertical-align:middle;
  /* 未切れ対策 */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* end*/
  background: ${props => props.rowNumber ? '#fff' : '#eee'};
`
export const ColumnStaffIdTD = styled.div`
  padding-left: 0.5rem;
  max-width: 12rem;
  min-width: 12rem;
`

export const ColumnTimeTH = styled.th`
  width: 7vw;
  vertical-align:middle;
`
export const ColumnSpan = styled.span`
    white-space: pre-line;
`
export const ColumnCountTH = styled.th`
  width: 6rem;
  vertical-align:middle;
`
export const RowHeaderTop = styled.tr`
  background-color: #649cdf;
  opacity: 1;
`

export const RowHeaderBottom = styled.tr`
  background-color: #FFE8E6;
`

export const ColumnDateTH = styled.th`
  width: 25vw;
  height: 2rem;
  vertical-align:middle;
`
export const ColumnDateTD = styled.td`
  width: 25vw;
  height: 4rem;
  vertical-align:middle;
`
export const TableDiv = styled.div`
  width: calc(100vw - 20px);
  max-height: calc(100vh - 280px);
  overflow: scroll;
  margin-left: 16px;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
   :horizontal {
     margin-left: 37rem;
   }
   :vertical {
    margin-top: 30px;
   }
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-corner {
   display: none;
  }

  ::-webkit-scrollbar-thumb:active {
      background-color: rgba(112, 112, 112, 1);
  }


  table{
    td.staffID{
      position: sticky;
      left 0;
      z-index 2;
    }
    td.occupation{
      position: sticky;
      left 12rem;
      z-index 2;
    }
    td.workTime{
      position: sticky;
      left 22rem;
      z-index 2;
    }
    td.restTime{
      position: sticky;
      left 27rem;
      z-index 2;
    }
    td.overTime{
      position: sticky;
      left 32rem;
      z-index 2;
    }
    thead{
      z-index: 3;
      position: sticky;
      top: 0;
      th.staffID{
        position: sticky;
        top: 0;
        left 0;
      }
      th.occupation{
        position: sticky;
        top: 0;
        left 12rem;
      }
      th.workTime{
        position: sticky;
        top: 0;
        left 22rem;
      }
      th.restTime{
        position: sticky;
        top: 0;
        left 27rem;
      }
      th.overTime{
        position: sticky;
        top: 0;
        left 32rem;
      }
    }

  }

`
const SalariedTableWrap = styled.table`
  font-size: 12px;
  border-collapse:separate;
  //border-spacing: 1px;
  

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(115rem + 33px);
  }

  tbody {
    // height: calc(80vh - 1rem - 3rem - 3rem + 6px);
    // width: calc(115rem + 33px);
  }

  // div.mainTable{
  //   min-height: calc(80vh);
  // }

  // div.mainTable:hover {
  //   overflow-x: scroll;
  //   overflow-y: scroll;
  // }

  // div.mainTable::-webkit-scrollbar {
  //   width: 10px;
  //   height: 10px;
  //   position: absolute;
  // }
  
  // div.mainTable::-webkit-scrollbar-track {
  //   border:none;
  // }

  // div.mainTable::-webkit-scrollbar-thumb {
  //   background-color: rgba(212, 212, 212, 1);
  //   border-radius: 10px;
  // }

  // div.mainTable::-webkit-scrollbar-thumb:active {
  //   background-color: rgba(112, 112, 112, 1);
  // }
  // div.mainTable::-webkit-scrollbar-corner {
  //   display: none;
  // }
  // div.mainTable::-webkit-scrollbar-track {
  //   :horizontal {
  //     margin-left: 200px;
  //   }
  //   :vertical {
  //     margin-top: 30px;
  //   }
  // }
  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  // tbody:hover {
  //   overflow-x: scroll;
  //   overflow-y: auto;
  // }

  // tbody::-webkit-scrollbar {
  //   width: 10px;
  //   height: 10px;
  // }
  
  // tbody::-webkit-scrollbar-track {
  //   border:none;
  // }

  // tbody::-webkit-scrollbar-thumb {
  //   background-color: rgba(212, 212, 212, 1);
  //   border-radius: 10px;
  // }

  // tbody::-webkit-scrollbar-thumb:active {
  //   background-color: rgba(112, 112, 112, 1);
  // }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

export default SalariedTableWrap