import React,{useEffect,useState} from 'react'
import PropTypes from 'prop-types'
//import styled from 'styled-components'
import {ColumnStaffTH,ColumnTimeTH,RowHeaderTop,RowHeaderBottom}from '../../Wrap/DashboardTableWrap'

const OverTimeHeader = ({ staffWorkInfoList, selectFunction,targetDt }) => {
  const [headerData, setHeaderData] = useState({
    overTimeWeek:0,
    twoMonth:0,
    threeMonth:0,
    fourMonth:0,
    fiveMonth:0,
    sixMonth:0,
    thisMonth:0,
    thisYear:0
  })
  useEffect(() => {
    var data = {
      overTimeWeek:0,
      twoMonth:0,
      threeMonth:0,
      fourMonth:0,
      fiveMonth:0,
      sixMonth:0,
      thisMonth:0,
      thisYear:0
    }
    staffWorkInfoList.map((staff)=>{
      data.overTimeWeek += staff.overTimeWeek
      data.twoMonth += staff.twoMonth
      data.threeMonth += staff.threeMonth
      data.fourMonth += staff.fourMonth
      data.fiveMonth += staff.fiveMonth
      data.sixMonth += staff.sixMonth
      data.thisMonth += staff.thisMonth
      data.thisYear += staff.thisYear                
    })
    setHeaderData(data)
  }, [staffWorkInfoList])

  return (
    <thead>
      <tr>
      <RowHeaderTop>
        <ColumnStaffTH>スタッフ名</ColumnStaffTH>
        <ColumnTimeTH>今週残業時間</ColumnTimeTH>
        <ColumnTimeTH>当月残業時間</ColumnTimeTH>
        <ColumnTimeTH>2ヶ月平均</ColumnTimeTH>
        <ColumnTimeTH>3ヶ月平均</ColumnTimeTH>
        <ColumnTimeTH>4ヶ月平均</ColumnTimeTH>
        <ColumnTimeTH>5ヶ月平均</ColumnTimeTH>
        <ColumnTimeTH>6ヶ月平均</ColumnTimeTH>
        <ColumnTimeTH>{(targetDt.targetDt).slice(0,4)}年度残業時間</ColumnTimeTH>
        <ColumnTimeTH>特例月</ColumnTimeTH>
      </RowHeaderTop>
      <RowHeaderBottom>
        <ColumnStaffTH>合計</ColumnStaffTH>
        <ColumnTimeTH>{headerData.overTimeWeek.toFixed(2)}</ColumnTimeTH>
        <ColumnTimeTH>{headerData.thisMonth.toFixed(2)}</ColumnTimeTH>
        <ColumnTimeTH>{headerData.twoMonth.toFixed(2)}</ColumnTimeTH>
        <ColumnTimeTH>{headerData.threeMonth.toFixed(2)}</ColumnTimeTH>
        <ColumnTimeTH>{headerData.fourMonth.toFixed(2)}</ColumnTimeTH>
        <ColumnTimeTH>{headerData.fiveMonth.toFixed(2)}</ColumnTimeTH>
        <ColumnTimeTH>{headerData.sixMonth.toFixed(2)}</ColumnTimeTH>
        <ColumnTimeTH>{headerData.thisYear.toFixed(2)}</ColumnTimeTH>
        <ColumnTimeTH>-</ColumnTimeTH>
      </RowHeaderBottom>
      </tr>
    </thead>
  )
}

OverTimeHeader.propTypes = {
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
}

export default OverTimeHeader
