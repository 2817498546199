import React, { useEffect, useState, useCallback } from 'react'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { GetApi } from '../../Common/ApiAxios'
import ErrorHandling from '../../Common/ErrorHandling'
import Calendar from '../../Common/Atoms/Calendar'
import * as regex from '../../Common/Regex'
import { InputGroup, SelectGroup, HiddenInput } from '../../Common/CommonInputGroup'
import { Alert } from '../../Common/CommonStyled'
import { format48Time, diff48Time } from '../../Common/Utilties/LaborUtility'
import { formatMoney } from '../../Common/Utilties/MoneyUtility'
import SearchDepartmentDialog from '../../Common/CommonMasterDepartmentDialog'
import Button from '../../Common/Atoms/NormalButton'
import MasterDetailWrap, { MasterDetailFormWrap } from '../Wrap/MasterDetailWrap'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75vw',
  },
  typography: {
    width: '72vw',
    fontSize: '18px',
    fontWeight: '700',
    color: '#ffffff',
    verticalAlign: 'middle',
    margin: '0',
    padding: '16px',
    background: '#4169E1',
  },
}))

export const OrderMasterForm = ({
  register, errors, setValue, getValues, orderDetail,
  watch, canWrite, loginUserInfo, isDeleted, actionType,
  isView,postCompanyList,postCustomerList,postComDepartmentList,postCusDepartmentList 
}) => {
  const classes = useStyles();
  const alert = useAlert()
  const [contractDivisionOptions, setContractDivisionOptions] = useState(<></>)
  const [wagesTypeOptoins, setWagesTypeOptions] = useState(<></>)
  const [departmentalCalcTypeOptoins, setDepartmentalCalcTypeOptoins] = useState(<></>)
  const [billingDisplay, setBillingDisplay] = useState('block')

  const [companyDepartmentId, setCompanyDepartmentId] = useState(null)
  const [companyId, setCompanyId] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [companyDepartmentName,setCompanyDepartmentName]=useState(null)
  const [companyDepartmentList,setCompanyDepartmentList]=useState(null)

  const [customerDepartmentId, setCustomerDepartmentId] = useState(null)
  const [customerId, setCustomerId] = useState(null)
  const [customerName, setCustomerName] = useState(null)
  const [customerDepartmentName,setCustomerDepartmentName]=useState(null)
  const [customerDepartmentList,setCustomerDepartmentList]=useState(null)
  // 受注情報切り替え時
  useEffect(() => {
    // 受注情報が変更された場合のみ実行
    if (orderDetail != null) {
      console.log(orderDetail,postCompanyList,postCustomerList)
      setOrderDt(orderDetail.orderDt)
      setAgreementStartDt(orderDetail.agreementStartDt)
      setAgreementEndDt(orderDetail.agreementEndDt)

      setValue('orderId', orderDetail.orderId)
      setValue('contractDivision', orderDetail.contractDivision)
      setValue('companyId', orderDetail.companyId)
      setValue('companyName', orderDetail.companyName)
      setValue('comDepartmentId', orderDetail.comDepartmentId)
      setValue('comDepartmentName', orderDetail.comDepartmentName)
      setValue('customerName', orderDetail.customerName)
      setValue('customerId', orderDetail.customerId)
      setValue('cusDepartmentId', orderDetail.cusDepartmentId)
      setValue('cusDepartmentName', orderDetail.cusDepartmentName)
      //setValue('orderDt', orderDetail.orderDt)
      //setValue('agreementStartDt', orderDetail.agreementStartDt)
      //setValue('agreementEndDt', orderDetail.agreementEndDt)
      setValue('occupation', orderDetail.occupation)
      setValue('occupationDetail1', orderDetail.occupationDetail1)
      setValue('occupationDetail2', orderDetail.occupationDetail2)
      setValue('workStartDt', diff48Time('1900-01-01', orderDetail.workStartDt))
      setValue('workEndDt', diff48Time('1900-01-01', orderDetail.workEndDt))
      //setValue('workTotalDt', orderDetail.workTotalDt)
      setValue('restStart1Dt', diff48Time('1900-01-01', orderDetail.restStart1Dt))
      setValue('restEnd1Dt', diff48Time('1900-01-01', orderDetail.restEnd1Dt))
      setValue('restStart2Dt', diff48Time('1900-01-01', orderDetail.restStart2Dt))
      setValue('restEnd2Dt', diff48Time('1900-01-01', orderDetail.restEnd2Dt))
      setValue('restStart3Dt', diff48Time('1900-01-01', orderDetail.restStart3Dt))
      setValue('restEnd3Dt', diff48Time('1900-01-01', orderDetail.restEnd3Dt))
      //setValue('restTotalDt', orderDetail.restTotalDt)
      //setValue('nightStartDt', orderDetail.nightStartDt)
      //setValue('nightEndDt', orderDetail.nightEndDt)
      setValue('wagesType', orderDetail.wagesType)
      setValue('normalPrice', formatMoney(orderDetail.normalPrice))
      setValue('overPrice', formatMoney(orderDetail.overPrice))
      setValue('nightPrice', formatMoney(orderDetail.nightPrice))
      setValue('holNormalPrice', formatMoney(orderDetail.holNormalPrice))
      setValue('holOverPrice', formatMoney(orderDetail.holOverPrice))
      setValue('holNightPrice', formatMoney(orderDetail.holNightPrice))
      setValue('lawNormalPrice', formatMoney(orderDetail.lawNormalPrice))
      setValue('lawOverPrice', formatMoney(orderDetail.lawOverPrice))
      setValue('lawNightPrice', formatMoney(orderDetail.lawNightPrice))
      setValue('billingType', orderDetail.billingType)
      setValue('normalBillingPrice', formatMoney(orderDetail.normalBillingPrice))
      setValue('overBillingPrice', formatMoney(orderDetail.overBillingPrice))
      setValue('nightBillingPrice', formatMoney(orderDetail.nightBillingPrice))
      setValue('holNormalBillingPrice', formatMoney(orderDetail.holNormalBillingPrice))
      setValue('holOverBillingPrice', formatMoney(orderDetail.holOverBillingPrice))
      setValue('holNightBillingPrice', formatMoney(orderDetail.holNightBillingPrice))
      setValue('lawNormalBillingPrice', formatMoney(orderDetail.lawNormalBillingPrice))
      setValue('lawOverBillingPrice', formatMoney(orderDetail.lawOverBillingPrice))
      setValue('lawNightBillingPrice', formatMoney(orderDetail.lawNightBillingPrice))
      setValue('departmentCalcDivision', orderDetail.departmentCalcDivision)
      //setValue('rateDivision', orderDetail.rateDivision)
      setValue('remark', orderDetail.remark)
      setValue('updatedAt', orderDetail.updatedAt)
      setValue('updatedId', orderDetail.updatedId)

      //部門ダイアログ用変数セット
      //会社
      if(orderDetail.companyId){
        setCompanyDepartmentId(orderDetail.comDepartmentId)
        setCompanyId(orderDetail.companyId)
        setCompanyName(orderDetail.companyName)
        setCompanyDepartmentName(orderDetail.comDepartmentName)

        postComDepartmentList.map((item)=>{
          if(orderDetail.companyId==item.index)setCompanyDepartmentList(item.departments)
        })
      }else{
        setCompanyId(postCompanyList[0].props.children.props.value)
        setCompanyName(postCompanyList[0].props.children.props.children)
        setCompanyDepartmentName('')
        setCompanyDepartmentId(null)

        postComDepartmentList.map((item)=>{
          if(postCompanyList[0].props.children.props.value==item.index)setCompanyDepartmentList(item.departments)
        })
      }

      //顧客
      if(orderDetail.customerId){
        setCustomerDepartmentId(orderDetail.cusDepartmentId)
        setCustomerId(orderDetail.customerId)
        setCustomerName(orderDetail.customerName)
        setCustomerDepartmentName(orderDetail.cusDepartmentName)

        postCusDepartmentList.map((item)=>{
          if(orderDetail.customerId==item.index)setCustomerDepartmentList(item.departments)
        })
      }else{
        setCustomerDepartmentId(null)
        setCustomerId(postCustomerList[0].props.children.props.value)
        setCustomerName(postCustomerList[0].props.children.props.children)
        setCustomerDepartmentName('')

        postCusDepartmentList.map((item)=>{
          if(postCustomerList[0].props.children.props.value==item.index)setCustomerDepartmentList(item.departments)
        })
      }
      setValue('nightStartDt', '')
      setValue('nightEndDt', '')
    }
  // eslint-disable-next-line
  }, [orderDetail])

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
      getContractDivision()
      getWagesType()
      getDepartmentalCalcType()
    }
    getOptions()
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  // 契約区分取得
  const getContractDivision = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/contractType')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setContractDivisionOptions(options)
    }
  }, [setContractDivisionOptions, alert])

  // 給与(請求)形態取得
  const getWagesType = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/wagesType')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setWagesTypeOptions(options)
    }
  }, [setWagesTypeOptions, alert])

  // 部門別計算区分取得
  const getDepartmentalCalcType = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/departmentalCalcType')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setDepartmentalCalcTypeOptoins(options)
    }
  }, [setDepartmentalCalcTypeOptoins, alert])

  // 受注日
  //register({ name: 'orderDt' })
  const [orderDt, _setOrderDt] = useState()
  const setOrderDt = (date) => {
    _setOrderDt(date)
    setValue('orderDt', date)
  }

  // 契約開始日
  //register({ name: 'agreementStartDt' })
  const [agreementStartDt, _setAgreementStartDt] = useState()
  const setAgreementStartDt = (date) => {
    _setAgreementStartDt(date)
    setValue('agreementStartDt', date)
  }

  // 契約終了日
  //register({ name: 'agreementEndDt' })
  const [agreementEndDt, _setAgreementEndDt] = useState()
  const setAgreementEndDt = (date) => {
    _setAgreementEndDt(date)
    setValue('agreementEndDt', date)
  }

  const onChangeContract = ( event) => {
    let value = event.target.value

    setBillingDisplay(value == 1 ? 'block' : 'none')
  }

  const onBlurTime = ( event ) => {
    let value = event.target.value
    value = format48Time(value)
    event.target.value = value
  }

  const onBlurMonery = ( event ) => {
    let value = event.target.value
    value = formatMoney(value)
    event.target.value = value
  }

  
  const onChangeCompany = ( event) => {
    event.persist()
    let value = event.target.value
    setValue('companyName',(event.target.options[event.target.selectedIndex].text))
    
    setCompanyId(value)
    setCompanyName(event.target.options[event.target.selectedIndex].text)
    setCompanyDepartmentName('')
    setCompanyDepartmentId(null)
    setCompanyDepartmentList(new Array())
    postComDepartmentList.map((item)=>{
      if(value==item.index){
        if(item.departmentFlag){
          setCompanyDepartmentName('-')
          setValue('comDepartmentId', value)
        }
        setCompanyDepartmentList(item.departments)
      }
    })
    console.log(value,postComDepartmentList)
  }
  const onChangeCustomer = ( event) => {
    event.persist()
    let value = event.target.value
    setValue('customerName',(event.target.options[event.target.selectedIndex].text))
    setCustomerId(value)
    setCustomerName(event.target.options[event.target.selectedIndex].text)

    setCustomerDepartmentName('')
    setCustomerDepartmentId(null)
    setCustomerDepartmentList(new Array())
    postCusDepartmentList.map((item)=>{
      if(value==item.index){
        if(item.departmentFlag){
          setCustomerDepartmentName('-')
          setValue('cusDepartmentId', value)
        }
        setCustomerDepartmentList(item.departments)
      }
    })
    console.log(value,postCusDepartmentList)
  }

  const [canCompanyOpenDialog, setCompanyCanOpenDialog] = useState(false);
  //ダイアログ閉じるボタン処理
  const handleClose = () => {
    setCompanyCanOpenDialog(false);
  }

  //選択ボタン処理
  const onClickDepartmentSelect =  (event) => {
    setCompanyCanOpenDialog(true)
  }
  const setComDepartmentId =(data)=>{
    if(!data) return
    setValue('comDepartmentId', (data+'000000000000').slice(0,12))
    setCompanyDepartmentId((data+'000000000000').slice(0,12))
  }
  const setComDepartmentName =(data)=>{
    console.log(data)
    if(!data) return
    setValue('comDepartmentName', data)
    setCompanyDepartmentName(data)
  }
  const [canCustomerOpenDialog, setCustomerCanOpenDialog] = useState(false);
  //ダイアログ閉じるボタン処理
  const handleCustomerClose = () => {
    setCustomerCanOpenDialog(false);
  }

  //選択ボタン処理
  const onClickCustomerDepartmentSelect =  (event) => {
    setCustomerCanOpenDialog(true)
  }
  const setCusDepartmentId =(data)=>{
    if(!data) return
    setValue('cusDepartmentId', (data+'000000000000').slice(0,12))
    setCustomerDepartmentId((data+'000000000000').slice(0,12))
  }
  const setCusDepartmentName =(data)=>{
    console.log(data)
    if(!data) return
    setValue('cusDepartmentName', data)
    setCustomerDepartmentName(data)
  }
 
  if (orderDetail === null) return null // 未選択時の場合は非表示
  return (
    <MasterDetailWrap isView={isView}>
      <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>基本情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li style={{ height: '15px' }}><label /><div /></li>
            {isDeleted && <li><label /><div><Alert>削除データ</Alert></div></li>}
            <li>
              <label>ID</label>
              <div>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 0'
                  placeholder='受注ID'
                  name='orderId'
                  register={register}
                  rule={(isDeleted || !canWrite || actionType === 'PUT')?{}:{
                    maxLength: regex.maxLength(10),
                    pattern: regex.halfWidthAlphaNumeric
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite || actionType === 'PUT'}
                />
              </div>
            </li>
            <li>
              <label>契約区分</label>
              <div>
                <SelectGroup
                  name='contractDivision'
                  width='10rem'
                  margin='0'
                  options={contractDivisionOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  onChange={onChangeContract}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label className="required">会社</label>
              <div>
                <SelectGroup
                  width='20rem'
                  margin='12px 0 12px 0'
                  name='companyId'
                  register={register}
                  options={postCompanyList}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  onChange={onChangeCompany}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 12px'
                  name='comDepartmentName'
                  value = {companyDepartmentName}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={true}
                />
                <Button width='4rem' margin='0 0 0 4px' name='選択' padding='0px' type='button' onClick={onClickDepartmentSelect} />
              </div>
            </li>
            <li>
              <label className="required">顧客</label>
              <div>
                <SelectGroup
                  width='20rem'
                  margin='12px 0 12px 0'
                  name='customerId'
                  register={register}
                  options={postCustomerList}
                  rule={{
                    required: regex.required
                  }}
                  onChange={onChangeCustomer}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup 
                  width='20rem'
                  margin='12px 0 12px 12px'
                  name='cusDepartmentName'
                  value = {customerDepartmentName}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={true}
                />
                 <Button width='4rem' margin='0 0 0 4px' name='選択' padding='0px' type='button' onClick={onClickCustomerDepartmentSelect} />
              </div>
            </li>
            <li>
              <label className="required">受注日</label>
              <div>
                <Calendar
                  margin='0 16px 0 0'
                  name='orderDt'
                  placeholder='受注日'
                  changeCalendar={setOrderDt}
                  dataValue={orderDt}
                  register={register}
                  disabled={isDeleted || !canWrite}
                  prefix='/'
                  isViewIcon
                />
              </div>
            </li>
            <li>
              <label className="required">契約期間</label>
              <div>
                <Calendar
                  margin='0'
                  name='agreementStartDt'
                  placeholder='開始日'
                  changeCalendar={setAgreementStartDt}
                  dataValue={agreementStartDt}
                  register={register}
                  disabled={isDeleted || !canWrite}
                  prefix='/'
                  isViewIcon
                />
                <label>～</label>
                <Calendar
                  margin='0'
                  name='agreementEndDt'
                  placeholder='終了日'
                  changeCalendar={setAgreementEndDt}
                  dataValue={agreementEndDt}
                  register={register}
                  disabled={isDeleted || !canWrite}
                  prefix='/'
                  isViewIcon
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>業務情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li style={{ height: '15px' }}><label /><div /></li>
            <li>
              <label>業務</label>
              <div>
                <InputGroup
                  width='30rem'
                  margin='12px 0 12px 0'
                  placeholder='業務名称'
                  name='occupation'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(100)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <InputGroup
                  width='60vw'
                  margin='12px 0 12px 0'
                  placeholder='業務詳細1'
                  name='occupationDetail1'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(255)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <InputGroup
                  width='60vw'
                  margin='12px 0 12px 0'
                  placeholder='業務詳細2'
                  name='occupationDetail2'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(255)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>就業情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li style={{ height: '15px' }}><label /><div /></li>
            <li>
              <label>勤務時間</label>
              <div>
                <InputGroup
                  width='6rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='workStartDt'
                  register={register}
                  rule={{
                    pattern: regex.time,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='6rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='workEndDt'
                  register={register}
                  rule={{
                    pattern: regex.time,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>休憩時間</label>
              <div>
                <InputGroup
                  width='6rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restStart1Dt'
                  register={register}
                  rule={{
                   pattern: regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='6rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd1Dt'
                  register={register}
                  rule={{
                   pattern: regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='6rem'
                  margin='12px 0 12px 12px'
                  placeholder='0000～3559'
                  name='restStart2Dt'
                  register={register}
                  rule={{
                   pattern: regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='6rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd2Dt'
                  register={register}
                  rule={{
                   pattern: regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='6rem'
                  margin='12px 0 12px 12px'
                  placeholder='0000～3559'
                  name='restStart3Dt'
                  register={register}
                  rule={{
                   pattern: regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='6rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd3Dt'
                  register={register}
                  rule={{
                   pattern: regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>工数算出</label>
              <div>
                <SelectGroup
                  name='departmentCalcDivision'
                  width='10rem'
                  margin='0'
                  options={departmentalCalcTypeOptoins}
                  register={register}
                  rule={{
                    required: regex.halfWidthNumber
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>給与情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li>
              <label>給与区分</label>
              <div>
                <SelectGroup
                  name='wagesType'
                  width='10rem'
                  margin='0'
                  options={wagesTypeOptoins}
                  register={register}
                  rule={{
                    required: regex.halfWidthNumber
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>支給金額</label>
              <div>
                <label style={{ width: '2rem' }}></label>
                <label style={{ width: '8rem', margin: '12px 16px 6px 12px', textAlign: 'center' }}>時間内</label>
                <label style={{ width: '8rem', margin: '12px 16px 6px 12px', textAlign: 'center' }}>時間外</label>
                <label style={{ width: '8rem', margin: '12px 16px 6px 12px', textAlign: 'center' }}>深夜</label>
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <label>通常</label>
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='normalPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='overPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='nightPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <label>休日</label>
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='holNormalPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='holOverPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='holNightPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <label>法休</label>
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='lawNormalPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='lawOverPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='lawNightPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>
      
      <div className={classes.root} style={{ display: billingDisplay}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>請求情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li>
              <label>請求区分</label>
              <div>
                <SelectGroup
                  name='billingType'
                  width='10rem'
                  margin='0'
                  options={wagesTypeOptoins}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>請求金額</label>
              <div>
                <label style={{ width: '2rem' }}></label>
                <label style={{ width: '8rem', margin: '12px 16px 6px 12px', textAlign: 'center' }}>時間内</label>
                <label style={{ width: '8rem', margin: '12px 16px 6px 12px', textAlign: 'center' }}>時間外</label>
                <label style={{ width: '8rem', margin: '12px 16px 6px 12px', textAlign: 'center' }}>深夜</label>
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <label>通常</label>
                <InputGroup
                  width='8rem'
                  margin='12px 16px 6px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='normalBillingPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 6px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='overBillingPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 6px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='nightBillingPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <label>休日</label>
                <InputGroup
                  width='8rem'
                  margin='12px 16px 6px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='holNormalBillingPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 6px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='holOverBillingPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 6px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='holNightBillingPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <label>法休</label>
                <InputGroup
                  width='8rem'
                  margin='12px 16px 6px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='lawNormalBillingPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 6px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='lawOverBillingPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 6px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='lawNightBillingPrice'
                  register={register}
                  rule={{
                    pattern: regex.halfNumericHyphen,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>その他</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li>
              <label>備考</label>
              <div>
                <InputGroup
                  width='60vw'
                  margin='12px 0 12px 0'
                  placeholder=''
                  name='remark'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(255)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <HiddenInput name='updatedId' register={register} />
      <HiddenInput name='updatedAt' register={register} />

      <HiddenInput name='comDepartmentId' register={register} />
      <HiddenInput name='cusDepartmentId' register={register} />

      <HiddenInput name='nightStartDt' register={register} />
      <HiddenInput name='nightEndDt' register={register} />
      <SearchDepartmentDialog
        open={canCompanyOpenDialog}
        onCloseFunc={handleClose}
        postName={companyName}
        postId={companyId}
        setSearchDepartmentId={(data)=>setComDepartmentId(data)}
        searchDepartmentId={companyDepartmentId}
        setDepartmentName={(data)=>setComDepartmentName(data)}
        postType={0}
        departmentList={companyDepartmentList}
        >
      </SearchDepartmentDialog>
      <SearchDepartmentDialog
        open={canCustomerOpenDialog}
        onCloseFunc={handleCustomerClose}
        postName={customerName}
        postId={customerId}
        setSearchDepartmentId={(data)=>setCusDepartmentId(data)}
        searchDepartmentId={customerDepartmentId}
        setDepartmentName={(data)=>setCusDepartmentName(data)}
        postType={1}
        departmentList={customerDepartmentList}
        >
      </SearchDepartmentDialog>
    </MasterDetailWrap>
  )
}

OrderMasterForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  orderDetail: PropTypes.object,
  watch: PropTypes.func,
  canWrite: PropTypes.bool,
  isDeleted: PropTypes.bool,
  actionType: PropTypes.string,
  selects: PropTypes.object,
  setSelects: PropTypes.func,
  isView: PropTypes.bool
}

export default OrderMasterForm
