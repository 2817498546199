import React from 'react'
import  { ColumnTH , ColumnNameTH,ColumnDivisionTH, ColumnStaffNameTH} from '../../../Wrap/MasterDialogWrap'
const DailyTableHeader = () => {

  return (
    <thead>
      <tr>
        <ColumnTH></ColumnTH>
        <ColumnTH>スタッフID</ColumnTH>
        <ColumnStaffNameTH>スタッフ名称</ColumnStaffNameTH>
        <ColumnDivisionTH>スタッフ区分</ColumnDivisionTH>
        <ColumnTH>入社日</ColumnTH>
        <ColumnTH>退社日</ColumnTH>
      </tr>
    </thead>
  )
}

DailyTableHeader.propTypes = {
}

export default DailyTableHeader
