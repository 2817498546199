import React, {  useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import Button from '../../Common/Atoms/NormalButton'
import { PostApi } from '../../Common/ApiAxios'
import Input from '../../Common/Atoms/Input'
import Select from '../../Common/Atoms/SelectAddValue'
import IconUpdateImg from '../../../image/update-icon.png'
import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconCloseImg from '../../../image/close_icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
import IconAddImg from '../../../image/add-icon.png'
import IconPageTypeChange from '../../../image/page-type-change-icon.png'

import SearchWrap,{FunctionContent,FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/SearchWrap'

const Search = (props) => {
  const {
    title, showButton, onClick, onKeyDown, staffIdRef, staffNameRef, firstSearch, grants,
    addStyle,  relationStatus,onAddClick,onUpadate,
    viewFunction,onClickFunction,onPageTypeChangeClick,statusRef
  } = props
  const dispatch = useDispatch();
  const history = useHistory();

  const [renderFlg, setRenderFlg] = useState(false);
  const [plusButton, setPlusButton] = useState(false);
  const alert = useAlert();
  const loginUserInfo = useSelector(state => state.loginUserInfo);

  // 初期値
  useEffect(() => {
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, dispatch])

  // レンダー終了判定

  // 初期検索
  useEffect(() => {
    if (renderFlg && firstSearch) {
      onClick()
    }
  }, [renderFlg, firstSearch, onClick])

  useEffect(() => {

  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, relationStatus])
  
  // 登録情報コンボボックス選択変更イベント
  const onChangeStatus = (event) => {
    event.persist()
    console.log(event.target.selectedIndex)
    statusRef.current = (event.target.selectedIndex)
    onClick()
  }

  // TOP画面遷移クリックイベント
  async function topPage () {
    history.push('/top');
  }

  async function logout () {
    const apiUrl = '/api/logout'
    await PostApi(apiUrl, null)
    document.cookie = 'accessToken=;path=/; max-age=0;'
    document.cookie = 'refreshToken=;path=/; max-age=0;'
    document.cookie = 'accessTokenExpires=;path=/; max-age=0;'
    document.cookie = 'refreshTokenExpires=;path=/; max-age=0;'
    window.location.href = '/login'
  }

  const viewSubSearch = false
  return (
    <SearchWrap addStyle={addStyle} plusButton={plusButton} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{title}</h3>
      <ul className='mainSearch'>
      <li>
          <label>機器状況</label>
          <div>
          <Select
            name={'postId'}
            width='7rem'
            selectValue={statusRef.current.value}
            onChange={onChangeStatus}>
            <option value={0} >{'全て'}</option>
            <option value={1} >{'正常'}</option>
            <option value={2} >{'異常'}</option>
          </Select>
          </div>
        </li>
        <li>
          <label>ID</label>
          <Input type='text' name='staffId' inputRef={staffIdRef} onKeyDown={onKeyDown} disabled={grants.readGrantDivision === 3}
           width='12rem'
           />
        </li>
        <li>
          <label>設置場所</label>
          <Input type='text' name='staffName' inputRef={staffNameRef} onKeyDown={onKeyDown} disabled={grants.readGrantDivision === 3}
           width='24rem'
           />
        </li>
      </ul>
      {showButton && <Button margin='0 0 0 16px' name='検索' padding='0px' onClick={onClick} />}
      <Tooltip title={'機能メニュー'}>
        <img src={IconFunctionImg} width="24px" height="24px" style={{marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px'}}>
            <FunctionIcon src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
          <hr/>
          <FunctionContentRow onClick={onUpadate}>
            <FunctionIcon src={IconUpdateImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>更新</span></FunctionName>
          </FunctionContentRow>
          <hr/>
          <FunctionContentRow onClick={onAddClick} style={{marginTop:'12px'}}>
            <FunctionIcon src={IconAddImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>機器追加</span></FunctionName>
          </FunctionContentRow>
          <hr/>
          <FunctionContentRow onClick={onPageTypeChangeClick}>
            <FunctionIcon src={IconPageTypeChange}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>表示切替</span></FunctionName>
          </FunctionContentRow>
          <hr/>
          <FunctionContentRow onClick={logout} style={{marginBottom:'12px'}}>
            <FunctionIcon src={IconCloseImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>ログアウト</span></FunctionName>
          </FunctionContentRow>
        </FunctionContent> 
      </li>
    </SearchWrap>
  )
}

Search.propTypes = {
  title: PropTypes.string, // タイトル
  showButton: PropTypes.bool, // 検索ボタン表示
  onClick: PropTypes.func, // 検索処理
  onKeyDown: PropTypes.func, // キーダウン処理
  staffIdRef: PropTypes.object, // 検索項目関連
  staffNameRef: PropTypes.object, // 検索項目関連
  firstSearch: PropTypes.bool, // 最初に検索処理をするか
  grants: PropTypes.object, // 権限関連
  inSubChildren: PropTypes.bool, // その他メニューをサブメニューに表示 メインには表示しない
  addStyle: PropTypes.string, // css用追加用
  children: PropTypes.any, // 子要素
  viewFunction : PropTypes.bool, // 機能メニューを表示・非表示フラッグ
  onClickFunction: PropTypes.func, // 機能メニューを表示する処理
  onExport: PropTypes.func, //エクスポート処理
  onImport: PropTypes.func, //インポート処理
}

export default Search
