import React, { useState, useEffect, useRef ,useCallback} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import { useHistory } from 'react-router'
import { Prompt } from 'react-router-dom';
import { GetApi ,MultipartApi} from '../Common/ApiAxios'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../Common/ErrorHandling'
import FUNC from '../Common/FunctionEnum'
import AttendancePanelWrap, { AttendanceFlexWrap,AttendanceCountPanelWrap,AttendancePagingPanelWrap,PagingIconImg,AttendancePagingIconPanelWrap,PagingIconNextImg,AttendancePageButtonWrap } from './Wrap/AttendancePanelWrap'
import Search from './Search'
import DailyTable from './Molcules/DailyTable/DailyTable'

import { calcRestHours,format48Time,timeToSec,calcWorkingHours,formatRound48Time, diff48Time,makeElementIdByDailyWorkInfo,ValidUpdate,calcWorkingSeconds,calcWorkingRestFreeSeconds,makeAttendanceCSV,dateFormat,formatHour,calcEarlyLateTime} from '../Common/Utilties/LaborUtility'

import IconPageNextImg from '../../image/PageNext.png'
import IconPageBackImg from '../../image/PageBack.png'
import IconPageLastImg from '../../image/PageLast.png'
import IconPageTopImg from '../../image/PageTop.png'
import PaymentDuductionDialog from './Molcules/PaymentDuductionDialog/PaymentDuductionModal'

//import ImportDialog from './Molcules/ImportDialog'

import * as ExcelJS from 'exceljs';
//import { SubscriptionsOutlined } from '@material-ui/icons'

export const DefaultUrl = '/api/attendanceManagement/attendance'

const Attendance = (props) => {
  const fromDateRef = useRef()
  const toDateRef = useRef()
  const staffIdRef = useRef()
  const staffNameRef = useRef()
  const [searchOption, setSearchOption] = useState({ shiftDt: '', staffId: '', staffName: '',postType:'',postId:'',departmentId:'' })
  const { loginUserInfo } = useSelector(state => state)
  const [staffWorkInfoList, setStaffWorkInfoList] = useState([])
  const [staffWorkInfoAllList, setStaffWorkInfoAllList] = useState([])
  const [dateDataList, setDateDataList] = useState([])
  const [pagenation, setPagenation] = useState({})
  const [staffCount, setStaffCount] = useState(0)
  const dispatch = useDispatch()
  const alert = useAlert()
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.ATTENDANCE)
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [viewFunction,setViewFunction] = useState(false)
  const pageRecord = 12
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()

  const [workDivisions, setWorkDivisions] = useState(<></>)
  const [restFlag,setRestFlag] = useState(false)//休憩時間の選択　true:開放,false:３つ

  const onChangeRestType=()=>{
    setRestFlag(restFlag)
  }

  // 遷移可能かどうか判定
  const handleBlockedNavigation = () => {
    var updateFlag = false
    staffWorkInfoAllList.forEach((staffWorkInfo)=>{
      staffWorkInfo.dailyWorkInfoList.forEach(dailyWorkInfo => {
        if(dailyWorkInfo.workInfoList.length != 1) return
        dailyWorkInfo.workInfoList.forEach((workInfo)=>{
          if(workInfo.isDiff) updateFlag = true
        })
    })})
    if (updateFlag) {
      const is_ok = window.confirm('編集中のデータを破棄しますか');
      return is_ok;
    }
    return true;
  };

  //出勤区分取得
  const getWorkDivisions = useCallback(async () => {
    //dispatch(SetLoadSpinner(true))
    const result = await GetApi('/api/masterManagement/classificationValues/workDivisions')
    //dispatch(SetLoadSpinner(false))
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setWorkDivisions(options)
    }
  }, [setWorkDivisions, alert])

  // 検索APIの実行
  const onSearch = async (createId, page) => {
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
    // 機能メニューを非表示に変更
    setViewFunction(false);

    var updateFlag = false
    staffWorkInfoAllList.forEach((staffWorkInfo)=>{
      staffWorkInfo.dailyWorkInfoList.forEach(dailyWorkInfo => {
        if(dailyWorkInfo.workInfoList.length != 1) return
        dailyWorkInfo.workInfoList.forEach((workInfo)=>{
          if(workInfo.isDiff) updateFlag = true
        })
    })})

    if(updateFlag){
      const is_ok = window.confirm('編集中のデータを破棄しますか');
      if(!is_ok)
      {
        console.log( fromDateRef.current,searchOption.startDt,toDateRef.current,searchOption.endDt)
        fromDateRef.current = searchOption.startDt
        toDateRef.current = searchOption.endDt
        return searchOption
      }
    }

    dispatch(SetLoadSpinner(true))
    await getWorkDivisions()
    setSearchOption(
      {
        startDt: fromDateRef.current,
        endDt: toDateRef.current,
        staffId: (staffIdRef.current)?staffIdRef.current.value:'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        postType:postType,
        postId:postId,
        departmentId:departmentId
      }
    )
    const data = {
      startDt: fromDateRef.current,
      endDt: toDateRef.current,
      staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      page: page
    }
    console.log(data)
    //const resultDate = await GetApi('/api/masterManagement/calendar/month', { targetDt: attendanceDateRef.current })
    const resultDate = await GetApi('/api/masterManagement/calendar/range', { startDt: fromDateRef.current, endDt: toDateRef.current })
    if (resultDate.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(resultDate, alert)
      return null
    } else {
      //setDataDateList(resultDate.data.responseList)
    }
    const resultAttendance = await GetApi(DefaultUrl + '/daily', data)

    if (resultAttendance.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(resultAttendance, alert)
      return null
    } else {
      setDateDataList(resultDate.data.responseList)
      setStaffWorkInfoAllList(resultAttendance.data.responseList.slice())
      var count = 0
      resultAttendance.data.responseList.forEach((staffWorkInfo)=>{
        count += staffWorkInfo.dailyWorkInfoList.length
      })
      setStaffCount(count)
      setPageLast(Math.ceil(count/pageRecord))
      console.log(page)
      setPageNum(page)
      setPagenation(resultAttendance.data)
      var tmp = resultAttendance.data.responseList.slice()
      makePage(tmp,page)
    }
    dispatch(SetLoadSpinner(false))
  }
  //ページング設定
  const makePage = (data,page) =>{
    if(page!=0){
      console.log(data)
      console.log(page)
      var minRecord = (page-1)*pageRecord
      var maxRecord = (page*pageRecord)
      var count = 0 
      var tmpList = new Array()
      data.forEach((staffWorkInfo)=>{
        var recordLen = staffWorkInfo.dailyWorkInfoList.length
        //console.log(staffWorkInfo.dailyWorkInfoList)
        var beforeCount = count
        count += recordLen
        var start = -1
        var end = -1
        if(count>minRecord && beforeCount<maxRecord){
          if(beforeCount < minRecord){
            start = minRecord - beforeCount
          }else{
            start = 0
          }

          if(count < maxRecord){
            end = recordLen
            //console.log(start,end)
          }else{
            end = maxRecord - beforeCount
          }
          
        }
        //console.log(beforeCount,count)
        var tmp = new Array() 
        if(start != -1 && end != -1){
            var tmpCount = 0
            staffWorkInfo.dailyWorkInfoList.forEach((dailyList)=>{
              if(tmpCount>=start && tmpCount<end)
              tmp.push(dailyList)
              tmpCount+=1
            }
          )
        }
        tmpList.push({dailyWorkInfoList:tmp,
          staffId: staffWorkInfo.staffId,
          staffName: staffWorkInfo.staffName
        })
      })
      setStaffWorkInfoList(tmpList)
    }else{
      setStaffWorkInfoList(data)
    }
    
  }
  
  // エクスポートAPIの実行
  const onExport = async (createId, page) => {
      //dispatch(SetLoadSpinner(true))
  
      const data = {
        startDt: fromDateRef.current,
        endDt: toDateRef.current,
        staffId: grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value,
        staffName: staffNameRef.current.value,
        postType:postType,
        postId:(postId!=undefined && postId != -1) ? postId:'',
        departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',  
        page: page
      }
  
      //const resultDate = await GetApi('/api/masterManagement/calendar/month', { targetDt: attendanceDateRef.current })
      const resultDate = await GetApi('/api/masterManagement/calendar/range', { startDt: fromDateRef.current, endDt: toDateRef.current })
      if (resultDate.errorDetail) {
        dispatch(SetLoadSpinner(false))
        ErrorHandling(resultDate, alert)
        return null
      } else {
        //setDataDateList(resultDate.data.responseList)
      }

      const resultAttendance = await GetApi(`${DefaultUrl}/Export`, data)
      console.log(resultAttendance)
      dispatch(SetLoadSpinner(false))
      if (resultAttendance.errorDetail) {
        ErrorHandling(resultAttendance, alert)
        return null
      }
      console.log(resultAttendance)
      console.log(resultAttendance.data.csvOutputWorkInfoList)
      console.log(resultAttendance.data.header)
      console.log(resultAttendance.data.nowDate)

      const nowDate = resultAttendance.data.nowDate

      //休憩データ追加
      resultAttendance.data.csvOutputWorkInfoList.forEach((dailyRow)=>{
        dailyRow.restList = []
        resultAttendance.data.restInfoList.forEach((restInfo)=>{
          if(dailyRow.staffId !=  restInfo.staffId) return
          if(dailyRow.orderId !=  restInfo.orderId) return
          if(dailyRow.baseDt !=  restInfo.baseDt) return
          
          let restRow = {
            attendanceRestStartDt:restInfo.attendanceRestStartDt,
            attendanceRestEndDt:restInfo.attendanceRestEndDt
          }
          dailyRow.restList.push(restRow)
        })
      })

      //BOM情報付与(utf-8 文字コードでcsvが開く情報付与)
      let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
      //勤怠データ出力
      {
        const exportData = makeAttendanceCSV(resultAttendance.data.csvOutputWorkInfoList,resultAttendance.data.header)
        const blob = new Blob([bom,exportData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "勤怠管理("+nowDate+").csv"; 
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      }


      //休憩データ出力
      {
        //const exportData = makeAttendanceRestCSV(resultAttendance.data.restInfoList)
        // const blob = new Blob([bom,exportData], { type: 'text/csv' });
        // const url = window.URL.createObjectURL(blob);
        // const a = document.createElement("a");
        // a.href = url;
        // a.download = "勤怠管理休憩情報("+nowDate+").csv"; 
        // a.click();
        // a.remove();
        // window.URL.revokeObjectURL(url);
      }

  }

  const [actionType, setActionType] = useState('POST')
  // POST・PUT処理
  const onSubmit = (data) => {
    async function sendPost (requestBody, data) {
      var resultAttendance
      dispatch(SetLoadSpinner(true))
      console.log(data)
      resultAttendance = 
        await MultipartApi(`${DefaultUrl}`,actionType, requestBody, data)
      
      if (!ErrorHandling(resultAttendance, alert)) {
        alert.success('保存しました')
        setStaffWorkInfoAllList(resultAttendance.data.responseList.slice())
        var count = 0
        resultAttendance.data.responseList.forEach((staffWorkInfo)=>{
          count += staffWorkInfo.dailyWorkInfoList.length
        })
        setStaffCount(count)
        setPageLast(Math.ceil(count/pageRecord))
        setPageNum(pageNum)
        setPagenation(resultAttendance.data)
        var tmp = resultAttendance.data.responseList.slice()
        makePage(tmp,pageNum)
        setActionType('POST')
      }
      dispatch(SetLoadSpinner(false))
    }
    if(staffWorkInfoList.length==0){
      alert.error("更新するデータがありません")
      return
    }
    const submitData = new window.FormData()
    console.log(staffWorkInfoList)
  
    data = makeSendData(data)
    console.log(data)
    if(ValidUpdate(data))
    {
      alert.error("入力項目にエラーがあります")
      return
    }

    //再検索用情報付与
    data = {'formDatas': data,
            'startDt': searchOption.startDt,
            'endDt': searchOption.endDt,
            'staffId': searchOption.staffId,
            'staffName': searchOption.staffName,
            'postType':searchOption.postType,
            'postId':(searchOption.postId!=undefined && searchOption.postId != -1) ? searchOption.postId:'',
            'departmentId':(searchOption.departmentId != null && searchOption.departmentId!=undefined)? searchOption.departmentId:'',
            'page': 1}
    console.log(data.startDt)
    if(data.startDt=='' || data.formDatas.length==0)
    {
        alert.error("更新するデータがありません")
        return
    }
    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    sendPost(submitData, data)
  }
  //更新データ作成処理
  const makeSendData = (data) =>
  {
    var dailyList= new Array();
    staffWorkInfoAllList.forEach((staffWorkInfo)=>{
      staffWorkInfo.dailyWorkInfoList.forEach(dailyWorkInfo => {
        if(dailyWorkInfo.workInfoList.length != 1) return
        //let id = makeElementIdByDailyWorkInfo(dailyWorkInfo.workInfoList[0])
        dailyWorkInfo.workInfoList.forEach((workInfo)=>{
          // let dailyRow = {
          //     orderId: workInfo.orderId, //getValues('orderId_'+id),
          //     staffId: workInfo.staffId, //getValues('staffId_'+id),
          //     baseDt: workInfo.baseDt, //getValues('baseDt_'+id),
              
          //     workDivision: getValues('attendanceWorkDivision_'+id),

          //     clockWorkStartDt: "",
          //     clockWorkEndDt: "",
          //     clockWorkTotalDt: "",
          //     clockRestStart1Dt: "",
          //     clockRestEnd1Dt: "",
          //     clockRestStart2Dt: "",
          //     clockRestEnd2Dt: "",
          //     clockRestStart3Dt: "",
          //     clockRestEnd3Dt: "",
          //     clockRestTotalDt: "",

          //     workStartDt: getValues('attendanceWorkStartDt_'+id),
          //     workEndDt: getValues('attendanceWorkEndDt_'+id),
          //     workTotalDt: getValues('attendanceWorkTotalDt_'+id),
          //     restStart1Dt: getValues('attendanceRestStart1Dt_'+id),
          //     restEnd1Dt: getValues('attendanceRestEnd1Dt_'+id),
          //     restStart2Dt: getValues('attendanceRestStart2Dt_'+id),
          //     restEnd2Dt: getValues('attendanceRestEnd2Dt_'+id),
          //     restStart3Dt: getValues('attendanceRestStart3Dt_'+id),
          //     restEnd3Dt: getValues('attendanceRestEnd3Dt_'+id),
          //     restTotalDt: getValues('attendanceRestTotalDt_'+id),
              
          //     overTotalDt:null,

          //     remark: null,
          //     updatedAt: dateFormat(workInfo.attendanceUpdatedAt)
          //   }
            
          //   let workInfoRow = {
          //     workDivision: workInfo.attendanceWorkDivision,
          //     workStartDt: diff48Time(workInfo.baseDt,workInfo.attendanceWorkStartDt),
          //     workEndDt: diff48Time(workInfo.baseDt,workInfo.attendanceWorkEndDt),
          //     restStart1Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestStart1Dt),
          //     restEnd1Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd1Dt),
          //     restStart2Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestStart2Dt),
          //     restEnd2Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd2Dt),
          //     restStart3Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestStart3Dt),
          //     restEnd3Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd3Dt),

          //     remark: null
          //   }
          //   //差異チェック
          //   if(checkUpdate(workInfoRow,dailyRow))
          //   {
          //     //勤務時間,休憩時間計算
          //     let result = calcWorkingSeconds([dailyRow.workStartDt, dailyRow.workEndDt], 
          //         [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, 
          //           dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt], workInfo.attendanceBaseTime)
          //     dailyRow.workTotalDt = result[0]
          //     dailyRow.restTotalDt = result[1]
          //     dailyRow.overTotalDt = result[2]

          //     dailyList.push(dailyRow)
          //   }

          var restList = new Array()
          var addRestList = new Array()
          var deleteRestList = new Array()
          var updateRestList = new Array()
          workInfo.restList.map((restData)=>{
            if(restData.restNum == null) return

            if(!restData.isDel){
              restList.push(restData.attendanceRestStartDt)
              restList.push(restData.attendanceRestEndDt)
            }
            if(restData.isDiff == undefined)return;
            if(restData.isDiff){
              if(restData.isNew){
                addRestList.push(restData)
              }else if(restData.isDel){
                deleteRestList.push(restData)
              }else{
                updateRestList.push(restData)
              }
                
            }
          })
  
          let dailyRow = {
              orderId: workInfo.orderId, //getValues('orderId_'+id),
              staffId: workInfo.staffId, //getValues('staffId_'+id),
              baseDt: workInfo.baseDt, //getValues('baseDt_'+id),
              workDivision: workInfo.attendanceWorkDivision,
              workStartDt: (workInfo.attendanceWorkStartDt),
              workEndDt: (workInfo.attendanceWorkEndDt),
              restStart1Dt: (workInfo.attendanceRestStart1Dt),
              restEnd1Dt: (workInfo.attendanceRestEnd1Dt),
              restStart2Dt: (workInfo.attendanceRestStart2Dt),
              restEnd2Dt: (workInfo.attendanceRestEnd2Dt),
              restStart3Dt: (workInfo.attendanceRestStart3Dt),
              restEnd3Dt: (workInfo.attendanceRestEnd3Dt),

              deleteRestList: deleteRestList,
              updateRestList: updateRestList,
              addRestList: addRestList,
              
              remark: null,
              updatedAt: dateFormat(workInfo.attendanceUpdatedAt)
          }
          //差異チェック
          if(workInfo.isDiff)
          {
              //休憩時間開放しないversion
              if(!staffWorkInfo.restFlag) restList = [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt,dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt]
              //勤務時間,休憩時間計算
              let result = calcWorkingRestFreeSeconds([dailyRow.workStartDt, dailyRow.workEndDt], restList, workInfo.attendanceBaseTime)
              dailyRow.workTotalDt = result[0]
              dailyRow.restTotalDt = result[1]
              dailyRow.overTotalDt = result[2]
              dailyRow.nightTime = result[3]

              dailyList.push(dailyRow)

              console.log(workInfo)
          }
        })
      })
    })
    console.log(dailyList)
    return dailyList
  }

  //勤怠再計算用データ作成
  const makeRecalculationData = () =>
  {
    async function sendGet(data){
      //再計算データ取得処理
      const resultAttendance = await GetApi(`${DefaultUrl}/recalculation`, data)
      if (ErrorHandling(resultAttendance, alert)) {
        return null
      }
      return resultAttendance.data.responseList
    }

    var dailyList= new Array();
    staffWorkInfoList.forEach((staffWorkInfo)=>{
      staffWorkInfo.dailyWorkInfoList.forEach(dailyWorkInfo => {
        let id = makeElementIdByDailyWorkInfo(dailyWorkInfo.workInfoList[0])
        dailyWorkInfo.workInfoList.forEach((workInfo)=>{
          // let dailyRow = {
          //     orderId: workInfo.orderId, //getValues('orderId_'+id),
          //     staffId: workInfo.staffId, //getValues('staffId_'+id),
          //     baseDt: workInfo.baseDt, //getValues('baseDt_'+id),
              
          //     workDivision: getValues('attendanceWorkDivision_'+id),

          //     clockWorkStartDt: "",
          //     clockWorkEndDt: "",
          //     clockWorkTotalDt: "",
          //     clockRestStart1Dt: "",
          //     clockRestEnd1Dt: "",
          //     clockRestStart2Dt: "",
          //     clockRestEnd2Dt: "",
          //     clockRestStart3Dt: "",
          //     clockRestEnd3Dt: "",
          //     clockRestTotalDt: "",

          //     workStartDt: getValues('attendanceWorkStartDt_'+id),
          //     workEndDt: getValues('attendanceWorkEndDt_'+id),
          //     workTotalDt: getValues('attendanceWorkTotalDt_'+id),
          //     restStart1Dt: getValues('attendanceRestStart1Dt_'+id),
          //     restEnd1Dt: getValues('attendanceRestEnd1Dt_'+id),
          //     restStart2Dt: getValues('attendanceRestStart2Dt_'+id),
          //     restEnd2Dt: getValues('attendanceRestEnd2Dt_'+id),
          //     restStart3Dt: getValues('attendanceRestStart3Dt_'+id),
          //     restEnd3Dt: getValues('attendanceRestEnd3Dt_'+id),
          //     restTotalDt: getValues('attendanceRestTotalDt_'+id),
              
          //     overTotalDt:null,

          //     remark: getValues('attendanceRemark_'+id),
          //     updatedAt: dateFormat(workInfo.attendanceUpdatedAt)
          //   }
          //   const data = {
          //     orderId: dailyRow.orderId,
          //     staffId: dailyRow.staffId,
          //     attendanceDt: dailyRow.baseDt
          //   }
          //   const calcData = sendGet(data)
          //   端数調整処理
          //   dailyRow.workStartDt = formatRound48Time(dailyRow.workStartDt,calcData.fractionUnit)
          //   dailyRow.workEndDt = formatRound48Time(dailyRow.workEndDt,calcData.fractionUnit*(-1))
          //   dailyRow.restStart1Dt = formatRound48Time(dailyRow.restStart1Dt,calcData.fractionUnit*(-1))
          //   dailyRow.restEnd1Dt = formatRound48Time(dailyRow.restEnd1Dt,calcData.fractionUnit)
          //   dailyRow.restStart2Dt = formatRound48Time(dailyRow.restStart2Dt,calcData.fractionUnit*(-1))
          //   dailyRow.restEnd2Dt = formatRound48Time(dailyRow.restEnd2Dt,calcData.fractionUnit)
          //   dailyRow.restStart3Dt = formatRound48Time(dailyRow.restStart3Dt,calcData.fractionUnit*(-1))
          //   dailyRow.restEnd3Dt = formatRound48Time(dailyRow.restEnd3Dt,calcData.fractionUnit)
          //   勤務時間,休憩時間計算
          //   let result = calcWorkingSeconds([dailyRow.workStartDt, dailyRow.workEndDt], 
          //       [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, 
          //       dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt], calcData.attendanceBaseTime)
          //   dailyRow.workTotalDt = result[0]
          //   dailyRow.restTotalDt = result[1]
          //   dailyRow.overTotalDt = result[2]

          //   let workInfoRow = {
          //     workDivision: workInfo.attendanceWorkDivision,
          //     workStartDt: diff48Time(workInfo.baseDt,workInfo.attendanceWorkStartDt),
          //     workEndDt: diff48Time(workInfo.baseDt,workInfo.attendanceWorkEndDt),
          //     restStart1Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestStart1Dt),
          //     restEnd1Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd1Dt),
          //     restStart2Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestStart2Dt),
          //     restEnd2Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd2Dt),
          //     restStart3Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestStart3Dt),
          //     restEnd3Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd3Dt),

          //     remark: null
          //   }
          //   差異チェック
          //   if(checkUpdate(workInfoRow,dailyRow))
          //   {
          //       勤務時間,休憩時間計算
          //       let result = calcWorkingSeconds([dailyRow.workStartDt, dailyRow.workEndDt], 
          //             [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, 
          //             dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt], workInfo.attendanceBaseTime)
          //       dailyRow.workTotalDt = result[0]
          //       dailyRow.restTotalDt = result[1]
          //       dailyRow.overTotalDt = result[2]
            
          //       dailyList.push(dailyRow)
          //   }

          let dailyRow = {
            orderId: workInfo.orderId, //getValues('orderId_'+id),
            staffId: workInfo.staffId, //getValues('staffId_'+id),
            baseDt: workInfo.baseDt, //getValues('baseDt_'+id),

            workDivision: workInfo.attendanceWorkDivision,
            workStartDt: (workInfo.isDiff)?(workInfo.attendanceWorkStartDt):diff48Time(workInfo.baseDt,workInfo.attendanceWorkStartDt),
            workEndDt: (workInfo.isDiff)?(workInfo.attendanceWorkEndDt):diff48Time(workInfo.baseDt,workInfo.attendanceWorkEndDt),
            restStart1Dt: (workInfo.isDiff)?(workInfo.attendanceRestStart1Dt):diff48Time(workInfo.baseDt,workInfo.attendanceRestStart1Dt),
            restEnd1Dt: (workInfo.isDiff)?(workInfo.attendanceRestEnd1Dt):diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd1Dt),
            restStart2Dt: (workInfo.isDiff)?(workInfo.attendanceRestStart2Dt):diff48Time(workInfo.baseDt,workInfo.attendanceRestStart2Dt),
            restEnd2Dt: (workInfo.isDiff)?(workInfo.attendanceRestEnd2Dt):diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd2Dt),
            restStart3Dt: (workInfo.isDiff)?(workInfo.attendanceRestStart3Dt):diff48Time(workInfo.baseDt,workInfo.attendanceRestStart3Dt),
            restEnd3Dt: (workInfo.isDiff)?(workInfo.attendanceRestEnd3Dt):diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd3Dt),

            remark: null,
            updatedAt: dateFormat(workInfo.attendanceUpdatedAt)
          }
            const data = {
              orderId: dailyRow.orderId,
              staffId: dailyRow.staffId,
              attendanceDt: dailyRow.baseDt
            }
            const calcData = sendGet(data)
            //端数調整処理
            dailyRow.workStartDt = formatRound48Time(dailyRow.workStartDt,calcData.fractionUnit)
            dailyRow.workEndDt = formatRound48Time(dailyRow.workEndDt,calcData.fractionUnit*(-1))
            dailyRow.restStart1Dt = formatRound48Time(dailyRow.restStart1Dt,calcData.fractionUnit*(-1))
            dailyRow.restEnd1Dt = formatRound48Time(dailyRow.restEnd1Dt,calcData.fractionUnit)
            dailyRow.restStart2Dt = formatRound48Time(dailyRow.restStart2Dt,calcData.fractionUnit*(-1))
            dailyRow.restEnd2Dt = formatRound48Time(dailyRow.restEnd2Dt,calcData.fractionUnit)
            dailyRow.restStart3Dt = formatRound48Time(dailyRow.restStart3Dt,calcData.fractionUnit*(-1))
            dailyRow.restEnd3Dt = formatRound48Time(dailyRow.restEnd3Dt,calcData.fractionUnit)
            //勤務時間,休憩時間計算
            let result = calcWorkingSeconds([dailyRow.workStartDt, dailyRow.workEndDt], 
                [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, 
                dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt], calcData.attendanceBaseTime)
            dailyRow.workTotalDt = result[0]
            dailyRow.restTotalDt = result[1]
            dailyRow.overTotalDt = result[2]
            dailyRow.nightTime = result[3]
            var restList = new Array()
            var addRestList = new Array()
            var deleteRestList = new Array()
            var updateRestList = new Array()
            workInfo.restList.map((restData)=>{
              if(restData.restNum == null) return
      
              restData.attendanceRestStartDt = formatRound48Time(restData.attendanceRestStartDt,calcData.fractionUnit*(-1))
              restData.attendanceRestEndDt = formatRound48Time(restData.attendanceRestEndDt,calcData.fractionUnit)
              if(!restData.isDel){
                restList.push(restData.attendanceRestStartDt)
                restList.push(restData.attendanceRestEndDt)
              }
              if(restData.isNew){
                addRestList.push(restData)
              }else if(restData.isDel){
                deleteRestList.push(restData)
              }else{
                updateRestList.push(restData)
              }
            })
            dailyRow.addRestList = addRestList
            dailyRow.deleteRestList = deleteRestList
            dailyRow.updateRestList = updateRestList

            let workInfoRow = {
              workDivision: workInfo.attendanceWorkDivision,
              workStartDt: (workInfo.isDiff)?(workInfo.attendanceWorkStartDt):diff48Time(workInfo.baseDt,workInfo.attendanceWorkStartDt),
              workEndDt: (workInfo.isDiff)?(workInfo.attendanceWorkEndDt):diff48Time(workInfo.baseDt,workInfo.attendanceWorkEndDt),
              restStart1Dt: (workInfo.isDiff)?(workInfo.attendanceRestStart1Dt):diff48Time(workInfo.baseDt,workInfo.attendanceRestStart1Dt),
              restEnd1Dt: (workInfo.isDiff)?(workInfo.attendanceRestEnd1Dt):diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd1Dt),
              restStart2Dt: (workInfo.isDiff)?(workInfo.attendanceRestStart2Dt):diff48Time(workInfo.baseDt,workInfo.attendanceRestStart2Dt),
              restEnd2Dt: (workInfo.isDiff)?(workInfo.attendanceRestEnd2Dt):diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd2Dt),
              restStart3Dt: (workInfo.isDiff)?(workInfo.attendanceRestStart3Dt):diff48Time(workInfo.baseDt,workInfo.attendanceRestStart3Dt),
              restEnd3Dt: (workInfo.isDiff)?(workInfo.attendanceRestEnd3Dt):diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd3Dt),

              remark: null
            }
            
            //差異チェック
            //if(checkUpdate(workInfoRow,dailyRow))
            {
                //勤務時間,休憩時間計算
                // let result = calcWorkingSeconds([dailyRow.workStartDt, dailyRow.workEndDt], 
                //       [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, 
                //       dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt], workInfo.attendanceBaseTime)
                if(!staffWorkInfo.restFlag) restList = [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt,dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt]
                let result = calcWorkingRestFreeSeconds([dailyRow.workStartDt, dailyRow.workEndDt], restList, calcData.attendanceBaseTime)
                dailyRow.workTotalDt = result[0]
                dailyRow.restTotalDt = result[1]
                dailyRow.overTotalDt = result[2]
                dailyRow.nightTime = result[3]

                dailyList.push(dailyRow)
            }
        })
      })
    })

    return dailyList
  }
  //テキスト位置
  const headerAlignment = { vertical: 'middle', horizontal: 'center' };
  /** 枠線のスタイル */
  const borderEvenStyle = {
    top: { style: 'thin', color: { argb: 'FF8FAADC' } },
    left: { style: 'thin', color: { argb: 'FFDAE3F3' } },
    bottom: { style: 'thin', color: { argb: 'FF8FAADC' } },
    right: { style: 'thin', color: { argb: 'FFDAE3F3' } },
  };
  /** 枠線のスタイル */
  const borderOddStyle = {
    top: { style: 'thin', color: { argb: 'FF8FAADC' } },
    left: { style: 'thin', color: { argb: 'FFCCCCCC' } },
    bottom: { style: 'thin', color: { argb: 'FF8FAADC' } },
    right: { style: 'thin', color: { argb: 'FFCCCCCC' } },
  };
  /** ヘッダ行の背景色 */
  const headerFillStyle = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FF4472C4' },
  };
  /** ヘッダ行のフォント */
  const headerFontStyle = {
    bold: true,
    name: 'Yu Gothic UI',
    size: 9,
    color: { argb: 'FFFFFFFF' } 
  };
  /** ヘッダ行以外のフォント */
  const cellFontStyle = {
    name: 'Yu Gothic UI',
    size: 9
  };
  /** 偶数行の背景色 */
  const bodyEvenFillStyle = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFDAE3F3' },
  };
  /** 奇数行の背景色 */
  const bodyOddFillStyle = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
  };
  //給与明細出力処理
  const  onClickPayment = async () =>{
      function makeExcelHistory(history,staffDivisionName){
        //合計行用
        var sumShiftWorkTime = 0;
        var sumShiftRestTime = 0;
        var sumAttendanceWorkTime = 0;
        var sumAttendanceRestTime = 0;
        var sumAttendanceEarlyLateTime = 0;
        var sumAttendanceNormalTime = 0;
        var sumAttendanceOverTime = 0;
        var sumAttendanceNightTime = 0;
        var workTimes = 0;
        //2020/10/19 H.Watanabe ADD(交通費)
        var transportationExpenses = 0;
        var sumTransportationExpenses = 0;

        var fractionUnit = 1;
        history.map((daily)=>{
          daily.dateValue = daily.baseDt.split('-')[1]+'/'+daily.baseDt.split('-')[2]
          //勤務と有給の場合
          if(daily.attendanceWorkDivision==2 || daily.attendanceWorkDivision==3 
            || daily.attendanceWorkDivision==4 || daily.attendanceWorkDivision==5
            || daily.attendanceWorkDivision==9){
            workTimes++;
          }
          else if(daily.attendanceWorkDivision!=6){
            daily.attendanceWorkDivisionName = ''
          }
          daily.shiftWorkTotalTime=formatHour(daily.shiftWorkTotalDt)
          daily.shiftRestTotalTime=formatHour(daily.shiftRestTotalDt) 
          daily.shiftWorkStartTime = formatRound48Time(diff48Time(daily.baseDt, daily.shiftWorkStartDt),fractionUnit)
          daily.shiftWorkEndTime = formatRound48Time(diff48Time(daily.baseDt, daily.shiftWorkEndDt),fractionUnit*(-1))

          daily.attendanceWorkTotalTime=formatHour(daily.attendanceWorkTotalDt)
          daily.attendanceRestTotalTime=formatHour(daily.attendanceRestTotalDt)
          daily.attendanceWorkStartTime = formatRound48Time(diff48Time(daily.baseDt, daily.attendanceWorkStartDt),fractionUnit)
          daily.attendanceWorkEndTime = formatRound48Time(diff48Time(daily.baseDt, daily.attendanceWorkEndDt),fractionUnit*(-1))
          
          daily.attendanceEarlyLateTime = calcEarlyLateTime(daily.shiftWorkStartTime
            ,daily.shiftWorkEndTime,daily.attendanceWorkTotalTime,daily.attendanceWorkEndTime)       
          daily.attendanceEarlyLateTime = (daily.attendanceWorkDivision==6)?8.00:daily.attendanceEarlyLateTime
          //宇野紙工版のみ　社員の時スタッフ勤怠設定マスタの所定労働時間でattendanceEarlyLateTimeを計算
          if((daily.attendanceWorkDivision==2 || daily.attendanceWorkDivision==3 || daily.attendanceWorkDivision==4) && staffDivisionName!=3){
            daily.attendanceEarlyLateTime = calcEarlyLateTime(
              (diff48Time('1900-01-01T00:00:00.000+00:00', daily.workingStartTime))
              ,(diff48Time('1900-01-01T00:00:00.000+00:00', daily.workingEndTime))
              ,daily.attendanceWorkTotalTime,daily.attendanceWorkEndTime)   
          }

          switch (daily.attendanceWorkDivision)
          {
              case 2:
                daily.attendanceNormalTime = formatHour(daily.attendanceNormalTime)
                daily.attendanceOverTime = formatHour(daily.attendanceOverTime)
                daily.attendanceNightTime = formatHour(daily.attendanceNightTime)
                break;
              case 3:
                daily.attendanceNormalTime = formatHour(daily.attendanceHolNormalTime)
                daily.attendanceOverTime = formatHour(daily.attendanceHolOverTime)
                daily.attendanceNightTime = formatHour(daily.attendanceHolNightTime)
                break;
              case 4:
                daily.attendanceNormalTime = formatHour(daily.attendanceLawNormalTime)
                daily.attendanceOverTime = formatHour(daily.attendanceLawOverTime)
                daily.attendanceNightTime = formatHour(daily.attendanceLawNightTime)
                break;
              case 5:
              case 9:
                daily.attendanceNormalTime = formatHour(daily.workingTime)
                daily.attendanceOverTime = formatHour(daily.attendanceLawOverlTime)
                daily.attendanceNightTime = formatHour(daily.attendanceLawNightTime)
                break;
              default:
                daily.attendanceNormalTime = formatHour(0.00)
                daily.attendanceOverTime = formatHour(0.00)
                daily.attendanceNightTime = formatHour(0.00)
                break;
          }

          //========== ==========
          //合計データ
          //========== ==========
          sumShiftWorkTime += parseFloat(daily.shiftWorkTotalTime);
          sumShiftRestTime += parseFloat(daily.shiftRestTotalTime);
          sumAttendanceWorkTime += parseFloat(daily.attendanceWorkTotalTime);
          sumAttendanceRestTime += parseFloat(daily.attendanceRestTotalTime);
          
          sumAttendanceEarlyLateTime += parseFloat(daily.attendanceEarlyLateTime);

          //2022/01/04 H.Watanabe MOD(有給の場合に、所定労働時間を設定する。)
          sumAttendanceNormalTime +=  parseFloat(daily.attendanceNormalTime)            
          sumAttendanceOverTime += parseFloat(daily.attendanceOverTime);
          sumAttendanceNightTime += parseFloat(daily.attendanceNightTime);

          //2020/10/19 H.Watanabe ADD(交通費)
          sumTransportationExpenses += parseFloat(daily.transferCost);
        })

        history.unshift({
          dateValue:'',
          staffId:'',
          week:'合計　',
          attendanceWorkDivisionName: workTimes+'日',
          shiftWorkTotalTime: sumShiftWorkTime,
          shiftRestTotalTime: sumShiftRestTime,
          shiftWorkStartTime: '',
          shiftWorkEndTime: '',
          attendanceWorkTotalTime:sumAttendanceWorkTime,
          attendanceRestTotalTime:sumAttendanceRestTime,
          attendanceWorkStartTime: '',
          attendanceWorkEndTime: '',
          attendanceEarlyLateTime:sumAttendanceEarlyLateTime,
          attendanceNormalTime:sumAttendanceNormalTime,
          attendanceOverTime:sumAttendanceOverTime,
          attendanceNightTime:sumAttendanceNightTime,
          transferCost:sumTransportationExpenses,
        })
      }
      
      dispatch(SetLoadSpinner(true))
  
      const data = {
        targetDt:  fromDateRef.current,
        staffId: grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value,
        staffName: staffNameRef.current.value,
        postType:postType,
        postId:(postId!=undefined && postId != -1) ? postId:'',//'000000',//
        departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      }
      
      const resultAttendance = await GetApi('/api/paymentDuductionManagement/paymentDuduction/payment', data)
      console.log(resultAttendance)
      if (resultAttendance.errorDetail) {
        dispatch(SetLoadSpinner(false))
        ErrorHandling(resultAttendance, alert)
        return null
      }
      const resultRate= await GetApi('/api/paymentDuductionManagement/paymentDuduction/rate', {})
      console.log(resultRate)
      dispatch(SetLoadSpinner(false))
      if (resultRate.errorDetail) {
        ErrorHandling(resultRate, alert)
        return null
      }

      var attendanceList = []
      resultAttendance.data.map((staff)=>{
        var history = staff.paymentHistoryList
        var attendance = staff.paymentAttendanceList

        //スタッフ区分追加
        makeExcelHistory(history,staff.staffDivisionName)
        var workTotal = 0;
        var restTotal = 0;
        var nightTotal = 0;
        var overTotal = 0;
        var workTimes = 0;

        //2020/07/14 H.Watanabe ADD(変数追加)
        var saturdayWorkDays= 0;            //土曜出勤日数
        var saturdayWorkTotal = 0;       //土曜勤務時間
        var paidDays = 0;                   //有給日数
        var absenceDays = 0;                //欠勤日数

        //2020/07/15 H.Watanabe ADD(変数追加)
        var lawWork = 0;                     //法出日数
        var lawWorkTotal = 0;             //法出勤務時間(休日手当用)
        var holidayWork = 0;                 //休出日数
        attendance.map((daily)=>{
          //有給・欠勤の日数をカウントする。
          switch (daily.attendanceWorkDivision)
          {
               case 5:       //有給日数
                   paidDays++;
                   return;
               case 6:        //欠勤日数
                   absenceDays++;
                   return;
               case 3:    //休出日数
                   holidayWork++;
                   break;
               case 4:        //法出日数
                   lawWork++;
                   break;
               case 2:
                  break;
               default:
                   return;
          }
          workTimes++

          let fractionUnit = parseInt(daily.attendanceFractionUnit)
          var workStart = formatRound48Time(diff48Time(daily.baseDt, daily.attendanceWorkStartDt),fractionUnit)
          var workEnd = formatRound48Time(diff48Time(daily.baseDt, daily.attendanceWorkEndDt),fractionUnit*(-1))

          var restStart1 = formatRound48Time(diff48Time(daily.baseDt, daily.attendanceRestStart1Dt),fractionUnit*(-1))
          var restEnd1 = formatRound48Time(diff48Time(daily.baseDt, daily.attendanceRestEnd1Dt),fractionUnit*(1))
          var restStart2 = formatRound48Time(diff48Time(daily.baseDt, daily.attendanceRestStart2Dt),fractionUnit*(-1))
          var restEnd2 = formatRound48Time(diff48Time(daily.baseDt, daily.attendanceRestEnd2Dt),fractionUnit*(1))
          var restStart3 = formatRound48Time(diff48Time(daily.baseDt, daily.attendanceRestStart3Dt),fractionUnit*(-1))
          var restEnd3 = formatRound48Time(diff48Time(daily.baseDt, daily.attendanceRestEnd3Dt),fractionUnit*(1))
          
          let result = calcWorkingHours([workStart, workEnd], [restStart1, restEnd1, restStart2, restEnd2, restStart3, restEnd3], daily.attendanceBaseTime)


          workTotal += (parseFloat(result[0]))
          restTotal += parseFloat(result[1])
          overTotal += parseFloat(result[2])
          //土曜出勤をカウントする。
          if (daily.week.indexOf('土') != -1)
          {
              saturdayWorkDays++;
              saturdayWorkTotal += parseFloat(result[0]);
          }
          if(daily.workDivision==4)
          {
              lawWorkTotal += parseFloat(result[0]);
          }
        })

        var rateOvertime = parseFloat(resultRate.data.overRate);
        var rateNight = parseFloat(resultRate.data.nightRate);
        var rateHoliday = parseFloat(resultRate.data.holidayOverRate);
        var rateLaw = parseFloat(resultRate.data.lawNormalRate);

        attendanceList.push({
          dateValue:staff.dateValue,
          staffId:staff.staffId,
          staffName:staff.staffName,
          division:(staff.staffDivisionName==3?'パート':'社員'),
          workTimes:workTimes,
          salariedTimes:paidDays,
          absenceDays:absenceDays,
          saturdayWorkDays:saturdayWorkDays,
          workTotalTimes:workTotal.toFixed(2),
          restTotalTimes:restTotal.toFixed(2),
          normalTimes:(workTotal - overTotal - saturdayWorkTotal - nightTotal).toFixed(2),
          overTimes:overTotal.toFixed(2),
          nightTimes:nightTotal.toFixed(2),
          saturdayWorkTotal:saturdayWorkTotal.toFixed(2),
          payment:(staff.staffDivisionName==3?((workTotal - overTotal - saturdayWorkTotal - nightTotal) * parseInt(staff.daily)):staff.monthly),
          housingAllowance:staff.housingAllowance,
          familyAllowance:staff.familyAllowance,
          jobAllowance:staff.jobAllowance,
          specialAllowance:staff.specialAllowance,
          transportationExpenses:staff.transportationExpenses,
          saturdayPayment: staff.staffDivisionName==3?
            Math.ceil(saturdayWorkTotal * (parseInt(staff.daily) * rateHoliday)):Math.ceil(saturdayWorkTotal * Math.ceil(parseInt(staff.daily) * rateHoliday)),
          nightPayment:staff.staffDivisionName==3?
            Math.ceil(nightTotal * (parseInt(staff.daily) * rateNight)):Math.ceil(nightTotal * Math.ceil(parseInt(staff.daily) * rateNight)),
          overPayment:staff.staffDivisionName==3?
            Math.ceil(overTotal * (parseInt(staff.daily) * rateOvertime)):Math.ceil(overTotal * Math.ceil(parseInt(staff.daily) * rateOvertime)),
          holidayPayment:staff.staffDivisionName==3?
            Math.ceil(lawWorkTotal * (parseInt(staff.daily) * rateLaw)):Math.ceil(lawWorkTotal * Math.ceil(parseInt(staff.daily) * rateLaw)),
          absencePayment:(absenceDays * 8) * (parseInt(staff.daily) * -1),
          assignmentAllowance:0,
        })
      })

      //ヘッダー
      var headerRow = [
        { header:"年月", key:'dateValue'},
        { header:"スタッフID", key:'staffId'},
        { header:"スタッフ名", key:'staffName'},
        { header:"区分", key:'division'},
        { header:"出勤日数", key:'workTimes'},
        { header:"有給日数", key:'salariedTimes'},
        { header:"欠勤日数", key:'absenceDays'},
        { header:"土曜出勤日数", key:'saturdayWorkDays'},
        { header:"勤務時間", key:'workTotalTimes'},
        { header:"休憩時間", key:'restTotalTimes'},
        { header:"基本時間", key:'normalTimes'},
        { header:"残業時間", key:'overTimes'},
        { header:"深夜時間", key:'nightTimes'},
        { header:"土曜勤務時間", key:'saturdayWorkTotal'},
        { header:"基本給", key:'payment'},
        { header:"住宅手当", key:'housingAllowance'},
        { header:"家族手当", key:'familyAllowance'},
        { header:"職務手当", key:'jobAllowance'},
        { header:"特別手当", key:'specialAllowance'},
        { header:"交通費", key:'transportationExpenses'},
        { header:"土曜出勤", key:'saturdayPayment'},
        { header:"深夜手当", key:'nightPayment'},
        { header:"時間外手当", key:'overPayment'},
        { header:"休日手当", key:'holidayPayment'},
        { header:"欠勤", key:'absencePayment'},
        { header:"出向手当", key:'assignmentAllowance'},
      ]

      //csv出力
      {
        //ワークブックを作成する
        let workbook = new ExcelJS.Workbook();
 
        //ワークシートを作成する
        let worksheet = workbook.addWorksheet(  "【"+data.targetDt.split('-')[0]+"年"+data.targetDt.split('-')[1]+"月度】給与明細データ.csv" , {} ) ;

        // 各列のヘッダー
        worksheet.columns = headerRow;
        worksheet.addRows(attendanceList);

        //エクセルファイルを生成する
        let uint8Array = await workbook.csv.writeBuffer(); //csvの場合
        //BOM情報付与(utf-8 文字コードでcsvが開く情報付与)
        let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
        let blob = new Blob([bom,uint8Array], { type: "application/octet-binary" });

        //Excelファイルダウンロード
        let link = document.createElement( "a" );
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = "【"+data.targetDt.split('-')[0]+"年"+data.targetDt.split('-')[1]+"月度】給与明細データ.csv" ;
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }
      {
        //ワークブックを作成する
        let workbook = new ExcelJS.Workbook();

        //ワークシートを作成する
        let worksheet = workbook.addWorksheet( "給与明細データ", {} );

        // 各列のヘッダー
        worksheet.columns = headerRow;
        worksheet.addRows(attendanceList);
        
        //列幅自動生成
        worksheet.columns.forEach(function (column, i) {
          var maxLength = 0;
          column["eachCell"]({ includeEmpty: true }, function (cell) {
              var columnLength = cell.value ? cell.value.toString().length : 0;
              if (columnLength > maxLength ) {
                  maxLength = columnLength;
              }
          });
          column.width = parseFloat(5.58) + parseInt(maxLength)*parseFloat(1);  
        });
        
        // すべての行を走査
        worksheet.eachRow((row, rowNumber) => {
          //高さ
          row.height = 12;
          // すべてのセルを走査
          row.eachCell((cell, colNumber) => {
            if (rowNumber === 1) {
              // ヘッダ行のスタイルを設定
              cell.fill = headerFillStyle;
              cell.font = headerFontStyle;
              cell.alignment = headerAlignment;
            } else {
              if (rowNumber % 2 === 0) {
                // ボディ行（偶数行）のスタイルを設定
                cell.fill = bodyEvenFillStyle;
                cell.border = borderEvenStyle;
              } else {
                // ボディ行（奇数行）のスタイルを設定
                cell.fill = bodyOddFillStyle;
                cell.border = borderOddStyle;
              }
              // ボディ行のフォントを設定
              cell.font = cellFontStyle;
              
            }
            // セルの枠線を設定
            if(colNumber >=15){
              cell.numFmt ="#,##0"
            }            
          });
          // 行の設定を適用
          row.commit();
        });

        resultAttendance.data.map((staff)=>{
          var history = staff.paymentHistoryList
          //ワークシートを作成する
          let staffWorksheet = workbook.addWorksheet( staff.staffName+'('+ staff.staffId +')', {} ) ;
          staffWorksheet.getCell('A1').value = "【氏名】：" + staff.staffName+'('+ staff.staffId +')'
          staffWorksheet.getCell('A1').font =
          {
            bold: true,
            name: 'Yu Gothic UI',
            size: 10,
            //color: { argb: 'FFFFFFFF' } 
          };

          staffWorksheet.getCell('E1').value = '住宅手当'
          staffWorksheet.getCell('E1').font = headerFontStyle
          staffWorksheet.getCell('E1').fill = headerFillStyle
          staffWorksheet.getCell('F1').value = '職務手当'
          staffWorksheet.getCell('F1').font = headerFontStyle
          staffWorksheet.getCell('F1').fill = headerFillStyle
          staffWorksheet.getCell('G1').value = '家族手当'
          staffWorksheet.getCell('G1').font = headerFontStyle
          staffWorksheet.getCell('G1').fill = headerFillStyle
          staffWorksheet.getCell('H1').value = '特別手当'
          staffWorksheet.getCell('H1').font = headerFontStyle
          staffWorksheet.getCell('H1').fill = headerFillStyle

          staffWorksheet.getCell('E2').value = staff.housingAllowance
          staffWorksheet.getCell('E2').font = cellFontStyle
          staffWorksheet.getCell('F2').value = staff.jobAllowance
          staffWorksheet.getCell('F2').font = cellFontStyle
          staffWorksheet.getCell('G2').value = staff.familyAllowance
          staffWorksheet.getCell('G2').font = cellFontStyle
          staffWorksheet.getCell('H2').value = staff.specialAllowance
          staffWorksheet.getCell('H2').font = cellFontStyle

          const header =
          ['勤務日','曜日','勤務区分',
          '勤務時間(シフト)','休憩時間(シフト)','出勤時刻(シフト)','退勤時刻(シフト)',
          '勤務時間(実績)','休憩時間(実績)','出勤時刻(実績)','退勤時刻(実績)',
          '遅早時間','通常時間','残業時間','深夜時間','交通費']
          // set an outline level for rows
          staffWorksheet.getRow(3).outlineLevel = 0;
          staffWorksheet.addRow(header)

          history.map((daily)=>{
            staffWorksheet.addRow([
              daily.dateValue,daily.week,daily.attendanceWorkDivisionName,
              parseFloat(daily.shiftWorkTotalTime),parseFloat(daily.shiftRestTotalTime),daily.shiftWorkStartTime,daily.shiftWorkEndTime,
              parseFloat(daily.attendanceWorkTotalTime),parseFloat(daily.attendanceRestTotalTime),daily.attendanceWorkStartTime,daily.attendanceWorkEndTime,
              parseFloat(daily.attendanceEarlyLateTime.toFixed(2)),parseFloat(daily.attendanceNormalTime),parseFloat(daily.attendanceOverTime),parseFloat(daily.attendanceNightTime),daily.transferCost,
            ]);
          })
          //列幅自動生成
          staffWorksheet.columns.forEach(function (column, i) {
            var maxLength = 0;
            column.eachCell((cell, rowNumber)=>{
              if(i == 0 && rowNumber == 1) return;
              var columnLength = cell.value ? cell.value.toString().length : 0;
              if (columnLength > maxLength ) {
                   maxLength = columnLength;
              }
            })
            // column["eachCell"]({ includeEmpty: true }, function (cell) {
            //   var columnLength = cell.value ? cell.value.toString().length : 0;
            //   if (columnLength > maxLength ) {
            //       maxLength = columnLength;
            //   }
            // });
            column.width = parseFloat(5.58) + parseInt(maxLength)*parseFloat(1);//*parseFloat(1.71); 
          });
          // すべての行を走査
          staffWorksheet.eachRow((row, rowNumber) => {
            // すべてのセルを走査
            row.eachCell((cell, colNumber) => {
              if(rowNumber !=1 )row.height = 12;
              if(rowNumber < 4) return
              if (rowNumber === 4) {
                // ヘッダ行のスタイルを設定
                cell.fill = headerFillStyle;
                cell.font = headerFontStyle;
                cell.alignment = headerAlignment;
              } else if(rowNumber===5){
                // 合計行のスタイルを設定
                cell.fill = bodyEvenFillStyle;
                cell.font = {
                  bold: true,
                  name: 'Yu Gothic UI',
                  size: 9,
                  color: { argb: 'FF0000FF' } 
                };
                cell.border = borderEvenStyle;
              } 
              else {
                if (rowNumber % 2 === 1) {
                  // ボディ行（偶数行）のスタイルを設定
                  cell.fill = bodyEvenFillStyle;
                  cell.border = borderEvenStyle;
                } else {
                  // ボディ行（奇数行）のスタイルを設定
                  cell.fill = bodyOddFillStyle;
                  cell.border = borderOddStyle;
                }
                // ボディ行のフォントを設定
                cell.font = cellFontStyle;
              }
              if(colNumber >=16){
                cell.numFmt ="#,##0"
              }else{
                cell.numFmt ="##0.00"
              }
              if(colNumber == 6 || colNumber == 7|| colNumber == 10 || colNumber == 11){
                cell.alignment = headerAlignment;
              }
            });
            // 行の設定を適用
            row.commit();
          });

        })

        //エクセルファイルを生成する
        let uint8Array = await workbook.xlsx.writeBuffer(); //xlsxの場合
        let blob = new Blob([uint8Array], { type: "application/octet-binary" });


        //Excelファイルダウンロード
        let link = document.createElement( "a" );
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = "【"+data.targetDt.split('-')[0]+"年"+data.targetDt.split('-')[1]+"月度】給与明細データ.xlsx" ;
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }
  }

  //勤怠再計算処理
  const onRecalculation = () =>{
    async function sendPost (requestBody, data) {
      var resultAttendance
      dispatch(SetLoadSpinner(true))

      resultAttendance = 
        await MultipartApi(`${DefaultUrl}`,actionType, requestBody, data)
      if (!ErrorHandling(resultAttendance, alert)) {
        alert.success('保存しました')

        setStaffWorkInfoAllList(resultAttendance.data.responseList.slice())
        var count = 0
        resultAttendance.data.responseList.forEach((staffWorkInfo)=>{
          count += staffWorkInfo.dailyWorkInfoList.length
        })
        setStaffCount(count)
        setPageLast(Math.ceil(count/pageRecord))
        setPageNum(pageNum)
        setPagenation(resultAttendance.data)
        var tmp = resultAttendance.data.responseList.slice()
        if(pageType==1)
        pageNum =0
        makePage(tmp,pageNum)
        
        setActionType('POST')
      }
      dispatch(SetLoadSpinner(false))
    }
    if(staffWorkInfoList.length==0){
      alert.error("再計算するデータがありません")
      return
    }
    const submitData = new window.FormData()
    console.log(staffWorkInfoList)
  
    let data = makeRecalculationData()

    if(ValidUpdate(data))
    {
      alert.error("入力項目にエラーがあります")
      return
    }

    //再検索用情報付与
    data = {'formDatas': data,
            'startDt': searchOption.startDt,
            'endDt': searchOption.endDt,
            'staffId': searchOption.staffId,
            'staffName': searchOption.staffName,
            'postType':searchOption.postType,
            'postId':(searchOption.postId!=undefined && searchOption.postId != -1) ? searchOption.postId:'',
            'departmentId':(searchOption.departmentId != null && searchOption.departmentId!=undefined)? searchOption.departmentId:'',
            'page': 1}
    console.log(data.startDt)
    if(data.startDt=='')
    {
        alert.error("更新するデータがありません")
        return
    }
    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    sendPost(submitData, data)
  }

  const [openUpload, setOpenUpload] = useState(false);
  const handleCloseUpload = () => {
    setOpenUpload(false)
  }
  const onImport=()=>{
    //setOpenUpload(true);
  }
  //インポート処理
  const onUpload=(uploadData)=>{
    async function sendPost (requestBody, data) {
      var resultAttendance
      dispatch(SetLoadSpinner(true))
      console.log(data)
      resultAttendance = 
        await MultipartApi(`${DefaultUrl}/Import`,'POST', requestBody, data)
      
      if (!ErrorHandling(resultAttendance, alert)) {
        alert.success('保存しました')
        setStaffWorkInfoAllList(resultAttendance.data.responseList.slice())
        var count = 0
        resultAttendance.data.responseList.forEach((staffWorkInfo)=>{
          count += staffWorkInfo.dailyWorkInfoList.length
        })
        setStaffCount(count)
        setPageLast(Math.ceil(count/pageRecord))
        setPageNum(pageNum)
        setPagenation(resultAttendance.data)
        var tmp = resultAttendance.data.responseList.slice()
        makePage(tmp,pageNum)
        setActionType('POST')

        handleCloseUpload()
        dispatch(SetLoadSpinner(false))
      }else{
        dispatch(SetLoadSpinner(false))
      }
      
    }

    var dailyList= new Array();
    console.log(uploadData)
    uploadData.forEach((workInfo)=>{
      let dailyRow = {
        orderId: workInfo["受注ID"],
        staffId: workInfo["スタッフID"], 
        baseDt: workInfo["勤怠日付"],
        workDivision: workInfo["出勤区分"],
        workStartDt: workInfo["出勤時間"],
        workEndDt: workInfo["退勤時間"],
        restStart1Dt: (workInfo["休憩開始時間1"])?(workInfo["休憩開始時間1"]):'',
        restEnd1Dt: (workInfo["休憩終了時間1"])?(workInfo["休憩終了時間1"]):'',
        restStart2Dt: (workInfo["休憩開始時間2"])?(workInfo["休憩開始時間2"]):'',
        restEnd2Dt: (workInfo["休憩終了時間2"])? (workInfo["休憩終了時間2"]):'',
        restStart3Dt: (workInfo["休憩開始時間3"])?(workInfo["休憩開始時間3"]):'',
        restEnd3Dt: (workInfo["休憩終了時間3"])?(workInfo["休憩終了時間3"]):'',
        
        deleteRestList: new Array(),
        updateRestList: new Array(),
        addRestList: new Array(),

        updatedAt: '1900-01-01 00:00:00',
        remark: (workInfo["備考"]),
      }
      
      var count = 0
      while(true){
        count++;
        console.log("休憩開始時間"+(count))
        if(!workInfo["休憩開始時間"+(count)] || !workInfo["休憩終了時間"+(count)])break
        if(workInfo["休憩開始時間"+(count)] =="" || workInfo["休憩終了時間"+(count)]=="")break
        
        let restRow = {
          orderId: workInfo["受注ID"],
          staffId: workInfo["スタッフID"], 
          baseDt: workInfo["勤怠日付"],
          restNum: count-1,
          attendanceRestStartDt:workInfo["休憩開始時間"+(count)],
          attendanceRestEndDt:workInfo["休憩終了時間"+(count)],
          attendanceRestTotalDt:parseFloat(calcRestHours( [workInfo["休憩開始時間"+(count)], workInfo["休憩終了時間"+(count)]]))*3600
        }
        
        dailyRow.addRestList.push(restRow)
      }
      // uploadRestData.forEach((restInfo)=>{
      //   if(dailyRow.staffId !=  restInfo["スタッフID"]) return
      //   if(dailyRow.orderId !=  restInfo["受注ID"]) return
      //   if(dailyRow.baseDt !=  restInfo["勤怠日付"]) return
      //   let restRow = {
      //     orderId: workInfo["受注ID"],
      //     staffId: workInfo["スタッフID"], 
      //     baseDt: workInfo["勤怠日付"],
      //     restNum: count,
      //     attendanceRestStartDt:restInfo["休憩開始日時"],
      //     attendanceRestEndDt:restInfo["休憩終了日時"],
      //     attendanceRestTotalDt:parseFloat(calcRestHours( [restInfo["休憩開始日時"], restInfo["休憩終了日時"]]))*3600
      //   }
      //   count++;
      //   dailyRow.addRestList.push(restRow)
      // })

      {
          dailyRow.workTotalDt = timeToSec(format48Time(workInfo["勤務時間"]))
          dailyRow.restTotalDt = timeToSec(format48Time(workInfo["休憩時間"]))
          dailyRow.overTotalDt = timeToSec(format48Time(workInfo["残業工数"]))
          dailyRow.nightTime = timeToSec(format48Time(workInfo["深夜工数"]))
  
          dailyList.push(dailyRow)
      }
    })
    const submitData = new window.FormData()
    //再検索用情報付与
    var data = {'formDatas': dailyList,
            'startDt': searchOption.startDt,
            'endDt': searchOption.endDt,
            'staffId': searchOption.staffId,
            'staffName': searchOption.staffName,
            'postType':searchOption.postType,
            'postId':(searchOption.postId!=undefined && searchOption.postId != -1) ? searchOption.postId:'',
            'departmentId':(searchOption.departmentId != null && searchOption.departmentId!=undefined)? searchOption.departmentId:'',
            'page': 1}
    console.log(data.startDt)
    if(data.startDt=='' || data.formDatas.length==0)
    {
        alert.error("更新するデータがありません")
        return
    }
    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    sendPost(submitData, data)
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }

  const pageSelectFunc = (pageId) => {
    onSearch(null, pageId)
  }
  
  const updatedAttendanceFunc = () => {
    onSearch(null, pageNum)
  }

  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud'
    else
      document.title = 'Core Time Cloud'
  }, [grants])

  useEffect(() => {
    setPost()
    onSearch(null,1)
  }, [])


  const [openPaymentDuduction, setOpenPaymentDuduction] = useState(false);
  const handleClosePaymentDuduction = (flag) => {
    setOpenPaymentDuduction(flag)
  }
  const handleOpenPaymentDuduction = () => {
    console.log('OK')
    setOpenPaymentDuduction(true);
  }

  const setPost=()=>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      }
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.companyId,
          name: item.companyName+'('+item.companyId+')',
        }
      })
      setPostList(resultList)
      return true
    }
    const serachCustomer = async()=>{
      const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } 
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.customerId,
          name: item.customerName+'('+item.customerId+')',
        }
      })
      
      setPostList(resultList)
      return true
    }
    if(postType==0){
      serachCompany()
    }else{
      serachCustomer()
    }
  }
  
  //会社顧客変更処理
  useEffect(() => {
    setPost()
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postType])
  
  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
    console.log(viewSubSearch)
  }
  
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  const onPageSeletClick = (pageId) => {
      console.log(pageId)
      //onSearch(null,pageId)
      makePage(staffWorkInfoAllList,pageId)
      setPageNum(pageId)
  }
  const onPageNextSeletClick = (pageId) => {
    if(pageNum!=pageLast){
      onPageSeletClick(pageId)
    }
    //setStaffWorkInfoList(staffWorkInfoAllList)
    //makePage(new Array(),pageId)
  }
  const onPageBackSeletClick = (pageId) => {
    if(pageNum!=1){
      onPageSeletClick(pageId)
    }
    //console.log(staffWorkInfoAllList)
    //setStaffWorkInfoList(staffWorkInfoAllList)
    //makePage(new Array(),pageId)
  }
  const onPageChangeLabelClick = () => {
    // 機能メニューを非表示に変更
    setViewFunction(false);
    if(pageType==0){
      setPageType(1)
      setPageNum(0)
      makePage(staffWorkInfoAllList,0)
    }else{
      setPageType(0)
      setPageNum(1)
      makePage(staffWorkInfoAllList,1)
    }
  }
  
  //ページ遷移前の更新破棄の確認
  //更新キャンセル処理
  useEffect(() => {
    // イベントの設定
    window.addEventListener('beforeunload', onUnload);
  
    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload);
    }
  })
  const onUnload = (e) => {
    var updateFlag = false
    staffWorkInfoAllList.forEach((staffWorkInfo)=>{
      staffWorkInfo.dailyWorkInfoList.forEach(dailyWorkInfo => {
        if(dailyWorkInfo.workInfoList.length != 1) return
        dailyWorkInfo.workInfoList.forEach((workInfo)=>{
          if(workInfo.isDiff) updateFlag = true
        })
    })})
    if(updateFlag){
      e.preventDefault();
      e.returnValue = '';
    }
  }

  if (!fromDateRef.current) {
    var today = new Date();
    fromDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
    toDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
  }

  if (!grants) return null
  return (
      <AttendancePanelWrap id='AttendanceWrap'>
        <Prompt when={true} message={handleBlockedNavigation} />
        <Search
          title= {grants.functionMenuName}
          onClick={() => onSearch(null, pageType==0?1:0)}
          onClickOption={(e) => onClickOptionBtn(e)}
          onKeyDown={(e) => onKeyDown(e)}
          fromDateRef={fromDateRef}
          toDateRef={toDateRef}
          staffIdRef={staffIdRef}
          staffNameRef={staffNameRef}
          viewSubSearch={viewSubSearch}
          grants={grants}
          postList={postList}
          postId={postId}
          setPostId={setPostId}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          postType={postType}
          setPostType={setPostType}
          onClickFunction={(e)=>onClickFunction(e)}
          onExport={() => onExport(null, 1)}
          viewFunction={viewFunction}
          onRecalculation={()=>onRecalculation()}
          onUpadate={()=>onSubmit()}
          onPageTypeChangeClick={() => onPageChangeLabelClick()}
          onClickPaymentDuduction={()=>handleOpenPaymentDuduction()}
          onClickPayment={()=>onClickPayment()}
          onChangeRestType={()=>onChangeRestType()}
          onImport={(uploadData)=>onUpload(uploadData)}
        >
        </Search>
        <AttendanceCountPanelWrap>
          <label>検索ヒット件数:{staffCount}件</label>
        </AttendanceCountPanelWrap>
        <AttendanceFlexWrap>
            <DailyTable
              register={register}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              reset={reset}
              errors={errors}

              workDivisions={workDivisions}
              dateDataList={dateDataList}
              staffWorkInfoList={staffWorkInfoList}
              updatedAttendanceFunc={updatedAttendanceFunc}
              defaultUrl={DefaultUrl}
              restFlag={restFlag}
            >
            </DailyTable>
        </AttendanceFlexWrap>
        {
          staffWorkInfoList.length>0 &&
          <AttendancePagingPanelWrap>
          {/* <AttendancePagingChangePanelWrap onClick={() => onPageChangeLabelClick()}>表示切替</AttendancePagingChangePanelWrap> */}
          { pageType==0 &&
            <AttendancePageButtonWrap>       
            <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
            <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
            <AttendancePagingIconPanelWrap>{pageNum}</AttendancePagingIconPanelWrap>
            <AttendancePagingIconPanelWrap>/</AttendancePagingIconPanelWrap>
            <AttendancePagingIconPanelWrap>{pageLast}</AttendancePagingIconPanelWrap>
            <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
            <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
            
            </AttendancePageButtonWrap>
          }
          </AttendancePagingPanelWrap>
        }
        <PaymentDuductionDialog
          open={openPaymentDuduction}
          onCloseFunc={(flag)=>handleClosePaymentDuduction(flag)}
        />
        {/* <ImportDialog open={openUpload} onCloseFunc={()=>handleCloseUpload()} onUpload={(uploadData,uploadRestData)=>onUpload(uploadData,uploadRestData)}/> */}
      </AttendancePanelWrap>
  )
}

export default Attendance
