import React,{useState} from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import { formatHour } from '../../../Common/Utilties/LaborUtility'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
//import styled from 'styled-components'
import { ColumnStaffTD, ColumnWorkTD, ColumnOccupationTD,ColumnStaffIdTD} from '../../Wrap/ShiftGanttChartTableWrap'
import Canvas from '../Labor/TableCanvas'

//import { CompassCalibrationOutlined } from '@material-ui/icons'

// styled
/*
const LaborRow = styled.td`
  display: flex;
  width:2220px;
  min-height:50px;
  max-height:50px;
  background:#fff;
  margin-bottom: 3px;
  canvas{
      margin:0;
      padding:0;
      vertical-align:bottom;
  }
`
*/
const TableRow = ({ staff,workInfoList,workInfo,workDivisions,wrapRef,count,restFlag }) => {

  const createElementStaff = (staff) => {
    if (!staff) {
      return <><br/><span>( - )</span></>
    }
    let text = staff.staffName
    let textPx = measurePxText(text)
    let textRem = convertPxToRem(textPx)

    let checkRem = 9
    if (textRem < checkRem)
    {
      return <>{text}<br/><ColumnStaffIdTD>({staff.staffId})</ColumnStaffIdTD></>
    }

    let editedText = text
    while (textRem > checkRem) {
      editedText = editedText.slice(0, -1)
      textPx = measurePxText(editedText + '...')
      textRem = convertPxToRem(textPx)
    }

    editedText = editedText + '...'

    return <Tooltip title={text}><span>{editedText && editedText}<br/><ColumnStaffIdTD>({staff.staffId})</ColumnStaffIdTD></span></Tooltip>
  }
  const createElementOccupation = (workInfoList) => {
    if (workInfoList) {
      let attendanceInfo = workInfoList
      let text = attendanceInfo.occupation
      let textPx = measurePxText(text)
      let textRem = convertPxToRem(textPx)
  
      let checkRem = 7
      if (textRem < checkRem)
      {
        return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({attendanceInfo.orderId})</span></>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...')
        textRem = convertPxToRem(textPx)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({attendanceInfo.orderId})</span></span></Tooltip>
    }

    return <><br/><span>( - )</span></>
  }
  const makeTmp=(workInfoList)=>{
    var tmp= new Array()
    tmp.push(workInfoList)
    //console.log(count)
    return tmp
  }

  const [workTimeList,setWorkTimeList] = useState({
    workTotalDt:(workInfoList.workTotalDt),
    restTotalDt:(workInfoList.restTotalDt),
    overTotalDt:(workInfoList.overTotalDt)
  })
  return(
    <tr>    
    <ColumnStaffTD className='staffID' rowNumber={count%2==0}>{createElementStaff(staff)}</ColumnStaffTD>
    <ColumnOccupationTD className='occupation' rowNumber={count%2==0}>{createElementOccupation(workInfoList)}</ColumnOccupationTD>
    <ColumnWorkTD className='workTime' rowNumber={count%2==0}>{formatHour(workTimeList.workTotalDt)}</ColumnWorkTD>
    <ColumnWorkTD className='restTime' rowNumber={count%2==0}>{formatHour(workTimeList.restTotalDt)}</ColumnWorkTD>
    <ColumnWorkTD className='overTime' rowNumber={count%2==0}>{formatHour(workTimeList.overTotalDt)}</ColumnWorkTD>
    <td>
      <Canvas staffId={staff.staffId} wrapRef={wrapRef} workInfo={staff} restFlag={restFlag} workDivisions={workDivisions} workInfoList={makeTmp(workInfoList)} setWorkTimeList={setWorkTimeList} count={count}/>
    </td>
  </tr>
  )
}

TableRow.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
}

export default TableRow
