import styled from 'styled-components'

export const SubSearchContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 600px;
  margin: 0.5rem;
`

export const SubSearchContentRow = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 0.5rem;
  text-align: left;
`

export const FunctionContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 200px;
  font-size:16px;
  div:hover {
    ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
  }
`

export const FunctionContentRow = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  text-align: left;
  margin-top: 4px;
  margin-bottom: 4px;
`

export const FunctionIcon = styled.img`
  width: 18px;
  height: 18px;

  margin-left: 12px;
`

export const FunctionName = styled.div`
  margin-left: 1rem;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
`

export const FiltersPanelWrap = styled.div`
  display: flex;
  background-image: :${props => props.image || 'none'};
`
export const ClickLabelPanelWrap = styled.div`
  display: flex;
  color:blue;
`

const SearchWrap = styled.div`
  display:${props => props.display || 'flex'};
  padding: 1vw;
  width: 100vw;
  flex-wrap: wrap;
  h3 {
    line-height: 32px;
    font-size: 24px;
    margin-right: 16px;
  }
  ul.mainSearch {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    position: relative;
    > li {
      display: flex;
      margin: 0 0 0 1rem;
      label {
        line-height: 32px;
        text-align: right;
        font-size: 12px;
        margin: 0 0.5rem 0 0;
      }
    }
    li.subSearch {
      display: ${props => props.viewSubSearch ? 'block' : 'none' };
      position: absolute;
      padding: 0 16px;
      margin: 0;
      top: 60px;
      z-index: 3;
      left: 76px;
      background-color: #ffffff;
      box-shadow: 2px 2px 6px rgba(0,0,0,.4);
    }
  }
  li.mainFunction {
    display: ${props => props.viewFunction? 'block' : 'none' };
    position: absolute;
    align-items: left;
    margin: 0;
    top: 120px;
    z-index: 1;
    left: calc(100vw - 200px - 1rem);
    background-color: #cfe2f3;
    box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  }
}
${props => props.addStyle}
`
export default SearchWrap
