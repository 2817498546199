import React,{useState, useEffect, useRef ,useCallback} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector,useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import { InputGroup, SelectGroup, HiddenInput } from '../../../Common/CommonInputGroup'
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../../../Common/Atoms/NormalButton'
import PublicTable from  './TransportationPublicTable'
import PrivateTable from  './TransportationPraivateTable'
import { GetApi } from '../../../Common/ApiAxios'
import ErrorHandling from '../../../Common/ErrorHandling'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import PublicTransportationEditDialog from './PublicTransportationEditDialog'
import PrivateTransportationEditDialog from './PrivateTransportationEditDialog'
export const defaultUrl = '/api/paymentDuductionManagement/paymentDuduction'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 45rem;
  width: 50rem;
`
const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 3rem;
  text-align: right;
  padding: 1vw;
`
const SumContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 3rem;
  text-align: right;
  justify-content: flex-end;
  padding: 1vw;
`

const ContentLabel = styled.div`
  display: flex;
  flex-wrap: wrap;
  line-height:32px;
  height: 2rem;
  width:6rem;
`

const TitleWrap = styled.div`
  font-size: 24px;
  display: flex;
  font-weight: bold;
  padding: 1vw;
`
const FormWrap = styled.form`
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
  padding: 1vw;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

const TransportationModal = ({ open, onCloseFunc,workInfo,passDivisions,transportationPublic,transportationPraivate,gasCost,onHandleClose}) => {
  const { register, handleSubmit, getValues, setError, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();
  const alert = useAlert()
  const dispatch = useDispatch()
  const [canUpdate, setCanUpdate] = useState(false)
  useEffect(() => {
    if (!workInfo) {
        return
    }

    workInfo.privateTransportationExpensesList.map((info)=>{
      onSearch(info)
    })
    workInfo.publicTransportationExpensesList.map((info)=>{
      onSearch(info)
    })
    setCanUpdate(workInfo.beforePublicTransportationExpensesList.length==0 || workInfo.beforePrivateTransportationExpensesList.length==0)
  }, [workInfo])


  const onSearch = async (info) => {
   
    dispatch(SetLoadSpinner(true))
    const data = {
      startDt:info.startDt,
      staffId: info.staffId,
      endDt:info.endDt,
    }
    const result = await GetApi(defaultUrl+'/Count', data)

    if (result.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert)
      return null
    } else {
      info.dateCount = result.data
      dispatch(SetLoadSpinner(false))
    }


  }
  const onMaxNum = (transList) => {
    var max = 0;
    transList.map((item)=>{
      if(item.transportNum>max){
        max = item.transportNum
      }
    })
    return max
  }
  const onSubmit = () => {
    JSON.parse(JSON.stringify(workInfo.beforePrivateTransportationExpensesList)).map((info)=>{
      onSearch(info)
      info.isDiff = true
      info.updatedId = null
      info.transportNum = onMaxNum(workInfo.privateTransportationExpensesList)
      workInfo.privateTransportationExpensesList.push(info)
    })
    JSON.parse(JSON.stringify(workInfo.beforePublicTransportationExpensesList)).map((info)=>{
      onSearch(info)
      info.isDiff = true
      info.updatedId = null
      info.transportNum = onMaxNum(workInfo.publicTransportationExpensesList)
      workInfo.publicTransportationExpensesList.push(info)
    })
  }
  const makeYM=(date)=>
  {
    var dateYM = date.split('-')
    return dateYM[0] + '/' + dateYM[1]
  }
  const [openPublic, setOpenPublic] = useState(false);
  const [publicType, setPublicType] = useState(0);
  const [publicTransport, setPublicTransport] = useState([]);
  const handleClosePublic = () => {
    setOpenPublic(false)
  }
  const handleOpenPublic = (type,transport) => {
    console.log('OK')
    console.log(type)
    setPublicType(type)
    setPublicTransport(transport)
    setOpenPublic(true);
  }

  const [openPrivate, setOpenPrivate] = useState(false);
  const [privateType, setPrivateType] = useState(0);
  const [privateTransport, setPrivateTransport] = useState([]);
  const handleClosePrivate = () => {
    setOpenPrivate(false)
  }
  const handleOpenPrivate = (type,transport) => {
    console.log('OK')
    console.log(type)
    setPrivateType(type)
    setPrivateTransport(transport)
    setOpenPrivate(true);
  }

  const makeSumValue = (workInfo) => {
    var sum = Number(0)
    workInfo.privateTransportationExpensesList.map((info)=>{if(info.delFlag)sum = parseInt(sum) + calcMoney(info)})
    workInfo.publicTransportationExpensesList.map((info)=>{if(info.delFlag)sum = parseInt(sum) + calcMoney(info)})
    return sum
  }
  const calcMoney = (workInfo)=>
  {
    if(workInfo.passDivision==0) return parseInt(workInfo.passPrice);
    var amount = parseInt(workInfo.passPrice) * parseInt(workInfo.dateCount);
    return  Number(amount)
  }

  const onClose =()=>
  {
    //workInfo.transportationExpenses = makeSumValue(workInfo)
    onHandleClose(makeSumValue(workInfo))
  }

  //ページ遷移前の更新破棄の確認
  //更新キャンセル処理
  useEffect(() => {
    // イベントの設定
    window.addEventListener('beforeunload', onUnload);
  
    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload);
    }
  })

  const onUnload = (e) => {
    var updateFlag = true
    if(updateFlag){
      e.preventDefault();
      e.returnValue = '';
    }
  }

  return (
    <>
    <Modal
      open={open}
      onClose={()=>onCloseFunc(makeSumValue(workInfo))}
      aria-labelledby='交通費編集'
      aria-describedby='交通費編集ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap className='h-dsm'> 
    <div className={classes.paper}>
      <ContentWrap>
        <TitleWrap>{'交通費内訳'}</TitleWrap>
        <Content>
          <ContentLabel>対象年月</ContentLabel>
          <InputGroup                 
                name={'dateValue_'+ workInfo.staffId}
                width='6rem'
                placeholder=''
                margin='auto'
                register={register}
                errors={errors}
                value={makeYM(workInfo.dateValue)}
                style={{ textAlign: 'center', paddingRight: '0.5rem' }}
                disabled={true}/>
        </Content>
        <Content>
          <ContentLabel>スタッフID</ContentLabel>
          <InputGroup                 
                name={'staffId_'+ workInfo.staffId}
                width='8rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                value={workInfo.staffId}
                style={{ textAlign: 'left', paddingRight: '0.5rem' }}
                disabled={true}/>
          <div style={{width:'20px'}}/>
          <ContentLabel>スタッフ名</ContentLabel>
          <InputGroup                 
                name={'staffName_'+ workInfo.staffId}
                width='18rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                value={workInfo.staffName}
                style={{ textAlign: 'left', paddingRight: '0.5rem' }}
                disabled={true}/>
        </Content>
      <TableWrap>
        <PublicTable workInfoList={workInfo.publicTransportationExpensesList} open={(type,transport)=>handleOpenPublic(type,transport)}></PublicTable>
      </TableWrap>
      <TableWrap>
        <PrivateTable
        workInfoList={workInfo.privateTransportationExpensesList} open={(type,transport)=>handleOpenPrivate(type,transport)}>
        </PrivateTable>
      </TableWrap>
      <SumContent>
          <ContentLabel>合計</ContentLabel>
          <InputGroup                 
                name={'sumValue'+ workInfo.staffId}
                width='6rem'
                placeholder=''
                margin='auto'
                register={register}
                errors={errors}
                value={makeSumValue(workInfo).toLocaleString() +'円'}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={true}/>
        </SumContent>
      </ContentWrap>
      <ButtonsWrap>
        <Button name='前月コピー' padding='0 0 12px 0' type='button' onClick={onSubmit}  display={canUpdate?'':'none' }/>
        <Button name='閉じる' type='button' padding='0px' onClick={onClose} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  <PublicTransportationEditDialog
      open={openPublic}
      onCloseFunc={handleClosePublic}
      workInfo={workInfo}
      passDivisions={passDivisions}
      transportationPublic={transportationPublic}
      type={publicType}
      transport={publicTransport}
      onSearch={(info)=>onSearch(info)}
    />
    <PrivateTransportationEditDialog
      open={openPrivate}
      onCloseFunc={handleClosePrivate}
      workInfo={workInfo}
      passDivisions={passDivisions}
      transportationPrivate={transportationPraivate}
      type={privateType}
      transport={privateTransport}
      gasCost={gasCost}
      onSearch={(info)=>onSearch(info)}
    />
  </>
  )
}

TransportationModal.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedShiftFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    dailyWorkInfo: PropTypes.object,
    workDivisions: PropTypes.array,
}

export default TransportationModal
