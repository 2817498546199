import React,{useState} from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import IconCollapseImg from '../../image/Collapse.png'


const MasterModel = styled.div`
  display:${props => props.display || 'flex'};
  flex-wrap: wrap;
  height: 84vh;
  img {
  }
  ul.mainSearch {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    > li {
      display: flex;
      margin: 0 0 0 1rem;
      label {
        line-height: 32px;
        text-align: right;
        font-size: 12px;
        margin: 0 0.5rem 0 0;
    }
  }
    li.subSearch {
      display: ${props => props.visible ? 'block' : 'none' };
      position: absolute;
      font-size: 16px;
      margin: 0;
      top: 0px;
      z-index: 3;
      left: 0;
    }
  }
`

const MasterSelect = styled.div`
    flex-direction: column;
    background-repeat: no-repeat;
    height: 84vh;
    width: 10vw;
    background-color: #cfe2f3;
    overflow-y: none;
`

const MasterSelectNone = styled.div`
    flex-direction: column;
    background-repeat: no-repeat;
    overflow-y: none;
    background-color: #FFF;
`

const MasterSelectRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1vw;
    background-repeat: no-repeat;
    width: 10vw;
    overflow-y: none;
`

const MasterSelectImg = styled.div`
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    overflow-y: none;
`

const MasterNoneRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1vw;
    background-repeat: no-repeat;
    overflow-y: none;
`
const MasterSelectBody = () => {
    const [viewDasdboardLink,setViewDasdboardLink] = useState(false)
    const [title,setTitle] = useState('')
    const onClick =()=>{    
        setViewDasdboardLink(!viewDasdboardLink)
    }

return (
    <MasterModel  visible={viewDasdboardLink}>
    <ul className='mainSearch'>
    <Tooltip title={'マスタメニュー'}>
    <img src={IconCollapseImg} width="24px" height="24px" style={{cursor: 'pointer' }} onClick={()=>onClick()}/>
    </Tooltip>
    <li  className='subSearch'>
    <MasterSelect>
        <MasterSelectImg>
            <img src={IconCollapseImg} width="24px" height="24px" style={{transform: 'rotate(180deg)' ,cursor: 'pointer' }} onClick={()=>onClick()}/>
        </MasterSelectImg>
        <MasterSelectRow>
        <Link to="/master/staff">
        <Tooltip title={'スタッフマスタ'}>
            <div>{'スタッフマスタ'}</div>
        </Tooltip>
        </Link>
        </MasterSelectRow>
        <MasterSelectRow>
        <Link to="/master/company" >
        <Tooltip title={'会社マスタ'}>
            <div>{'会社マスタ'}</div>
        </Tooltip>
        </Link>
        </MasterSelectRow>
        <MasterSelectRow>
        <Link to="/master/customer">
        <Tooltip title={'顧客マスタ'}>
            <div>{'顧客マスタ'}</div>
        </Tooltip>
        </Link>
        </MasterSelectRow>
        <MasterSelectRow>
        <Link to="/master/order">
        <Tooltip title={'受注管理'}>
            <div>{'受注管理'}</div>
        </Tooltip>
        </Link>
        </MasterSelectRow>
        <MasterSelectRow>
        <Link to="/master/allocation">
        <Tooltip title={'引当管理'}>
            <div>{'引当管理'}</div>
        </Tooltip>
        </Link>
        </MasterSelectRow>
        <MasterSelectRow>
        <Link to="/master/grant">
        <Tooltip title={'権限管理'}>
            <div>{'権限管理'}</div>
        </Tooltip>
        </Link>
        {/* <Tooltip title={'工事中'}>
            <div>{'権限管理'}</div>
        </Tooltip> */}
        </MasterSelectRow>
        <MasterSelectRow>
        <Link to="/master/calendar">
        <Tooltip title={'カレンダー'}>
            <div>{'カレンダー'}</div>
        </Tooltip>
        </Link>
        </MasterSelectRow>
        <MasterSelectRow>
        <Link to="/coretimeAliveMonitoring">
        <Tooltip title={'CoreTime死活監視'}>
            <div>{'死活監視'}</div>
        </Tooltip>
        </Link>
        </MasterSelectRow>
    </MasterSelect>
    </li>
    </ul>
    </MasterModel>
)
}

export default MasterSelectBody