import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'
import { useAlert } from 'react-alert'

import { InputGroup, SelectGroup } from '../../../Common/CommonInputGroup'
import Button from '../../../Common/Atoms/NormalButtonAttendance'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
import { diff48Time, format48Time, formatHour, makeElementIdByDailyWorkInfo, calcWorkingHours ,formatRound48Time,calcWorkingRestFreeHoursTime} from '../../../Common/Utilties/LaborUtility'

import { ColumnStaffTD, ColumnOccupationTD, ColumnDateTD, ColumnWorkTypeTD, ColumnTimeTD, ColumnRemarkTD, ColumnButtonsTD,ColumnTD } from '../../Wrap/DailyTableWrap'
import { min } from '../../../Common/Regex'
import TableCell from './DailyTableCell'
import RestDialog from '../RestDialog/RestModel'

const DailyTableRow = ({ 
    register, getValues, setValue, watch, reset, errors, 
    workDivisions, dateDataList, dailyWorkInfo, selectFunction,
    handleOpenPunchHistoryFunc, handleClosePunchHistoryFunc, handleOpenDailyAttendanceFunc, handleCloseDailyAttendanceFunc,restFlag }) => {

  const elementId = makeElementIdByDailyWorkInfo(dailyWorkInfo.workInfoList[0])
  const [workDivision, setWorkDivision] = useState(0)
   
  //シフトデータ表示用
  const [attendanceWorkTotalTime, setAttendanceWorkTotalTime] = useState('0.00')
  const [attendanceRestTotalTime, setAttendanceRestTotalTime] = useState('0.00')
  const [attendanceOverTotalTime, setAttendanceOverTotalTime] = useState('0.00')
  //シフトデータ表示用
  const [shiftWorkTotalTime, setShiftWorkTotalTime] = useState('0.00')
  const [shiftRestTotalTime, setShiftRestTotalTime] = useState('0.00')
  const [shiftOverTotalTime, setShiftOverTotalTime] = useState('0.00')

  const alert = useAlert()

  const findDateInfo = (dateList, date) => {
    var day = undefined
    dateList.forEach(dateInfo => {
        if (dateInfo.dateValue === date) {
            day = dateInfo
        }
    })

    return day
  }

  // 勤怠情報切り替え時
  useEffect(() => {
    // 勤怠情報が変更された場合のみ実行
    if (dailyWorkInfo.workInfoList) {
      if (dailyWorkInfo.workInfoList.length === 1) {
        var workInfo = dailyWorkInfo.workInfoList[0]
        setValue('orderId_' + elementId, workInfo.orderId)
        setValue('staffId_' + elementId, workInfo.staffId)
        setValue('baseDt_' + elementId, workInfo.baseDt)
        setValue('attendanceUpdatedAt_' + elementId, workInfo.attendanceUpdatedAt)
    
        setValue('attendanceWorkDivision_' + elementId, workInfo.attendanceWorkDivision)
        if(!workInfo.isDiff){
          setValue('attendanceWorkStartDt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceWorkStartDt))
          setValue('attendanceWorkEndDt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceWorkEndDt))
        }
        else{
          setValue('attendanceWorkStartDt_' + elementId, (workInfo.baseDt, workInfo.attendanceWorkStartDt))
          setValue('attendanceWorkEndDt_' + elementId, (workInfo.baseDt, workInfo.attendanceWorkEndDt))
        }
        setValue('attendanceWorkTotalDt_' + elementId, formatHour(workInfo.attendanceWorkTotalDt))
        setValue('attendanceRestTotalDt_' + elementId, formatHour(workInfo.attendanceRestTotalDt))

        let overTotal = 0.0
        if (workInfo.attendanceWorkTotalDt > workInfo.attendanceBaseTime)
          overTotal = workInfo.attendanceWorkTotalDt - workInfo.attendanceBaseTime
        setValue('attendanceOverTotalDt_' + elementId, formatHour(overTotal))
        
        setAttendanceWorkTotalTime(formatHour(workInfo.attendanceWorkTotalDt))
        setAttendanceRestTotalTime(formatHour(workInfo.attendanceRestTotalDt))
        setAttendanceOverTotalTime(formatHour(overTotal))

        if(restFlag){
          let restStartList = new Array()
          let restEndList = new Array()
  
          workInfo.attendanceRestList.map((restData)=>{
            if(restData.restNum==null) return
            restStartList.push(diff48Time(workInfo.baseDt, restData.restStartDt))
            restEndList.push(diff48Time(workInfo.baseDt, restData.restEndDt))
          })
          for(var i=restStartList.length;i<3;i++){
            restStartList.push('')
          }
          for(var i=restEndList.length;i<3;i++){
            restEndList.push('')
          }
  
          setValue('attendanceRestStart1Dt_' + elementId, restStartList[0])
          setValue('attendanceRestEnd1Dt_' + elementId, restEndList[0])
          setValue('attendanceRestStart2Dt_' + elementId, restStartList[1])
          setValue('attendanceRestEnd2Dt_' + elementId, restEndList[1])
          setValue('attendanceRestStart3Dt_' + elementId, restStartList[2])
          setValue('attendanceRestEnd3Dt_' + elementId, restEndList[2])
        }
        else if(!workInfo.isDiff){
          setValue('attendanceRestStart1Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart1Dt))
          setValue('attendanceRestEnd1Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd1Dt))
          setValue('attendanceRestStart2Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart2Dt))
          setValue('attendanceRestEnd2Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd2Dt))
          setValue('attendanceRestStart3Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart3Dt))
          setValue('attendanceRestEnd3Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd3Dt))
        }
        else{
          setValue('attendanceRestStart1Dt_' + elementId, (workInfo.baseDt, workInfo.attendanceRestStart1Dt))
          setValue('attendanceRestEnd1Dt_' + elementId, (workInfo.baseDt, workInfo.attendanceRestEnd1Dt))
          setValue('attendanceRestStart2Dt_' + elementId, (workInfo.baseDt, workInfo.attendanceRestStart2Dt))
          setValue('attendanceRestEnd2Dt_' + elementId, (workInfo.baseDt, workInfo.attendanceRestEnd2Dt))
          setValue('attendanceRestStart3Dt_' + elementId, (workInfo.baseDt, workInfo.attendanceRestStart3Dt))
          setValue('attendanceRestEnd3Dt_' + elementId, (workInfo.baseDt, workInfo.attendanceRestEnd3Dt))
        }

        setValue('attendanceRemark_' + elementId, workInfo.attendanceRemark)

        setWorkDivision(workInfo.attendanceWorkDivision)

        //シフト
        setValue('shiftWorkDivision_' + elementId, workInfo.shiftWorkDivision)
        if(!workInfo.isDiff){
          setValue('shiftWorkStartDt_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftWorkStartDt))
          setValue('shiftWorkEndDt_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftWorkEndDt))
        }
        else{
          setValue('shiftWorkStartDt_' + elementId, (workInfo.baseDt, workInfo.shiftWorkStartDt))
          setValue('shiftWorkEndDt_' + elementId, (workInfo.baseDt, workInfo.shiftWorkEndDt))
        }
        setShiftWorkTotalTime(formatHour(workInfo.shiftWorkTotalDt))
        setShiftRestTotalTime(formatHour(workInfo.shiftRestTotalDt))

        overTotal = 0.0
        if (workInfo.shiftWorkTotalDt > workInfo.attendanceBaseTime)
          overTotal = workInfo.shiftWorkTotalDt - workInfo.attendanceBaseTime
        setShiftOverTotalTime(formatHour(overTotal))

        if(restFlag){
          let restStartList = new Array()
          let restEndList = new Array()
  
          workInfo.shiftRestList.map((restData)=>{
            if(restData.restNum==null) return
            restStartList.push(diff48Time(workInfo.baseDt, restData.restStartDt))
            restEndList.push(diff48Time(workInfo.baseDt, restData.restEndDt))
          })
          for(var i=restStartList.length;i<3;i++){
            restStartList.push('')
          }
          for(var i=restEndList.length;i<3;i++){
            restEndList.push('')
          }
  
          setValue('shiftRestStart1Dt_' + elementId, restStartList[0])
          setValue('shiftRestEnd1Dt_' + elementId, restEndList[0])
          setValue('shiftRestStart2Dt_' + elementId, restStartList[1])
          setValue('shiftRestEnd2Dt_' + elementId, restEndList[1])
          setValue('shiftRestStart3Dt_' + elementId, restStartList[2])
          setValue('shiftRestEnd3Dt_' + elementId, restEndList[2])
        }
        else if(!workInfo.isDiff){
          setValue('shiftRestStart1Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftRestStart1Dt))
          setValue('shiftRestEnd1Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftRestEnd1Dt))
          setValue('shiftRestStart2Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftRestStart2Dt))
          setValue('shiftRestEnd2Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftRestEnd2Dt))
          setValue('shiftRestStart3Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftRestStart3Dt))
          setValue('shiftRestEnd3Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftRestEnd3Dt))
        }
        else{
          setValue('shiftRestStart1Dt_' + elementId, (workInfo.baseDt, workInfo.shiftRestStart1Dt))
          setValue('shiftRestEnd1Dt_' + elementId, (workInfo.baseDt, workInfo.shiftRestEnd1Dt))
          setValue('shiftRestStart2Dt_' + elementId, (workInfo.baseDt, workInfo.shiftRestStart2Dt))
          setValue('shiftRestEnd2Dt_' + elementId, (workInfo.baseDt, workInfo.shiftRestEnd2Dt))
          setValue('shiftRestStart3Dt_' + elementId, (workInfo.baseDt, workInfo.shiftRestStart3Dt))
          setValue('shiftRestEnd3Dt_' + elementId, (workInfo.baseDt, workInfo.shiftRestEnd3Dt))
        }

        setValue('shiftRemark_' + elementId, workInfo.shiftRemark)
       
      }
      else if (dailyWorkInfo.workInfoList.length > 1) {
        let workTotal = 0.0
        let restTotal = 0.0
        let overTotal = 0.0

        let attendanceWorkDivision = 0
        
        let minStartDt = dailyWorkInfo.workInfoList[0].attendanceWorkStartDt
        let maxEndDt = dailyWorkInfo.workInfoList[0].attendanceWorkEndDt

        let restStartList = new Array()
        let restEndList = new Array()

        //シフト
        let shiftWorkDivision = 0
        let shiftWorkTotal = 0.0
        let shiftRestTotal = 0.0
        let shiftOverTotal = 0.0

        dailyWorkInfo.workInfoList.map((workInfo) => {
          workTotal += workInfo.attendanceWorkTotalDt
          restTotal += workInfo.attendanceRestTotalDt
          if (workInfo.attendanceWorkTotalDt > workInfo.attendanceBaseTime) {
            overTotal += workInfo.attendanceWorkTotalDt - workInfo.attendanceBaseTime
          }
          
          if(workInfo.attendanceWorkStartDt && minStartDt>workInfo.attendanceWorkStartDt)  minStartDt = workInfo.attendanceWorkStartDt
          if(workInfo.attendanceWorkEndDt && maxEndDt<workInfo.attendanceWorkEndDt)  maxEndDt = workInfo.attendanceWorkEndDt

          if(workInfo.attendanceRestStart1Dt) {
            restStartList.push(workInfo.attendanceRestStart1Dt)
            restEndList.push(workInfo.attendanceRestEnd1Dt)
          }
          if(workInfo.attendanceRestStart2Dt) {
            restStartList.push(workInfo.attendanceRestStart2Dt)
            restEndList.push(workInfo.attendanceRestEnd2Dt)
          }
          if(workInfo.attendanceRestStart3Dt) {
            restStartList.push(workInfo.attendanceRestStart3Dt)
            restEndList.push(workInfo.attendanceRestEnd3Dt)
          }

          if(attendanceWorkDivision==0 && workInfo.attendanceWorkDivision!=0){ 
            attendanceWorkDivision= workInfo.attendanceWorkDivision
          }else if(!(2 > workInfo.attendanceWorkDivision || workInfo.attendanceWorkDivision > 4)){
            attendanceWorkDivision = workInfo.attendanceWorkDivision
          }

          //シフト
          shiftWorkTotal += workInfo.shiftWorkTotalDt
          shiftRestTotal += workInfo.shiftRestTotalDt
          if (workInfo.shiftWorkTotalDt > workInfo.attendanceBaseTime) {
            shiftOverTotal += workInfo.shiftWorkTotalDt - workInfo.attendanceBaseTime
          }
        

          if(shiftWorkDivision==0 && workInfo.shiftWorkDivision!=0){ 
            shiftWorkDivision= workInfo.shiftWorkDivision
          }else if(!(2 > workInfo.shiftWorkDivision || workInfo.shiftWorkDivision > 4)){
            shiftWorkDivision = workInfo.shiftWorkDivision
          }
        });
        //シフト
        setValue('shiftWorkDivision_' + elementId, shiftWorkDivision)
        setShiftWorkTotalTime(formatHour(shiftWorkTotal))
        setShiftRestTotalTime(formatHour(shiftRestTotal))
        setShiftOverTotalTime(formatHour(shiftOverTotal))

        //勤怠
        setAttendanceWorkTotalTime(formatHour(workTotal))
        setAttendanceRestTotalTime(formatHour(restTotal))
        setAttendanceOverTotalTime(formatHour(overTotal))

        setValue('attendanceWorkDivision_' + elementId, attendanceWorkDivision)
        setWorkDivision(attendanceWorkDivision)

        setValue('attendanceWorkStartDt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, minStartDt))
        setValue('attendanceWorkEndDt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, maxEndDt))
        setValue('attendanceRestStart1Dt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, restStartList[0]))
        setValue('attendanceRestEnd1Dt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, restEndList[0]))
        setValue('attendanceRestStart2Dt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt,restStartList[1]))
        setValue('attendanceRestEnd2Dt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, restEndList[1]))
        setValue('attendanceRestStart3Dt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, restStartList[2]))
        setValue('attendanceRestEnd3Dt_' + elementId, diff48Time(dailyWorkInfo.workInfoList[0].baseDt, restEndList[2]))

        setValue('attendanceWorkTotalDt_' + elementId, formatHour(workTotal))
        setValue('attendanceRestTotalDt_' + elementId, formatHour(restTotal))
        setValue('attendanceOverTotalDt_' + elementId, formatHour(overTotal))
      }
    }
  // eslint-disable-next-line
  }, [dailyWorkInfo])

  const formatDate = (dateInfo) => {
    var date = new Date(dateInfo.dateValue);
    return ('00' + (date.getMonth() + 1)).slice(-2) + '/' + ('00' + date.getDate()).slice(-2)
  }

  const weekColor = (dateInfo) => {
    switch(dateInfo && dateInfo.publicHoliday) {
        case '0':
            switch(dateInfo.week)
            {
                case '土':
                    return '#00F'
                case '日':
                    return '#F00'
            }
            return '#000'
        case '1':
            return '#F00'
    }

    return '#000'
  }

  const createElementStaff = (staff) => {
    if (staff) {
      let text = staff.staffName
      let textPx = measurePxText(text)
      let textRem = convertPxToRem(textPx)
  
      let checkRem = 7
      if (textRem < checkRem)
      {
        return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...')
        textRem = convertPxToRem(textPx)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></span></Tooltip>
    }

    return <><br/><span>( - )</span></>
  }

  const createElementOccupation = (workInfoList) => {
    if (workInfoList && workInfoList.length === 1) {
      let attendanceInfo = workInfoList[0]
      let text = attendanceInfo.occupation
      let textPx = measurePxText(text)
      let textRem = convertPxToRem(textPx)
  
      let checkRem = 7
      if (textRem < checkRem)
      {
        return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({attendanceInfo.orderId})</span></>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...')
        textRem = convertPxToRem(textPx)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({attendanceInfo.orderId})</span></span></Tooltip>
    }

    return <><br/><span>( - )</span></>
  }

  const createElementDate = (dateList, date) => {
    if (!dateList || dateList.length === 0 || !date) {
        return <></>
    }

    var dateInfo = findDateInfo(dateList, date)
    if (!dateInfo) {
        return <></>
    }

    if (!dateInfo.note)
    {
      return <span>
        {formatDate(dateInfo)} (<span style={{ color: weekColor(dateInfo) }}>{dateInfo.week}</span>)
      </span>
    }

    return <Tooltip title={dateInfo.note}>
             <span>{formatDate(dateInfo)} (<span style={{ color: weekColor(dateInfo) }}>{dateInfo.week}</span>)</span>
           </Tooltip>
  }

  const createElementWorkType = (workInfoList) => {
    if (workInfoList && workInfoList.length === 1) {
        return <>
               <SelectGroup
                 name={'attendanceWorkDivision_' + elementId}
                 width='6rem'
                 margin='auto'
                 options={workDivisions}
                 register={register}
                 errors={errors}
                 onChange={onChange}
               />
               </>
    }else if(workInfoList){
      return <>
      <SelectGroup
        name={'attendanceWorkDivision_' + elementId}
        width='6rem'
        margin='auto'
        options={workDivisions}
        register={register}
        errors={errors}
        disabled={true}
      />
      </>
    }
    
    return <></>
  }

  const createElementWorkTime = (workInfoList) => {
    if (workInfoList && workInfoList.length >= 1) {
        return <>
            <div
                name={'attendanceWorkTotalDt_' + elementId}
                width='3.5rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={true}
                values={attendanceWorkTotalTime}
            >{(2 > workDivision || workDivision > 4)?'--.--':(attendanceWorkTotalTime)}</div>
           </>
    }

    return <></>
  }

  const createElementRestTime = (workInfoList) => {
    if (workInfoList && workInfoList.length >= 1) {
        return <>
            <div
                name={'attendanceRestTotalDt_' + elementId}
                width='3.5rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={true}
            >{(2 > workDivision || workDivision > 4)?'--.--':(attendanceRestTotalTime)}</div>
           </>
    }

    return <></>
  }

  const createElementOverTime = (workInfoList) => {
    if (workInfoList && workInfoList.length >= 1) {
        return <>
            <div
                name={'attendanceOverTotalDt_' + elementId}
                width='3.5rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={true}
            >{(2 > workDivision || workDivision > 4)?'--.--':(attendanceOverTotalTime)}</div>
           </>
    }

    return <></>
  }

  const createElementTime = (prefix, workInfoList) => {
    
    if (workInfoList && workInfoList.length === 1 && prefix.indexOf("attendanceRest")!=-1) {
        var colorFlag = (workInfoList[0][prefix] != workInfoList[0][prefix.replace('attendance','shift')])
        if(restFlag){
          colorFlag = ((workInfoList[0].shiftRestList.length == 0) || (workInfoList[0].attendanceRestList.length == 0))
          if(!colorFlag){
            switch(prefix){
              case 'attendanceRestStart1Dt':
                colorFlag = (workInfoList[0].shiftRestList[0].restStartDt != workInfoList[0].attendanceRestList[0].restStartDt)
                break
              case 'attendanceRestEnd1Dt':
                colorFlag = (workInfoList[0].shiftRestList[0].restEndDt != workInfoList[0].attendanceRestList[0].restEndDt)
                break
              case 'attendanceRestStart2Dt':
                colorFlag = ((workInfoList[0].shiftRestList.length == 1) || (workInfoList[0].attendanceRestList.length == 1))
                if(!colorFlag) colorFlag = (workInfoList[0].shiftRestList[1].restStartDt != workInfoList[0].attendanceRestList[1].restStartDt)
                break
              case 'attendanceRestEnd2Dt':
                colorFlag = ((workInfoList[0].shiftRestList.length == 1) || (workInfoList[0].attendanceRestList.length == 1))
                if(!colorFlag) colorFlag = (workInfoList[0].shiftRestList[1].restEndDt != workInfoList[0].attendanceRestList[1].restEndDt)
                break
              case 'attendanceRestStart3Dt':
                colorFlag = ((workInfoList[0].shiftRestList.length < 3) || (workInfoList[0].attendanceRestList.length < 3))
                if(!colorFlag) colorFlag = (workInfoList[0].shiftRestList[2].restEndDt != workInfoList[0].attendanceRestList[2].restEndDt)
                break
              case 'attendanceRestEnd3Dt':
                colorFlag = ((workInfoList[0].shiftRestList.length < 3) || (workInfoList[0].attendanceRestList.length < 3))
                if(!colorFlag) colorFlag = (workInfoList[0].shiftRestList[2].restEndDt != workInfoList[0].attendanceRestList[2].restEndDt)
                break
            }
          }
        }
        return <>
            <InputGroup
                name={prefix+ '_' + elementId}
                width='3.5rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                onBlur={onBlurTime}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={(2 > workDivision || workDivision > 4) || restFlag}
                color={colorFlag?'red':'#333'}
            />
           </>
    } else if(workInfoList && workInfoList.length === 1 )
    {
      var colorFlag = false
      if(prefix.indexOf("attendance")!=-1){
        var colorFlag = (workInfoList[0][prefix] != workInfoList[0][prefix.replace('attendance','shift')])
      }
      return <>
      <InputGroup
          name={prefix + '_' + elementId}
          width='3.5rem'
          margin='auto'
          placeholder=''
          register={register}
          errors={errors}
          onBlur={onBlurTime}
          style={{ textAlign: 'right', paddingRight: '0.5rem' }}
          disabled={(2 > workDivision || workDivision > 4)}
          color={colorFlag?'red':'#333'}
      />
     </>
    }else if(workInfoList){
          return <>
            <InputGroup
                name={prefix  + '_' + elementId}
                width='3.5rem'
                margin='auto'
                placeholder=''
                errors={errors}
                register={register}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={true}
            />
           </>
    }

    return <></>
  }
  
  const createElementShiftTime = (prefix, workInfoList) => {
    if (workInfoList && workInfoList.length == 1) {
      return workInfoList[0][prefix]==null?'--.--':diff48Time(workInfoList[0].baseDt,workInfoList[0][prefix])
    }

    return '--.--'
  }

  const createElementButtons = (workInfoList) => {
    if (workInfoList && workInfoList.length === 1) {
        return <>
            <Button margin='auto' padding='0' width='3rem' name='履歴' onClick={() => onShowPunchHistoryModal(workInfoList[0])}/>
           </>
    }
    else if (workInfoList && workInfoList.length > 1) {
        return <>
            <Button margin='auto' padding='0' width='3rem' name='詳細' onClick={() => onShowDailyModal(workInfoList)}/>
           </>
    }

    return <></>
  }

  const createElementRestButtons = (workInfoList,onClick) => {
    if (workInfoList && workInfoList.length === 1 && restFlag) {
        return <>
            <Button margin='auto' padding='0' width='3rem' name='休憩' disabled={false} onClick={onClick}/>
           </>
    }
    else if ((workInfoList && workInfoList.length > 1) ||!restFlag) {
        return <>
            <Button margin='auto' padding='0' width='3rem' name='休憩' disabled={true}/>
           </>
    }

    return <></>
  }

  const onShowPunchHistoryModal = (workInfo) => {
    handleOpenPunchHistoryFunc(workInfo,restFlag)
  }

  const onShowDailyModal = (workInfoList) => {
    handleOpenDailyAttendanceFunc(workInfoList,restFlag)
  }

  const onBlurTime = ( event ) => {
    let value = event.target.value
    //日時の分単位を指定分で丸める
    let fractionUnit = parseInt(dailyWorkInfo.workInfoList[0].attendanceFractionUnit)
    //value = format48Time(value)
    let name = event.target.name 
    if(name.indexOf('WorkEnd') != -1 || name.indexOf('RestStart') != -1)
    {
      value = formatRound48Time(value,fractionUnit*(-1))
    }
    else
    {
      value = formatRound48Time(value,fractionUnit)
    }
    event.target.value = value

    let workStart = getValues("attendanceWorkStartDt_" + elementId)
    let workEnd = getValues("attendanceWorkEndDt_" + elementId)
    let restStart1 = getValues("attendanceRestStart1Dt_" + elementId)
    let restEnd1 = getValues("attendanceRestEnd1Dt_" + elementId)
    let restStart2 = getValues("attendanceRestStart2Dt_" + elementId)
    let restEnd2 = getValues("attendanceRestEnd2Dt_" + elementId)
    let restStart3 = getValues("attendanceRestStart3Dt_" + elementId)
    let restEnd3 = getValues("attendanceRestEnd3Dt_" + elementId)
    
    //日時の分単位を指定分で丸める
    workStart = formatRound48Time(workStart,fractionUnit)
    workEnd = formatRound48Time(workEnd,fractionUnit*(-1))
    restStart1 = formatRound48Time(restStart1,fractionUnit*(-1))
    restEnd1 = formatRound48Time(restEnd1,fractionUnit)
    restStart2 = formatRound48Time(restStart2,fractionUnit*(-1))
    restEnd2 = formatRound48Time(restEnd2,fractionUnit)
    restStart3 = formatRound48Time(restStart3,fractionUnit*(-1))
    restEnd3 = formatRound48Time(restEnd3,fractionUnit)

    let result = calcWorkingHours([workStart, workEnd], [restStart1, restEnd1, restStart2, restEnd2, restStart3, restEnd3], dailyWorkInfo.workInfoList[0].attendanceBaseTime)
    //追加休憩時間開放version
    if(restFlag){
      result = calcWorkingRestFreeHoursTime([workStart, workEnd], dailyWorkInfo.workInfoList[0].attendanceRestList, dailyWorkInfo.workInfoList[0].attendanceBaseTime)
    }
  
    setValue('attendanceWorkTotalDt_' + elementId, result[0])
    setValue('attendanceRestTotalDt_' + elementId, result[1])
    setValue('attendanceOverTotalDt_' + elementId, result[2])
    setValue('attendanceNightTotalDt_' + elementId, 0)

    {
      var workInfo = dailyWorkInfo.workInfoList[0]
      workInfo.attendanceWorkStartDt = workStart
      workInfo.attendanceWorkEndDt = workEnd
      workInfo.attendanceWorkTotalDt = parseFloat(result[0])*3600
      workInfo.attendanceRestTotalDt = parseFloat(result[1])*3600
      workInfo.attendanceOverTotalDt = parseFloat(result[2])*3600
      workInfo.attendanceRestStart1Dt = restStart1
      workInfo.attendanceRestEnd1Dt = restEnd1
      workInfo.attendanceRestStart2Dt = restStart2
      workInfo.attendanceRestEnd2Dt = restEnd2
      workInfo.attendanceRestStart3Dt = restStart3
      workInfo.attendanceRestEnd3Dt = restEnd3
      workInfo.isDiff = true
              
      //勤怠
      setAttendanceWorkTotalTime(formatHour(parseFloat(result[0])*3600))
      setAttendanceRestTotalTime(formatHour(parseFloat(result[1])*3600))
      setAttendanceOverTotalTime(formatHour(parseFloat(result[2])*3600))
    }
  }

  //出勤区分変更イベント
  const onChange = ( event ) =>
  {
    let value = event.target.value

    var workInfo = dailyWorkInfo.workInfoList[0]

    if(!workInfo.isDiff){
      workInfo.attendanceWorkStartDt = getValues("attendanceWorkStartDt_" + elementId)
      workInfo.attendanceWorkEndDt = getValues("attendanceWorkEndDt_" + elementId)
      workInfo.attendanceWorkTotalDt = parseFloat(getValues('attendanceWorkTotalDt_' + elementId))*3600
      workInfo.attendanceRestTotalDt = parseFloat(getValues('attendanceRestTotalDt_' + elementId))*3600
      workInfo.attendanceOverTotalDt = parseFloat(getValues('attendanceOverTotalDt_' + elementId))*3600
      workInfo.attendanceRestStart1Dt = getValues("attendanceRestStart1Dt_" + elementId)
      workInfo.attendanceRestEnd1Dt = getValues("attendanceRestEnd1Dt_" + elementId)
      workInfo.attendanceRestStart2Dt = getValues("attendanceRestStart2Dt_" + elementId)
      workInfo.attendanceRestEnd2Dt = getValues("attendanceRestEnd2Dt_" + elementId)
      workInfo.attendanceRestStart3Dt = getValues("attendanceRestStart3Dt_" + elementId)
      workInfo.attendanceRestEnd3Dt = getValues("attendanceRestEnd3Dt_" + elementId)
    }

    if(2 > value || value > 4)
    {
      setValue('attendanceWorkStartDt_' + elementId, '')
      setValue('attendanceWorkEndDt_' + elementId, '')
      setValue('attendanceWorkTotalDt_' + elementId, '0.00')
      setValue('attendanceRestTotalDt_' + elementId, '0.00')
      setValue('attendanceOverTotalDt_' + elementId, '0.00')
      setValue('attendanceRestStart1Dt_' + elementId, '')
      setValue('attendanceRestEnd1Dt_' + elementId, '')
      setValue('attendanceRestStart2Dt_' + elementId, '')
      setValue('attendanceRestEnd2Dt_' + elementId, '')
      setValue('attendanceRestStart3Dt_' + elementId, '')
      setValue('attendanceRestEnd3Dt_' + elementId, '')

      workInfo.attendanceWorkStartDt = ''
      workInfo.attendanceWorkEndDt = ''
      workInfo.attendanceWorkTotalDt = parseFloat('0.00')
      workInfo.attendanceRestTotalDt = parseFloat('0.00')
      workInfo.attendanceOverTotalDt = parseFloat('0.00')
      workInfo.attendanceRestStart1Dt = ''
      workInfo.attendanceRestEnd1Dt = ''
      workInfo.attendanceRestStart2Dt = ''
      workInfo.attendanceRestEnd2Dt = ''
      workInfo.attendanceRestStart3Dt = ''
      workInfo.attendanceRestEnd3Dt = ''

      setAttendanceWorkTotalTime(formatHour(parseFloat('0.00')))
      setAttendanceRestTotalTime(formatHour(parseFloat('0.00')))
      setAttendanceOverTotalTime(formatHour(parseFloat('0.00')))

      workInfo.attendanceRestList.map((restData)=>{
        if(restData.isNew){
          restData.isDel = true
          restData.isDiff = false
        }else{
          restData.isDel = true
          restData.isDiff = true
        }
      })
    }
    setWorkDivision(value)
    console.log(workInfo)

    workInfo.attendanceWorkDivision = value
    workInfo.isDiff = true
  }
  const [openAttendanceRest, setOpenAttendanceRest] = useState(false);
  const [openShiftRest, setOpenShiftRest] = useState(false);
  // const onOpenShift=()=>{
  //   console.log(getValues('shiftWorkDivision_' + elementId),getValues('attendanceWorkDivision_' + elementId))
  //   if((2 > workDivision || workDivision > 4)&&(2 > getValues('shiftWorkDivision_' + elementId) || getValues('shiftWorkDivision_' + elementId) > 4)){
  //     alert.error("出勤区分が出勤・休出・法出ではありません")
  //   }else{
  //     setOpenShiftRest(true)
  //   }
  // }
  const onOpenAttendance=()=>{
    if((2 > workDivision || workDivision > 4)&&(2 > getValues('shiftWorkDivision_' + elementId) || getValues('shiftWorkDivision_' + elementId) > 4)){
      alert.error("出勤区分が出勤・休出・法出ではありません")
    }else{
      setOpenAttendanceRest(true)
    }  
  }

  //ダイアログ閉じる処理
  const onClose=(restList)=>{
    var workInfo = dailyWorkInfo.workInfoList[0]
    //日時の分単位を指定分で丸める
    let fractionUnit = parseInt(workInfo.attendanceFractionUnit)
    let workStart = getValues("attendanceWorkStartDt_" + elementId)
    let workEnd = getValues("attendanceWorkEndDt_" + elementId)
    let restStart1 = getValues("attendanceRestStart1Dt_" + elementId)
    let restEnd1 = getValues("attendanceRestEnd1Dt_" + elementId)
    let restStart2 = getValues("attendanceRestStart2Dt_" + elementId)
    let restEnd2 = getValues("attendanceRestEnd2Dt_" + elementId)
    let restStart3 = getValues("attendanceRestStart3Dt_" + elementId)
    let restEnd3 = getValues("attendanceRestEnd3Dt_" + elementId)
    
    //日時の分単位を指定分で丸める
    workStart = formatRound48Time(workStart,fractionUnit)
    workEnd = formatRound48Time(workEnd,fractionUnit*(-1))
    restStart1 = formatRound48Time(restStart1,fractionUnit*(-1))
    restEnd1 = formatRound48Time(restEnd1,fractionUnit)
    restStart2 = formatRound48Time(restStart2,fractionUnit*(-1))
    restEnd2 = formatRound48Time(restEnd2,fractionUnit)
    restStart3 = formatRound48Time(restStart3,fractionUnit*(-1))
    restEnd3 = formatRound48Time(restEnd3,fractionUnit)

    let restStartList = new Array()
    let restEndList = new Array()

    restList.map((restData)=>{
      if(restData.restNum==null || restData.isDel) return
      restStartList.push( restData.restStartDt)
      restEndList.push( restData.restEndDt)
    })
    for(var i=restStartList.length;i<3;i++){
      restStartList.push('')
    }
    for(var i=restEndList.length;i<3;i++){
      restEndList.push('')
    }

    setValue('attendanceRestStart1Dt_' + elementId, restStartList[0])
    setValue('attendanceRestEnd1Dt_' + elementId, restEndList[0])
    setValue('attendanceRestStart2Dt_' + elementId, restStartList[1])
    setValue('attendanceRestEnd2Dt_' + elementId, restEndList[1])
    setValue('attendanceRestStart3Dt_' + elementId, restStartList[2])
    setValue('attendanceRestEnd3Dt_' + elementId, restEndList[2])

    let result = calcWorkingRestFreeHoursTime([workStart, workEnd], restList, workInfo.attendanceBaseTime)
    
    setValue('attendanceWorkTotalDt_' + elementId, result[0])
    setValue('attendanceRestTotalDt_' + elementId, result[1])
    setValue('attendanceOverTotalDt_' + elementId, result[2])
    setValue('attendanceNightTotalDt_' + elementId, 0)
    
      {
        workInfo.attendanceWorkStartDt = workStart
        workInfo.attendanceWorkEndDt = workEnd
        workInfo.attendanceWorkTotalDt = parseFloat(result[0])*3600
        workInfo.attendanceRestTotalDt = parseFloat(result[1])*3600
        workInfo.attendanceOverTotalDt = parseFloat(result[2])*3600
        workInfo.attendanceRestStart1Dt = restStart1
        workInfo.attendanceRestEnd1Dt = restEnd1
        workInfo.attendanceRestStart2Dt = restStart2
        workInfo.attendanceRestEnd2Dt = restEnd2
        workInfo.attendanceRestStart3Dt = restStart3
        workInfo.attendanceRestEnd3Dt = restEnd3
        workInfo.attendanceRestList= restList
        workInfo.isDiff = true

        //勤怠
        setAttendanceWorkTotalTime(formatHour(parseFloat(result[0])*3600))
        setAttendanceRestTotalTime(formatHour(parseFloat(result[1])*3600))
        setAttendanceOverTotalTime(formatHour(parseFloat(result[2])*3600))
      }
      setOpenAttendanceRest(false)
    }
  return (
    <tr>
      <ColumnStaffTD>{createElementStaff(dailyWorkInfo)}</ColumnStaffTD>
      <ColumnOccupationTD>{createElementOccupation(dailyWorkInfo.workInfoList)}</ColumnOccupationTD>
      <ColumnDateTD>{createElementDate(dateDataList, dailyWorkInfo.baseDt)}</ColumnDateTD>
      <ColumnTD><TableCell  shiftDateCount={'予定'} attendanceDateCount={'実績'}/></ColumnTD>
      <ColumnWorkTypeTD>
        <TableCell  
          shiftDateCount={
          <SelectGroup
            name={'shiftWorkDivision_' + elementId}
            width='6rem'
            margin='auto'
            options={workDivisions}
            register={register}
            errors={errors}
            disabled={true}
          />} 
          attendanceDateCount={createElementWorkType(dailyWorkInfo.workInfoList)} 
          cellType={2}
          />
      </ColumnWorkTypeTD>
      <ColumnTimeTD>
        <TableCell  
          shiftDateCount={shiftWorkTotalTime}  
          attendanceDateCount={createElementWorkTime(dailyWorkInfo.workInfoList)}
          color={(2 > workDivision || workDivision > 4)?undefined:'red'}
         />
      </ColumnTimeTD>
      <ColumnTimeTD>
        <TableCell
          shiftDateCount={shiftRestTotalTime} 
          attendanceDateCount={createElementRestTime(dailyWorkInfo.workInfoList)}
          color={(2 > workDivision || workDivision > 4)?undefined:'red'}/>
      </ColumnTimeTD>
      <ColumnTimeTD>
        <TableCell 
          shiftDateCount={shiftOverTotalTime} 
          attendanceDateCount={createElementOverTime(dailyWorkInfo.workInfoList)}
          color={(2 > workDivision || workDivision > 4)?undefined:'red'}/>
      </ColumnTimeTD>
      <ColumnTimeTD>
        <TableCell
          shiftDateCount={createElementShiftTime('shiftWorkStartDt', dailyWorkInfo.workInfoList)} 
          attendanceDateCount={createElementTime('attendanceWorkStartDt', dailyWorkInfo.workInfoList)}
          cellType={1}/>
      </ColumnTimeTD>
      <ColumnTimeTD>
        <TableCell
          shiftDateCount={createElementShiftTime('shiftWorkEndDt', dailyWorkInfo.workInfoList)} 
          attendanceDateCount={createElementTime('attendanceWorkEndDt', dailyWorkInfo.workInfoList)}
          cellType={1}/>
      </ColumnTimeTD>
      <ColumnTimeTD>
        <TableCell
          shiftDateCount={createElementShiftTime('shiftRestStart1Dt', dailyWorkInfo.workInfoList)} 
          attendanceDateCount={createElementTime('attendanceRestStart1Dt', dailyWorkInfo.workInfoList)}
          cellType={1}/>
      </ColumnTimeTD>
      <ColumnTimeTD>
        <TableCell
          shiftDateCount={createElementShiftTime('shiftRestEnd1Dt', dailyWorkInfo.workInfoList)} 
          attendanceDateCount={createElementTime('attendanceRestEnd1Dt', dailyWorkInfo.workInfoList)}
          cellType={1}/>
      </ColumnTimeTD>
      <ColumnTimeTD>
        <TableCell
          shiftDateCount={createElementShiftTime('shiftRestStart2Dt', dailyWorkInfo.workInfoList)} 
          attendanceDateCount={createElementTime('attendanceRestStart2Dt', dailyWorkInfo.workInfoList)}
          cellType={1}/>
      </ColumnTimeTD>
      <ColumnTimeTD>
        <TableCell
          shiftDateCount={createElementShiftTime('shiftRestEnd2Dt', dailyWorkInfo.workInfoList)} 
          attendanceDateCount={createElementTime('attendanceRestEnd2Dt', dailyWorkInfo.workInfoList)}
          cellType={1}/>
      </ColumnTimeTD>
      <ColumnTimeTD>
        <TableCell
          shiftDateCount={createElementShiftTime('shiftRestStart3Dt', dailyWorkInfo.workInfoList)} 
          attendanceDateCount={createElementTime('attendanceRestStart3Dt', dailyWorkInfo.workInfoList)}
          cellType={1}/>
      </ColumnTimeTD>
      <ColumnTimeTD>
        <TableCell
          shiftDateCount={createElementShiftTime('shiftRestEnd3Dt', dailyWorkInfo.workInfoList)} 
          attendanceDateCount={createElementTime('attendanceRestEnd3Dt', dailyWorkInfo.workInfoList)}
          cellType={1}/>
      </ColumnTimeTD>
      <ColumnButtonsTD>        
      {/* <TableCell  
          shiftDateCount={createElementRestButtons(dailyWorkInfo.workInfoList,onOpenShift)} 
          attendanceDateCount={createElementRestButtons(dailyWorkInfo.workInfoList,onOpenAttendance)}
          cellType={2}/> */}
          {createElementRestButtons(dailyWorkInfo.workInfoList,onOpenAttendance)}
      </ColumnButtonsTD>
      <ColumnRemarkTD>
      <TableCell  
          shiftDateCount={''} 
          attendanceDateCount={''}
          cellType={0}/>
      </ColumnRemarkTD>
      <ColumnButtonsTD>        
      <TableCell  
          shiftDateCount={''} 
          attendanceDateCount={createElementButtons(dailyWorkInfo.workInfoList)}
          cellType={1}/></ColumnButtonsTD>
      <RestDialog open={openShiftRest} onCloseFunc={()=>setOpenShiftRest(false)} workInfo={dailyWorkInfo.workInfoList[0]}
      setRest={setValue} elementId={elementId} canWrite={false}></RestDialog>
      <RestDialog open={openAttendanceRest} onCloseFunc={(restList)=>onClose(restList)} workInfo={dailyWorkInfo.workInfoList[0]}
      setRest={setValue} elementId={elementId} canWrite={true}></RestDialog>
    </tr>
  )
}

DailyTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  workDivisions: PropTypes.any,
  dateDataList: PropTypes.array,
  dailyWorkInfo: PropTypes.object,
  selectFunction: PropTypes.func,

  handleOpenPunchHistoryFunc: PropTypes.func,
  handleClosePunchHistoryFunc: PropTypes.func,
  handleOpenDailyAttendanceFunc: PropTypes.func,
  handleCloseDailyAttendanceFunc: PropTypes.func,
}

export default DailyTableRow
