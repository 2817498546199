/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import CalenderIcon from '../../../image/svg/CalenderIcon.js'

registerLocale('ja', ja)

// 共通検索用
const WrapDiv = styled.div`
  position: relative;
  margin: ${props => props.margin || '0 16px'};

  .react-datepicker-popper {
    z-index: 10000;
    width: 200px;
  }
  .react-datepicker__month-container {
    float: left;
    width: 280px;
    height: 175px;
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month-wrapper {
    flex: 1;
  }
  .react-datepicker__month .react-datepicker__month-text {
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .react-datepicker__header {
    height: 30px;
  }

  .normalCalendar {
    height: 32px;
    display: ${props => props.display || 'block'};
    position: relative;
    text-indent: 12px;
    width: ${props => props.width || '160px'};
    min-width: ${props => props.width || '160px'};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.05em;
    background: #eee;
    border-radius: 5px;
    border: none;
    line-height: 12px;
    box-shadow: inset 0 3px 5px 0 rgba(0,0,0,0.35);
    color: #333;
    &:focus {
        background: #fff;
        outline: none;
        border: 2px solid #0036ff;
        box-sizing: border-box;
        text-indent: 14px;
    }
    ::placeholder {
        color: #ccc;
    }
  }
  svg {
    position: absolute;
    top: 6px;
    right: 12px;
    pointer-events: none;
    z-index:2;
  }

  ::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 32px;
    background: #0036ff;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    pointer-events: none;
    z-index:1;
  }
`

const MonthCalendar = ({ dataValue, changeCalendar, placeholder, width, margin, disabled, display, prefix, name, isViewIcon }) => {
  const [startDate, setStartDate] = useState(dataValue && new Date(dataValue))
  const onCalendarChange = (changeDate) => {
    setStartDate(changeDate)
    localStorage.setItem('normalCalendar', changeDate)
  }
  const onCalendarClose = () => {
    const localDate = localStorage.getItem('normalCalendar')
    if (!localDate || localDate === 'undefined' || localDate === 'null') return null
    const date = new Date(localDate)
    const dateStr = date.getFullYear() + `${prefix || '-'}` + ('00' + (date.getMonth() + 1)).slice(-2) + `${prefix || '-'}` + ('00' + date.getDate()).slice(-2)
    if (dataValue !== dateStr) {
      changeCalendar(dateStr)
    }
    localStorage.removeItem('normalCalendar')
  }
  useEffect(() => {
    setStartDate(dataValue && new Date(dataValue))
  }, [dataValue])

  return (
    <WrapDiv margin={margin} width={width} display={display} isViewIcon={isViewIcon} disabled={disabled}>
      <DatePicker
        className='normalCalendar'
        dateFormat='yyyy/MM'
        locale='ja'
        selected={startDate}
        onChange={changeDate => onCalendarChange(changeDate)}
        onCalendarOpen={() => localStorage.setItem('normalCalendar', startDate)}
        onCalendarClose={() => onCalendarClose()}
        peekNextMonth
        showMonthYearPicker
        dropdownMode='select'
        disabled={disabled}
        placeholderText={placeholder}
      />
      {isViewIcon && <CalenderIcon />}
    </WrapDiv>
  )
}

MonthCalendar.propTypes = {
  name: PropTypes.string,
  dataValue: PropTypes.string,
  changeCalendar: PropTypes.func,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  display: PropTypes.string,
  prefix: PropTypes.string,
  isViewIcon: PropTypes.bool
}

export default MonthCalendar
