import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {RowHeaderTop}from '../../Wrap/BulkEditTableWrap'

const ColumnWrap = styled.th`
  vertical-align:middle;
  padding: 0.25rem;
  height: 2rem;
`

const TableHeader = () => {

  return (
    <thead>
      <tr>
        <RowHeaderTop>
            <ColumnWrap style={{ width: '2rem' }}></ColumnWrap>
            <ColumnWrap style={{ width: '16rem' }}>スタッフ名</ColumnWrap>
            <ColumnWrap style={{ width: '13rem' }}>業務名</ColumnWrap>
            <ColumnWrap style={{ width: '5rem' }}>日付</ColumnWrap>
            <ColumnWrap style={{ width: '4rem' }}>変更</ColumnWrap>
            <ColumnWrap style={{ width: '7rem' }}>勤怠時間</ColumnWrap>
            <ColumnWrap style={{ width: '7rem' }}>休憩時間</ColumnWrap>
            <ColumnWrap style={{ width: '7rem' }}>残業時間</ColumnWrap>
            <ColumnWrap style={{ width: '6rem' }}>出勤</ColumnWrap>
            <ColumnWrap style={{ width: '6rem' }}>退勤</ColumnWrap>
            <ColumnWrap style={{ width: '6rem' }}>休始1</ColumnWrap>
            <ColumnWrap style={{ width: '6rem' }}>休終1</ColumnWrap>
            <ColumnWrap style={{ width: '6rem' }}>休始2</ColumnWrap>
            <ColumnWrap style={{ width: '6rem' }}>休終2</ColumnWrap>
            <ColumnWrap style={{ width: '6rem' }}>休始3</ColumnWrap>
            <ColumnWrap style={{ width: '6rem' }}>休終3</ColumnWrap>
            <ColumnWrap style={{ width: '5rem' }}></ColumnWrap>
        </RowHeaderTop>
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
}

export default TableHeader
