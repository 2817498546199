import React, { useEffect, useState, useCallback,PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReTooltip from '@material-ui/core/Tooltip'
import {PolarGrid,PolarAngleAxis,PolarRadiusAxis,Radar,Legend,ResponsiveContainer,RadarChart,Tooltip} from "recharts"


import {DashboardBaseFormRow,DashboardTitle} from '../../../Wrap/DashboardBaseWrap'

const COLORS = ['blue', 'red', 'gray', 'green','orange','purple','cyan'];
const PalDashboardGraph= ({ title,data,keys }) => {        
      // 初期化メソッド
  useEffect(() => {
    console.log(title)

  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  const CustomTooltip = ({ active, payload, label }) => {
    var i=-1
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{backgroundColor:'white',padding:'10px'}}>
          <p className="desc">{label}</p>
          {payload.map((pay)=>{
            i++
            return <p className={"label"+pay.name} style={{color:`${pay.color}`}}>{`${pay.name} : ${parseFloat(pay.value).toFixed(2)}${'%'}`}</p>
          })
          }
        </div>
      );
    }
    return null;
  };
    return (
        <DashboardBaseFormRow>
        <div>
        <DashboardTitle>{title}</DashboardTitle>
        <ResponsiveContainer 
        width={620}
        height={350}
        margin={{
            top: 10, right: 30, left: 0, bottom: 5,
        }}
        >
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="rateName" />
          <PolarRadiusAxis angle={90-360/data.length} domain={[0,100]}/>
          {data && keys.map((workplace,index) => {
            return (
              <Radar name={workplace} dataKey={workplace} stroke={COLORS[index%COLORS.length]} fill={COLORS[index%COLORS.length]} fillOpacity={0} strokeWidth={2}/>
            )
          })
          }
          <Legend/>
          <Tooltip content={<CustomTooltip/>}/>
        </RadarChart>
        </ResponsiveContainer >
        </div>
        </DashboardBaseFormRow>
      )
    }

PalDashboardGraph.propTypes = {
  salariedList: PropTypes.array,
  title: PropTypes.string,
}

export default PalDashboardGraph
