import React from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

const ColumnWrap = styled.th`
  vertical-align:middle;
  padding: 0.25rem;
`

const DailyTableHeader = () => {

  return (
    <thead>
      <tr>
        <ColumnWrap style={{ width: '8rem' }} rowSpan={2}>スタッフ名</ColumnWrap>
        <ColumnWrap style={{ width: 'calc( 10rem + 1px)' }} colSpan={2}>希望時間</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }} rowSpan={2}>時間外勤務</ColumnWrap>
        <ColumnWrap style={{ width: 'calc( 40rem + 8px)' }} colSpan={8}>希望曜日</ColumnWrap>
      </tr>
      <tr>
        <ColumnWrap style={{ width: '5rem' }}>開始時間</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>終了時間</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>月</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>火</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>水</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>木</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>金</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>土</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>日</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>祝</ColumnWrap>
      </tr>
    </thead>
  )
}

DailyTableHeader.propTypes = {
}

export default DailyTableHeader
