import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PersonPinTwoTone } from '@material-ui/icons'

const ColumnWrap = styled.td`
  justify-content: center;
  text-align: center;
  vertical-align:middle;
  padding: 0.25rem;
  height: 2rem;
`

const TransportationPraivateTableBody = ({
    workInfoList,open
  }) => {

  const calcMoney = (workInfo)=>
  {
    if(workInfo.passDivision==0) return parseInt(workInfo.passPrice);
    var amount = parseInt(workInfo.passPrice) * parseInt(workInfo.dateCount);
    return  Number(amount).toLocaleString()

  }
  return (
    <tbody>
      {workInfoList && workInfoList.map((workInfo) => {
        return (
          <>
          {(workInfo.delFlag) &&
            <tr key={workInfo.staffId+'_'+workInfo.startDt+'_'+workInfo.transportNum}>
              <ColumnWrap style={{ width: '4rem' }}>{workInfo.passDivisionName}</ColumnWrap>
              <ColumnWrap style={{ width: '12rem' }}>{workInfo.startDt.replace(/-/g, '/')}～{workInfo.endDt.replace(/-/g, '/')}</ColumnWrap>
              <ColumnWrap style={{ width: '4rem' }}>{workInfo.transportationName}</ColumnWrap>
              <ColumnWrap style={{ width: 'calc(8rem + 1px)' }}>{workInfo.runningDistance}</ColumnWrap>
              <ColumnWrap style={{ width: '4rem' }}>{workInfo.dateCount}日</ColumnWrap>
              <ColumnWrap style={{ width: '4rem' }}>{workInfo.passPrice}</ColumnWrap>
              <ColumnWrap style={{ width: '4rem' }}>{calcMoney(workInfo)}</ColumnWrap>
              <ColumnWrap style={{ width: '3rem' }}><button style={{margin:'auto', padding:'0', minWidth:'2rem'}} onClick={()=>open(0,workInfo)}>詳細</button></ColumnWrap>
              <ColumnWrap style={{ width: '3rem' }}><button style={{margin:'auto', padding:'0', minWidth:'2rem'}} onClick={()=>open(2,workInfo)}>更新</button></ColumnWrap>
            </tr>
          }
          </>
        )
      })}
    </tbody>
  )
}

TransportationPraivateTableBody.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,
  salariedDt: PropTypes.string,
  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array
}

export default TransportationPraivateTableBody

