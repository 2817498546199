import React,{useState,useEffect} from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
//import DashboardMain from './DashboardMain'
import Allocation from './Allocation/Allocation'
import Calendar from './Calendar/Calendar'
import Company from './Company/Company'
import Customer from './Customer/Customer'
import Grant from './Grant/Grant'
import Order from './Order/Order'
import Staff from './Staff/Staff'

import Main from './MasterMain'

import NotFound from '../NotFound'

const Container = styled.div`
  .AppMasterborad {
    background-repeat: no-repeat;
    background-color: #FFF;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
`

const Master = () => {
  const [viewDasdboardLink,setViewDasdboardLink] = useState(false)
  const [title,setTitle] = useState('')
  const onClick =()=>{    
      setViewDasdboardLink(!viewDasdboardLink)
  }

  return(
  <Container>
  <div className='AppMasterborad' id='AppMasterborad'>
    <div className='mainMaster'>
        <Switch>
          <Route path='/master/allocation' component={Allocation} />
          <Route path='/master/calendar' component={Calendar} />
          <Route path='/master/company' component={Company} />
          <Route path='/master/customer' component={Customer} />
          <Route path='/master/grant' component={Grant} />
          <Route path='/master/order' component={Order} />
          <Route path='/master/staff' component={Staff} />
          <Route exact component={Staff} />
        </Switch>
      </div>
  </div>
  </Container>
  )
}
export default Master
