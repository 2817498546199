/* eslint-env browser */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import adminIcon from '../../image/admin-icon.png'
import { PostApi, GetApi, exportErrorLog, forceLogout } from './ApiAxios'
import { setLoginUserInfo } from './Slice/LoginSlice'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'

const WrapDiv = styled.div`
  margin-right: 8px;
  display: flex;
  color: ${props => props.rideMenu ? '#000' : '#fff'};
  position: ${props => props.rideMenu ? 'absolute' : 'unset'};
  position: relative;
  cursor: pointer;
  justify-content: center;
  :hover {
    border: 1px solid #eee;
  }
  span {
    color: white;
    padding: 12px 16px;
    margin: 12px 16px;
    height: 48px;
    font-size: 16px;
    display: block;
    line-height: 20px;
    width: 150px;
    :hover {
      color: #333;
      background: #ddd;
    }
  }
  a {
    color: white;
    text-decoration: none;
  }
`
const SettingMenu = styled.div`
  background: #333;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, .2);
  color: #000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  display: ${props => props.showFlg};
  outline: none;
  overflow: hidden;
  position: absolute;
  top: 30px;
  right: 0;
  border-radius: 2px;
  z-index: 99999;
  width: 195px;
`

// ログインユーザー情報取得
export async function getLoginUserInfo (dispatch) {
  const apiUrl = '/api/loginStaff'
  const result = await GetApi(apiUrl, null)
  if (result.errorDetail) {
    exportErrorLog(result)
    const errorStatus = result.errorDetail.response && result.errorDetail.response.status
    if (errorStatus === 401) {
      forceLogout()
    } else {
      alert('ユーザー情報取得に失敗しました。')
    }
    window.location.href = '/login'
  } else {
    const loginInfo = result.data
    dispatch(setLoginUserInfo(loginInfo))
  }
}

const StaffInfo = (props) => {
  const { staffName, staffId } = useSelector(state => state.loginUserInfo)
  const dispatch = useDispatch()
  const [showFlg, setShowFlg] = useState('none')
  const [photo, setPhoto] = useState()

  function getTooltipText() {
    return <><div>{staffName}</div><div style={{ paddingTop: '0.25rem', marginLeft: '0.5rem' }}>({staffId})</div></>
  }

  const showMenu = () => {
    setShowFlg(showFlg === 'none' ? 'block' : 'none')
  }

  // ログアウト操作
  async function logout () {
    const apiUrl = '/api/logout'
    await PostApi(apiUrl, null)
    document.cookie = 'accessToken=;path=/; max-age=0;'
    document.cookie = 'refreshToken=;path=/; max-age=0;'
    document.cookie = 'accessTokenExpires=;path=/; max-age=0;'
    document.cookie = 'refreshTokenExpires=;path=/; max-age=0;'
    window.location.href = '/login'
  }

  // ログイン情報取得
  useEffect(() => {
    if (staffName == null) {
      getLoginUserInfo(dispatch)
    }
    return () => {
      console.log('Clean up')
    }
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])
/*
  useEffect(() => {
    if (staffId) {
      GetApi(`/api/master/staff/${staffId}`)
        .then(result => setPhoto(result.data.facePhotoFile))
      console.log('--- staff photo ---')
    }
  }, [staffId, photo])
*/
  const funcShowFlg = (e) => {
    if (!e.target.closest('#staffInfo')) {
      setShowFlg('none')
    }
  }

  window.onclick = funcShowFlg

  return (
    <Tooltip title={getTooltipText()}>
    <WrapDiv rideMenu={props.rideMenu} onClick={showMenu} id='staffInfo'>
      <Avatar alt={staffName} src={photo !== null ? photo : adminIcon} style={{ width: '25px', height: '25px', marginRight: '5px' }} />
      {/* <IconImg src={props.rideMenu ? adminIcon2 : adminIcon} alt='adminIcon' /> */}

      <SettingMenu showFlg={showFlg}>
        <span>{staffName}</span>
        <span onClick={logout}>ログアウト</span>
      </SettingMenu>
    </WrapDiv>
    </Tooltip>
  )
}

export default StaffInfo
