import React, { useState } from 'react'
import PropTypes from 'prop-types'

import DailyTableWrap from '../../Wrap/DailyTableWrap'
import DailyTableHeader from './DailyTableHeader'
import DailyTableBody from './DailyTableBody'

import { useAlert } from 'react-alert'
import { useSelector, useDispatch } from 'react-redux'
import { GetApi,MultipartApi } from '../../../Common/ApiAxios'
import ErrorHandling from '../../../Common/ErrorHandling'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'


const DailyTable = ({ 
  register, getValues, setValue, watch, reset, errors, 
  dateDataList, staffWorkInfoList, selectedIndex, selectFunction, updatedAttendanceFunc, defaultUrl,workDivisions }) => {
  const [openPunchHistory, setOpenPunchHistory] = useState(false);
  const [openDailyAttendance, setOpenDailyAttendance] = useState(false);
  
  const [workInfo, setWorkInfo] = useState();
  const [dailyWorkInfoList, setDailyWorkInfoList] = useState([]);

  const alert = useAlert()
  const dispatch = useDispatch()
  const [punchDailyDataList, setPunchDailyDataList] = useState([])
  const [restFlag,setRestFlag] = useState(false)
  const handleOpenPunchHistory = (workInfo) => {
    const getPunchData = async (workInfo) => {
      dispatch(SetLoadSpinner(true))
  
      const searchOption = {
        targetDt: workInfo.baseDt,
        staffId: workInfo.staffId,
        orderId: workInfo.orderId
      }
      const resultDate = await GetApi('/api/punchManagement/punch/daily', searchOption)
      
      dispatch(SetLoadSpinner(false))
      if (resultDate.errorDetail) {
        ErrorHandling(resultDate, alert)
        return
      } else {
        console.log(resultDate.data.responseList.length>0)
        console.log(resultDate.data.responseList)
        //setCanUpdate(resultDate.data.responseList.length>0)
        setPunchDailyDataList(resultDate.data.responseList)
      }
      if(resultDate.data.responseList.length>0){
        setOpenPunchHistory(true)
      }else{
        alert.error("打刻履歴がありません")
        return
      }
    }
    setWorkInfo(workInfo)
    setRestFlag(workInfo.restFlag)
    getPunchData(workInfo)
    //setOpenPunchHistory(true);
  }

  const handleClosePunchHistory = () => {
    setOpenPunchHistory(false)
  }
  const handleOpenDailyAttendance = (dataArray) => {
    setDailyWorkInfoList(dataArray)
    setRestFlag(dataArray[0].restFlag)
    setOpenDailyAttendance(true);
  }

  const handleCloseDailyAttendance = () => {
    setOpenDailyAttendance(false)
  }

  return (
    <>
    <DailyTableWrap visible={staffWorkInfoList.length ? true : false}>
      <DailyTableHeader/>
      <DailyTableBody
        register={register}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        reset={reset}
        errors={errors}
        
        dateDataList={dateDataList}
        staffWorkInfoList={staffWorkInfoList}
        updatedAttendanceFunc={updatedAttendanceFunc}
        defaultUrl={defaultUrl}
        workDivisions={workDivisions}
      >
      </DailyTableBody>
    </DailyTableWrap>
    

    </>

  )
}

DailyTable.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  workDivisions: PropTypes.array,
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func,
  updatedAttendanceFunc: PropTypes.func,
  defaultUrl: PropTypes.string
}

export default DailyTable
