import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

import { DeleteApi, MultipartApi } from '../../Common/ApiAxios'
import Button from '../../Common/Atoms/NormalButton'
import FUNC from '../../Common/FunctionEnum'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
import { grantDecision } from '../../Common/Utilties/CommonUtility'

import MasterBaseFormWrap from '../Wrap/MasterBaseFormWrap'
import MasterEditButtonsWrap from '../Wrap/MasterEditButtonsWrap'
import { DefaultUrl } from './Allocation'
import AllocationMasterForm from './AllocationMasterForm'

const AllocationDetail = ({ onSearch, setDetail, setSelectedId, allocationDetail }) => {
  const { register, handleSubmit, setValue, watch, errors, reset, getValues, control } = useForm({ validateCriteriaMode: 'all' })
  const [actionType, setActionType] = useState('POST')
  const [isDeleted, setIsDeleted] = useState(false)
  const [isView, setIsView] = useState(false)
  const [canWriteStaffBasic, setCanWriteStaffBasic] = useState(false)
  const loginUserInfo = useSelector(state => state.loginUserInfo)
  const alert = useAlert()
  const dispatch = useDispatch()

  // 情報をFormに反映
  useEffect(() => {
    if (allocationDetail === null) {
      // 未選択
      setIsView(false)
      return
    }
    
    if (allocationDetail.orderId === undefined) {
      // 新規作成
      setActionType('POST')
      reset()
      setIsView(true)
      setIsDeleted(false)
      setCanWriteStaffBasic(grantDecision('write', FUNC.ALLOCATION, loginUserInfo, null, null))
    } else {
      // 編集
      setActionType('PUT')
      setIsView(true)
      setIsDeleted(allocationDetail.delFlag)
      setCanWriteStaffBasic(grantDecision('write', FUNC.ALLOCATION, loginUserInfo, null, null))
    }
  // 受注情報の変更検知時のみ実行
  // eslint-disable-next-line
  }, [allocationDetail])

  // POST・PUT処理
  const onSubmit = (data) => {
    async function sendPost (requestBody, data) {
      var result
      dispatch(SetLoadSpinner(true))
      switch (actionType) {
        case 'POST':
          result = await MultipartApi(`${DefaultUrl}`, actionType, requestBody, data)
          break
        case 'PUT':
          result = await MultipartApi(`${DefaultUrl}/${allocationDetail.orderId}`, actionType, requestBody, data)
          break
        default:
          result = false
          break
      }
      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(result, alert)) {
        isDeleted ? alert.success('有効化しました') : alert.success('保存しました')
        setDetail(null)
        setSelectedId(null)
        onSearch(null, 1)
        setActionType('POST')
      }
    }
    console.log(data)
    {
      data.holNightPrice=data.holNightPrice.replaceAll(',','')
      data.holNormalPrice=data.holNormalPrice.replaceAll(',','')
      data.holOverPrice=data.holOverPrice.replaceAll(',','')
      data.lawNightPrice=data.lawNightPrice.replaceAll(',','')
      data.lawNormalPrice=data.lawNormalPrice.replaceAll(',','')
      data.lawOverPrice=data.lawOverPrice.replaceAll(',','')
      data.nightPrice=data.nightPrice.replaceAll(',','')
      data.normalPrice=data.normalPrice.replaceAll(',','')
      data.overPrice=data.overPrice.replaceAll(',','')

      data.holNightBillingPrice=data.holNightBillingPrice.replaceAll(',','')
      data.holNormalBillingPrice=data.holNormalBillingPrice.replaceAll(',','')
      data.holOverBillingPrice=data.holOverBillingPrice.replaceAll(',','')
      data.lawNightBillingPrice=data.lawNightBillingPrice.replaceAll(',','')
      data.lawNormalBillingPrice=data.lawNormalBillingPrice.replaceAll(',','')
      data.lawOverBillingPrice=data.lawOverBillingPrice.replaceAll(',','')
      data.nightBillingPrice=data.nightBillingPrice.replaceAll(',','')
      data.normalBillingPrice=data.normalBillingPrice.replaceAll(',','')
      data.overBillingPrice=data.overBillingPrice.replaceAll(',','')
      
      data.monthlyTransportExpenses=data.monthlyTransportExpenses.replaceAll(',','')
      data.dailyTransportExpenses=data.dailyTransportExpenses.replaceAll(',','')

      data.staffIds = data.staffIds.split(',')
    }
    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    sendPost(submitData, data)
  }

  // DELETEはForm状態に影響されないため個別で定義
  const onClickHandleDelete = () => {
    async function sendDelete (orderId, data) {
      dispatch(SetLoadSpinner(true))
      const result = await DeleteApi(`${DefaultUrl}/${orderId}`, data, true)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert)
      if (result.data) alert.info('無効化しました')
    }
    if (allocationDetail.orderId !== undefined) {
      // const lastUpdatedAt = moment(allocationDetail.updatedAt, 'YYYY/MM/DD HH:mm:ss')
      const data = { lastUpdatedAt: allocationDetail.updatedAt }
      sendDelete(allocationDetail.orderId, data)
    }
  }

  return (
    <>
      <MasterBaseFormWrap onSubmit={handleSubmit(onSubmit)} className='h-adr' style={{display: allocationDetail != null ? 'block' : 'none'}}>
        <AllocationMasterForm
          register={register}
          errors={errors}
          isDeleted={isDeleted}
          getValues={getValues}
          control={control}
          setValue={setValue}
          allocationDetail={allocationDetail}
          watch={watch}
          canWrite={canWriteStaffBasic}
          loginUserInfo={loginUserInfo}
          actionType={actionType}
          isView={true}
        />
        <MasterEditButtonsWrap>
          <Button
            display={canWriteStaffBasic ? 'block' : 'none'}
            name={actionType === 'PUT' ? '更新' : '登録'}
            height='20px'
            width='90px'
            padding='0'
            margin='0 0 12px 0'
            type='submit'
          />
          <Button
            display='none' // TODO 一旦非表示
            height='20px'
            width='90px'
            padding='0'
            name='削除'
            type='button'
            margin='0 0 12px 10px'
            onClick={(e) => onClickHandleDelete(e)}
          />
        </MasterEditButtonsWrap>
      </MasterBaseFormWrap>
    </>
  )
}

AllocationDetail.propTypes = {
  allocationDetail: PropTypes.object,
  onSearch: PropTypes.func,
}

export default AllocationDetail
