import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import { useSelector, useDispatch } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import * as regex from '../../../Common/Regex'
import { InputGroup, SelectGroup, HiddenInput } from '../../../Common/CommonInputGroup'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
import { formatRound48Time, formatHour, makeElementId, calcWorkingHours,format48Time ,secToTime,diff48Time} from '../../../Common/Utilties/LaborUtility'
import Button from '../../../Common/Atoms/NormalButtonAttendance'


const ColumnWrap = styled.td`
  text-align:center;
  vertical-align:middle;
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const CellTop = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
`
/*
const CellBottom = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
  border-top: 1px solid #DDD;
`
*/
const ColumnOccupationTD = styled.td`
  font-size: 16px;
  width: 8rem;
  max-width: 8rem;
  vertical-align: middle;
  text-align: left;
  padding-left: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const DailyTableRow = ({
    register, getValues, setValue, watch, reset, errors, 
    defaultUrl, workInfo,workDivisions,restFlag,dailyWorkInfo
  }) => {

  const alert = useAlert()
  //const [workDivisions, setWorkDivisions] = useState(<></>)
  const [elementId, setElementId] = useState(makeElementId(workInfo))
  const [workDivision, setWorkDivision] = useState(0)



  // 初期化メソッド
  useEffect(() => {
    async function getOptions (isDiff) {
      //await getWorkDivisions()
      console.log(workInfo)
      if (workInfo) 
      {
        setValue('afterWorkingFlag', workInfo.afterWorkingFlag)
        setValue('beforeWorkingFlag', workInfo.beforeWorkingFlag)
  
   
        setValue('workingTime', formatRound48Time(secToTime(workInfo.workingTime),1))
        setValue('workingStartTime', diff48Time('1900-01-01', workInfo.workingStartTime))
        setValue('workingEndTime' , diff48Time('1900-01-01', workInfo.workingEndTime))
        setValue('nightStartTime' , diff48Time('1900-01-01', workInfo.nightStartTime))
        setValue('nightEndTime' , diff48Time('1900-01-01', workInfo.nightEndTime))
        setValue('deemedTime' , formatRound48Time(secToTime(workInfo.deemedTime),1))

      }

    }
    getOptions(workInfo.isDiff)
    console.log(workInfo.orderId)
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])


  const createElementStaff = (staff) => {
    if (staff) {
      let text = staff.staffName
      let textPx = measurePxText(text)
      let textRem = convertPxToRem(textPx)
  
      let checkRem = 5
      if (textRem < checkRem)
      {
        return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...')
        textRem = convertPxToRem(textPx)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></span></Tooltip>
    }

    return <><br/><span>( - )</span></>
  }

  const onBlurTime = ( event ) => {
    let value = event.target.value
    value = format48Time(value)
    event.target.value = value
  }
  const onOpen=()=>{

  }
  const onWorkingTime = ( e , flag) => {
    e.persist()
    var overTime = getValues(flag+'WorkingFlag')
    setValue(flag+'WorkingFlag',(overTime=='true'?false:true))
    e.target.innerText = (overTime=='true'?'不可':'可')

    console.log(overTime)
  }

  return (
    <tr>
      <ColumnOccupationTD>
        {createElementStaff(dailyWorkInfo)}
        <HiddenInput name='beforeWorkingFlag' register={register} defaultValue={workInfo.beforeWorkingFlag?'true':'false'}/>
        <HiddenInput name='afterWorkingFlag' register={register} defaultValue={workInfo.afterWorkingFlag?'true':'false'}/>
      </ColumnOccupationTD>
      <ColumnWrap style={{ width: '5rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'workingTime'}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '5rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'workingStartTime'}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '5rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'workingEndTime'}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '5rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'nightStartTime'}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '5rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'nightEndTime'}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '5rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'deemedTime'}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '5rem' }}>
        <CellPanel>
          <CellTop>
            <Button type='button' margin='auto'  padding='0' width='4rem' onClick={(e)=>onWorkingTime(e,'before')} name={workInfo.beforeWorkingFlag?'可':'不可'}/>
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '5rem' }}>
        <CellPanel>
          <CellTop>
            <Button type='button' margin='auto'  padding='0' width='4rem' onClick={(e)=>onWorkingTime(e,'after')} name={workInfo.afterWorkingFlag?'可':'不可'}/>
          </CellTop>
        </CellPanel>
      </ColumnWrap>
    </tr>
  )
}

DailyTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfo: PropTypes.object
}

export default DailyTableRow
