import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'

import { GetApi ,MultipartApi} from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
import FUNC from '../../Common/FunctionEnum'
import { createCustomerInfo } from '../../Common/Utilties/ObjectUtitlity'

import MasterPanelWrap from '../Wrap/MasterPanelWrap'
import MasterFlexWrap from '../Wrap/MasterFlexWrap'
import MasterListView from '../Molcules/MasterListView'
import Search from './Search'
import CustomerDetail from './CustomerDetail'

import MasterSelect from '../MasterSelect'

import {onExportCsvFile,makeCustomerCSV,makeCusDepartmentCSV,makeCustomerErrorCSV,makeCusDepartmentErrorCSV} from '../../Common/Utilties/ExportUtility'
import {onCheckComAndCus} from '../../Common/Utilties/ImportUtility'

export const DefaultUrl = '/api/masterManagement/customer'
const Header = "顧客ID,顧客名,顧客名カナ,郵便番号,都道府県,市区町村,町域名,建物名,電話番号,FAX番号,WEBサイト,連携用ID,部門有無"
const HeaderDepartment = "顧客部門ID,顧客ID,顧客名,階層,顧客部門名称,顧客部門名称カナ,郵便番号,都道府県,市区町村,町域名,建物名,電話番号,FAX番号,連携用ID,勤怠時間端数単位,基準内時間,支給端数計算区分,請求端数計算区分"

const Customer = (props) => {
    const customerIdRef = useRef()
    const customerNameRef = useRef()
    const [dataList, setDataList] = useState([])
    const [detail, setDetail] = useState(null) // 未選択状態
    const [selectedId, setSelectedId] = useState(null)
    const [searchOption, setSearchOption] = useState({ customerId: '', customerName: '' })
    const { loginUserInfo } = useSelector(state => state)
    const [pagenation, setPagenation] = useState({})
    const dispatch = useDispatch()
    const alert = useAlert()
    const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.CUSTOMER)
    
    const selectItem = async (id) => {
      dispatch(SetLoadSpinner(true))
      setSelectedId(id)
      const result = await GetApi(`${DefaultUrl}/${id}`)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert) || setDetail(result.data)
    }

    // 新規登録用初期化処理
    const createNew = (e) => {
      setDetail(createCustomerInfo()) // 新規作成時の値
      setSelectedId(null)
    }

    // 検索APIの実行
    const onSearch = async (createId, page) => {
      dispatch(SetLoadSpinner(true))
      setSearchOption(
        {
          customerId: (customerIdRef.current)?customerIdRef.current.value:'',
          customerName: (customerNameRef.current)?customerNameRef.current.value:'',
        }
      )
      const data = {
        customerId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : customerIdRef.current.value):'',
        customerName: (customerNameRef.current)?customerNameRef.current.value:'',
        page: page
      }
      const result = await GetApi(DefaultUrl, data)
      dispatch(SetLoadSpinner(false))
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else {
        const resultList = result.data.responseList.map(item => {
          return {
            index: item.customerId,
            id: item.customerId,
            name: item.customerName,
            delFlag: item.delFlag,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          }
        })
        setDataList(resultList)
        setPagenation(result.data)
        setSelectedId(null)
        setDetail(null) // 未選択状態
      }
    }
    const onKeyDown = (event) => {
      if (event.keyCode === 13) {
        onSearch(null, 1)
      }
    }

    const pageSelectFunc = (pageId) => {
      onSearch(null, pageId)
    }
    const onClickFunction=()=>{
      setViewFunction(!viewFunction)
    }
    const [viewFunction,setViewFunction] = useState(false)

    //ダウンロード処理
    const onExport=async()=>{
      dispatch(SetLoadSpinner(true))
      const data = {
        customerId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : customerIdRef.current.value):'',
        customerName: (customerNameRef.current)?customerNameRef.current.value:'',
      }
      const result = await GetApi(DefaultUrl+'/export', data)
      console.log(result)
      dispatch(SetLoadSpinner(false))
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else{
        var today = new Date();
        var day = (''+today.getFullYear()).slice(-2) + ('00' + (today.getMonth())).slice(-2) + ('00' + today.getDate()).slice(-2)
                + ('00' + (today.getHours())).slice(-2) + ('00' + today.getMinutes()).slice(-2) + ('00' + today.getSeconds()).slice(-2)
        var fileName = '顧客マスタ('+day+')'
        onExportCsvFile(makeCustomerCSV(result.data.customers,Header),fileName)

        fileName = '顧客部門マスタ('+day+')'
        onExportCsvFile(makeCusDepartmentCSV(result.data.departments,HeaderDepartment),fileName)
      }
    }
        //スキーマ取得処理
    const [schema,setSchema] = useState([])
    const [schemaDepartment,setSchemaDepartment] = useState([])
    const onSchema=async()=>{
      var data = {
        tableName: 'm_customer',
      }
      var result = await GetApi('/api/schema', data)
      console.log(result)
      if (result.errorDetail) {
        return null
      } else{        
        setSchema(
          result.data.map((schema)=>{
            let fieldName = schema.columnName.replace(/_[a-z]/g, function(match){return match.toUpperCase().slice(1)});
            
            let [type,maxLength] = schema.dataType.split('(')
            if(maxLength)
            {
              maxLength = maxLength.slice(0,maxLength.length-1)
            }      

            return{
              fieldName:fieldName,
              notNull:schema.notNull,
              maxLength:maxLength,
              type:type,
              comment:schema.comment,
            }
          })
        )
      }

      data = {
        tableName: 'm_cus_department',
      }
      result = await GetApi('/api/schema', data)
      console.log(result)
      if (result.errorDetail) {
        return null
      } else{        
        setSchemaDepartment(
          result.data.map((schema)=>{
            let fieldName = schema.columnName.replace(/_[a-z]/g, function(match){return match.toUpperCase().slice(1)});
            
            let [type,maxLength] = schema.dataType.split('(')
            if(maxLength)
            {
              maxLength = maxLength.slice(0,maxLength.length-1)
            }      

            return{
              fieldName:fieldName,
              notNull:schema.notNull,
              maxLength:maxLength,
              type:type,
              comment:schema.comment,
            }
          })
        )
      }
    }


    //インポート処理
    const onImport=(uploadData,type)=>{
      async function sendPost (requestBody, data,type) {
        var result
        dispatch(SetLoadSpinner(true))
        console.log(data)
        if(type){
          result = 
           await MultipartApi(`${DefaultUrl}/import/department`,'POST', requestBody, data)
        }
        else{
          result = 
          await MultipartApi(`${DefaultUrl}/import`,'POST', requestBody, data)
        }
         
        
        if (!ErrorHandling(result, alert)) {
          alert.success('保存しました')
   
          onSearch(null,1)
        }else{
          dispatch(SetLoadSpinner(false))
        }
        
      }

      var uploadDataList= new Array();
      console.log(uploadData)
      uploadData.forEach((data)=>{
        let dataRow = {
          customerId: data["顧客ID"], 
          customerName: data["顧客名"], 
          customerKana: data["顧客名カナ"], 
          zipCode: data["郵便番号"], 
          address1: data["都道府県"], 
          address2: data["市区町村"], 
          address3: data["町域名"], 
          address4: data["建物名"], 
          phoneNum: data["電話番号"], 
          faxNum: data["FAX番号"], 
          webUrl: data["WEBサイト"], 
          cooperationId: data["連携用ID"], 
          departmentFlag: data["部門有無"], 
          delFlag: 'FALSE',
        }
        if(type){
          dataRow = {
            cusDepartmentId: data["顧客部門ID"], 
            customerId: data["顧客ID"], 
            hierarchyNo: data["階層"], 
            cusDepartmentName: data["顧客部門名称"], 
            cusDepartmentKana: data["顧客部門名称カナ"], 

            zipCode: data["郵便番号"], 
            address1: data["都道府県"], 
            address2: data["市区町村"], 
            address3: data["町域名"], 
            address4: data["建物名"], 
            phoneNum: data["電話番号"], 
            faxNum: data["FAX番号"], 
    
            departmentDivision: 2,
            attendanceFractionUnit:data["勤怠時間端数単位"],
            payFractionDivision:data["支給端数計算区分"],
            attendanceBaseTime:data["基準内時間"],
            billFractionDivision:data["請求端数計算区分"],

            cooperationId: data["連携用ID"],  
            delFlag: 'FALSE', 
          }
        }  
        uploadDataList.push(dataRow)
      })
      
      
      var errorList = new Array();
      [uploadDataList,errorList]=onCheckComAndCus(uploadDataList,type?schemaDepartment:schema,type)
      if(errorList.length!=0)
      {
          alert.error(<>エラーレコードがあります。<br/>エラーレコードをCSV出力します。</>)
          var today = new Date();
          var day = (''+today.getFullYear()).slice(-2) + ('00' + (today.getMonth())).slice(-2) + ('00' + today.getDate()).slice(-2)
                  + ('00' + (today.getHours())).slice(-2) + ('00' + today.getMinutes()).slice(-2) + ('00' + today.getSeconds()).slice(-2)
          var fileName = (type?'【Error】顧客部門マスタデータ_':'【Error】顧客マスタデータ_')+day+''

          type?
          onExportCsvFile(makeCusDepartmentErrorCSV(errorList,HeaderDepartment),fileName)
          :onExportCsvFile(makeCustomerErrorCSV(errorList,Header),fileName)
      }
      console.log(uploadDataList,errorList)
      if(uploadDataList.length==0)
      {
          alert.error("更新するデータがありません")
          return
      }
      
      const submitData = new window.FormData()
      submitData.append('formData', new window.Blob([JSON.stringify(uploadDataList)], { type: 'application/json' }))

      sendPost(submitData, uploadDataList,type)
    }

    useEffect(() => {
      if (grants)
        document.title = grants.functionMenuName + ' | Core Time Cloud'
      else
        document.title = 'Core Time Cloud'
    }, [grants])
    
    //画面表示の時
    useEffect(()=>{
      onSchema()
      onSearch(null,1)
    },[])

    if (!grants) return null
    return (
        <MasterPanelWrap id='CustomerWrap'>
        <Search
          title={grants.functionMenuName}
          showButton
          onClick={() => onSearch(null, 1)}
          onKeyDown={(e) => onKeyDown(e)}
          customerIdRef={customerIdRef}
          customerNameRef={customerNameRef}
          grants={grants}
          viewFunction={viewFunction}
          onClickFunction={()=>onClickFunction()}
          onExport={()=>onExport()}
          onImport={(uploadData,type)=>onImport(uploadData,type)}
        >
        </Search>
        <MasterFlexWrap>
          <MasterSelect/>
            <MasterListView
              dataList={dataList}
              setSelectedId={setSelectedId}
              selectFunction={(id) => selectItem(id)}
              createFunction={() => createNew()}
              canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
              pageSelectFunc={pageSelectFunc}
              pagenation={pagenation}
              isIdView
            />
            <CustomerDetail
              onSearch={onSearch}
              setSelectedId={setSelectedId}
              setDetail={setDetail}
              customerDetail={detail}
              reSerach={(id) => selectItem(id)}
            >
            </CustomerDetail>
        </MasterFlexWrap>
        </MasterPanelWrap>
    )
}

export default Customer
