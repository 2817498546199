import React from 'react'
import PropTypes from 'prop-types'

import {DashboardBaseFormRow} from '../../../Wrap/DashboardBaseWrap'
import AttendanceBarGraph from './AttendanceBarGraph'
import AttendanceBar2Graph from './AttendanceBar2Graph'
import AttendancePersentGraph from './AttendancePersentGraph'
const GraphBody = ({ dateDataList }) => {

//visible={staffWorkInfoList.length ? true : false}
  return (
    <DashboardBaseFormRow>
        <div>
        <AttendancePersentGraph title={'曜日別残業割合'} attendanceList={dateDataList.attendanceOvertime}></AttendancePersentGraph>
        <AttendanceBar2Graph title={'曜日別勤怠実績'} 
          attendanceList={dateDataList.attendanceResult}
          axisTitle={['weeklyShiftTime','weeklyAttendanceTime','weeklyShift','weeklyAttendance']}>
        </AttendanceBar2Graph>
        </div>
        <div>
        <AttendanceBarGraph title={'曜日別平均労働時間'} 
          attendanceList={dateDataList.attendanceWorkTime} 
          axisTitle={['monthWorkerTotal','avgMonthWork']}>
        </AttendanceBarGraph>
        <AttendanceBarGraph title={'曜日別欠勤人数'} 
          attendanceList={dateDataList.attendanceAbsenceStaff}
          axisTitle={['absenceMonthlyCt','absenceMonthlyWorkTotal']}></AttendanceBarGraph>
        </div>
    </DashboardBaseFormRow>
  )
}

GraphBody.propTypes = {
  dateDataList: PropTypes.array,
}

export default GraphBody
