import React from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

const ColumnWrap = styled.th`
  vertical-align:middle;
  padding: 0.25rem;
`

const DailyTableHeader = () => {

  return (
    <thead>
      <tr>
        <ColumnWrap style={{ width: '8rem' }}>スタッフ名</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>労働総時間</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>作業開始</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>作業終了</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>深夜開始</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>深夜終了</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>みなし</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>終業時刻前</ColumnWrap>
        <ColumnWrap style={{ width: '5rem' }}>終業時刻後</ColumnWrap>
      </tr>
    </thead>
  )
}

DailyTableHeader.propTypes = {
}

export default DailyTableHeader
