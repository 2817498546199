import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import FUNC from '../../Common/FunctionEnum'
import { useForm } from 'react-hook-form'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
//import Select from '../../Common/Atoms/SelectAddValue'

import Search from './Search'
import DashboardBaseWrap,{DashboardBaseFormRow} from '../Wrap/DashboardBaseWrap'

import DashBoardSelect from '../DashboardSelect'

//import { Tooltip } from '@material-ui/core'
// import StaffBiometricPanelWrap,{StaffBiometricFlexWrap,CountPanelWrap,StaffBiometricPageButtonWrap,StaffBiometricPagingPanelWrap,StaffBiometricPagingChangePanelWrap,PagingIconImg,PagingIconNextImg,StaffBiometricPagingIconPanelWrap} from './Wrap/StaffBiometricPanelWrap'
import SalariedGraphBody from './Molcules/SalariedGraph/SalariedGraphBody'
export const DefaultUrl = '/api/dashboardManagement/salaried'

const Salaried = (props) => {
  const [searchOption, setSearchOption] = useState({targetDt:'',postType:'',postId:'',departmentId:''})
  const { loginUserInfo } = useSelector(state => state)
  const targetDateRef = useRef()
  const [authStatus, setAuthStatus] = useState(0)
  const [staffInfoList, setStaffInfoList] = useState([])
  const [staffInfoAllList, setStaffInfoAllList] = useState([])
  const [LimitList, setLimitList] = useState([])
  const [dateDataList, setDateDataList] = useState([])
  const [pagenation, setPagenation] = useState({})
  const [staffCount, setStaffCount] = useState(0)
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const pageRecord = 7
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()
  const [serachType,setSerachType] = useState(1)//0:グラフ 1:リスト


  const dispatch = useDispatch()
  const alert = useAlert()
  const [dashboardDataList, setDashboardDataList] = useState([])
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.DASHBOARD)

  // 検索APIの実行
  const onSearch = async (createId, page) => {
    //グラフ(会社名が"全て"の場合)
    const serachGraphALL = async(data)=>{
      dispatch(SetLoadSpinner(true))
      const sendData = {
        targetDt: data.targetDt,
        postType:postType,
        postId:data.postId,
        departmentId:data.departmentId,
        page:1,
      }
      console.log(sendData)

      const resultDashBoardData = await GetApi(`${DefaultUrl}`,sendData)
      if (resultDashBoardData.errorDetail) {
        dispatch(SetLoadSpinner(false))
        ErrorHandling(resultDashBoardData, alert)
        return null
      }
      
      setDashboardDataList(makeDashboardData(resultDashBoardData.data))
      dispatch(SetLoadSpinner(false))
      console.log(resultDashBoardData)
    }
    
    setSearchOption(
      {
        targetDt: targetDateRef.current,
        postType:postType,
        postId:(postId!=undefined && postId != -1) ? postId:'',
        departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      }
    )
   
    const data = {
      targetDt: targetDateRef.current,
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId.replace(new RegExp(0 + "+$", "g"),''):'',
      page: page
    }

    serachGraphALL(data)
    


    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
  }

  const makeDashboardData = (data) =>{
    var count = 0
    data.monthlySalariedStaffCount =
    data.monthlySalariedStaffCountList.map((row)=>{
        return {dateValue:count++,salariedCount:row}
    })
    data.monthlySalariedStaffCount[6].dateValue='16日以上'

    count = 0
    data.yearlySalariedStaffCount =
    data.yearlySalariedStaffCountList.map((row)=>{
        return {dateValue:count++,salariedCount:row}
    })
    data.yearlySalariedStaffCount[6].dateValue='16日以上'
    return data
  }

  // 検索項目でキーボードの押下イベント
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }

  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
  }
  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud'
    else
      document.title = 'Core Time Cloud'
  }, [grants])

  useEffect(() => {
    setPost()
    onSearch(null,1)
  }, [])
  
  const setPost=()=>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      }
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.companyId,
          name: item.companyName+'('+item.companyId+')',
        }
      })
      setPostList(resultList)
      return true
    }
    const serachCustomer = async()=>{
      const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } 
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.customerId,
          name: item.customerName+'('+item.customerId+')',
        }
      })
      setPostList(resultList)
      return true
    }

    if(postType==0){
      serachCompany()
    }else{
      serachCustomer()
    }
  }

  //会社顧客変更処理
  useEffect(() => {
    setPost()
    // 必要なデータが更新された場合のみ実行
    // eslint-disable-next-line
  }, [postType])
    
  const [viewFunction,setViewFunction] = useState(false)
    
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }
  if(!postList){
    setPost()
  }

  if (!targetDateRef.current) {
    var today = new Date();
    targetDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-01'
  }
  if (!grants) return null
  return (
    <DashboardBaseWrap>
      <DashboardBaseFormRow>
        <Search
            showButton
            onClick={() => onSearch(null, pageType==0?1:0)}
            onClickOption={(e) => onClickOptionBtn(e)}
            onKeyDown={(e) => onKeyDown(e)}
            shiftDateRef={targetDateRef}
            viewSubSearch={viewSubSearch}
            grants={grants}
            postList={postList}
            postId={postId}
            setPostId={setPostId}
            departmentId={departmentId}
            setDepartmentId={setDepartmentId}
            postType={postType}
            setPostType={setPostType}
            viewFunction={viewFunction}
            onClickFunction={()=>onClickFunction()}>
        </Search>
      </DashboardBaseFormRow>
      <DashboardBaseFormRow>
        <DashBoardSelect/>
        {dashboardDataList.length != 0 &&
          <SalariedGraphBody dateDataList={dashboardDataList}></SalariedGraphBody>
          //<OverTimeTable></OverTimeTable>
        }
      </DashboardBaseFormRow>
    </DashboardBaseWrap>
  )
}

export default Salaried
