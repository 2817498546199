import React,{useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import TableRow from './PalDashBoardWorkplaceTableRow'
export const TableWrap = styled.table`
  justify-content: center;
  border-collapse:separate;
  border-spacing: 1px;
  margin-top: 1rem;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(28rem + 13px);
  }

  tbody {
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(50vh - 2px);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
    height: 2rem;
  }

  th {
    font-size: 16px;
    background-color: #649cdf;
  }

  td {
    font-size: 16px;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`
const ColumnWrap = styled.th`
  vertical-align:middle;
  padding: 0.25rem;
  height: 2rem;
`
const DashboardTable = ({
    ColumnList
  }) => {

  return (
    <TableWrap  visible={ColumnList.length ?true : false}>
        <thead>
            <tr>
                <ColumnWrap style={{width:'2rem'}}></ColumnWrap>
                <ColumnWrap style={{width:'13rem'}}>所属部署名</ColumnWrap>
                <ColumnWrap style={{width:'13rem'}}>現場名</ColumnWrap>
            </tr>
        </thead>
        <tbody>
        {ColumnList && ColumnList.map((ColumnData) => {
            if(ColumnData.checked==undefined)ColumnData.checked=false
            return (
              <TableRow ColumnData={ColumnData}/>
            )
        })}
      </tbody>
    </TableWrap>
  )
}

DashboardTable.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func
}

export default DashboardTable
