import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import { useSelector, useDispatch } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import * as regex from '../../../Common/Regex'
import { InputGroup, SelectGroup, HiddenInput } from '../../../Common/CommonInputGroup'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
import { formatRound48Time, formatHour, makeElementId, calcWorkingHours,calcWorkingRestFreeHoursTime ,secToTime,diff48Time,format48Time} from '../../../Common/Utilties/LaborUtility'
import Button from '../../../Common/Atoms/NormalButtonAttendance'


const ColumnWrap = styled.td`
  text-align:center;
  vertical-align:middle;
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const CellTop = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
`
/*
const CellBottom = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
  border-top: 1px solid #DDD;
`
*/
const ColumnOccupationTD = styled.td`
  font-size: 16px;
  width: 8rem;
  max-width: 8rem;
  vertical-align: middle;
  text-align: left;
  padding-left: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const DailyTableRow = ({
    register, getValues, setValue, watch, reset, errors, 
    defaultUrl, workInfo,workDivisions,restFlag,dailyWorkInfo
  }) => {

  const alert = useAlert()
  //const [workDivisions, setWorkDivisions] = useState(<></>)
  const [elementId, setElementId] = useState(makeElementId(workInfo))
  const [workDivision, setWorkDivision] = useState(0)



  // 初期化メソッド
  useEffect(() => {
    async function getOptions (isDiff) {
      //await getWorkDivisions()
      console.log(workInfo)
      if (workInfo) 
      {

      //初期値設定
      if(workInfo.daily==null) workInfo.daily=0
      if(workInfo.monthly==null) workInfo.monthly=0
      if(workInfo.housingAllowance==null) workInfo.housingAllowance=0
      if(workInfo.fullTimeAllowance==null) workInfo.fullTimeAllowance=0
      if(workInfo.familyAllowance==null) workInfo.familyAllowance=0
      if(workInfo.jobAllowance==null) workInfo.jobAllowance=0
      if(workInfo.specialAllowance==null) workInfo.specialAllowance=0
      if(workInfo.transportationExpenses==null) workInfo.transportationExpenses=0

      setValue('daily', Number(workInfo.daily).toLocaleString())
      setValue('monthly',Number(workInfo.monthly).toLocaleString())
      setValue('housingAllowance', Number(workInfo.housingAllowance).toLocaleString())
      setValue('fullTimeAllowance', Number(workInfo.fullTimeAllowance).toLocaleString())
      setValue('familyAllowance', Number(workInfo.familyAllowance).toLocaleString())
      setValue('jobAllowance', Number(workInfo.jobAllowance).toLocaleString())
      setValue('specialAllowance',Number(workInfo.specialAllowance).toLocaleString())
      setValue('transportationExpenses', Number(workInfo.transportationExpenses).toLocaleString())
      //setTransportation(Number(workInfo.transportationExpenses).toLocaleString())

      }

    }
    getOptions(workInfo.isDiff)
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])


  const createElementStaff = (staff) => {
    if (staff) {
      let text = staff.staffName
      let textPx = measurePxText(text)
      let textRem = convertPxToRem(textPx)
  
      let checkRem = 5
      if (textRem < checkRem)
      {
        return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...')
        textRem = convertPxToRem(textPx)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></span></Tooltip>
    }

    return <><br/><span>( - )</span></>
  }

  const onBlurAmount = ( event ) => {
    let value = event.target.value
    value = Number(value.replace(/,/g, '')).toLocaleString()
    if(value=='NaN') value = '0'

    var name = event.target.name.split('_')[0]
    workInfo[name] = value.replace(/,/g, '')

    event.target.value = value
  }

  const onHopeButton = ( event,flag ) => {
    event.persist()
    var overTime = getValues(flag)
    setValue(flag, (overTime=='true'?false:true))
    event.target.innerText = (overTime=='true'?'不可':'可')

  }

  return (
    <tr>
      <ColumnOccupationTD>
        {createElementStaff(dailyWorkInfo)}
        <HiddenInput name='overtimePropriety' register={register} value={workInfo.overtimePropriety?'true':'false'}/>
      </ColumnOccupationTD>
      <ColumnWrap style={{ width: '7rem' }}>
        <CellPanel>
          <CellTop>
          {workInfo.staffDivisionName}
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '7rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'daily'}
              width='6.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurAmount}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '7rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'monthly'}
              width='6.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurAmount}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '7rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'housingAllowance'}
              width='6.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurAmount}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '7rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'fullTimeAllowance'}
              width='6.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurAmount}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '7rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'familyAllowance'}
              width='6.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurAmount}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '7rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'jobAllowance'}
              width='6.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurAmount}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '7rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'specialAllowance'}
              width='6.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurAmount}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '7rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'transportationExpenses'}
              width='6.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurAmount}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
    </tr>
  )
}

DailyTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfo: PropTypes.object
}

export default DailyTableRow
