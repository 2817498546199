import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { TableWrap } from '../../../Wrap/MasterDialogWrap'

import DailyTableHeader from './StaffHeader'
import DailyTableBody from './StaffTableBody'

const StaffTable = ({
    register, getValues, setValue, watch, reset, errors,
    defaultUrl, workInfoList, selectedIndex, selectFunction,workDivisions,staffTypeOptions,staffIds
  }) => {

  return (
    <TableWrap visible={workInfoList.length ? true : false}>
      <DailyTableHeader/>
      <DailyTableBody
        register={register}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        reset={reset}
        errors={errors}
        workDivisions={workDivisions}
        defaultUrl={defaultUrl}
        workInfoList={workInfoList}
        staffTypeOptions={staffTypeOptions}
        staffIds={staffIds}
      >
      </DailyTableBody>
    </TableWrap>
  )
}

StaffTable.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func
}

export default StaffTable
