import React,{useState,useEffect} from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import DashboardMain from './DashboardMain'
import OverTimeWork from './OverTimeWork/OverTimeWork'
import Salaried from './Salaried/Salaried'
import AttendanceMonthly from './AttendanceMonthly/AttendanceMonthly'
import AttendanceWeekly from './AttendanceWeekly/AttendanceWeekly'
import StaffComposition from './StaffComposition/StaffComposition'
import PalDashboard from './PalDashboard/PalDashboard'
import { useSelector, useDispatch } from 'react-redux'
import FUNC from '../Common/FunctionEnum'
import IconCollapseImg from '../../image/Collapse.png'

const Container = styled.div`
  .AppDashborad {
    flex-direction: column;
    background-repeat: no-repeat;
    background-color: #FFF;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
`
const DashBoardModel = styled.div`
    display: flex; /* 横並び */
    background-repeat: no-repeat;
    background-color: #FFF;
    height: calc(100vh);
    width: 100vw;
    overflow-y: auto;
`

const DashBoardBody = styled.div`
    flex-direction: column;
    background-repeat: no-repeat;
    background-color: #FFF;
    height: calc(100vh);
    width: 80vw;
    overflow-y: auto;
`

const DashBoardSelect = styled.div`
    display: ${props => props.visible ? 'block' : 'none'};
    flex-direction: column;
    background-repeat: no-repeat;
    height: calc(100vh);
    width: 20vw;
    overflow-y: auto;
    background-color: #cfe2f3;
`

const DashBoardSelectNone = styled.div`
    display: ${props => props.visible ? 'block' : 'none'};
    flex-direction: column;
    background-repeat: no-repeat;
    overflow-y: auto;
    background-color: #FFF;
`

const DashBoardSelectRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1vw;
    background-repeat: no-repeat;
    width: 20vw;
    overflow-y: auto;
`
const DashBoard = () => {
    const { loginUserInfo } = useSelector(state => state)
    const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.DASHBOARD)
    const [viewDasdboardLink,setViewDasdboardLink] = useState(true)
    const [title,setTitle] = useState('')
    useEffect(() => {
        if (grants)
          document.title = grants.functionMenuName + ' | Core Time Cloud'
        else
          document.title = 'Core Time Cloud'
      }, [grants])
    const onClick =()=>{    
        setViewDasdboardLink(!viewDasdboardLink)
    }
    const onClickLink =(id)=>{ 
        switch(id){
            case 0:
                setTitle("勤怠（月別）")
                break
            case 1:
                setTitle("勤怠（曜日別）")
                break
            case 2:
                setTitle('有給休暇')
                break
            case 3:
                setTitle('スタッフ構成')
                break
            case 4:
                setTitle('時間外労動')
                break
            default:
                setTitle('')
                break
        }   
    }  
return (
  <Container>
        <div className='AppDashborad' id='AppDashborad'>
            <div className='mainDashborad'>
              <Switch>
                <Route path='/dashboard/OverTimeWork' component={OverTimeWork} />
                <Route path='/dashboard/Salaried' component={Salaried} />
                <Route path='/dashboard/AttendanceMonthly' component={AttendanceMonthly} />
                <Route path='/dashboard/AttendanceWeekly' component={AttendanceWeekly} />
                <Route path='/dashboard/StaffComposition' component={StaffComposition} />
                <Route path='/dashboard/PalDashboard' component={PalDashboard} />
                <Route exact component={AttendanceMonthly} />
              </Switch>
            </div>
        </div>
    {/* <div style={{display: 'flex'}}>
        <div className='title' style={{padding:'1vw',fontSize:'24px'}}>{'ダッシュボード'}</div>
        <img src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'16px'}}/>
        <div className='subTitleItem' style={{padding:'1vw',fontSize:'16px',marginTop:'6px'}}>{title}</div>
    </div>
    <DashBoardModel>
    <DashBoardSelect visible={viewDasdboardLink}>
        <DashBoardSelectRow>
            <img src={IconCollapseImg} width="24px" height="24px" style={{transform: 'rotate(180deg)' ,cursor: 'pointer' }} onClick={()=>onClick()}/>
        </DashBoardSelectRow>
        <DashBoardSelectRow>
        <Link to="/dashboard/AttendanceMonthly" onClick={()=>onClickLink(0)}>
        <Tooltip title={'勤怠（月別）'}>
            <div>{'勤怠（月別）'}</div>
        </Tooltip>
        </Link>
        </DashBoardSelectRow>
        <DashBoardSelectRow>
        <Link to="/dashboard/AttendanceWeekly" onClick={()=>onClickLink(1)}>
        <Tooltip title={'勤怠（曜日別）'}>
            <div>{'勤怠（曜日別）'}</div>
        </Tooltip>
        </Link>
        </DashBoardSelectRow>
        <DashBoardSelectRow>
        <Link to="/dashboard/Salaried" onClick={()=>onClickLink(2)}>
        <Tooltip title={'有給休暇'}>
            <div>{'有給休暇'}</div>
        </Tooltip>
        </Link>
        </DashBoardSelectRow>
        <DashBoardSelectRow>
        <Link to="/dashboard/StaffComposition" onClick={()=>onClickLink(3)}>
        <Tooltip title={'スタッフ構成'}>
            <div>{'スタッフ構成'}</div>
        </Tooltip>
        </Link>
        </DashBoardSelectRow>
        <DashBoardSelectRow>
        <Link to="/dashboard/OverTimeWork" onClick={()=>onClickLink(4)}>
        <Tooltip title={'時間外労働'}>
            <div>{'時間外労動'}</div>
        </Tooltip>
        </Link>
        </DashBoardSelectRow>
    </DashBoardSelect>
    <DashBoardSelectNone visible={!viewDasdboardLink}>
    <DashBoardSelectRow>
        <img src={IconCollapseImg} width="24px" height="24px" style={{cursor: 'pointer' }} onClick={()=>onClick()}/>
    </DashBoardSelectRow>
    </DashBoardSelectNone>
    <DashBoardBody>
        <div className='AppDashborad' id='AppDashborad'>
            <div className='mainDashborad'>
              <Switch>
                <Route path='/dashboard/OverTimeWork' component={OverTimeWork} />
                <Route exact component={DashboardMain} />
              </Switch>
            </div>
        </div>
    </DashBoardBody>
    </DashBoardModel> */}
  </Container>
)
}

export default DashBoard
