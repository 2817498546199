import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

//import { formatHour } from '../../../Common/Utilties/LaborUtility'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'

import { ColumnStaffTD, ColumnWorkTD, ColumnTimeTD, ColumnCountTD,ColumnSalariedCountTD,ColumnStaffIdTD} from '../../Wrap/SalariedTableWrap'


const MonthTableBody = ({ staffWorkInfoList, setOpenDialog,selectFunction, updatedShiftFunc, defaultUrl,setTargetId }) => {

  const onLabelClick = (staff) =>{
      console.log(staff)
      setTargetId(staff.staffId)
      setOpenDialog(true)
  }
  const createElementStaff = (staff) => {
    if (!staff) {
      return <><br/><span>( - )</span></>
    }
    let text = staff.staffName
    let textPx = measurePxText(text)
    let textRem = convertPxToRem(textPx)

    let checkRem = 9
    if (textRem < checkRem)
    {
      return <>{text}<br/><ColumnStaffIdTD onClick={()=>onLabelClick(staff)}>({staff.staffId})</ColumnStaffIdTD></>
    }

    let editedText = text
    while (textRem > checkRem) {
      editedText = editedText.slice(0, -1)
      textPx = measurePxText(editedText + '...')
      textRem = convertPxToRem(textPx)
    }

    editedText = editedText + '...'

    return <Tooltip title={text}><span>{editedText && editedText}<br/><ColumnStaffIdTD onClick={()=>onLabelClick(staff)}>({staff.staffId})</ColumnStaffIdTD></span></Tooltip>
  }
  const createElementEnterDt = (staff) => {
    if (!staff) {
      return <><br/><span>（ - ）</span></>
    }
    let text = staff.enterDt
    let nowDate = new Date()
    let enterDt = new Date(staff.enterDt)
    let d1Month = enterDt.getFullYear() * 12 + enterDt.getMonth()
    let d2Month = nowDate.getFullYear() * 12 + nowDate.getMonth()
    let diffMonth = d2Month - d1Month
    let diffYear = Math.floor((10.0*diffMonth) / 12.0)/10.0
    return <>{text}<br/><span>（{diffYear}）</span></>
  }
  const createElementSalariedCount = (staff) => {
    if (!staff) {
        return  <ColumnTimeTD></ColumnTimeTD>
      }
    let actualDays = staff.actualSalariedDates.length
    let nowDate = new Date()
    let acquisitionDt = new Date(staff.acquisitionDt)
    let d1Month = acquisitionDt.getFullYear() * 12 + acquisitionDt.getMonth()
    let d2Month = nowDate.getFullYear() * 12 + nowDate.getMonth()
    let diffMonth = d2Month - d1Month
    
    if(diffMonth > 6 && actualDays < 5){
        return <ColumnSalariedCountTD>{actualDays}</ColumnSalariedCountTD>
    }

    return <ColumnCountTD>{actualDays}</ColumnCountTD>
  }

  const createElementPasentage= (staff) => {
    if (!staff) {
        return  <></>
      }
    let actualDays = staff.actualSalariedDates.length
    let salariedDays = staff.acquiredDays + staff.remaingDaysPreviousYear
    if(salariedDays <= 0 || actualDays<=0){
        return  <>0.0</>
    }
    let persent = Math.floor((1000.0*actualDays) / salariedDays)/10.0
    
    return <>{persent}</>
  }

  const createElementSalariedYearsCount= (staff) => {
    if (!staff) {
        return  <></>
      }
    var tmp = new Array();
    for(var i=0;i<12;i++){
        var aCount = staff.actualSalariedDatesCount[i];
        var sCount = staff.planSalariedDatesCount[i];
        tmp.push(<ColumnWorkTD>{aCount}（{sCount}）</ColumnWorkTD>);
    }

    return tmp
  }

  return (
    <tbody>
      {staffWorkInfoList && staffWorkInfoList.map((staff) => {
        return (
          <tr key={staff.staffId+'_'+staff.acquisitionDt}>
            <ColumnStaffTD>{createElementStaff(staff)}</ColumnStaffTD>
            <ColumnTimeTD>{createElementEnterDt(staff)}</ColumnTimeTD>
            <ColumnCountTD>{createElementPasentage(staff)}</ColumnCountTD>
            {createElementSalariedCount(staff)}
            <ColumnCountTD>{staff.acquiredDays + staff.remaingDaysPreviousYear - staff.actualSalariedDates.length}</ColumnCountTD>
            <ColumnTimeTD>{staff.acquisitionDt}</ColumnTimeTD>
            <ColumnTimeTD>{staff.limitDt}</ColumnTimeTD>
            {createElementSalariedYearsCount(staff)}
          </tr>
        )
      })}
    </tbody>
  )
}

MonthTableBody.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
}

export default MonthTableBody
