import styled from 'styled-components'

export const ColumnStaffTH = styled.th`
  width: 12rem;
  vertical-align:middle;
`

export const ColumnWorkTD = styled.td`
  width: 5rem;
  height: 2rem;
  vertical-align:middle;
  text-align:center;
`
export const ColumnTimeTD = styled.td`
  width: 7vW;
  vertical-align:middle;
  text-align:center;
`

export const ColumnCountTD = styled.td`
  width: 6rem;
  vertical-align:middle;
  text-align:center;
`
export const ColumnSalariedCountTD = styled.td`
  width: 6rem;
  vertical-align:middle;
  text-align:center;
  color: red;
  background-color: MistyRose;
`

export const ColumnStaffTD = styled.td`
  font-size: 16px;
  width: 12rem;
  vertical-align:middle;
  /* 未切れ対策 */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* end*/
`
export const ColumnStaffIdTD = styled.div`
  padding-left: 0.5rem;
  color:blue;
  text-decoration: underline;
`

export const ColumnTimeTH = styled.th`
  width: 7vw;
  vertical-align:middle;
`
export const ColumnSpan = styled.span`
    white-space: pre-line;
`
export const ColumnCountTH = styled.th`
  width: 6rem;
  vertical-align:middle;
`
export const RowHeaderTop = styled.tr`
  background-color: #649cdf;
`

export const RowHeaderBottom = styled.tr`
  background-color: #FFE8E6;
`

export const ColumnDateTH = styled.th`
  width: 25vw;
  height: 2rem;
  vertical-align:middle;
`
export const ColumnDateTD = styled.td`
  width: 25vw;
  height: 4rem;
  vertical-align:middle;
`

const SalariedTableWrap = styled.table`
  font-size: 12px;
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(115rem + 33px);
  }

  tbody {
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(80vh - 6rem - 3rem - 4rem + 5px);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
  }

  td {
    height: 3rem;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

export default SalariedTableWrap