import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ColumnWrap = styled.th`
  vertical-align:middle;
  padding: 0.25rem;
`

const DailyTableHeader = () => {

  return (
    <thead>
      <tr>
        <ColumnWrap style={{ width: '8rem' }}></ColumnWrap>
        <ColumnWrap style={{ width: '2rem' }}></ColumnWrap>
        <ColumnWrap style={{ width: '7rem' }}>出勤区分</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>勤務時間</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>休憩時間</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>残業時間</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>出勤</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>退勤</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>休始1</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>休終1</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>休始2</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>休終2</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>休始3</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>休終3</ColumnWrap>
        <ColumnWrap style={{ width: '10rem' }}>備考</ColumnWrap>
      </tr>
    </thead>
  )
}

DailyTableHeader.propTypes = {
}

export default DailyTableHeader
