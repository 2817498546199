import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import PunchTableRow from './PunchTableRow'

const PunchTableBody = ({ 
  register, getValues, setValue, watch, reset, errors, 
  punchDailyDataList, selectFunction, updatedAttendanceFunc, defaultUrl }) => {
    const [punchRows, setPunchRows] = useState(<></>)

  useEffect(() => {
    if(!punchDailyDataList || punchDailyDataList.length === 0) {
      setPunchRows(<></>)
      return
    }

    var dailyData = punchDailyDataList[0];

    const rows = dailyData.punchInfoList.map(value => 
      <PunchTableRow key={"top_" + value.biometricId}
        register={register}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        reset={reset}
        errors={errors}

        defaultUrl={defaultUrl}
        punchData={value}
      >
      </PunchTableRow>
    )
    setPunchRows(rows)
  }, [punchDailyDataList])

  // 初期化メソッド
  useEffect(() => {
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  return (
    <tbody>
      {punchRows}
    </tbody>
  )
}

PunchTableBody.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  punchDailyDataList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedAttendanceFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
}

export default PunchTableBody
