import styled from 'styled-components'

export const DashboardBaseForm = styled.div`
  display: flex;
`

export const ColumnStaffTH = styled.th`
  width: 14rem;
  vertical-align:middle;
  height: 2rem;
`

export const ColumnWorkTD = styled.td`
  width: 3.5rem;
  height: 2rem;
  vertical-align:middle;
  text-align:center;
`
export const ColumnTimeTD = styled.td`
  width: 9.5vW;
  vertical-align:middle;
  text-align:center;
`

export const ColumnCountTD = styled.td`
  width: 4rem;
  vertical-align:middle;
  text-align:center;
`
export const ColumnSalariedCountTD = styled.td`
  width: 4rem;
  vertical-align:middle;
  text-align:center;
  color: red;
  background-color: MistyRose;
`

export const ColumnStaffTD = styled.td`
  font-size: 16px;
  width: 14rem;
  vertical-align:middle;
  /* 未切れ対策 */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* end*/
`
export const ColumnStaffIdTD = styled.div`
  padding-left: 0.5rem;
  color:blue;
  text-decoration: underline;
`

export const ColumnTimeTH = styled.th`
  width: 9.5vw;
  vertical-align:middle;
`
export const ColumnSpan = styled.span`
    white-space: pre-line;
`
export const ColumnCountTH = styled.th`
  width: 4rem;
  vertical-align:middle;
`
export const RowHeaderTop = styled.tr`
  background-color: #649cdf;
`

export const RowHeaderBottom = styled.tr`
  background-color: #FFE8E6;
`

export const ColumnDateTH = styled.th`
  width: 25vw;
  height: 2rem;
  vertical-align:middle;
`
export const ColumnDateTD = styled.td`
  width: 25vw;
  height: 4rem;
  vertical-align:middle;
`

export const CountPanelWrap = styled.div`
  display: flex;
  align: right;
  align-items: right;
  text-align: right;
  margin-right: 1rem;
  margin-top: 8px;
  margin-left: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  background-position: right;
  justify-content: right;
  flex-flow: column;
`

export const PageButtonWrap = styled.div`
display: flex;
align: right;
margin-left:calc(50vw - 7rem);
`
export const PagingPanelWrap = styled.div`
display: flex;
align: right;
margin-left: 5rem;
margin-top: 0.5rem;
`
export const PagingIconPanelWrap = styled.div`
text-align:center;
line-height:32px;
justify-content: center;
font-size: 12px;
width: 22px;
height: 36px;
`
export const PagingIconImg = styled.img`
  align-items: left;
  width: 44px;
  height: 36px;
`
export const PagingIconNextImg = styled.img`
  align-items: left;
  width: 22px;
  height: 36px;
`
export const PagingChangePanelWrap = styled.div`
  text-align:center;
  line-height:32px;
  justify-content: center;
  font-size: 16px;
  width: 70px;
  height: 36px;
  color:blue;
  margin-right:calc(50vw - 14rem);
`

const TableWrap = styled.table`
  font-size: 12px;
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(85.5vw + 14rem + 22px);
  }

  tbody {
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(80vh - 6rem - 4rem + 5.5px);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
  }

  td {
    height: 3rem;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

export default TableWrap