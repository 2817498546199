import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useAlert } from 'react-alert'
import Tooltip from '@material-ui/core/Tooltip'

import { InputGroup, SelectGroup } from '../../../Common/CommonInputGroup'
import Button from '../../../Common/Atoms/NormalButtonAttendance'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
import { diff48Time, formatHour, makeElementIdByDailyWorkInfo, calcWorkingHours ,formatRound48Time,calcWorkingRestFreeHours,secToTime} from '../../../Common/Utilties/LaborUtility'

import { ColumnStaffTD, ColumnOccupationTD, ColumnDateTD, ColumnWorkTypeTD, ColumnTimeTD, ColumnRemarkTD, ColumnButtonsTD } from '../../Wrap/DailyTableWrap'
//import { min } from '../../../Common/Regex'
import DailyShiftModal from '../EmploymentDetailModel/EmploymentDetailModel'
import HopetDetailModel from '../HopeDetailModel/HopeDetailModel'
import PaymentDetailModel from '../PaymentDetailModel/PaymentDetailModel'

const DailyTableRow = ({ 
    register, getValues, setValue, watch, reset, errors, 
    workDivisions, staff }) => {


  // 勤怠情報切り替え時
  useEffect(() => {
    console.log(staff)
  // eslint-disable-next-line
  }, [])

  const createElementStaff = (staff) => {
    if (staff) {
      let text = staff.staffName
      let textPx = measurePxText(text)
      let textRem = convertPxToRem(textPx)
  
      let checkRem = 7
      if (textRem < checkRem)
      {
        return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...')
        textRem = convertPxToRem(textPx)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></span></Tooltip>
    }

    return <><br/><span>( - )</span></>
  }
  
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true);
  }

  const [openHope, setOpenHope] = useState(false);
  const handleCloseHope = () => {
    setOpenHope(false)
  }
  const handleOpenHope = () => {
    setOpenHope(true);
  }

  const [openPayment, setOpenPayment] = useState(false);
  const handleClosePayment = () => {
    setOpenPayment(false)
  }
  const handleOpenPayment = () => {
    setOpenPayment(true);
  }
  return (
    <tr>
      <ColumnStaffTD>{createElementStaff(staff)}</ColumnStaffTD>
      <ColumnOccupationTD>{staff.staffDivisionName}</ColumnOccupationTD>
      <ColumnWorkTypeTD>{formatRound48Time(secToTime(staff.setting.workingTime),1)}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{diff48Time('1900-01-01', staff.setting.workingStartTime)}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{diff48Time('1900-01-01', staff.setting.workingEndTime)}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{diff48Time('1900-01-01', staff.setting.nightStartTime)}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{diff48Time('1900-01-01', staff.setting.nightEndTime)}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{formatRound48Time(secToTime(staff.setting.deemedTime),1)}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{staff.setting.beforeWorkingFlag?'可':'不可'}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{staff.setting.afterWorkingFlag?'可':'不可'}</ColumnWorkTypeTD>
      <ColumnButtonsTD>
        <Button margin='auto' padding='0' width='4rem' name='編集' onClick={handleOpen}/>
        <Button margin='auto' padding='0' width='4rem' name='手当' onClick={handleOpenPayment}/>
        <Button margin='auto' padding='0' width='4rem' name='希望' onClick={handleOpenHope}/>
      </ColumnButtonsTD>
      <DailyShiftModal
        open={open}
        onCloseFunc={handleClose}
        dailyWorkInfo={staff}
        workDivisions={workDivisions}
        workInfoList={staff.setting}
      >
      </DailyShiftModal>
      <HopetDetailModel
        open={openHope}
        onCloseFunc={handleCloseHope}
        dailyWorkInfo={staff}
        workDivisions={workDivisions}
        workInfoList={staff.hope}
      >
      </HopetDetailModel>
      <PaymentDetailModel
        open={openPayment}
        onCloseFunc={handleClosePayment}
        dailyWorkInfo={staff}
        workDivisions={workDivisions}
        workInfoList={staff.payment}
      >
      </PaymentDetailModel>
    </tr>
  )
}

DailyTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  workDivisions: PropTypes.any,
  dateDataList: PropTypes.array,
  dailyWorkInfo: PropTypes.object,
  selectFunction: PropTypes.func,

  handleOpenPunchHistoryFunc: PropTypes.func,
  handleClosePunchHistoryFunc: PropTypes.func,
  handleOpenDailyAttendanceFunc: PropTypes.func,
  handleCloseDailyAttendanceFunc: PropTypes.func,
}

export default DailyTableRow
