import React,{useEffect} from 'react'
import PropTypes from 'prop-types'

import {DashboardBaseFormRow} from '../../../Wrap/DashboardBaseWrap'
import PalDashboardGraph from './PalDashboardGraph'
const GraphBody = ({ dateDataList, targetDt,staffWorkInfoList,limitList, selectedIndex, selectFunction, updatedShiftFunc, defaultUrl }) => {
  // 初期化メソッド
  useEffect(() => {
    console.log(dateDataList)
    
    // 初回描画時のみ実行
    // eslint-disable-next-line
  }, [])
//visible={staffWorkInfoList.length ? true : false}
  return (
    <DashboardBaseFormRow>
        {dateDataList.length != 0 && dateDataList.map((post)=>{
          return (
            <div>
              <PalDashboardGraph title={post.title+'(荷役)'} data={post.cargoWorkDataList} keys={post.keys}></PalDashboardGraph>
              <PalDashboardGraph title={post.title+'(労務)'} data={post.laborDataList} keys={post.keys}></PalDashboardGraph>
            </div>
          )
        })}
    </DashboardBaseFormRow>
  )
}

GraphBody.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  limitList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  onSalariedSearch: PropTypes.func,
  defaultUrl: PropTypes.string,
  targetDt: PropTypes.any,
}

export default GraphBody
