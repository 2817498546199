import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CellWrap = styled.th`
  width: 3rem;
  height: 2rem;
  vertical-align:middle;
  ${props => props.addStyle}
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
`

const Column = styled.th`
  text-align: center;
  height: 1rem;
`

const MonthTableHeaderDailySummaryCell = ({ shiftDateCount, attendanceDateCount }) => {

  return (
    <CellWrap>
      <CellPanel>
        <Column>{shiftDateCount}</Column>
        <Column>{attendanceDateCount}</Column>
      </CellPanel>
    </CellWrap>
  )
}

MonthTableHeaderDailySummaryCell.propTypes = {
    shiftDateCount: PropTypes.string,
    attendanceDateCount: PropTypes.string
}

export default MonthTableHeaderDailySummaryCell
