import React, { useEffect, useState, useCallback } from 'react'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import * as regex from '../../../Common/Regex'
import { InputGroup, SelectGroup, HiddenInput } from '../../../Common/CommonInputGroup'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
import { diff48Time, format48Time, formatRound48Time, formatHour, makeElementId, calcWorkingHours } from '../../../Common/Utilties/LaborUtility'

const ColumnWrap = styled.td`
  text-align:center;
  vertical-align:middle;
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const CellTop = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
`

const CellBottom = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
  border-top: 1px solid #DDD;
`

const ColumnOccupationTD = styled.td`
  font-size: 16px;
  width: 8rem;
  max-width: 8rem;
  vertical-align: middle;
  text-align: left;
  padding-left: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const DailyTableRow = ({
    register, getValues, setValue, watch, reset, errors, 
    defaultUrl, workInfo,workDivisions
  }) => {

  const alert = useAlert()
  //const [workDivisions, setWorkDivisions] = useState(<></>)
  const [elementId, setElementId] = useState(makeElementId(workInfo))
  const [workDivision, setWorkDivision] = useState(0)

  const [restStart1DtError,setRrestStart1DtError] = useState(false)

  // 勤務情報切り替え時
  useEffect(() => {
  // 勤務情報が変更された場合のみ実行
    if (workInfo) {
      setElementId(makeElementId(workInfo))
      setValue('workDivision_' + elementId, workInfo.shiftWorkDivision)
      setWorkDivision(workInfo.shiftWorkDivision)
      if(workInfo.restStart1DtError==undefined)
      {
        setRrestStart1DtError(false)
        console.log(setRrestStart1DtError)
      }
      else
      {
        setRrestStart1DtError(workInfo.restStart1DtError)
      }
    }
  // eslint-disable-next-line
  }, [workInfo])

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
      //await getWorkDivisions()
      console.log(workInfo)
      setValue('orderId_' + elementId, elementId)
      setValue('staffId_' + elementId, workInfo.staffId)
      setValue('baseDt_' + elementId, workInfo.dateValue)
      setValue('shiftUpdatedAt_' + elementId, workInfo.updatedAt)

      setValue('shiftWorkDivision_' + elementId, workInfo.shiftWorkDivision)
      setValue('shiftWorkStart_' + elementId, diff48Time(workInfo.dateValue, workInfo.shiftWorkStartDt))
      setValue('shiftWorkEnd_' + elementId, diff48Time(workInfo.dateValue, workInfo.shiftWorkEndDt))
      setValue('shiftWorkTotalDt_' + elementId, formatHour(workInfo.shiftWorkTotalDt))
      setValue('shiftRestTotalDt_' + elementId, formatHour(workInfo.shiftRestTotalDt))
      let overTotal = 0.0
      if (workInfo.shiftWorkTotalDt > workInfo.attendanceBaseTime) {
        overTotal = workInfo.shiftWorkTotalDt - workInfo.attendanceBaseTime
      }
      setValue('shiftOverTotalDt_' + elementId, formatHour(overTotal))
      setValue('shiftRestStart1_' + elementId, diff48Time(workInfo.dateValue, workInfo.shiftRestStart1Dt))
      setValue('shiftRestEnd1_' + elementId, diff48Time(workInfo.dateValue, workInfo.shiftRestEnd1Dt))
      setValue('shiftRestStart2_' + elementId, diff48Time(workInfo.dateValue, workInfo.shiftRestStart2Dt))
      setValue('shiftRestEnd2_' + elementId, diff48Time(workInfo.dateValue, workInfo.shiftRestEnd2Dt))
      setValue('shiftRestStart3_' + elementId, diff48Time(workInfo.dateValue, workInfo.shiftRestStart3Dt))
      setValue('shiftRestEnd3_' + elementId, diff48Time(workInfo.dateValue, workInfo.shiftRestEnd3Dt))
      setValue('shiftRemark_' + elementId, workInfo.shiftRemark)

    }
    getOptions()
    console.log(workInfo.orderId)
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  const createElementOccupation = (workInfo) => {
    let text = workInfo.occupation
    let textPx = measurePxText(text)
    let textRem = convertPxToRem(textPx)

    let checkRem = 7
    if (textRem < checkRem)
    {
      return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({workInfo.orderId})</span></>
    }

    let editedText = text
    while (textRem > checkRem) {
      editedText = editedText.slice(0, -1)
      textPx = measurePxText(editedText + '...')
      textRem = convertPxToRem(textPx)
    }

    editedText = editedText + '...'

    return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({workInfo.orderId})</span></span></Tooltip>
  }

  // 出勤区分取得
  // const getWorkDivisions = useCallback(async () => {
  //   dispatch(SetLoadSpinner(true))
  //   const result = await GetApi('/api/masterManagement/classificationValues/workDivisions')
  //   dispatch(SetLoadSpinner(false))
  //   if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
  //     const options = result.data.values.map(value =>
  //       <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
  //     )
  //     setWorkDivisions(options)
  //   }
  // }, [setWorkDivisions, alert])

  const onBlurTime = ( event ) => {
    let value = event.target.value
    //日時の分単位を指定分で丸める
    let fractionUnit = parseInt(workInfo.attendanceFractionUnit)
    //value = format48Time(value)
    let name = event.target.name 
    if(name.indexOf('WorkEnd') != -1 || name.indexOf('RestStart') != -1)
    {
      value = formatRound48Time(value,fractionUnit*(-1))
    }
    else
    {
      value = formatRound48Time(value,fractionUnit)
    }
    event.target.value = value

    let workStart = getValues("shiftWorkStart_" + elementId)
    let workEnd = getValues("shiftWorkEnd_" + elementId)
    let restStart1 = getValues("shiftRestStart1_" + elementId)
    let restEnd1 = getValues("shiftRestEnd1_" + elementId)
    let restStart2 = getValues("shiftRestStart2_" + elementId)
    let restEnd2 = getValues("shiftRestEnd2_" + elementId)
    let restStart3 = getValues("shiftRestStart3_" + elementId)
    let restEnd3 = getValues("shiftRestEnd3_" + elementId)

    //日時の分単位を指定分で丸める
    workStart = formatRound48Time(workStart,fractionUnit)
    workEnd = formatRound48Time(workEnd,fractionUnit*(-1))
    restStart1 = formatRound48Time(restStart1,fractionUnit*(-1))
    restEnd1 = formatRound48Time(restEnd1,fractionUnit)
    restStart2 = formatRound48Time(restStart2,fractionUnit*(-1))
    restEnd2 = formatRound48Time(restEnd2,fractionUnit)
    restStart3 = formatRound48Time(restStart3,fractionUnit*(-1))
    restEnd3 = formatRound48Time(restEnd3,fractionUnit)
    
    let result = calcWorkingHours([workStart, workEnd], [restStart1, restEnd1, restStart2, restEnd2, restStart3, restEnd3], workInfo.attendanceBaseTime)

    setValue('shiftWorkTotalDt_' + elementId, result[0])
    setValue('shiftRestTotalDt_' + elementId, result[1])
    setValue('shiftOverTotalDt_' + elementId, result[2])
  }
  
  const onChange = ( event ) =>
  {
    let value = event.target.value
    if(2 > value || value > 4)
    {
      setValue('shiftWorkStart_' + elementId, '')
      setValue('shiftWorkEnd_' + elementId, '')
      setValue('shiftWorkTotalDt_' + elementId, '0.00')
      setValue('shiftRestTotalDt_' + elementId, '0.00')
      setValue('shiftOverTotalDt_' + elementId, '0.00')
      setValue('shiftRestStart1_' + elementId, '')
      setValue('shiftRestEnd1_' + elementId, '')
      setValue('shiftRestStart2_' + elementId, '')
      setValue('shiftRestEnd2_' + elementId, '')
      setValue('shiftRestStart3_' + elementId, '')
      setValue('shiftRestEnd3_' + elementId, '')
    }
    setWorkDivision(value)
  }

  return (
    <tr>
      <ColumnOccupationTD>
        {createElementOccupation(workInfo)}
        <HiddenInput name={'staffId_'+ elementId} register={register} />
        <HiddenInput name={'orderId_'+ elementId} register={register} />
        <HiddenInput name={'baseDt_'+ elementId} register={register} />
        <HiddenInput name={'updatedAt_'+ elementId} register={register} />
      </ColumnOccupationTD>
      <ColumnWrap style={{ width: '2rem' }}>
        <CellPanel>
          <CellTop>
            <p>予定</p>
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '7rem' }}>
        <CellPanel>
          <CellTop>
            <SelectGroup
              name={'shiftWorkDivision_' + elementId}
              width='6rem'
              margin='auto'
              options={workDivisions}
              register={register}
              errors={errors}
              onChange={onChange}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftWorkTotalDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestTotalDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftOverTotalDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftWorkStart_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftWorkEnd_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestStart1_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
              rule ={(restStart1DtError)?{required: regex.onError}:{}}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestEnd1_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestStart2_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestEnd2_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestStart3_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestEnd3_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '10rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRemark_' + elementId}
              width='8rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
    </tr>
  )
}

DailyTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfo: PropTypes.object
}

export default DailyTableRow
