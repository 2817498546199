import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SelectPref = styled.div.attrs(props => ({
  margin: props.margin || '0 16px'
}))`
  margin: ${props => props.margin};
  position: relative;
  &::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 36px;
    background: #0036ff;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    pointer-events: none;
  }
`
const Triangle = styled.div`
  width: 0px;
  height: 0px;
  right: 9px;
  top: 14px;
  position: absolute;
  cursor: pointer;
  border-top: 8px solid white;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  pointer-events: none;
  z-index: 1;
`

const SelectWrap = styled.select.attrs(props => ({
  width: props.width || '150px',
  display: props.display || 'block'
}))`
  width: ${props => props.width};
  min-width: ${props => props.width};
  display: ${props => props.display};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  height: 36px;
  border-radius: 5px;
  border: none;
  line-height: 16px;
  text-indent: 16px;
  color: #333;
  appearance: none;
  cursor: pointer;
  background: ${props => props.disabled ? '#eee' : '#fff'};
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  &:focus {
    background: #fff;
    outline: none;
    border: 2px solid #0036ff;
    box-sizing: border-box;
    text-indent: 14px;
  }
`
const Select = ({ selectValue, disabled, onChange, name, margin, width, type, display, selectRef, children }) => (
  <SelectPref margin={margin}>
    <SelectWrap
      value={selectValue}
      disabled={disabled}
      onChange={onChange}
      width={width}
      type={type}
      name={name}
      display={display}
      ref={selectRef}
    >
      {children}
    </SelectWrap>
    <Triangle />
  </SelectPref>
)

Select.propTypes = {
  selectValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  margin: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string,
  display: PropTypes.string,
  children: PropTypes.any,
  selectRef: PropTypes.any,
  name: PropTypes.string
}

export default Select
