import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import FUNC from '../../Common/FunctionEnum'
//import { useForm } from 'react-hook-form'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
//import Select from '../../Common/Atoms/SelectAddValue'

import Search from './Search'
import DashboardBaseWrap,{DashboardBaseFormRow} from '../Wrap/DashboardBaseWrap'

import DashBoardSelect from '../DashboardSelect'
import GraphBody from './Molcules/PalDashboardGraph/PalDashboardGraphBody'

// import StaffBiometricPanelWrap,{StaffBiometricFlexWrap,CountPanelWrap,StaffBiometricPageButtonWrap,StaffBiometricPagingPanelWrap,StaffBiometricPagingChangePanelWrap,PagingIconImg,PagingIconNextImg,StaffBiometricPagingIconPanelWrap} from './Wrap/StaffBiometricPanelWrap'

export const CargoWorkDefaultUrl = '/api/dashboardManagement/cargoWork'
export const LaborDefaultUrl = '/api/dashboardManagement/labor'
const PalDashboard = (props) => {
  const [searchOption, setSearchOption] = useState({targetDt:'',postType:'',postId:'',departmentId:''})
  const { loginUserInfo } = useSelector(state => state)
  const targetDateRef = useRef()
  //const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [viewFunction,setViewFunction] = useState(false)
    
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()

  const dispatch = useDispatch()
  const alert = useAlert()

  const staffWorkplceId = '000002' 
  //元の検索データ
  const [cargoWorkDataList, setCargoWorkDataList] = useState([])
  const [laborAllDataList, setLaborAllDataList] = useState([])
  //ダッシュボードに表示するデータ
  const [dashboardDataList, setDashboardDataList] = useState([])
  //ダッシュボードに表示する項目データ
  const [cargoWorkRateData, setCargoWorkRateData] = useState([])
  const [laborRateData, setLaborRateData] = useState([])
  //項目のデータリスト
  const [cargoWorkRateList, setCargoWorkRateList] = useState([])
  const [laborRateList, setLaborRateList] = useState([])
  //現場のリスト
  const [workplaceList,setWorkplaceList] = useState([staffWorkplceId])
  const [workplaceAllList,setWorkplaceAllList] = useState([])
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.DASHBOARD)

  // 検索APIの実行
  const onSearch = async (createId, page,dataList) => {

    setSearchOption(
      {
        targetDt: targetDateRef.current,
        postType:postType,
        postId:(postId!=undefined && postId != -1) ? postId:'',
        departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      }
    )
   
    const data = {targetDt: targetDateRef.current,}
    dispatch(SetLoadSpinner(true))

    const resultDashBoardData = await GetApi(`${CargoWorkDefaultUrl}/post`,data)
    if (resultDashBoardData.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(resultDashBoardData, alert)
      return null
    }
    var tmpList = []
    var count = 1
    var errorFlag = false;
    var tmpCargoWorkList = []
    var tmpLaborList = []
    resultDashBoardData.data.map(async(post)=>{  
      // const sendData = {
      //     targetDt: data.targetDt,
      //     workplaceIds: workplaceList
      // }
      const sendData =  {targetDt: data.targetDt,postId: post.postId}
      const worlplaceCargoWorkData = await GetApi(`${CargoWorkDefaultUrl}/workplace`,sendData)
      const worlplaceLaborData = await GetApi(`${LaborDefaultUrl}/workplace`,sendData)
      if (worlplaceCargoWorkData.errorDetail || worlplaceLaborData.errorDetail) {
          ErrorHandling(resultDashBoardData, alert)
          errorFlag=true
          dispatch(SetLoadSpinner(false))
      }else{
          tmpCargoWorkList.push({postId:post.postId,data:worlplaceCargoWorkData.data})
          tmpLaborList.push({postId:post.postId,data:worlplaceLaborData.data})

          //データ作成
          var [dataList,keys] = await makeCargoWorkDashboardData(worlplaceCargoWorkData.data)
          var laborDataList = await makeLaborDashboardData(worlplaceLaborData.data)
          tmpList.push({title:post.postName,postId:post.postId,cargoWorkDataList:dataList,laborDataList:laborDataList,keys:keys})
          if(count == resultDashBoardData.data.length && !errorFlag){
            setDashboardDataList(tmpList.sort((el1, el2) => {
                if (el1['postId'] < el2['postId']) {
                  return 1;
                }
                if (el1['postId'] > el2['postId']) {
                  return -1;
                }
                  return 0;
            }))
            
            setCargoWorkDataList(tmpCargoWorkList)
            setLaborAllDataList(tmpLaborList)

            dispatch(SetLoadSpinner(false))
          }
          count++;
        }
    })    
    
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
    setViewFunction(false)
  }

  //ダッシュボード用にデータを加工(荷積み)
  const makeCargoWorkDashboardData =async(workplaceData)=>{
    var dataList = (cargoWorkRateList)
    // [{
    //     rateName:'ピッキング仕分けミス率',id:'pickingErrorRate'              
    // },{
    //     rateName:'荷物傷み発生率',id:'luggageDamageRate'
    // },{
    //     rateName:'ピッキング効率',id:'pickingRate'
    // }
    // ,{
    //     rateName:'稼働率',id:'utilizationRate'
    // },{
    //     rateName:'作業効率',id:'workRate'
    // }];
    if(dataList.length==0){
      console.log(dataList)
      dataList=await setCargoWorkSchema()
    }
    console.log(cargoWorkRateList,workplaceData)
    var keys = []
    workplaceData.map((data)=>{
        var workplaceName = data.workplaceName;
        dataList.forEach(ratedata => {
          if(workplaceList.includes(data.workplaceId)) ratedata[workplaceName] = data[ratedata.id]
        });
        if(workplaceList.includes(data.workplaceId)) keys.push(workplaceName)
    })
    return [dataList,keys]
  }
  //ダッシュボード用にデータを加工(労務)
  const makeLaborDashboardData =async(workplaceData)=>{
        var dataList = (laborRateList)
        // [  {
        //     rateName:'労働分配率',id:'laborShare'              
        // },{
        //     rateName:'労働生産性',id:'laborProductivity'
        // },{
        //     rateName:'離職率',id:'retireRate'
        // },
        // // {
        // //     rateName:'平均勤続年数',id:'avgLengthOfService'
        // // },
        // {
        //     rateName:'有給休暇消化率',id:'usageSalariedRate'
        // },
        // {
        //     rateName:'残業発生率',id:'overTimeRate'
        // },
        // // {
        // //     rateName:'平均残業時間',id:'avgOverTime'
        // // },
        // {
        //     rateName:'休憩法令順守率',id:'lessBreakTimeRate'
        // },
        // {
        //     rateName:'健康診断受診率',id:'healthCheckupRate'
        // }];
        if(dataList.length==0){
          dataList=await setLaborSchema()
        }
        console.log(dataList,workplaceData)
        workplaceData.map((data)=>{
            var workplaceName = data.workplaceName;
            dataList.forEach(ratedata => {
              if(workplaceList.includes(data.workplaceId)) ratedata[workplaceName] = data[ratedata.id]
              //console.log(data.workplaceId)
            });
        })
    return dataList
  }

  // 検索項目でキーボードの押下イベント
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }

  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
  }
  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud'
    else
      document.title = 'Core Time Cloud'
  }, [grants])

  useEffect(() => {
    setPost()
    setWorkplace()
    onSearch(null,1)
  }, [])
  const setWorkplace=async()=>{
    const result = await GetApi(`${CargoWorkDefaultUrl}/workplaceId`)
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    }
    else{
        console.log(result)
        //setWorkplaceList(result.data)
        result.data.map((workplace)=>{
          //デフォルト値決定
          workplace.checked = workplace.workplaceId==staffWorkplceId
        })
        setWorkplaceAllList(result.data)
        
    }    
  }
  //項目リスト変更処理
  useEffect(() => {
    if(cargoWorkDataList.length != 0){
      var tmpList = []
      cargoWorkDataList.map(async(dataList)=>{  
        var [tmp,keys] = await makeCargoWorkDashboardData(dataList.data)
        dashboardDataList.map((dashboardData)=>{
          if(dashboardData.postId==dataList.postId){
            //console.log(tmp)
            tmpList.push({title:dashboardData.title,postId:dashboardData.postId,cargoWorkDataList:tmp,laborDataList:dashboardData.laborDataList,keys:dashboardData.keys})
          }
          if(dashboardDataList.length == tmpList.length){
            setDashboardDataList(tmpList.sort((el1, el2) => {
              if (el1['postId'] < el2['postId']) {
                return 1;
              }
              if (el1['postId'] > el2['postId']) {
                return -1;
              }
                return 0;
            }))
          
          }
        })
      })
    }
  }, [cargoWorkRateList])
  useEffect(() => {
    if(laborAllDataList.length != 0){
      var tmpList = []
      laborAllDataList.map(async(dataList)=>{  
        var tmp = await makeLaborDashboardData(dataList.data)
        dashboardDataList.map((dashboardData)=>{
          if(dashboardData.postId==dataList.postId){
            // console.log(dashboardData)
            tmpList.push({title:dashboardData.title,postId:dashboardData.postId,cargoWorkDataList:dashboardData.cargoWorkDataList,laborDataList:tmp,keys:dashboardData.keys})
          }
          if(dashboardDataList.length == tmpList.length){
            setDashboardDataList(tmpList.sort((el1, el2) => {
              if (el1['postId'] < el2['postId']) {
                return 1;
              }
              if (el1['postId'] > el2['postId']) {
                return -1;
              }
                return 0;
            }))
          
          }
        })
      })
    }

  }, [laborRateList])
  useEffect(() => {
    if(laborAllDataList.length != 0){
      console.log(workplaceList)
      var tmpList = []
      laborAllDataList.map(async(dataList)=>{  
        var tmpLabor = await makeLaborDashboardData(dataList.data)
        var [tmpCargo,keys] = await makeCargoWorkDashboardData(dataList.data)
        dashboardDataList.map((dashboardData)=>{
          if(dashboardData.postId==dataList.postId){
            //console.log(dashboardData)
            tmpList.push({title:dashboardData.title,postId:dashboardData.postId,cargoWorkDataList:tmpCargo,laborDataList:tmpLabor,keys:keys})
          }
          if(dashboardDataList.length == tmpList.length){
            setDashboardDataList(tmpList.sort((el1, el2) => {
              if (el1['postId'] < el2['postId']) {
                return 1;
              }
              if (el1['postId'] > el2['postId']) {
                return -1;
              }
                return 0;
            }))
          
          }
        })
      })
    }

  }, [workplaceList])

  const setCargoWorkSchema=async()=>{
    const resultDashBoardData = await GetApi(`${CargoWorkDefaultUrl}/schema`)
    if (resultDashBoardData.errorDetail) {
      ErrorHandling(resultDashBoardData, alert)
      return null
    }
    else{
        console.log(resultDashBoardData)
        setCargoWorkRateData(resultDashBoardData.data)
        var tmpList = []
        resultDashBoardData.data.map((column)=>{
            tmpList.push({rateName:column.comment,id:makeRateId(column.columnName)})
            console.log(column)
        })
        setCargoWorkRateList(tmpList)
        return tmpList
    }
  }
  const setLaborSchema=async()=>{

    const result = await GetApi(`${LaborDefaultUrl}/schema`)
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    }
    else{
        console.log(result)
        setLaborRateData(result.data)
        var tmpList = []
        result.data.map((column)=>{
            tmpList.push({rateName:column.comment,id:makeRateId(column.columnName) })
            console.log(column)
        })
        setLaborRateList(tmpList)
        return tmpList
    }
  }  
  const makeRateId=(columnName)=>{
    var columns = columnName.split('_')
    var columnName = ''
    columns.map((name,index)=>{
      name = name.replace('overtime','overTime')
      if(index==0) {
        columnName+=name
        return
      } 
      columnName+=name[0].toUpperCase()+name.slice(1)
    })
    return columnName
  }

  const setPost=()=>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      }
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.companyId,
          name: item.companyName+'('+item.companyId+')',
        }
      })
      setPostList(resultList)
      return true
    }
    const serachCustomer = async()=>{
      const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } 
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.customerId,
          name: item.customerName+'('+item.customerId+')',
        }
      })
      setPostList(resultList)
      return true
    }

    if(postType==0){
      serachCompany()
    }else{
      serachCustomer()
    }
  }

  //会社顧客変更処理
  useEffect(() => {
    setPost()
    // 必要なデータが更新された場合のみ実行
    // eslint-disable-next-line
  }, [postType])

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }
  if(!postList){
    setPost()
  }

  if (!targetDateRef.current) {
    var today = new Date();
    targetDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-01'
  }
  if (!grants) return null
  return (
    <DashboardBaseWrap>
      <DashboardBaseFormRow>
        <Search
            showButton
            onClick={() => onSearch(null, pageType==0?1:0)}
            onClickOption={(e) => onClickOptionBtn(e)}
            onKeyDown={(e) => onKeyDown(e)}
            shiftDateRef={targetDateRef}
            viewSubSearch={viewSubSearch}
            grants={grants}
            postList={postList}
            postId={postId}
            setPostId={setPostId}
            departmentId={departmentId}
            setDepartmentId={setDepartmentId}
            postType={postType}
            setPostType={setPostType}
            viewFunction={viewFunction}
            onClickFunction={()=>onClickFunction()}
            cargoWorkRateData={cargoWorkRateData}
            laborRateData={laborRateData}
            setCargoWorkRateList={setCargoWorkRateList}
            setLaborRateList={setLaborRateList}
            cargoWorkRateList={cargoWorkRateList}
            laborRateList={laborRateList}
            setWorkplaceList={setWorkplaceList}
            workplaceAllList={workplaceAllList}
            workplaceList={workplaceList}
            >
        </Search>
      </DashboardBaseFormRow>
      <DashboardBaseFormRow>
        <DashBoardSelect/>
        {dashboardDataList.length != 0 &&
        <GraphBody dateDataList={dashboardDataList}/>
        }
        {/* {dashboardDataList.length != 0 &&
          <SalariedGraphBody dateDataList={dashboardDataList}></SalariedGraphBody>
          //<OverTimeTable></OverTimeTable>
        } */}
      </DashboardBaseFormRow>
    </DashboardBaseWrap>
  )
}

export default PalDashboard
