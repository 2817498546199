import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import Login, { Auth } from './Login/Login'
import { setLoginUserInfo } from './Common/Slice/LoginSlice'
import LoadSpinner from './Common/LoadSpinner'
import { getLoginUserInfo } from './Common/StaffInfo'

import Header from './Header'
import Main from './Main'
import Footer from './Footer';
import NotFound from './NotFound'

import Top from './Top/Top'
import Shift from './ShiftManagement/Shift'
import Attendance from './AttendanceManagement/Attendance'
import Salaried from './SalariedManagement/Salaried'
import StaffBiometric from './StaffBiometricManagement/StaffBiometric'
import Dashboard from './DashboardManagement/Dashboard'
import BulkEdit from './BulkEditManagement/BulkEdit'
import Master from './MasterManagement/Master'
import diffAttendance from './DiffAttendanceManagement/DiffAttendance'
import shiftGanttChart from './ShiftGanttChartManagement/ShiftGanttChart'

import CoreTimeAliveMonitoring from './MasterManagement/CoreTimeAliveMonitoring/CoreTimeAliveMonitoring'
//import Staff from './MasterManagement/Staff/Staff'
//import Company from './MasterManagement/Company/Company'
//import Customer from './MasterManagement/Customer/Customer'
//import Order from './MasterManagement/Order/Order'
//import Allocation from './MasterManagement/Allocation/Allocation'
//import Grant from './MasterManagement/Grant/Grant'
//import Calendar from './MasterManagement/Calendar/Calendar'

import employmentContract from './EmploymentContract/EmploymentContract'
import overTimeWork from './DashboardManagement/OverTimeWork/OverTimeWork'

import '../css/App.scss';

const Container = styled.div`
  .App {
    flex-direction: column;
    background-repeat: no-repeat;
    background-color: #FFF;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
  }
`

const App = () => {
  const { loginUserInfo } = useSelector(state => state)
  const userInfo = { loginUserInfo: loginUserInfo, setLoginUserInfo: setLoginUserInfo }
  const dispatch = useDispatch()

  // ログインスタッフ情報・会社情報取得
  useEffect(() => {

    async function getLoginUserInfo_ () {
      getLoginUserInfo(dispatch)
    }
    if (loginUserInfo.staffId === undefined) {
      getLoginUserInfo_()
    }
  }, [loginUserInfo, dispatch])

  return (
    <Container>
      <Router>
        <LoadSpinner />
        <Switch>
        <Route path='/login' render={() => (<Login userInfo={userInfo} />)} exact />
        <Auth>
        <div className='App' id='App'>
            <div className='main'>
              <Header />
              <Switch>
                <Route path='/' component={Main} exact />
                <Route path='/top' component={Top} />
                <Route path='/attendance' component={Attendance} />
                <Route path='/shift' component={Shift} />
                <Route path='/salaried' component={Salaried} />
                <Route path='/staffBiometric' component={StaffBiometric} />
                <Route path='/dashboard' component={Dashboard} />
                
                <Route path='/shiftBulk' component={BulkEdit} />
                {/* 勤怠差異 */}
                <Route path='/diffAttendance' component={diffAttendance} />
                {/* シフトガントチャート*/}
                <Route path='/shiftGanttChart' component={shiftGanttChart} />
                {/* マスター */}
                <Route path='/Master' component={Master} />

                {/* <Route path='/master/staff' component={Staff} />
                <Route path='/master/company' component={Company} />
                <Route path='/master/customer' component={Customer} />
                <Route path='/master/order' component={Order} />
                <Route path='/master/allocation' component={Allocation} />
                <Route path='/master/grant' component={Grant} />
                <Route path='/master/calendar' component={Calendar} /> */}
                
                {/* 雇用契約管理 */}
                <Route path='/employmentContract' component={employmentContract} />
                <Route path='/overTimeWork' component={overTimeWork} />

                <Route path='/coretimeAliveMonitoring' component={CoreTimeAliveMonitoring} />
                {/* 404 not found */}
                <Route component={NotFound} />
              </Switch>
              <Footer />
            </div>
        </div>
        </Auth>
        </Switch>
      </Router>
    </Container>
  );
}

export default App;
