import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { useHistory } from "react-router-dom"
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
//import { useSyncExternalStore } from 'react'
import PropTypes from 'prop-types'
//import { Link } from 'react-router-dom'
//import Tooltip from '@material-ui/core/Tooltip'

import { GetApi, exportErrorLog, forceLogout } from '../Common/ApiAxios'
import NormalButton from '../Common/Atoms/NormalButton'

import ShiftIcon from '../../image/menu-shift.png'
import ShiftBulkIcon from '../../image/menu-shift-bulk.png'
import AttendanceIcon from '../../image/menu-attendance.png'
import SalariedIcon from '../../image/menu-salaried.png'
import SalesIcon from '../../image/menu-sales.png'
import DashboardIcon from '../../image/menu-dashboard.png'
import BiometricIcon from '../../image/menu-biometric.png'
import MasterIcon from '../../image/menu-master.png'

//import FUNC from '../Common/FunctionEnum'

import InfoCard from './Atoms/InfoCard'

import TopBaseWrap from './Wrap/TopPanelWrap'

const ServiceContent = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: 100px 100px;
  grid-gap: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`

const DataContentWrap = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  margin: 10px;
`
const DataContent = styled.div`
  width: 1005px;
  height: 560px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #fafafa;
  border-radius: 10px;
  border: none;

  color: #000;
`

const InfoContent = styled.div`
  width: 860px;
  height: 560px;
  margin: 10px;
`

const NavigatorItemContent  = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  width: 300px;
  font-size: 14px;

  background-color: #fafafa;
  border-radius: 10px;
  border: none;

  color: #000;
`

const Icon = styled.img`
  width: 48px;
  height: 48px;
  margin-left: 1.5rem;
  margin-right: 1.0rem;
  margin-top: 0.5rem;
`

const Title = styled.span`
  font-size: 24px;
  font-weight: bold;

  margin-top: 1rem;
`

const Content = styled.div`
  display: flex; /* 横並び */
  overflow: hidden;
  text-align: left;
  margin-left: 1rem;
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
`

const NavigatorItem = (props) => {
  const {
    functionItem,
    onClick,
  } = props
  const history = useHistory();

  if (!functionItem) return null;
  return (
    <NavigatorItemContent>
      {/* 売上以外 */}
      {functionItem.data.functionMenuId !== '2030101001' && 
      <Content onClick={() => history.push(functionItem.linkTo)} style={{cursor:'pointer'}}>
        <Icon src={functionItem.img} />
        <Title>{functionItem.data.functionMenuName}</Title>
      </Content>
      }
      {/* 売上 */}
      {functionItem.data.functionMenuId === '2030101001' && 
      <Content>
        <Icon src={functionItem.img} />
        <Title>{functionItem.data.functionMenuName}</Title>
      </Content>
      }
    </NavigatorItemContent>
  )
}
  
NavigatorItem.propTypes = {
  functionItem: PropTypes.object,
  onClick: PropTypes.func
}

const Top = (props) => {
    const [serviceList, setServiceList] = useState([]);
    const loginUserInfo = useSelector(state => state.loginUserInfo);
    const [functionList, setFunctionList] = useState([]);
    const [masterFunctionList, setMasterFunctionList] = useState([]);
  //const dispatch = useDispatch()
  //const alert = useAlert()
  //const { loginUserInfo } = useSelector(state => state);
  //const [viewFunction,setViewFunction] = useState(false);
  //const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK);
  //const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK_HEALTHCARE);

  // メニューリスト
  async function getMenuList (cleanedUp) {
    const apiUrl = '/api/menuList'
    const grantNameId = loginUserInfo.grantNameId
    const data = { grantNameId }
    const result = await GetApi(apiUrl, data)
    console.log(result)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
          forceLogout()
      } else {
          alert('情報取得に失敗しました。')
      }
      window.location.href = '/login'
    } else {
      setServiceList(result.data)
      
      console.log(result.data)
      var list1 = [];
      result.data.map(service => {
        service.manageMenuList.map(manage => {
          manage.functionMenuList.map(item => {
            //console.log(item.functionMenuId);
            switch (item.functionMenuId) {
              case '2020201001':
                list1.push({ data:item, linkTo: '/attendance', img: AttendanceIcon, sort:0 });
                break;
              case '2020101001':
                list1.push({ data:item, linkTo: '/shift', img: ShiftIcon, sort:1 });
                break;
              case '2020102001':
                list1.push({ data:item, linkTo: '/shiftBulk', img: ShiftBulkIcon, sort:2 });
                break;
              case '2020301001':
                list1.push({ data:item, linkTo: '/salaried', img: SalariedIcon, sort:3 });
                break;
              case '2030101001':
                list1.push({ data:item, linkTo: '/sales', img: SalesIcon, sort:4 });
                break;
              case '2050101001':
                list1.push({ data:item, linkTo: '/dashboard', img: DashboardIcon, sort:5 });
                break;
              case '2040101002':
                list1.push({ data:item, linkTo: '/staffBiometric', img: BiometricIcon, sort:6 });
                break;
              //マスタ管理
              case '2042201001':
                list1.push({ data:item, linkTo: '/Master', img: MasterIcon, sort:7 });
                break;
            }
          })
        })
      })
      console.log(list1);
      if (list1.length > 0)
        list1.sort(function(a, b){ return a.sort - b.sort; });
      setFunctionList(list1);

      var list2 = [];
      result.data.map(service => {
        service.manageMenuList.map(manage => {
          manage.functionMenuList.map(item => {
            switch (item.functionMenuId) {
              case '2040101001':
                list2.push({ data:item, linkTo: '/master/staff', img: MasterIcon, sort:0 });
                break;
              case '2040201001':
                list2.push({ data:item, linkTo: '/master/company', img: MasterIcon, sort:1 });
                break;
              case '2040301001':
                list2.push({ data:item, linkTo: '/master/customer', img: MasterIcon, sort:2 });
                break;
              case '2040401001':
                list2.push({ data:item, linkTo: '/master/order', img: MasterIcon, sort:3 });
                break;
              case '2040501001':
                list2.push({ data:item, linkTo: '/master/allocation', img: MasterIcon, sort:4 });
                break;
              case '2040601001':
                list2.push({ data:item, linkTo: '/master/grant', img: MasterIcon, sort:5 });
                break;
              case '2040901001':
                list2.push({ data:item, linkTo: '/master/calendar', img: MasterIcon, sort:6 });
                break;
            }
          })
        })
      })
      if (list2.length > 0)
        list2.sort(function(a, b){ return a.sort - b.sort; });
      setMasterFunctionList(list2);
    }
  }

  useEffect(() => {
    if (loginUserInfo.grantNameId) {
        getMenuList()
    }
  // ログインユーザー情報の変更検知時のみ実行する
  // eslint-disable-next-line
  }, [loginUserInfo])

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  return (
    <TopBaseWrap>
      <ServiceContent>
        {functionList && functionList.map(item => (
          <NavigatorItem key={item.data.functionMenuName} functionItem={item} />
        ))}
      </ServiceContent>
      <DataContentWrap>
        <DataContent></DataContent>
        <InfoContent><InfoCard /></InfoContent>
      </DataContentWrap>
    </TopBaseWrap>
  )
}

export default Top
