import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/Atoms/NormalButton'
import ListView from '../../Common/Atoms/ListView'
import Pagenation from '../../Common/Atoms/Pagenation'

const ListWrap = styled.div`
  width: 18vw;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-bottom: 1vh;
`
const ListHeader = styled.div`
  width: 16vw;
  height: 48px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
`
const CountLabel = styled.label`
  height: 48px;
  line-height: 48px;
  margin-bottom: 12px;
`

const MasterListView = (props) => {
  const {
    dataList, setSelectedId, selectFunction, createFunction, canWrite, isIdView, listHeight, addStyle,
    pageSelectFunc, pagenation
  } = props
  return (
    <ListWrap>
      <div style={{ height: '600px'}}>
      <ListHeader>
        <Button name='新規' margin='0' padding='0' display={canWrite ? 'block' : 'none'} onClick={createFunction} />
        <CountLabel>{pagenation && pagenation.allRecordCount ? '全 ' + pagenation.allRecordCount + '件' : '0件'}</CountLabel>
      </ListHeader>
      <ListView
        dataList={dataList} setSelectedId={setSelectedId} selectFunction={selectFunction}
        isIdView={isIdView} isBadgeView={true} height={listHeight} addStyle={addStyle} pagenation={pagenation}
      />
      </div>
      {dataList && dataList.length > 0 && pagenation &&
        <Pagenation pagenation={pagenation} pageSelectFunc={pageSelectFunc} addStyle='margin-top: 24px;' />}
    </ListWrap>
  )
}

MasterListView.propTypes = {
  dataList: PropTypes.array,
  selectedId: PropTypes.any,
  selectFunction: PropTypes.func,
  createFunction: PropTypes.func,
  canWrite: PropTypes.bool,
  isIdView: PropTypes.bool,
  listHeight: PropTypes.string,
  addStyle: PropTypes.string,
  pageSelectFunc: PropTypes.func,
  pagenation: PropTypes.object
}

export default MasterListView
