import styled from 'styled-components'

const MasterFlexWrap = styled.div`
display:flex;
width: 100vw;
background-repeat: no-repeat;
background-size: 20%;
background-position: 5% top;
`

export default MasterFlexWrap
