import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
///import moment from 'moment'
//import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'

import { PostApi, PutApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import Calendar from '../../../Common/Atoms/Calendar'
import Input from '../../../Common/Atoms/Input'
import NormalButton from '../../../Common/Atoms/NormalButton'
import Select from '../../../Common/Atoms/SelectAddValue'
import * as CommonUtility from '../../../Common/Utilties/CommonUtility'

import CloseIcon from '../../../../image/close_icon.png'


//import MediaAccidentHeader from './MediaAccidentHeader'
//import MediaAccident from './MediaAccident'
//import * as MapWrap from '../../Wrap/PositioningMapWrap'
//import { DefaultUrl } from '../../PositioningBoard'
//import { conforms } from 'lodash'

/* 横並 */
//const Flex = styled.div`
//  display: flex; /* 横並び */
//  flex-flow: row nowrap;
//`

/* 縦並 */
const Wrap = styled.div`
  display:flex;
  flex-flow: column;
`

const ColumnFlex = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  align-items: center;
  marign: 2rem;
  height: 40px;
`

const ColumnName = styled.div`
  width: 8rem;
  margin-left: 1rem;
  text-align: left;
  align-items: center;
`

const NoticeModal = (props) => {
  const {
    //grants,
    noticeData,
    type,
    modalIsOpen,
    setIsOpen,
    onChangeNotice,
  } = props
  const dispatch = useDispatch();
  //const alert = useAlert();
  const [targetYMD, _setTargetYMD] = useState(noticeData.noticeAt);
  const setTargetYMD = (date) => {
    _setTargetYMD(date);
  };
  const titleInputRef = useRef();
  const contentInputRef = useRef();
  const typeRef = useRef();

  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 10
    }
  }

  //ダイアログ開いた時の処理
  function afterOpenModal () {
    titleInputRef.current.value = noticeData.title;
    contentInputRef.current.value = noticeData.text;
    typeRef.current.value = noticeData.noticeType;
  }

  function closeModal () {
      setIsOpen(false);
  }

  // 登録ボタンクリックイベント
  const onClickCreate = () => {
    //console.log('call onClickCreate');
    
    apiCreateNotice(true);
  }

  // 更新ボタンクリックイベント
  const onClickUpdate = () => {
    //console.log('call onClickUpdate');

    apiUpdateNotice(true);
  }

  // お知らせの登録
  const apiCreateNotice = async (isLoadSplinner) => {
    //console.log('cal apiDeleteNotice');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = '/api/noticeManagement' + `/notice/`;
    const data = {
      //warehouseId: Number(noticeData.warehouseId),
      noticeType: typeRef.current.value,
      noticeAt: CommonUtility.getStringFromDate(new Date(targetYMD), 'YYYY-MM-DD'),
      title: titleInputRef.current.value,
      noticeContent: contentInputRef.current.value,
    }

    const result = await PostApi(apiUrl, data);

    if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('お知らせの削除に失敗しました');
    } else {
      onChangeNotice();
      setIsOpen(false);
    }
  }

  // お知らせの更新
  const apiUpdateNotice = async (isLoadSplinner) => {
    //console.log('cal apiDeleteNotice');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = '/api/noticeManagement' + `/notice/${noticeData.noticeId}`;
    const data = {
      noticeId: noticeData.noticeId,
      noticeType: typeRef.current.value,
      noticeAt: CommonUtility.getStringFromDate(new Date(targetYMD), 'YYYY-MM-DD'),
      title: titleInputRef.current.value,
      noticeContent: contentInputRef.current.value,
    }

    const result = await PutApi(apiUrl, data);

    if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('お知らせの削除に失敗しました');
    } else {
      onChangeNotice();
      setIsOpen(false);
    }
  }

  //if (!grants) return null;
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Create Zone Modal'
      ariaHideApp={false}
    >
      <div style={{ width: '45vw', height: '350px'}}>
        <Wrap>
          <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
            <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" onClick={closeModal} />
          </div>
          <Wrap>
            <ColumnFlex>
              <ColumnName>日付</ColumnName>
              <Calendar
                name='targetYMD'
                placeholder='お知らせ日'
                changeCalendar={setTargetYMD}
                dataValue={targetYMD}
                prefix='/'
              />
            </ColumnFlex>
            <ColumnFlex>
              <ColumnName>種類</ColumnName>
              <Select selectRef={typeRef}>
                <option key={'type_0'} value={0}>お知らせ</option>
                <option key={'type_1'} value={1}>重要</option>
                <option key={'type_2'} value={2} >警告</option>
              </Select>
            </ColumnFlex>
            <ColumnFlex>
              <ColumnName>タイトル</ColumnName>
              <div style={{marginLeft:'10px'}}>
                <Input
                  type='text' 
                  width='15rem'
                  placeholder='タイトル'
                  name='content'
                  maxLength='10'
                  inputRef={titleInputRef} 
                  //errors={errors}
                  //disabled={isDeleted || !canWrite}
                />
              </div>
            </ColumnFlex>
            <ColumnFlex>
              <ColumnName>内容</ColumnName>
              <div style={{marginLeft:'10px'}}>
                <Input
                  type='text' 
                  width='40rem'
                  placeholder='内容'
                  name='content'
                  maxLength='50'
                  inputRef={contentInputRef} 
                  //errors={errors}
                  //disabled={isDeleted || !canWrite}
                />
              </div>
            </ColumnFlex>
          </Wrap>
          <div style={{marginLeft:'auto', marginRight:'10px', marginTop:'100px', cursor: 'pointer'}}>
            {type ===  0 && <NormalButton name='登録' onClick={onClickCreate} />}
            {type ===  1 && <NormalButton name='更新' onClick={onClickUpdate} />}
          </div>
        </Wrap>
      </div>
    </Modal>
  )
}

NoticeModal.propTypes = {
  grants: PropTypes.object, // 権限関連
  noticeData: PropTypes.object,
  type: PropTypes.number, // 0:新規 1:更新
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onChangeNotice: PropTypes.func,
}

export default NoticeModal
