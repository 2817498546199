import { Check } from '@material-ui/icons';
import PropTypes from 'prop-types'
import {timeToSec} from './LaborUtility'
//エラーチェック
export function onCheckStaff(importDataList,schemaList,grantList,companyList,comDepartmentList,customerList,cusDepartmentList) {
    var errorList = []
    var successList = []
    importDataList.map((importData)=>{
        for(var i=0;i<schemaList.length;i++){
            let fieldName = schemaList[i].fieldName.replace(/_[a-z]/g, function(match){return '_'+match.toUpperCase();});

            if(importData[fieldName] != undefined && importData[fieldName] != null && importData[fieldName] != ''){
                if(!MasterErrorCheck(importData[fieldName],  schemaList[i])){
                    importData.error = schemaList[i].comment+'の値に誤りがあります。'
                    errorList.push(importData)
                    return;
                }
            }
            //NOTNULL制約が保たれないときはエラーリストに
            else if (schemaList[i].notNull)
            {
                if(fieldName == 'createdId' || fieldName == 'createdAt' 
                || fieldName == 'updatedId' || fieldName == 'updatedAt') continue;
                importData.error = schemaList[i].comment+'が入力されていません'
                errorList.push(importData)
                return;
            }
        }

        if(!grantList.includes(importData.grant.grantNameId)){
            importData.error = '権限名称IDの値は存在しません'
            errorList.push(importData)
            return;
        }
        if (importData["staffDivision"] == 4)
        {
            if (!customerList.includes(importData["companyId"]))
            {
                importData.error = '会社IDの値は存在しません'
                errorList.push(importData)
                return;
            }
            if (!cusDepartmentList.includes(importData["departmentId"]) && !customerList.includes(importData["companyId"]))
            {
                importData.error = '部門IDの値は存在しません'
                errorList.push(importData)
                return;
            }
        }
        else
        {
            if (!companyList.includes(importData["companyId"]))
            {
                importData.error = '会社IDの値は存在しません'
                errorList.push(importData)
                return;
            }
            if (!comDepartmentList.includes(importData["departmentId"]) && !companyList.includes(importData["companyId"]))
            {
                importData.error = '部門IDの値は存在しません'
                errorList.push(importData)
                return;
            }
        }
        importData.delFlag = false
        successList.push(importData)
    })
    
    return [successList,errorList] 
} 
//エラーチェック
export function onCheckComAndCus(importDataList,schemaList,type) {
    var errorList = []
    var successList = []
    importDataList.map((importData)=>{
        for(var i=0;i<schemaList.length;i++){
            let fieldName = schemaList[i].fieldName.replace(/_[a-z]/g, function(match){return '_'+match.toUpperCase();});

            if(importData[fieldName] != undefined && importData[fieldName] != null && importData[fieldName] != ''){
                if(!MasterErrorCheck(importData[fieldName],  schemaList[i])){
                    importData.error = schemaList[i].comment+'の値に誤りがあります。'
                    errorList.push(importData)
                    return;
                }
            }
            //NOTNULL制約が保たれないときはエラーリストに
            else if (schemaList[i].notNull)
            {
                if(fieldName == 'createdId' || fieldName == 'createdAt' 
                || fieldName == 'updatedId' || fieldName == 'updatedAt') continue;
                importData.error = schemaList[i].comment+'が入力されていません'
                errorList.push(importData)
                return;
            }
        }

        importData.delFlag = false
        
        if(!type && importData.departmentFlag==undefined){
            importData.error = '部門有無が入力されていません'
            errorList.push(importData)
            return
        }

        if(importData.departmentFlag == 'TRUE'){
            importData.departmentFlag = true
        }else{
            importData.departmentFlag = false
        }

        successList.push(importData)
    })
    
    return [successList,errorList] 
} 
//エラーチェック
export function onCheckOrder(importDataList,schemaList,companyList,comDepartmentList,customerList,cusDepartmentList) {
    var errorList = []
    var successList = []
    importDataList.map((importData)=>{
        for(var i=0;i<schemaList.length;i++){
            let fieldName = schemaList[i].fieldName.replace(/_[a-z]/g, function(match){return '_'+match.toUpperCase();});

            if(importData[fieldName] != undefined && importData[fieldName] != null && importData[fieldName] != ''){
                // if(!MasterErrorCheck(importData[fieldName],  schemaList[i])){
                //     importData.error = schemaList[i].comment+'の値に誤りがあります。'
                //     errorList.push(importData)
                //     return;
                // }
            }
            //NOTNULL制約が保たれないときはエラーリストに
            else if (schemaList[i].notNull)
            {
                if(fieldName == 'createdId' || fieldName == 'createdAt' 
                || fieldName == 'updatedId' || fieldName == 'updatedAt'
                || fieldName == 'nightStartDt' || fieldName == 'nightEndDt') continue;
                importData.error = schemaList[i].comment+'が入力されていません'
                errorList.push(importData)
                return;
            }
        }
        if ('POUNCHONLY' == importData.orderId)
        {
            importData.error= "「受注ID」の値は使用できないIDです。";
            errorList.push(importData);
            return;
        }
        //契約区分の確認
        var order_division = importData["contractDivision"];
        if (order_division != 1 && order_division != 2)
        {
            importData.error  = "「契約区分」に誤りがあります。";
            errorList.push(importData);
            return;
        }

        var order_startDate = importData["agreementStartDt"];
        var order_endDate = importData["agreementEndDt"];
        if(order_startDate > order_endDate)
        {
            importData.error  = "「契約開始日付」、「契約終了日付」のいずれかに誤りがあります。";
            errorList.push(importData);
            return;
        }
        
        if (!cusDepartmentList.includes(importData["cusDepartmentId"]) && !customerList.includes(importData["cusDepartmentId"]))
        {
            importData.error = '顧客部門IDの値は存在しません'
            errorList.push(importData)
            return;
        }
        if (!comDepartmentList.includes(importData["comDepartmentId"]) && !companyList.includes(importData["comDepartmentId"]))
        {
            importData.error = '会社部門IDの値は存在しません'
            errorList.push(importData)
            return;
        }
        //支給形態チェック
        if (importData.wagesType > 3 || importData.wagesType < 1)
        {
            importData.error = "「給与形態」に誤りがあります。";
            errorList.push(importData)
            return;
        }

        //請求形態チェック
        if (importData.billingType > 3 || importData.billingType < 1)
        {
            importData.error = "「請求形態」に誤りがあります。";
            errorList.push(importData)
            return;
        }

        //部門別計算区分チェック
        if (importData.departmentCalcDivision > 2 || importData.departmentCalcDivision < 1)
        {
            importData.error = "「部門別計算区分」に誤りがあります。";
            errorList.push(importData)
            return;
        }

        //休憩時間
        var timeRet = false
        const timeList = []
        timeList[1] = importData['restStart1Dt']
        timeList[2] = importData['restEnd1Dt']
        timeList[3] = importData['restStart2Dt']
        timeList[4] = importData['restEnd2Dt']
        timeList[5] = importData['restStart3Dt']
        timeList[6] = importData['restEnd3Dt']
      
        timeList[7] = importData['workEndDt']
        timeList[0] = importData['workStartDt']

        var checkTime = timeList[0]
        var endTime = timeList[7]
        for(var i=1;i<7;i++){
            if(timeList[i]=='') continue;
            if(timeToSec(timeList[i])<timeToSec(checkTime)){
                console.log()
                timeRet=true
            }
            checkTime=timeList[i]
        }
        if(endTime != '' && timeToSec(checkTime) > timeToSec(endTime)){
            timeRet=true
        }
        if(timeRet){
            importData.error = "「出勤、退勤、および、休憩開始～終了の時刻」に誤りがあります";
            errorList.push(importData)
            return;
        }

        var departmentID = importData.departmentCalcDivision==1?importData.comDepartmentId:importData.cusDepartmentId
        var checkDepartment = importData.departmentCalcDivision==2?!cusDepartmentList.includes(departmentID) && !customerList.includes(departmentID):
                              !comDepartmentList.includes(departmentID) && !companyList.includes(departmentID)
        if(checkDepartment)
        {
            importData.error= "部門ID:" + departmentID + "に誤りがあります。";
            errorList.push(importData)
            return;
        }  
        successList.push(importData)
    })
    
    return [successList,errorList] 
} 
//エラーチェック
export function onCheckAllcation(importDataList,schemaList) {
    var errorList = []
    var successList = []
    importDataList.map((importData)=>{
        for(var i=0;i<schemaList.length;i++){
            let fieldName = schemaList[i].fieldName.replace(/_[a-z]/g, function(match){return '_'+match.toUpperCase();});

            if(importData[fieldName] != undefined && importData[fieldName] != null && importData[fieldName] != ''){
                // if(!MasterErrorCheck(importData[fieldName],  schemaList[i])){
                //     importData.error = schemaList[i].comment+'の値に誤りがあります。'
                //     errorList.push(importData)
                //     return;
                // }
            }
            //NOTNULL制約が保たれないときはエラーリストに
            else if (schemaList[i].notNull)
            {
                if(fieldName == 'createdId' || fieldName == 'createdAt' 
                || fieldName == 'updatedId' || fieldName == 'updatedAt') continue;
                importData.error = schemaList[i].comment+'が入力されていません'
                errorList.push(importData)
                return;
            }
        }
        if ('POUNCHONLY' == importData.orderId)
        {
            importData.error= "「受注ID」の値は使用できないIDです。";
            errorList.push(importData);
            return;
        }
        //スタッフIDの確認
        //2019/11/20 K.Harada MOD(staffidの場所を確認障害管理No.137対応用を追加)
        if (importData.staffId == 'admin')
        {
            importData.error = "「スタッフID」に誤りがあります。";
            errorList.push(importData);
            return;
        }

        var order_startDate = importData["employmentStartDt"];
        var order_endDate = importData["employmentEndDt"];
        if(order_startDate > order_endDate)
        {
            importData.error  = "「雇用開始日付」、「雇用終了日付」のいずれかに誤りがあります。";
            errorList.push(importData);
            return;
        }

        var  retireDt = importData.retireDt
        if(retireDt!='' && order_endDate>retireDt)
        {
            importData.error  = "「リタイア日」、「雇用終了日付」のいずれかに誤りがあります。";
            errorList.push(importData);
            return;
        }
        

        //支給形態チェック
        if (importData.wagesType > 3 || importData.wagesType < 1)
        {
            importData.error = "「給与形態」に誤りがあります。";
            errorList.push(importData)
            return;
        }

        //請求形態チェック
        if (importData.billingType > 3 || importData.billingType < 1)
        {
            importData.error = "「請求形態」に誤りがあります。";
            errorList.push(importData)
            return;
        }

        //休憩時間
        var timeRet = false
        const timeList = []
        timeList[1] = importData['restStart1Dt']
        timeList[2] = importData['restEnd1Dt']
        timeList[3] = importData['restStart2Dt']
        timeList[4] = importData['restEnd2Dt']
        timeList[5] = importData['restStart3Dt']
        timeList[6] = importData['restEnd3Dt']
      
        timeList[7] = importData['workEndDt']
        timeList[0] = importData['workStartDt']

        var checkTime = timeList[0]
        var endTime = timeList[7]
        for(var i=1;i<7;i++){
            if(timeList[i]=='') continue;
            if(timeToSec(timeList[i])<timeToSec(checkTime)){
                console.log()
                timeRet=true
            }
            checkTime=timeList[i]
        }
        if(endTime != '' && timeToSec(checkTime) > timeToSec(endTime)){
            timeRet=true
        }
        if(timeRet){
            importData.error = "「出勤、退勤、および、休憩開始～終了の時刻」に誤りがあります";
            errorList.push(importData)
            return;
        }

        successList.push(importData)
    })
    
    return [successList,errorList] 
} 
/// <summary>
/// マスタ情報エラーチェック
/// </summary>
///  <returns>true:正常、false:エラー</returns>
export function MasterErrorCheck(data, SchemaRow)
{
    var ret = true;
    var colList = null;
    try
    {
        switch (SchemaRow["type"].toLowerCase().trimEnd())
        {
            //文字列長が範囲以上だとfalse
            case "varchar":

                ret = ((SchemaRow["maxLength"]) >= data.length);
                if(!ret) return ret

                //▼▼▼2019/08/06 H.Watanabe ADD Start(IDについて、半角英数字チェックを追加。)
                colList = [ "staffId", "orderId", "companyId", "customerId", "departmentId" ];
                colList.map((id)=>
                {
                    if(SchemaRow["fieldName"] == id)
                    {
                        var isAlphaNumericOnly = /[^a-zA-z0-9]/g;
                        ret = !isAlphaNumericOnly.test(data);

                        if (ret)
                        {
                            if ('POUNCHONLY' == data)
                            {   
                                // インポート不可のIDの場合data
                                ret = false;
                            }
                        }
                    }
                })
                
                if(!ret) return ret
                //▲▲▲2019/08/06 H.Watanabe ADD End(IDについて、半角英数字チェックを追加。)

                switch(SchemaRow["fieldName"])
                {
                    case "zipCode":
                        if (data && data.length != 0)
                        {
                            var isZipCode = /^\d{3}-\d{4}$/g;
                            ret = isZipCode.test(data);
                        }
                        break;
                    case "phoneNum":
                    case "mobileNum":
                        if (data && data.length != 0)
                        {
                            var isPhoneNum = /^\d{1,6}-\d{1,6}-\d{1,6}$/g;
                            ret = isPhoneNum.test(data);
                        }
                        break;
                    case "pcMailAddress":
                    case "mobileMailAddress":
                        if (data && data.length != 0)
                        {
                            var isMailAddress = /^[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g
                            ret = isMailAddress.test(data);
                        }
                        break;
                            //▼▼▼2019/11/18 K.Hrada ADD Start(「admin」に対して更新を防ぐため追加)
                    case "staffId":
                        ret = data !='admin';
                        break;
                        //▲▲▲2019/11/18 K.Hrada ADD Start(「admin」に対して更新を防ぐため追加)
                }
                break;

            case "bit": // mysql用
                ret = (data == "0" || data == "1" || data.toLowerCase()=='false' || data.toLowerCase()=='true');
                break;
            case "char":
                //char(1)型 ==bit型なので0/1以外はfalse
                var lenth = 0;
                {
                    lenth = (SchemaRow["maxLength"]);
                }
                if (lenth == 1)
                {
                    ret = (data == "0" || data == "1");
                    break;
                }
                else
                {   
                        ret = lenth == data.length;
                        console.log(ret)
                        //▼▼▼2019/08/06 H.Watanabe ADD Start(IDについて、半角英数字チェックを追加。)
                        colList = [ "comDepartmentId", "cusDepartmentId", "departmentId" ];
                        colList.map((id)=>
                        {
                            if (SchemaRow["fieldName"].toLowerCase().trimEnd() == id)
                            {
                                var isAlphaNumericOnly = /[^a-zA-z0-9]/g;
                                ret = !isAlphaNumericOnly.test(data);

                            }

                            if (ret)
                            {
                                if ('POUNCHONLY' == data)
                                {   
                                    // インポート不可のIDの場合data
                                    ret = false;
                                }
                            }
                                
                        })

                    //▲▲▲2019/08/06 H.Watanabe ADD End(IDについて、半角英数字チェックを追加。)

                }
                break;


                case "tinyint":  // mysql
                    var type = "0";
                    type = data;
                    //マスタの範囲ではsmallint型は1-3,4なのでそれ以外の整数はfalse
                    if (SchemaRow["fieldName"].toLowerCase().trimEnd() == "sex")
                    {
                        ret = (type == "1" || type == "2" || type == "3");
                    }
                    else
                    {
                        ret = (type == "1" |type == "2" || type == "3" || type == "4");
                    }
                break;

                case "date":
                case "datetime":
                case "timestamp":
                    var isTime = /^[0-2][0-9]:[0-5][0-9]/g;
                    ret = !isTime.test(data);
                      
                    break;
        }

    }
    catch
    {
        return false;
    }
    return ret;
}
