import styled from 'styled-components'

export const ColumnStaffTH = styled.th`
  width: 14rem;
  vertical-align:middle;
`

export const ColumnOccupationTH = styled.th`
  width: 20rem;
  vertical-align:middle;
`

export const ColumnDateTH = styled.th`
  width: 5rem;
  vertical-align:middle;
`

export const ColumnWorkTypeTH = styled.th`
  width: 7rem;
  vertical-align:middle;
`

export const ColumnTimeTH = styled.th`
  width: 4rem;
  vertical-align:middle;
`

export const ColumnRemarkTH = styled.th`
  width: calc(16rem);
  vertical-align:middle;
`
export const ColumnRestRemarkTH = styled.th`
  width: calc(40rem + 5px);
  vertical-align:middle;
`

export const ColumnButtonsTH = styled.th`
  width: 4rem;
  vertical-align:middle;
`

export const ColumnStaffTD = styled.td`
  font-size: 16px;
  width: 14rem;
  max-width: 14rem;
  vertical-align:middle;
  padding-left:1rem;
  /* 未切れ対策 */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* end*/
`

export const ColumnOccupationTD = styled.td`
  width: 20rem;
  max-width: 20rem;
  vertical-align:middle;
  padding-left:1rem;
`

export const ColumnDateTD = styled.td`
  width: 5rem;
  vertical-align:middle;
  text-align:center;
`

export const ColumnWorkTypeTD = styled.td`
  width: 7rem;
  vertical-align:middle;
  text-align:center;
`

export const ColumnTimeTD = styled.td`
  width: 4rem;
  vertical-align:middle;
  text-align:center;
`

export const ColumnRemarkTD = styled.td`
  width: calc(16rem);
  vertical-align:middle;
  text-align:center;
`
export const ColumnRestRemarkTD = styled.td`
  width: calc(40rem + 5px);
  vertical-align:middle;
  text-align:center;
`
export const ColumnButtonsTD = styled.td`
  width: 4rem;
  vertical-align:middle;
  text-align:center;
`

const DailyTableWrap = styled.table`
  font-size: 12px;
  border-collapse:separate;
  border-spacing: 1px;
  
  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(102rem + 16px + 12px + 12rem)
  }

  tbody {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(85vh - 6rem - 2rem - 4rem - 8px);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
    height: 2rem;
    background-color: #649cdf;
  }

  td {
    height: 3rem;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`


export const DailyTableModalWrap = styled.table`
  font-size: 12px;
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(77rem + 16px + 12px)
  }

  tbody {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(50vh - 6rem);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
    height: 2rem;
    background-color: #649cdf;
  }

  td {
    height: 3rem;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`
export default DailyTableWrap
