import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { diff48Time,makeElementId,ValidUpdate,checkUpdate,calcWorkingSeconds,dateFormat} from '../../../Common/Utilties/LaborUtility'

import Button from '../../../Common/Atoms/NormalButton'
import { MultipartApi } from '../../../Common/ApiAxios'
import ErrorHandling from '../../../Common/ErrorHandling'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'

import DailyTable from  './DailyTable'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  display: flex;
  padding: 1vw;
`
const FormWrap = styled.form`
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
  padding: 1vw;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

const DailyAttendanceModal = ({ open, onCloseFunc, updatedAttendanceFunc, defaultUrl, dailyWorkInfoList,workDivisions,handleOpenPunchHistoryFunc}) => {
  const [date, setData] = useState('')
  const [staffId, seStaffId] = useState('')
  const [staffName, seStaffName] = useState('')
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();
  const alert = useAlert()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!dailyWorkInfoList || dailyWorkInfoList.length === 0) {
        return
    }

    setData(dailyWorkInfoList[0].baseDt.replace(/-/g, '/'))
    seStaffId(dailyWorkInfoList[0].staffId)
    seStaffName(dailyWorkInfoList[0].staffName)

  }, [dailyWorkInfoList])

  // POST・PUT処理
  const onSubmit = (data) => {
    async function sendPost (requestBody, data) {
      var resultAttendance
      dispatch(SetLoadSpinner(true))
      console.log(data)
      resultAttendance = 
        await MultipartApi(`${defaultUrl}`,'POST', requestBody, data)

      if (!ErrorHandling(resultAttendance, alert)) {
        alert.success('保存しました')
      }
      dispatch(SetLoadSpinner(false))
    }

    const submitData = new window.FormData()
  
    data = makeSendData(data)
   
    if(ValidUpdate(data))
    {
      alert.error("入力項目にエラーがあります")
      return     
    }

    //再検索用情報付与
    data = {'formDatas': data,
            'startDt': '2020-07-01',
            'endDt': '2020-07-01',
            'staffId': '',
            'staffName': '',
            'postType':0,
            'postId':'',
            'departmentId':'',
            'page': 1}
    console.log(data.startDt)
    if(data.formDatas.length==0)
    {
        alert.error("更新するデータがありません")
        return
    }
    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    sendPost(submitData, data)

    updatedAttendanceFunc()
    onCloseFunc()
  }
  const makeSendData = (data) =>
  {
    
    var dailyList= new Array();
      dailyWorkInfoList.forEach((workInfo)=>{
          let id = makeElementId(workInfo)
          let dailyRow = {
              orderId: workInfo.orderId, //getValues('orderId_'+id),
              staffId: workInfo.staffId, //getValues('staffId_'+id),
              baseDt: workInfo.baseDt, //getValues('baseDt_'+id),
              
              workDivision: getValues('attendanceWorkDivision_'+id),

              clockWorkStartDt: "",
              clockWorkEndDt: "",
              clockWorkTotalDt: "",
              clockRestStart1Dt: "",
              clockRestEnd1Dt: "",
              clockRestStart2Dt: "",
              clockRestEnd2Dt: "",
              clockRestStart3Dt: "",
              clockRestEnd3Dt: "",
              clockRestTotalDt: "",

              workStartDt: getValues('attendanceWorkStartDt_'+id),
              workEndDt: getValues('attendanceWorkEndDt_'+id),
              workTotalDt: getValues('attendanceWorkTotalDt_'+id),
              restStart1Dt: getValues('attendanceRestStart1Dt_'+id),
              restEnd1Dt: getValues('attendanceRestEnd1Dt_'+id),
              restStart2Dt: getValues('attendanceRestStart2Dt_'+id),
              restEnd2Dt: getValues('attendanceRestEnd2Dt_'+id),
              restStart3Dt: getValues('attendanceRestStart3Dt_'+id),
              restEnd3Dt: getValues('attendanceRestEnd3Dt_'+id),
              restTotalDt: getValues('attendanceRestTotalDt_'+id),
              
              overTotalDt:null,

              remark: null,
              updatedAt: dateFormat(workInfo.attendanceUpdatedAt)
            }
            
            let workInfoRow = {
              workDivision: workInfo.attendanceWorkDivision,
              workStartDt: diff48Time(workInfo.baseDt,workInfo.attendanceWorkStartDt),
              workEndDt: diff48Time(workInfo.baseDt,workInfo.attendanceWorkEndDt),
              restStart1Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestStart1Dt),
              restEnd1Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd1Dt),
              restStart2Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestStart2Dt),
              restEnd2Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd2Dt),
              restStart3Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestStart3Dt),
              restEnd3Dt: diff48Time(workInfo.baseDt,workInfo.attendanceRestEnd3Dt),

              remark: null
            }

            //差異チェック
            if(checkUpdate(workInfoRow,dailyRow))
            {
              console.log(dailyRow)
              //勤務時間,休憩時間計算
              let result = calcWorkingSeconds([dailyRow.workStartDt, dailyRow.workEndDt], 
                  [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, 
                    dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt], workInfo.attendanceBaseTime)
              dailyRow.workTotalDt = result[0]
              dailyRow.restTotalDt = result[1]
              dailyRow.overTotalDt = result[2]
              dailyRow.nightTime= result[3]

              dailyList.push(dailyRow)
            }
        })

    return dailyList
  }

  return (
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='勤怠編集'
      aria-describedby='勤怠編集ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap onSubmit={handleSubmit(onSubmit)} className='h-dsm'>
    <div className={classes.paper}>
      <ContentWrap>
      <TitleWrap>
          <label>{date} {staffId} {staffName}</label>
      </TitleWrap>
      <TableWrap>
        <DailyTable
          register={register}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          reset={reset}
          errors={errors}
          workDivisions={workDivisions}
          defaultUrl={defaultUrl}
          dailyWorkInfoList={dailyWorkInfoList}
          updatedAttendanceFunc={updatedAttendanceFunc}
          handleOpenPunchHistoryFunc={handleOpenPunchHistoryFunc}
        >
        </DailyTable>
      </TableWrap>
      </ContentWrap>
      <ButtonsWrap>
        <Button name='更新' padding='0 0 12px 0' type='submit'/>
        <Button name='閉じる' type='button' padding='0px' onClick={onCloseFunc} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

DailyAttendanceModal.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedAttendanceFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    dailyWorkInfoList: PropTypes.array,
    workDivisions: PropTypes.array,
    handleOpenPunchHistoryFunc: PropTypes.func,
}

export default DailyAttendanceModal
