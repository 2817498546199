import React,{useState,useEffect} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { InputGroup, SelectGroup } from '../../../Common/CommonInputGroup'
import { diff48Time, format48Time, formatHour, makeElementIdByDailyWorkInfo, calcRestHours ,formatRound48Time} from '../../../Common/Utilties/LaborUtility'
import Button from '../../../Common/Atoms/NormalButtonAttendance'
export const ColumnTD = styled.td`
width: 4rem;
vertical-align:middle;
text-align:center;
`
export const Columndiv = styled.div`
color:red;
`
const RestTableRow = ({
  register, getValues, setValue, watch, reset, errors,restData,workInfo,onDelete,canWrite,index
  }) => {

  // 初期化メソッド
  useEffect(() => {
    if(restData){
      if(restData.attendanceRest){
        if(restData.attendanceRest.isDiff==undefined){
          restData.attendanceRest.isDiff=false
          setValue('restStart_' + restData.restNum, (workInfo.baseDt, restData.attendanceRest.restStartDt))
          setValue('restEnd_' + restData.restNum, (workInfo.baseDt, restData.attendanceRest.restEndDt))

          if(restData.attendanceRest.clockRestStartDt!=null){
            restData.attendanceRest.clockRestStartDt = diff48Time(workInfo.baseDt, restData.attendanceRest.clockRestStartDt)
          }
          if(restData.attendanceRest.clockRestEndDt!=null){
            restData.attendanceRest.clockRestEndDt = diff48Time(workInfo.baseDt, restData.attendanceRest.clockRestEndDt)
          }
  
        }
        else{
          setValue('restStart_' + restData.restNum, (workInfo.baseDt, restData.attendanceRest.restStartDt))
          setValue('restEnd_' + restData.restNum, (workInfo.baseDt, restData.attendanceRest.restEndDt))
        }
        setValue('restTotalDt_' + restData.restNum, formatHour(restData.attendanceRest.restTotalDt))
      }else{
        setValue('restStart_' + restData.restNum, "--.--")
        setValue('restEnd_' + restData.restNum, "--.--")
      }
      if(restData.shiftRest){
        setValue('shiftRestStart_' + restData.restNum, restData.shiftRest.restStartDt)
        setValue('shiftRestEnd_' + restData.restNum, restData.shiftRest.restEndDt) 
        setValue('shiftRestTotalDt_' + restData.restNum, formatHour(restData.shiftRest.restTotalDt))
      }else{
        setValue('shiftRestStart_' + restData.restNum, "--.--")
        setValue('shiftRestEnd_' + restData.restNum, "--.--")
      }
    }


  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  const onBlurTime = ( event ) => {
    let value = event.target.value
    //日時の分単位を指定分で丸める
    let fractionUnit = parseInt(workInfo.attendanceFractionUnit)
    //value = format48Time(value)
    let name = event.target.name 
    if(name.indexOf('restStart') != -1)
    {
      value = formatRound48Time(value,fractionUnit*(-1))
    }
    else
    {
      value = formatRound48Time(value,fractionUnit)
    }
    event.target.value = value

    let restStart1 = getValues("restStart_" + restData.restNum)
    let restEnd1 = getValues("restEnd_" + restData.restNum)
    
    //日時の分単位を指定分で丸める
    restStart1 = formatRound48Time(restStart1,fractionUnit*(-1))
    restEnd1 = formatRound48Time(restEnd1,fractionUnit)

    let result = calcRestHours( [restStart1, restEnd1])

    setValue('restTotalDt_' + restData.restNum, result)

    {
      restData.attendanceRest.restTotalDt = parseFloat(result)*3600
      restData.attendanceRest.restStartDt = restStart1
      restData.attendanceRest.restEndDt = restEnd1

      console.log(workInfo)
      restData.attendanceRest.isDiff =true
    }
  }
    const createElementRestTime = (workInfoList,canWrite) => {
      if (workInfoList && !canWrite && workInfoList.shiftRest) {
          return <>
              <InputGroup
                  name={'shiftRestTotalDt_' + restData.restNum}
                  width='3.5rem'
                  margin='auto'
                  placeholder=''
                  register={register}
                  errors={errors}
                  style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                  disabled={true}
              />
             </>
      }else if (workInfoList && canWrite && workInfoList.attendanceRest) {
        return <>
            <InputGroup
                name={'restTotalDt_' + restData.restNum}
                width='3.5rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={true}
                color={restData.shiftRest && restData.attendanceRest.restTotalDt==restData.shiftRest.restTotalDt?'#333':'red'}
            />
           </>
    }else{
      if(canWrite&&restData.shiftRest)return <Columndiv>--.--</Columndiv>
      return '--.--'
    }
  
      return <></>
    }  
    const createElementTime = (prefix, workInfoList,canWrite) => {
      if (workInfoList && ((!canWrite && workInfoList.shiftRest) || (canWrite && workInfoList.attendanceRest))) {
          var colorFlag = canWrite && restData.attendanceRest && restData.shiftRest
          if(colorFlag){
            colorFlag = canWrite && restData.attendanceRest[prefix.slice(0,-1)]!=restData.shiftRest[prefix.slice(0,-1)]
          }else{
            colorFlag = canWrite
          }

          return <>
              <InputGroup
                  name={prefix + restData.restNum}
                  width='3.5rem'
                  margin='auto'
                  placeholder=''
                  register={register}
                  errors={errors}
                  onBlur={onBlurTime}
                  style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                  disabled={!canWrite}
                  color={colorFlag?'red':'#333'}
              />
             </>
      }else{
        if(canWrite&&restData.shiftRest)return <Columndiv>--.--</Columndiv>
        return '--.--'
      }
    }
    return (
      <tr>
        <ColumnTD style={{ width: '4rem' }}> 休憩{index}</ColumnTD>
        <ColumnTD style={{ width: '4rem' }}> {createElementRestTime(restData,false)}</ColumnTD>
        <ColumnTD style={{ width: '4rem' }}> {createElementTime('shiftRestStart_',restData,false)}</ColumnTD>
        <ColumnTD style={{ width: '4rem' }}> {createElementTime('shiftRestEnd_',restData,false)}</ColumnTD>
        <ColumnTD style={{ width: '4rem' }}> {createElementRestTime(restData,true)}</ColumnTD>
        <ColumnTD style={{ width: '4rem' }}> {createElementTime('restStart_',restData,true)}</ColumnTD>
        <ColumnTD style={{ width: '4rem' }}> {createElementTime('restEnd_',restData,true)}</ColumnTD>
        <ColumnTD style={{ width: '4rem' }}>{<Button margin='auto' padding='0' type='button' width='3rem' name='削除' onClick={() => onDelete(restData.restNum)} disabled={!restData.attendanceRest}/>}</ColumnTD>
      </tr>
    )
}

RestTableRow.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func
}

export default RestTableRow