import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ColumnWrap = styled.th`
  vertical-align:middle;
  padding: 0.25rem;
`

const DailyTableHeader = ({open}) => {

  return (
    <>
    <thead>
      <tr>
        <ColumnWrap style={{ width: 'calc(39rem + 7px)' }}>公共機関</ColumnWrap>
        <ColumnWrap style={{ width: '7rem' }}><button style={{margin:'auto', padding:'0', minWidth:'6rem'}} onClick={()=>open(1,[])}>追加</button></ColumnWrap>
      </tr>
    </thead>
    <thead>
      <tr>
        <ColumnWrap style={{ width: '4rem' }}>支払区分</ColumnWrap>
        <ColumnWrap style={{ width: '12rem' }}>有効期限</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>移動手段</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>駅名(発)</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>駅名(着)</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>日数</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>料金</ColumnWrap>
        <ColumnWrap style={{ width: '4rem' }}>合計</ColumnWrap>
        <ColumnWrap style={{ width: '6rem' }}></ColumnWrap>
      </tr>
    </thead>
    </>
  )
}

DailyTableHeader.propTypes = {
}

export default DailyTableHeader
