import React, { useState, useEffect, useRef ,useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import FUNC from '../Common/FunctionEnum'
import { useForm } from 'react-hook-form'
import { GetApi ,MultipartApi} from '../Common/ApiAxios'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../Common/ErrorHandling'
import IconPageNextImg from '../../image/PageNext.png'
import IconPageBackImg from '../../image/PageBack.png'
import IconPageLastImg from '../../image/PageLast.png'
import IconPageTopImg from '../../image/PageTop.png'

import Search from './Search'

import { dateFormat,ValidUpdate,calcWorkingSeconds} from '../Common/Utilties/LaborUtility'

import BulkEditPanelWrap,{BulkEditFlexWrap,CountPanelWrap,BulkEditPageButtonWrap,BulkEditPagingPanelWrap,BulkEditPagingChangePanelWrap,PagingIconImg,PagingIconNextImg,BulkEditPagingIconPanelWrap} from './Wrap/BulkEditPanelWrap'
import BulkEditTable from './Molcules/BulkEditModel/BulkEditTable'
export const DefaultUrl = '/api/bulkEditManagement/bulkEdit'

const BulkEdit = (props) => {
  const [searchOption, setSearchOption] = useState({ staffId: '', staffName: '',postType:'',postId:'',departmentId:'',authStatus:0 })
  const { loginUserInfo } = useSelector(state => state)
  const staffIdRef = useRef()
  const staffNameRef = useRef()

  const [staffInfoList, setStaffInfoList] = useState([])
  const [staffInfoAllList, setStaffInfoAllList] = useState([])
  const [dateDataList, setDateDataList] = useState([])
  const [pagenation, setPagenation] = useState({})
  const [staffCount, setStaffCount] = useState(0)
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [viewFunction,setViewFunction] = useState(false)
  const pageRecord = 12
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()
  const dispatch = useDispatch()
  const alert = useAlert()
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.BULK_EDIT)
  const fromDateRef = useRef()
  const toDateRef = useRef()
  // 検索APIの実行
  const onSearch = async (createId, page) => {
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
    // 機能メニューを非表示に変更
    setViewFunction(false);
    
    var updateFlag = false

    staffInfoAllList.forEach(staff => {
      if(staff.isDiff) updateFlag=true
    })

    if(updateFlag){
      const is_ok = window.confirm('編集中のデータを破棄しますか');
      if(!is_ok)
      {
        console.log( fromDateRef.current,searchOption.startDt,toDateRef.current,searchOption.endDt)
        fromDateRef.current = searchOption.startDt
        toDateRef.current = searchOption.endDt
        return searchOption
      }
    }


    dispatch(SetLoadSpinner(true))
    await getWorkDivisions()
    const resultDate = await GetApi('/api/masterManagement/calendar/range', { startDt: fromDateRef.current, endDt: toDateRef.current })
    if (resultDate.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(resultDate, alert)
      return null
    } else {
      //setDataDateList(resultDate.data.responseList)
    }
    setSearchOption(
      {
        startDt: fromDateRef.current,
        endDt: toDateRef.current,
        staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        postType:postType,
        postId:postId,
        departmentId:departmentId
      }
    )
    const data = {
      startDt: fromDateRef.current,
      endDt: toDateRef.current,
      staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId.replace(new RegExp(0 + "+$", "g"),''):'',
      page: page
    }
    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      console.log(result)

      setPageNum(page)
      setStaffInfoAllList(result.data)
      makePage(result.data.slice(),page)
      setStaffCount(result.data.length)
      setPageLast(Math.ceil(result.data.length/pageRecord))

      setDateDataList(resultDate.data.responseList)
    }
    setViewSubSearch(false);
  }

  const Reserch = async (createId, page) => {
    dispatch(SetLoadSpinner(true))
    const resultDate = await GetApi('/api/masterManagement/calendar/range', { startDt: fromDateRef.current, endDt: toDateRef.current })
    if (resultDate.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(resultDate, alert)
      return null
    } else {
      //setDataDateList(resultDate.data.responseList)
    }

    const data = {
      startDt: searchOption.startDt,
      endDt:searchOption.startDt,
      staffId: grants.readGrantDivision === 3 ? loginUserInfo.staffId : searchOption.staffId,
      staffName: searchOption.staffName,
      postType:searchOption.postType,
      postId:(searchOption.postId!=undefined && searchOption.postId != -1) ? searchOption.postId:'',
      departmentId:(searchOption.departmentId != null && searchOption.departmentId!=undefined)? searchOption.departmentId.replace(new RegExp(0 + "+$", "g"),''):'',
      page: page
    }
    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      console.log(result)

      setPageNum(page)
      setStaffInfoAllList(result.data)
      makePage(result.data.slice(),page)
      setStaffCount(result.data.length)
      setPageLast(Math.ceil(result.data.length/pageRecord))

      setDateDataList(resultDate.data.responseList)
    }
    setViewSubSearch(false);
    setViewFunction(false);
  }
  const onReserch=()=>{
    Reserch(null,1)
  }

  //ページング設定
  const makePage = (data,page) =>{
    if(page!=0){
      var minRecord = (page-1)*pageRecord
      var maxRecord = (page*pageRecord)
      console.log(minRecord,maxRecord)
      var tmpCount = 0
      var tmpList = new Array()
      data.forEach((staffWorkInfo)=>{
        if(tmpCount>=minRecord && tmpCount<maxRecord){
          tmpList.push(staffWorkInfo)
          // staffWorkInfo.checked = false
          // if(staffWorkInfo.shiftBulkList.length===1) staffWorkInfo.shiftNewList = staffWorkInfo.shiftBulkList[0]
          // staffWorkInfo.isDiff=false        
        }
        tmpCount+=1
      })
      setStaffInfoList(tmpList)
    }else{
      data.forEach((staffWorkInfo)=>{
        //staffWorkInfo.checked = false
      })
      setStaffInfoList(data)
    }
  }

  // 検索項目でキーボードの押下イベント
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }
  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
  }
  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud'
    else
      document.title = 'Core Time Cloud'
  }, [grants])

  useEffect(() => {
    setPost()
    onSearch(null,1)
  }, [])
  
  const setPost=()=>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      }
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.companyId,
          name: item.companyName+'('+item.companyId+')',
        }
      })
      setPostList(resultList)
      return true
    }
    const serachCustomer = async()=>{
      const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } 
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.customerId,
          name: item.customerName+'('+item.customerId+')',
        }
      })
      setPostList(resultList)
      return true
    }

    if(postType==0){
      serachCompany()
    }else{
      serachCustomer()
    }
  }
  //会社顧客変更処理
  useEffect(() => {
    setPost()
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postType])
  if(!postList){
    setPost()
  }
  const onPageSeletClick = (pageId) => {
    console.log(pageId)
    makePage(staffInfoAllList,pageId)
    setPageNum(pageId)
  }
  const onPageNextSeletClick = (pageId) => {
    if(pageNum!=pageLast){
      onPageSeletClick(pageId)
    }
  }
  const onPageBackSeletClick = (pageId) => {
    if(pageNum!=1){
      onPageSeletClick(pageId)
    }
  }
  const onPageChangeLabelClick = () => {
    if(pageType==0){
      setPageType(1)
      setPageNum(0)  
      makePage(staffInfoAllList,0)
  
    }else{
      setPageType(0)
      setPageNum(1)
      makePage(staffInfoAllList,1)
    }
  }
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }
  
  // 全選択・全解除処理
  const onAllChecked = (check) => {
    var tmp = staffInfoList.map((staff)=>
    {
      if(staff.shiftBulkList.length===1)staff.checked=check 
      return staff
    })
    setStaffInfoList(tmp)
  }

  // 元に戻す
  const onUnEdit= () => {
    var tmp = staffInfoList.map((staff)=>
    {
      if(staff.checked) {
        staff.shiftNewList=staff.shiftBulkList[0]
        staff.isDiff=false
      }
      return staff
    })
    setStaffInfoList(tmp)
  }

  const [canOpenDialog, setCanOpenDialog] = useState(false);
  //ダイアログ閉じるボタン処理
  const handleClose = () => {
    setCanOpenDialog(false);
  }

  //選択ボタン処理
  const onClickEdit =  (event) => {
    setCanOpenDialog(true)
  }

  
  //選択ボタン処理
  const onUpdate =  (event) => {
    onSubmit()
    setViewFunction(false);
  }
  // POST・PUT処理
  const onSubmit = () => {

    async function sendPost (requestBody) {
      var result
      dispatch(SetLoadSpinner(true))
      result = await MultipartApi(`${DefaultUrl}`, 'PUT', requestBody)
      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(result, alert)) {
        alert.success('保存しました')

        onReserch()
      }
    }

    var dailyList= new Array();
    staffInfoAllList.forEach(staff => {
      if(staff.isDiff)
      {
        console.log(staff)
        let dailyRow ={
          orderId: staff.shiftBulkList[0].orderId, //getValues('orderId_'+id),
          staffId: staff.staffId, //getValues('staffId_'+id),
          shiftDt: staff.dateValue, //getValues('baseDt_'+id),
          baseDt: staff.dateValue, //追加
          workDivision: (staff.shiftNewList.shiftWorkTotalDt?2:1),
          
          workStartDt: staff.shiftNewList.shiftWorkStartDt,
          workEndDt: staff.shiftNewList.shiftWorkEndDt,
          workTotalDt: staff.shiftNewList.shiftWorkTotalDt,

          restStart1Dt: staff.shiftNewList.shiftRestStart1Dt,
          restEnd1Dt: staff.shiftNewList.shiftRestEnd1Dt,
          restStart2Dt: staff.shiftNewList.shiftRestStart2Dt,
          restEnd2Dt: staff.shiftNewList.shiftRestEnd2Dt,
          restStart3Dt: staff.shiftNewList.shiftRestStart3Dt,
          restEnd3Dt: staff.shiftNewList.shiftRestEnd3Dt,

          restTotalDt: staff.shiftNewList.shiftRestTotalDt,
          overTime: staff.shiftNewList.shifOverTotalDt,
          
          remark: '',
          updatedAt: dateFormat(staff.shiftBulkList[0].shiftUpdatedAt)
        }
        {
          //勤務時間,休憩時間計算
          let result = calcWorkingSeconds([dailyRow.workStartDt, dailyRow.workEndDt], 
            [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, 
            dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt], staff.shiftBulkList[0].attendanceBaseTime)
          dailyRow.workTotalDt = result[0]
          dailyRow.restTotalDt = result[1]
          dailyRow.overTime = result[2]
          dailyRow.nghtTime = result[3]
          console.log(staff.shiftBulkList[0].attendanceBaseTime)
          dailyList.push(dailyRow)
        }
      }

     
    })

    if(ValidUpdate(dailyList))
    {
      alert.error("入力項目にエラーがあります")
      return
    }
    console.log(dailyList)

    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(dailyList)], { type: 'application/json' }))
    sendPost(submitData)
  }

  const [workDivisions, setWorkDivisions] = useState(<></>)
  //出勤区分取得
  const getWorkDivisions = useCallback(async () => {
    //dispatch(SetLoadSpinner(true))
    const result = await GetApi('/api/masterManagement/classificationValues/workDivisions')
    //dispatch(SetLoadSpinner(false))
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setWorkDivisions(options)
    }
  }, [setWorkDivisions, alert])

  //ページ遷移前の更新破棄の確認
  //更新キャンセル処理
  useEffect(() => {
    // イベントの設定
    window.addEventListener('beforeunload', onUnload);
  
    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload);
    }
  })
  const onUnload = (e) => {
    var updateFlag = false
    staffInfoAllList.forEach(staff => {
      if(staff.isDiff) updateFlag=true
    })
    if(updateFlag){
      e.preventDefault();
      e.returnValue = '';
    }
  }

  if (!fromDateRef.current) {
    var today = new Date();
    fromDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
    toDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
  }
  console.log('call BulkEdit');
  console.log(grants);
  if (!grants) return null
  return (
    <BulkEditPanelWrap id = 'BulkEditWrap'>
      <Search
          title= {grants.functionMenuName}
          onClick={() => onSearch(null, pageType==0?1:0)}
          onClickOption={(e) => onClickOptionBtn(e)}
          onKeyDown={(e) => onKeyDown(e)}
          fromDateRef={fromDateRef}
          toDateRef={toDateRef}
          staffIdRef={staffIdRef}
          staffNameRef={staffNameRef}
          viewSubSearch={viewSubSearch}
          grants={grants}
          postList={postList}
          postId={postId}
          setPostId={setPostId}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          postType={postType}
          setPostType={setPostType}
          onClickFunction={(e)=>onClickFunction(e)}
          viewFunction={viewFunction}
          onPageTypeChangeClick={() => onPageChangeLabelClick()}
          onAllChecked={()=>onAllChecked(true)}
          onAllUnChecked={()=>onAllChecked(false)}
          onClickEdit={()=>onClickEdit()}
          onUnEdit={()=>onUnEdit()}
          onUpdate={()=>onUpdate()}
        >
        </Search>
        <CountPanelWrap>
          <label>検索ヒット件数:{staffCount}件</label>
        </CountPanelWrap>
         <BulkEditFlexWrap>
          <BulkEditTable
            workInfoList={staffInfoList}
            defaultUrl={DefaultUrl}
            Reserch={()=>onReserch()}
            dateDataList={dateDataList}
            canOpenDialog={canOpenDialog}
            handleClose={handleClose}
            setStaffInfoList={setStaffInfoList}
            workDivisions={workDivisions}
          >
          </BulkEditTable>
        </BulkEditFlexWrap>
         
        {
          staffInfoList.length>0 &&
          <BulkEditPagingPanelWrap>
          { pageType==0 &&
            <BulkEditPageButtonWrap>       
            <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
            <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
            <BulkEditPagingIconPanelWrap>{pageNum}</BulkEditPagingIconPanelWrap>
            <BulkEditPagingIconPanelWrap>/</BulkEditPagingIconPanelWrap>
            <BulkEditPagingIconPanelWrap>{pageLast}</BulkEditPagingIconPanelWrap>
            <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
            <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
            
            </BulkEditPageButtonWrap>
          }
          </BulkEditPagingPanelWrap>
        }
    </BulkEditPanelWrap>
  )
}

export default BulkEdit
