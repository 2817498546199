import React from 'react'
import PropTypes from 'prop-types'

import { ColumnDateTH, ColumnWeekTH, ColumnPublicHolidayTH, ColumnNoteTH, ColumnButtonsTH } from '../Wrap/CalendarTableWrap'

const CalendarTableHeader = ({  }) => {

  return (
    <thead>
      <tr>
        <ColumnDateTH>日付</ColumnDateTH>
        <ColumnWeekTH>曜日</ColumnWeekTH>
        <ColumnPublicHolidayTH>祝日</ColumnPublicHolidayTH>
        <ColumnNoteTH>メモ</ColumnNoteTH>
        <ColumnButtonsTH></ColumnButtonsTH>
      </tr>
    </thead>
  )
}

CalendarTableHeader.propTypes = {
}

export default CalendarTableHeader
