import styled from 'styled-components'

export const SubSearchContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 600px;
  margin: 0.5rem;
`

export const SubSearchContentRow = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 0.5rem;
  text-align: left;
`

export const FunctionContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 200px;
  font-size: 16px;
  div:hover {
    ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
  }
`
export const FunctionContentRow = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  text-align: left;
  margin-top: 4px;
  margin-bottom: 4px;
`

export const AttendanceClickLabelPanelWrap = styled.div`
display: flex;
color:blue;
`
export const AttendanceFiltersPanelWrap = styled.div`
display: flex;
background-image: :${props => props.image || 'none'};
`
export const AttendanceFlexWrap = styled.div`
    display: flex;
    padding: 1vw;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 20%;
    background-position: 5% top;
`

export const AttendanceUpdateWrap = styled.form`
    display: flex;
    padding: 1vw;
    background-repeat: no-repeat;
    background-size: 20%;
    background-position: 5% top;
`
export const AttendanceExportWrap = styled.div`
    display: flex;
    padding: 1vw;
    background-repeat: no-repeat;
    background-size: 20%;
    background-position: 5% top;
`
export const AttendancePanelTableWrap = styled.div`
    display: flex;
    flex-direction: row;
    li.plusButtonLi {
      display: flex;
      margin: 0 0 12px 0;
      Button {
        margin: 0 16px;
        cursor: pointer;
      }
      Button {
        margin: 0 16px;
        cursor: pointer;
      }
    }
`
export const AttendanceCountPanelWrap = styled.div`
  font-size: 20px;
  display: flex;
  align: right;
  align-items: right;
  text-align: right;
  margin-right: 1rem;
  overflow-x: hidden;
  overflow-y: hidden;
  background-position: right;
  justify-content: right;
  flex-flow: column;
`
export const AttendancePageButtonWrap = styled.div`
display: flex;
align: right;
margin-left:calc(23.5vw - 7rem);
`
export const AttendancePagingPanelWrap = styled.div`
display: flex;
align: right;
margin-left: 5rem;
`
export const AttendancePagingIconPanelWrap = styled.div`
text-align:center;
line-height:32px;
justify-content: center;
font-size: 12px;
width: 22px;
height: 36px;
`
export const PagingIconImg = styled.img`
  align-items: left;
  width: 44px;
  height: 36px;
`
export const PagingIconNextImg = styled.img`
  align-items: left;
  width: 22px;
  height: 36px;
`
export const AttendancePagingChangePanelWrap = styled.div`
text-align:center;
line-height:32px;
justify-content: center;
font-size: 16px;
width: 70px;
height: 36px;
color:blue;
margin-right:calc(50vw - 14rem);
`

export const SearchWrap = styled.div`
display:${props => props.display || 'flex'};
padding: 1vw;
width:55vw;
flex-wrap: wrap;
h3 {
  line-height: 32px;
  font-size: 24px;
  margin-right: 16px;
}
ul.mainSearch {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  position: relative;
  > li {
    display: flex;
    margin: 0 0 0 1rem;
    label {
      line-height: 32px;
      text-align: right;
      font-size: 12px;
      margin: 0 0.5rem 0 0;
    }
  }
  li.subSearch {
    display: ${props => props.viewSubSearch ? 'block' : 'none' };
    position: absolute;
    padding: 0 16px;
    margin: 0;
    top: 60px;
    z-index: 3;
    left: 16px;
    background-color: #ffffff;
    box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  }
}

li.mainFunction {
    display: ${props => props.viewFunction? 'block' : 'none' };
    position: absolute;
    align-items: left;
    margin: 0;
    top: calc(120px + 5vh);
    z-index: 1;
    left: calc(79vw - 200px - 1rem);
    background-color: #cfe2f3;
    box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  }
${props => props.addStyle}
`

export const TableWrap = styled.table`
  font-size: 12px;
  cellpadding: 0;
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }
  thead {
    width: calc(62rem + 19px)
  }
  tbody {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(59vh - 6rem - 10.5px );
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
    margin-top:0.25rem;
    margin-bottom:0.25rem;
  }
  
  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th {
    height: 2rem;
    background-color: #649cdf;
  }

  tbody tr {
    :nth-child(even){
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

export const ColumnTD = styled.td`
width: 5rem;
vertical-align:middle;
text-align:center;
height: 3rem;
`
export const ColumnNameTD = styled.td`
width: 16rem;
vertical-align:middle;
text-align:center;
height: 3rem;
`
export const ColumnStaffNameTD = styled.td`
width: calc(31rem + 2px);
vertical-align:middle;
text-align:center;
height: 3rem;
`

export const ColumnDivisionTD = styled.td`
width: 11rem;
vertical-align:middle;
text-align:center;
`

export const ColumnTH = styled.th`
width: 5rem;
vertical-align:middle;
text-align:center;
`
export const ColumnNameTH = styled.th`
width: 16rem;
vertical-align:middle;
text-align:center;
`

export const ColumnDivisionTH = styled.th`
width: 11rem;
vertical-align:middle;
text-align:center;
`

export const ColumnStaffNameTH = styled.th`
width: calc(31rem + 2px);
vertical-align:middle;
text-align:center;
height: 3rem;
`
