import React from 'react'
import PropTypes from 'prop-types'
//import ReTooltip from '@material-ui/core/Tooltip'
import {XAxis ,YAxis, Tooltip, Legend, Line, Bar,ComposedChart } from "recharts"


import {DashboardBaseFormRow,DashboardTitle} from '../../../Wrap/DashboardBaseWrap'

const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(0)}%`;
/*
const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0;

  return toPercent(ratio, 2);
}
*/
const renderColorfulLegendText = (value) => {
  return <span style={{ color:'black'}}>{value}</span>
}

const AttendancePersentGraph= ({ attendanceList, title }) => {        
    
    return (
        <DashboardBaseFormRow>
        <div>
        <DashboardTitle>{title}</DashboardTitle>
        <ComposedChart 
      width={930}
      height={350}
        data={attendanceList}
        stackOffset='expand'
        margin={{
            top: 10, right: 30, left: 0, bottom: 5,
        }}>
        <XAxis xAxisId={1} dataKey="dateValue"/>
        <YAxis yAxisId={1} tickFormatter={toPercent} tickCount={6}/>
        <YAxis yAxisId={2} domain={[0, dataMax => (dataMax+(5 - dataMax%5))]} tickCount={6} orientation="right"/>
        <Tooltip/>
        <Legend formatter={renderColorfulLegendText}/>
        <Bar name="通常" xAxisId={1} yAxisId={1} stackId="1" dataKey={'normalCt'} fill="blue" />
        <Bar name="残業" xAxisId={1} yAxisId={1} stackId="1" dataKey={'overCt'} fill="orange" />
        <Line name="時間" xAxisId={1} yAxisId={2} dataKey="overTime" stroke="#649cdf"/>
        </ComposedChart >
        </div>
        </DashboardBaseFormRow>
      )
    }

AttendancePersentGraph.propTypes = {
  attendanceList: PropTypes.array,
  title: PropTypes.string,
}

export default AttendancePersentGraph
