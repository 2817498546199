import React,{useState, useEffect, useRef ,useCallback} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector,useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import FUNC from '../../../../Common/FunctionEnum'
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { MultipartApi,GetApi } from '../../../../Common/ApiAxios'
import ErrorHandling from '../../../../Common/ErrorHandling'
import { SetLoadSpinner } from '../../../../Common/Slice/LoadSpinnerSlice'

import  { AttendanceCountPanelWrap,AttendancePagingPanelWrap,PagingIconImg,AttendancePagingIconPanelWrap,PagingIconNextImg,AttendancePageButtonWrap } from '../../../Wrap/MasterDialogWrap'
import DailyTable from  './StaffTable'
import Search from './Search'
import IconPageNextImg from '../../../../../image/PageNext.png'
import IconPageBackImg from '../../../../../image/PageBack.png'
import IconPageLastImg from '../../../../../image/PageLast.png'
import IconPageTopImg from '../../../../../image/PageTop.png'
export const defaultUrl = '/api/masterManagement/staff/department'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  font-size: 18px;
  display: flex;
  padding: 1vw;
`
const FormWrap = styled.form`
  width: 55vw;
  height: 80vh;
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
  padding: 1vw;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

const StaffModal = ({ open, onCloseFunc, staffTypeOptions,allocationValue,staffIds,setStaffIds}) => {
  const { register, handleSubmit, getValues, setError, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();
  const alert = useAlert()
  const dispatch = useDispatch()

  const fromDateRef = useRef()
  const toDateRef = useRef()
  const staffIdRef = useRef()
  const staffNameRef = useRef()
  const [searchOption, setSearchOption] = useState({ shiftDt: '', staffId: '', staffName: '',postType:'',postId:'',departmentId:'' })
  const { loginUserInfo } = useSelector(state => state)
  const [staffWorkInfoList, setStaffWorkInfoList] = useState([])
  const [staffWorkInfoAllList, setStaffWorkInfoAllList] = useState([])

  const [pagenation, setPagenation] = useState({})
  const [staffCount, setStaffCount] = useState(0)
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.ATTENDANCE)
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [viewFunction,setViewFunction] = useState(false)
  const pageRecord = 9
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()
  
  
  const onSearch = async (createId, page) => {

    dispatch(SetLoadSpinner(true))
    setSearchOption(
      {
        staffId: (staffIdRef.current)?staffIdRef.current.value:'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        postType:postType,
        postId:postId,
        departmentId:departmentId
      }
    )
    const data = {
      staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : (staffIdRef.current)?staffIdRef.current.value:''):'',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      page: page
    }
    const result = await GetApi(defaultUrl, data)

    if (result.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert)
      return null
    } else {
      setStaffWorkInfoAllList(result.data)

      setStaffCount(result.data.length)
      setPageLast(Math.ceil(result.data.length/pageRecord))
      setPageNum(page)
      setPagenation(result.data)
      var tmp = result.data.slice()
      makePage(tmp,page)

    }
    dispatch(SetLoadSpinner(false))
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
    setViewFunction(false);
  }
  //ページング設定
  const makePage = (data,page) =>{
      if(page!=0){
        console.log(data)
        console.log(page)
        var minRecord = (page-1)*pageRecord
        var maxRecord = (page*pageRecord)
        console.log(minRecord,maxRecord)
        var tmpCount = 0
        var tmpList = new Array()
        data.forEach((staffWorkInfo)=>{
          if(tmpCount>=minRecord && tmpCount<maxRecord){
            tmpList.push(staffWorkInfo)
          }
          tmpCount+=1
        })
        setStaffWorkInfoList(tmpList)
        console.log(tmpList)
      }else{
        setStaffWorkInfoList(data)
      }
      
  }

  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
    console.log(viewSubSearch)
  }
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }
  const onPageSeletClick = (pageId) => {
    console.log(pageId)
    //onSearch(null,pageId)
    //makePage(JSON.parse(JSON.stringify(staffWorkInfoAllList)),pageId)
    makePage(staffWorkInfoAllList,pageId)
    setPageNum(pageId)
}
const onPageNextSeletClick = (pageId) => {
  if(pageNum!=pageLast){
    onPageSeletClick(pageId)
  }
  //setStaffWorkInfoList(staffWorkInfoAllList)
  //makePage(new Array(),pageId)
}
const onPageBackSeletClick = (pageId) => {
  if(pageNum!=1){
    onPageSeletClick(pageId)
  }

}
const onPageChangeLabelClick = () => {
  if(pageType==0){
    setPageType(1)
    setPageNum(0)
    makePage(staffWorkInfoAllList,0)
  }else{
    setPageType(0)
    setPageNum(1)
    makePage(staffWorkInfoAllList,1)
  }
}
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  const setPost=()=>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      }
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.companyId,
          name: item.companyName+'('+item.companyId+')',
        }
      })
      setPostList(resultList)
      return true
    }
    const serachCustomer = async()=>{
      const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } 
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.customerId,
          name: item.customerName+'('+item.customerId+')',
        }
      })
      
      setPostList(resultList)
      return true
    }
    if(postType==0){
      serachCompany()
    }else{
      serachCustomer()
    }
  }
 
  //会社顧客変更処理
  useEffect(() => {
    setPost()
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postType])

  //閉じる処理
  const makeCloseFlag=()=>{
    return false
  }

  //ダイアログ表示時の処理
  useEffect(() => {
      if(open) onSearch(null,pageType==0?1:0)
      console.log(staffIds)
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [open])
  if (!fromDateRef.current) {
    var today = new Date();
    fromDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
    toDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
  }

  const onSelect=()=>{
    var staffIdList = new Array()
    var staffName = ''
    staffWorkInfoAllList.forEach((staff)=>{
      if(staff.checked) {
        staffIdList.push(staff.staffId)
        staffName = staff.staffName
      }
    })

    console.log(staffIdList)
    setStaffIds(staffIdList)
    allocationValue("staffIds",staffIdList)
    if(staffIdList.length==1){
      allocationValue('staffId', staffIdList[0])
      allocationValue('staffName', staffName)
    }else if(staffIdList.length>1){
      allocationValue('staffId', '-')
      allocationValue('staffName', '複数')
    }else{
      allocationValue('staffId', '')
      allocationValue('staffName', '')
    }
    onCloseFunc(false)
  } 
  return (
    <Modal
      open={open}
      onClose={()=>onCloseFunc(makeCloseFlag())}
      aria-labelledby='スタッフ選択'
      aria-describedby='スタッフ選択ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap  className='h-dsm'> 
    <div className={classes.paper}>
      <ContentWrap>
      <Search
          title= {'スタッフ選択'}
          onClick={() => onSearch(null, pageType==0?1:0)}
          onClickOption={(e) => onClickOptionBtn(e)}
          onKeyDown={(e) => onKeyDown(e)}
          fromDateRef={fromDateRef}
          toDateRef={toDateRef}
          staffIdRef={staffIdRef}
          staffNameRef={staffNameRef}
          viewSubSearch={viewSubSearch}
          grants={grants}
          postList={postList}
          postId={postId}
          setPostId={setPostId}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          postType={postType}
          setPostType={setPostType}
          viewFunction={viewFunction}
          onClose={()=>onCloseFunc(makeCloseFlag())}
          onClickFunction={()=>onClickFunction()}
          onPageTypeChangeClick={() => onPageChangeLabelClick()}
          onSelect={()=>onSelect()}
        >
        </Search>
        <AttendanceCountPanelWrap>
          <label>検索ヒット件数:{staffCount}件</label>
        </AttendanceCountPanelWrap>
      <TableWrap>
        <DailyTable
          register={register}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          reset={reset}
          errors={errors}
          defaultUrl={defaultUrl}
          workInfoList={staffWorkInfoList}
          staffTypeOptions={staffTypeOptions}
          staffIds={staffIds}
        >
        </DailyTable>
      </TableWrap>
      </ContentWrap>
      {
          staffWorkInfoList.length>0 &&
          <AttendancePagingPanelWrap>
          {/* <AttendancePagingChangePanelWrap onClick={() => onPageChangeLabelClick()}>表示切替</AttendancePagingChangePanelWrap> */}
          { pageType==0 &&
            <AttendancePageButtonWrap>       
            <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
            <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
            <AttendancePagingIconPanelWrap>{pageNum}</AttendancePagingIconPanelWrap>
            <AttendancePagingIconPanelWrap>/</AttendancePagingIconPanelWrap>
            <AttendancePagingIconPanelWrap>{pageLast}</AttendancePagingIconPanelWrap>
            <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
            <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
            
            </AttendancePageButtonWrap>
          }
          </AttendancePagingPanelWrap>
        }
    </div>
    </FormWrap>
  </Modal>
  )
}

StaffModal.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedShiftFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    dailyWorkInfo: PropTypes.object,
    workDivisions: PropTypes.array,
}

export default StaffModal
