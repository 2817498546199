import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'

import { GetApi,MultipartApi } from '../../Common/ApiAxios'
//import Button from '../../Common/Atoms/NormalButton'
import { grantDecision } from '../../Common/Utilties/CommonUtility'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
import FUNC from '../../Common/FunctionEnum'

import MasterPanelWrap from '../Wrap/MasterPanelWrap'
import MasterFlexWrap from '../Wrap/MasterFlexWrap'
//import MasterEditButtonsWrap from '../Wrap/MasterEditButtonsWrap'
import Search from './Search'
import CalendarTable from './Molcules/CalendarTable'
import {PagingPanelWrap,PageButtonWrap,PagingIconImg,PagingIconNextImg,PagingIconPanelWrap,CalendarFlexWrap,CountPanelWrap}  from './Wrap/CalendarTableWrap'
import IconPageNextImg from '../../../image/PageNext.png'
import IconPageBackImg from '../../../image/PageBack.png'
import IconPageLastImg from '../../../image/PageLast.png'
import IconPageTopImg from '../../../image/PageTop.png'
import { dateFormat} from '../../Common/Utilties/LaborUtility'

import MasterSelect from '../MasterSelect'
//import { CompareArrowsOutlined } from '@material-ui/icons'


import {onExportCsvFile,makeCalenderCSV} from '../../Common/Utilties/ExportUtility'

export const DefaultUrl = '/api/masterManagement/calendar'

const Header = '日付,曜日,祝日,メモ'

const Calendar = (props) => {
  const startDateRef = useRef()
  const endDateRef = useRef()
  const [dateAllDataList, setDateAllDataList] = useState([])
  const [dateDataList, setDateDataList] = useState([])
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const [selectedId, setSelectedId] = useState()
  const [searchOption, setSearchOption] = useState({ startDt: '', endDt: '' })
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const [canWriteStaffBasic, setCanWriteStaffBasic] = useState(false)
  const dispatch = useDispatch()
  const alert = useAlert()
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.CALENDAR)

  const [viewFunction,setViewFunction] = useState(false)
  const pageRecord = 13
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [resutlSearchCount, setResutlSearchCount] = useState(0)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const selectItem = async (id) => {
    dispatch(SetLoadSpinner(true))
    setSelectedId(id)
    const result = await GetApi(`${DefaultUrl}/${id}`)
    dispatch(SetLoadSpinner(false))
    //ErrorHandling(result, alert) || setDetail(result.data)
  }

  // 検索APIの実行
  const onSearch = async (createId, page) => {
    dispatch(SetLoadSpinner(true))
    setSearchOption(
      {
        startDt: startDateRef.current,
        endDt: endDateRef.current,
      }
    )
    const data = {
      startDt: startDateRef.current,
      endDt: endDateRef.current,
      page: page
    }
    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      //const resultList = result.data.responseList.map(item => {
      const resultList = result.data.map(item => {
        return {
          id: item.dateValue,
          name: item.dateValue,
          dateValue: item.dateValue,
          week: item.week,
          publicHoliday: item.publicHoliday,
          note: item.note,
          updatedAt: dateFormat(item.updatedAt)
        }
      })

    //setDateDataList(resultList)
    setDateAllDataList(resultList);
    makePage(resultList,1);
    setSelectedId(null);

    setPageLast(Math.ceil(resultList.length/pageRecord));
    setPageNum(page);
    setResutlSearchCount(resultList.length);
    }
  }
  
  // 検索APIの実行
  const onFirstSearch = async (createId, page) => {
    dispatch(SetLoadSpinner(true))

    const data = {
      targetDt: startDateRef.current,
    }
    const result = await GetApi(DefaultUrl+'/month', data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      //const resultList = result.data.responseList.map(item => {
      const resultList = result.data.responseList.map(item => {
        return {
          id: item.dateValue,
          name: item.dateValue,
          dateValue: item.dateValue,
          week: item.week,
          publicHoliday: item.publicHoliday,
          note: item.note,
          updatedAt: dateFormat(item.updatedAt)
        }
      })
      //setDateDataList(resultList)]
      startDateRef.current = resultList.slice().shift().id        ;
      endDateRef.current =  resultList.slice().pop().id
      setSearchOption(
        {
          startDt: startDateRef.current,
          endDt: endDateRef.current,
        }
      );
      setDateAllDataList(resultList)
      makePage(resultList,1);
      setSelectedId(null);

      setPageLast(Math.ceil(resultList.length/pageRecord));
      setPageNum(page);
      setResutlSearchCount(resultList.length);
    }
  }
  
  //更新処理
  const onSubmit=()=>{
    async function sendPost (requestBody, data) {
      var resultAttendance
      dispatch(SetLoadSpinner(true))
      console.log(data)
      resultAttendance = 
        await MultipartApi(`${DefaultUrl}`,'POST', requestBody, data)
      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(resultAttendance, alert)) {
        alert.success('保存しました')
        onSearch('',1)
      }
    }
    var dailyList= new Array();
    dateDataList.forEach((date)=>{
      let id = date.dateValue
      let dailyRow = {
        dateValue: date.dateValue,
        week: date.week,
        note: getValues('note_'+id),
        publicHoliday: getValues('publicHoliday_'+id),
        updatedAt: date.updatedAt
      }
      if(date.note==null) date.note = ''
      if(dailyRow.note != date.note){
        dailyList.push(dailyRow)
        return
      }
      if(dailyRow.publicHoliday != date.publicHoliday) dailyList.push(dailyRow)
    })
    if(dailyList.length == 0){
      alert.error("更新するデータがありません")
      return
    }
    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(dailyList)], { type: 'application/json' }))
    //console.log(dailyList)
    sendPost(submitData, dailyList)
  }

  //ページング設定
  const makePage = (data,page) =>{
    if(page!=0){
      var minRecord = (page-1)*pageRecord
      var maxRecord = (page*pageRecord)
      var tmpCount = 0
      var tmpList = new Array()
      data.forEach((staffWorkInfo)=>{
        if(tmpCount>=minRecord && tmpCount<maxRecord){
          tmpList.push(staffWorkInfo)
        }
        tmpCount+=1
      })
      setDateDataList(tmpList)
    }else{
      setDateDataList(data)
    } 
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, 1)
    }
  }

  const onPageSeletClick = (pageId) => {
    makePage(dateAllDataList,pageId)
    setPageNum(pageId)
  }
  const onPageNextSeletClick = (pageId) => {
    if(pageNum!=pageLast){
      onPageSeletClick(pageId)
    }
  }
  const onPageBackSeletClick = (pageId) => {
    if(pageNum!=1){
      onPageSeletClick(pageId)
    }
  }
  const onPageChangeLabelClick = () => {
    if(pageType==0){
      setPageType(1)
      setPageNum(0)
      makePage(dateAllDataList,0)
    }else{
      setPageType(0)
      setPageNum(1)
      makePage(dateAllDataList,1)
    }
  }
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  //ダウンロード処理
  const onExport=async()=>{
    dispatch(SetLoadSpinner(true))
    setSearchOption(
      {
        startDt: startDateRef.current,
        endDt: endDateRef.current,
      }
    )
    const data = {
      startDt: startDateRef.current,
      endDt: endDateRef.current,
      page: 1
    }
    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))

    console.log(result)
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else{
      var today = new Date();
      var day = (''+today.getFullYear()).slice(-2) + ('00' + (today.getMonth())).slice(-2) + ('00' + today.getDate()).slice(-2)
              + ('00' + (today.getHours())).slice(-2) + ('00' + today.getMinutes()).slice(-2) + ('00' + today.getSeconds()).slice(-2)
      var fileName = 'カレンダーマスタ('+day+')'
      onExportCsvFile(makeCalenderCSV(result.data,Header),fileName)
    }
  }
  
  //インポート処理
  const onImport=(uploadData)=>{
    async function sendPost (requestBody, data) {
      var result
      dispatch(SetLoadSpinner(true))
      console.log(data)
      result = 
        await MultipartApi(`${DefaultUrl}/import`,'POST', requestBody, data)
      
      if (!ErrorHandling(result, alert)) {
        alert.success('保存しました')
  
        onSearch(null,1)
      }else{
        dispatch(SetLoadSpinner(false))
      }
    }

    var uploadDataList= new Array();
    //console.log(uploadData)
    uploadData.forEach((data)=>{
      let dataRow = {
        dateValue: data["日付"], 
        week: data["曜日"], 
        publicHoliday: data["祝日"], 
        note: data["メモ"],
      }

      uploadDataList.push(dataRow)
    })
    
    const submitData = new window.FormData()
    if(uploadDataList.length==0)
    {
        alert.error("更新するデータがありません")
        return
    }
    submitData.append('formData', new window.Blob([JSON.stringify(uploadDataList)], { type: 'application/json' }))

    sendPost(submitData, uploadDataList)
  }

  if (!startDateRef.current) {
    var today = new Date();
    var monthEndDt = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    startDateRef.current= today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + '01'//('00' + today.getDate()).slice(-2)
    endDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + monthEndDt.getDate()).slice(-2)
  }

  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud'
    else
      document.title = 'Core Time Cloud'

      
    setCanWriteStaffBasic(grantDecision('write', FUNC.CALENDAR, loginUserInfo, null, null))
  }, [grants]);

  //画面表示の時
  useEffect(()=>{
    //onSearch(null,1)
    onFirstSearch(null,1)
  },[]);

  if (!grants) return null;
  return (
    <MasterPanelWrap id='CalendarWrap'>
    <CalendarFlexWrap>
      <Search
        title={grants.functionMenuName}
        showButton
        onClick={() => onSearch(null, 1)}
        onKeyDown={(e) => onKeyDown(e)}
        startDateRef={startDateRef}
        endDateRef={endDateRef}
        grants={grants}
        onUpadate={()=>onSubmit()}
        onPageTypeChangeClick={() => onPageChangeLabelClick()}
        onClickFunction={(e)=>onClickFunction(e)}
        viewFunction={viewFunction}
        onExport={()=>onExport()}
        onImport={(uploadData)=>onImport(uploadData)}
      >
    </Search>
    </CalendarFlexWrap> 
    <MasterFlexWrap>
      <CalendarFlexWrap>
        <MasterSelect/>
        <div>
        <CalendarFlexWrap>
        <div/>
        <CountPanelWrap>
          <label style={{marginLeft:'auto', marginRigth:'0px'}}>検索ヒット件数:{resutlSearchCount}件</label>
        </CountPanelWrap>
        </CalendarFlexWrap>
        <CalendarTable
          register={register}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          reset={reset}
          errors={errors}
          
          dateDataList={dateDataList}
          defaultUrl={DefaultUrl}
          >
        </CalendarTable>
        {
        dateAllDataList.length>0 &&
        <PagingPanelWrap>
        { pageType==0 && 
          <PageButtonWrap>       
          <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
          <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
          <PagingIconPanelWrap>{pageNum}</PagingIconPanelWrap>
          <PagingIconPanelWrap>/</PagingIconPanelWrap>
          <PagingIconPanelWrap>{pageLast}</PagingIconPanelWrap>
          <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
          <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
          </PageButtonWrap>
        }
        </PagingPanelWrap>
        }
        </div>
      </CalendarFlexWrap>
      </MasterFlexWrap>
    </MasterPanelWrap>
  )
}

export default Calendar
