import React from 'react'

import { ColumnStaffTH, ColumnWorkTypeTH } from '../../Wrap/DailyTableWrap'

const DailyTableHeader = () => {

  return (
    <thead>
      <tr>
        <ColumnStaffTH>スタッフ名</ColumnStaffTH>
        <ColumnWorkTypeTH>区分</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>月給</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>時給</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>住宅手当</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>皆勤手当</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>家族手当</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>職務手当</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>特別手当</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>交通費単価</ColumnWorkTypeTH>
        <ColumnStaffTH></ColumnStaffTH>
      </tr>
    </thead>
  )
}

DailyTableHeader.propTypes = {
}

export default DailyTableHeader
