import React, { useEffect, useState, useCallback,PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReTooltip from '@material-ui/core/Tooltip'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';


import {DashboardBaseFormRow,DashboardTitle} from '../../../Wrap/DashboardBaseWrap'

const COLORS = ['aqua', 'red', 'lime',];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent ,payload, value}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  console.log(payload)
  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${payload.xTitle}:${value}（${(percent * 100).toFixed(0)}%）`}
    </text>
  )
}
const PieGraph= ({ staffList, title }) => {        


    return (
        <DashboardBaseFormRow>
        <div>
        <DashboardTitle>{title}</DashboardTitle>
        <PieChart 
        width={930}
        height={350}>
        <Pie
            data={staffList}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={150}
            innerRadius={60}
            fill="#8884d8"
            dataKey="count"
        >
          {staffList.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
        </Pie>
        </PieChart >
        </div>
        </DashboardBaseFormRow>
      )
    }

PieGraph.propTypes = {
  attendanceList: PropTypes.array,
  title: PropTypes.string,
}

export default PieGraph
