import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../../../Common/Atoms/NormalButton'
import { MultipartApi } from '../../../Common/ApiAxios'
import ErrorHandling from '../../../Common/ErrorHandling'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import { makeElementId ,ValidUpdate,checkUpdate, diff48Time,dateFormat,calcWorkingRestFreeSeconds} from '../../../Common/Utilties/LaborUtility'

import DailyTable from  './EmploymentTable'

export const defaultUrl = '/api/shiftManagement/shift'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  font-size: 18px;
  display: flex;
  padding: 1vw;
`
const FormWrap = styled.form`
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
  padding: 1vw;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

const DailyShiftModal = ({ open, onCloseFunc, updatedShiftFunc, dailyWorkInfo,workDivisions,workInfoList}) => {
  const { register, handleSubmit, getValues, setError, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();
  const alert = useAlert()
  const dispatch = useDispatch()

  // POST・PUT処理
  const onSubmit = (data) => {

    // async function sendPost (requestBody) {
    //   var result
    //   dispatch(SetLoadSpinner(true))
    //   result = await MultipartApi(`${defaultUrl}/${dailyWorkInfo.staffId}/${dailyWorkInfo.dateValue}`, 'PUT', requestBody)
    //   dispatch(SetLoadSpinner(false))
    //   if (!ErrorHandling(result, alert)) {
    //     alert.success('保存しました')

    //     updatedShiftFunc()
    //   }
    // }

    // var dailyList= new Array();
    // dailyWorkInfo.shiftBulkList.forEach(workInfo => {
    //   let id = makeElementId(workInfo)

    //   var restDiffFlag = false
    //   var restList = new Array()
    //   var addRestList = new Array()
    //   var deleteRestList = new Array()
    //   var updateRestList = new Array()

    //   var shiftList = workInfo.restList
    //   var workDivision = getValues('shiftWorkDivision_'+id)
    //   if(workDivision==2 || workDivision==3 || workDivision==4){
    //     shiftList.map((restData)=>{
    //       if(restData.restNum == null || restData.isDiff == undefined) return
    //       if(!restData.isDel){
    //         restList.push(restData.restStartDt)
    //         restList.push(restData.restEndDt)
    //       }
    //       if(restData.isDiff){
    //         if(restData.isNew){
    //           addRestList.push(restData)
    //         }else if(restData.isDel){
    //           deleteRestList.push(restData)
    //         }else{
    //           updateRestList.push(restData)
    //         }
    //         restDiffFlag=true
    //       }
    //     })
    //   }else{
    //     shiftList.map((restData)=>{
    //       if(restData.restNum == null || restData.isDiff == undefined) return
    //       if(!restData.isNew){
    //         deleteRestList.push(restData)
    //         restDiffFlag=true
    //       }
    //     })
    //   }

    //   let dailyRow ={
    //     orderId: workInfo.orderId, //getValues('orderId_'+id),
    //     staffId: workInfo.staffId, //getValues('staffId_'+id),
    //     shiftDt: workInfo.dateValue, //getValues('baseDt_'+id),
    //     baseDt: workInfo.dateValue, //追加
    //     workDivision: getValues('shiftWorkDivision_'+id),
    //     workStartDt: getValues('shiftWorkStart_'+id),
    //     workEndDt: getValues('shiftWorkEnd_'+id),
    //     workTotalDt: getValues('shiftWorkTotalDt_'+id),
    //     restStart1Dt: getValues('shiftRestStart1_'+id),
    //     restEnd1Dt: getValues('shiftRestEnd1_'+id),
    //     restStart2Dt: getValues('shiftRestStart2_'+id),
    //     restEnd2Dt: getValues('shiftRestEnd2_'+id),
    //     restStart3Dt: getValues('shiftRestStart3_'+id),
    //     restEnd3Dt: getValues('shiftRestEnd3_'+id),
    //     restTotalDt: getValues('shiftRestTotalDt_'+id),
    //     overTotalDt:null,
    //     deleteRestList: deleteRestList,
    //     updateRestList: updateRestList,
    //     addRestList: addRestList,
    //     remark: getValues('shiftRemark_'+id),
    //     updatedAt: dateFormat(workInfo.shiftUpdatedAt)
    //   }

    //   let workInfoRow = {

    //     workDivision: workInfo.shiftWorkDivision,
    //     workStartDt: diff48Time(workInfo.dateValue,workInfo.shiftWorkStartDt),
    //     workEndDt: diff48Time(workInfo.dateValue,workInfo.shiftWorkEndDt),
    //     restStart1Dt: diff48Time(workInfo.dateValue,workInfo.shiftRestStart1Dt),
    //     restEnd1Dt: diff48Time(workInfo.dateValue,workInfo.shiftRestEnd1Dt),
    //     restStart2Dt: diff48Time(workInfo.dateValue,workInfo.shiftRestStart2Dt),
    //     restEnd2Dt: diff48Time(workInfo.dateValue,workInfo.shiftRestEnd2Dt),
    //     restStart3Dt: diff48Time(workInfo.dateValue,workInfo.shiftRestStart3Dt),
    //     restEnd3Dt: diff48Time(workInfo.dateValue,workInfo.shiftRestEnd3Dt),

    //     remark: workInfo.shiftRemark
    //   }

    //   //差異チェック
    //   if(checkUpdate(workInfoRow,dailyRow))
    //   {
    //     //ValidUpdateRow(dailyRow,workInfo)
    //     //勤務時間,休憩時間計算
    //     // let result = calcWorkingSeconds([dailyRow.workStartDt, dailyRow.workEndDt], 
    //     //   [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, 
    //     //     dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt], workInfo.attendanceBaseTime)
        
    //     if(!restFlag) restList = [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt]
    //     //勤務時間,休憩時間計算
    //     let result = calcWorkingRestFreeSeconds([dailyRow.workStartDt, dailyRow.workEndDt], restList, workInfo.attendanceBaseTime)
    //     dailyRow.workTotalDt = result[0]
    //     dailyRow.restTotalDt = result[1]
    //     dailyRow.overTotalDt = result[2]
    //     dailyRow.nightTime = result[3]
      
    //     dailyList.push(dailyRow)
    //   }
     
    // })
    // var updateData =
    // {
    //   shiftDailyList: dailyList
    // }
    // console.log(dailyWorkInfo)
    // if(ValidUpdate(dailyList))
    // {
    //   alert.error("入力項目にエラーがあります")
    //   return
    // }
    // console.log(dailyList)

    // const submitData = new window.FormData()
    // submitData.append('formData', new window.Blob([JSON.stringify(updateData)], { type: 'application/json' }))
    // sendPost(submitData)
  }



  return (
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='雇用契約編集'
      aria-describedby='雇用契約編集ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap onSubmit={handleSubmit(onSubmit)} className='h-dsm'>
    <div className={classes.paper}>
      <ContentWrap>
      <TitleWrap>
          <label>雇用契約編集ダイアログ</label>
      </TitleWrap>
      <TableWrap>
        <DailyTable
          register={register}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          reset={reset}
          errors={errors}
          workDivisions={workDivisions}
          defaultUrl={defaultUrl}
          workInfoList={workInfoList}
          dailyWorkInfo={dailyWorkInfo}
        >
        </DailyTable>
      </TableWrap>
      </ContentWrap>
      <ButtonsWrap>
        <Button name='閉じる' padding='0px' onClick={onCloseFunc} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

DailyShiftModal.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedShiftFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    dailyWorkInfo: PropTypes.object,
    workDivisions: PropTypes.array,
}

export default DailyShiftModal
