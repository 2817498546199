// 

/**
 * スタッフ情報の新規作成時の初期値を生成する
 * @returns スタッフ情報の新規作成時の初期値
 */
export function createStaffInfo() {
  var today = new Date();
  var todayDt = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)

  return {
    birthDt: todayDt,
    enterDt: todayDt,
    staffDivision: 1,
    loginFlag: false,
    delFlag: false,
    grant: {
      grantNameId: 0,
    },
    hope: {
      overtimePropriety: false,
      shiftMon: false,
      shiftTue: false,
      shiftWed: false,
      shiftThu: false,
      shiftFri: false,
      shiftSat: false,
      shiftSun: false,
      shiftHol: false,
    },
    setting: {
      workingTime: 0,
      workingStartTime: '1900-01-01 9:00:00',
      workingEndTime: '1900-01-01 18:00:00',
      nightStartTime: '1900-01-01 22:00:00',
      nightEndTime: '1900-01-02 5:00:00',
      deemedTime: 0,
      beforeWorkingFlag: true,
      afterWorkingFlag: true,
    },
  }
}

/**
 * 会社情報の新規作成時の初期値を生成する
 * @returns 会社情報の新規作成時の初期値
 */
export function createCompayInfo() {
  return {
    delFlag: false,
  }
}

/**
 * 顧客情報の新規作成時の初期値を生成する
 * @returns 顧客情報の新規作成時の初期値
 */
export function createCustomerInfo() {
    return {
      delFlag: false,
    }
}

/**
 * 受注情報の新規作成時の初期値を生成する
 * @returns 受注情報の新規作成時の初期値
 */
export function createOrderInfo() {
  var today = new Date();
  var todayDt = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)

  return {
    contractDivision: 1,
    orderDt: todayDt,
    agreementStartDt: todayDt,
    agreementEndDt: todayDt,
    wagesType: 1,
    billingType: 1,
    departmentCalcDivision: 2,
    rateDivision: 1,
  }
}

/**
 * 引当情報の新規作成時の初期値を生成する
 * @returns 引当情報の新規作成時の初期値
 */
export function createAllocationInfo() {
  return {
    workType: 99,
    workType2: 0,
    workType3: 0,
    workType4: 0,
    workType5: 0,
    wagesType: 1,
    billingType: 1,
    rateDivision: 1,
  }
}

