import React, { useState, useEffect, useRef ,useCallback} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import { useHistory } from 'react-router'
import { Prompt } from 'react-router-dom';
import { GetApi ,MultipartApi} from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
import FUNC from '../../Common/FunctionEnum'
import AttendancePanelWrap from './Wrap/AttendancePanelWrap'
import Search from './Search'

import MasterFlexWrap from '../Wrap/MasterFlexWrap'
import MasterListView from '../Molcules/MasterListView'
import MasterPanelWrap from '../Wrap/MasterPanelWrap'

import CoreTimeAliveMonitoringTable from './Molcules/AliveMonitoringTable'
import {PagingPanelWrap,PageButtonWrap,PagingIconImg,PagingIconNextImg,PagingIconPanelWrap,CalendarFlexWrap,CountPanelWrap}  from './Wrap/TableWrap'
import IconPageNextImg from '../../../image/PageNext.png'
import IconPageBackImg from '../../../image/PageBack.png'
import IconPageLastImg from '../../../image/PageLast.png'
import IconPageTopImg from '../../../image/PageTop.png'
import { dateFormat} from '../../Common/Utilties/LaborUtility'

import DeviceAddDialog from './Molcules/DeviceAddDialog'
import MasterSelect from '../MasterSelect'

export const DefaultUrl = '/api/masterManagement/coretimeAliveMonitoring'

const CoreTimeAliveMonitoring = (props) => {
  const fromDateRef = useRef()
  const toDateRef = useRef()
  const deviceIdRef = useRef()
  const locationRef = useRef()
  const [searchOption, setSearchOption] = useState({ shiftDt: '', staffId: '', staffName: '',postType:'',postId:'',departmentId:'' })
  const { loginUserInfo } = useSelector(state => state)
  const [aliveMonitoringList, setAliveMonitoringList] = useState([])
  const [aliveMonitoringAllList, setAliveMonitoringAllList] = useState([])
  const statusRef= useRef(0)
  const [staffCount, setStaffCount] = useState(0)
  const dispatch = useDispatch()
  const alert = useAlert()
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.ALIVE_MONITORING)
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [viewFunction,setViewFunction] = useState(false)
  const pageRecord = 13
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()

  const [workDivisions, setWorkDivisions] = useState(<></>)

  // 検索APIの実行
  const onSearch = async (createId, page) => {
    dispatch(SetLoadSpinner(true))

    setSearchOption(
      {
        id: (deviceIdRef.current)?deviceIdRef.current.value:'',
        location: (locationRef.current)?locationRef.current.value:'',
        status: statusRef.current,
      }
    )
    const data = {
      id: (deviceIdRef.current)?deviceIdRef.current.value:'',
      location: (locationRef.current)?locationRef.current.value:'',
      status: statusRef.current,
    }
    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      console.log(result)
     
      setAliveMonitoringAllList(result.data)
      makePage(result.data,1)
      setStaffCount(result.data.length)
      setPageLast(Math.ceil(result.data.length/pageRecord))
      setPageNum(page)
    }
  }


  // POST・PUT処理
  const onSubmit = (data) => {
    async function sendPost (requestBody, data) {
      var resultAttendance
      dispatch(SetLoadSpinner(true))
      console.log(data)
      resultAttendance = 
        await MultipartApi(`${DefaultUrl}`,'POST', requestBody, data)
      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(resultAttendance, alert)) {
        alert.success('保存しました')
        onSearch('',1)
      }
    }
    var dailyList= new Array();
    aliveMonitoringList.forEach((date)=>{
      let id = date.deviceId
      let dailyRow = {
        deviceId: date.deviceId,
        location1: getValues('location1_'+id),
        location2: getValues('location2_'+id),
        updatedAt: date.updatedAt
      }
      if(date.location1==null) date.location1 = ''
      if(date.location2==null) date.location2 = ''

      if(dailyRow.location2 != date.location2 || dailyRow.location1 != date.location1){
        dailyList.push(dailyRow)
        return
      }
      if(dailyRow.publicHoliday != date.publicHoliday) dailyList.push(dailyRow)
    })
    if(dailyList.length == 0){
      alert.error("更新するデータがありません")
      return
    }
    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(dailyList)], { type: 'application/json' }))
    console.log(dailyList)
    sendPost(submitData, dailyList)
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }


  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud'
    else
      document.title = 'Core Time Cloud'
  }, [grants])

  useEffect(() => {
    onSearch(null,1)
  }, [])

  
  //会社顧客変更処理
  useEffect(() => {
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postType])
  
  
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }


  
  //ページ遷移前の更新破棄の確認
  //更新キャンセル処理
  useEffect(() => {
    // イベントの設定
    window.addEventListener('beforeunload', onUnload);
  
    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload);
    }
  })
  const onUnload = (e) => {
    var updateFlag = false
    if(updateFlag){
      e.preventDefault();
      e.returnValue = '';
    }
  }
  //ページング設定
  const makePage = (data,page) =>{
    if(page!=0){
      var minRecord = (page-1)*pageRecord
      var maxRecord = (page*pageRecord)
      var tmpCount = 0
      var tmpList = new Array()
      data.forEach((staffWorkInfo)=>{
        if(tmpCount>=minRecord && tmpCount<maxRecord){
          tmpList.push(staffWorkInfo)
        }
        tmpCount+=1
      })
      setAliveMonitoringList(tmpList)
    }else{
      setAliveMonitoringList(data)
    } 
  }

  const onPageSeletClick = (pageId) => {
    makePage(aliveMonitoringAllList,pageId)
    setPageNum(pageId)
  }
  const onPageNextSeletClick = (pageId) => {
    if(pageNum!=pageLast){
      onPageSeletClick(pageId)
    }
  }
  const onPageBackSeletClick = (pageId) => {
    if(pageNum!=1){
      onPageSeletClick(pageId)
    }
  }
  const onPageChangeLabelClick = () => {
    if(pageType==0){
      setPageType(1)
      setPageNum(0)
      makePage(aliveMonitoringAllList,0)
    }else{
      setPageType(0)
      setPageNum(1)
      makePage(aliveMonitoringAllList,1)
    }
  }
  //詳細要処理
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
      setOpen(true);
  }

  const onUpload=(id,location1,location2)=>{
    async function sendPost (id,location1,location2) {
      var result
      dispatch(SetLoadSpinner(true))
      result = await MultipartApi(`${DefaultUrl}/${id}/${location1}/${location2}`, 'PUT')
     
      if (!ErrorHandling(result, alert)) {
        alert.success('保存しました')
        onSearch()
      }else{
        dispatch(SetLoadSpinner(false))
      }
    }
    console.log(id,location1,location2)
    sendPost(id,location1,location2)
  }
  
  if (!fromDateRef.current) {
    var today = new Date();
    fromDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
    toDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
  }

  if (!grants) return null
  return (
      <MasterPanelWrap id='CoreTimeAliveMonitoringWrap'>
        <Search
          title= {'コアタイム死活監視'}
          showButton
          onClick={() => onSearch(null, 1)}
          onKeyDown={(e) => onKeyDown(e)}
          staffIdRef={deviceIdRef}
          staffNameRef={locationRef}
          grants={grants}
          firstSearch={true}
          viewFunction={viewFunction}
          onClickFunction={(e)=>onClickFunction(e)}
          onPageTypeChangeClick={(e)=>onPageChangeLabelClick(e)}
          onAddClick={(e)=>handleOpen(e)}
          onUpadate={()=>onSubmit()}
          statusRef={statusRef}
        >
        </Search>
        <MasterFlexWrap>
            <MasterSelect/>
            <div>
            <CalendarFlexWrap>
            <div/>
            <CountPanelWrap>
              <label style={{marginLeft:'auto', marginRight:'0px'}}>検索ヒット件数:{staffCount}件</label>
            </CountPanelWrap>
            </CalendarFlexWrap>
            <CoreTimeAliveMonitoringTable
              register={register}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              reset={reset}
              errors={errors}
              
              dateDataList={aliveMonitoringList}
              defaultUrl={DefaultUrl}
              >
            </CoreTimeAliveMonitoringTable>
            {
            aliveMonitoringAllList.length>0 &&
            <PagingPanelWrap>
            { pageType==0 && 
              <PageButtonWrap>       
              <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
              <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
              <PagingIconPanelWrap>{pageNum}</PagingIconPanelWrap>
              <PagingIconPanelWrap>/</PagingIconPanelWrap>
              <PagingIconPanelWrap>{pageLast}</PagingIconPanelWrap>
              <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
              <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
              </PageButtonWrap>
            }
            </PagingPanelWrap>
            }
            </div>
        </MasterFlexWrap>
        <DeviceAddDialog open={open} onCloseFunc={()=>handleClose()} onUpload={(id,location1,location2)=>onUpload(id,location1,location2)}/>
      </MasterPanelWrap>
  )
}

export default CoreTimeAliveMonitoring
