import React from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

const ColumnWrap = styled.th`
  vertical-align:middle;
  padding: 0.25rem;
`

const DailyTableHeader = () => {

  return (
    <thead>
      <tr>
        <ColumnWrap style={{ width: '8rem' }}>スタッフ名</ColumnWrap>
        <ColumnWrap style={{ width: '7rem' }}>区分</ColumnWrap>
        <ColumnWrap style={{ width: '7rem' }}>月給</ColumnWrap>
        <ColumnWrap style={{ width: '7rem' }}>時給</ColumnWrap>
        <ColumnWrap style={{ width: '7rem' }}>住宅手当</ColumnWrap>
        <ColumnWrap style={{ width: '7rem' }}>皆勤手当</ColumnWrap>
        <ColumnWrap style={{ width: '7rem' }}>家族手当</ColumnWrap>
        <ColumnWrap style={{ width: '7rem' }}>職務手当</ColumnWrap>
        <ColumnWrap style={{ width: '7rem' }}>特別手当</ColumnWrap>
        <ColumnWrap style={{ width: '7rem' }}>交通費単価</ColumnWrap>
      </tr>
    </thead>
  )
}

DailyTableHeader.propTypes = {
}

export default DailyTableHeader
