import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'
import { PostApi } from '../../Common/ApiAxios'
import MonthCalendar from '../../Common/Atoms/MonthCalendar'
import Button from '../../Common/Atoms/NormalButton'
import Select from '../../Common/Atoms/SelectAddValue'
import Input from '../../Common/Atoms/Input'
import IconSearchImg from '../../../image/search-icon.png'
import IconCollapseImg from '../../../image/Collapse.png'
import IconTopImg from '../../../image/menu-icon3.png'

import SearchWrap ,{ SubSearchContent, SubSearchContentRow, FiltersPanelWrap,ClickLabelPanelWrap,FunctionContent,FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/DashboardSerachWrap'
import SearchDepartmentDialog from '../../Common/CommonDepartmentDialog'

import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconCloseImg from '../../../image/close_icon.png'

const Search = (props) => {
  const {
    onClick, onClickOption, onKeyDown, shiftDateRef, firstSearch, viewSubSearch, grants,
    postList,setPostId,postId,setDepartmentId,departmentId,
    postType,setPostType,
    addStyle, relationStatus,
    viewFunction,onClickFunction,searchTypeRef
  } = props
  const dispatch = useDispatch();
  const history = useHistory();
  const [shiftDate, setShiftDate] = useState();
  const [renderFlg, setRenderFlg] = useState(false);
  const [rows,setRows] = useState(<></>);
  const alert = useAlert();
  const loginUserInfo = useSelector(state => state.loginUserInfo);

  const [labelName, setLabelName] = useState('会社');
  const [postText, setPostText] = useState('全て');
  const [departmentName, setDepartmentName] = useState('');
  const [canOpenDialog, setCanOpenDialog] = useState(false);
  const [selectValue,setSelectValue] = useState(null);
  //ダイアログ閉じるボタン処理
  const handleClose = () => {
    setCanOpenDialog(false);
  }

  //選択ボタン処理
  const onClickDepartmentSelect =  () => {
    setCanOpenDialog(true)
  }
  // 初期値
  useEffect(() => {
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, dispatch])

  // レンダー終了判定

  //会社リスト更新時処理
  useEffect(() => {
    console.log(postList)
    postList.unshift({index:-1,name:'全て'})
    var postRows = 
      postList.map(post=>{
        return <><option value={post.index} >{post.name}</option></> 
      })
    setRows(postRows)

  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postList])

  //デフォルト設定
  const setDefault=()=>{
    setSelectValue(-1)
    setPostId(-1)
    setPostText('全て')
    setDepartmentName('')
    setDepartmentId(null)
  }
  //会社顧客変更処理
  useEffect(() => {
    if(postType==0){
      setLabelName('会社')
    }else{
      setLabelName('顧客')
    }
    setDefault()
// 必要なデータが更新された場合のみ実行
// eslint-disable-next-line
}, [postType])

  const changeCalendar = (date) => {
    shiftDateRef.current = date;
    setShiftDate(date)
    onClick()
  }

  // 会社・顧客のコンボボックス選択変更イベント
  const onChange = (event) => {
    event.persist()
    setPostId(event.target.value)
    setPostText(event.target.options[event.target.selectedIndex].text)
    setSelectValue(event.target.value)
  }

  // 会社、顧客ラベルのクリックイベント
  const onClickLabel = ()=>{
    //console.log(postType)
    if(postType==0){
      setPostType(1)
    }else{
      setPostType(0)
    }
  }
  //リセットボタン処理
  const onClickResetBtn=()=>{
    setPostType(0)
    setDefault()
  }

  useEffect(() => {
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, relationStatus])
  
  // TOP画面遷移クリックイベント
  async function topPage () {
    history.push('/top');
  }

  async function logout () {
    const apiUrl = '/api/logout'
    await PostApi(apiUrl, null)
    document.cookie = 'accessToken=;path=/; max-age=0;'
    document.cookie = 'refreshToken=;path=/; max-age=0;'
    document.cookie = 'accessTokenExpires=;path=/; max-age=0;'
    document.cookie = 'refreshTokenExpires=;path=/; max-age=0;'
    window.location.href = '/login'
  }

  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{'ダッシュボード'}</h3>
      <img src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'4px'}}/>
      <div className='subTitleItem' style={{fontSize:'16px',margin:'8px 0 0 12px'}}>{'スタッフ構成'}</div>
      <ul className='mainSearch'>
        <li>
          <MonthCalendar changeCalendar={changeCalendar} dataValue={shiftDateRef.current} isViewIcon placeholder={'対象年月'}/>
          <Tooltip title={'対象'}>
          <div>
          <Select
            name={'postId'}
            width='8rem'
            selectRef={searchTypeRef}
            >
            <option value={0} >{'全て'}</option>  
            <option value={1} >{'引当中'}</option>
            <option value={2} >{'当月入職者'}</option>
            <option value={3} >{'退職者'}</option>
            <option value={4} >{'当月退職者'}</option>
          </Select>
          </div>
          </Tooltip>
          <Tooltip title={'検索オプション'}>
            <img src={IconSearchImg} width="24px" height="24px" style={{ marginTop: '4px',cursor: 'pointer' }} onClick={onClickOption} />
          </Tooltip>
          {/* <Tooltip title={'検索オプション'}>
            <img src={IconOptionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: '12px', cursor: 'pointer'}} onClick={onClickOption} />
          </Tooltip> */}
        </li>
        <li className='subSearch'>
          <SubSearchContent>
            <SubSearchContentRow>
              <ClickLabelPanelWrap onClick={onClickLabel}><label style={{ width: '4rem' }}>{labelName}</label></ClickLabelPanelWrap>
              <Tooltip title={postText}>
              <FiltersPanelWrap>
              <Select
                name={'postId'}
                width='30rem'
                onChange={onChange}
                disabled={grants.readGrantDivision === 3}
                selectValue={selectValue}
                >{rows}
              </Select>
              </FiltersPanelWrap>
              </Tooltip>
            </SubSearchContentRow>
            <SubSearchContentRow>
              <label style={{width:'4rem'}}>部門情報</label>
              <Input type='text' name='departmentName' value = {departmentName} onKeyDown={onKeyDown} disabled={true} width='25.5rem' style={{marginLeft:'15px'}}/>
              <Button type='button' width='4rem' margin='0 0 0 4px' name='選択' padding='0px' onClick={onClickDepartmentSelect} />
            </SubSearchContentRow>
            <SubSearchContentRow style={{textAlign:'right', marginLeft:'auto', marginRight:'1.5rem' }}>
              <Button type='button' margin='0 0 0 16px' name='リセット' padding='0px' onClick={onClickResetBtn} />
              <Button type='button' margin='0 0 0 16px' name='検索' padding='0px' onClick={onClick} />
            </SubSearchContentRow>
          </SubSearchContent>
        </li>
      </ul>
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img src={IconFunctionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px'}}>
            <FunctionIcon src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
          <hr/>
          <FunctionContentRow onClick={logout} style={{marginBottom:'12px'}}>
            <FunctionIcon src={IconCloseImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>ログアウト</span></FunctionName>
          </FunctionContentRow>
        </FunctionContent>
      </li>
      <SearchDepartmentDialog
        open={canOpenDialog}
        onCloseFunc={handleClose}
        postName={postText}
        postId={postId}
        setSearchDepartmentId={setDepartmentId}
        searchDepartmentId={departmentId}
        setDepartmentName={setDepartmentName}
        postType={postType}
        >
        </SearchDepartmentDialog>
    </SearchWrap>
  )
}

Search.propTypes = {
  title: PropTypes.string, // タイトル
  onClick: PropTypes.func, // 検索処理
  onClickOption: PropTypes.func, // サブ検索項目の表示処理
  onKeyDown: PropTypes.func, // キーダウン処理
  shiftDateRef: PropTypes.object, // 検索項目関連
  firstSearch: PropTypes.bool, // 最初に検索処理をするか
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  grants: PropTypes.object, // 権限関連
  addStyle: PropTypes.string, // css用追加用
  postList: PropTypes.string, // 会社・顧客リスト
}

export default Search
