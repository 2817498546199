import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

//import Tooltip from '@material-ui/core/Tooltip'

//import { convertPxToRem, measurePxText } from '../../../../Common/Utilties/StringUtility'
import  { ColumnTD ,ColumnStaffNameTD,ColumnDivisionTD} from '../../../Wrap/MasterDialogWrap'
import { SelectGroup } from '../../../../Common/CommonInputGroup'

const DailyTableRow = ({ 
    register, getValues, setValue, watch, reset, errors, workInfo,staffTypeOptions,staffIds }) => {

  useEffect(() => {
    if (workInfo) {
      setValue('staffDivision', workInfo.staffDivision)

      workInfo.checked = staffIds.includes(workInfo.staffId)
    }

  }, [workInfo])

  const createElementDate = (date) => {
    if(!date) return ''
    return date.replaceAll('-','/')
  }

  const [checked, setChecked] = useState(workInfo.checked)
  const onChange=()=>{
    workInfo.checked = !workInfo.checked
    setChecked(!checked)
  }
  return (
    <tr>
      <ColumnTD>
        <input
          type="checkbox"
          name={workInfo.staffId}
          checked={checked}
          onChange={onChange}
        />
      </ColumnTD>
      <ColumnTD>{workInfo.staffId}</ColumnTD>
      <ColumnStaffNameTD>{workInfo.staffName}</ColumnStaffNameTD>
      <ColumnDivisionTD>
                <SelectGroup
                  name='staffDivision'
                  width='10rem'
                  margin='auto'
                  options={staffTypeOptions}
                  register={register}
                  errors={errors}
                  disabled={true}
                />
      </ColumnDivisionTD>
      <ColumnTD>{createElementDate(workInfo.enterDt)}</ColumnTD>
      <ColumnTD>{createElementDate(workInfo.retireDt)}</ColumnTD>
    </tr>
  )
}

DailyTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  workDivisions: PropTypes.any,
  dateDataList: PropTypes.array,
  dailyWorkInfo: PropTypes.object,
  selectFunction: PropTypes.func,

}

export default DailyTableRow
