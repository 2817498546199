import React from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'
import {RowHeaderTop}from '../../Wrap/StaffBiometricTableWrap'


const ColumnWrap = styled.th`
  vertical-align:middle;
  padding: 0.25rem;
  height: 2rem;
`

const TableHeader = () => {

  return (
    <thead>
      <tr>
        <RowHeaderTop>
            <ColumnWrap style={{ width: '36rem' }}>スタッフID</ColumnWrap>
            <ColumnWrap style={{ width: '70rem' }}>スタッフ名</ColumnWrap>
            <ColumnWrap style={{ width: '6rem' }}>登録情報</ColumnWrap>
            <ColumnWrap style={{ width: '4rem' }}>編集</ColumnWrap>
        </RowHeaderTop>
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
}

export default TableHeader
