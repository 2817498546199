import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'
import ErrorHandling from '../../../Common/ErrorHandling'

import CalendarTableRow from './AliveMonitoringTableRow'

const CalendarTableBody = ({ 
  register, getValues, setValue, watch, reset, errors, 
  dateDataList, selectFunction, updatedDateFunc, defaultUrl,
   }) => {

  // 初期化メソッド
  useEffect(() => {
      
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])


  return (
    <tbody>
    {dateDataList && dateDataList.map((date) => {
    return (
        <CalendarTableRow key={date.dateValue}
            register={register}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            reset={reset}
            errors={errors}

            dateData={date}
        >
        </CalendarTableRow>
        )
    })}
    </tbody>
  )
}

CalendarTableBody.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  dateDataList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedDateFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
}

export default CalendarTableBody
