import React, { useEffect, useState, useCallback } from 'react'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { GetApi } from '../../../../Common/ApiAxios'
import ErrorHandling from '../../../../Common/ErrorHandling'
import * as regex from '../../../../Common/Regex'
import { InputGroup, SelectGroup, HiddenInput } from '../../../../Common/CommonInputGroup'
import { Alert } from '../../../../Common/CommonStyled'
import { useYubinbango } from '../../../../Common/Utilties/CommonUtility'
import { formatZipCode } from '../../../../Common/Utilties/StringUtility'
import { formatPhoneNum } from '../../../../Common/Utilties/PhoneNumUtility'
import { format48Time, secToTime } from '../../../../Common/Utilties/LaborUtility'
import { YubinbangoScript } from '../../../../Common/Atoms/ScriptTag'

import MasterDetailWrap, { MasterDetailFormWrap } from '../../../Wrap/MasterDetailWrap'
import NormalSubButton from '../../../../Common/Atoms/NormalSubButton'
import SearchDepartmentDialog from '../../../../Common/CommonMasterDepartmentDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75vw',
  },
  typography: {
    width: '72vw',
    fontSize: '18px',
    fontWeight: '700',
    color: '#ffffff',
    verticalAlign: 'middle',
    margin: '0',
    padding: '16px',
    background: '#4169E1',
  },
}))

const useTreeStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '70vw',
  },
}))

export const DepartmentMasterForm = ({
  register, errors, setValue, getValues, reset, department,departmentList,comGetValues,
  watch, canWrite, isDeleted, actionType, setActionType, setIsDeleted,setIsSucess
}) => {
  const classes = useStyles()
  const alert = useAlert()
  const [hierarchyNoOptions, setHerarchyNoOptions] = useState(<></>)
  const [fractionalUnitOptions, setFractionalUnitOptions] = useState(<></>)
  const [fractionalTypeOptions, setFractionalTypeOptions] = useState(<></>)

  const [companyDepartmentId, setCompanyDepartmentId] = useState(null)
  const [companyId, setCompanyId] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [companyDepartmentName,setCompanyDepartmentName]=useState(null)
  const [canCompanyOpenDialog, setCompanyOpenDialog] = useState(false);
  const setComDepartmentId =(data)=>{
    if(!data) {
      setCompanyDepartmentId('000000000000')
      if(!department.comDepartmentId)setValue('comDepartmentId', '')
      setValue('hierarchyNo', 1) 
      return
    }
    setCompanyDepartmentId((data+'000000000000').slice(0,12))
    if(!department.comDepartmentId) setValue('comDepartmentId', (data+'000000000000').slice(0,12))
    for(var i=1;i<=4;i++){
      setValue('hierarchyNo', i)
      if(data.slice((i-1)*3,i*3)=='000') break 
    }
  }
  const setComDepartmentName =(data)=>{
    if(!data) {
      setCompanyDepartmentName('')
      return
    }
    setCompanyDepartmentName(data)
  }
  //ダイアログ開くボタン処理
  const companyOpen = () => {
    setCompanyOpenDialog(true);
  }
  //ダイアログ閉じるボタン処理
  const handleCompanyClose = () => {
    setCompanyOpenDialog(false);
  }

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
      getHerarchyNoOptions()
      getFractionalUnitOptions()
      getFractionalType()
    }
    getOptions()
    // 初回描画時のみ実行
    // eslint-disable-next-line
  }, [])

  // 階層取得
  const getHerarchyNoOptions  = useCallback(async () => {
    const options = new Array()
    for(var i = 1; i <= 4; i++) {
        options.push(<option value={i} key={i}>{'第'+i+'階層'}</option>)
    }
    setHerarchyNoOptions(options)
  }, [setFractionalTypeOptions])

  // 端数単位取得
  const getFractionalUnitOptions = useCallback(async () => {
    const optionsValue = new Array(1, 5, 10, 15, 20, 25, 30)
    const options = new Array()
    optionsValue.forEach(item => {
      options.push(<option value={item} key={item}>{item+'分'}</option>)
    });
    setFractionalUnitOptions(options)
  }, [setFractionalUnitOptions])

  // 端数区分取得
  const getFractionalType = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/fractionalType')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setFractionalTypeOptions(options)
    }
  }, [setFractionalTypeOptions, alert])
  
  // 階層リストの変更時
  useEffect(() => {
    if (department === null) {
        return
    }
    setValue('hierarchyNo', department.hierarchyNo)

  }, [hierarchyNoOptions])
  
  // 端数単位リストの変更時
  useEffect(() => {
    if (department === null) {
        return
    }
    setValue('attendanceFractionUnit', department.attendanceFractionUnit)

  }, [fractionalUnitOptions])
  
  // 端数区分リストの変更時
  useEffect(() => {
    if (department === null) {
        return
    }
    setValue('payFractionDivision', department.payFractionDivision)
    setValue('billFractionDivision', department.billFractionDivision)

  }, [fractionalTypeOptions])

  // 部門情報切り替え時
  useEffect(() => {
    if (department === null) {
      return
    }

    if (department.comDepartmentId === undefined) {
        setActionType('POST')
        reset()
        setIsDeleted(false)
    } else {
      setIsDeleted(department.delFlag)
      setValue('comDepartmentId', department.comDepartmentId)
      setValue('companyId', department.companyId)
      setValue('hierarchyNo', department.hierarchyNo)
      setValue('comDepartmentName', department.comDepartmentName)
      setValue('comDepartmentKana', department.comDepartmentKana)
      setValue('zipCode', department.zipCode)
      setValue('address1', department.address1)
      setValue('address2', department.address2)
      setValue('address3', department.address3)
      setValue('address4', department.address4)
      setValue('phoneNum', department.phoneNum)
      setValue('faxNum', department.faxNum)
      setValue('webUrl', department.webUrl)
      setValue('cooperationId', department.cooperationId)

      // 部門別計算条件
      setValue('departmentDivision', department.departmentDivision)
      setValue('attendanceFractionUnit', department.attendanceFractionUnit)
      setValue('attendanceBaseTime', format48Time(secToTime(department.attendanceBaseTime)))
      setValue('payFractionDivision', department.payFractionDivision)
      setValue('billFractionDivision', department.billFractionDivision)

      setValue('updatedAt', department.updatedAt)
      setValue('updatedId', department.updatedId)
      setValue('calcUpdatedAt', department.calcUpdatedAt)
      //上位階層作成
      setCompanyId(department.companyId)
      setCompanyName(comGetValues('companyName'))
      if(department.hierarchyNo!=1){  
        var searchId = department.comDepartmentId.substring(0, 3 * (department.hierarchyNo - 1))
        searchId = (searchId+'000000000000').slice(0,12)
        departmentList.forEach((department)=>{
          if(department.comDepartmentId==searchId){
            setCompanyDepartmentName(department.comDepartmentName)
            setCompanyDepartmentId(searchId)
            setIsSucess(!department.delFlag)
          }
        })
      }else{
        setIsSucess(true)
      }
      if(department.comDepartmentId ==null) 
      {
        setActionType('POST')
      }
      else {
        setActionType('PUT')
      }
    }
  // 部門情報の変更検知時のみ実行
  // eslint-disable-next-line
  }, [department])

  // 郵便番号による自動補完
  const [zipCode, setZipCode] = useState(null)
  const { region, locality, street } = useYubinbango(zipCode)

  useEffect(() => {
    setValue('address1', region, true)
    setValue('address2', locality, true)
    setValue('address3', street, true)
  // 住所情報の変更検知時のみ実行する
  // eslint-disable-next-line
  }, [region, locality, street])

  const onChangePostalCode = useCallback(e => {
    e.currentTarget.value = formatZipCode(e.currentTarget.value)

    setZipCode(e.currentTarget.value)
  }, [setZipCode])
  
  const onChangePhoneNum = useCallback(e => {
    e.currentTarget.value = formatPhoneNum(e.currentTarget.value)
  })

  const onBlurTime = ( event ) => {
    let value = event.target.value
    value = format48Time(value)
    event.target.value = value
  }

  return (
    <MasterDetailWrap isView={true}>
    <YubinbangoScript />
    <div className={classes.root}>
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.typography}>基本情報</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <MasterDetailFormWrap>
        <ul>
          <li style={{ height: '15px' }}><label /><div /></li>
          {isDeleted && <li><label /><div><Alert>削除データ</Alert></div></li>}
          <li>
              <label>階層</label>
              <div>
                <SelectGroup
                  name='hierarchyNo'
                  width='10rem'
                  margin='0'
                  options={hierarchyNoOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={true}
                />
              </div>
          </li>
          <li>
              <label>所属</label>
              <div>
              <InputGroup
                width='20rem'
                margin='0'
                placeholder=''
                name='comDepartmentUpperName'
                value={companyDepartmentName}
                register={register}
                rule={{}}
                errors={errors}
                disabled={true}
              />
              <NormalSubButton onClick={()=>companyOpen(true)}
                    name='選択'
                    width='90px'
                    margin='0 0 0 16px'
              ></NormalSubButton>
              </div>
          </li>
          <li>
            <label>{department.comDepartmentId?'部門ID':'所属部門ID'}</label>
            <div>
              <InputGroup
                width='10rem'
                margin='12px 0 12px 0'
                placeholder='自動採番'
                name='comDepartmentId'
                register={register}
                rule={{
                  maxLength: regex.maxLength(10),
                  pattern: regex.halfWidthAlphaNumeric
                }}
                errors={errors}
                disabled={true}
              />
            </div>
          </li>
          <li>
            <label className="required">部門名</label>
            <div>
              <InputGroup
                width='20rem'
                margin='12px 0 12px 0'
                placeholder='名称'
                name='comDepartmentName'
                register={register}
                rule={{
                  required: regex.required,
                  maxLength: regex.maxLength(50)
                }}
                errors={errors}
                disabled={isDeleted || !canWrite}
              />
              <InputGroup
                width='20rem'
                margin='12px 0 12px 16px'
                placeholder='名称(カナ)'
                name='comDepartmentKana'
                register={register}
                rule={{
                  maxLength: regex.maxLength(50),
                  pattern: regex.kana
                }}
                errors={errors}
                disabled={isDeleted || !canWrite}
              />
            </div>
          </li>
        </ul>
      </MasterDetailFormWrap>
      </AccordionDetails>
    </Accordion>
    </div>

    <div className={classes.root}>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.typography}>連絡情報</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <MasterDetailFormWrap>
        <ul>
          <li>
            <label>住所</label>
            <div>
              <InputGroup
                width='10rem'
                margin='12px 0 12px 0'
                placeholder='郵便番号(例:550-0013)'
                name='zipCode'
                register={register}
                onChange={onChangePostalCode}
                rule={{
                  maxLength: regex.maxLength(8)
                }}
                errors={errors}
                disabled={isDeleted || !canWrite}
              />
            </div>
          </li>
          <li>
            <label />
            <div>
              <InputGroup
                width='10rem'
                margin='12px 0 12px 0'
                placeholder='都道府県(例:大阪府)'
                name='address1'
                register={register}
                rule={{
                  maxLength: regex.maxLength(5)
                }}
                errors={errors}
                disabled={isDeleted || !canWrite}
              />
            </div>
          </li>
          <li>
            <label />
            <div>
              <InputGroup
                width='40rem'
                margin='12px 0 12px 0'
                placeholder='地区町村(例:大阪市西区)'
                name='address2'
                register={register}
                rule={{
                  maxLength: regex.maxLength(20)
                }}
                errors={errors}
                disabled={isDeleted || !canWrite}
              />
            </div>
          </li>
          <li>
            <label />
            <div>
              <InputGroup
                width='40rem'
                margin='12px 0 12px 0'
                placeholder='町域名(例:新町1丁目4番26号)'
                name='address3'
                register={register}
                rule={{
                  maxLength: regex.maxLength(50)
                }}
                errors={errors}
                disabled={isDeleted || !canWrite}
              />
            </div>
          </li>
          <li>
            <label />
            <div>
              <InputGroup
                width='40rem'
                margin='12px 0 12px 0'
                placeholder='建物名(例:四ツ橋グランドビル2階)'
                name='address4'
                register={register}
                rule={{
                  maxLength: regex.maxLength(50)
                }}
                errors={errors}
                disabled={isDeleted || !canWrite}
              />
            </div>
          </li>
          <li>
            <label>電話番号</label>
            <div>
              <InputGroup
                width='20rem'
                margin='12px 0 12px 0'
                placeholder='電話番号'
                name='phoneNum'
                register={register}
                onChange={onChangePhoneNum}
                rule={{
                  maxLength: regex.maxLength(20),
                  pattern: regex.halfNumericHyphen
                }}
                errors={errors}
                disabled={isDeleted || !canWrite}
              />
            </div>
          </li>
          <li>
            <label>FAX番号</label>
            <div>
              <InputGroup
                width='20rem'
                margin='12px 0 12px 0'
                placeholder='FAX番号'
                name='faxNum'
                register={register}
                onChange={onChangePhoneNum}
                rule={{
                  maxLength: regex.maxLength(20),
                  pattern: regex.halfNumericHyphen
                }}
                errors={errors}
                disabled={isDeleted || !canWrite}
              />
            </div>
          </li>
          <li style={{ height: '15px' }}><label /><div /></li>
        </ul>
      </MasterDetailFormWrap>
      </AccordionDetails>
    </Accordion>
    </div>

    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>売上算出情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li style={{ height: '15px' }}><label /><div /></li>
            <li>
              <label>勤怠時間端数単位</label>
              <div>
                <SelectGroup
                  name='attendanceFractionUnit'
                  width='10rem'
                  margin='0'
                  options={fractionalUnitOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            
            <li>
              <label>基準内時間</label>
              <div>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='attendanceBaseTime'
                  register={register}
                  rule={{
                    required: regex.halfWidthNumber,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>支給端数計算区分</label>
              <div>
                <SelectGroup
                  name='payFractionDivision'
                  width='10rem'
                  margin='0'
                  options={fractionalTypeOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>請求端数計算区分</label>
              <div>
                <SelectGroup
                  name='billFractionDivision'
                  width='10rem'
                  margin='0'
                  options={fractionalTypeOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
    </div>

    <div className={classes.root}>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.typography}>他システム連携情報</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <MasterDetailFormWrap>
        <ul>
          <li style={{ height: '15px' }}><label /><div /></li>
          <li>
            <label>連携ID</label>
            <div>
              <InputGroup
                width='10rem'
                margin='12px 0 12px 16px'
                placeholder='連携用ID'
                name='cooperationId'
                register={register}
                rule={{
                  maxLength: regex.maxLength(10),
                  pattern: regex.halfWidthAlphaNumeric
                }}
                errors={errors}
                disabled={isDeleted || !canWrite}
              />
            </div>
          </li>
        </ul>
      </MasterDetailFormWrap>
      </AccordionDetails>
    </Accordion>
    </div>

    <HiddenInput name='departmentDivision' register={register} />
    <HiddenInput name='companyId' register={register} />
    <HiddenInput name='updatedId' register={register} />
    <HiddenInput name='updatedAt' register={register} />
    <HiddenInput name='calcUpdatedAt' register={register} />
    <SearchDepartmentDialog
        open={canCompanyOpenDialog}
        onCloseFunc={handleCompanyClose}
        postName={companyName}
        postId={companyId}
        setSearchDepartmentId={(data)=>setComDepartmentId(data)}
        searchDepartmentId={companyDepartmentId}
        setDepartmentName={(data)=>setComDepartmentName(data)}
        postType={0}
        departmentList={departmentList}
        disabled={department.comDepartmentId}
        >
    </SearchDepartmentDialog>
    </MasterDetailWrap>
  )
}

DepartmentMasterForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  reset: PropTypes.func,
  department: PropTypes.object,
  watch: PropTypes.func,
  canWrite: PropTypes.bool,
  isDeleted: PropTypes.bool,
  actionType: PropTypes.string,
  setActionType: PropTypes.func,
  setIsDeleted: PropTypes.func,
}

export default DepartmentMasterForm
