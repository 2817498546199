import React from 'react'
import PropTypes from 'prop-types'

import TableRow from './BulkEditRow'

const TableBody = ({ workInfoList,dateDataList,workDivisions, Reserch,setOpenDialog,selectFunction, updatedShiftFunc, defaultUrl,setTargetId }) => {

  return (
    <tbody>
      {workInfoList && workInfoList.map((staff) => {
        return (
          <TableRow key={staff.staffId+' '+staff.dateValue}
            workInfoList={staff}
            dateDataList={dateDataList}
            workDivisions={workDivisions}
            updatedShiftFunc={Reserch}
          />
        )
      })}
    </tbody>
  )
}

TableBody.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
  dateDataList: PropTypes.array,
}

export default TableBody