import React, { useState } from 'react'
import PropTypes from 'prop-types'

import CalendarTableWrap from '../Wrap/TableWrap'
import CalendarTableHeader from './AliveMonitoringTableHeader'
import CalendarTableBody from './AliveMonitoringTableBody'

const CalendarTable = ({ 
  register, getValues, setValue, watch, reset, errors, 
  dateDataList, updatedDateFunc, defaultUrl }) => {
  return (
    <CalendarTableWrap visible={dateDataList.length ? true : false}>
      <CalendarTableHeader
      >
      </CalendarTableHeader>
      <CalendarTableBody
        register={register}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        reset={reset}
        errors={errors}
        
        dateDataList={dateDataList}
        updatedDateFunc={updatedDateFunc}
        defaultUrl={defaultUrl}
      >
      </CalendarTableBody>
    </CalendarTableWrap>
  )
}

CalendarTable.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  dateDataList: PropTypes.array,
  updatedDateFunc: PropTypes.func,
  defaultUrl: PropTypes.string
}

export default CalendarTable
