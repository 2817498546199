import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
//import Tooltip from '@material-ui/core/Tooltip'

import { HiddenInput } from '../../../../Common/CommonInputGroup'
import chechkedIcon from '../../../../../image/expand-icon.png'
import {dateFormat} from '../../../../Common/Utilties/LaborUtility'
export const ColumnFunctionTD = styled.td`
  width: 25rem;
  height: 3rem;
  vertical-align:middle;
  padding: 0.25rem;
`
export const ColumnStatusTD = styled.td`
  width: 10rem;
  height: 2rem;
  vertical-align:middle;
`
const FunctionTableRow = ({ 
    register, getValues, setValue, watch, reset, errors, 
    functionItem,index, selectFunction,isDeleted
     }) => {

  const [checkedRead,setCheckedRead] = useState(false)
  const [checkedWrite,setCheckedWrite] = useState(false)

  // 勤怠情報切り替え時
  useEffect(() => {
    // 勤怠情報が変更された場合のみ実行

    if (functionItem) {
      setValue('functionItems['+index+'].functionMenuId', functionItem.functionMenuId)
      setValue('functionItems['+index+'].functionMenuName', functionItem.functionMenuName)
      setValue('functionItems['+index+'].readGrantDivision', functionItem.readGrantDivision==null?0: functionItem.readGrantDivision)
      setValue('functionItems['+index+'].writeGrantDivision', functionItem.writeGrantDivision==null?0:functionItem.writeGrantDivision)

      setCheckedRead(functionItem.readGrantDivision)
      setCheckedWrite(functionItem.writeGrantDivision)
    }
  // eslint-disable-next-line
  }, [functionItem])

  const onChange=(index,value,type)=>{
    console.log(index,value,type)
    if(type==0){
      setValue('functionItems['+index+'].readGrantDivision',value)
      setCheckedRead(value)
    }else{
      setValue('functionItems['+index+'].writeGrantDivision', value)
      setCheckedWrite(value)
    }

  }

  return (
  <tr key={functionItem.functionMenuId}>
    <ColumnFunctionTD name={'functionItems['+index+'].functionMenuName'} >{functionItem.functionMenuName}</ColumnFunctionTD>
    <ColumnStatusTD>
      <button type='button' onClick={()=>onChange(index,0,0)} style={{width:'9.5rem',height:'2.5rem',marginLeft:'0.25rem'}} disabled={isDeleted}>
        {!checkedRead && <img src={chechkedIcon} width="36px" height="24px" />}
      </button>
    </ColumnStatusTD>
    <ColumnStatusTD></ColumnStatusTD>
    <ColumnStatusTD>
      <button type='button' onClick={()=>onChange(index,2,0)} style={{width:'9.5rem',height:'2.5rem',marginLeft:'0.25rem'}} disabled={isDeleted}>
        {checkedRead==2 && <img src={chechkedIcon} width="36px" height="24px" />}
      </button>
    </ColumnStatusTD>
    <ColumnStatusTD>
      <button type='button' onClick={()=>onChange(index,0,1)}  style={{width:'9.5rem',height:'2.5rem',marginLeft:'0.25rem'}} disabled={isDeleted}>
        {!checkedWrite && <img src={chechkedIcon} width="36px" height="24px" />}
      </button>
    </ColumnStatusTD>
    <ColumnStatusTD></ColumnStatusTD>
    <ColumnStatusTD>
      <button type='button' onClick={()=>onChange(index,2,1)} style={{width:'9.5rem',height:'2.5rem',marginLeft:'0.25rem'}} disabled={isDeleted}>
        {checkedWrite ==2 && <img src={chechkedIcon} width="36px" height="24px" />}
      </button>
    </ColumnStatusTD>
    <HiddenInput name={'functionItems['+index+'].functionMenuId'} register={register} defaultValue={functionItem.functionMenuId}></HiddenInput>
    <HiddenInput name={'functionItems['+index+'].readGrantDivision'} register={register} defaultValue={functionItem.readGrantDivision==null?0: functionItem.readGrantDivision}></HiddenInput>
    <HiddenInput name={'functionItems['+index+'].writeGrantDivision'} register={register} defaultValue={functionItem.writeGrantDivision==null?0:functionItem.writeGrantDivision}></HiddenInput>
    <HiddenInput name={'functionItems['+index+'].updatedAt'} register={register} defaultValue={dateFormat(functionItem.updatedAt)}></HiddenInput>
  </tr>
  )
}

FunctionTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  dateData: PropTypes.object,
  selectFunction: PropTypes.func,
}

export default FunctionTableRow
