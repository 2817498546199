import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import FUNC from '../Common/FunctionEnum'
import { useForm } from 'react-hook-form'
import { GetApi } from '../Common/ApiAxios'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../Common/ErrorHandling'
import IconPageNextImg from '../../image/PageNext.png'
import IconPageBackImg from '../../image/PageBack.png'
import IconPageLastImg from '../../image/PageLast.png'
import IconPageTopImg from '../../image/PageTop.png'
import Search from './Search'
import StaffBiometricPanelWrap,{StaffBiometricFlexWrap,CountPanelWrap,StaffBiometricPageButtonWrap,StaffBiometricPagingPanelWrap,StaffBiometricPagingChangePanelWrap,PagingIconImg,PagingIconNextImg,StaffBiometricPagingIconPanelWrap} from './Wrap/StaffBiometricPanelWrap'
import StaffBiometricTable from './Molcules/StaffBiometricModel/StaffBiometricTable'
export const DefaultUrl = '/api/staffBiometricManagement/staffBiometric'

const StaffBiometric = (props) => {
  const [searchOption, setSearchOption] = useState({ staffId: '', staffName: '',postType:'',postId:'',departmentId:'',authStatus:0 })
  const { loginUserInfo } = useSelector(state => state)
  const staffIdRef = useRef()
  const staffNameRef = useRef()
  const authStatusRef= useRef(0)
  const [staffInfoList, setStaffInfoList] = useState([])
  const [staffInfoAllList, setStaffInfoAllList] = useState([])
  const [dateDataList, setDateDataList] = useState([])
  const [pagenation, setPagenation] = useState({})
  const [staffCount, setStaffCount] = useState(0)
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [viewFunction,setViewFunction] = useState(false)
  const pageRecord = 12
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()
  const dispatch = useDispatch()
  const alert = useAlert()
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.STAFF_BIOETRIC)

  // 検索APIの実行
  const onSearch = async (createId, page) => {
    dispatch(SetLoadSpinner(true))
    setSearchOption(
      {
        staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        postType:postType,
        postId:(postId!=undefined && postId != -1) ? postId:'',
        authStatus: authStatusRef.current,
        departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      }
    )
    console.log(authStatusRef.current)
    const data = {
      staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      authStatus: authStatusRef.current,
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      page: page
    }

    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      console.log(result.data.responseList)

      setPageNum(page)
      setStaffInfoAllList(result.data.responseList)
      makePage(result.data.responseList.slice(),page)
      setStaffCount(result.data.responseList.length)
      setPageLast(Math.ceil(result.data.responseList.length/pageRecord))

    }
    //setCanOpenDialog(true)
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
    setViewFunction(false);
  }

  const Reserch = async (createId, page) => {
    dispatch(SetLoadSpinner(true))
    console.log(authStatusRef)
    const data = {
      staffId: searchOption.staffId,
      staffName: searchOption.staffName,
      postType:searchOption.postType,
      postId:searchOption.postId,
      authStatus: searchOption.authStatus,
      departmentId:searchOption.departmentId,
      page: page
    }

    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      console.log(result.data.responseList)

      setPageNum(page)
      setStaffInfoAllList(result.data.responseList)
      makePage(result.data.responseList.slice(),page)
      setStaffCount(result.data.responseList.length)
      setPageLast(Math.ceil(result.data.responseList.length/pageRecord))

    }
    //setCanOpenDialog(true)
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
    setViewFunction(false);
  }
  const onReserch=()=>{
    Reserch(null,pageNum)
  }
  //ページング設定
  const makePage = (data,page) =>{
    if(page!=0){
      console.log(data)
      console.log(page)
      var minRecord = (page-1)*pageRecord
      var maxRecord = (page*pageRecord)
      console.log(minRecord,maxRecord)
      var tmpCount = 0
      var tmpList = new Array()
      data.forEach((staffWorkInfo)=>{
        if(tmpCount>=minRecord && tmpCount<maxRecord){
          tmpList.push(staffWorkInfo)
        }
        tmpCount+=1
      })
      setStaffInfoList(tmpList)
      console.log(tmpList)
    }else{
      setStaffInfoList(data)
    }
  }

  // 検索項目でキーボードの押下イベント
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }
  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
  }
  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud'
    else
      document.title = 'Core Time Cloud'
  }, [grants])

  useEffect(() => {
    setPost()
    onSearch(null,1)
  }, [])
  
  const setPost=()=>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      }
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.companyId,
          name: item.companyName+'('+item.companyId+')',
        }
      })
      setPostList(resultList)
      return true
    }
    const serachCustomer = async()=>{
      const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } 
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.customerId,
          name: item.customerName+'('+item.customerId+')',
        }
      })
      setPostList(resultList)
      return true
    }

    if(postType==0){
      serachCompany()
    }else{
      serachCustomer()
    }
  }
  //会社顧客変更処理
  useEffect(() => {
    setPost()
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postType])
  if(!postList){
    setPost()
  }
  const onPageSeletClick = (pageId) => {
    console.log(pageId)
    makePage(staffInfoAllList,pageId)
    setPageNum(pageId)
  }
  const onPageNextSeletClick = (pageId) => {
    if(pageNum!=pageLast){
      onPageSeletClick(pageId)
    }
  }
  const onPageBackSeletClick = (pageId) => {
    if(pageNum!=1){
      onPageSeletClick(pageId)
    }
  }
  const onPageChangeLabelClick = () => {
    if(pageType==0){
      setPageType(1)
      setPageNum(0)  
      makePage(staffInfoAllList,0)
  
    }else{
      setPageType(0)
      setPageNum(1)
      makePage(staffInfoAllList,1)
    }
    setViewFunction(false);
  }
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }
  if (!grants) return null
  return (
    <StaffBiometricPanelWrap id = 'StaffBiometricWrap'>
      <Search
          title= {grants.functionMenuName}
          showButton
          onClick={() => onSearch(null, pageType==0?1:0)}
          onClickOption={(e) => onClickOptionBtn(e)}
          onKeyDown={(e) => onKeyDown(e)}
          authStatus = {authStatusRef}
          staffIdRef={staffIdRef}
          staffNameRef={staffNameRef}
          viewSubSearch={viewSubSearch}
          grants={grants}
          postList={postList}
          postId={postId}
          setPostId={setPostId}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          postType={postType}
          setPostType={setPostType}
          onClickFunction={()=>onClickFunction()}
          viewFunction={viewFunction}
          onPageTypeChangeClick={() => onPageChangeLabelClick()}
          firstSearch={true}
        >
        </Search>
        <CountPanelWrap>
          <label>検索ヒット件数:{staffCount}件</label>
        </CountPanelWrap>
        <StaffBiometricFlexWrap>
          <StaffBiometricTable
            workInfoList={staffInfoList}
            defaultUrl={DefaultUrl}
            Reserch={()=>onReserch()}
          >
          </StaffBiometricTable>
        </StaffBiometricFlexWrap>
        {
          staffInfoList.length>0 &&
          <StaffBiometricPagingPanelWrap>
          {/* <StaffBiometricPagingChangePanelWrap onClick={() => onPageChangeLabelClick()}>表示切替</StaffBiometricPagingChangePanelWrap> */}
          { pageType==0 &&
            <StaffBiometricPageButtonWrap>       
            <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
            <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
            <StaffBiometricPagingIconPanelWrap>{pageNum}</StaffBiometricPagingIconPanelWrap>
            <StaffBiometricPagingIconPanelWrap>/</StaffBiometricPagingIconPanelWrap>
            <StaffBiometricPagingIconPanelWrap>{pageLast}</StaffBiometricPagingIconPanelWrap>
            <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
            <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
            
            </StaffBiometricPageButtonWrap>
          }
          </StaffBiometricPagingPanelWrap>
        }
    </StaffBiometricPanelWrap>
  )
}

export default StaffBiometric
