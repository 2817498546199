import React, { useEffect, useState, useCallback,PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReTooltip from '@material-ui/core/Tooltip'
import {LineChart, CartesianGrid, XAxis,LabelList ,YAxis, Tooltip, Legend, Line,BarChart, Bar,ComposedChart } from "recharts"

import {DashboardBaseFormRow,DashboardTitle} from '../../../Wrap/DashboardBaseWrap'

const renderColorfulLegendText = (value) => {
  return <span style={{ color:'black'}}>{value}</span>
}

const BarGraph= ({ staffList, title }) => {
        
  return (
    <DashboardBaseFormRow>
    <div>
    <DashboardTitle>{title}</DashboardTitle>
    <ComposedChart 
      width={930}
      height={350}
    data={staffList}
    margin={{
        top: 10, right: 30, left: 0, bottom: 5,
    }}
    layout="vertical"
    barCategoryGap='20%'> 
    <YAxis yAxisId={1} dataKey="xTitle" type="category"  width={100} />
    <XAxis xAxisId={1} domain={[0, dataMax => (dataMax+(5 - dataMax%5))]} tickCount={6} type="number"/>
    <Tooltip/>
    <Legend formatter={renderColorfulLegendText}/>
    <Bar name="男性" xAxisId={1} yAxisId={1} stackId="1" dataKey="male" fill="aqua" />
    <Bar name="女性" xAxisId={1} yAxisId={1} stackId="1" dataKey="female" fill="red" />
    <Bar name="不明" xAxisId={1} yAxisId={1} stackId="1" dataKey="unknown" fill="lime" />
    </ComposedChart >
    </div>
    </DashboardBaseFormRow>
    )
  }

BarGraph.propTypes = {
  staffList: PropTypes.array,
  title: PropTypes.string,
}

export default BarGraph