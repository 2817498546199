import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CellWrap = styled.div`
  width: 5rem;
  vertical-align:middle;
  ${props => props.addStyle}
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
`

const Column = styled.td`
  text-align: right;
  padding-right: 1rem;
  height:20px;
`

const MonthTableTimeCell = ({ shiftWorkTime, attendanceWorkTime }) => {

  return (
    <CellWrap>
      <CellPanel>
        <Column>{shiftWorkTime}</Column>
        <Column>{attendanceWorkTime}</Column>
      </CellPanel>
    </CellWrap>
  )
}

MonthTableTimeCell.propTypes = {
    shiftWorkTime: PropTypes.string,
    attendanceWorkTime: PropTypes.string
}

export default MonthTableTimeCell
