import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import * as regex from '../../Common/Regex'
import { InputGroup, SelectGroup, HiddenInput } from '../../Common/CommonInputGroup'
import NormalSubButton from '../../Common/Atoms/NormalSubButton'
import { Alert } from '../../Common/CommonStyled'
import { YubinbangoScript } from '../../Common/Atoms/ScriptTag'

import MasterDetailWrap, { MasterDetailFormWrap } from '../Wrap/MasterDetailWrap'
import {dateFormat} from '../../Common/Utilties/LaborUtility'

import FunctionTable  from './Molcules/FunctionModal/FunctionTable'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '75vw',
  },
  typography: {
    width: '72vw',
    fontSize: '18px',
    fontWeight: '700',
    color: '#ffffff',
    verticalAlign: 'middle',
    margin: '0',
    padding: '16px',
    background: '#4169E1',
  },
}))

const useTreeStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '70vw',
  },
}))


export const GrantMasterForm = ({
  register, errors, setValue, getValues, grantDetail,
  watch, canWrite, loginUserInfo, isDeleted, actionType,
  isView, reSerach
}) => {
  const classes = useStyles()
  const alert = useAlert()
  const [checkedRead,setCheckedRead] = useState(false)
  const [checkedWrite,setCheckedWrite] = useState(false)

  // 顧客情報切り替え時
  useEffect(() => {
    console.log(grantDetail)
    if (grantDetail != null) {
      setValue('grantNameId', grantDetail.id)
      setValue('grantName', grantDetail.name)
      var checkedReadList = []
      var checkedWriteList = []
      grantDetail.functionItems.map((functionItem,index) => {
        setValue('functionItems['+index+'].functionMenuId', functionItem.functionMenuId)
        setValue('functionItems['+index+'].functionMenuName', functionItem.functionMenuName)
        setValue('functionItems['+index+'].readGrantDivision', functionItem.readGrantDivision==null?0: functionItem.readGrantDivision)
        setValue('functionItems['+index+'].writeGrantDivision', functionItem.writeGrantDivision==null?0:functionItem.writeGrantDivision)
        checkedReadList[index]=(functionItem.readGrantDivision)
        checkedWriteList[index]=(functionItem.writeGrantDivision)
      })
      setCheckedRead(checkedReadList)
      setCheckedWrite(checkedWriteList)
      setValue('updatedAt', dateFormat(grantDetail.updatedAt))
    }
  // 顧客情報が変更された場合のみ実行
  // eslint-disable-next-line
  }, [grantDetail])

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
    }
    getOptions()
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])


  if (grantDetail === null) return null // 未選択時の場合は非表示
  return (
    <MasterDetailWrap isView={isView}>
      <YubinbangoScript />
      <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>基本情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li style={{ height: '15px' }}><label /><div /></li>
            {isDeleted && <li><label /><div><Alert>削除データ</Alert></div></li>}
            <li>
              <label>ID</label>
              <div>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 0'
                  placeholder='未入力時は自動採番'
                  name='grantNameId'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(10),
                    pattern: regex.halfWidthNumber
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite || actionType === 'PUT'}
                />
              </div>
            </li>
            <li>
              <label className="required">名称</label>
              <div>
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 0'
                  placeholder='名称'
                  name='grantName'
                  register={register}
                  rule={{
                    required: regex.required,
                    maxLength: regex.maxLength(50)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>
      <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>機能別権限情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FunctionTable
              register={register}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              errors={errors}
              isDeleted={isDeleted}
              grantDetail={grantDetail}
          />
        </AccordionDetails>
      </Accordion>
      </div>
      <HiddenInput name='updatedId' register={register} />
      <HiddenInput name='updatedAt' register={register} />
    </MasterDetailWrap>
  )
}


GrantMasterForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  customerDetail: PropTypes.object,
  watch: PropTypes.func,
  canWrite: PropTypes.bool,
  isDeleted: PropTypes.bool,
  actionType: PropTypes.string,
  isView: PropTypes.bool,
  reSerach: PropTypes.func,
}

export default GrantMasterForm
