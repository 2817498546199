import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import  { ColumnTD ,ColumnNameTD,ColumnDivisionTD} from '../../../Wrap/MasterDialogWrap'
import Button from '../../../../Common/Atoms/NormalButtonAttendance'

const DailyTableRow = ({ 
    register, getValues, setValue, watch, reset, errors, workInfo,onSelect }) => {

  useEffect(() => {
    if (workInfo) {
      console.log(workInfo)
      setValue('staffDivision', workInfo.staffDivision)
      //初期値設定
    }

  }, [workInfo])

  const createElementDate = (date) => {
    if(!date) return ''
    return date.replaceAll('-','/')
  }

  const onClick=()=>{
    onSelect(workInfo.orderId,workInfo.contractDivision)
  }
  return (
    <tr>
      <ColumnTD>{workInfo.orderId}</ColumnTD>
      <ColumnTD>{workInfo.contractDivision==1?'派遣':'請負'}</ColumnTD>
      <ColumnNameTD>{workInfo.comDepartmentName}</ColumnNameTD>
      <ColumnNameTD>{workInfo.cusDepartmentName}</ColumnNameTD>
      <ColumnTD>{createElementDate(workInfo.agreementStartDt)}</ColumnTD>
      <ColumnTD>{createElementDate(workInfo.agreementEndDt)}</ColumnTD>
      <ColumnTD>{(workInfo.agreementCt)?workInfo.agreementCt:0}</ColumnTD>
      <ColumnTD><Button width='4rem' margin='auto' name='選択' padding='0px' type='button' onClick={()=>onClick()}>選択</Button></ColumnTD>
    </tr>
  )
}

DailyTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  workDivisions: PropTypes.any,
  dateDataList: PropTypes.array,
  dailyWorkInfo: PropTypes.object,
  selectFunction: PropTypes.func,

}

export default DailyTableRow
