import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
//import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { InputGroup, HiddenInput } from '../../../Common/CommonInputGroup'


import Tooltip from '@material-ui/core/Tooltip'

import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'

//import Input from '../../Common/Atoms/Input'
//import Select from '../../Common/Atoms/SelectAddValue'
import Button from '../../../Common/Atoms/NormalButton'

//import { GetApi } from '../../Common/ApiAxios'
//import ErrorHandling from '../../Common/ErrorHandling'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 25rem;
  label{
    width: 4rem;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.05em;
    background: #0036ff;
    height: 36px;
    border-radius: 5px;
    border: none;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    color: white;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
    &:hover{
        background: #0026b3;
    }
  }

  input[type="file"] {
    display: none;
  }

  text{
    width:20rem;
    margin: 10px 10px 0 0; 
  }
`
const FormWrap = styled.form`
  display: flex;
  width: 30rem;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`
const TitleWrap = styled.div`
    font-size: 12px;
    border-collapse:separate;
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
    height: 2rem;
    background-color: #649cdf;
    vertical-align:middle;
    padding: 0.5rem;
`

export const IconImg = styled.img`
  align-items: left;
  width: 20px;
  height: 20px;
`
export const NotIconImg = styled.div`
  align-items: left;
  width: 20px;
  height: 20px;
`

const SearchDepartmentDialog = ({ open,onCloseFunc,onUpload}) => {
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();

  useEffect(() => {
  }, [])
  const alert = useAlert()

  const onClick=()=>{
    var id = getValues("deviceId")
    var location1 = getValues("location1")
    var location2 = getValues("location2")
    console.log(id,location1,location2)
    if(id==''){
      alert.error("IDを入力してください")
      return
    }
    if(location1==''){
      alert.error("現場名を入力してください")
      return
    }
    if(location2==''){
      alert.error("設置場所を入力してください")
      return
    }
    onUpload(id,location1,location2)  
  }

  const createElementTitle = (Name) => {
    if (Name) {
      return <>
          <InputGroup
              name={Name}
              width='25rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'left', paddingRight: '0.5rem' }}
              disabled={false}  
          />
         </>
    }

    return <></>
  }

  return (
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='アップロード'
      aria-describedby='アップロードダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap>
    <div className={classes.paper}>
      <TitleWrap>デバイスID</TitleWrap>
      <ContentWrap>{createElementTitle('deviceId')}</ContentWrap>
      <hr/>
      <TitleWrap>現場名</TitleWrap>
      <ContentWrap>{createElementTitle('location1')}</ContentWrap>
      <hr/>
      <TitleWrap>設置場所</TitleWrap>
      <ContentWrap>{createElementTitle('location2')}</ContentWrap>
      <ButtonsWrap>
        <Button type='button'name='追加' padding='0px' onClick={()=>onClick()} />
        <Button name='閉じる' padding='0px' onClick={onCloseFunc} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

SearchDepartmentDialog.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedAttendanceFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    iconName: PropTypes.string,
    text: PropTypes.string,
    dailyWorkInfoList: PropTypes.array
}

export default SearchDepartmentDialog