/* eslint-env browser */
import React, { useState, useCallback, useEffect } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import CalenderIcon from '../../../image/svg/CalenderIcon.js'

registerLocale('ja', ja)

// 共通検索用
const WrapDiv = styled.div`
  position: relative;
  margin: ${props => props.margin || '0 16px'};
  .react-datepicker-popper{
    z-index: 2;
  }
  .react-datepicker__current-month {
    display: none;
  }
  .react-datepicker__month-select, .react-datepicker__year-select {
    border: none;
    background: #eee;
    height: 20px;
    line-height: 20px;
  }

  .normalCalendar {
    height: 32px;
    display: ${props => props.display || 'block'};
    position: relative;
    text-indent: 16px;
    width: ${props => props.width || '160px'};
    min-width: ${props => props.width || '160px'};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.05em;
    background: ${props => props.disabled ? '#eee' : '#fff'};
    border-radius: 5px;
    border: none;
    line-height: 16px;
    box-shadow: inset 0 3px 5px 0 rgba(0,0,0,0.35);
    color: #333;
    z-index: 1;
    &:focus {
      background: #fff;
      outline: none;
      border: 2px solid #0036ff;
      box-sizing: border-box;
      text-indent: 14px;
    }
    ::placeholder {
      color: #ccc;
    }
  }
  svg {
    position: absolute;
    top: 5px;
    right: 4px;
    pointer-events: none;
    z-index:2;
  }
  
  ::after{
    display: ${props => props.isViewIcon ? 'block' : 'none'};
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    background: #0036ff;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    pointer-events: none;
    z-index:1;
  }
`

const RangeCalendar = ({ value, onChange, placeholderStart,placeholderEnd, width, margin, disabled, display, isViewIcon }) => {
  const [fromDate, setFromDate] = useState(value?.fromDate || new Date(value?.fromDate))
  const [toDate, setToDate] = useState(value?.toDate || new Date(value?.toDate))

  const onChangeFromDate = useCallback(date => {
    setFromDate(date)

    const fromDt = new Date(date)
    const toDt = new Date(toDate)

    const fromStr = fromDt.getFullYear() + '-' + ('00' + (fromDt.getMonth() + 1)).slice(-2) + '-' + ('00' + fromDt.getDate()).slice(-2)
    const toStr = toDt.getFullYear() + '-' + ('00' + (toDt.getMonth() + 1)).slice(-2) + '-' + ('00' + toDt.getDate()).slice(-2)

    typeof onChange === 'function' && onChange(fromStr, toStr)
  }, [toDate, onChange])

  const onChangeToDate = useCallback(date => {
    setToDate(date)

    const fromDt = new Date(fromDate)
    const toDt = new Date(date)

    const fromStr = fromDt.getFullYear() + '-' + ('00' + (fromDt.getMonth() + 1)).slice(-2) + '-' + ('00' + fromDt.getDate()).slice(-2)
    const toStr = toDt.getFullYear() + '-' + ('00' + (toDt.getMonth() + 1)).slice(-2) + '-' + ('00' + toDt.getDate()).slice(-2)

    typeof onChange === 'function' && onChange(fromStr, toStr)
  }, [fromDate, onChange])

  useEffect(() => {
    setFromDate(value?.fromDate && new Date(value?.fromDate))
    setToDate(value?.toDate && new Date(value?.toDate))
  // eslint-disable-next-line
  }, [value])

  return (
    <>
      <WrapDiv margin={margin} width={width} display={display} isViewIcon={isViewIcon} disabled={disabled}>
        <DatePicker
          className='normalCalendar'
          dateFormat='yyyy/MM/dd'
          locale='ja'
          selected={fromDate}
          onChange={onChangeFromDate}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          disabled={disabled}
          placeholderText={placeholderStart}
        />
        {isViewIcon && <CalenderIcon />}
      </WrapDiv>
      <label>～</label>
      <WrapDiv margin={margin} width={width} display={display} isViewIcon={isViewIcon} disabled={disabled}>
        <DatePicker
          className='normalCalendar'
          dateFormat='yyyy/MM/dd'
          locale='ja'
          selected={toDate}
          onChange={onChangeToDate}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          disabled={disabled}
          placeholderText={placeholderEnd}
        />
        {isViewIcon && <CalenderIcon />}
      </WrapDiv>
    </>
  )
}

RangeCalendar.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  placeholderStart: PropTypes.string,
  placeholderEnd: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  display: PropTypes.string,
  isViewIcon: PropTypes.bool
}

export default RangeCalendar
