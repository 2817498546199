import React from 'react'
import PropTypes from 'prop-types'

import { ColumnDateTH, ColumnStatusTH, ColumnIdTH, ColumnLocationTH } from '../Wrap/TableWrap'

const CalendarTableHeader = ({  }) => {

  return (
    <thead>
      <tr>
        <ColumnIdTH>デバイスID</ColumnIdTH>
        <ColumnStatusTH>ステータス</ColumnStatusTH>
        <ColumnDateTH>最終通知日時</ColumnDateTH>
        <ColumnLocationTH>現場名</ColumnLocationTH>
        <ColumnLocationTH>設置場所</ColumnLocationTH>
      </tr>
    </thead>
  )
}

CalendarTableHeader.propTypes = {
}

export default CalendarTableHeader
