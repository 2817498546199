import React, { useState, useEffect, useRef,useCallback } from 'react'
import { useLocation } from "react-router";
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import FUNC from '../Common/FunctionEnum'

import { SalariedFlexWrap,ShiftPagingIconPanelWrap,PagingIconImg ,CountPanelWrap,PagingIconNextImg,ShiftPagingPanelWrap,ShiftPageButtonWrap} from './Wrap/ShiftGanttChartPanelWrap'
import { dateFormat,ValidUpdateShift,calcWorkingRestFreeSeconds} from '../Common/Utilties/LaborUtility'

import SalariedTable from './Molcules/ShiftGanttChartTable/ShiftGanttChartMonthTable'
import { GetApi ,MultipartApi} from '../Common/ApiAxios'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../Common/ErrorHandling'
import SalariedPanelWrap from './Wrap/ShiftGanttChartPanelWrap'
import Search from './Search'

import IconPageNextImg from '../../image/PageNext.png'
import IconPageBackImg from '../../image/PageBack.png'
import IconPageLastImg from '../../image/PageLast.png'
import IconPageTopImg from '../../image/PageTop.png'

export const DefaultUrl = '/api/shiftGanttChartManagement/shiftGanttChart'

const Salaried = (props) => {
  
  const staffIdRef = useRef()
  const staffNameRef = useRef()
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()
  const [searchOption, setSearchOption] = useState({ shiftDt: '', staffId: '', staffName: '',postType:'',postId:'',departmentId:'' })
  const { loginUserInfo } = useSelector(state => state)
  const location = useLocation();
  const salariedDateRef = useRef()

  const [viewFunction,setViewFunction] = useState(false)
  const [staffWorkInfoList, setStaffWorkInfoList] = useState([])
  const [staffWorkInfoAllList, setStaffWorkInfoAllList] = useState([])
  const [dateDataList, setDateDataList] = useState([])
  const [pagenation, setPagenation] = useState({})
  const [staffCount, setStaffCount] = useState(0)
  const dispatch = useDispatch()
  const alert = useAlert()
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.SHIFT_GANTCHART)
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const pageRecord = 11
  const [workDivisions,setWorkDivisions] = useState()

  //出勤区分取得
  const getWorkDivisions = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/workDivisions')

    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setWorkDivisions(options)
    }
  }, [setWorkDivisions, alert])

    // 検索APIの実行
    const onSearch = async (createId, page) => {
      dispatch(SetLoadSpinner(true))
      setSearchOption(
        {
          targetDt: salariedDateRef.current,
          staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
          staffName: (staffNameRef.current)?staffNameRef.current.value:'',
          postType:postType,
          postId:postId,
          departmentId:departmentId
        }
      )
      const data = {
        targetDt: salariedDateRef.current,
        staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        postType:postType,
        postId:(postId!=undefined && postId != -1) ? postId:'',
        departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
        page: page
      }

      await getWorkDivisions()
      const result = await GetApi(DefaultUrl, data)
      dispatch(SetLoadSpinner(false))
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else {
        //setDateDataList(resultDate.data.responseList)
        setStaffWorkInfoAllList(result.data.responseList.slice())
        var count = 0
        result.data.responseList.forEach((staffWorkInfo)=>{
          staffWorkInfo.dailyWorkInfoList.forEach((staff)=>{
            count += staff.workInfoList.length
          }) 
        })
        setStaffCount(count)
        setPageLast(Math.ceil(count/pageRecord))
        console.log(page)
        setPageNum(page)
        setPagenation(result.data)
        var tmp = result.data.responseList.slice()
        makePage(tmp,page)
      }
      //setCanOpenDialog(true)
      // サブ検索項目を非表示に変更
      setViewSubSearch(false);
      setViewFunction(false);
    }

  //ページング設定
  //ページング設定
  const makePage = (data,page) =>{
    if(page!=0){
      console.log(data)
      console.log(page)
      var minRecord = (page-1)*pageRecord
      var maxRecord = (page*pageRecord)
      var count = 0 
      var tmpStaffList = new Array()
      data.forEach((staffWorkInfo)=>{
        var tmpList = new Array()
        staffWorkInfo.dailyWorkInfoList.forEach((staff)=>{
          var recordLen = staff.workInfoList.length
          var beforeCount = count
          count += recordLen
          var start = -1
          var end = -1
          if(count>minRecord && beforeCount<maxRecord){
            if(beforeCount < minRecord){
              start = minRecord - beforeCount
            }else{
              start = 0
            }
  
            if(count < maxRecord){
              end = recordLen
            }else{
              end = maxRecord - beforeCount
            }
            
          }
  
          var tmp = new Array() 
          if(start != -1 && end != -1){
              var tmpCount = 0
              staff.workInfoList.forEach((dailyList)=>{
                if(tmpCount>=start && tmpCount<end)
                tmp.push(dailyList)
                tmpCount+=1
              }
            )
          }
          tmpList.push({workInfoList:tmp,
            staffId: staffWorkInfo.staffId,
            staffName: staffWorkInfo.staffName,
            baseDt: staff.baseDt,
          })
        }) 
        tmpStaffList.push({dailyWorkInfoList:tmpList,
          staffId: staffWorkInfo.staffId,
          staffName: staffWorkInfo.staffName,
          restFlag: staffWorkInfo.restFlag
        })
      })
      setStaffWorkInfoList(tmpStaffList)
    }else{
      setStaffWorkInfoList(data)
    }
    
  }
    const setPost=()=>{
      const serachCompany = async()=>{
        const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
        if (result.errorDetail) {
          ErrorHandling(result, alert)
          return null
        }
        const resultList = result.data.responseList.map(item => {
          return {
            index: item.companyId,
            name: item.companyName+'('+item.companyId+')',
          }
        })
        setPostList(resultList)
        return true
      }
      const serachCustomer = async()=>{
        const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
        if (result.errorDetail) {
          ErrorHandling(result, alert)
          return null
        } 
        const resultList = result.data.responseList.map(item => {
          return {
            index: item.customerId,
            name: item.customerName+'('+item.customerId+')',
          }
        })
        setPostList(resultList)
        return true
      }
  
      if(postType==0){
        serachCompany()
      }else{
        serachCustomer()
      }
    }

  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud'
    else
      document.title = 'Core Time Cloud'
  }, [grants])

  useEffect(() => {
    setPost()
    onSearch(null,1)
  }, [])
    //会社顧客変更処理
    useEffect(() => {
      setPost()
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postType])
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }

  const onPageSeletClick = (pageId) => {
    console.log(pageId)
    makePage(staffWorkInfoAllList,pageId)
    setPageNum(pageId)
  }
  const onPageNextSeletClick = (pageId) => {
    if(pageNum!=pageLast){
      onPageSeletClick(pageId)
    }
  }
  const onPageBackSeletClick = (pageId) => {
    if(pageNum!=1){
      onPageSeletClick(pageId)
    }
  }
  const onPageChangeLabelClick = () => {
    if(pageType==0){
      setPageType(1)
      setPageNum(0)  
      makePage(staffWorkInfoAllList,0)
  
    }else{
      setPageType(0)
      setPageNum(1)
      makePage(staffWorkInfoAllList,1)
    }
    setViewFunction(false);
  }
  
  // POST・PUT処理
  const onSubmit = (data) => {

    async function sendPost (requestBody) {
      var result
      dispatch(SetLoadSpinner(true))
      result = await MultipartApi(`${DefaultUrl}`, 'PUT', requestBody)
      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(result, alert)) {
        alert.success('保存しました')
        onSearch(null,1)
        //updatedShiftFunc()
      }
    }

    var dailyList= new Array();
    staffWorkInfoList.map((dailyData) => {
        dailyData.dailyWorkInfoList.map((staff) => {
          staff.workInfoList.map(workInfo => {
            if(!workInfo.isDiff) return
            console.log(workInfo)
            var restDiffFlag = false
            var restList = new Array()
            var addRestList = new Array()
            var deleteRestList = new Array()
            var updateRestList = new Array()
      
            var shiftList = workInfo.restList
            var workDivision = workInfo.shiftWorkDivision
            if(workDivision==2 || workDivision==3 || workDivision==4){
              shiftList.map((restData)=>{
                if(restData.restNum == null || restData.isDiff == undefined) return
                if(!restData.isDel){
                  restList.push(restData.restStartDt)
                  restList.push(restData.restEndDt)
                }
                if(restData.isDiff){
                  if(restData.isNew){
                    addRestList.push(restData)
                  }else if(restData.isDel){
                    deleteRestList.push(restData)
                  }else{
                    updateRestList.push(restData)
                  }
                  restDiffFlag=true
                }
              })
            }else{
              shiftList.map((restData)=>{
                if(restData.restNum == null || restData.isDiff == undefined) return
                if(!restData.isNew){
                  deleteRestList.push(restData)
                  restDiffFlag=true
                }
              })
            }

            let dailyRow ={
              orderId: workInfo.orderId, //getValues('orderId_'+id),
              staffId: workInfo.staffId, //getValues('staffId_'+id),
              shiftDt: workInfo.baseDt, //getValues('baseDt_'+id),
              baseDt: workInfo.baseDt, //追加
              workDivision: workInfo.shiftWorkDivision,
              workStartDt: workInfo.shiftWorkStartDt,
              workEndDt: workInfo.shiftWorkEndDt,
              workTotalDt: null,
              restStart1Dt: dailyData.restFlag?'':workInfo.shiftRestStart1Dt,
              restEnd1Dt:  dailyData.restFlag?'':workInfo.shiftRestStart1Dt,
              restStart2Dt: dailyData.restFlag?'':workInfo.shiftRestStart2Dt,
              restEnd2Dt:  dailyData.restFlag?'':workInfo.shiftRestStart2Dt,
              restStart3Dt: dailyData.restFlag?'':workInfo.shiftRestStart3Dt,
              restEnd3Dt:  dailyData.restFlag?'':workInfo.shiftRestStart3Dt,
              restTotalDt: null,
              overTotalDt:workInfo.shiftOverTotalDt ,
              deleteRestList: deleteRestList,
              updateRestList: updateRestList,
              addRestList: addRestList,
              remark: null,
              updatedAt: dateFormat(workInfo.shiftUpdatedAt)
            }
      
            //差異チェック
            if(workInfo.isDiff)
            {
              //ValidUpdateRow(dailyRow,workInfo)
              //勤務時間,休憩時間計算
              // let result = calcWorkingSeconds([dailyRow.workStartDt, dailyRow.workEndDt], 
              //   [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, 
              //     dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt], workInfo.attendanceBaseTime)
              if(!dailyData.restFlag) restList = [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, 
                dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt]
              //勤務時間,休憩時間計算
              let result = calcWorkingRestFreeSeconds([dailyRow.workStartDt, dailyRow.workEndDt], restList, workInfo.attendanceBaseTime)

              dailyRow.workTotalDt = result[0]
              dailyRow.restTotalDt = result[1]
              dailyRow.overTime = result[2]
              dailyRow.nightTime = result[3]
              dailyList.push(dailyRow)
              
              console.log(dailyRow)
            }
          })
        })
    })

    if(ValidUpdateShift(dailyList))
    {
      alert.error("入力項目にエラーがあります")
      return
      // const is_ok = window.confirm('更新レコードにに誤りがあります');
      // if(!is_ok)
      // {
      //   return
      // }
    }


    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(dailyList)], { type: 'application/json' }))
    sendPost(submitData)
  }
  
  if (!salariedDateRef.current) {
    if(location && location.state){
      salariedDateRef.current = location.state
    }else{
      var today = new Date();

      salariedDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
    }

  }

  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
    console.log(viewSubSearch)
  }
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }
  if (!grants) return null
  return (
    <SalariedPanelWrap id='SalariedPanelWrap'>
        <Search
          title= {grants.functionMenuName}
          showButton
          onClick={() => onSearch(null, pageType==0?1:0)}
          onClickOption={(e) => onClickOptionBtn(e)}
          onKeyDown={(e) => onKeyDown(e)}
          salariedDateRef={salariedDateRef}
          staffIdRef={staffIdRef}
          staffNameRef={staffNameRef}
          viewSubSearch={viewSubSearch}
          grants={grants}
          postList={postList}
          postId={postId}
          setPostId={setPostId}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          postType={postType}
          setPostType={setPostType}
          onClickFunction={()=>onClickFunction()}
          viewFunction={viewFunction}
          onUpdate={()=>onSubmit()}
          onPageTypeChangeClick={() => onPageChangeLabelClick()}
        >
        </Search>
        {
          staffWorkInfoList &&
          <CountPanelWrap>
              <label>検索ヒット件数:{staffCount}件</label>
          </CountPanelWrap>
        }
        <SalariedFlexWrap>
        <SalariedTable
        staffWorkInfoList={staffWorkInfoList}
        workDivisions={workDivisions}
        >
        </SalariedTable>
        </SalariedFlexWrap>
        {
          staffWorkInfoList.length>0 &&
          <ShiftPagingPanelWrap>
          { pageType==0 &&
            <ShiftPageButtonWrap>       
            <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
            <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
            <ShiftPagingIconPanelWrap>{pageNum}</ShiftPagingIconPanelWrap>
            <ShiftPagingIconPanelWrap>/</ShiftPagingIconPanelWrap>
            <ShiftPagingIconPanelWrap>{pageLast}</ShiftPagingIconPanelWrap>
            <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
            <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
            
            </ShiftPageButtonWrap>
          }
          </ShiftPagingPanelWrap>
        }
    </SalariedPanelWrap>
  ) 
}

export default Salaried