import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import FunctionTableHeader from './FunctionTableHeader'
import FunctionTableBody from './FunctionTableBody'
const TableWrap = styled.table`
  font-size: 12px;
  cellpadding: 0;
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }
  thead {
    width: calc(85rem + 18px);
  }
  tbody {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(24rem + 8px);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
    margin-top:0.25rem;
    margin-bottom:0.25rem;
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th {
    height: 2rem;
    background-color: #649cdf;
  }

  tbody tr {
    :nth-child(even){
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`


const FunctionTable = ({ 
  register, getValues, setValue, watch, reset, errors, grantDetail,isDeleted }) => {
  return (
    <TableWrap visible={grantDetail}>
      <FunctionTableHeader/>
      <FunctionTableBody
        register={register}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        reset={reset}
        errors={errors}
        isDeleted={isDeleted}
        grantDetail={grantDetail}   
      />
    </TableWrap>
  )
}

FunctionTable.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  dateDataList: PropTypes.array,
  updatedDateFunc: PropTypes.func,
  defaultUrl: PropTypes.string
}

export default FunctionTable
