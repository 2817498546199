import React,{useState, useEffect,useCallback} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Input from './Common/Atoms/Input'
import { InputGroup, SelectGroup, HiddenInput } from './Common/CommonInputGroup'
import Button from './Common/Atoms/NormalButton'
import { GetApi ,MultipartApi} from './Common/ApiAxios'
import { SetLoadSpinner } from './Common/Slice/LoadSpinnerSlice'
import ErrorHandling from './Common/ErrorHandling'
import Select from './Common/Atoms/SelectAddValue'
import SearchDepartmentDialog from './Common/CommonMasterDepartmentDialog'
import { dateFormat} from './Common/Utilties/LaborUtility'
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  font-size: 24px;
  display: flex;
  padding: 1vw;
  font-weight: bold;
`
const RadioWrap = styled.div`
  display: flex;
  font-size: 18px;
  justify-content: center;
  padding: 10px;
`
const FormWrap = styled.form`
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
  padding: 1vw;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

const SettingMenu = ({ open, onCloseFunc}) => {
  const { register, handleSubmit, getValues, setError, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();
  const alert = useAlert()
  const dispatch = useDispatch()
  const [postList,setPostList] = useState(new Array())
  const [rows,setRows] = useState(<></>)
  const [canOpenDialog, setCanOpenDialog] = useState(false);

  const [settingList, setSettingList] = useState(null)

  const [companyDepartmentId, setCompanyDepartmentId] = useState(null)
  const [companyId, setCompanyId] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [companyDepartmentName,setCompanyDepartmentName]=useState(null)
  const [departmentList,setDepartmentList] = useState(null)

  const [checked,setChecked]=useState(true)
  //ダイアログ閉じるボタン処理
  const handleClose = () => {
    setCanOpenDialog(false);
  }
  const handleOpen = () => {
    setCanOpenDialog(true);
  }
  //会社リスト変更処理
  const onChange=(event)=>{
    event.persist()
    setCompanyId(event.target.value)
    setCompanyName(event.target.options[event.target.selectedIndex].text)
    postList.map((item)=>{
        if(event.target.value==item.index)setDepartmentList(item.departments)
    })
    var flag = true
    settingList.map(item => {
        if(item.companyId==event.target.value && item.comDepartmentId == null){
            flag=(item.restType)
        }
    })
    setChecked(flag)
    setCompanyDepartmentId(null)
    setCompanyDepartmentName(null)
  }
  //会社部門リスト変更処理
  const onChangeDepartment=(data)=>{
    setCompanyDepartmentId(data)

    var flag = true
    settingList.map(item => {
        if(item.companyId==companyId && item.comDepartmentId == data){
            flag=(item.restType==0)
        }
    })
    setChecked(flag)
  }
  //会社部門リスト変更処理
  const onChangeDepartmentName=(data)=>{
        setCompanyDepartmentName(data)
  }
  //ラジオボタン変更処理
  const handleCheckChange = () => {
    setChecked(!checked)
  };
  // POST・PUT処理
  const onSubmit = () => {
    async function sendPost (requestBody, data) {
        var result
        dispatch(SetLoadSpinner(true))
        console.log(data)
        result = 
          await MultipartApi('/api/updateSettingAttendance','PUT', requestBody, data)
        
        if (!ErrorHandling(result, alert)) {
            alert.success('保存しました')
            var flag = true
            var resultList = result.data.map(item => {
                if(item.comDepartmentId == ''){
                    item.comDepartmentId = null
                }
                if(item.companyId==companyId && item.comDepartmentId == companyDepartmentId){
                    flag=(item.restType==0)
                }
                return item
            })
            setChecked(flag)

            setSettingList(resultList)
        }
        dispatch(SetLoadSpinner(false))
    }

    let data ={
        comDepartmentId:companyDepartmentId==null?'':companyDepartmentId,
        companyId:companyId,
        hierarchyNo:makeHiersrchyNo(),
        restType:(checked)?0:1,
        createAt:'',
        createId:'',
        updatedAt:makeUpdatedAt(),
        updatedId:''
    }
    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    sendPost(submitData, data)
  }
 const onSubmitHandale=()=>{}
  const makeHiersrchyNo=()=>{
    if(companyDepartmentId==null) return 0
    var hierarchy = 0
    
    departmentList.map((item)=>{
        if(item.companyId==companyId && item.comDepartmentId == companyDepartmentId) {
            hierarchy = (item.hierarchyNo)            
        }
    })
    return hierarchy
  }
  const makeUpdatedAt=()=>{
    var updatedAt = null
    settingList.map(item => {
        if(item.companyId==companyId && item.comDepartmentId == companyDepartmentId){
            updatedAt = dateFormat(item.updatedAt)
        }
    })
    return updatedAt
  }
  //会社・会社部門取得処理
  const setPost=()=>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company/all')
      console.log(result)
      if (result.errorDetail || !result.data) {
        ErrorHandling(result, alert)
        return null
      }else{
        
        const resultList = result.data.map(item => {
            return {
              index: item.companyId,
              name: item.companyName+'('+item.companyId+')',
              departments: item.departments
            }
          })
          setPostList(resultList)
    
          var postRows = 
          resultList.map(post=>{
            return <><option value={post.index} >{post.name}</option></> 
          })
          setRows(postRows)
    
          if(resultList.length>0){
            setCompanyId(resultList[0].index)
            setCompanyName(resultList[0].name)
            setDepartmentList(result.data[0].departments)
          }
          return resultList[0].index
      }

      
    }
    serachCompany()
  }

  //勤怠設定取得処理
  const setSetting=(defaltId)=>{
    const serachSetting = async(defaltId)=>{
        const result = await GetApi('/api/settingAttendance')
        console.log(result)
        if (result.errorDetail || !result.data) {
          ErrorHandling(result, alert)
          return null
        }else{
            var flag = true
            var resultList = result.data.map(item => {
                if(item.comDepartmentId==''){
                    if(item.companyId==defaltId){
                        flag = (item.restType==0)
                    }
                    item.comDepartmentId=null
                }
                return item
            })
            setSettingList(resultList)
            setChecked(flag)
        }
      }
      serachSetting(defaltId)
  }

  useEffect(() => {
    var defaltId = setPost()
    setSetting(defaltId)
    // 必要なデータが更新された場合のみ実行
    // eslint-disable-next-line
  }, [])

  return (
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='設定'
      aria-describedby='設定ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap onSubmit={handleSubmit(onSubmitHandale)} className='h-dsm'>
    <div className={classes.paper}>
      <ContentWrap>
      <TitleWrap>
          <label>設定</label>
      </TitleWrap>
      </ContentWrap>
      <TableWrap>
        <label style={{marginTop:'6px',marginLeft:'0px',width:'6rem' ,fontWeight: 'bold',fontSize:'18px'}}>休憩設定</label>
        <label style={{marginTop:'8px',marginLeft:'12px',width:'4rem'}}>会社</label>
        <Select name={'postId'} width='20rem' disabled={false} onChange={(e)=>onChange(e)} selectValue={companyId}>{rows}</Select>
        <label style={{marginTop:'8px',width:'4rem'}}>部門</label>
        <Input type='text' name='comDepartmentName' disabled={true} width='20rem' value={companyDepartmentName} errors={errors}/>
        <Button width='4rem' margin='0 0 0 4px' name='選択' padding='0px' onClick={handleOpen} />
        <RadioWrap>
            <input type="radio" value={0} name="rest" checked={checked} onChange={()=>handleCheckChange()}/>休憩固定
            <input type="radio" value={1} name="rest" checked={!checked} onChange={()=>handleCheckChange()}/>休憩可変
        </RadioWrap>
      </TableWrap>
      <ButtonsWrap>
        <Button name='更新' padding='0 0 12px 0' type='button' onClick={()=>onSubmit()}/>
        <Button name='閉じる' padding='0px' onClick={onCloseFunc} />
      </ButtonsWrap>
      <SearchDepartmentDialog
        open={canOpenDialog}
        onCloseFunc={handleClose}
        postName={companyName}
        postId={companyId}
        setSearchDepartmentId={(data)=>onChangeDepartment(data)}
        searchDepartmentId={companyDepartmentId}
        setDepartmentName={(data)=>onChangeDepartmentName(data)}
        departmentList={departmentList}
        postType={0}
        disabled={false}
        >
    </SearchDepartmentDialog>
    </div>
    </FormWrap>
  </Modal>
  )
}

SettingMenu.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
}

export default SettingMenu
