import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'

import menuButton from '../image/menu-btn.png'
import LogoIcon from '../image/logo.png'
import SettingIcon from '../image/menu-icon4.png'
import StaffInfo from './Common/StaffInfo'
import Navigator from './Navigator'
import SettingMenu from './SettingMenu'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'

const FlexDiv = styled.div`
  display: flex; 
  align-items: center;
  height: 32px;
`

const MenuImg = styled.img`
  cursor: pointer;
  margin-left: 15px;
  width: 32px;
`

const LogoImg = styled.img`
  margin-left: 15px;
  margin-right: auto;
  height: 48px;
`

const SettingImg = styled.img`
  margin-left: auto;
  margin-right: 15px;
  height: 28px;
`

const HeaderWrap = styled.header`
  background: #cfe2f3;
  position: sticky;
  top: 0;
  z-index: 4;
`

const Header = (props) => {
  const { location } = useReactRouter()
  const [showMenuFlg, setShowMenuFlg] = useState('none')
  const showMenu = () => {
    setShowMenuFlg(showMenuFlg === 'none' ? 'block' : 'none')
  }
  const closeMenu = () => {
    setShowMenuFlg('none');
  }
  const [showSetteingMenuFlg, setShowSettingMenuFlg] = useState(false)
  const showSettingMenu = () => {
    setShowSettingMenuFlg(true)
  }
  const closeSettingMenu = () => {
    setShowSettingMenuFlg(false)
  }
  // サイドメニュー判定
  //const sideMenuPosition = useSelector(state => state.sideMenuPosition)
  //const existSideMenu = document.getElementById('SideMenu')
  //const marginRight = existSideMenu ? sideMenuPosition.position : '0px'
  const marginRight = '0px'

  return (
    <HeaderWrap className='header'>
      <div className='header-container'>
        <Tooltip title='メインメニュー'>
          <MenuImg src={menuButton} onClick={showMenu} />
        </Tooltip>
        <LogoImg src={LogoIcon} />
        <Navigator showFlg={showMenuFlg} setShowFlg={setShowMenuFlg} onCloseMenu={closeMenu} topPageFlg={window.location.href.endsWith('top')} />
        <div></div>
        <Tooltip title='設定'>
          <SettingImg src={SettingIcon} onClick={showSettingMenu}/>
        </Tooltip>
        <SettingMenu open={showSetteingMenuFlg} onCloseFunc={closeSettingMenu}/>
        <FlexDiv style={{ marginRight: marginRight }}>
          <StaffInfo />
        </FlexDiv>
      </div>
    </HeaderWrap>
  )
}

export default Header
