import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { useAlert } from 'react-alert'
import { Prompt } from 'react-router-dom';

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
import FUNC from '../../Common/FunctionEnum'
import { createOrderInfo } from '../../Common/Utilties/ObjectUtitlity'

import MasterPanelWrap from '../Wrap/MasterPanelWrap'
import MasterFlexWrap from '../Wrap/MasterFlexWrap'
import MasterListView from '../Molcules/MasterListView'
import Search from './Search'

import GrantDetail from './GrantDetail'

import MasterSelect from '../MasterSelect'
export const DefaultUrl = '/api/masterManagement/grant'

const GrantWrap = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-flow: column;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #666;
`
const FlexStyle = styled.div`
  display:flex;
  width: 100vw;
  height: 80vh;
  background-color:#666;
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: 5% top;
  color:#FFF;
`

const Grant = (props) => {
  const grantNameRef = useRef()
  const [dataList, setDataList] = useState([])
  const [detail, setDetail] = useState(null) // 未選択の状態
  const [selectedId, setSelectedId] = useState(null)
  const [searchOption, setSearchOption] = useState({ staffId: '' })
  const { loginUserInfo } = useSelector(state => state)
  const [pagenation, setPagenation] = useState({})
  const dispatch = useDispatch()
  const alert = useAlert()
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.GRANT)
  const [updateFlag, setUpdateFlag] = useState(false)

  const [viewFunction,setViewFunction] = useState(false)  
  const onClickFunction=()=>{
    setViewFunction(!viewFunction)
  }
 
  
  const pageSelectFunc = (pageId) => {
    onSearch(null, pageId)
  }
  useEffect(() => {
    if (grants)
    document.title = grants.functionMenuName + ' | Core Time Cloud'
  else
    document.title = 'Core Time Cloud'
  }, [])
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, 1)
    }
  }
      // 検索APIの実行
    const onSearch = async (createId, page) => {

      dispatch(SetLoadSpinner(true))
      setSearchOption(
        {
          grantName: (grantNameRef.current)?grantNameRef.current.value:'',
        }
      )
      const data = {
        grantName: (grantNameRef.current)?grantNameRef.current.value:'',
        page: page
      }
      const result = await GetApi(DefaultUrl+'/grantTypeName', data)
      dispatch(SetLoadSpinner(false))
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else {
        const resultList = result.data.responseList.map(item => {
          return {
            index: item.grantNameId,
            id: item.grantNameId,
            name: item.grantName,
            delFlag: item.delFlag,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          }
        })
        setDataList(resultList)
        setPagenation(result.data)
        setSelectedId(null)
        setDetail(null) // 未選択状態
      }
    }
    const selectItem = async (id) => {
      dispatch(SetLoadSpinner(true))
      setSelectedId(id)
      var data = {}
      dataList.map((grant)=>{
          if(id == grant.id){
            data=grant
          }
      })
      const result = await GetApi(`${DefaultUrl}/function/${id}`)
      dispatch(SetLoadSpinner(false))
      if(!ErrorHandling(result, alert)){
        data.functionItems = result.data
        setDetail(data)
      }
    }

    // 新規登録用初期化処理
    const createNew = (e) => {
      //setDetail({}) // 新規作成時の値
      selectItem(-1)
      setSelectedId(null)
    }
    
    //画面表示の時
    useEffect(()=>{
      onSearch(null,1)
    },[])

    if (!grants) return null
    return (
      <MasterPanelWrap id='GrantWrap'>
      <Search
        title={grants.functionMenuName}
        showButton
        onClick={() => onSearch(null, 1)}
        onKeyDown={(e) => onKeyDown(e)}
        grantNameRef={grantNameRef}
        grants={grants}
        viewFunction={viewFunction}
        onClickFunction={()=>onClickFunction()}
      >
      </Search>
      <MasterFlexWrap>
          <MasterSelect/>
          <MasterListView
              dataList={dataList}
              setSelectedId={setSelectedId}
              selectFunction={(id) => selectItem(id)}
              createFunction={() => createNew()}
              canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
              pageSelectFunc={pageSelectFunc}
              pagenation={pagenation}
              isIdView
            />
            <GrantDetail
              onSearch={() => onSearch(null, 1)}
              setSelectedId={setSelectedId}
              setDetail={setDetail}
              grantDetail={detail}
              reSerach={(id) => selectItem(id)}
            >
            </GrantDetail>
      </MasterFlexWrap>
      </MasterPanelWrap>
    )
}

export default Grant
