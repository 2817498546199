import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {ColumnStaffTH,RowHeaderTop,ColumnOccupationTH,ColumnWorkTH}from '../../Wrap/ShiftGanttChartTableWrap'

/*
const CellWrap = styled.th`
  width: calc(60rem + 11px);
  height: 3rem;
  ${props => props.addStyle}
`

const CellPanel = styled.th`
  display: flex;
  flex-direction: column;
`
const CellMonthPanel = styled.th`
  flex-direction: column;
`
const Column = styled.th`
  text-align: center;
  vertical-align:middle;
  line-height:1.5rem; 
  height: 1.5rem;
`
const ColumnMonth = styled.th`
  text-align: center;
  vertical-align:middle;
  width: 5rem;
  height: 1.5rem;
`
*/
const LaborGridHeaderRight = styled.th`
  max-height: 30px;
`

const SalariedTableHeader = ({ staffWorkInfoList, selectFunction }) => {
  return (
    <thead>
      <tr>
        <RowHeaderTop>
          <ColumnStaffTH className='staffID'>スタッフID</ColumnStaffTH>
          <ColumnOccupationTH className='occupation'>業務</ColumnOccupationTH>
          <ColumnWorkTH className='workTime'>勤務時間</ColumnWorkTH>
          <ColumnWorkTH className='restTime'>休憩時間</ColumnWorkTH>
          <ColumnWorkTH className='overTime'>残業時間</ColumnWorkTH>
          <LaborGridHeaderRight className='chart'>
              <canvas id='timeCanvas' width='2220' height='30' />
          </LaborGridHeaderRight>
        </RowHeaderTop>
        </tr>
      </thead>
  )
}

SalariedTableHeader.propTypes = {
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
}

export default SalariedTableHeader
