import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const ColumnStatusTH = styled.th`
  width: 10rem;
  height: 2rem;
  vertical-align:middle;
`
export const ColumnFunctionTH = styled.th`
  width: 25rem;
  height: 2rem;
  vertical-align:middle;
`

const TableHeader = ({  }) => {

  return (
    <thead>
      <tr>
        <ColumnFunctionTH rowSpan={2}>機能名称</ColumnFunctionTH>
        <ColumnStatusTH colSpan={3}>読取</ColumnStatusTH>
        <ColumnStatusTH colSpan={3}>書取</ColumnStatusTH>
      </tr>
      <tr>
        <ColumnStatusTH>不可</ColumnStatusTH>
        <ColumnStatusTH></ColumnStatusTH>
        <ColumnStatusTH>全部門</ColumnStatusTH>
        <ColumnStatusTH>不可</ColumnStatusTH>
        <ColumnStatusTH></ColumnStatusTH>
        <ColumnStatusTH>全部門</ColumnStatusTH>
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
}

export default TableHeader
