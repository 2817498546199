import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { SetLoadSpinner } from '../../../../Common/Slice/LoadSpinnerSlice'
import { DeleteApi, MultipartApi } from '../../../../Common/ApiAxios'
import ErrorHandling from '../../../../Common/ErrorHandling'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'

import Button from '../../../../Common/Atoms/NormalButton'

import { DefaultUrl } from '../../Company'
import DepartmentMasterForm from  './DepartmentMasterForm'
import { dateFormat,timeToSec} from '../../../../Common/Utilties/LaborUtility'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  root: {
    width: '75vw',
  },
  typography: {
    width: '70vw',
    fontSize: '18px',
    fontWeight: '700',
    color: '#ffffff',
    verticalAlign: 'middle',
    margin: '0',
    padding: '16px',
    background: '#4169E1',
  },
}));


const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  width: calc(75vw + 10px);
  height: calc(80vh - 6rem);

  :hover {
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-track {
    border:none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }
`

const TitleWrap = styled.div`
  display: flex;
  padding: 1vw;
`

const FormWrap = styled.form`
  display: flex;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

const DepartmentModal = ({ open, onCloseFunc, canWrite, department , departmentList ,comGetValues, reSerach,disabled}) => {
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const [actionType, setActionType] = useState('PUT')
  const [isDeleted, setIsDeleted] = useState(false)
  const [isSucess,setIsSucess]= useState(true)
  const classes = useStyles()
  const alert = useAlert()
  const dispatch = useDispatch()

  const [tmpDepartmentList, setTmpDepartmentList] = useState(departmentList)
  // 情報をFormに反映
  useEffect(() => {
    console.log(departmentList)
    if(department && department.comDepartmentId ==null){
      //新規作成の時,リストから削除済みと第四階層を取り除く
      setTmpDepartmentList(departmentList.filter(item => !item.delFlag && item.hierarchyNo!=4))
    }else{
      console.log("aaaa")
      setTmpDepartmentList(departmentList)
    }
    setIsSucess(true)
  // 部門情報の変更検知時のみ実行
  // eslint-disable-next-line
  }, [department])

  // POST・PUT処理
  const onSubmit = (data) => {
    async function sendPost (requestBody, data) {
      var result
      dispatch(SetLoadSpinner(true))
      switch (actionType) {
        case 'POST':
          result = await MultipartApi(`${DefaultUrl}/${data.companyId}`, actionType, requestBody, data)
          break
        case 'PUT':
          result = await MultipartApi(`${DefaultUrl}/${data.companyId}/${data.comDepartmentId}`, actionType, requestBody, data)
          break
        default:
          result = false
          break
      }
      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(result, alert)) {
        reSerach(data.companyId)
        onCloseFunc()
        isDeleted ? alert.success('有効化しました') : alert.success('保存しました')
      }
    }

    //submitData.append('formData', new window.Blob([JSON.stringify(updateData)], { type: 'application/json' }))
    let updateData ={
      comDepartmentId:getValues('comDepartmentId'),
      companyId:getValues('companyId'),
      hierarchyNo:getValues('hierarchyNo'),
      comDepartmentName:getValues('comDepartmentName'),
      comDepartmentKana:getValues('comDepartmentKana'),
      zipCode:getValues('zipCode'),
      address1:getValues('address1'),
      address2:getValues('address2'),
      address3:getValues('address3'),
      address4:getValues('address4'),
      phoneNum:getValues('phoneNum'),
      faxNum:getValues('faxNum'),
      cooperationId:getValues('cooperationId'),
  
      // 部門別計算条件
      departmentDivision:getValues('departmentDivision'),
      attendanceFractionUnit:getValues('attendanceFractionUnit'),
      attendanceBaseTime:timeToSec(getValues('attendanceBaseTime')),
      payFractionDivision:getValues('payFractionDivision')==''?department.payFractionDivision:getValues('payFractionDivision'),
      billFractionDivision:getValues('billFractionDivision')==''?department.billFractionDivision:getValues('billFractionDivision'),
  
      updatedAt:dateFormat(getValues('updatedAt')),
      updatedId:getValues('updatedId'),
      calcUpdatedAt:dateFormat(getValues('calcUpdatedAt'))
    }

    console.log(updateData)
    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(updateData)], { type: 'application/json' }))
    sendPost(submitData, updateData)
  }

  // DELETEはForm状態に影響されないため個別で定義
  const onClickHandleDelete = () => {
    async function sendDelete (companyId,comDepartmentId, data) {
      dispatch(SetLoadSpinner(true))
      const result = await DeleteApi(`${DefaultUrl}/${companyId}/${comDepartmentId}/${data.lastUpdatedAt}`, data, true)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert)
      if (result.data){        
        alert.info('無効化しました')
        reSerach(companyId)
        onCloseFunc()
        setActionType('POST')
      }
    }
    if (department.comDepartmentId !== undefined) {
      const data = { lastUpdatedAt: department.updatedAt }
      sendDelete(department.companyId,department.comDepartmentId.slice(0,department.hierarchyNo*3), data)
    }
  }

  if (department === null) return null // 未選択時の場合は非表示
  return (
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='部門情報'
      aria-describedby='部門情報ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap onSubmit={handleSubmit(onSubmit)} className='h-dsm'>
    <div className={classes.paper}>
    <TitleWrap>部門情報</TitleWrap>
      <ContentWrap>
      <DepartmentMasterForm
        register={register}
        errors={errors}
        isDeleted={isDeleted}
        getValues={getValues}
        control={control}
        setValue={setValue}
        reset={reset}
        department={department}
        watch={watch}
        canWrite={canWrite}
        actionType={actionType}
        setActionType={setActionType}
        setIsDeleted={setIsDeleted}
        departmentList={tmpDepartmentList}
        comGetValues={comGetValues}
        setIsSucess={(delFlag)=>setIsSucess(delFlag && disabled)}
      >
      </DepartmentMasterForm>
      </ContentWrap>
      <ButtonsWrap>
       {!isDeleted && department && department.comDepartmentId &&
        <Button
          padding='0'
          name='削除'
          type='button'
          onClick={(e) => onClickHandleDelete(e)}
        />}
        {isSucess && 
          <Button type='button' name={department.comDepartmentId?(isDeleted ? '有効化' : '更新') :'登録'} padding='0px' onClick={onSubmit} />
        }
        <Button name='閉じる' padding='0px' onClick={onCloseFunc} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

DepartmentModal.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    canWrite: PropTypes.bool,
    department: PropTypes.object,
}

export default DepartmentModal
