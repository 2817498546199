import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import { formatHour, checkNomalShift} from '../../../Common/Utilties/LaborUtility'

import IconShiftImg from '../../../../image/shift.png'
import IconShiftNormalImg from '../../../../image/shift-nomal.png'
import IconShiftDiffImg from '../../../../image/shift-diff.png'
import IconShiftAbsenceImg from '../../../../image/shift-absence.png'
import DailyShiftModal from '../DailyShiftModal/DailyShiftModal'
//import Shift from '../../Shift'

const CellWrap = styled.td`
  cursor: pointer;
  width: 3rem;
  height: 40px;
  vertical-align:middle;
  margin: 0;
  padding: 0;
  ${props => props.addStyle}
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const IconImg = styled.img`

  width: 60%;
  margin: auto;
`

const MonthListViewDataShiftCell = ({ dailyWorkInfo, updatedShiftFunc, defaultUrl,shiftFilter,workDivisions,restFlag }) => {

  const [canOpen, setCanOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [iconImg, setIconImg] = React.useState();

  const checkShift = (dailyWorkInfo,shiftFilter) => {
    //console.log(dailyWorkInfo)
    if (dailyWorkInfo.shiftWork) {
      if (!dailyWorkInfo.attendanceWork) {
        if (dailyWorkInfo.baseDt < new Date().toISOString().slice(0,10)) {
          // 当日までの場合でシフト有、勤怠無しは欠席とする
          if(shiftFilter=='all' || shiftFilter==IconShiftAbsenceImg){
            setIconImg(IconShiftAbsenceImg)
            setCanOpen(true)
          }else{
            setIconImg(null)
            setCanOpen(false)
          }
        }
        else {
          // 当日以降の場合は、シフト有とする
          if(shiftFilter=='all' || shiftFilter==IconShiftImg){
            setIconImg(IconShiftImg)
            setCanOpen(true)
          }else{
            setIconImg(null)
            setCanOpen(false)
          }
        }
      } else {
        console.log(checkNomalShift(dailyWorkInfo.workInfoList)) 
        if(checkNomalShift(dailyWorkInfo.workInfoList)){
          if(shiftFilter=='all' || shiftFilter==IconShiftNormalImg){
            setIconImg(IconShiftNormalImg)
            setCanOpen(true)
          }else{
            setIconImg(null)
            setCanOpen(false)
          }
        }else{
          if(shiftFilter=='all' || shiftFilter==IconShiftDiffImg){
            setIconImg(IconShiftDiffImg)
            setCanOpen(true)
          }else{
            setIconImg(null)
            setCanOpen(false)
          }
        }
      }
    } else {
      if (dailyWorkInfo.attendanceWork) {
        if(shiftFilter=='all' || shiftFilter==IconShiftDiffImg){
          setIconImg(IconShiftDiffImg)
          setCanOpen(true)
        }else{
          setIconImg(null)
          setCanOpen(false)
        }
      }
      else {
        setIconImg(null)
        if(shiftFilter=='all'){
          setCanOpen(true)
        }else{
          setCanOpen(false)
        }
      }
    }
  }

  useEffect(() => {
    checkShift(dailyWorkInfo,shiftFilter)
  }, [dailyWorkInfo])

  useEffect(() => {
    checkShift(dailyWorkInfo,shiftFilter)
  }, [shiftFilter])

  const handleOpen = () => {
    if(canOpen)
      setOpen(true);
  }

  const handleClose = () => {
    dailyWorkInfo.workInfoList.map((workInfo)=>{
      workInfo.restList = workInfo.shiftRestList
    })
    setOpen(false)
  }

  const updatedShiftFunction = () => {
    setOpen(false)

    updatedShiftFunc()
  }

  const createToolTipTitle = (dailyWorkInfo) => {
    if (dailyWorkInfo) {
      let shiftTotal = 0.0
      let attendanceTotal = 0.0
      dailyWorkInfo.workInfoList.forEach((workInfo) => {
        if (workInfo.shiftWork)
          shiftTotal += workInfo.shiftWorkTotalDt
        if (workInfo.attendanceWork)
          attendanceTotal += workInfo.attendanceWorkTotalDt
      });
  
      let shiftText = "--.-- h"
      let attendanceText = "--.-- h"

      if (dailyWorkInfo.shiftWork) {
        shiftText = formatHour(shiftTotal) + " h"
      }
      if (dailyWorkInfo.attendanceWork) {
        attendanceText = formatHour(attendanceTotal) + " h"
      }
  
      return <React.Fragment>
          {"予定 "}{shiftText}<br/>
          {"実績 "}{attendanceText}
        </React.Fragment>
    }

    return <></>
  }

  return (
    <CellWrap>
      <CellPanel onClick={handleOpen}>
        {iconImg && 
        <Tooltip title={createToolTipTitle(dailyWorkInfo)}>
          <IconImg src={iconImg} onClick={handleOpen} />
        </Tooltip>
        }
      </CellPanel>
      <DailyShiftModal
        open={open}
        onCloseFunc={handleClose}
        updatedShiftFunc={updatedShiftFunction}
        defaultUrl={defaultUrl}
        dailyWorkInfo={dailyWorkInfo}
        workDivisions={workDivisions}
        restFlag={restFlag}
      >
      </DailyShiftModal>
    </CellWrap>
  )
}

MonthListViewDataShiftCell.propTypes = {
  dailyWorkInfo: PropTypes.object,
  updatedShiftFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
  workDivisions: PropTypes.array,
}

export default MonthListViewDataShiftCell
