import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
//import ReTooltip from '@material-ui/core/Tooltip'
import {XAxis ,ReferenceLine,YAxis, Tooltip, Legend, Line, Bar,ComposedChart } from "recharts"

const renderColorfulLegendText = (value) => {
  return <span style={{ color:'black'}}>{value}</span>
}

const OverTimeGraph = ({
    register, getValues, setValue, watch, reset, errors,
    defaultUrl, workInfoList
  }) => {
    
    const maxY1=(data)=>{
      var max = 0
      data.map((YMData)=>{
          var monthMax =  
            Math.max(
              YMData["当月"]
            , YMData["2ヶ月"]
            , YMData["3ヶ月"]
            , YMData["4ヶ月"]
            , YMData["5ヶ月"]
            , YMData["6ヶ月"]
          )
        if(max < monthMax){
          max =  Math.ceil(monthMax)
          console.log(max)
          console.log(5 - max%5)
          max =  max + (5 - max%5)
        }
      })
      
      if(max < 80){
        max=80
      }
      return max
    }
    const maxY2=(data)=>{
      var max = 200
      data.map((YMData)=>{
        var thisYear =YMData["今年度"]
        var ave = YMData["1人当たりの平均"]
        if(max < thisYear){
          max =  Math.ceil(thisYear)
          console.log(max)
          console.log(500 - max%500)
          max =  max + (200 - max%200)
        }
      })
      return max
    }
    const XYList = new Array()
    class CustomizedLabel extends PureComponent {
      XYList = new Array()
      render() {
        const { x, y, stroke, value} = this.props;
        var tmpX = 0
        XYList.map((data)=>{
          if(x!=data.x && x+36!=data.x && x-36!=data.x) return
          if(y > (610-12) && y < (610+12)){
            tmpX = 36
          }
          if(y>(data.y-12) && y <(data.y+12)){
            tmpX = -36
          }
          if(tmpX+x==data.x) tmpX=-tmpX
        })
        console.log(value,x+tmpX,y,tmpX)
        XYList.push({x:x+tmpX,y:y})
        return (
          <text x={x+tmpX} y={y} dy={-6} fill={stroke} fontSize={12} textAnchor="middle">
            {value}
          </text>
        );
      }
    }
    class CustomizedBarLabel extends PureComponent {
      XYList = new Array()
      render() {
        const { x, y, stroke,width, value} = this.props;
        return (
          <text x={x+width/2} y={610} fill={stroke} fontSize={12} textAnchor="middle">
            {value}
          </text>
        );
      }
    }
    
    const CustomTooltip = (event) => {
      console.log(event)
      // if (active && payload && value && payload.length) {
      //   return (
      //     <div className="custom-tooltip">
      //       <p className="label">{`${value}`}</p>
      //     </div>
      //   );
      // }
    };
    const dataRow = new Array()
    dataRow.push({data:0,number:70})
    dataRow.push({data:1,number:70})
  return (
    <div>
    <ComposedChart 
    width={1850}
    height={680}
    data={workInfoList}
    margin={{
        top: 5, right: 30, left: 60, bottom: 5,
    }}>
    {/* <CartesianGrid strokeDasharray="3 3" /> */}
    <XAxis xAxisId={2} dataKey="name" hide={true}/>
    <XAxis xAxisId={1} dataKey="name" />
    <XAxis xAxisId={0} dataKey="name" hide={true} tickLine={false}/>
    <YAxis yAxisId={1}  domain={[0, maxY1(workInfoList)]} tickCount={6} label={{ value: "残業時間(単位:h)",fontSize:'24px', angle: -90, dx: -20 }}/>
    <YAxis yAxisId={2}  domain={[0, maxY2(workInfoList)]} tickCount={6} orientation="right"/>
    {/* <Tooltip cursor={false} wrapperStyle={{ display: "none" }}/> */}
    <Tooltip />
    <Legend formatter={renderColorfulLegendText}/>
    {/* <CartesianGrid stroke="red" vertical={false} horizontalPoints={[0]}/> */}
    <Bar xAxisId={1} yAxisId={1} dataKey="2ヶ月" fill="#8884d8"/>
    <Bar xAxisId={1} yAxisId={1} dataKey="3ヶ月" fill="red" />
    <Bar xAxisId={1} yAxisId={1} dataKey="4ヶ月" fill="#82ca9d"/>
    <Bar xAxisId={1} yAxisId={1} dataKey="5ヶ月" fill="green" />
    <Bar xAxisId={1} yAxisId={1} dataKey="6ヶ月" fill="black" />
    <ReferenceLine xAxisId={1} yAxisId={1} y={80} stroke="red" />
    <ReferenceLine xAxisId={1} yAxisId={1} y={70} stroke="yellow" />
    <Line type="plainline" xAxisId={1} yAxisId={2} strokeWidth={2} onMouseOver={(e)=>CustomTooltip(e)} dataKey="今年度" stroke="red" label={<CustomizedLabel />}/>    
    <Line type="plainline" xAxisId={1} yAxisId={2} strokeWidth={2} dataKey="1人当たりの平均" stroke="orange" label={<CustomizedLabel />}/>
    <Bar xAxisId={0} yAxisId={2} maxBarSize={Math.ceil(200/workInfoList.length)} dataKey="当月" fill="blue" label={<CustomizedBarLabel />} />
    {/* <LabelList dataKey="1人当たりの平均" position="left" /> */}
    </ComposedChart >
    {/* <div display={viewSubSearch ? 'block' : 'none' }>{dat}</div> */}
    </div>
  )
}

OverTimeGraph.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array
}

export default OverTimeGraph
