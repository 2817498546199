import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

//import Tooltip from '@material-ui/core/Tooltip'

import IconExpendImg from '../../../../image/expand-icon.png'
import { InputGroup, HiddenInput } from '../../../Common/CommonInputGroup'
import { ColumnDateTD, ColumnIdTD, ColumnStatusTD, ColumnLocationTD } from '../Wrap/TableWrap'
import { dateFormat} from '../../../Common/Utilties/LaborUtility'

const CalendarTableRow = ({ 
    register, getValues, setValue, watch, reset, errors, 
    dateData, selectFunction,
     }) => {

  const elementId = dateData.deviceId

  const [imgVisible,setImgVisible] = useState(false)
  const [color,setColor] = useState('')
  // 勤怠情報切り替え時
  useEffect(() => {
    // 勤怠情報が変更された場合のみ実行
    console.log(dateData)
    if (dateData) {

      setValue('location1_' + elementId, dateData.location1)
      setValue('location2_' + elementId, dateData.location2)
    }
  // eslint-disable-next-line
  }, [dateData])

  const formatDate = (dateData) => {
    var date = new Date(dateData.dateValue);
    return dateFormat(dateData.checkAt)
  }


  const createElementDate = (dateData) => {

    return <>{formatDate(dateData)}</>

  }



   const createElementNote = (dateData,num) => {
    if (dateData) {
      return <>
          <InputGroup
              name={'location'+num+'_'+ elementId}
              width='47rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'left', paddingRight: '0.5rem' }}
              disabled={false}  
          />
         </>
    }

    return <></>
  }

  return (
    <tr>
      <ColumnIdTD backgroundColor={dateData.status}>{dateData.deviceId}</ColumnIdTD>
      <ColumnStatusTD  backgroundColor={dateData.status}>{dateData.status?'正常':'異常'}</ColumnStatusTD>

      <ColumnDateTD  backgroundColor={dateData.status}>{createElementDate(dateData)}</ColumnDateTD>

      <ColumnLocationTD backgroundColor={dateData.status}>{createElementNote(dateData,1)}</ColumnLocationTD>
      <ColumnLocationTD  backgroundColor={dateData.status}>{createElementNote(dateData,2)}</ColumnLocationTD>

      <HiddenInput name={'publicHoliday_'+elementId} register={register} defaultValue={dateData.publicHoliday}></HiddenInput>
    </tr>
  )
}

CalendarTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  dateData: PropTypes.object,
  selectFunction: PropTypes.func,
}

export default CalendarTableRow
