import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Input from './Atoms/Input'
import Select from './Atoms/SelectAddValue'
import Button from './Atoms/NormalButton'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
import { GetApi } from './ApiAxios'
import ErrorHandling from './ErrorHandling'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`
/*
const TitleWrap = styled.div`
  display: flex;
  padding: 1vw;
`
*/
const FormWrap = styled.form`
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 1vw;
`

export const IconImg = styled.img`
  align-items: left;
  width: 20px;
  height: 20px;
`
export const NotIconImg = styled.div`
  align-items: left;
  width: 20px;
  height: 20px;
`

const SearchDepartmentDialog = ({ open,onCloseFunc,postName,postId,postType,setDepartmentName,searchDepartmentId, setSearchDepartmentId,departmentList,disabled}) => {
  //const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();
  const [hierarchy1Rows, setHierarchy1Rows] = useState(<></>)
  const [hierarchy2Rows, setHierarchy2Rows] = useState(<></>)
  const [hierarchy3Rows, setHierarchy3Rows] = useState(<></>)
  const [hierarchy4Rows, setHierarchy4Rows] = useState(<></>)
  const [hierarchy1RowsStatus, setHierarchy1RowsStatus] = useState(true)
  const [hierarchy2RowsStatus, setHierarchy2RowsStatus] = useState(true)
  const [hierarchy3RowsStatus, setHierarchy3RowsStatus] = useState(true)
  const [hierarchy4RowsStatus, setHierarchy4RowsStatus] = useState(true)
  const [hierarchy1Name, setHierarchy1Name] = useState(null)
  const [hierarchy2Name, setHierarchy2Name] = useState(null)
  const [hierarchy3Name, setHierarchy3Name] = useState(null)
  const [hierarchy4Name, setHierarchy4Name] = useState(null)
  const [hierarchy1Id, setHierarchy1Id] = useState(null)
  const [hierarchy2Id, setHierarchy2Id] = useState(null)
  const [hierarchy3Id, setHierarchy3Id] = useState(null)
  const [hierarchy4Id, setHierarchy4Id] = useState(null)
  const [displayHierarchy1Id, setDisplayHierarchy1Id] = useState(null)
  const [displayHierarchy2Id, setDisplayHierarchy2Id] = useState(null)
  const [displayHierarchy3Id, setDisplayHierarchy3Id] = useState(null)
  const [displayHierarchy4Id, setDisplayHierarchy4Id] = useState(null)

  const alert = useAlert()
  const dispatch = useDispatch()
  const [departmentDatas,setDepartmentDatas] = useState(new Array())
  const [departmentId,setDepartmentId] = useState(searchDepartmentId)
  useEffect(() => {
    if(postId != null && postId != -1)
    {
      setDetail(departmentList)
    }
    else{
      setDefalt()
    }
  }, [postId])

  const setDetailId = (departmentData)=> {
    if(searchDepartmentId && searchDepartmentId != null && searchDepartmentId!= '全て' && departmentData.length!=0){
      setSearchDepartmentId(searchDepartmentId)

      var seter = []
      seter.push({display:setDisplayHierarchy1Id,state:setHierarchy1RowsStatus,name:setHierarchy1Name,id:setHierarchy1Id})
      seter.push({display:setDisplayHierarchy2Id,state:setHierarchy2RowsStatus,name:setHierarchy2Name,id:setHierarchy2Id})
      seter.push({display:setDisplayHierarchy3Id,state:setHierarchy3RowsStatus,name:setHierarchy3Name,id:setHierarchy3Id})
      seter.push({display:setDisplayHierarchy4Id,state:setHierarchy4RowsStatus,name:setHierarchy4Name,id:setHierarchy4Id})
      
      var departmentName = ''
      var dataList = []
      var breakFlag = false
      seter[0].state(disabled)
      for(var hierarchyNo=1;hierarchyNo<=4;hierarchyNo++){
        dataList = setCmbDepearment(departmentData,searchDepartmentId,hierarchyNo)
        
        var searchId = searchDepartmentId.substring(0, 3 * (hierarchyNo))+('0'*(3 * (hierarchyNo)))
        searchId=(searchId+'000000000000').slice(0,12)
        console.log(searchId)
        seter[hierarchyNo-1].display(searchId)
        dataList.map((data)=>{
          if(data.index==searchId){
            seter[hierarchyNo-1].display(searchId)
            seter[hierarchyNo-1].id(searchId)
            seter[hierarchyNo-1].name(data.name)
            departmentName+=data.name
          }
          if(data.index==searchDepartmentId){
            breakFlag=true
            setDepartmentName(departmentName)
          }
        })
        if(hierarchyNo!=4){
          seter[hierarchyNo].state(disabled)
        }
        if(breakFlag){
          break
        }
        departmentName+='-'        
      }
      if(!breakFlag) setDefalt(1)
    }else{
      setDefalt(1)
      setDepartmentId(null)
      setHierarchy1Name('')
    }
  }

  const setDetail = async(departmentList) =>{
    var result = null
    if(!departmentList){
      dispatch(SetLoadSpinner(true))
      if(postType==0){
        result = await GetApi(`/api/masterManagement/company/${postId}`)
      }
      else
      {
        result = await GetApi(`/api/masterManagement/customer/${postId}`)
      }
      if (result.errorDetail) {
          ErrorHandling(result, alert)
          return null
      }
    }
    else{
      result={
        data:{departments: departmentList}
      }
    }
    console.log(result.data.departments)
    setSearchDepartmentId(null)
    setDisplayHierarchy1Id(null)
    setDisplayHierarchy2Id(null)
    setDisplayHierarchy3Id(null)
    setDisplayHierarchy4Id(null)
    setDepartmentName('')
    setCmbDepearment(result.data.departments,null,1,)

    setDetailId(result.data.departments)
    dispatch(SetLoadSpinner(false))
  }

  const setDefalt = (hierarchyNo) =>{
    switch (hierarchyNo) {
      case 1:
        setHierarchy1RowsStatus(disabled)
        setHierarchy2Rows(<><option value={null} >{'全て'}</option></> )
        setHierarchy2RowsStatus(true)
        setHierarchy2Id(null)
        setHierarchy2Name('全て')
        setHierarchy3Rows(<><option value={null} >{'全て'}</option></> )
        setHierarchy3RowsStatus(true)
        setHierarchy3Name('全て')
        setHierarchy3Id(null)
        setHierarchy4Rows(<><option value={null} >{'全て'}</option></> )
        setHierarchy4RowsStatus(true)
        setHierarchy4Name('全て')
        setHierarchy4Id(null)
        break
      case 2:
        setHierarchy2RowsStatus(disabled)
        setHierarchy3Rows(<><option value={null} >{'全て'}</option></> )
        setHierarchy3RowsStatus(true)
        setHierarchy3Name('全て')
        setHierarchy3Id(null)
        setHierarchy4Rows(<><option value={null} >{'全て'}</option></> )
        setHierarchy4RowsStatus(true)
        setHierarchy4Name('全て')
        setHierarchy4Id(null)
        break
      case 3:
        setHierarchy3RowsStatus(disabled)
        setHierarchy4Rows(<><option value={null} >{'全て'}</option></> )
        setHierarchy4RowsStatus(true)
        setHierarchy4Name('全て')
        setHierarchy4Id(null)
        break
      case 4:
        setHierarchy4RowsStatus(disabled)
        break
      default:
        setHierarchy1Rows(<><option value={null} >{'全て'}</option></> )
        setHierarchy1RowsStatus(true)
        setHierarchy1Name('全て')
        setHierarchy1Id(null)
        setHierarchy2Rows(<><option value={null} >{'全て'}</option></> )
        setHierarchy2RowsStatus(true)
        setHierarchy2Name('全て')
        setHierarchy2Id(null)
        setHierarchy3Rows(<><option value={null} >{'全て'}</option></> )
        setHierarchy3RowsStatus(true)
        setHierarchy3Name('全て')
        setHierarchy3Id(null)
        setHierarchy4Rows(<><option value={null} >{'全て'}</option></> )
        setHierarchy4RowsStatus(true)
        setHierarchy4Name('全て')
        break
    }
  }

  const setCmbDepearment=(departmentData,departmentId,hierarchyNo,name)=>
  {
    var searchId = '';
    if (departmentId != null && departmentId!= '全て')
    {
        searchId = departmentId.substring(0, 3 * (hierarchyNo - 1));
    }
    const dataList = new Array()
    dataList.push({index:null,name:'全て'})
    console.log(departmentData)
    departmentData.map((data)=>{
        var departmentId = (postType==0?data.comDepartmentId:data.cusDepartmentId)
        var departmentName = (postType==0?data.comDepartmentName:data.cusDepartmentName)
        if(hierarchyNo != data.hierarchyNo){
            return
        }
        if(!departmentId.startsWith(searchId)){
            console.log(searchId)
            return
        }
        
        dataList.push({index:departmentId,name:departmentName})
    })
    const rows = dataList.map((data)=>{
      return <><option value={data.index} >{data.name}</option></> 
    })
    switch (hierarchyNo) {
        case 1:
          setHierarchy1Rows(rows)
          setHierarchy1RowsStatus(false)
          break
        case 2:
          setHierarchy2Rows(rows)
          setHierarchy2RowsStatus(false)
          break
        case 3:
          setHierarchy3Rows(rows)
          setHierarchy3RowsStatus(false)
          break
        case 4:
          setHierarchy4Rows(rows)
          setHierarchy4RowsStatus(false)
          break
        default:
          break
    }
    setDefalt(hierarchyNo)
    if(hierarchyNo==1 && departmentData.length<=0){
      setDefalt()
    }
    setDepartmentDatas(departmentData)

    return dataList
  }

  const onChangeHierarchy1=(event)=>{
    event.persist()
    console.log(event.target.value)
    if(event.target.value!='全て'){
      setCmbDepearment(departmentDatas,event.target.value,2)
      setDepartmentId(event.target.value)
      setHierarchy1Id(event.target.value)
      var text = event.target.options[event.target.selectedIndex].text
      setHierarchy1Name(text)
    }else{
      setDefalt(1)
      setDepartmentId(null)
      setHierarchy1Id(null)
      setHierarchy1Name('全て')
    }
   
  }
  const onChangeHierarchy2=(event)=>{
    event.persist()
    console.log(event.target.value)
    if(event.target.value!='全て'){
      setCmbDepearment(departmentDatas,event.target.value,3)
      setDepartmentId(event.target.value)
      setHierarchy2Id(event.target.value)
      var text = event.target.options[event.target.selectedIndex].text
      setHierarchy2Name(text)
    }else{
      setDefalt(2)
      setDepartmentId(hierarchy1Id)
      setHierarchy2Id(null)
      setHierarchy2Name('全て')
    }
      
  }
  const onChangeHierarchy3=(event)=>{
    event.persist()
    console.log(event.target.value)
    if(event.target.value!='全て'){
      setCmbDepearment(departmentDatas,event.target.value,4)
      setDepartmentId(event.target.value)
      setHierarchy3Id(event.target.value)
      var text = event.target.options[event.target.selectedIndex].text
      setHierarchy3Name(text)
    }else{
      setDefalt(3)
      setDepartmentId(hierarchy2Id)
      setHierarchy3Id(null)
      setHierarchy3Name('全て')
    }
      
  }
  const onChangeHierarchy4=(event)=>{
    event.persist()
    console.log(event.target.value)
    if(event.target.value!='全て'){
      setDepartmentId(event.target.value)
      setHierarchy4Id(event.target.value)
      var text = event.target.options[event.target.selectedIndex].text
      setHierarchy4Name(text)
    }else{
      setDepartmentId(hierarchy3Id)
      setHierarchy4Id(null)
      setHierarchy4Name('全て')
    }
  }
  
  const onSelect=()=>{
    setSearchDepartmentId(departmentId)

    var departmentName=''
    if(hierarchy1Name != '全て'){
      departmentName += hierarchy1Name
    }
    if(hierarchy2Name != '全て'){
      departmentName += '-'
      departmentName += hierarchy2Name
    }
    if(hierarchy3Name != '全て'){
      departmentName += '-'
      departmentName += hierarchy3Name
    }
    if(hierarchy4Name != '全て'){
      departmentName += '-'
      departmentName += hierarchy4Name
    }
    setDisplayHierarchy1Id(hierarchy1Id)
    setDisplayHierarchy2Id(hierarchy2Id)
    setDisplayHierarchy3Id(hierarchy3Id)
    setDisplayHierarchy4Id(hierarchy4Id)
    setDepartmentName(departmentName)
    onCloseFunc()

  }
  const onClose=()=>{
    setHierarchy1Id(displayHierarchy1Id)
    var flag = true
    if(displayHierarchy1Id != null){
      setCmbDepearment(departmentDatas,displayHierarchy2Id,2)
    }else{
      setDefalt(1)
      flag = false
    }
    setHierarchy2Id(displayHierarchy2Id)
    if(displayHierarchy2Id != null){
      setCmbDepearment(departmentDatas,displayHierarchy2Id,3)
    }else if(flag){
      setDefalt(2)
      flag = false
    }
    setHierarchy3Id(displayHierarchy3Id)
    if(displayHierarchy3Id != null){
      setCmbDepearment(departmentDatas,displayHierarchy2Id,4)
    }else if(flag){
      setDefalt(3)
      flag = false
    }
    setHierarchy4Id(displayHierarchy4Id)
    
    onCloseFunc()
  }
  return (
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='部門'
      aria-describedby='部門情報ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap>
    <div className={classes.paper}>
      <ContentWrap>
        <TableWrap>
        <label>会社</label>
        <Input type='text' name='departmentName' value={postName} disabled={true}
           width='20rem'
           margin='0 0 12px 20px'
           />
        </TableWrap>
        <TableWrap>
        <label>部門1</label>
        <Select
                name={'hierarchy1'}
                width='20rem'
                margin='0 0 12px 12px'
                selectValue={hierarchy1Id}
                disabled={hierarchy1RowsStatus}
                onChange={onChangeHierarchy1}
              >{hierarchy1Rows}</Select>
        </TableWrap>
        <TableWrap>
        <label>部門2</label>
        <Select
                name={'hierarch2'}
                width='20rem'
                margin='0 0 12px 12px'
                selectValue={hierarchy2Id}
                disabled={hierarchy2RowsStatus}
                onChange={onChangeHierarchy2}
              >{hierarchy2Rows}</Select>
        </TableWrap>
        <TableWrap>
        <label>部門3</label>
        <Select
                name={'hierarchy3'}
                width='20rem'
                margin='0 0 12px 12px'
                selectValue={hierarchy3Id}
                disabled={hierarchy3RowsStatus}
                onChange={onChangeHierarchy3}
              >{hierarchy3Rows}</Select>
        </TableWrap>
        <TableWrap>
        <label>部門4</label>
        <Select
                name={'hierarchy4'}
                width='20rem'
                margin='0 0 12px 12px'
                selectValue={hierarchy4Id}
                disabled={hierarchy4RowsStatus}
                onChange={onChangeHierarchy4}
              >{hierarchy4Rows}</Select>
        </TableWrap>
      </ContentWrap>
      <ButtonsWrap>
        <Button type='button' name='選択' padding='0px' onClick={()=>disabled?onClose():onSelect()}/>
        <Button type='button' name='閉じる' padding='0px' onClick={()=>onClose()}/>
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

SearchDepartmentDialog.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedAttendanceFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    iconName: PropTypes.string,
    text: PropTypes.string,
    dailyWorkInfoList: PropTypes.array
}

export default SearchDepartmentDialog