/* eslint-env browser */
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { PostApi, GetApi, exportErrorLog, forceLogout, GetToken } from '../Common/ApiAxios'
import useReactRouter from 'use-react-router'
//import logo from '../../image/svg/whiteLogo.svg'
import '../../css/Login.scss'
import backImage from '../../image/login_background_image.png'
//import logoRole from '../../image/svg/logoRole.svg'
import { useAlert } from 'react-alert'
import { useForm } from 'react-hook-form'
import { InputGroup } from '../Common/CommonInputGroup'
import NormalButton from '../Common/Atoms/NormalButton'
import * as regex from '../Common/Regex'
import { purgeStoredState } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { clearAuthCookie, setAccessTokenCookie, setRefreshTokenCookie } from '../Common/utils'

import LogImg from '../../image/logo.png'

// const ImagesLoaded = require('imagesloaded')

const LoginWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-flow: column;
  /* background-image: url('${backImage}'); */
  background-repeat: no-repeat;
  background-position: center;
  background-color:#eeeeee;
  
`
const FlexStyle = styled.div`
  width: 600px;
  height: 450px;
  background-color:#eeeeee;
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: 5% top;
  color:#000;
`
const LoginImageArea = styled.div`
  width:12vw;
  img {
    margin-top: 8vh;
    margin-bottom: 5vh;
    max-width: 100%;
    height: auto;
    width: auto;
  }
`
const LoginArea = styled.div`
  display: flex;
  height:100%;
  margin-left:auto;
  margin-right:auto;
  padding-top:20px;
  flex-direction: column;
  align-items: center;
`
const SiteInfoBlock = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background: ${({ src }) => `url(${src})` || 'linear-gradient(-90deg, #222222, #333333)'};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`
const InputArea = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  
  >label {
    margin-left:0.5rem;
    font-size: 16px;
    font-weight: bold;
  }
`
// const Remember = styled.div`
//   position: relative;
//   margin: 1vh 0;
//   font-size: .6em;
//   width: 272px;
// `
const LoginButtonArea = styled.div`
  box-sizing:border-box;
 `
const CopyRightArea = styled.div`
    margin-top: auto;
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
`
const CopyrightLabel = styled.label`
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.2px;
`

async function checkAuth (TokenInCookie) {
  const apiUrl = '/api/auth'
  const data = { accessToken: TokenInCookie }
  const result = await GetApi(apiUrl, data)
  if (result.errorDetail) {
    exportErrorLog(result)
    const errorStatus = result.errorDetail.response && result.errorDetail.response.status
    if (errorStatus === 401) {
      forceLogout()
    } else {
      alert('認証に失敗しました。')
    }
    clearAuthCookie()
    window.location.href = '/login'
    return false
  }
  return true
}

// 認証判定
export const Auth = (props) => {
  const { location } = useReactRouter()
  const [urlPath, setUrlPath] = useState('/')
  const accessToken = GetToken()
  if (urlPath !== location.pathname) {
    setUrlPath(location.pathname)
    const isAuth = checkAuth(accessToken)
    return (
      // eslint-disable-next-line react/prop-types
      isAuth ? props.children : <Redirect to='/login' />
    )
  } else {
    return props.children
  }
}

// ログイン画面
function Login (props) {
  // const [infoTitle, setInfoTitle] = useState('')
  // const [infoBody, setInfoBody] = useState([])
  const alert = useAlert()
  const dispatch = useDispatch()

  // react-hook-form 関連
  const { register, handleSubmit, errors } = useForm({ validateCriteriaMode: 'all', reValidateMode: 'onChange' })

  const onSubmit = useCallback(async (data, event) => {
    event.preventDefault()
    const apiUrl = '/api/login'
    const result = await PostApi(apiUrl, data)
    if (result.errorDetail) {
      exportErrorLog(result)
      var rememberLoginExpires = new Date()
      rememberLoginExpires.setMinutes(rememberLoginExpires.getMinutes() + (60 * 24 * 7))
      clearAuthCookie()
      alert.error('ログインに失敗しました')
    } else {
      setAccessTokenCookie(result.data.accessToken)
      setRefreshTokenCookie(result.data.refreshToken)
      // getLoginUserInfo()
      const persistConfig = {
        key: 'root',
        version: 1,
        storage
      }
      purgeStoredState(persistConfig)
      // eslint-disable-next-line react/prop-types
      //props.history.push('/shift')
      //props.history.push('/attendance');
      props.history.push('/top');
      //console.log('ok')
    }
  }, [props, alert])

  useEffect(() => {
    document.title = 'Core Time Cloud'
  }, [])

  return (
    <LoginWrap id='LoginWrap'>
      <form id='loginForm' onSubmit={handleSubmit(onSubmit)}>
        <FlexStyle>
          <LoginArea>
            <img src={LogImg} height='60px' />
            <InputArea style={{marginTop:'2rem'}}>
              <label>ユーザーID</label>
              <InputGroup
                autoFocus
                width='300px'
                margin='0.5rem 0px 0px 4px'
                type='text'
                name='loginId'
                placeholder='ユーザーID'
                register={register}
                rule={{
                  required: regex.required,
                  maxLength: regex.maxLength(10)
                }}
                errors={errors}
              />
            </InputArea>
            <InputArea style={{marginTop:'1rem'}}>
              <label>パスワード</label>
              <InputGroup
                autoFocus
                width='300px'
                margin='0.5rem 0px 0px 4px'
                type='password'
                name='password'
                placeholder='パスワード'
                register={register}
                rule={{
                  required: regex.required,
                  maxLength: regex.maxLength(255)
                }}
                errors={errors}
              />
            </InputArea>
            <LoginButtonArea style={{marginTop:'1rem'}}>
              <NormalButton type='submit' width='300px' name='ログイン' />
            </LoginButtonArea>
            {/*<a href='/auth/google'>Google でサインイン</a>*/}
            <CopyRightArea>
              <CopyrightLabel>&copy; {(new Date().getFullYear())} PAL Co., Ltd.</CopyrightLabel>
            </CopyRightArea>
          </LoginArea>
          <SiteInfoBlock src={`${process.env.PUBLIC_URL}/loginImage.jpg`}>
            {/* <div className='site-info'>
              <div className='info_title'><h3>{infoTitle}</h3></div>
              <div>
                <span className='info_body'>
                  {infoBody.map((body, index) => {
                    return (
                      <p key={index}>{body}</p>
                    )
                  })}
                </span>
              </div>
            </div> */}
          </SiteInfoBlock>
        </FlexStyle>
      </form>
    </LoginWrap>
  )
}

export default withRouter(Login)
