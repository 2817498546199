import styled from 'styled-components'

const MasterEditButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 80vw;
  margin-top: 1rem;

  button {
    margin: 0 1vw 12px 0;
    :last-child {
      margin: 0 0 12px 0;
    }
  }
`
export default MasterEditButtonsWrap
