import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import { InputGroup, HiddenInput } from '../../../Common/CommonInputGroup'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'

import { ColumnStaffTD, ColumnWorkTypeTD } from '../../Wrap/DailyTableWrap'
import TransportationModal from '../TransportationDialog/TransportationModal'

const DailyTableRow = ({ 
    register, getValues, setValue, watch, reset, errors, workInfo, selectFunction,
    passDivisions,transportationPublic,transportationPraivate, gasCost}) => {

  const elementId = workInfo.staffId
  const [transportation,setTransportation] = useState('')
  // 支給控除情報が変更された場合のみ実行
  useEffect(() => {
    // 支給控除情報が変更された場合のみ実行
    if (workInfo) {
      console.log(workInfo)
      // 支給控除情報が変更された場合のみ実行

      //初期値設定
      if(workInfo.daily==null) workInfo.daily=0
      if(workInfo.monthly==null) workInfo.monthly=0
      if(workInfo.housingAllowance==null) workInfo.housingAllowance=0
      if(workInfo.fullTimeAllowance==null) workInfo.fullTimeAllowance=0
      if(workInfo.familyAllowance==null) workInfo.familyAllowance=0
      if(workInfo.jobAllowance==null) workInfo.jobAllowance=0
      if(workInfo.specialAllowance==null) workInfo.specialAllowance=0
      if(workInfo.transportationExpenses==null) workInfo.transportationExpenses=0

      setValue('daily_' + elementId, Number(workInfo.daily).toLocaleString())
      setValue('monthly_' + elementId,Number(workInfo.monthly).toLocaleString())
      setValue('housingAllowance_' + elementId, Number(workInfo.housingAllowance).toLocaleString())
      setValue('fullTimeAllowance_' + elementId, Number(workInfo.fullTimeAllowance).toLocaleString())
      setValue('familyAllowance_' + elementId, Number(workInfo.familyAllowance).toLocaleString())
      setValue('jobAllowance_' + elementId, Number(workInfo.jobAllowance).toLocaleString())
      setValue('specialAllowance_' + elementId,Number(workInfo.specialAllowance).toLocaleString())
      setValue('transportationExpenses_' + elementId, Number(workInfo.transportationExpenses).toLocaleString())
      setTransportation(Number(workInfo.transportationExpenses).toLocaleString())
    }
  // eslint-disable-next-line
  }, [workInfo])

  const createElementStaff = (staff) => {
    //console.log(staff)
    if (staff) {
      let text = staff.staffName
      let textPx = measurePxText(text)
      let textRem = convertPxToRem(textPx)
  
      let checkRem = 7
      if (textRem < checkRem)
      {
        return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...')
        textRem = convertPxToRem(textPx)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></span></Tooltip>
    }

    return <><br/><span>( - )</span></>
  }

  const createElementMoney = (workInfo,name) => {
    if (workInfo) {
        return <>
            <InputGroup
                name={name +'_'+ elementId}
                width='6rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={false}
                onBlur={onBlurAmount}
            />
           </>
    }

    return <></>
  }
  const createElementCloseMoney = (workInfo,name) => {
    if (workInfo) {
        return <>
            <InputGroup
                name={name +'_'+ elementId}
                width='6rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={true}
                onBlur={onBlurAmount}
            />
           </>
    }

    return <></>
  }

  const createElementButtons = (workInfo) => {
    if (workInfo) {
        return <>
            <button style={{margin:'auto', padding:'0', minWidth:'6rem'}} onClick={handleOpen}>{transportation}</button>
           </>
    }

    return <></>
  }

  const onBlurAmount = ( event ) => {
    let value = event.target.value
    value = Number(value.replace(/,/g, '')).toLocaleString()
    if(value=='NaN') value = '0'

     workInfo.isDiff = true
    var name = event.target.name.split('_')[0]
    workInfo[name] = value.replace(/,/g, '')

    event.target.value = value
  }

  const [open, setOpen] = useState(false);
  const handleClose = (sum) => {
    console.log('OK')
    setValue('transportationExpenses_' + elementId, Number(sum).toLocaleString())
    setTransportation(Number(sum).toLocaleString())
    workInfo.transportationExpenses = Number(sum)
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true);
  }
  const onClose = () =>{

  }
  return (
    <tr>
      <ColumnStaffTD>{createElementStaff(workInfo)}</ColumnStaffTD>
      <ColumnWorkTypeTD>{workInfo.staffDivisionName}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{createElementMoney(workInfo,'monthly')}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{createElementMoney(workInfo,'daily')}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{createElementMoney(workInfo,'housingAllowance')}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{createElementCloseMoney(workInfo,'fullTimeAllowance')}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{createElementMoney(workInfo,'familyAllowance')}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{createElementMoney(workInfo,'jobAllowance')}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{createElementMoney(workInfo,'specialAllowance')}</ColumnWorkTypeTD>
      <ColumnWorkTypeTD>{createElementButtons(workInfo)}</ColumnWorkTypeTD>
      <ColumnStaffTD/>

      <HiddenInput name={'transportationExpenses_'+elementId} register={register} defaultValue={workInfo.transportationExpenses}/>
      <HiddenInput name={'updatedId_'+elementId} register={register} value={workInfo.updatedId}/>
      <HiddenInput name={'updatedAt_'+elementId} register={register} value={workInfo.updatedAt}/>
      <TransportationModal
          open={open}
          //onCloseFunc={(sum)=>handleClose(sum)}
          onCloseFunc={onClose}
          onHandleClose={(sum)=>handleClose(sum)}
          workInfo={workInfo}
          passDivisions={passDivisions}
          transportationPublic={transportationPublic}
          transportationPraivate={transportationPraivate}
          gasCost={gasCost}
        />
    </tr>
  )
}

DailyTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  workDivisions: PropTypes.any,
  dateDataList: PropTypes.array,
  dailyWorkInfo: PropTypes.object,
  selectFunction: PropTypes.func,

  handleOpenPunchHistoryFunc: PropTypes.func,
  handleClosePunchHistoryFunc: PropTypes.func,
  handleOpenDailyAttendanceFunc: PropTypes.func,
  handleCloseDailyAttendanceFunc: PropTypes.func,
}

export default DailyTableRow
