import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import TableRow from './RestTableRow'

export const TableWrap = styled.table`
font-size: 12px;
border-collapse:separate;
border-spacing: 1px;

thead, tbody {
  display: ${props => props.visible ? 'block' : 'none'};
}

thead {
  width: calc(16rem + 13px)
}

tbody {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(50vh + 1rem + 10px);
}

tbody:hover {
  overflow-y: auto;
}

tbody::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

tbody::-webkit-scrollbar-track {
  border:none;
}

tbody::-webkit-scrollbar-thumb {
  background-color: rgba(212, 212, 212, 1);
  border-radius: 10px;
}

tbody::-webkit-scrollbar-thumb:active {
  background-color: rgba(112, 112, 112, 1);
}

th, td {
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #DDD;
  border-collapse: collapse;
}

th {
  height: 2rem;
  background-color: #649cdf;
}

td {
  height: 3rem;
}

tbody tr {
  :nth-child(even) {
    background: ${props => props.selected ? '#048b63' : '#eee'};
  }
  :hover {
    ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
  }
}
`
const ColumnWrap = styled.th`
  vertical-align:middle;
  padding: 0.25rem;
  height: 2rem;
`
const RestTable = ({
    restList,register, getValues, setValue, watch, reset, errors,workInfo,onDelete,canWrite
  }) => {

  return (
    <TableWrap  visible={restList.length ?true : false}>
        <thead>
            <tr>
                <ColumnWrap style={{width:'4rem'}}>休憩時間</ColumnWrap>
                <ColumnWrap style={{width:'4rem'}}>休始</ColumnWrap>
                <ColumnWrap style={{width:'4rem'}}>休終</ColumnWrap>
                <ColumnWrap style={{width:'4rem'}}></ColumnWrap>
            </tr>
        </thead>
        <tbody>
        {restList && restList.map((restData) => {
            if(restData.isNew==undefined)restData.isNew=false
            return (
              <>
              {(!restData.isDel) && (restData.restNum != null) &&
              <TableRow key={restData.restNum} 
              restData={restData}
              register={register}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              reset={reset}
              errors={errors}
              workInfo={workInfo}
              onDelete={onDelete}
              canWrite={canWrite}
              />
              }
              </>
            )
            
        })}
      </tbody>
    </TableWrap>
  )
}

RestTable.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func
}

export default RestTable
