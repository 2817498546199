import styled from 'styled-components'

const MasterPanelWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #FFF;
  color:#000;
`

export default MasterPanelWrap
