import React from 'react'
import PropTypes from 'prop-types'
//import { makeElementId } from '../../../Common/Utilties/LaborUtility'
import {ColumnStaffTD,ColumnTimeTD} from '../../Wrap/DashboardTableWrap'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
import Tooltip from '@material-ui/core/Tooltip'

const OverTimeTableBody = ({
    register, getValues, setValue, watch, reset, errors,
    defaultUrl, workInfoList,targetDt
  }) => {

    const createElementStaff = (staff) => {
      if (staff) {
        let text = staff.staffName
        let textPx = measurePxText(text)
        let textRem = convertPxToRem(textPx)
    
        let checkRem = 7
        if (textRem < checkRem)
        {
          return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></>
        }
    
        let editedText = text
        while (textRem > checkRem) {
          editedText = editedText.slice(0, -1)
          textPx = measurePxText(editedText + '...')
          textRem = convertPxToRem(textPx)
        }
    
        editedText = editedText + '...'
    
        return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></span></Tooltip>
      }
  
      return <><br/><span>( - )</span></>
    }
    
    const createElementTime=(text,limitType)=>{
        switch(limitType){
          case 0:
            return <>{text}</>
          case 1:
            return <div style={{backgroundColor:'yellow'}}><ColumnTimeTD>{text}</ColumnTimeTD></div>
          case 2:
            return <div style={{backgroundColor:'red'}}><ColumnTimeTD>{text}</ColumnTimeTD></div>
          default:
            return <>{text}</>
        }
    }

  return (
    <tbody>
      {workInfoList && workInfoList.map((workInfo) => {
        return (
          <tr key={workInfo.staffId}>
            <ColumnStaffTD>{createElementStaff(workInfo)}</ColumnStaffTD>
            <ColumnTimeTD>
              {workInfo.overTimeWeek.toFixed(2)}
            </ColumnTimeTD>
            <ColumnTimeTD>
              {createElementTime(workInfo.thisMonth.toFixed(2),workInfo.thisMonthLimitWatch)}
            </ColumnTimeTD>
            <ColumnTimeTD>
              {createElementTime(workInfo.twoMonth.toFixed(2),workInfo.twoMonthLimitWatch)}
            </ColumnTimeTD>
            <ColumnTimeTD>
              {createElementTime(workInfo.threeMonth.toFixed(2),workInfo.threeMonthLimitWatch)}
            </ColumnTimeTD>
            <ColumnTimeTD>
              {createElementTime(workInfo.fourMonth.toFixed(2),workInfo.fourMonthLimitWatch)}
            </ColumnTimeTD>
            <ColumnTimeTD>
              {createElementTime(workInfo.fiveMonth.toFixed(2),workInfo.fiveMonthLimitWatch)}
            </ColumnTimeTD>
            <ColumnTimeTD>
              {createElementTime(workInfo.sixMonth.toFixed(2),workInfo.sixMonthLimitWatch)}
            </ColumnTimeTD>            
            <ColumnTimeTD>
              {createElementTime(workInfo.thisYear.toFixed(2),workInfo.thisYearLimitWatch)}
            </ColumnTimeTD>
            <ColumnTimeTD>{workInfo.specialMonth}</ColumnTimeTD>
          </tr>
        )
      })}
    </tbody>
  )
}

OverTimeTableBody.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array,
  targetDt: PropTypes.any,
}

export default OverTimeTableBody
