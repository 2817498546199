import styled from 'styled-components'

export const RowHeaderTop = styled.tr`
  background-color: #649cdf;
`

export const RowHeaderBottom = styled.tr`
  background-color: #FFE8E6;
`

export const ColumnStaffTH = styled.th`
  width: 10rem;
  vertical-align:middle;
`

export const ColumnWorkTH = styled.th`
  width: 3rem;
  vertical-align:middle;
`

export const ColumnWorkCell = styled.div`
  width: 3rem;
  vertical-align:middle;
`

export const ColumnTimeTH = styled.th`
  width: 4rem;
  vertical-align:middle;
`

export const ColumnTimeCell = styled.div`
  width: 4rem;
  vertical-align:middle;
`

export const ColumnStaffTD = styled.td`
  width: 10rem;
  max-width: 10rem;
  vertical-align:middle;
  padding-left:1rem;
  height: 2rem;
`

export const ColumnWorkTD = styled.td`
  width: 3rem;
  vertical-align: middle;
  text-align: right;
`

export const ColumnWorkDiv = styled.div`
  width: 3rem;
  vertical-align: middle;
  text-align: right;
  height 1rem;
`

export const ColumnWorkCellTD = styled.td`
  width: 3rem;
  vertical-align: middle;
  text-align: right;
  padding-right: 1rem;
  height 1rem;
`

export const ColumnTimeTD = styled.td`
  width: 4rem;
  vertical-align: middle;
  text-align: right;
`


const StaffBiometricTableWrap = styled.table`
  
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(116rem + 10px + 12px);
  }

  tbody {
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(85vh + 8px - 7rem - 2rem - 4rem);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
    font-size: 12px;
  }

  td {
    font-size: 16px;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

export default StaffBiometricTableWrap
