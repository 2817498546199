const Config = () => {
    if (process.env.NODE_ENV === 'test') {
      // test
      return {
        restful: {
          host: process.env.REACT_APP_TEST_API_URL
        }
      }
    } else if (process.env.NODE_ENV === 'production') {
      // production
      return {
        restful: {
          host: process.env.REACT_APP_PROD_API_URL
        }
      }
    }
    // development
    return {
      restful: {
        //host: process.env.REACT_APP_DEV_API_URL
        host: 'http://localhost:8080'
      }
    }
  }
  export const restfulApiConfig = Config().restful
  