import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { MultipartApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../../Common/ErrorHandling'
import Button from '../../../Common/Atoms/NormalButton'

import {dateFormat,diffFormatRound48Time,calcWorkingSeconds,makeElementIdByDailyWorkInfo, calcClockWorkingSeconds,calcWorkingRestFreeSeconds,timeToSec} from '../../../Common/Utilties/LaborUtility'

import PunchTable from './PunchTable'


const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.table`
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  display: flex;
  padding: 1vw;
`
const FormWrap = styled.form`
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
  padding: 1vw;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 1vw;
`

const PunchHistoryModal = ({ open, onCloseFunc, updatedPunchFunc, defaultUrl, workInfo,punchDailyList,onCloseAttendanceFunc,restFlag}) => {
  const [date, setData] = useState('')
  const [staffId, seStaffId] = useState('')
  const [staffName, seStaffName] = useState('')
  const [punchDailyDataList, setPunchDailyDataList] = useState([])
  const [actionType, setActionType] = useState('POST')
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();
  const alert = useAlert()
  const dispatch = useDispatch()
  const [canUpdate, setCanUpdate] = useState(true)
  // const getPunchData = async (workInfo) => {
  //   dispatch(SetLoadSpinner(true))

  //   const searchOption = {
  //     targetDt: workInfo.baseDt,
  //     staffId: workInfo.staffId,
  //     orderId: workInfo.orderId
  //   }
  //   const resultDate = await GetApi('/api/punchManagement/punch/daily', searchOption)
    
  //   dispatch(SetLoadSpinner(false))
  //   if (resultDate.errorDetail) {
  //     ErrorHandling(resultDate, alert)
  //   } else {
  //     console.log(resultDate.data.responseList.length>0)
  //     setCanUpdate(resultDate.data.responseList.length>0)
  //     setPunchDailyDataList(resultDate.data.responseList)
  //   }
  // }

  useEffect(() => {
    if (!workInfo) {
        return
    }
    setData(workInfo.baseDt.replace(/-/g, '/'))
    seStaffId(workInfo.staffId)
    seStaffName(workInfo.staffName)
    setPunchDailyDataList(punchDailyList)
    console.log(punchDailyList)
    //getPunchData(workInfo)

  }, [workInfo])

  
  useEffect(() => {
    if (!punchDailyList) {
        return
    }
    setPunchDailyDataList(punchDailyList)
    console.log(punchDailyList)
  }, [punchDailyList])

  useEffect(() => {
  }, [])
  
  // POST・PUT処理
  const onSubmit = async (data) => {
    async function sendPost (requestBody, data) {
      var resultDate
      dispatch(SetLoadSpinner(true))

      dispatch(SetLoadSpinner(true))
      resultDate = await MultipartApi(`/api/punchManagement/punch/`,actionType, submitData, data)
      dispatch(SetLoadSpinner(false))
      if (resultDate.errorDetail) {
          ErrorHandling(resultDate, alert)
      } else {
          setPunchDailyDataList(resultDate.data.responseList)
          updatedPunchFunc()
          onCloseFunc()
          onCloseAttendanceFunc()
          alert.success('保存しました')
      }
    }
    console.log(data)
    const elementId = makeElementIdByDailyWorkInfo(workInfo)
    var punchList= new Array()
    let dailyRow = {
      orderId: workInfo.orderId, //getValues('orderId_'+id),
      staffId: workInfo.staffId, //getValues('staffId_'+id),
      baseDt: workInfo.baseDt, //getValues('baseDt_'+id),
      
      workDivision: workInfo.attendanceWorkDivision,

      clockWorkStartDt: '',
      clockWorkEndDt: '',
      clockWorkTotalDt: 0,
      clockRestStart: new Array(),
      clockRestEnd: new Array(),
      clockRestTotalDt: 0,
      

      workStartDt: '' ,
      workEndDt: '',
      workTotalDt: 0,

      restStart: new Array(),
      restEnd: new Array(),
      restTotalDt: 0,//workInfo.attendanceRestTotalDt,
      overTotalDt: 0,//workInfo.attendanceOverTotalDt,

      addRestList:[],
      deleteRestList:[],
      updateRestList:[],

      remark: workInfo.attendanceRemark,
      updatedAt: dateFormat(workInfo.attendanceUpdatedAt)
    }

    var errorFlg = false 
    var count = {"01":0,"02":0,"03":0,"04":0}
    punchDailyDataList.map(data=>{
      data.punchInfoList.map(value =>{ 
        console.log(value)
        const checkbox = document.getElementsByName('switch_'+value.biometricId)
        const newFlg = (checkbox[0].checked?0:1)
        count[value.biometricState]+=newFlg
        if((value.biometricState =='01' || value.biometricState=='02'))
        {
          if(count[value.biometricState]>=2)
          {
            errorFlg = true
            return
          }
        }
        else
        {
          if(count[value.biometricState]>=4 && !errorFlg)
          {
            errorFlg = true
            return
          }
        }
      })
      if(errorFlg){
        return
      }
    })
    if(errorFlg){
      alert.error("同じ項目は選択できません\r\n")
      return
    }
    
    var restList = new Array()
    var addRestList = new Array()
    var deleteRestList = new Array()
    var updateRestList = new Array()

    let fractionUnit = parseInt(workInfo.attendanceFractionUnit)
    let nightTime=[diffFormatRound48Time('1900/01/01 00:00:00',workInfo.nightStartDt,fractionUnit),
    diffFormatRound48Time('1900/01/01 00:00:00',workInfo.nightEndDt,fractionUnit)]
    var cooperationExist = false
    punchDailyDataList.map(data=>{
      data.punchInfoList.map(value =>{ 
        //checkボックスのデータ取得
        const checkbox = document.getElementsByName('switch_'+value.biometricId)
        const newFlg = (checkbox[0].checked?0:1)

        if(newFlg==1)
        {
          cooperationExist = true
          switch (value.biometricState) {
            case '01':
              dailyRow.clockWorkStartDt=dateFormat(value.biometricDt)
              dailyRow.workStartDt=diffFormatRound48Time(workInfo.baseDt,value.biometricDt,fractionUnit)
              break
            case '02':
              dailyRow.clockWorkEndDt=dateFormat(value.biometricDt)
              dailyRow.workEndDt=diffFormatRound48Time(workInfo.baseDt,value.biometricDt,fractionUnit*(-1))
              break
            case '03':
              dailyRow.clockRestStart.push(dateFormat(value.biometricDt))
              dailyRow.restStart.push(diffFormatRound48Time(workInfo.baseDt,value.biometricDt,fractionUnit*(-1)))
              break
            default:
              dailyRow.clockRestEnd.push(dateFormat(value.biometricDt))
              dailyRow.restEnd.push(diffFormatRound48Time(workInfo.baseDt,value.biometricDt,fractionUnit))
              break
          }
        }  

        if(value.cooperationFlag!=newFlg){
          let punchRow = {
            biometricId: value.biometricId,
            baseDt: workInfo.baseDt,
            cooperationFlg: newFlg,
            updatedAt: dateFormat(value.updatedAt)
          }
          punchList.push(punchRow)
        }
         
      }) 
    })
    if(cooperationExist)
    {
      if(workInfo.attendanceWorkDivision == 1 || workInfo.attendanceWorkDivision > 4)
      {
        dailyRow.workDivision = 2
      }
      
      //勤務時間,休憩時間計算
      let result = calcWorkingSeconds([dailyRow.workStartDt, dailyRow.workEndDt], 
          [dailyRow.restStart[0], dailyRow.restEnd[0], dailyRow.restStart[1], 
          dailyRow.restEnd[1], dailyRow.restStart[2], dailyRow.restEnd[2]], workInfo.attendanceBaseTime)
      if(restFlag){
        var restLength = dailyRow.restStart.length>dailyRow.restEnd.length?dailyRow.restStart.length:dailyRow.restEnd.length
        var restList = []
        for(var i=0;i<restLength;i++){
          restList.push(dailyRow.restStart[i])
          restList.push(dailyRow.restEnd[i])
        }
        result =calcWorkingRestFreeSeconds([dailyRow.workStartDt, dailyRow.workEndDt], restList, workInfo.attendanceBaseTime)
      }
      
      dailyRow.workTotalDt = result[0]
      dailyRow.restTotalDt = result[1]
      dailyRow.overTotalDt = result[2]

      //勤務時間,休憩時間計算
      result = calcClockWorkingSeconds(
        [dailyRow.clockWorkStartDt, dailyRow.clockWorkEndDt], 
        [dailyRow.clockRestStart[0], dailyRow.clockRestEnd[0], dailyRow.clockRestStart[1], 
        dailyRow.clockRestEnd[1], dailyRow.clockRestStart[2], dailyRow.clockRestEnd[2]])
      if(restFlag){
          var restLength = dailyRow.clockRestStart.length>dailyRow.clockRestEnd.length?dailyRow.clockRestStart.length:dailyRow.clockRestEnd.length
          var restList = []
          for(var i=0;i<restLength;i++){
            var restLength = dailyRow.clockRestStart.length>dailyRow.clockRestEnd.length?dailyRow.clockRestStart.length:dailyRow.clockRestEnd.length
            restList.push(dailyRow.clockRestStart[i])
            restList.push(dailyRow.clockRestEnd[i])
          }
          result =calcWorkingRestFreeSeconds([dailyRow.clockWorkStartDt, dailyRow.clockWorkEndDt], restList, workInfo.attendanceBaseTime)
      }
      dailyRow.clockWorkTotalDt = result[0]
      dailyRow.clockRestTotalDt = result[1]
    
      console.log(restFlag)
      //休憩追加
      if(restFlag){
        var restLength = dailyRow.restStart.length>dailyRow.restEnd.length?dailyRow.restStart.length:dailyRow.restEnd.length
        var restList = []
        
        for(var i=0;i<restLength;i++){
          let restRow = {
            orderId: workInfo.orderId, //getValues('orderId_'+id),
            staffId: workInfo.staffId, //getValues('staffId_'+id),
            baseDt: workInfo.baseDt, //getValues('baseDt_'+id),
            restNum: i,

            attendanceRestStartDt: dailyRow.restStart[i],
            attendanceRestEndDt: dailyRow.restEnd[i],
            attendanceRestTotalDt: timeToSec(dailyRow.restEnd[i]) - timeToSec(dailyRow.restStart[i]),

            clockWorkTotalDt:(new Date(dailyRow.clockRestEnd[i]).getTime() - new Date(dailyRow.clockRestStart[i]).getTime())/1000,
            attendanceRestClockStartDt:dailyRow.clockRestStart[i],
            attendanceRestClockEndDt:dailyRow.clockRestEnd[i],
          }
          dailyRow.addRestList.push(restRow)
        }
        workInfo.restList.map((rest)=>{if(rest.restNum != null) dailyRow.deleteRestList.push(rest)})
        console.log(new Date(dailyRow.clockRestEnd[0]).getTime(),new Date(dailyRow.clockRestStart[0]).getTime())
        console.log(workInfo.restList)
      }
    }
    else
    {
      dailyRow.workDivision = 1
      workInfo.restList.map((rest)=>{if(rest.restNum != null) dailyRow.deleteRestList.push(rest)})
      console.log(workInfo.restList)
    }
    data={
      formData:dailyRow ,
      punchInfo:punchList
    }
    console.log(workInfo)
    console.log(data)
    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    sendPost(submitData, data)
  }
  return (
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='打刻履歴'
      aria-describedby='打刻履歴ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap onSubmit={handleSubmit(onSubmit)} className='h-dsm'>
    <div className={classes.paper}>
      <ContentWrap>
      <TitleWrap>
          <label>{date} {staffId} {staffName}</label>
      </TitleWrap>
      <TableWrap>
          <PunchTable
            register = {register}
            punchDailyDataList={punchDailyDataList}
            getValues={getValues}
            setValue={setValue}
          >
          </PunchTable>
      </TableWrap>
      </ContentWrap>
      <ButtonsWrap>
        <Button name='更新' padding='0 0 12px 0' type='submit' display={canUpdate?'':'none' }/>
        <Button name='閉じる' type='button' padding='0px' onClick={onCloseFunc} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

PunchHistoryModal.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedPunchFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    workInfo: PropTypes.object,

    onCloseAttendanceFunc: PropTypes.func,
}

export default PunchHistoryModal
