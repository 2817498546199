import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

const CellWrap = styled.th`
  width: 2rem;
  height: 2rem;
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
`

const ColumnDate = styled.div`
  text-align: center;
  padding-top 0.25rem;
  padding-bottom 0.25rem;
`

const ColumnWeek = styled.div`
  text-align: center;
  padding-top 0.25rem;
  padding-bottom 0.25rem;
`


const MonthTableHeaderDateCell = ({ data,onClick }) => {

  const weekColor = (dateInfo) => {
    switch(dateInfo.publicHoliday) {
        case '0':
            switch(dateInfo.week)
            {
                case '土':
                    return '#00F'
                case '日':
                    return '#F00'
            }
            return '#000'
        case '1':
            return '#F00'
    }
  
    return '#000'
  }
  
  return (
    <CellWrap onClick={(e)=>onClick(e,data)}>
      {data.note &&
        <Tooltip title={data.note}>
          <CellPanel>
            <ColumnDate>{data.dateValue.split('-')[2]}</ColumnDate>
            <ColumnWeek style={{ color: weekColor(data) }}>{data.week}</ColumnWeek>
          </CellPanel>
        </Tooltip>
      }
      {!data.note &&
        <CellPanel>
          <ColumnDate>{data.dateValue.split('-')[2]}</ColumnDate>
          <ColumnWeek style={{ color: weekColor(data) }}>{data.week}</ColumnWeek>
        </CellPanel>
      }
    </CellWrap>
  )
}

MonthTableHeaderDateCell.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
}

export default MonthTableHeaderDateCell
