import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'
import ErrorHandling from '../../../Common/ErrorHandling'

import DailyTableRow from './DailyTableRow'

const DailyTableBody = ({ 
  register, getValues, setValue, watch, reset, errors, 
  dateDataList, staffWorkInfoList, selectFunction, updatedAttendanceFunc, defaultUrl,
  handleOpenPunchHistoryFunc, handleClosePunchHistoryFunc, handleOpenDailyAttendanceFunc, handleCloseDailyAttendanceFunc,restFlag }) => {
  const [workDivisions, setWorkDivisions] = useState(<></>)

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
      await getWorkDivisions()
    }
    getOptions()
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  // 出勤区分取得
  const getWorkDivisions = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/workDivisions')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setWorkDivisions(options)
    }
  }, [setWorkDivisions, alert])

  return (
    <tbody>
    {staffWorkInfoList && staffWorkInfoList.map((staff) => {
      return (
        staff.dailyWorkInfoList.map((dailyData) => {
          return (
            <DailyTableRow key={staff.staffId+dailyData.workInfoList[0].baseDt}
              register={register}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              reset={reset}
              errors={errors}

              workDivisions={workDivisions}
              dateDataList={dateDataList}
              dailyWorkInfo={dailyData}

              handleOpenPunchHistoryFunc={handleOpenPunchHistoryFunc}
              handleClosePunchHistoryFunc={handleClosePunchHistoryFunc}
              handleOpenDailyAttendanceFunc={handleOpenDailyAttendanceFunc}
              handleCloseDailyAttendanceFunc={handleCloseDailyAttendanceFunc}

              restFlag={restFlag}
            >
            </DailyTableRow>
          )
        })
      )
    })}
    </tbody>
  )
}

DailyTableBody.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedAttendanceFunc: PropTypes.func,
  defaultUrl: PropTypes.string,

  handleOpenPunchHistoryFunc: PropTypes.func,
  handleClosePunchHistoryFunc: PropTypes.func,
  handleOpenDailyAttendanceFunc: PropTypes.func,
  handleCloseDailyAttendanceFunc: PropTypes.func,
}

export default DailyTableBody
