import React from 'react'
import  { ColumnTH , ColumnNameTH} from '../../../Wrap/MasterDialogWrap'
const DailyTableHeader = () => {

  return (
    <thead>
      <tr>
        <ColumnTH>受注ID</ColumnTH>
        <ColumnTH>契約区分</ColumnTH>
        <ColumnNameTH>会社部門</ColumnNameTH>
        <ColumnNameTH>顧客部門</ColumnNameTH>
        <ColumnTH>契約開始日</ColumnTH>
        <ColumnTH>契約終了日</ColumnTH>
        <ColumnTH>引当人数</ColumnTH>
        <ColumnTH></ColumnTH>
      </tr>
    </thead>
  )
}

DailyTableHeader.propTypes = {
}

export default DailyTableHeader
