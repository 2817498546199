import React,{useState,useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Tooltip from '@material-ui/core/Tooltip'

import TableCell from './BulkEditTableCell'

import DailyShiftModal from '../DailyBulkEditModel/DailyShiftModal'
import Button from '../../../Common/Atoms/NormalButton'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
import { diff48Time, format48Time, formatHour, makeElementIdByDailyWorkInfo, calcWorkingSeconds ,formatRound48Time} from '../../../Common/Utilties/LaborUtility'

const ColumnWrap = styled.td`
  vertical-align:middle;
  padding: 0.25rem;
  height: 3rem;
`

const ColumnCellWrap = styled.td`
vertical-align:middle;
height: 3rem;
width: ${props => props.width};
`

const TableRow = ({ workInfoList,dateDataList,workDivisions, updatedShiftFunc}) => {
  const [checked,setChecked] = useState(false)
  const [color,setColor] = useState('black')
  useEffect(() => {
    if(workInfoList && workInfoList.shiftBulkList.length===1){ 
      setChecked(workInfoList.checked)
    }
  }, [workInfoList.checked])
  useEffect(() => {
    if(workInfoList && workInfoList.shiftBulkList.length===1){ 
      setColor(workInfoList.isDiff?'red':'black')
      console.log(workInfoList)  
      
      // if(workInfoList.shiftNewList && !workInfoList.isDiff)
      // {
        
      //   let fractionUnit = parseInt(workInfoList.shiftBulkList[0].attendanceFractionUnit)
      //   var newList = workInfoList.shiftNewList
  
      //   newList.shiftWorkStartDt = formatRound48Time(newList.shiftWorkStartDt,fractionUnit)
      //   newList.shiftWorkEndDt = formatRound48Time(newList.shiftWorkEndDt,fractionUnit*(-1))

      //   newList.shiftRestStart1Dt = formatRound48Time(newList.shiftRestStart1Dt ,fractionUnit*(-1))
      //   newList.shiftRestEnd1Dt = formatRound48Time(newList.shiftRestEnd1Dt ,fractionUnit)
      //   newList.shiftRestStart2Dt = formatRound48Time(newList.shiftRestStart2Dt ,fractionUnit*(-1))
      //   newList.shiftRestEnd2Dt = formatRound48Time(newList.shiftRestEnd2Dt ,fractionUnit)
      //   newList.shiftRestStart3Dt = formatRound48Time(newList.shiftRestStart3Dt ,fractionUnit*(-1))
      //   newList.shiftRestEnd3Dt = formatRound48Time(newList.shiftRestEnd3Dt ,fractionUnit)

      //   if(newList.shiftWorkStartDt && newList.shiftWorkEndDt )
      //   {
      //     //勤務時間,休憩時間計算
      //     let result = calcWorkingSeconds([newList.shiftWorkStartDt,newList.shiftWorkEndDt ], 
      //       [newList.shiftRestStart1Dt,newList.shiftRestEnd1Dt,
      //       newList.shiftRestStart2Dt,newList.shiftRestEnd2Dt,
      //       newList.shiftRestStart3Dt,newList.shiftRestEnd3Dt,], 
      //       workInfoList.shiftBulkList[0].attendanceBaseTime)
      //     newList.shiftWorkTotalDt = result[0]
      //     newList.shiftRestTotalDt = result[1]
      //     newList.shifOverTotalDt = result[2]
        
      //     workInfoList.shiftNewList=newList
      //     console.log(workInfoList)  
      //   }

        
      // }
    }

  }, [workInfoList.isDiff])

  const formatDate = (dateInfo) => {
    var date = new Date(dateInfo.dateValue);
    return ('00' + (date.getMonth() + 1)).slice(-2) + '/' + ('00' + date.getDate()).slice(-2)
  }

  const weekColor = (dateInfo) => {
    switch(dateInfo && dateInfo.publicHoliday) {
        case '0':
            switch(dateInfo.week)
            {
                case '土':
                    return '#00F'
                case '日':
                    return '#F00'
            }
            return '#000'
        case '1':
            return '#F00'
    }

    return '#000'
  }
  const findDateInfo = (dateList, date) => {
    var day = undefined
    dateList.forEach(dateInfo => {
        if (dateInfo.dateValue === date) {
            day = dateInfo
        }
    })

    return day
  }
  
  const createElementStaff = (staff) => {
    if (staff) {
      let text = staff.staffName
      let textPx = measurePxText(text)
      let textRem = convertPxToRem(textPx)
  
      let checkRem = 7
      if (textRem < checkRem)
      {
        return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...')
        textRem = convertPxToRem(textPx)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></span></Tooltip>
    }

    return <><br/><span>( - )</span></>
  }

  const createElementOccupation = (workInfoList) => {
    if (workInfoList && workInfoList.length===1) {
      let attendanceInfo = workInfoList[0]
      let text = attendanceInfo.occupation
      let textPx = measurePxText(text)
      let textRem = convertPxToRem(textPx)
  
      let checkRem = 7
      if (textRem < checkRem)
      {
        return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({attendanceInfo.orderId})</span></>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...')
        textRem = convertPxToRem(textPx)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({attendanceInfo.orderId})</span></span></Tooltip>
    }

    return <><br/><span>( - )</span></>
  }

  const createElementDate = (dateList, date) => {
    if (!dateList || dateList.length === 0 || !date) {
        return <></>
    }

    var dateInfo = findDateInfo(dateList, date)
    if (!dateInfo) {
        return <></>
    }

    if (!dateInfo.note)
    {
      return <>
        {formatDate(dateInfo)} (<span style={{ color: weekColor(dateInfo) }}>{dateInfo.week}</span>)
      </>
    }

    return <Tooltip title={dateInfo.note}>
             <span>{formatDate(dateInfo)} (<span style={{ color: weekColor(dateInfo) }}>{dateInfo.week}</span>)</span>
           </Tooltip>
  }

  const createElementTotalTime = (shiftTimes,newShiftTime,title) => {
    //var color = (newShiftTime)?'red':'black'
    var checkNull = null
    if(shiftTimes.length==1){ 
      var shiftTime = shiftTimes[0][title]
      checkNull = shiftTimes[0].shiftWorkTotalDt
    }
    else return <TableCell  shiftDateCount={'--.--'} attendanceDateCount={'--.--'} color={color}></TableCell>
    var shift = formatHour(shiftTime)
    var newShift = null
    if(newShiftTime && newShiftTime[title]) {
      newShift = formatHour(newShiftTime[title])
    }
    if (newShiftTime==null || newShift==null){
      newShift='--.--'
    }
    if (shiftTime==null || checkNull==null || checkNull==0) {
      shift='--.--'
    }
    if(shiftTimes[0]==newShiftTime)newShift=shift

    return <TableCell  shiftDateCount={shift} attendanceDateCount={newShift} color={color}></TableCell>
  }
  const createElementOverTotalTime = (shiftTimes,newShiftTime) => {

    var checkNull = null
    if(shiftTimes.length==1) {
      checkNull = shiftTimes[0].shiftWorkTotalDt
      var shiftTime = shiftTimes[0].shiftOverTotalTime
    }
    else{
      return <TableCell  shiftDateCount={'--.--'} attendanceDateCount={'--.--'} color={color}></TableCell>
    }
    var shift = formatHour(shiftTime)
    var newShift = null
    if(newShiftTime && newShiftTime.shifOverTotalDt) {
      newShift=formatHour(newShiftTime.shifOverTotalDt)
    }
    if (newShiftTime==null || newShift==null) {
      newShift='--.--'
    }
    if (checkNull==null || checkNull==0) {
      shift='--.--'
    }

    if(shiftTimes[0]==newShiftTime)newShift=shift

    return <TableCell  shiftDateCount={shift} attendanceDateCount={newShift} color={color}></TableCell>
  }
  const createElementTime = (shiftTimeList,newShiftTime,baseDt,title) => {
  
    var shiftTime=null
    if(shiftTimeList && shiftTimeList.length==1) shiftTime=shiftTimeList[0][title]
    var shift = diff48Time(baseDt, shiftTime)
    var newShift = null
    if(newShiftTime){
      newShift = (shiftTimeList[0]==newShiftTime)?shift:newShiftTime[title]
    }
    if (!newShiftTime || !newShift) {
      newShift='-'
    }
    if (!shiftTime) {
      shift='-'
    }

    return <TableCell  shiftDateCount={shift} attendanceDateCount={newShift} color={color}></TableCell>
  }

  const createElementCheck = (staff) => {
    return (          
      <input
        type="checkbox"
        disabled={staff.shiftBulkList.length!==1}
        checked={checked}
        onChange={()=>onClick(staff)}
      />
    )
  }
  const onClick = (staff) => {
    staff.checked=!staff.checked
    setChecked(staff.checked)
  }

  //詳細要処理
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
      setOpen(true);
  }
  return (
          <tr>
            <ColumnWrap style={{ width: '2rem' }}> {createElementCheck(workInfoList)}</ColumnWrap>
            <ColumnWrap style={{ width: '16rem' }}>{createElementStaff(workInfoList)}</ColumnWrap>
            <ColumnWrap style={{ width: '13rem' }}>{createElementOccupation(workInfoList.shiftBulkList)}</ColumnWrap>
            <ColumnWrap style={{ width: '5rem' }}>{createElementDate(dateDataList,workInfoList.dateValue)}</ColumnWrap>
            <ColumnCellWrap width= '4rem'><TableCell  shiftDateCount={'変更前'} attendanceDateCount={'変更後'} color='red'></TableCell></ColumnCellWrap>
            <ColumnCellWrap width= '7rem'>{createElementTotalTime(workInfoList.shiftBulkList,workInfoList.shiftNewList,'shiftWorkTotalDt')}</ColumnCellWrap>
            <ColumnCellWrap width= '7rem'>{createElementTotalTime(workInfoList.shiftBulkList,workInfoList.shiftNewList,'shiftRestTotalDt')}</ColumnCellWrap>
            <ColumnCellWrap width= '7rem'>{createElementOverTotalTime(workInfoList.shiftBulkList,workInfoList.shiftNewList)}</ColumnCellWrap>
            <ColumnCellWrap width= '6rem'>{createElementTime(workInfoList.shiftBulkList,workInfoList.shiftNewList,workInfoList.dateValue,'shiftWorkStartDt')}</ColumnCellWrap>
            <ColumnCellWrap width= '6rem'>{createElementTime(workInfoList.shiftBulkList,workInfoList.shiftNewList,workInfoList.dateValue,'shiftWorkEndDt')}</ColumnCellWrap>
            <ColumnCellWrap width= '6rem'>{createElementTime(workInfoList.shiftBulkList,workInfoList.shiftNewList,workInfoList.dateValue,'shiftRestStart1Dt')}</ColumnCellWrap>
            <ColumnCellWrap width= '6rem'>{createElementTime(workInfoList.shiftBulkList,workInfoList.shiftNewList,workInfoList.dateValue,'shiftRestEnd1Dt')}</ColumnCellWrap>
            <ColumnCellWrap width= '6rem'>{createElementTime(workInfoList.shiftBulkList,workInfoList.shiftNewList,workInfoList.dateValue,'shiftRestStart2Dt')}</ColumnCellWrap>
            <ColumnCellWrap width= '6rem'>{createElementTime(workInfoList.shiftBulkList,workInfoList.shiftNewList,workInfoList.dateValue,'shiftRestEnd2Dt')}</ColumnCellWrap>
            <ColumnCellWrap width= '6rem'>{createElementTime(workInfoList.shiftBulkList,workInfoList.shiftNewList,workInfoList.dateValue,'shiftRestStart3Dt')}</ColumnCellWrap>
            <ColumnCellWrap width= '6rem'>{createElementTime(workInfoList.shiftBulkList,workInfoList.shiftNewList,workInfoList.dateValue,'shiftRestEnd3Dt')}</ColumnCellWrap>
            <ColumnWrap style={{ width: '5rem' }}><Button type='button' margin='auto'  padding='0' width='4rem' name='詳細' onClick={handleOpen}/></ColumnWrap>
            <DailyShiftModal
              open={open}
              onCloseFunc={handleClose}
              updatedShiftFunc={updatedShiftFunc}
              dailyWorkInfo={workInfoList}
              workDivisions={workDivisions}
            >
            </DailyShiftModal>
          </tr>
  )
}

TableRow.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
  dateDataList: PropTypes.array,
}

export default TableRow