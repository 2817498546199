import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import FUNC from '../Common/FunctionEnum'

import { SalariedFlexWrap,ShiftPagingIconPanelWrap,PagingIconImg ,CountPanelWrap,PagingIconNextImg,ShiftPagingPanelWrap,ShiftPageButtonWrap} from './Wrap/SalariedPanelWrap'
import { dateFormat} from '../Common/Utilties/LaborUtility'
import {onExportCsvFile,makeSalariedCSV} from '../Common/Utilties/ExportUtility'

import SalariedTable from './Molcules/SalariedTable/SalariedMonthTable'
import { GetApi ,MultipartApi} from '../Common/ApiAxios'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../Common/ErrorHandling'
import SalariedPanelWrap from './Wrap/SalariedPanelWrap'
import Search from './Search'

import IconPageNextImg from '../../image/PageNext.png'
import IconPageBackImg from '../../image/PageBack.png'
import IconPageLastImg from '../../image/PageLast.png'
import IconPageTopImg from '../../image/PageTop.png'

export const DefaultUrl = '/api/salariedManagement/salaried'

const Header ="スタッフID,スタッフ名,入社年月日,勤続年数,有給取得率(%),有給取得日数,有給残日数,有給付与日数,前年残日数,有給基準日,有給期限日,有給有効期限年月日"

const Salaried = (props) => {
  const salariedDateRef = useRef(new Date())
  const staffIdRef = useRef()
  const staffNameRef = useRef()
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()
  const [searchOption, setSearchOption] = useState({ shiftDt: '', staffId: '', staffName: '',postType:'',postId:'',departmentId:'' })
  const { loginUserInfo } = useSelector(state => state)

  const [viewFunction,setViewFunction] = useState(false)
  const [salareidInfoList, setSalareidInfoList] = useState([])
  const [salareidInfoAllList, setSalareidInfoAllList] = useState([])
  //const [dateDataList, setDateDataList] = useState([])
  //const [pagenation, setPagenation] = useState({})
  const [staffCount, setStaffCount] = useState(0)
  const dispatch = useDispatch()
  const alert = useAlert()
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.SALARIED)
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const pageRecord = 11

  // 検索APIの実行
  const onSearch = async (createId, page) => {
    dispatch(SetLoadSpinner(true))
    setSearchOption(
      {
        targetDt: salariedDateRef.current,
        staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        postType:postType,
        postId:postId,
        departmentId:departmentId
      }
    )
    const data = {
      targetDt: salariedDateRef.current,
      staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      page: page
    }
    // console.log(data.postId)
    // const resultDate = await GetApi('/api/masterManagement/calendar/month', { targetDt: shiftDateRef.current })
    // dispatch(SetLoadSpinner(false))
    // if (resultDate.errorDetail) {
    //   ErrorHandling(resultDate, alert)
    //   return null
    // } else {
    //   setDataDateList(resultDate.data.responseList)
    // }

    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      console.log(result.data.responseList)
      // setDateDataList(resultDate.data.responseList)
      setPageNum(page)
      setSalareidInfoAllList(result.data.responseList)
      makePage(result.data.responseList.slice(),page)
      setStaffCount(result.data.responseList.length)
      setPageLast(Math.ceil(result.data.responseList.length/pageRecord))
      // setPagenation(resultShift.data)
      //console.log(result);
      if(result.data.responseList.length === 0) {
        var year = dateFormat(salariedDateRef.current).split('-')[0];
        alert.error(year+"年度該当データがありません。");
      }
    }
    //setCanOpenDialog(true)
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
    setViewFunction(false);
  }

  //ページング設定
  const makePage = (data,page) =>{
    if(page !== 0){
      //console.log(data)
      //console.log(page)
      var minRecord = (page-1)*pageRecord
      var maxRecord = (page*pageRecord)
      //console.log(minRecord,maxRecord)
      var tmpCount = 0
      var tmpList = new Array()
      data.forEach((staffWorkInfo)=>{
        if(tmpCount>=minRecord && tmpCount<maxRecord){
          tmpList.push(staffWorkInfo)
        }
        tmpCount+=1
      })
      setSalareidInfoList(tmpList)
      //console.log(tmpList)
    }else{
      setSalareidInfoList(data)
    }
  }

  const setPost = () =>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return false;
      }
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.companyId,
          name: item.companyName+'('+item.companyId+')',
        }
      })

      setPostList(resultList)
      return true;
    }
    const serachCustomer = async()=>{
      const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return false;
      } 
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.customerId,
          name: item.customerName+'('+item.customerId+')',
        }
      })
      
      setPostList(resultList)
      return true;
    }

    if(postType === 0){
      serachCompany();
    }else {
      serachCustomer();
    }
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType === 0 ? 1 : 0)
    }
  }

  const onPageSeletClick = (pageId) => {
    //console.log(pageId)
    makePage(salareidInfoAllList,pageId)
    setPageNum(pageId)
  }
  const onPageNextSeletClick = (pageId) => {
    if(pageNum!=pageLast){
      onPageSeletClick(pageId)
    }
  }
  const onPageBackSeletClick = (pageId) => {
    if(pageNum!=1){
      onPageSeletClick(pageId)
    }
  }
  const onPageChangeLabelClick = () => {
    setViewFunction(false);
    if(pageType==0){
      setPageType(1)
      setPageNum(0)  
      makePage(salareidInfoAllList,0)
  
    }else{
      setPageType(0)
      setPageNum(1)
      makePage(salareidInfoAllList,1)
    }
  }
  
  const UpdateGrantStaffSalaried= async ()=>{
    var NormalWorkerAttendanceCt = 217;
    //var result
    dispatch(SetLoadSpinner(true))
    const data = {
      targetDt: salariedDateRef.current,
    }

    var result = await GetApi(`${DefaultUrl}/${data.targetDt}`,data);
  
    if (result.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert)
      return null;
    } else {
      //console.log(result)
      var updateDataList = new Array()
      //初回
      result.data.halfStaffs.map((staff)=>{
        var attendanceCt = staff.calcInfoStaffSalarid[0].attendanceCt * 2
        var staffDivision = staff.staffDivision
        var acquiredDays = 0
        switch (staffDivision) {
          case 1:
          case 2:
            result.data.grantInfoStaffSalaried.map((salaried)=>{
              if(salaried.lengthServiceYears >0.5){
                  return;
              }
              if (salaried.minAttendanceDays < NormalWorkerAttendanceCt)
              {
                  return;
              }
              if (attendanceCt >= salaried.minAttendanceDays)
              {
                  acquiredDays = salaried.grantDays;
                  return;
              }
            })
            break;
          case 4:
            acquiredDays = 0
            break;
          default:
            result.data.grantInfoStaffSalaried.map((salaried)=>{
              if(salaried.lengthServiceYears >0.5){
                  return;
              }
              if (attendanceCt >= salaried.minAttendanceDays)
              {
                  acquiredDays = salaried.grantDays;
                  return;
              }
            })
            break;
        }
        var acquisitionDt = new Date(staff.endDt)
        acquisitionDt = acquisitionDt.setDate(parseInt(acquisitionDt.getDate()) + 1)
        acquisitionDt = new Date(acquisitionDt)
        console.log(acquisitionDt)
        var endDt = new Date(staff.endDt)
        let updateData={
          staffId: staff.staffId,
          acquisitionDt: dateFormat(acquisitionDt),
          expirationDt: dateFormat(new Date(endDt.setFullYear(acquisitionDt.getFullYear()+2))),
          nextAcquisitionDt: dateFormat(new Date(acquisitionDt.setFullYear(acquisitionDt.getFullYear()+1))),
          acquiredDays: acquiredDays,
          remaingDaysPreviousYear: 0,
          remark: ''
        }
        updateDataList.push(updateData)
      })
      //2回目以降
      result.data.yearStaffs.map((staff)=>{
        var attendanceCt = staff.calcInfoStaffSalarid[0].attendanceCt
        var salariedCt = staff.calcInfoStaffSalarid[0].salariedCt
        
        var endDt = new Date(staff.endDt)

        var acquisitionDt = new Date(staff.endDt)
        acquisitionDt = acquisitionDt.setDate(parseInt(acquisitionDt.getDate()) + 1)
        acquisitionDt = new Date(acquisitionDt)
        console.log(acquisitionDt)
        var enterDt = new Date(staff.enterDt)

        var lengthYear = 
          ((acquisitionDt.getFullYear()- enterDt.getFullYear())*12.0+(acquisitionDt.getMonth() - enterDt.getMonth()))/12.0
        var staffDivision = staff.staffDivision
        var acquiredDays = 0
        switch (staffDivision) {
          case 1:
          case 2:
            result.data.grantInfoStaffSalaried.map((salaried)=>{
              if(salaried.lengthServiceYears > lengthYear) {
                  return;
              }
              if (salaried.minAttendanceDays < NormalWorkerAttendanceCt) {
                  return;
              }
              if (attendanceCt >= salaried.minAttendanceDays)
              {
                  acquiredDays = salaried.grantDays;
                  return;
              }
            })
            break;
          case 4:
            acquiredDays = 0
            break;
          default:
            result.data.grantInfoStaffSalaried.map((salaried)=>{
              if(salaried.lengthServiceYears > lengthYear) {
                  return;
              }
              if (attendanceCt >= salaried.minAttendanceDays) {
                  acquiredDays = salaried.grantDays;
                  return;
              }
            })
            break;
        }
        // 前年度繰越日数の算出
        var newRemaingDaysPreviousYear = 0;
        var beforegrantDays = staff.acquiredDays;
        var beforeRemaing = staff.remaingDaysPreviousYear;
        if (salariedCt <= beforeRemaing) {
          // 使用済日数が繰越日数より少ない場合は付与日数を全て繰越日数にする
          newRemaingDaysPreviousYear = beforegrantDays;
        }
        else {
          newRemaingDaysPreviousYear = (beforegrantDays + beforeRemaing) - salariedCt;
        }
        let updateData={
          staffId: staff.staffId,
          acquisitionDt: dateFormat(acquisitionDt),
          expirationDt: dateFormat(new Date(endDt.setFullYear(acquisitionDt.getFullYear()+2))),
          nextAcquisitionDt: dateFormat(new Date(acquisitionDt.setFullYear(acquisitionDt.getFullYear()+1))),
          acquiredDays: acquiredDays,
          remaingDaysPreviousYear: newRemaingDaysPreviousYear,
          remark: ''
        }
        updateDataList.push(updateData);
      })
      //console.log(updateDataList);

      //var resultUpadate
      const submitData = new window.FormData()
      submitData.append('formData', new window.Blob([JSON.stringify(updateDataList)], { type: 'application/json' }));
      var resultUpadate = await MultipartApi(`${DefaultUrl}`,'POST', submitData, updateDataList);

      if (resultUpadate.errorDetail) {
        dispatch(SetLoadSpinner(false));
        ErrorHandling(result, alert);
        return null;
      }
    }
    
    dispatch(SetLoadSpinner(false));
  }
  const onSalaried= ()=>{
    setViewFunction(false);
    UpdateGrantStaffSalaried();
  }
  
  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
    console.log(viewSubSearch)
  }
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 検索APIの実行
  const onExport = async () => {
    dispatch(SetLoadSpinner(true))
    const data = {
      targetDt: salariedDateRef.current,
      staffId: grants ? (grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value) : '',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:postType,
      postId:(postId !== undefined && postId !== -1) ? postId:'',
      departmentId:(departmentId !== null && departmentId !== undefined)? departmentId:'',
      page: 1
    }

    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      console.log(result.data.responseList)
      var year = dateFormat(salariedDateRef.current).split('-')[0];
      if(result.data.responseList.length === 0){
        alert.error(year+"年度該当データがありません。")
      }
      var today = new Date();
      var day = (''+today.getFullYear()).slice(-2) + ('00' + (today.getMonth())).slice(-2) + ('00' + today.getDate()).slice(-2)
              + ('00' + (today.getHours())).slice(-2) + ('00' + today.getMinutes()).slice(-2) + ('00' + today.getSeconds()).slice(-2)
      var fileName = '有給休暇管理'+year+'年度('+day+')'
      onExportCsvFile(makeSalariedCSV(result.data.responseList,Header),fileName)
    }

    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
    setViewFunction(false);
  }
  // 検索APIの実行
  const onImport= async (uploadData) => {
    async function sendPost (requestBody, data) {
      //var result
      dispatch(SetLoadSpinner(true))
      //console.log(data)
      var result = await MultipartApi(`${DefaultUrl}`,'POST', requestBody, data)
      
      if (!ErrorHandling(result, alert)) {
        alert.success('保存しました')
 
        onSearch(null,1)
      }else{
        dispatch(SetLoadSpinner(false))
      }
    }

    var uploadDataList= new Array();

    uploadData.forEach((data)=>{
      let dataRow = {
        staffId: data["スタッフID"], 
        acquisitionDt: data["有給基準日"], 
        acquiredDays: data["有給付与日数"], 
        remaingDaysPreviousYear: data["前年残日数"], 
        nextAcquisitionDt: data["有給期限日"], 
        expirationDt: data["有給有効期限年月日"], 
        remark: ''
      }

      var tmp = new Date(dataRow.nextAcquisitionDt)
      tmp.setDate(tmp.getDate() + 1);
      dataRow.nextAcquisitionDt = tmp.getFullYear() + '-' +('00'+(tmp.getMonth()+1)).slice(-2)+'-'+('00'+tmp.getDate()).slice(-2)
      uploadDataList.push(dataRow)
    })

    //console.log(uploadDataList)
    if(uploadDataList.length==0)
    {
        alert.error("更新するデータがありません")
        return;
    }
    
    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(uploadDataList)], { type: 'application/json' }))

    sendPost(submitData, uploadDataList)
  }

  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud';
    else
      document.title = 'Core Time Cloud';
  }, [grants]);

  useEffect(() => {
    setPost()
    onSearch(null,1)
  }, []);

  //会社顧客変更処理
  useEffect(() => {
    setPost()
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postType]);

  if (!grants) return null;
  return (
    <SalariedPanelWrap id='SalariedPanelWrap'>
      <Search
        title= {grants.functionMenuName}
        showButton
        onClick={() => onSearch(null, pageType==0?1:0)}
        onClickOption={(e) => onClickOptionBtn(e)}
        onKeyDown={(e) => onKeyDown(e)}
        salariedDateRef={salariedDateRef}
        staffIdRef={staffIdRef}
        staffNameRef={staffNameRef}
        viewSubSearch={viewSubSearch}
        grants={grants}
        postList={postList}
        postId={postId}
        setPostId={setPostId}
        departmentId={departmentId}
        setDepartmentId={setDepartmentId}
        postType={postType}
        setPostType={setPostType}
        onSalaried={onSalaried}
        onClickFunction={()=>onClickFunction()}
        viewFunction={viewFunction}
        onPageTypeChangeClick={() => onPageChangeLabelClick()}
        onExport={()=>onExport()}
        onImport={(updateDataList)=>onImport(updateDataList)}
      >
      </Search>
      {
        salareidInfoList &&
        <CountPanelWrap>
            <label>検索ヒット件数:{staffCount}件</label>
        </CountPanelWrap>
      }
      <SalariedFlexWrap>
      <SalariedTable
      staffWorkInfoList={salareidInfoList}
      defaultUrl ={DefaultUrl}
      targetDt={salariedDateRef.current}
      >
      </SalariedTable>
      </SalariedFlexWrap>
      {
        salareidInfoList.length>0 &&
        <ShiftPagingPanelWrap>
        {/* <ShiftPagingChangePanelWrap onClick={() => onPageChangeLabelClick()}>表示切替</ShiftPagingChangePanelWrap> */}
        { pageType==0 &&
          <ShiftPageButtonWrap>       
          <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
          <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
          <ShiftPagingIconPanelWrap>{pageNum}</ShiftPagingIconPanelWrap>
          <ShiftPagingIconPanelWrap>/</ShiftPagingIconPanelWrap>
          <ShiftPagingIconPanelWrap>{pageLast}</ShiftPagingIconPanelWrap>
          <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
          <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
          
          </ShiftPageButtonWrap>
        }
        </ShiftPagingPanelWrap>
      }
    </SalariedPanelWrap>
  ) 
}

export default Salaried
