import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'

import { dateFormat } from '../../../Common/Utilties/LaborUtility'

import IconDeleteImg from '../../../../image/delete-box-icon.png'

import Tooltip from '@material-ui/core/Tooltip'
import { GetApi } from '../../../Common/ApiAxios'
//import { formatHour } from '../../../Common/Utilties/LaborUtility'
//import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
//import Button from '../../../Common/Atoms/NormalButton'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../../Common/ErrorHandling'
//import { FaResearchgate } from 'react-icons/fa'
//import { SearchWrap } from '../../Wrap/StaffBiometricPanelWrap'

const ColumnWrap = styled.td`
  vertical-align:middle;
  padding: 0.25rem;
  height: 3rem;
`

const ColumnIconWrap = styled.td`
  vertical-align:center;
  align-items:center; 
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0.25rem;
  height: 3rem;
`

const TableBody = ({ staffInfoList, Reserch,setOpenDialog,selectFunction, updatedShiftFunc, defaultUrl,setTargetId }) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  // 削除ボタンのクリックイベント
  const onDelete = (staff) => {
    async function sendPost (staff,data) {
      var result
      dispatch(SetLoadSpinner(true))

      result = await GetApi(`${defaultUrl}/delete`,data)
      
      if (!ErrorHandling(result, alert)) {
        alert.success('削除しました')
        Reserch()
      }
      dispatch(SetLoadSpinner(false))
    }

    const is_ok = window.confirm('削除しますか？');
    if(!is_ok)
    {
      return
    }

    var data = {staffId:staff.staffId,updatedAt:dateFormat(staff.updateAt)}
    
    sendPost(staff, data)
    console.log(staff.staffId)
    
  }

  return (
    <tbody>
      {staffInfoList && staffInfoList.map((staff) => {
        return (
          <tr key={staff.staffId}>
            <ColumnWrap style={{ width: '36rem' ,height:'2rem',fontSize: '16px'}}>{staff.staffId}</ColumnWrap>
            <ColumnWrap style={{ width: '70rem' , fontSize: '16px'}}>{staff.staffName}</ColumnWrap>
            <ColumnWrap style={{ width: '6rem' ,textAlign:'center',fontSize: '16px'}}>{(staff.isEmptyAuth?'':'〇')}</ColumnWrap>
            <ColumnIconWrap style={{ width: '4rem',alignItems:'center' }}>
              {!staff.isEmptyAuth &&
              <Tooltip title={'削除'}>
                <img src={IconDeleteImg} width="32px" height="32px" style={{ margin: '4px 0 0 12px', cursor: 'pointer'}} onClick={()=>onDelete(staff)} />
              </Tooltip>
              }
              {/* <Button type='button' onClick={()=>onDelete(staff)} name='削除' margin='auto' padding='0' width='8em' display = {(staff.isEmptyAuth?'none':'block')}/> */}
            </ColumnIconWrap>
          </tr>
        )
      })}
    </tbody>
  )
}

TableBody.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
}

export default TableBody