import React, { useCallback, useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'
import SearchDepartmentDialog from '../../Molcules/SearchDepartmentDialog'
import MonthCalendar from '../../../Common/Atoms/MonthCalendar'
import Button from '../../../Common/Atoms/NormalButton'
import Select from '../../../Common/Atoms/SelectAddValue'
import { PostApi } from '../../../Common/ApiAxios'
import Input from '../../../Common/Atoms/Input'
import { SubSearchContent, SubSearchContentRow,AttendanceClickLabelPanelWrap,AttendanceFiltersPanelWrap,FunctionContent,FunctionContentRow} from '../../Wrap/AttendancePanelWrap'
import { SearchWrap } from '../../Wrap/PaymentDuductionWrap'
import IconSearchImg from '../../../../image/search-icon.png'
import IconDataCopyImg from '../../../../image/data-copy-icon.png'
import IconFunctionImg from '../../../../image/three-reader-vertical-icon.png'
import IconUpdateImg from '../../../../image/update-icon.png'
import IconCloseImg from '../../../../image/close_icon.png'
import IconPageTypeChange from '../../../../image/page-type-change-icon.png'
const Search = (props) => {
  const dispatch = useDispatch()
  const [shiftDate, setShiftDate] = useState(new Date())
  const changeCalendar = (date) => {
    fromDateRef.current = date;
    setShiftDate(date)
    onClick()
    console.log(date,fromDateRef.current)
    setShiftDate(fromDateRef.current)
  }

  const [renderFlg, setRenderFlg] = useState(false)
  const [plusButton, setPlusButton] = useState(false)
  const {
    title, onClick, onKeyDown, fromDateRef, toDateRef, staffIdRef, staffNameRef, firstSearch, grants,
    inSubChildren,onClickOption,viewSubSearch,
    postList,setPostId,postId,setDepartmentId,departmentId,
    postType,setPostType,
    addStyle, children, relationStatus,onUpadate,onClose,viewFunction,onClickFunction,onPageTypeChangeClick,onDataCopy
  } = props
  const alert = useAlert()
  const loginUserInfo = useSelector(state => state.loginUserInfo)

  const [labelName, setLabelName] = useState('会社');
  const [postText, setPostText] = useState('全て');
  const [departmentName, setDepartmentName] = useState('');
  const [canOpenDialog, setCanOpenDialog] = useState(false);
  const [rows,setRows] = useState(<></>)
  const [selectValue,setSelectValue] = useState(null)
  //ダイアログ閉じるボタン処理
  const handleClose = () => {
    setCanOpenDialog(false);
  }

  //選択ボタン処理
  const onClickDepartmentSelect =  (event) => {
    setCanOpenDialog(true)
  }
  //会社リスト更新時処理
  useEffect(() => {
      console.log(postList)
      postList.unshift({index:-1,name:'全て'})
      var postRows = 
      postList.map(post=>{
        return <><option value={post.index} >{post.name}</option></> 
      })
      setRows(postRows)
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postList])
  // 初期値
  useEffect(() => {
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, dispatch])

  // レンダー終了判定

  // 初期検索
  useEffect(() => {
    if (renderFlg && firstSearch) {
      onClick()
    }
  }, [renderFlg, firstSearch, onClick])

  useEffect(() => {
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, relationStatus])

    //会社顧客変更処理
    useEffect(() => {
      if(postType==0){
        setLabelName('会社')
      }else{
        setLabelName('顧客')
      }
      setDefault()
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postType])

    //デフォルト設定
    const setDefault=()=>{
      setSelectValue(-1)
      setPostId(-1)
      setPostText('全て')
      setDepartmentName('')
      setDepartmentId(null)
    }
  const onChange = (event) => {
    event.persist()
    setPostId(event.target.value)
    setPostText(event.target.options[event.target.selectedIndex].text)
    setSelectValue(event.target.value)
    console.log(event.target.value)
  }
  const onClickLabel = ()=>{
    console.log(postType)
    if(postType==0){
      setPostType(1)
    }else{
      setPostType(0)
    }
  }
    //リセットボタン処理
    const onClickResetBtn=()=>{
      setPostType(0)
      setDefault()
      staffIdRef.current.value=''
      staffNameRef.current.value=''
    }


  return (
    <SearchWrap addStyle={addStyle} plusButton={plusButton} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{title}</h3>
      <ul className='mainSearch'>
        <li>
          <MonthCalendar changeCalendar={changeCalendar} dataValue={shiftDate} isViewIcon placeholder={'対象年月'}/>
          <Tooltip title={'検索オプション'}>
          <img src={IconSearchImg} width="24px" height="24px" style={{ marginTop: '4px',cursor: 'pointer' }} onClick={onClickOption} />
          </Tooltip>
          {/* <Tooltip title={'検索オプション'}>
            <img src={IconOptionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: '12px', cursor: 'pointer'}} onClick={onClickOption} />
          </Tooltip> */}
        </li>
        <li className='subSearch'>
          <SubSearchContent>
          <SubSearchContentRow>
            <AttendanceClickLabelPanelWrap onClick={onClickLabel}><label style={{width:'4rem'}}>{labelName}</label></AttendanceClickLabelPanelWrap>
            <Tooltip title={postText}>
            <AttendanceFiltersPanelWrap>
              <Select
                name={'postId'}
                width='30rem'
                onChange={onChange}
                disabled={grants.readGrantDivision === 3}
                selectValue={selectValue}
              >{rows}</Select>
            </AttendanceFiltersPanelWrap>
            </Tooltip>
            </SubSearchContentRow>
            <SubSearchContentRow>
              <label style={{width:'4rem'}}>{labelName}部門</label>
              <Input type='text' name='departmentName' value = {departmentName} onKeyDown={onKeyDown} disabled={true} width='25.5rem' style={{marginLeft:'15px'}}/>
              <Button width='4rem' margin='0 0 0 4px' name='選択' padding='0px' onClick={onClickDepartmentSelect} />
            </SubSearchContentRow>
            <SubSearchContentRow>
              <label style={{width:'4rem'}}>ID</label>
              <Input type='text' name='staffId' inputRef={staffIdRef} onKeyDown={onKeyDown} disabled={grants.readGrantDivision === 3} width='30rem' style={{marginLeft:'15px'}} />
            </SubSearchContentRow>
            <SubSearchContentRow>
              <label style={{width:'4rem'}}>名称</label>
              <Input type='text' name='staffName' inputRef={staffNameRef} onKeyDown={onKeyDown} disabled={grants.readGrantDivision === 3} width='30rem' style={{marginLeft:'15px'}} />
            </SubSearchContentRow>
            <SubSearchContentRow style={{textAlign:'right', marginLeft:'auto', marginRight:'1.5rem' }}>
              <Button type='button' margin='0 0 0 16px' name='リセット' padding='0px' onClick={onClickResetBtn} />
              <Button margin='0 0 0 16px' name='検索' padding='0px' onClick={onClick} />
            </SubSearchContentRow>
          </SubSearchContent>
        </li>
      </ul>
      <Tooltip title={'機能メニュー'}>
        <img src={IconFunctionImg} width="24px" height="24px" style={{marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
          <FunctionContent>
            <label style={{height: '12px'}}></label>
            <FunctionContentRow onClick={onDataCopy}>
              <img src={IconDataCopyImg} width="20px" height="20px"style={{marginLeft: '12px'}}  />
              <label style={{width:'6rem',marginLeft: '12px',marginTop: '3px'}}>前月コピー</label>
            </FunctionContentRow>
            <FunctionContentRow onClick={onUpadate}>
              <img src={IconUpdateImg} width="20px" height="20px" style={{margin: '0 0 3px 12px'}}/>
              <label style={{width:'6rem',marginLeft: '12px',marginTop: '4px'}}>更新</label>
            </FunctionContentRow>
            <hr/>
            <FunctionContentRow onClick={onPageTypeChangeClick}>
              <img src={IconPageTypeChange} width="20px" height="20px"style={{marginLeft: '12px'}}  />
              <label style={{width:'6rem',marginLeft: '12px',marginTop: '3px'}}>表示切替</label>
            </FunctionContentRow>
            <hr/>
            <FunctionContentRow onClick={onClose}>
              <img src={IconCloseImg} width="18px" height="18px" style={{marginLeft: '12px'}}  />
              <label style={{width:'6rem',marginLeft: '12px',marginTop: '2px'}}>閉じる</label>
            </FunctionContentRow>
            <label style={{height: '12px'}}></label>
          </FunctionContent>
      </li>
      <SearchDepartmentDialog
        open={canOpenDialog}
        onCloseFunc={handleClose}
        postName={postText}
        postId={postId}
        setSearchDepartmentId={setDepartmentId}
        searchDepartmentId={departmentId}
        setDepartmentName={setDepartmentName}
        postType={postType}
        >
        </SearchDepartmentDialog>
    </SearchWrap>
  )
}

Search.propTypes = {
  title: PropTypes.string, // タイトル
  onClick: PropTypes.func, // 検索処理
  onKeyDown: PropTypes.func, // キーダウン処理
  onClickOption: PropTypes.func, // サブ検索項目の表示処理
  onClickFunction: PropTypes.func, // 機能メニューの表示処理
  viewFunction: PropTypes.bool, // 機能メニューを表示するか
  onExport: PropTypes.func, // エクスポート処理
  onUpadate: PropTypes.func, // 更新処理
  onRecalculation: PropTypes.func, // 再計算処理
  fromDateRef: PropTypes.object, // 検索項目関連
  toDateRef: PropTypes.object, // 検索項目関連
  staffIdRef: PropTypes.object, // 検索項目関連
  staffNameRef: PropTypes.object, // 検索項目関連
  firstSearch: PropTypes.bool, // 最初に検索処理をするか
  grants: PropTypes.object, // 権限関連
  inSubChildren: PropTypes.bool, // その他メニューをサブメニューに表示 メインには表示しない
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  addStyle: PropTypes.string, // css用追加用
  children: PropTypes.any, // 子要素
}

export default Search
