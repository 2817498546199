import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const today = new Date()

const initialState = {
  workDate: today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2), // 対象日
  changeFlg: false // 共通検索変更フラグ
}

const CommonSearchSlice = createSlice({
  name: 'commonSearch',
  initialState,
  reducers: {

    // 対象日セット
    SetWorkDate: (state, action) => {
      state.workDate = action.payload
    },

    SetCommonSearch: (state, action) => {
      const { payWorkDate } = action.payload
      state.workDate = payWorkDate
    }

  }
})

export const CommonSearchStates = () => {
  return useSelector((state) => state.commonSearch)
}

export const {
  SetWorkDate,
  SetCommonSearch
} = CommonSearchSlice.actions

export default CommonSearchSlice.reducer
