import React from 'react'
import PropTypes from 'prop-types'

import { formatHour } from '../../../Common/Utilties/LaborUtility'

import { RowHeaderTop, RowHeaderBottom, ColumnStaffTH, ColumnWorkTH, ColumnTimeTH, ColumnTimeCell } from '../../Wrap/MonthTableWrap'
import MonthTableHeaderWorkCell from './MonthTableHeaderWorkCell'
import MonthTableHeaderTimeCell from './MonthTableHeaderTimeCell'
import MonthTableHeaderDateCell from './MonthTableHeaderDateCell'
import MonthTableHeaderDailySummaryCell from './MonthTableHeaderDailySummaryCell'

const MonthTableHeader = ({ dateDataList, staffWorkInfoList, selectFunction }) => {

  const countSumShiftWork = () => {
    var count = 0

    if (!staffWorkInfoList || staffWorkInfoList.lenfth === 0) {
      return '-'
    }

    staffWorkInfoList.forEach(staff => {
        staff.dailyWorkInfoList.forEach(day => {
          if (day.shiftWork) {
            count++
          }
        })
    })

    return count.toString()
  }

  const countSumAttendanceWork = () => {
    var count = 0

    if (!staffWorkInfoList || staffWorkInfoList.lenfth === 0) {
      return '-'
    }

    staffWorkInfoList.forEach(staff => {
        staff.dailyWorkInfoList.forEach(day => {
          if (day.attendanceWork) {
            count++
          }
        })
    })

    return count.toString()
  }

  const countShiftWork = (date) => {
    var count = 0

    if (!staffWorkInfoList || staffWorkInfoList.lenfth === 0) {
      return '-'
    }

    staffWorkInfoList.forEach(staff => {
        staff.dailyWorkInfoList.forEach(day => {
          if (day.baseDt === date && day.shiftWork) {
            count++
          }
        })
    })

    return count.toString()
  }

  const CountAttendanceWork = (date) => {
    var count = 0

    if (!staffWorkInfoList || staffWorkInfoList.lenfth === 0) {
      return '-'
    }

    staffWorkInfoList.forEach(staff => {
        staff.dailyWorkInfoList.forEach(day => {
          if (day.baseDt === date && day.attendanceWork)
          count++
        })
    })

    return count.toString()
  }
  
  const CalcSumShiftWorkTime = () => {
    var time = 0.0

    if (!staffWorkInfoList || staffWorkInfoList.lenfth === 0) {
      return '-'
    }

    staffWorkInfoList.forEach(staff => {
        staff.dailyWorkInfoList.forEach(day => {
          if (day.shiftWork) {
            day.workInfoList.forEach( workInfo => {
              if (workInfo.shiftWork)
               time += workInfo.shiftWorkTotalDt
            })
          }
        })
    })

    return formatHour(time);
  }
  
  const CalcSumAttendanceWorkTime = () => {
    var time = 0.0

    if (!staffWorkInfoList || staffWorkInfoList.lenfth === 0) {
      return '-'
    }

    staffWorkInfoList.forEach(staff => {
        staff.dailyWorkInfoList.forEach(day => {
          if (day.attendanceWork) {
            day.workInfoList.forEach( workInfo => {
              if (workInfo.attendanceWork)
               time += workInfo.attendanceWorkTotalDt
            })
          }
        })
    })

    return formatHour(time);
  }

  return (
    <thead>
      <RowHeaderTop>
        <ColumnStaffTH>スタッフ名</ColumnStaffTH>
        <ColumnWorkTH>日数</ColumnWorkTH>
        <ColumnTimeTH>時間</ColumnTimeTH>
        {dateDataList && dateDataList.map((data) => {
          return (
          <MonthTableHeaderDateCell
              onClick={(e) => selectFunction(e,data)}
              key={data.dateValue}
              data={data}
          >
          </MonthTableHeaderDateCell>
          )
        })}
      </RowHeaderTop>
      <RowHeaderBottom>
        <ColumnStaffTH>合計</ColumnStaffTH>
        <ColumnWorkTH>
          <MonthTableHeaderWorkCell
            shiftDateCount={countSumShiftWork()}
            attendanceDateCount={countSumAttendanceWork()}
          >
          </MonthTableHeaderWorkCell>
        </ColumnWorkTH>
        <ColumnTimeCell>
          <MonthTableHeaderTimeCell
            shiftWorkTime={CalcSumShiftWorkTime()}
            attendanceWorkTime={CalcSumAttendanceWorkTime()}
          >
          </MonthTableHeaderTimeCell>
        </ColumnTimeCell>
        {dateDataList && dateDataList.map((data) => {
          return (
          <MonthTableHeaderDailySummaryCell
            key={'header_daily_' + data.dateValue}
            shiftDateCount={countShiftWork(data.dateValue)}
            attendanceDateCount={CountAttendanceWork(data.dateValue)}
          >
          </MonthTableHeaderDailySummaryCell>
          )
        })}
      </RowHeaderBottom>
    </thead>
  )
}

MonthTableHeader.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
}

export default MonthTableHeader
