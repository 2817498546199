import React from 'react'
import PropTypes from 'prop-types'

import DailyTableRow from './OrderTableRow'
const TableBody = ({
  register, getValues, setValue, watch, reset, errors,
  defaultUrl, workInfoList,workDivisions,handleOpenPunchHistoryFunc,onSelect
}) => {

  return (
    <tbody>
      {workInfoList && workInfoList.map((workInfo) => {
        return (
          <DailyTableRow key={"top_" + workInfo.staffId}
            register={register}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            reset={reset}
            errors={errors}
            workDivisions={workDivisions}
            defaultUrl={defaultUrl}
            workInfo={workInfo}
            onSelect={onSelect}
          >
          </DailyTableRow>
        )
      })}
    </tbody>
  )
}

TableBody.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
}

export default TableBody