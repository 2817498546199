import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {ColumnDateTH,ColumnTimeTH,RowHeaderTop,ColumnSpan}from '../../Wrap/SalariedTableWrap'

/*
const CellWrap = styled.th`
  width: 50rem;
  height: 2rem;
  vertical-align:middle;
  ${props => props.addStyle}
`
*/
const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
`
/*
const CellMonthPanel = styled.div`
  width: 36rem;
  flex-direction: column;
`
*/
const Column = styled.div`
  text-align: center;
  height: 1rem;
`
/*
const ColumnMonth = styled.th`
  text-align: center;
  width: 3rem;
  height: 1rem;
`
*/
const SalariedTableHeader = ({ staffWorkInfoList, selectFunction }) => {
  const salariedDt = ''+''
  return (
    <thead>
      <tr>
        <RowHeaderTop>
        <ColumnDateTH><CellPanel><Column>年次有給休暇取得年月日</Column><Column>自年月日  ～  至年月日</Column></CellPanel></ColumnDateTH>
        <ColumnTimeTH><ColumnSpan>{'使用日数'}</ColumnSpan></ColumnTimeTH>
        <ColumnTimeTH><ColumnSpan>{'残日数'}</ColumnSpan></ColumnTimeTH>
        <ColumnTimeTH><ColumnSpan>{'種別'}</ColumnSpan></ColumnTimeTH>
        <ColumnDateTH><ColumnSpan>{'備考'}</ColumnSpan></ColumnDateTH>
        </RowHeaderTop>
      </tr>
    </thead>
  )
}

SalariedTableHeader.propTypes = {
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
}

export default SalariedTableHeader
