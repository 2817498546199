import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'
import Button from '../../../Common/Atoms/NormalButton'

import { InputGroup, SelectGroup, HiddenInput } from '../../../Common/CommonInputGroup'

import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
import { diff48Time, format48Time, formatHour, makeElementId, calcWorkingHours,formatRound48Time } from '../../../Common/Utilties/LaborUtility'

const ColumnWrap = styled.td`
  text-align:center;
  vertical-align:middle;
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CellTop = styled.div`
  height: 4rem;
`

const CellBottom = styled.div`
  height: 4rem;
  border-top: 1px solid #000;
`

const ColumnOccupationTD = styled.td`
  width: 8rem;
  max-width: 8rem;
  vertical-align: middle;
  text-align: left;
  padding-left: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const DailyTableRow = ({
    register, getValues, setValue, watch, reset, errors, 
    defaultUrl, workInfo,workDivisions,handleOpenPunchHistoryFunc
  }) => {

  const alert = useAlert()
  //const [workDivisions, setWorkDivisions] = useState(<></>)
  const elementId = makeElementId(workInfo)
  const [workDivision, setWorkDivision] = useState(0)

  // 勤怠情報切り替え時
  useEffect(() => {
    // 勤怠情報が変更された場合のみ実行
    setValue('attendanceWorkDivision_' + elementId)

    setValue('orderId_' + elementId, workInfo.orderId)
    setValue('staffId_' + elementId, workInfo.staffId)
    setValue('baseDt_' + elementId, workInfo.baseDt)
    setValue('attendanceUpdatedAt_' + elementId, workInfo.attendanceUpdatedAt)

    setValue('attendanceWorkDivision_' + elementId, workInfo.attendanceWorkDivision)
    setValue('attendanceWorkStartDt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceWorkStartDt))
    setValue('attendanceWorkEndDt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceWorkEndDt))
    setValue('attendanceWorkTotalDt_' + elementId, formatHour(workInfo.attendanceWorkTotalDt))
    setValue('attendanceRestTotalDt_' + elementId, formatHour(workInfo.attendanceRestTotalDt))
    let overTotal = 0.0
    if (workInfo.attendanceWorkTotalDt > workInfo.attendanceBaseTime)
      overTotal = workInfo.attendanceWorkTotalDt - workInfo.attendanceBaseTime

    setValue('attendanceOverTotalDt_' + elementId, formatHour(overTotal))
    setValue('Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart1Dt))
    setValue('attendanceRestEnd1Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd1Dt))
    setValue('attendanceRestStart2Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart2Dt))
    setValue('attendanceRestEnd2Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd2Dt))
    setValue('attendanceRestStart3Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart3Dt))
    setValue('attendanceRestEnd3Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd3Dt))
    setValue('attendanceRemark_' + elementId, workInfo.attendanceRemark)

    setWorkDivision(workInfo.attendanceWorkDivision)
  // eslint-disable-next-line
  }, [workInfo])

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
      //await getWorkDivisions()

      setValue('orderId_' + elementId, workInfo.orderId)
      setValue('staffId_' + elementId, workInfo.staffId)
      setValue('baseDt_' + elementId, workInfo.baseDt)
      setValue('attendanceUpdatedAt_' + elementId, workInfo.attendanceUpdatedAt)
  
      setValue('attendanceWorkDivision_' + elementId, workInfo.attendanceWorkDivision)
      setValue('attendanceWorkStartDt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceWorkStartDt))
      setValue('attendanceWorkEndDt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceWorkEndDt))
      setValue('attendanceWorkTotalDt_' + elementId, formatHour(workInfo.attendanceWorkTotalDt))
      setValue('attendanceRestTotalDt_' + elementId, formatHour(workInfo.attendanceRestTotalDt))
      let overTotal = 0.0
      if (workInfo.attendanceWorkTotalDt > workInfo.attendanceBaseTime)
        overTotal = workInfo.attendanceWorkTotalDt - workInfo.attendanceBaseTime

      setValue('attendanceOverTotalDt_' + elementId, formatHour(overTotal))
      setValue('attendanceRestStart1Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart1Dt))
      setValue('attendanceRestEnd1Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd1Dt))
      setValue('attendanceRestStart2Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart2Dt))
      setValue('attendanceRestEnd2Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd2Dt))
      setValue('attendanceRestStart3Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart3Dt))
      setValue('attendanceRestEnd3Dt_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd3Dt))
      setValue('attendanceRemark_' + elementId, workInfo.attendanceRemark)

      setWorkDivision(workInfo.attendanceWorkDivision)
    }
    getOptions()

  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  // 出勤区分取得
  // const getWorkDivisions = useCallback(async () => {
  //   const result = await GetApi('/api/masterManagement/classificationValues/workDivisions')
  //   if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
  //     const options = result.data.values.map(value =>
  //       <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
  //     )
  //     setWorkDivisions(options)
  //   }
  // }, [setWorkDivisions, alert])

  const createElementOccupation = (workInfo) => {
      let text = workInfo.occupation
      let textPx = measurePxText(text)
      let textRem = convertPxToRem(textPx)
  
      let checkRem = 7
      if (textRem < checkRem)
      {
        return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({workInfo.orderId})</span></>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...')
        textRem = convertPxToRem(textPx)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({workInfo.orderId})</span></span></Tooltip>
  }

  const onBlurTime = ( event ) => {
    let value = event.target.value
    //日時の分単位を指定分で丸める
    let fractionUnit = parseInt(workInfo.attendanceFractionUnit)
    //value = format48Time(value)
    let name = event.target.name 
    if(name.indexOf('WorkEnd') != -1 || name.indexOf('RestStart') != -1)
    {
      value = formatRound48Time(value,fractionUnit*(-1))
    }
    else
    {
      value = formatRound48Time(value,fractionUnit)
    }
    event.target.value = value

    let workStart = getValues("attendanceWorkStartDt_" + elementId)
    let workEnd = getValues("attendanceWorkEndDt_" + elementId)
    let restStart1 = getValues("attendanceRestStart1Dt_" + elementId)
    let restEnd1 = getValues("attendanceRestEnd1Dt_" + elementId)
    let restStart2 = getValues("attendanceRestStart2Dt_" + elementId)
    let restEnd2 = getValues("attendanceRestEnd2Dt_" + elementId)
    let restStart3 = getValues("attendanceRestStart3Dt_" + elementId)
    let restEnd3 = getValues("attendanceRestEnd3Dt_" + elementId)

    //日時の分単位を指定分で丸める
    workStart = formatRound48Time(workStart,fractionUnit)
    workEnd = formatRound48Time(workEnd,fractionUnit*(-1))
    restStart1 = formatRound48Time(restStart1,fractionUnit*(-1))
    restEnd1 = formatRound48Time(restEnd1,fractionUnit)
    restStart2 = formatRound48Time(restStart2,fractionUnit*(-1))
    restEnd2 = formatRound48Time(restEnd2,fractionUnit)
    restStart3 = formatRound48Time(restStart3,fractionUnit*(-1))
    restEnd3 = formatRound48Time(restEnd3,fractionUnit)

    let result = calcWorkingHours([workStart, workEnd], [restStart1, restEnd1, restStart2, restEnd2, restStart3, restEnd3], workInfo.attendanceBaseTime)

    setValue('attendanceWorkTotalDt_' + elementId, result[0])
    setValue('attendanceRestTotalDt_' + elementId, result[1])
    setValue('attendanceOverTotalDt_' + elementId, result[2])
    
  }

  const onChange = ( event ) =>
  {
    let value = event.target.value
    if(2 > value || value > 4)
    {
      setValue('attendanceWorkStartDt_' + elementId, '')
      setValue('attendanceWorkEndDt_' + elementId, '')
      setValue('attendanceWorkTotalDt_' + elementId, '0.00')
      setValue('attendanceRestTotalDt_' + elementId, '0.00')
      setValue('attendanceOverTotalDt_' + elementId, '0.00')
      setValue('attendanceRestStart1Dt_' + elementId, '')
      setValue('attendanceRestEnd1Dt_' + elementId, '')
      setValue('attendanceRestStart2Dt_' + elementId, '')
      setValue('attendanceRestEnd2Dt_' + elementId, '')
      setValue('attendanceRestStart3Dt_' + elementId, '')
      setValue('attendanceRestEnd3Dt_' + elementId, '')
    }
    setWorkDivision(value)

  }
  
  //打刻履歴表示処理
  const onShowPunchHistoryModal = () => {
    handleOpenPunchHistoryFunc(workInfo)
  }

  //画面表示 
  return (
    <tr>
      <ColumnOccupationTD>
        {createElementOccupation(workInfo)}
        <HiddenInput name={'staffId_'+ elementId} register={register} />
        <HiddenInput name={'orderId_'+ elementId} register={register} />
        <HiddenInput name={'baseDt_'+ elementId} register={register} />
        <HiddenInput name={'attendanceUpdatedAt_'+ elementId} register={register} />
      </ColumnOccupationTD>
      <ColumnWrap style={{ width: '7rem', height: '3rem' }}>
        <CellPanel>
          <CellTop>
            <SelectGroup
              name={'attendanceWorkDivision_' + elementId}
              width='6rem'
              margin='auto'
              options={workDivisions}
              register={register}
              errors={errors}
              onChange={onChange}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'attendanceWorkTotalDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'attendanceRestTotalDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'attendanceOverTotalDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'attendanceWorkStartDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'attendanceWorkEndDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'attendanceRestStart1Dt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'attendanceRestEnd1Dt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'attendanceRestStart2Dt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'attendanceRestEnd2Dt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'attendanceRestStart3Dt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'attendanceRestEnd3Dt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '10rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'attendanceRemark_' + elementId}
              width='8rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
            <Button type='button' margin='auto'  padding='0' width='3rem' name='履歴' 
            onClick={() => onShowPunchHistoryModal(workInfo)}
            />
      </ColumnWrap>
    </tr>
  )
}

DailyTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,
  workDivisions: PropTypes.array,
  defaultUrl: PropTypes.string,
  dailyWorkInfo: PropTypes.object,
  handleOpenPunchHistoryFunc:PropTypes.func,
}

export default DailyTableRow
