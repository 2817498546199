import React from 'react'
import PropTypes from 'prop-types'

import MonthTableWrap from '../../Wrap/MonthTableWrap'
import MonthTableHeader from './MonthTableHeader'
import MonthTableBody from './MonthTableBody'

const MonthTable = ({ dateDataList, staffWorkInfoList, selectedIndex, selectFunction, updatedShiftFunc, defaultUrl ,shiftFilter,staffWorkInfoAllList,workDivisions}) => {

  const calcTableWidth = (count) => {
    return (count * 3).toString() + 'rem' + ' + ' + count.toString() + 'px';
  }

  return (
    <MonthTableWrap visible={dateDataList.length ? true : false} width={calcTableWidth(dateDataList.length)}>
      <MonthTableHeader
        dateDataList={dateDataList}
        staffWorkInfoList={staffWorkInfoAllList}
        selectFunction={selectFunction}
      >
      </MonthTableHeader>
      <MonthTableBody
        staffWorkInfoList={staffWorkInfoList}
        updatedShiftFunc={updatedShiftFunc}
        defaultUrl={defaultUrl}
        shiftFilter={shiftFilter}
        workDivisions={workDivisions}
      >
      </MonthTableBody>
    </MonthTableWrap>
  )
}

MonthTable.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
  workDivisions: PropTypes.array,
}

export default MonthTable
