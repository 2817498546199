import styled from 'styled-components'

export const ColumnDateTH = styled.th`
  width: 8rem;
  height: 2rem;
  vertical-align:middle;
`
export const ColumnStatusTH = styled.th`
  width: 4rem;
  height: 2rem;
  vertical-align:middle;
`

export const ColumnButtonsTH = styled.th`
  width: 4.5rem;
  height: 2rem;
  vertical-align:middle;
`

export const ColumnDateTD = styled.td`
  width: 8rem;
  vertical-align:middle;
  text-align:center;
`

export const ColumnStatusTD = styled.td`
  width: 4rem;
  vertical-align:middle;
  text-align:center;
  color: #FFFF;
`

export const ColumnButtonsTD = styled.td`
  width: 4.5rem;
  height: 2.5rem;
  max-height: 2.5rem;
  vertical-align:middle;
  padding-left 0.25rem;
`

const PunchTableWrap = styled.table`
  font-size: 12px;
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(16.5rem + 16px + 12px)
  }

  tbody {
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(30rem);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
    height: 2rem;
    background-color: #649cdf;
  }

  td {
    height: 3rem;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

export default PunchTableWrap
