import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CellWrap = styled.td`
  width: 4rem;
  ${props => props.addStyle}
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
`

const Column = styled.td`
  text-align: right;
  align-items: right;
  padding-right: 1rem;
  height: 20px;
`

const MonthTableDailySummaryCell = ({ shiftDateCount, attendanceDateCount }) => {

  return (
    <CellWrap>
      <CellPanel>
        <Column>{shiftDateCount}</Column>
        <Column>{attendanceDateCount}</Column>
      </CellPanel>
    </CellWrap>
  )
}

MonthTableDailySummaryCell.propTypes = {
    shiftDateCount: PropTypes.string,
    attendanceDateCount: PropTypes.string
}

export default MonthTableDailySummaryCell
