import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import DailyTableHeader from './HopeTableHeader'
import DailyTableBody from './HopeTableBody'

const TableWrap = styled.table`
  font-size: 12px;
  cellpadding: 0;
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  tbody {
    overflow-x: hidden;
    overflow-y: auto;
    height: 4.5rem;
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
    margin-top:0.25rem;
    margin-bottom:0.25rem;
  }
  
  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th {
    height: 2rem;
    background-color: #649cdf;
  }

  tbody tr {
    :nth-child(even){
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

const DailyTable = ({
    register, getValues, setValue, watch, reset, errors,
    defaultUrl, workInfoList, dailyWorkInfo,selectedIndex, selectFunction,workDivisions,restFlag
  }) => {

  return (
    <TableWrap visible={true}>
      <DailyTableHeader
      >
      </DailyTableHeader>
      <DailyTableBody
        register={register}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        reset={reset}
        errors={errors}
        workDivisions={workDivisions}
        defaultUrl={defaultUrl}
        workInfoList={workInfoList}
        restFlag={restFlag}
        dailyWorkInfo={dailyWorkInfo}
      >
      </DailyTableBody>
    </TableWrap>
  )
}

DailyTable.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func
}

export default DailyTable
