import styled from 'styled-components'

export const SearchWrap = styled.div`
display:${props => props.display || 'flex'};
padding: 1vw;
width:80vw;
flex-wrap: wrap;
h3 {
  line-height: 32px;
  font-size: 24px;
  margin-right: 16px;
}
ul.mainSearch {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  position: relative;
  > li {
    display: flex;
    margin: 0 0 0 1rem;
    label {
      line-height: 32px;
      text-align: right;
      font-size: 12px;
      margin: 0 0.5rem 0 0;
    }
  }
  li.subSearch {
    display: ${props => props.viewSubSearch ? 'block' : 'none' };
    position: absolute;
    padding: 0 16px;
    margin: 0;
    top: 60px;
    z-index: 3;
    left: 16px;
    background-color: #ffffff;
    box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  }
}

li.mainFunction {
    display: ${props => props.viewFunction? 'block' : 'none' };
    position: absolute;
    align-items: left;
    margin: 0;
    top: calc(120px + 5vh);
    z-index: 1;
    left: calc(92vw - 200px - 1rem);
    background-color: #cfe2f3;
    box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  }
${props => props.addStyle}
`

export const TableWrap = styled.table`
  font-size: 12px;
  cellpadding: 0;
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }
  thead {
    width: calc(91rem + 24px)
  }
  tbody {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(60vh - 6rem - 5.5px );
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
    margin-top:0.25rem;
    margin-bottom:0.25rem;
  }
  
  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th {
    height: 2rem;
    background-color: #649cdf;
  }

  tbody tr {
    :nth-child(even){
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

export const TransportationTableWrap = styled.table`
  font-size: 12px;
  cellpadding: 0;
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }
  thead {
    width: calc(46rem + 17px)
  }
  tbody {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(9rem - 3px);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
    margin-top:0.25rem;
    margin-bottom:0.25rem;
  }
  
  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th {
    height: 2rem;
    background-color: #649cdf;
  }

  tbody tr {
    :nth-child(even){
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

export const AttendancePageButtonWrap = styled.div`
display: flex;
align: right;
margin-left:calc(36vw - 7rem);
`