import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import SearchCalendar from '../../Common/Atoms/Calendar'
import Button from '../../Common/Atoms/NormalButton'
import { PostApi } from '../../Common/ApiAxios'

import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconCloseImg from '../../../image/close_icon.png'
import IconDownloadImg from '../../../image/download-icon.png'
import IconUploadImg from '../../../image/upload-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'

import SearchWrap,{FunctionContent,FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/SearchWrap'

const Search = (props) => {
  const {
    title, showButton, onClick, onKeyDown, agreementDateRef, firstSearch, grants,
    inSubChildren,
    addStyle, children, relationStatus,
    viewFunction,onClickFunction,onImport,onExport
  } = props
  const dispatch = useDispatch();
  const history = useHistory();
  const [agreementDate, setAgreementDate] = useState();
  const [renderFlg, setRenderFlg] = useState(false);
  const [plusButton, setPlusButton] = useState(false);
  const alert = useAlert();
  const loginUserInfo = useSelector(state => state.loginUserInfo);

  // 初期値
  useEffect(() => {
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, dispatch])

  // レンダー終了判定

  // 初期検索
  useEffect(() => {
    if (renderFlg && firstSearch) {
      onClick()
    }
  }, [renderFlg, firstSearch, onClick])

  const changeCalendar = (date) => {
    agreementDateRef.current = date;
    setAgreementDate(date)
  }

  useEffect(() => {
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, relationStatus])
  
  // TOP画面遷移クリックイベント
  async function topPage () {
    history.push('/top');
  }

  async function logout () {
    const apiUrl = '/api/logout'
    await PostApi(apiUrl, null)
    document.cookie = 'accessToken=;path=/; max-age=0;'
    document.cookie = 'refreshToken=;path=/; max-age=0;'
    document.cookie = 'accessTokenExpires=;path=/; max-age=0;'
    document.cookie = 'refreshTokenExpires=;path=/; max-age=0;'
    window.location.href = '/login'
  }


  const handleFileSelect=async(e)=> {
    e.persist()
    if (!(e.target instanceof HTMLInputElement)) {
        return
    }
    if (!e.target.files){
        return
    }
    if (!e.target.files[0]){
        return
    }

    // 読み込んだCSVファイルを文字列に変換
    const file = await e.target.files[0].text()

    // 配列を定義
    let csvArray = [];
    
    // 改行ごとに配列化
    let lines = file.split(/\r\n|\n/);
    //ヘッダー
    let header = lines[0].split(",");
    // 1行ごとに処理
    for (let i = 1; i < lines.length; ++i) {
      let cells = lines[i].split(",");
      if (cells.length != 1) {
        var tmp={}
        cells.forEach((cell,index) => {
            tmp[header[index]]=cell
        });
        csvArray.push(tmp);
      }
    }
    
    // コンソールに配列を出力
    console.log(csvArray);
    onImport(csvArray)
  }

  const viewSubSearch = false
  return (
    <SearchWrap addStyle={addStyle} plusButton={plusButton} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{title}</h3>
      <ul className='mainSearch'>
        <li>
          <label>契約日付</label>
          <SearchCalendar changeCalendar={changeCalendar} dateFormat='yyyy/MM' dataValue={agreementDateRef.current} isViewIcon />
        </li>
      </ul>
      {showButton && <Button margin='0 0 0 16px' name='検索' padding='0px' onClick={onClick} />}
      <Tooltip title={'機能メニュー'}>
        <img src={IconFunctionImg} width="24px" height="24px" style={{marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px'}}>
            <FunctionIcon src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
          <hr/>
          <label>
          <FunctionContentRow>
            <FunctionIcon src={IconUploadImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>アップロード</span></FunctionName>
            <input type="file" accept="text/csv" style={{display:'none'}} onChange={handleFileSelect}/>
          </FunctionContentRow>
          </label>
          <FunctionContentRow onClick={onExport}>
            <FunctionIcon src={IconDownloadImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>ダウンロード</span></FunctionName>
          </FunctionContentRow>
          <hr/>
          <FunctionContentRow onClick={logout} style={{marginBottom:'12px'}}>
            <FunctionIcon src={IconCloseImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>ログアウト</span></FunctionName>
          </FunctionContentRow>
        </FunctionContent> 
      </li>
    </SearchWrap>
  )
}

Search.propTypes = {
  title: PropTypes.string, // タイトル
  showButton: PropTypes.bool, // 検索ボタン表示
  onClick: PropTypes.func, // 検索処理
  onKeyDown: PropTypes.func, // キーダウン処理
  agreementDateRef: PropTypes.object, // 検索項目関連
  firstSearch: PropTypes.bool, // 最初に検索処理をするか
  grants: PropTypes.object, // 権限関連
  inSubChildren: PropTypes.bool, // その他メニューをサブメニューに表示 メインには表示しない
  addStyle: PropTypes.string, // css用追加用
  children: PropTypes.any, // 子要素
  viewFunction : PropTypes.bool, // 機能メニューを表示・非表示フラッグ
  onClickFunction: PropTypes.func, // 機能メニューを表示する処理
  onExport: PropTypes.func, //エクスポート処理
  onImport: PropTypes.func, //インポート処理
}

export default Search
