import React, { useState } from 'react'
import PropTypes from 'prop-types'

import DailyTableWrap from '../../Wrap/DailyTableWrap'
import DailyTableHeader from './DailyTableHeader'
import DailyTableBody from './DailyTableBody'
import DailyAttendanceModal from '../DailyAttendanceModal/DailyAttendanceModal'
import PunchHistoryModal from '../PunchHistoryModal/PunchHistoryModal'

import { useAlert } from 'react-alert'
import { useSelector, useDispatch } from 'react-redux'
import { GetApi,MultipartApi } from '../../../Common/ApiAxios'
import ErrorHandling from '../../../Common/ErrorHandling'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'

//import PaymentDuductionDialog from '../PaymentDuductionDialog/PaymentDuductionModal'

const DailyTable = ({ 
  register, getValues, setValue, watch, reset, errors, 
  dateDataList, staffWorkInfoList, selectedIndex, selectFunction, updatedAttendanceFunc, defaultUrl,workDivisions,count }) => {
  const [openPunchHistory, setOpenPunchHistory] = useState(false);
  const [openDailyAttendance, setOpenDailyAttendance] = useState(false);
  
  const [workInfo, setWorkInfo] = useState();
  const [dailyWorkInfoList, setDailyWorkInfoList] = useState([]);

  const alert = useAlert()
  const dispatch = useDispatch()
  const [punchDailyDataList, setPunchDailyDataList] = useState([])

  const [restFlag,setRestFlag] = useState(false)

  const handleOpenPunchHistory = (workInfo,restFlag) => {
    const getPunchData = async (workInfo) => {
      dispatch(SetLoadSpinner(true))
  
      const searchOption = {
        targetDt: workInfo.baseDt,
        staffId: workInfo.staffId,
        orderId: workInfo.orderId
      }
      const resultDate = await GetApi('/api/punchManagement/punch/daily', searchOption)
      
      dispatch(SetLoadSpinner(false))
      if (resultDate.errorDetail) {
        ErrorHandling(resultDate, alert)
        return
      } else {
        console.log(resultDate.data.responseList.length>0)
        console.log(resultDate.data.responseList)
        //setCanUpdate(resultDate.data.responseList.length>0)
        setPunchDailyDataList(resultDate.data.responseList)
      }
      if(resultDate.data.responseList.length>0){
        setOpenPunchHistory(true)
      }else{
        alert.error("打刻履歴がありません")
        return
      }
    }
    setWorkInfo(workInfo)
    getPunchData(workInfo)
    setRestFlag(restFlag)
    //setOpenPunchHistory(true);
  }

  const handleClosePunchHistory = () => {
    setOpenPunchHistory(false)
  }
  const handleOpenDailyAttendance = (dataArray) => {
    setDailyWorkInfoList(dataArray)
    setOpenDailyAttendance(true);
  }

  const handleCloseDailyAttendance = () => {
    setOpenDailyAttendance(false)
  }

  return (
    <>
    <DailyTableWrap visible={count>0 ? true : false}>
      <DailyTableHeader
        staffWorkInfoList={staffWorkInfoList}
      >
      </DailyTableHeader>
      <DailyTableBody
        register={register}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        reset={reset}
        errors={errors}
        
        dateDataList={dateDataList}
        staffWorkInfoList={staffWorkInfoList}
        updatedAttendanceFunc={updatedAttendanceFunc}
        defaultUrl={defaultUrl}

        handleOpenPunchHistoryFunc={handleOpenPunchHistory}
        handleClosePunchHistoryFunc={handleClosePunchHistory}
        handleOpenDailyAttendanceFunc={handleOpenDailyAttendance}
        handleCloseDailyAttendanceFunc={handleCloseDailyAttendance}
      >
      </DailyTableBody>
    </DailyTableWrap>
    
    <PunchHistoryModal
        open={openPunchHistory}
        onCloseFunc={handleClosePunchHistory}
        updatedPunchFunc={updatedAttendanceFunc}
        workInfo={workInfo}
        punchDailyList={punchDailyDataList}
        restFlag={restFlag}
        onCloseAttendanceFunc={handleCloseDailyAttendance}
    >
    </PunchHistoryModal>
    <DailyAttendanceModal
        open={openDailyAttendance}
        onCloseFunc={handleCloseDailyAttendance}
        dailyWorkInfoList={dailyWorkInfoList}
        workDivisions={workDivisions}
        defaultUrl={defaultUrl}
        updatedAttendanceFunc={updatedAttendanceFunc}
        handleOpenPunchHistoryFunc={handleOpenPunchHistory}
    >
    </DailyAttendanceModal>

    </>

  )
}

DailyTable.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  workDivisions: PropTypes.array,
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func,
  updatedAttendanceFunc: PropTypes.func,
  defaultUrl: PropTypes.string
}

export default DailyTable
