import React, { useState, useEffect,useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet ,Font} from '@react-pdf/renderer';
import YearCalendar from '../../../Common/Atoms/YearCalendar'
import Button from '../../../Common/Atoms/NormalButton'
import IconPrintImg from '../../../../image/printer-icon.png' 
import IconGetImg from '../../../../image/update-icon.png' 
import {dtFormatYMD } from '../../../Common/Utilties/LaborUtility'
import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../../Common/ErrorHandling'
import SalariedTableHeader from './SalariedDialogHeader'
import SalariedTableBody from './SalaridTableBody'
import FontBold from '../../../../font/Nasu-Bold.ttf'
import FontRegular from '../../../../font/Nasu-Regular.ttf'
import Tooltip from '@material-ui/core/Tooltip'
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  width:70vw;
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
`
const FormWrap = styled.form`
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
`
const StaffNameWrap = styled.div`
  font-weight: bold;
  line-height: 30px;
  font-size: 20px;
  width:56vw;
  display: flex;
  margin-bottom:12px;
`

const ContentNameWrap = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  border: solid 0.5px;
  border-right-style: ${props => props.borderRightStyle};
  border-color: #808080;
`
const TextWrap = styled.div`
  font-size: 16px;
  margin: ${props => props.margin};
  justify-content: center;
  color: ${props => props.color};
  display: flex;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

export const IconImg = styled.img`
  align-items: left;
  width: 20px;
  height: 20px;
`
export const NotIconImg = styled.div`
  align-items: left;
  width: 20px;
  height: 20px;
`
export const MonthTableWrap = styled.table`
  font-size: 12px;
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(71vw + 18px);
  }

  tbody {
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(20vw + 13px);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
  }

  td {
    height:2rem;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

const SalariedManagementDialog = ({ open,onCloseFunc, targetDt,targetId, defaultUrl}) => {
  const [data, setData] = useState(
    {
    acquiredDays: 0
    ,acquisitionDt: ''
    ,actualSalariedDates: []
    ,enterDt: ''
    ,remaingDaysPreviousYear: 0
    ,staffId: ''
    ,staffName: ''
    }
    )
  const [staffId, setStaffId] = useState('')
  const [staffName, setStaffName] = useState('')
  const [enterDt, setEnterDt] = useState('')
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();
  const [rows, setRows] = useState(<></>)
  const [salariedDt, setSalariedDt] = useState(0)
  const alert = useAlert()
  const dispatch = useDispatch()
  // 期間指定
  const salariedDateRef = useRef(new Date(targetDt).getFullYear()+'-01-01')
  const onChangeRangeCalendar = (fromDate) => {
    console.log(fromDate)
    salariedDateRef.current = fromDate;
  }
  useEffect(() => {
    if(!targetId)
        return
    salariedDateRef.current = targetDt;
    onSalariedSearch()
  }, [targetDt])
  useEffect(() => {
    if(!targetId)
     return
    onSalariedSearch()
  }, [targetId])
  
  // 検索APIの実行
  const onSalariedSearch = async () => {
        dispatch(SetLoadSpinner(true))
        const data = {
              targetDt: salariedDateRef.current,
              staffId: targetId,
        }

        const result = await GetApi(`${defaultUrl}/${data.targetDt}/${targetId}`, data)
        dispatch(SetLoadSpinner(false))
        if (result.errorDetail) {
          ErrorHandling(result, alert)
          return null
        } else {
          console.log(result.data.responseList)
          if(result.data.responseList)
            setData(result.data.responseList[0])
            var acquiredDays=0
            var remaingDaysPreviousYear=0
            if(result.data.responseList){
                acquiredDays = (result.data.responseList[0].acquiredDays?result.data.responseList[0].acquiredDays:0)
                remaingDaysPreviousYear = (result.data.responseList[0].remaingDaysPreviousYear?result.data.responseList[0].remaingDaysPreviousYear:0)
            }
            setSalariedDt(acquiredDays + remaingDaysPreviousYear)
            //console.log(acquiredDays + remaingDaysPreviousYear)
        }
  }

  const onClick=()=>{
    onSalariedSearch()
    console.log('OK')
  }

  const onPrint=async()=>{
    //window.print()
    // const blob = new Blob([value], { type: 'pdf' });
    // const url = window.URL.createObjectURL(blob);
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = "有給管理簿.pdf"; 
    // a.click();
    // a.remove();
    // window.URL.revokeObjectURL(url);
    // console.log('OK')
    //let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
  }
  // ttfファイルのフォント定義
  // フォント「ナス レギュラー」
  Font.register({
    family: "Nasu-Regular",
    src: FontRegular,
  });

  // フォント「ナス 太字」
  Font.register({
    family: "Nasu-Bold",
    src: FontBold,
  });
  // Create styles
  const styles = StyleSheet.create({
    page: {
      paddingTop: 30,
      paddingBottom: 65,
      paddingHorizontal: 5,
    },
    //テキストstyle
    title: {
      fontFamily: "Nasu-Bold",
      fontSize:24,
      textAlign:'center'
    },
    staffName:{
      fontFamily: "Nasu-Bold",
      fontSize:12,
      textAlign:'left'
    },
    sections: {
      fontFamily: "Nasu-Bold",
      fontSize:12,
      flexDirection: "row",
      textAlign:'center',
    },
    sectionHeader: {
      fontFamily: "Nasu-Bold",
      fontSize:10,
      flexDirection: "row",
      textAlign:'center',
    },
    sectionCenterHeader: {
      fontFamily: "Nasu-Bold",
      fontSize:10,
      flexDirection: "row",
      textAlign:'center',
      marginTop:8
    },
    sectionCenterRow: {
      fontFamily: "Nasu-Regular",
      fontSize:10,
      flexDirection: "row",
      textAlign:'center',
      marginTop:8
    },
    sectionsCenter: {
      fontFamily: "Nasu-Bold",
      fontSize:12,
      flexDirection: "row",
      textAlign:'center',
      marginTop:7
    },
    sectionsTop: {
      fontFamily: "Nasu-Bold",
      fontSize:12,
      flexDirection: "row",
      color:'#808080',
      textAlign:'center',
    }, 
    //tableb style
    table: {
      width: "auto",
      borderStyle: 'solid',
      borderColor: 'black',
      borderWidth: 1,
      borderTopWidth:0,
      borderBottomWidth:0,
      borderRightWidth: 1,
    },
    //row style
    tableRow: {
      margin: "auto",
      flexDirection: "row",
      borderBottomWidth: 1,
    },
    tableRowHeader: {
      margin: "auto",
      flexDirection: "row",
      borderTopWidth: 1,
      borderBottomWidth: 1,
    },
    //cell style
    tableColDateHeader: {
      width:98,
      borderStyle: 'solid',
      borderColor: 'black',
      borderRightWidth: 1,
      height:30,
    },
    tableColHeader: {
      width:210,
      borderStyle: 'solid',
      borderColor: 'black',
      borderRightWidth: 1,
      height:30,
    },   
    tableColDaysHeader: {
      width:40,
      borderStyle: 'solid',
      borderColor: 'black',
      borderRightWidth: 1,
      height:30,
    },
    tableColTimeHeader: {
      width:61,
      borderStyle: 'solid',
      borderColor: 'black',
      borderRightWidth: 1,
      borderLeftWidth: 0,
      height:30,
    },
    tableColRemarkHeader: {
      width:190,
      borderStyle: 'solid',
      borderColor: 'black',
      borderLeftWidth: 0,
      height:30,
    },
    });

  var count=0
  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>年次有給休暇管理簿</Text>
        <Text style={styles.staffName} >氏名：{data.staffName}</Text>
        <View style={styles.table}>
          <View style={styles.tableRowHeader}>
            <View style={styles.tableColDateHeader}>
              <Text style={styles.sectionsTop}>入社年月日</Text>
              <Text style={styles.sections}>{dtFormatYMD(data.enterDt)}</Text>
            </View>
            <View style={styles.tableColDateHeader}>
              <Text style={styles.sectionsTop}>付与日</Text>
              <Text style={styles.sections}>{dtFormatYMD(data.acquisitionDt)}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.sectionsTop}>有給年間範囲</Text>
              <Text style={styles.sections}>{dtFormatYMD(data.acquisitionDt)} ～ {dtFormatYMD(data.nextAcquisitionDt)}</Text>
            </View>
            <View style={styles.tableColDateHeader}>
              <Text style={styles.sectionsTop}>前年度繰越日数</Text>
              <Text style={styles.sectionsTop}>今年度付与日数</Text>
            </View>
            <View style={styles.tableColDaysHeader}>
              <Text style={styles.sections}>{(data.remaingDaysPreviousYear || data.remaingDaysPreviousYear==0)?data.remaingDaysPreviousYear:'-'}日</Text>
              <Text style={styles.sections}>{(data.acquiredDays || data.acquiredDays==0)?data.acquiredDays:'-'}日</Text>
            </View>
            <View style={styles.tableColDaysHeader}>
              <Text style={styles.sectionsCenter}>
                計{((data.acquiredDays || data.acquiredDays==0)||(data.remaingDaysPreviousYear || data.remaingDaysPreviousYear==0))?data.acquiredDays+data.remaingDaysPreviousYear:'-'}日
              </Text>
            </View>
          </View>
        </View>
        <View style={{height:10}}/>
        <View style={styles.table}>
          <View style={styles.tableRowHeader} fixed>
            <View style={styles.tableColHeader}>
              <Text style={styles.sectionHeader}>年次有給休暇取得年月日</Text>
              <Text style={styles.sectionHeader}>自年月日 ～ 至年月日</Text>
            </View>
            <View style={styles.tableColTimeHeader}>
              <Text style={styles.sectionCenterHeader}>使用日数</Text>
            </View>
            <View style={styles.tableColTimeHeader}>
              <Text style={styles.sectionCenterHeader}>残日数</Text>
            </View>
            <View style={styles.tableColTimeHeader}>
              <Text style={styles.sectionCenterHeader}>種別</Text>
            </View>
            <View style={styles.tableColRemarkHeader}>
              <Text style={styles.sectionCenterHeader}>備考</Text>
            </View>
          </View>
          {data.actualSalariedDates && data.actualSalariedDates.map((workInfo) => {
            count++;
            return (
              <View key={workInfo.staffId+'_'+workInfo.salariedDate} style={styles.tableRow} wrap={false}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.sectionCenterRow}>{workInfo.salariedDate} ～ {workInfo.salariedDate}</Text>
                </View>
                <View style={styles.tableColTimeHeader}>
                  <Text style={styles.sectionCenterRow}>1</Text>
                </View>
                <View style={styles.tableColTimeHeader}>
                  <Text style={styles.sectionCenterRow}>{salariedDt - (count)}</Text>
                </View>
                <View style={styles.tableColTimeHeader}>
                  <Text style={styles.sectionCenterRow}>{workInfo.salariedDivisionName}</Text>
                </View>
                <View style={styles.tableColRemarkHeader}>
                  <Text style={styles.sectionCenterRow}>{workInfo.remark}</Text>
                </View>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  );

  return (
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='年次有給休暇管理簿'
      aria-describedby='年次有給休暇管理簿ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap>
    <div className={classes.paper}>
      <TitleWrap>年次有給休暇管理簿</TitleWrap>
      <TableWrap>
          <StaffNameWrap>氏名：{data.staffName}</StaffNameWrap>
          <YearCalendar dataValue={salariedDateRef.current} changeCalendar={onChangeRangeCalendar} isViewIcon={true} />
          <Tooltip title={'更新'}>
          <img src={IconGetImg} width="36px" height="36px" style={{ cursor: 'pointer' }} onClick={onClick} />
          </Tooltip>
          <Tooltip title={'印刷'}>
          <div>
          <PDFDownloadLink document={<MyDocument />} fileName={+salariedDateRef.current.slice(0,4) +"年度有給管理簿("+data.staffName+").pdf"}>
            <img src={IconPrintImg} width="36px" height="36px" style={{ marginLeft: '12px',cursor: 'pointer' }}/>
            {/* {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')} */}
          </PDFDownloadLink>
          </div>    
          </Tooltip>
      </TableWrap>
      <TableWrap>
          <ContentNameWrap width={'11vw'} height={'50px'} borderRightStyle={'none'}>
            <TextWrap margin={'3px 0 12px 0'} color={'#808080'}>入社年月日</TextWrap>
            <TextWrap>{dtFormatYMD(data.enterDt)}</TextWrap>
          </ContentNameWrap>
          <ContentNameWrap width={'11vw'} height={'50px'} borderRightStyle={'none'}>
            <TextWrap margin={'3px 0 12px 0'} color={'#808080'}>付与日</TextWrap>
            <TextWrap>{dtFormatYMD(data.acquisitionDt)}</TextWrap>
          </ContentNameWrap>
          <ContentNameWrap width={'22vw'} height={'50px'} borderRightStyle={'none'}>
            <TextWrap margin={'3px 0 12px 0'} color={'#808080'}>有給年間範囲</TextWrap>
            <TextWrap>{dtFormatYMD(data.acquisitionDt)} ～ {dtFormatYMD(data.nextAcquisitionDt)}</TextWrap>
          </ContentNameWrap>
          <ContentNameWrap width={'11vw'} height={'50px'} borderRightStyle={'none'}>
            <TextWrap margin={'3px 0 12px 0'} color={'#808080'}>前年度繰越日数</TextWrap>
            <TextWrap color={'#808080'}>今年度付与日数</TextWrap>
          </ContentNameWrap>
          <ContentNameWrap width={'5vw'} height={'50px'} borderRightStyle={'none'}>
            <TextWrap margin={'3px 0 12px 0'}>{(data.remaingDaysPreviousYear || data.remaingDaysPreviousYear==0)?data.remaingDaysPreviousYear:'-'}日</TextWrap>
            <TextWrap>{(data.acquiredDays || data.acquiredDays==0)?data.acquiredDays:'-'}日</TextWrap>
          </ContentNameWrap>
          <ContentNameWrap width={'11vw'} height={'50px'}>
            <TextWrap margin={'15px 0 12px 0'}>計{((data.acquiredDays || data.acquiredDays==0)||(data.remaingDaysPreviousYear || data.remaingDaysPreviousYear==0))?data.acquiredDays+data.remaingDaysPreviousYear:'-'}日</TextWrap>
          </ContentNameWrap>
      </TableWrap>
      <MonthTableWrap visible={true}>
        <SalariedTableHeader></SalariedTableHeader>
        <SalariedTableBody workInfoList={data.actualSalariedDates}
        salariedDt = {salariedDt}
        ></SalariedTableBody>
      </MonthTableWrap>
      <ButtonsWrap>
        <Button name='閉じる' padding='0px' onClick={onCloseFunc} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

SalariedManagementDialog.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    targetDt: PropTypes.any,
    targetId: PropTypes.string,
    onSalariedSearch: PropTypes.func,
}

export default SalariedManagementDialog