import React, { useEffect, useState, useCallback } from 'react'
import { useAlert } from 'react-alert'
//import styled from 'styled-components'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove';

import { GetApi } from '../../Common/ApiAxios'
import ErrorHandling from '../../Common/ErrorHandling'
import Calendar from '../../Common/Atoms/Calendar'
import * as regex from '../../Common/Regex'
import { InputGroup, SelectGroup, HiddenInput } from '../../Common/CommonInputGroup'
import { Alert } from '../../Common/CommonStyled'
import { format48Time, diff48Time } from '../../Common/Utilties/LaborUtility'
import { formatMoney } from '../../Common/Utilties/MoneyUtility'

import StaffModal from './Molcules/StaffDialog/StaffModal'
import OrderModal from './Molcules/OrderDialog/OrderModal'
import Button from '../../Common/Atoms/NormalButtonAttendance'

import MasterDetailWrap, { MasterDetailFormWrap } from '../Wrap/MasterDetailWrap'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75vw',
  },
  typography: {
    width: '72vw',
    fontSize: '18px',
    fontWeight: '700',
    color: '#ffffff',
    verticalAlign: 'middle',
    margin: '0',
    padding: '16px',
    background: '#4169E1',
  },
}))

export const AllocationMasterForm = ({
  register, errors, setValue, getValues, allocationDetail,
  watch, canWrite, loginUserInfo, isDeleted, actionType,
  isView
}) => {
  const classes = useStyles();
  const alert = useAlert()
  const [workTypeOptions, setWorkTypeOptions] = useState(<></>)
  const [wagesTypeOptoins, setWagesTypeOptions] = useState(<></>)
  const [contractDivision, setContractDivision] = useState(0)
  const [staffIds, setStaffIds] = useState(new Array())
  // 引当情報切り替え時
  useEffect(() => {
    // 引当情報が変更された場合のみ実行
    if (allocationDetail != null) {
      setEmploymentStartDt(allocationDetail.employmentStartDt)
      setEmploymentEndDt(allocationDetail.employmentEndDt)
      setRetireDt(allocationDetail.retireDt)

      setValue('staffId', allocationDetail.staffId)
      setValue('staffName', allocationDetail.staffName)
      setValue('orderId', allocationDetail.orderId)
      // setValue('employmentStartDt', allocationDetail.employmentStartDt)
      // setValue('employmentEndDt', allocationDetail.employmentEndDt)
      // setValue('retireDt', allocationDetail.retireDt)
      console.log(workTypeOptions)
      console.log(allocationDetail)
      setValue('workType', allocationDetail.workType)
      setValue('workStartDt', diff48Time('1900-01-01', allocationDetail.workStartDt))
      setValue('workEndDt', diff48Time('1900-01-01', allocationDetail.workEndDt))
      //setValue('workTotalDt', allocationDetail.workTotalDt)
      setValue('restStart1Dt', diff48Time('1900-01-01', allocationDetail.restStart1Dt))
      setValue('restEnd1Dt', diff48Time('1900-01-01', allocationDetail.restEnd1Dt))
      setValue('restStart2Dt', diff48Time('1900-01-01', allocationDetail.restStart2Dt))
      setValue('restEnd2Dt', diff48Time('1900-01-01', allocationDetail.restEnd2Dt))
      setValue('restStart3Dt', diff48Time('1900-01-01', allocationDetail.restStart3Dt))
      setValue('restEnd3Dt', diff48Time('1900-01-01', allocationDetail.restEnd3Dt))
      //setValue('restTotalDt', allocationDetail.restTotalDt)
      setValue('workType2', allocationDetail.workType2)
      setValue('workStartDt2', diff48Time('1900-01-01', allocationDetail.workStartDt2))
      setValue('workEndDt2', diff48Time('1900-01-01', allocationDetail.workEndDt2))
      //setValue('workTotalDt2', allocationDetail.workTotalDt2)
      setValue('restStart1Dt2', diff48Time('1900-01-01', allocationDetail.restStart1Dt2))
      setValue('restEnd1Dt2', diff48Time('1900-01-01', allocationDetail.restEnd1Dt2))
      setValue('restStart2Dt2', diff48Time('1900-01-01', allocationDetail.restStart2Dt2))
      setValue('restEnd2Dt2', diff48Time('1900-01-01', allocationDetail.restEnd2Dt2))
      setValue('restStart3Dt2', diff48Time('1900-01-01', allocationDetail.restStart3Dt2))
      setValue('restEnd3Dt2', diff48Time('1900-01-01', allocationDetail.restEnd3Dt2))
      //setValue('restTotalDt2', allocationDetail.restTotalDt2)
      setValue('workType3', allocationDetail.workType3)
      setValue('workStartDt3', diff48Time('1900-01-01', allocationDetail.workStartDt3))
      setValue('workEndDt3', diff48Time('1900-01-01', allocationDetail.workEndDt3))
      //setValue('workTotalDt3', allocationDetail.workTotalDt3)
      setValue('restStart1Dt3', diff48Time('1900-01-01', allocationDetail.restStart1Dt3))
      setValue('restEnd1Dt3', diff48Time('1900-01-01', allocationDetail.restEnd1Dt3))
      setValue('restStart2Dt3', diff48Time('1900-01-01', allocationDetail.restStart2Dt3))
      setValue('restEnd2Dt3', diff48Time('1900-01-01', allocationDetail.restEnd2Dt3))
      setValue('restStart3Dt3', diff48Time('1900-01-01', allocationDetail.restStart3Dt3))
      setValue('restEnd3Dt3', diff48Time('1900-01-01', allocationDetail.restEnd3Dt3))
      //setValue('restTotalDt3', allocationDetail.restTotalDt3)
      setValue('workType4', allocationDetail.workType4)
      setValue('workStartDt4', diff48Time('1900-01-01', allocationDetail.workStartDt4))
      setValue('workEndDt4', diff48Time('1900-01-01', allocationDetail.workEndDt4))
      //setValue('workTotalDt4', allocationDetail.workTotalDt4)
      setValue('restStart1Dt4', diff48Time('1900-01-01', allocationDetail.restStart1Dt4))
      setValue('restEnd1Dt4', diff48Time('1900-01-01', allocationDetail.restEnd1Dt4))
      setValue('restStart2Dt4', diff48Time('1900-01-01', allocationDetail.restStart2Dt4))
      setValue('restEnd2Dt4', diff48Time('1900-01-01', allocationDetail.restEnd2Dt4))
      setValue('restStart3Dt4', diff48Time('1900-01-01', allocationDetail.restStart3Dt4))
      setValue('restEnd3Dt4', diff48Time('1900-01-01', allocationDetail.restEnd3Dt4))
      //setValue('restTotalDt4', allocationDetail.restTotalDt4)
      setValue('workType5', allocationDetail.workType5)
      setValue('workStartDt5', diff48Time('1900-01-01', allocationDetail.workStartDt5))
      setValue('workEndDt5', diff48Time('1900-01-01', allocationDetail.workEndDt5))
      //setValue('workTotalDt5', allocationDetail.workTotalDt5)
      setValue('restStart1Dt5', diff48Time('1900-01-01', allocationDetail.restStart1Dt5))
      setValue('restEnd1Dt5', diff48Time('1900-01-01', allocationDetail.restEnd1Dt5))
      setValue('restStart2Dt5', diff48Time('1900-01-01', allocationDetail.restStart2Dt5))
      setValue('restEnd2Dt5', diff48Time('1900-01-01', allocationDetail.restEnd2Dt5))
      setValue('restStart3Dt5', diff48Time('1900-01-01', allocationDetail.restStart3Dt5))
      setValue('restEnd3Dt5', diff48Time('1900-01-01', allocationDetail.restEnd3Dt5))
      //setValue('restTotalDt5', allocationDetail.restTotalDt5)
      setValue('wagesType', allocationDetail.wagesType)
      setValue('normalPrice', formatMoney(allocationDetail.normalPrice))
      setValue('overPrice', formatMoney(allocationDetail.overPrice))
      setValue('nightPrice', formatMoney(allocationDetail.nightPrice))
      setValue('holNormalPrice', formatMoney(allocationDetail.holNormalPrice))
      setValue('holOverPrice', formatMoney(allocationDetail.holOverPrice))
      setValue('holNightPrice', formatMoney(allocationDetail.holNightPrice))
      setValue('lawNormalPrice', formatMoney(allocationDetail.lawNormalPrice))
      setValue('lawOverPrice', formatMoney(allocationDetail.lawOverPrice))
      setValue('lawNightPrice', formatMoney(allocationDetail.lawNightPrice))
      setValue('billingType', formatMoney(allocationDetail.billingType))
      setValue('normalBillingPrice', formatMoney(allocationDetail.normalBillingPrice))
      setValue('overBillingPrice', formatMoney(allocationDetail.overBillingPrice))
      setValue('nightBillingPrice', formatMoney(allocationDetail.nightBillingPrice))
      setValue('holNormalBillingPrice', formatMoney(allocationDetail.holNormalBillingPrice))
      setValue('holOverBillingPrice', formatMoney(allocationDetail.holOverBillingPrice))
      setValue('holNightBillingPrice', formatMoney(allocationDetail.holNightBillingPrice))
      setValue('lawNormalBillingPrice', formatMoney(allocationDetail.lawNormalBillingPrice))
      setValue('lawOverBillingPrice', formatMoney(allocationDetail.lawOverBillingPrice))
      setValue('lawNightBillingPrice', formatMoney(allocationDetail.lawNightBillingPrice))
      setValue('dailyTransportExpenses', formatMoney(allocationDetail.dailyTransportExpenses))
      setValue('monthlyTransportExpenses', formatMoney(allocationDetail.monthlyTransportExpenses))
      setValue('remark', allocationDetail.remark)
      setValue('updatedAt', allocationDetail.updatedAt)
      setValue('updatedId', allocationDetail.updatedId)

      setContractDivision(allocationDetail.contractDivision) 
      setValue('staffIds', new Array())
    }
  // eslint-disable-next-line
  }, [allocationDetail])

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
      getWorkType()
      getWagesType()
      getContractDivision()
      getStaffType()
    }
    getOptions()
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  // 就業形態取得
  const getWorkType = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/workType')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setWorkTypeOptions(options)
    }
  }, [setWorkTypeOptions, alert])

  // 給与(請求)形態取得
  const getWagesType = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/wagesType')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setWagesTypeOptions(options)
    }
  }, [setWagesTypeOptions, alert])


  // 雇用開始日
  //register({ name: 'employmentStartDt' })
  const [employmentStartDt, _setEmploymentStartDt] = useState()
  const setEmploymentStartDt = (date) => {
    _setEmploymentStartDt(date)
    setValue('employmentStartDt', (date))
    console.log(date)
  }

  // 雇用終了日
  //register({ name: 'employmentEndDt' })
  const [employmentEndDt, _setEmploymentEndDt] = useState()
  const setEmploymentEndDt = (date) => {
    _setEmploymentEndDt(date)
    setValue('employmentEndDt', (date))
  }

  // リタイヤ日
  //register({ name: 'retireDt' })
  const [retireDt, _setRetireDt] = useState()
  const setRetireDt = (date) => {
    _setRetireDt(date)
    setValue('retireDt', (date))
  }

  const onBlurTime = ( event ) => {
    let value = event.target.value
    value = format48Time(value)
    event.target.value = value
  }

  const onBlurMonery = ( event ) => {
    let value = event.target.value
    value = formatMoney(value)
    event.target.value = value
  }

  //スタッフダイアログ
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true);
  }

  // スタッフ区分取得
  const [staffTypeOptions, setStaffTypeOptions] = useState(<></>)
  const getStaffType = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/staffType')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setStaffTypeOptions(options)
    }
  }, [setStaffTypeOptions, alert])
  // 契約区分取得
  const [contractDivisions, setContractDivisions] = useState(<></>)
  const getContractDivision = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/contractType')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      options.unshift(<option value={0} key={0}>{"全て"}</option>)
      setContractDivisions(options)
    }
  }, [setContractDivisions, alert])
  //受注ダイアログ
  const [orderOpen, setOrderOpen] = useState(false);
  const handleOrderClose = () => {
    setOrderOpen(false)
  }
  const handleOrderOpen = () => {
    setOrderOpen(true);
  }

  if (allocationDetail === null) return null // 未選択時の場合は非表示
  return (
    <MasterDetailWrap isView={isView}>
      <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>基本情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li style={{ height: '15px' }}><label /><div /></li>
            {isDeleted && <li><label /><div><Alert>削除データ</Alert></div></li>}
            <li>
              <label className="required">スタッフ</label>
              <div>
                <label>ID</label>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 12px'
                  placeholder='スタッフID'
                  name='staffId'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(10),
                    pattern: regex.halfWidthAlphaNumericHyphen
                  }}
                  errors={errors}
                  disabled={true}
                />
                <label style={{ marginLeft: '12px' }}>名</label>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 12px'
                  placeholder='スタッフ名'
                  name='staffName'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(10),
                  }}
                  errors={errors}
                  disabled={true}
                />
                <Button width='4rem' margin='0 0 0 4px' name='選択' padding='0px' type='button' onClick={handleOpen} disabled={actionType=='PUT'}/>
              </div>              
            </li>
            <li>
              <label className="required">受注ID</label>
              <div>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 0'
                  placeholder='受注ID'
                  name='orderId'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(10),
                    pattern: regex.halfWidthAlphaNumeric
                  }}
                  errors={errors}
                  disabled={true}
                />
                <Button width='4rem' margin='0 0 0 4px' name='選択' padding='0px' type='button' onClick={handleOrderOpen} disabled={actionType=='PUT'}/>
              </div>
            </li>
            <li>
              <label className="required">雇用期間</label>
              <div>
                <Calendar
                  margin='0'
                  name='employmentStartDt'
                  placeholder='開始日'
                  changeCalendar={setEmploymentStartDt}
                  dataValue={employmentStartDt}
                  disabled={isDeleted || !canWrite}
                  register={register}
                  prefix='/'
                  isViewIcon
                />
                <label>～</label>
                <Calendar
                  margin='0'
                  name='employmentEndDt'
                  placeholder='終了日'
                  changeCalendar={setEmploymentEndDt}
                  dataValue={employmentEndDt}
                  disabled={isDeleted || !canWrite}
                  register={register}
                  prefix='/'
                  isViewIcon
                />
              </div>
            </li>
            <li>
              <label>リタイヤ日</label>
              <div>
                <Calendar
                  margin='0 16px 0 0'
                  name='retireDt'
                  placeholder='リタイヤ日'
                  changeCalendar={setRetireDt}
                  dataValue={retireDt}
                  disabled={isDeleted || !canWrite}
                  register={register}
                  prefix='/'
                  isViewIcon
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>就業情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li>
              <label className="required">就業形態1</label>
              <div>
                <SelectGroup
                  name='workType'
                  width='10rem'
                  margin='0'
                  options={workTypeOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
              <div style={{paddingLeft: '55%'}}>
              <Fab size="small" color="primary" aria-label="add">
                <AddIcon />
              </Fab>
              <Fab size="small" color="secondary" aria-label="remove" style={{marginLeft: '1rem'}}>
                <RemoveIcon />
              </Fab>
              </div>
            </li>
            <li>
              <label className="required">勤務時間</label>
              <div>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='workStartDt'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='workEndDt'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>休憩時間</label>
              <div>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restStart1Dt'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd1Dt'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 12px'
                  placeholder='0000～3559'
                  name='restStart2Dt'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='8rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd2Dt'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 12px'
                  placeholder='0000～3559'
                  name='restStart3Dt'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd3Dt'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>

          <ul>
            <li>
              <label>就業形態2</label>
              <div>
                <SelectGroup
                  name='workType_2'
                  width='10rem'
                  margin='0'
                  options={workTypeOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
              <div style={{paddingLeft: '55%'}}>
              <Fab size="small" color="primary" aria-label="add">
                <AddIcon />
              </Fab>
              <Fab size="small" color="secondary" aria-label="remove" style={{marginLeft: '1rem'}}>
                <RemoveIcon />
              </Fab>
              </div>
            </li>
            <li>
              <label>勤務時間</label>
              <div>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='workStartDt2'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='8rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='workEndDt2'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>休憩時間</label>
              <div>
                <InputGroup
                  width='8rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restStart1Dt2'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd1Dt2'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 12px'
                  placeholder='0000～3559'
                  name='restStart2Dt2'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='8rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd2Dt2'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 12px'
                  placeholder='0000～3559'
                  name='restStart3Dt2'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd3Dt2'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>

          <ul>
            <li>
              <label>就業形態3</label>
              <div>
                <SelectGroup
                  name='workType_3'
                  width='10rem'
                  margin='0'
                  options={workTypeOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
              <div style={{paddingLeft: '55%'}}>
              <Fab size="small" color="primary" aria-label="add">
                <AddIcon />
              </Fab>
              <Fab size="small" color="secondary" aria-label="remove" style={{marginLeft: '1rem'}}>
                <RemoveIcon />
              </Fab>
              </div>
            </li>
            <li>
              <label>勤務時間</label>
              <div>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='workStartDt3'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='workEndDt3'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>休憩時間</label>
              <div>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restStart1Dt3'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd1Dt3'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 12px'
                  placeholder='0000～3559'
                  name='restStart2Dt3'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd2Dt3'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 12px'
                  placeholder='0000～3559'
                  name='restStart3Dt3'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd3Dt3'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>

          <ul>
            <li>
              <label>就業形態4</label>
              <div>
                <SelectGroup
                  name='workType_4'
                  width='10rem'
                  margin='0'
                  options={workTypeOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
              <div style={{paddingLeft: '55%'}}>
              <Fab size="small" color="primary" aria-label="add">
                <AddIcon />
              </Fab>
              <Fab size="small" color="secondary" aria-label="remove" style={{marginLeft: '1rem'}}>
                <RemoveIcon />
              </Fab>
              </div>
            </li>
            <li>
              <label>勤務時間</label>
              <div>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='workStartDt4'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='workEndDt4'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>休憩時間</label>
              <div>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restStart1Dt4'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd1Dt4'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 12px'
                  placeholder='0000～3559'
                  name='restStart2Dt4'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='8rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd2Dt4'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 12px'
                  placeholder='0000～3559'
                  name='restStart3Dt4'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd3Dt4'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>

          <ul>
            <li>
              <label>就業形態5</label>
              <div>
                <SelectGroup
                  name='workType_5'
                  width='10rem'
                  margin='0'
                  options={workTypeOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
              <div style={{paddingLeft: '55%'}}>
              <Fab size="small" color="primary" aria-label="add" disabled>
                <AddIcon />
              </Fab>
              <Fab size="small" color="secondary" aria-label="remove" style={{marginLeft: '1rem'}}>
                <RemoveIcon />
              </Fab>
              </div>
            </li>
            <li>
              <label>勤務時間</label>
              <div>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='workStartDt5'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='workEndDt5'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>休憩時間</label>
              <div>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restStart1Dt5'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd1Dt5'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 12px'
                  placeholder='0000～3559'
                  name='restStart2Dt5'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd2Dt5'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 12px'
                  placeholder='0000～3559'
                  name='restStart3Dt5'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='7rem'
                  margin='12px 0 12px 0'
                  placeholder='0000～3559'
                  name='restEnd3Dt5'
                  register={register}
                  rule={{
                    pattern:  regex.time ,
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>給与情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li>
              <label>給与区分</label>
              <div>
                <SelectGroup
                  name='wagesType'
                  width='10rem'
                  margin='0'
                  options={wagesTypeOptoins}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>支給</label>
              <div>
                <label style={{ width: '2rem' }}></label>
                <label style={{ width: '8rem', margin: '12px 16px 6px 12px', textAlign: 'center' }}>時間内</label>
                <label style={{ width: '8rem', margin: '12px 16px 6px 12px', textAlign: 'center' }}>時間外</label>
                <label style={{ width: '8rem', margin: '12px 16px 6px 12px', textAlign: 'center' }}>深夜</label>
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <label>通常</label>
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='normalPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='overPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='nightPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <label>休日</label>
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='holNormalPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='holOverPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='holNightPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <label>法休</label>
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='lawNormalPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='lawOverPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='lawNightPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root} style={{ display:contractDivision == 1 ? 'block' : 'none'}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>請求情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li>
              <label>請求区分</label>
              <div>
                <SelectGroup
                  name='billingType'
                  width='10rem'
                  margin='0'
                  options={wagesTypeOptoins}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>請求</label>
              <div>
                <label style={{ width: '2rem' }}></label>
                <label style={{ width: '8rem', margin: '12px 16px 6px 12px', textAlign: 'center' }}>時間内</label>
                <label style={{ width: '8rem', margin: '12px 16px 6px 12px', textAlign: 'center' }}>時間外</label>
                <label style={{ width: '8rem', margin: '12px 16px 6px 12px', textAlign: 'center' }}>深夜</label>
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <label>通常</label>
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='normalBillingPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='overBillingPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='nightBillingPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <label>休日</label>
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='holNormalBillingPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='holOverBillingPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='holNightBillingPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label></label>
              <div>
                <label>法休</label>
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='lawNormalBillingPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='lawOverBillingPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='8rem'
                  margin='12px 16px 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='lawNightBillingPrice'
                  register={register}
                  rule={{
                    pattern:  regex.halfNumericDot ,
                    maxLength: regex.maxLength(6)
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>支給控除情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li>
              <label>交通費</label>
              <div>
                <label>日額</label>
                <InputGroup
                  width='8rem'
                  margin='12px 0 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='dailyTransportExpenses'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(6),
                    pattern: regex.halfNumericDot
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
                <label style={{ marginLeft: '12px' }}>月額</label>
                <InputGroup
                  width='8rem'
                  margin='12px 0 12px 12px'
                  addStyle='text-align: right; padding-right: 1rem'
                  placeholder='1000'
                  name='monthlyTransportExpenses'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(6),
                    pattern: regex.halfNumericDot
                  }}
                  errors={errors}
                  onBlur={onBlurMonery}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>その他</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li>
              <label>備考</label>
              <div>
                <InputGroup
                  width='60vw'
                  margin='12px 0 12px 0'
                  placeholder=''
                  name='remark'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(255)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <StaffModal
        open={open}
        onCloseFunc={handleClose}
        staffTypeOptions={staffTypeOptions}
        allocationValue={setValue}
        staffIds={staffIds}
        setStaffIds={setStaffIds}
      >
      </StaffModal>
      <OrderModal
        open={orderOpen}
        onCloseFunc={handleOrderClose}
        contractDivisions={contractDivisions}
        allocationValue={setValue}
        allocationContractDivision={setContractDivision}
      >
      </OrderModal>
      <HiddenInput name='updatedId' register={register} />
      <HiddenInput name='updatedAt' register={register} />
      <HiddenInput name='staffIds' register={register} />
    </MasterDetailWrap>
  )
}

AllocationMasterForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  allocationDetail: PropTypes.object,
  watch: PropTypes.func,
  canWrite: PropTypes.bool,
  isDeleted: PropTypes.bool,
  actionType: PropTypes.string,
  isView: PropTypes.bool
}

export default AllocationMasterForm
