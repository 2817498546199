import styled from 'styled-components'

const MasterBaseFormWrap = styled.form`
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin-bottom:5vh;
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: 5% top;
`

export default MasterBaseFormWrap
