import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { formatHour} from '../../../Common/Utilties/LaborUtility'
import Button from '../../../Common/Atoms/NormalButton'

import Table from './RestTable'
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  display: flex;
  padding: 1vw;
`
const FormWrap = styled.form`
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
`
const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

export const IconImg = styled.img`
  align-items: left;
  width: 20px;
  height: 20px;
`
export const NotIconImg = styled.div`
  align-items: left;
  width: 20px;
  height: 20px;
`

const RestDialog = ({ open,onCloseFunc,workInfo,elementId,setRest,canWrite}) => {
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();

  const alert = useAlert()
  const dispatch = useDispatch()
  const [restList,setRestList]=useState(new Array())
  useEffect(() => {
    if(workInfo)
    {
      if(canWrite) setRestList(JSON.parse(JSON.stringify(workInfo.restList)))

      console.log(workInfo)
    }
  }, [workInfo])

  const onDelete=(num)=>{
    restList.map((restData)=>{
        if(restData.restNum == num) {
            if(restData.isNew){
                restData.isDel = true
                restData.isDiff = false
            }else{
                restData.isDel = true
                restData.isDiff = true
            }
        }
    })
    setRestList(JSON.parse(JSON.stringify(restList)))
  }

  const onClose=()=>{
    if(canWrite){

      var sum = 0
      restList.map((restData)=>{
        if(!restData.isDel){
          sum += restData.restTotalDt
        }
        if(restData.isDiff)
        {
          workInfo.isDiff=true
        }
      })
      //workInfo.restTotalDt = sum
   
      setRest('shiftRestTotalDt_' + elementId, formatHour(sum))
      
      onCloseFunc(restList)
    }else{
      onCloseFunc()
    }
    
    
  }
  const onAdd=()=>{
    var [restNum,isAdd] = lastNum()
    var list = {
        restEndDt: null,
        restStartDt: null,
        restTotalDt: null,
        updatedAt: null,
        updatedId: null,
        baseDt: workInfo.baseDt,
        isDiff: false,
        orderId: workInfo.orderId,
        staffId: workInfo.staffId,
    }
    var tmp = JSON.parse(JSON.stringify(restList))
    if(isAdd){
      list.restNum=restNum
      list.isNew = true
      tmp.push(list)
    }else{
      var tmpData = tmp[restNum]
      list.isNew = tmpData.isNew
      list.restNum = tmpData.restNum
      tmp[restNum] = list
    }
    
    setRestList(tmp)
    console.log(tmp)
  }
  const lastNum=()=>{
    var maxNum = -1
    var delNum = -1
    var isAdd = true
    restList.map((restData,index)=>{
        if(restData.isDel && delNum == -1){
          delNum = index
          isAdd = false
        }
        if(restData.restNum>maxNum && restData.restNum != null) maxNum=restData.restNum
    })
    if(delNum != -1) return [delNum,isAdd]
    else return [parseInt(maxNum)+1,isAdd]
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='休憩'
      aria-describedby='休憩ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap>
    <div className={classes.paper}>
      <ContentWrap>
      <TableWrap>
      <Table restList={restList}
        register={register}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        reset={reset}
        errors={errors}
        workInfo={workInfo}
        onDelete={(num)=>onDelete(num)}
        canWrite={canWrite}/>
      </TableWrap>
      </ContentWrap>
      <ButtonsWrap>
        {canWrite && <Button name='追加' type='button' padding='0px' onClick={onAdd} />}
        <Button name='閉じる' type='button' padding='0px' onClick={onClose} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

RestDialog.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedAttendanceFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    iconName: PropTypes.string,
    text: PropTypes.string,
    dailyWorkInfoList: PropTypes.array
}

export default RestDialog