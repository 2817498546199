import React, { useEffect, useState, useCallback,PureComponent } from 'react'
import PropTypes from 'prop-types'
//import ReTooltip from '@material-ui/core/Tooltip'
import {XAxis,YAxis, Tooltip, Legend, Line, Bar,ComposedChart } from "recharts"

import {DashboardBaseFormRow,DashboardTitle} from '../../../Wrap/DashboardBaseWrap'

const renderColorfulLegendText = (value) => {
  return <span style={{ color:'black'}}>{value}</span>
}

const AttendanceBarGraph= ({ attendanceList, title, axisTitle }) => {
                
  return (
    <DashboardBaseFormRow>
    <div>
    <DashboardTitle>{title}</DashboardTitle>
    <ComposedChart 
      width={930}
      height={350}
    data={attendanceList}
    margin={{
        top: 10, right: 30, left: 0, bottom: 5,
    }}>
    <XAxis xAxisId={1} dataKey="dateValue"/>
    <YAxis yAxisId={1}  domain={[0, dataMax => (dataMax+(5 - dataMax%5))]} tickCount={6}/>
    <YAxis yAxisId={2}  domain={[0, dataMax => (dataMax+(5 - dataMax%5))]} tickCount={6} orientation="right"/>
    <Tooltip/>
    <Legend formatter={renderColorfulLegendText}/>
    <Bar name="シフト人数" xAxisId={1} yAxisId={1} dataKey={axisTitle[2]} fill="blue" />
    <Bar name="勤怠人数" xAxisId={1} yAxisId={1} dataKey={axisTitle[3]} fill="red" />
    <Line name="シフト時間" xAxisId={1} yAxisId={2} dataKey={axisTitle[0]} stroke="orange" />
    <Line name="勤怠時間" xAxisId={1} yAxisId={2} dataKey={axisTitle[1]} stroke="green" />
    </ComposedChart >
    </div>
    </DashboardBaseFormRow>
  )
}

AttendanceBarGraph.propTypes = {
  attendanceList: PropTypes.array,
  title: PropTypes.string,
}

export default AttendanceBarGraph