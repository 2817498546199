import React,{useState} from 'react'
import PropTypes from 'prop-types'

import {DashboardBaseFormRow} from '../../../Wrap/DashboardBaseWrap'
import BarGraph from './BarGraph'
import PersentGraph from './PersentGraph'
const GraphBody = ({ dateDataList }) => {

  return (
    <DashboardBaseFormRow>
        <div>
        <PersentGraph title={'男女比'} staffList={dateDataList.staffCount}/>
        <BarGraph title={'勤続年数構成'} 
          staffList={dateDataList.staffWorkTime}/>
        </div>
        <div>
        <BarGraph title={'年齢構成'} 
          staffList={dateDataList.staffAge} />
        <BarGraph title={'スタッフ区分'} 
          staffList={dateDataList.staffDivision}/>
        </div>
    </DashboardBaseFormRow>
  )
}

GraphBody.propTypes = {
  dateDataList: PropTypes.array,
}

export default GraphBody
