import { ZoomOut } from "@material-ui/icons"


export function convertRemToPx(rem) {
  let fontSize = getComputedStyle(document.documentElement).fontSize
  return rem * parseFloat(fontSize)
}

export function convertPxToRem(px) {
  let fontSize = getComputedStyle(document.documentElement).fontSize
  return px / parseFloat(fontSize)
}

export function measurePxText(text, fontSize) {
  if(!fontSize) {
    fontSize = '10px'
  }

  // spanを生成.
  let span = document.createElement('span')

  // 現在の表示要素に影響しないように、画面外に飛ばしておく.
  span.style.position = 'absolute'
  span.style.top = '-1000px'
  span.style.left = '-1000px'

  // 折り返しはさせない.
  span.style.whiteSpace = 'nowrap'

  // 計測したい文字を設定する.
  span.innerHTML = text

  // 必要に応じてスタイルを適用する.
  span.style.fontSize = fontSize;
  //span.style.letterSpacing = '2em'

  // DOMに追加する（追加することで、ブラウザで領域が計算されます）
  document.body.appendChild(span)

  // 横幅を取得します.
  let width = span.clientWidth

  // 終わったらDOMから削除します.
  span.parentElement.removeChild(span)

  return width
}

/**
 * 郵便番号をハイフン付きの8桁の文字列にする.
 * @param {string} zipCode
 * @return {string} 郵便番号をハイフン付きの8桁の文字列
 */
export function formatZipCode(zipCode) {
  if (zipCode.length >= 7 && !zipCode.match('-')) {
    zipCode = zipCode.substring(0, 3) + '-' + zipCode.substring(3)
  }

  if (zipCode.length > 8) {
    zipCode = zipCode.substring(0, 8)
  }

  return zipCode
}