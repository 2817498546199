const FUNC = {
    HOME: '2010101001', // ホーム
    SHIFT: '2020101001', // シフト管理
    ATTENDANCE: '2020201001', // 勤怠管理
    STAFF: '2040101001', // スタッフマスタ基本情報
    STAFF_GRANT: '2040101003', // スタッフマスタ権限
    COMPANY: '2040201001', // 会社マスタ
    CUSTOMER: '2040301001', // 顧客マスタ
    ORDER: '2040401001', // 受注管理
    ALLOCATION: '2040501001', // 引当管理
    GRANT: '2040601001', // 権限管理
    CALENDAR: '2040901001', // カレンダー管理
    SALARIED:'2020301001', //有給休暇管理
    STAFF_BIOETRIC:'2040101002',//認証管理
    DASHBOARD:'2050101001',//ダッシュボード
    BULK_EDIT:'2020102001',//一括編集
    DIFF_ATTENDANCE:'2020203001',//勤怠差異
    SHIFT_GANTCHART:'2020103001',//シフトガントチャート
    OVER_TIME_WORK:'2020401001',//時間外労働管理
    ALIVE_MONITORING:'2040701001',//死活監視
    EMPLOYMENT_CONTRACT:'2020501001',//雇用契約管理
  }
  
  export default FUNC
  