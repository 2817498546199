import React, { useEffect, useState, useCallback,PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReTooltip from '@material-ui/core/Tooltip'
import {LineChart, CartesianGrid, XAxis,LabelList ,YAxis, Tooltip, Legend, Line,BarChart, Bar,ComposedChart } from "recharts"

import {DashboardBaseFormRow,DashboardTitle} from '../../../Wrap/DashboardBaseWrap'

const renderColorfulLegendText = (value) => {
  return <span style={{ color:'black'}}>{value}</span>
}

const SalariedBarGraph= ({ salariedList, title }) => {
        
    return (
      <DashboardBaseFormRow>
      <div>
      <DashboardTitle>{title}</DashboardTitle>
      <ComposedChart 
      width={930}
      height={350}
      data={salariedList}
      margin={{
          top: 10, right: 30, left: 0, bottom: 5,
      }}
      layout="vertical"> 
      <YAxis yAxisId={1} dataKey="dateValue" type="category"  width={100} />
      <XAxis xAxisId={1}  domain={[0, dataMax => (dataMax+(5 - dataMax%5))]} tickCount={6} type="number"/>
      <Tooltip/>
      <Legend formatter={renderColorfulLegendText}/>
      <Bar name="取得日数別人数" xAxisId={1} yAxisId={1} dataKey="salariedCount" fill="#649cdf" />
      </ComposedChart >
      </div>
      </DashboardBaseFormRow>
      )
    }

SalariedBarGraph.propTypes = {
  salariedList: PropTypes.array,
  title: PropTypes.string,
}

export default SalariedBarGraph