import React from 'react'
import PropTypes from 'prop-types'

import { ColumnStaffTH, ColumnOccupationTH, ColumnDateTH, ColumnWorkTypeTH, ColumnTimeTH, ColumnRemarkTH, ColumnButtonsTH} from '../../Wrap/DailyTableWrap'

const DailyTableHeader = ({}) => {

  return (
    <thead>
      <tr>
        <ColumnStaffTH rowSpan={2}>スタッフ名</ColumnStaffTH>
        <ColumnOccupationTH rowSpan={2}>区分</ColumnOccupationTH>
        <ColumnRemarkTH colSpan={8}>雇用契約</ColumnRemarkTH>
        <ColumnButtonsTH rowSpan={2}></ColumnButtonsTH>
      </tr>
      <tr>
        <ColumnWorkTypeTH>労働総時間</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>作業開始</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>作業終了</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>深夜開始</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>深夜終了</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>みなし</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>終業時刻前</ColumnWorkTypeTH>
        <ColumnWorkTypeTH>終業時刻後</ColumnWorkTypeTH>
      </tr>
    </thead>
  )
}

DailyTableHeader.propTypes = {
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
}

export default DailyTableHeader
