import React from 'react'
import PropTypes from 'prop-types'

import PunchTableWrap from '../../Wrap/PunchTableWrap'

import PunchTableHeader from './PunchTableHeader'
import PunchTableBody from './PunchTableBody'

const PunchTable = ({
    register, getValues, setValue, watch, reset, errors,
    defaultUrl, punchDailyDataList, selectedIndex, selectFunction
  }) => {

  return (
    <PunchTableWrap visible={punchDailyDataList.length ? true : false}>
      <PunchTableHeader
      >
      </PunchTableHeader>
      <PunchTableBody
        register={register}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        reset={reset}
        errors={errors}

        defaultUrl={defaultUrl}
        punchDailyDataList={punchDailyDataList}
      >
      </PunchTableBody>
    </PunchTableWrap>
  )
}

PunchTable.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  punchDataList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func
}

export default PunchTable
