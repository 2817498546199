import React,{useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import TableWrap from '../../Wrap/BulkEditTableWrap'
import TableHeader from './BulkEditTableHeader'
import TableBody from './BulkEditTableBody'

import BulkEditDialog from '../BulkEditDialog/BulkEditDialog'

const DailyTable = ({
    register, getValues, setValue, watch, reset, errors,
    defaultUrl, workInfoList,workDivisions,dateDataList,
    canOpenDialog,handleClose,setStaffInfoList,updatedShiftFunc,Reserch
  }) => {

  return (
    <>
    <TableWrap  visible={workInfoList.length ?true : false}>
      <TableHeader
      >
      </TableHeader>
      <TableBody
        register={register}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        reset={reset}
        errors={errors}
        workDivisions={workDivisions}
        dateDataList={dateDataList}
        defaultUrl={defaultUrl}
        workInfoList={workInfoList}
        updatedShiftFunc={Reserch}
        Reserch={Reserch}
      >
      </TableBody>
    </TableWrap>
    <BulkEditDialog
      open={canOpenDialog}
      onCloseFunc={handleClose}
      staffInfoList={workInfoList}
      setStaffInfoList={setStaffInfoList}
    ></BulkEditDialog>
    </>
  )
}

DailyTable.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func
}

export default DailyTable
