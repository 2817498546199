import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useHistory } from "react-router-dom"
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import Tooltip from '@material-ui/core/Tooltip'

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as CommonUtility from '../../Common/Utilties/CommonUtility'
import MediaNotice from './MediaNotice'

import {ListUl, ListLi} from '../Wrap/TopPanelWrap'

import InfoIcon from '../../../image/infomation-icon.png'
import CalendarIcon from '../../../image/calendar.png'

//import NoticeCalendarModal from '../Molcules/NoticeCalendarModal/NoticeCalendarModal'
//import NoticeModal from '../Molcules/NoticeModal/NoticeModal'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  border-radius: 10px;
  border: none;

  color: #000;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 48px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`

const Icon = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
`

const Title = styled.span`
  font-size: 24px;
  font-weight: bold;
`

const DateText = styled.span`
  font-size: 20px;
  font-weight: bold;
`

const Content = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 690px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

const FooterContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  height: 48px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
`

//　お知らせカード
const InfoCard = (props) => {
  const {
    grants,
  } = props
  const dispatch = useDispatch();
  //const alert = useAlert();
  const interval1Ref = useRef(null);
  const interval2Ref = useRef(null);
  const [dateInfo, setDateInfo] = useState(null);
  const [week, setWeek] = useState('');
  const [dateText, setDateText] = useState('');
  const [timeText, setTimeText] = useState('');
  const [noticeData, setNoticeData] = useState(null);
  const [noticeList, setNoticeList] = useState([]);
  //const [openDialog1,setOpenDialog1] = useState(false);
  //const [openDialog2,setOpenDialog2] = useState(false);

  // 日付情報の取得
  const apiGetDateInfo = async (isLoadSplinner) => {
    /*
    console.log('cal apiGetDateInfo');

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = '/api/masterManagement/calendar' + '/range';
    const data = {
      startDt: CommonUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      endDt: CommonUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('日付情報の取得に失敗しました');
    } else {
      if (result.data === null) {
        return;
      }
      //console.log(result.data);
      setDateInfo(result.data.responseList[0]);

      setWeek(result.data.responseList[0].week);

      setDateText(CommonUtility.getStringFromDate(new Date(), 'MM/DD'));
      setTimeText(CommonUtility.getStringFromDate(new Date(), 'hh:mm'));
    }
    */
  }

  // お知らせリストの取得
  const apiGetNoticeList = async (isLoadSplinner) => {
    console.log('cal apiGetNoticeList');
    
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = '/api/noticeManagement' + '/getNotices';
    const data = {
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('お知らせリストの取得に失敗しました');
    } else {
      console.log(result.data);
      if (!result.data)
        return;
      var list = result.data.sort((a, b) => new Date(b.noticeAt) - new Date(a.noticeAt));
      setNoticeList(list.map(item => {
        return {
          noticeId: item.noticeId,
          noticeType: item.noticeType,
          title: item.title,
          text: item.noticeContent,
          noticeAt: new Date(item.noticeAt),
          jumpURL: item.jumpURL,
        }
      }))
    }
  }

  // お知らせ情報の変更イベント
  const onChangeNotice = async () => {
    apiGetNoticeList(false);
  }

  // お知らせ情報の登録イベント
  const onClickCreateNotice = () => {
    setNoticeData({
      noticeId: '',
      noticeType: 0,
      text: '',
      noticeAt: CommonUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    });

    //setOpenDialog1(true);
  }

  // 周期初期のクリア
  const onClearInerval = async () => {
    //console.log('call onClearInerval');
    if (interval1Ref.current !== null) {
      clearInterval(interval1Ref.current);
    }
    
    if (interval2Ref.current !== null) {
      clearInterval(interval2Ref.current);
    }

    interval1Ref.current = null;
    interval2Ref.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    //apiGetDateInfo(false);
    apiGetNoticeList(false);
    /*
    // 日時更新
    interval1Ref.current = setInterval(() => {
      apiGetDateInfo(false);
    }, 5000); // 5秒間隔で日時情報を取得
    */
    // お知らせ更新
    interval2Ref.current = setInterval(() => {
      apiGetNoticeList(false);
    }, 60000); // 60秒間隔でお知らせ情報を取得

    return () => {
      onClearInerval();
    };
  }, [])

  //if (!grants) return null
  return (
    <CardWrap >
      <HeaderContent>
        <Icon src={InfoIcon} />
        <Title style={{marginLeft:'0.5rem', marginTop:'0.5rem'}}>お知らせ</Title>
        {dateInfo &&
        <>
          <div style={{textAlign:'right', marginLeft:'auto', marginRight:'1.0rem', marginTop:'0.5rem'}}>
            <DateText>{dateText+' ('}<span style={{color:CommonUtility.getDateColor(dateInfo)}}>{week}</span>{') '+timeText}</DateText>
          </div>
          <div style={{textAlign:'right', marginRight:'1.5rem' }}>
            <Icon style={{width:'20px', height:'20px', cursor:'pointer'}} src={CalendarIcon} onClick={onClickCreateNotice} />
          </div>
        </>
        }
      </HeaderContent>
      <Content>
        <ListUl width={'95%'} height={'610px'} style={{marginLeft:'1rem', marginTop:'1rem'}}>
          {noticeList && noticeList.map(item => (
            <ListLi key={'info_'+item.noticeId}>
              <MediaNotice grants={grants} noticeData={item} selected={false} onChangeNotice={onChangeNotice} />
            </ListLi>
          ))}
        </ListUl>
      </Content>
      <FooterContent>
      </FooterContent>
    </CardWrap>
  )
}

InfoCard.propTypes = {
  grants: PropTypes.object, // 権限関連
}

export default InfoCard
