import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

import SwitchButton from '../../../Common/Atoms/SwitchButton'
import { InputGroup, SelectGroup, HiddenInput } from '../../../Common/CommonInputGroup'
import { GetApi } from '../../../Common/ApiAxios'
import ErrorHandling from '../../../Common/ErrorHandling'

import { ColumnDateTD, ColumnStatusTD, ColumnButtonsTD } from '../../Wrap/PunchTableWrap'

const PunchTableRow = ({
    register, getValues, setValue, watch, reset, errors, 
    defaultUrl, punchData
  }) => {

  const alert = useAlert()

  const punchStateColor = (state) => {
    switch(state) {
      case '01':
        return '#000BFF'
      case '02':
        return '#FF6347'
      case '03':
        return '#F4A460'
      case '04':
        return '#A9A9A9'
    }
  
    return ''
  }

  const convertPunchState = (state) => {
    switch(state) {
      case '01':
        return '出勤'
      case '02':
        return '退勤'
      case '03':
        return '休始'
      case '04':
        return '休終'
    }
  
    return ''
  }

  const calcTime = (baseDateTime, datetime) => {
    var date = new Date(datetime);
    var dateStr =
    date.getUTCFullYear() + "/" +
    ("00" + (date.getUTCMonth() + 1)).slice(-2) + "/" +
    ("00" + date.getUTCDate()).slice(-2) + 
    " " +
    ("00" + date.getUTCHours()).slice(-2) + ":" +
    ("00" + date.getUTCMinutes()).slice(-2);
    return dateStr;
  }

  // 初期化メソッド
  useEffect(() => {
    // 初回描画時のみ実行
    // eslint-disable-next-line
  }, [])
  

  return (
    <tr>
      <ColumnStatusTD style={{ backgroundColor: punchStateColor(punchData.biometricState) }}>{convertPunchState(punchData.biometricState)}</ColumnStatusTD>
      <ColumnDateTD>{calcTime(punchData.attendanceDt, punchData.biometricDt)}</ColumnDateTD>
      <ColumnButtonsTD><SwitchButton switchName={'switch_'+punchData.biometricId} flg={punchData.cooperationFlag}/></ColumnButtonsTD>
    </tr>
  )
}

PunchTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  punchData: PropTypes.object
}

export default PunchTableRow
