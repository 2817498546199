import React,{useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const ColumnTD = styled.td`
  vertical-align:middle;
  padding: 0.25rem;
  height: 2rem;
`
const DashboardRow = ({
    ColumnData
  }) => {
    const [checked,setChecked] = useState(ColumnData.checked)
    const createElementCheck = (data) => {
      return (          
        <input
          type="checkbox"
          disabled={false}
          checked={checked}
          onChange={()=>onClick(data)}
        />
      )
    }
    const onClick = (data) => {
      data.checked=!data.checked
      setChecked(data.checked)
    }
    return (
      <tr>
      <ColumnTD style={{ width: '2rem' }}> {createElementCheck(ColumnData)}</ColumnTD>
      <ColumnTD style={{ width: '13rem' }}> {ColumnData.comment}</ColumnTD>
      </tr>
    )
}

DashboardRow.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func
}

export default DashboardRow