import PropTypes from 'prop-types'
//import { useCallback, useState, useEffect } from 'react'
import {dateFormat,diff48Time} from './LaborUtility'

//csvファイルエクスポート処理
export function onExportCsvFile (exportData,name) {

    {
      //BOM情報付与(utf-8 文字コードでcsvが開く情報付与)
      let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([bom,exportData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download =  name+".csv"; 
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
}
onExportCsvFile.propTypes = {
    exportData: PropTypes.string, // 出力データ
    name: PropTypes.string, //ファイル名
}

 /**
 * スタッフCSV出力用データ追加
 * @param {InfoList} workInfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
 export function makeStaffCSV(InfoList,header) 
 {
   let Header = header
   let result = '';
   InfoList.map((Info)=>{
     var lineResult =   
         Info.staffId + ','
       + Info.staffName + ','
       + Info.staffKana + ','
       + Info.staffDivision + ','
       + Info.companyId + ','
       + Info.departmentId + ','
       + Info.loginFlag + ','

       + Info.enterDt + ','
       + Info.retireDt + ','

       + Info.sex + ','
       + Info.birthDt + ','
       + Info.zipCode + ','
       + Info.address1 + ','
       + Info.address2 + ','
       + Info.address3 + ','
       + Info.address4 + ','
       
       + Info.phoneNum + ','
       + Info.mobileNum + ','
       + Info.pcMailAddress + ','
       + Info.mobileMailAddress + ','
       + Info.cooperationId + ','

       + diff48Time('1900-01-01',Info.hope.hopeStartTime) + ','
       + diff48Time('1900-01-01',Info.hope.hopeEndTime) + ','
       + Info.hope.overtimePropriety + ','
       + Info.hope.shiftMon + ','
       + Info.hope.shiftTue + ','
       + Info.hope.shiftWed + ','
       + Info.hope.shiftThu + ','
       + Info.hope.shiftFri + ','
       + Info.hope.shiftSat + ','
       + Info.hope.shiftSun + ','
       + Info.hope.shiftHol + ','
       + dateFormat(Info.hope.updatedAt) + ','

       + Info.grant.grantNameId + ','
       + dateFormat(Info.grant.updatedAt);
 
     lineResult = lineResult.replaceAll('null','')
     result += lineResult
     
     result += '\r\n'
   })
   result = Header + '\r\n' + result
   return result
 }
 /**
 * スタッフCSV出力用データ追加
 * @param {InfoList} workInfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
 export function makeStaffCSVError(InfoList,header) 
 {
   let Header = header+",Error"
   let result = '';
   InfoList.map((Info)=>{
     var lineResult =   
         Info.staffId + ','
       + Info.staffName + ','
       + Info.staffKana + ','
       + Info.staffDivision + ','
       + Info.companyId + ','
       + Info.departmentId + ','
       + Info.loginFlag + ','

       + Info.enterDt + ','
       + Info.retireDt + ','

       + Info.sex + ','
       + Info.birthDt + ','
       + Info.zipCode + ','
       + Info.address1 + ','
       + Info.address2 + ','
       + Info.address3 + ','
       + Info.address4 + ','
       
       + Info.phoneNum + ','
       + Info.mobileNum + ','
       + Info.pcMailAddress + ','
       + Info.mobileMailAddress + ','
       + Info.cooperationId + ','

       + (Info.hope.hopeStartTime) + ','
       + (Info.hope.hopeEndTime) + ','
       + Info.hope.overtimePropriety + ','
       + Info.hope.shiftMon + ','
       + Info.hope.shiftTue + ','
       + Info.hope.shiftWed + ','
       + Info.hope.shiftThu + ','
       + Info.hope.shiftFri + ','
       + Info.hope.shiftSat + ','
       + Info.hope.shiftSun + ','
       + Info.hope.shiftHol + ','
       + (Info.hope.updatedAt) + ','

       + Info.grant.grantNameId + ','
       + (Info.grant.updatedAt) + ','
       + Info.error;
     lineResult = lineResult.replaceAll('null','')
     result += lineResult
     
     result += '\r\n'
   })
   result = Header + '\r\n' + result
   return result
 }

  /**
 * カレンダーマスタCSV出力用データ追加
 * @param {InfoList} workInfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
  export function makeCalenderCSV(InfoList,header) 
  {
    let Header = header
    let result = '';
    InfoList.map((Info)=>{
      var lineResult =   
          Info.dateValue + ','
        + Info.week + ','
        + Info.publicHoliday + ','
        + Info.note + ','
  
      lineResult = lineResult.replaceAll('null','')
      result += lineResult
      
      result += '\r\n'
    })
    result = Header + '\r\n' + result
    return result
  }

  /**
 * 受注管理CSV出力用データ追加
 * @param {dailyList} InfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
 export function makeOrderCSV(InfoList,header) 
 {
   let Header = header
   let result = '';
   InfoList.map((Info)=>{
     var lineResult =   
         Info.orderId + ','
       + Info.contractDivision + ','
       + Info.contractDivisionName + ','

       + Info.companyId + ','
       + Info.companyName + ','     
       + Info.comDepartmentId + ','
       + Info.comDepartmentName + ','

       + Info.customerId + ','
       + Info.customerName + ','     
       + Info.cusDepartmentId + ','
       + Info.cusDepartmentName + ','

       + Info.orderDt + ','

       + Info.agreementStartDt + ','
       + Info.agreementEndDt + ','
       + Info.occupation + ','
       + Info.occupationDetail1 + ','
       + Info.occupationDetail2 + ','
       + diff48Time('1900-01-01',Info.workStartDt) + ','
       + diff48Time('1900-01-01',Info.workEndDt) + ','
       + diff48Time('1900-01-01',Info.restStart1Dt) + ','
       + diff48Time('1900-01-01',Info.restEnd1Dt) + ','
       + diff48Time('1900-01-01',Info.restStart2Dt) + ','
       + diff48Time('1900-01-01',Info.restEnd2Dt) + ','
       + diff48Time('1900-01-01',Info.restStart3Dt) + ','
       + diff48Time('1900-01-01',Info.restEnd3Dt) + ','
       + Info.wagesType + ','
       + Info.wagesTypeName + ','
       + Info.normalPrice + ','
       + Info.overPrice + ','
       + Info.nightPrice + ','
       + Info.holNormalPrice + ','
       + Info.holOverPrice + ','
       + Info.holNightPrice + ','
       + Info.lawNormalPrice + ','
       + Info.lawOverPrice + ','
       + Info.lawNightPrice + ','

       + Info.billingType + ','
       + Info.billingTypeName + ','
       + Info.normalBillingPrice + ','
       + Info.overBillingPrice + ','
       + Info.nightBillingPrice + ','
       + Info.holNormalBillingPrice + ','
       + Info.holOverBillingPrice + ','
       + Info.holNightBillingPrice + ','
       + Info.lawNormalBillingPrice + ','
       + Info.lawOverBillingPrice + ','
       + Info.lawNightBillingPrice + ','

       + Info.departmentCalcDivision + ','
       + Info.rateDivision + ','
       + Info.remark 
 
     lineResult = lineResult.replaceAll('null','')
     result += lineResult
     
     result += '\r\n'
   })
   result = Header + '\r\n' + result
   return result
 }
  /**
 * 受注管理CSVエラー出力用データ追加
 * @param {dailyList} InfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
  export function makeOrderErrorCSV(InfoList,header) 
  {
    let Header = header+",Error"
    let result = '';
    InfoList.map((Info)=>{
      var lineResult =   
          Info.orderId + ','
        + Info.contractDivision + ','
        + Info.contractDivisionName + ','
 
        + Info.companyId + ','
        + Info.companyName + ','     
        + Info.comDepartmentId + ','
        + Info.comDepartmentName + ','
 
        + Info.customerId + ','
        + Info.customerName + ','     
        + Info.cusDepartmentId + ','
        + Info.cusDepartmentName + ','
 
        + Info.orderDt + ','
 
        + Info.agreementStartDt + ','
        + Info.agreementEndDt + ','
        + Info.occupation + ','
        + Info.occupationDetail1 + ','
        + Info.occupationDetail2 + ','
        + Info.workStartDt + ','
        + Info.workEndDt + ','
        + Info.restStart1Dt + ','
        + Info.restEnd1Dt + ','
        + Info.restStart2Dt + ','
        + Info.restEnd2Dt + ','
        + Info.restStart3Dt + ','
        + Info.restEnd3Dt + ','
        + Info.wagesType + ','
        + Info.wagesTypeName + ','
        + Info.normalPrice + ','
        + Info.overPrice + ','
        + Info.nightPrice + ','
        + Info.holNormalPrice + ','
        + Info.holOverPrice + ','
        + Info.holNightPrice + ','
        + Info.lawNormalPrice + ','
        + Info.lawOverPrice + ','
        + Info.lawNightPrice + ','
 
        + Info.billingType + ','
        + Info.billingTypeName + ','
        + Info.normalBillingPrice + ','
        + Info.overBillingPrice + ','
        + Info.nightBillingPrice + ','
        + Info.holNormalBillingPrice + ','
        + Info.holOverBillingPrice + ','
        + Info.holNightBillingPrice + ','
        + Info.lawNormalBillingPrice + ','
        + Info.lawOverBillingPrice + ','
        + Info.lawNightBillingPrice + ','
 
        + Info.departmentCalcDivision + ','
        + Info.rateDivision + ','
        + Info.remark + ','
        + Info.error;
      lineResult = lineResult.replaceAll('null','')
      result += lineResult
      
      result += '\r\n'
    })
    result = Header + '\r\n' + result
    return result
  }

 /**
 * 会社マスタCSV出力用データ追加
 * @param {dailyList} InfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
   export function makeCompanyCSV(InfoList,header) 
   {
     let Header = header
     let result = '';
     InfoList.map((Info)=>{
       var lineResult =   
           Info.companyId + ','
         + Info.companyName + ','
         + Info.companyKana + ','
  
         + Info.zipCode + ','
         + Info.address1 + ','
         + Info.address2 + ','
         + Info.address3 + ','
         + Info.address4 + ','

         + Info.phoneNum + ','
         + Info.faxNum + ','
         + Info.webUrl + ','
         + Info.cooperationId + ','
         + Info.departmentFlag 

       lineResult = lineResult.replaceAll('null','')
       result += lineResult
       
       result += '\r\n'
     })
     result = Header + '\r\n' + result
     return result
   }

  /**
 * 会社部門CSV出力用データ追加
 * @param {dailyList} InfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
  export function makeComDepartmentCSV(InfoList,header) 
  {
    let Header = header
    let result = '';
    InfoList.map((Info)=>{
      var lineResult =   
        Info.comDepartmentId + ','

        + Info.companyId + ','
        + Info.companyName + ','     

        + Info.hierarchyNo + ','   
        + Info.comDepartmentName + ','
        + Info.comDepartmentKana + ','
 
        + Info.zipCode + ','
        + Info.address1 + ','
        + Info.address2 + ','
        + Info.address3 + ','
        + Info.address4 + ','
  
        + Info.phoneNum + ','
        + Info.faxNum + ','
        + Info.cooperationId + ','

        + Info.attendanceFractionUnit + ','
        + Info.attendanceBaseTime + ','
        + Info.payFractionDivision + ','
        + Info.billFractionDivision

      lineResult = lineResult.replaceAll('null','')
      result += lineResult
      
      result += '\r\n'
    })
    result = Header + '\r\n' + result
    return result
  }

   /**
 * 会社マスタCSV出力用データ追加
 * @param {dailyList} InfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
   export function makeCompanyErrorCSV(InfoList,header) 
   {
     let Header = header+",Error"
     let result = '';
     InfoList.map((Info)=>{
       var lineResult =   
           Info.companyId + ','
         + Info.companyName + ','
         + Info.companyKana + ','
  
         + Info.zipCode + ','
         + Info.address1 + ','
         + Info.address2 + ','
         + Info.address3 + ','
         + Info.address4 + ','

         + Info.phoneNum + ','
         + Info.faxNum + ','
         + Info.webUrl + ','
         + Info.cooperationId + ','
         + Info.departmentFlag + ','
         + Info.error

       lineResult = lineResult.replaceAll('null','')
       result += lineResult
       
       result += '\r\n'
     })
     result = Header + '\r\n' + result
     return result
   }

  /**
 * 会社部門CSV出力用データ追加
 * @param {dailyList} InfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
  export function makeComDepartmentErrorCSV(InfoList,header) 
  {
    let Header = header+",Error"
    let result = '';
    InfoList.map((Info)=>{
      var lineResult =   
        Info.comDepartmentId + ','

        + Info.companyId + ','
        + Info.companyName + ','     

        + Info.hierarchyNo + ','   
        + Info.comDepartmentName + ','
        + Info.comDepartmentKana + ','
 
        + Info.zipCode + ','
        + Info.address1 + ','
        + Info.address2 + ','
        + Info.address3 + ','
        + Info.address4 + ','
  
        + Info.phoneNum + ','
        + Info.faxNum + ','
        + Info.cooperationId + ','

        + Info.attendanceFractionUnit + ','
        + Info.attendanceBaseTime + ','
        + Info.payFractionDivision + ','
        + Info.billFractionDivision + ','
        + Info.error

      lineResult = lineResult.replaceAll('null','')
      result += lineResult
      
      result += '\r\n'
    })
    result = Header + '\r\n' + result
    return result
  }

   /**
 * 顧客マスタCSV出力用データ追加
 * @param {dailyList} InfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
   export function makeCustomerCSV(InfoList,header) 
   {
     let Header = header
     let result = '';
     InfoList.map((Info)=>{
       var lineResult =   
           Info.customerId + ','
         + Info.customerName + ','
         + Info.customerKana + ','
  
         + Info.zipCode + ','
         + Info.address1 + ','
         + Info.address2 + ','
         + Info.address3 + ','
         + Info.address4 + ','

         + Info.phoneNum + ','
         + Info.faxNum + ','
         + Info.webUrl + ','
         + Info.cooperationId + ','
         + Info.departmentFlag 

       lineResult = lineResult.replaceAll('null','')
       result += lineResult
       
       result += '\r\n'
     })
     result = Header + '\r\n' + result
     return result
   }

  /**
 * 顧客部門CSV出力用データ追加
 * @param {dailyList} InfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
  export function makeCusDepartmentCSV(InfoList,header) 
  {
    let Header = header
    let result = '';
    InfoList.map((Info)=>{
      var lineResult =   
        Info.cusDepartmentId + ','

        + Info.customerId + ','
        + Info.customerName + ','   

        + Info.hierarchyNo + ','   
        + Info.cusDepartmentName + ','
        + Info.cusDepartmentKana + ','
 
        + Info.zipCode + ','
        + Info.address1 + ','
        + Info.address2 + ','
        + Info.address3 + ','
        + Info.address4 + ','
  
        + Info.phoneNum + ','
        + Info.faxNum + ','
        + Info.cooperationId + ','

        + Info.attendanceFractionUnit + ','
        + Info.attendanceBaseTime + ','
        + Info.payFractionDivision + ','
        + Info.billFractionDivision

      lineResult = lineResult.replaceAll('null','')
      result += lineResult
      
      result += '\r\n'
    })
    result = Header + '\r\n' + result
    return result
  }

     /**
 * 顧客マスタCSV出力用データ追加
 * @param {dailyList} InfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
     export function makeCustomerErrorCSV(InfoList,header) 
     {
       let Header = header+',Error'
       let result = '';
       InfoList.map((Info)=>{
         var lineResult =   
             Info.customerId + ','
           + Info.customerName + ','
           + Info.customerKana + ','
    
           + Info.zipCode + ','
           + Info.address1 + ','
           + Info.address2 + ','
           + Info.address3 + ','
           + Info.address4 + ','
  
           + Info.phoneNum + ','
           + Info.faxNum + ','
           + Info.webUrl + ','
           + Info.cooperationId + ','
           + Info.departmentFlag + ','
           + Info.error
  
         lineResult = lineResult.replaceAll('null','')
         result += lineResult
         
         result += '\r\n'
       })
       result = Header + '\r\n' + result
       return result
     }
  
    /**
   * 顧客部門CSV出力用データ追加
   * @param {dailyList} InfoList DBに登録されているデータ 
   * @param {String} header ヘッダー行
   * @return {csvExport} エクスポートデータ
   */
    export function makeCusDepartmentErrorCSV(InfoList,header) 
    {
      let Header = header+',Error'
      let result = '';
      InfoList.map((Info)=>{
        var lineResult =   
          Info.cusDepartmentId + ','
  
          + Info.customerId + ','
          + Info.customerName + ','   
  
          + Info.hierarchyNo + ','   
          + Info.cusDepartmentName + ','
          + Info.cusDepartmentKana + ','
   
          + Info.zipCode + ','
          + Info.address1 + ','
          + Info.address2 + ','
          + Info.address3 + ','
          + Info.address4 + ','
    
          + Info.phoneNum + ','
          + Info.faxNum + ','
          + Info.cooperationId + ','
  
          + Info.attendanceFractionUnit + ','
          + Info.attendanceBaseTime + ','
          + Info.payFractionDivision + ','
          + Info.billFractionDivision+ ','
          + Info.error
  
        lineResult = lineResult.replaceAll('null','')
        result += lineResult
        
        result += '\r\n'
      })
      result = Header + '\r\n' + result
      return result
    }

/**
 * 引当管理CSV出力用データ追加
 * @param {dailyList} InfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
    export function makeAllocationCSV(InfoList,header) 
    {
      let Header = header
      let result = '';
      InfoList.map((Info)=>{
        var lineResult =   
        Info.orderId + ','
        + Info.occupation + ','
        + Info.staffId + ','
        + Info.staffName + ','

        + Info.employmentStartDt + ','
        + Info.employmentEndDt + ','
        + Info.retireDt + ','
        
        + Info.workType + ','
        + Info.workTypeName + ','

        + diff48Time('1900-01-01',Info.workStartDt) + ','
        + diff48Time('1900-01-01',Info.workEndDt) + ','
        + diff48Time('1900-01-01',Info.restStart1Dt) + ','
        + diff48Time('1900-01-01',Info.restEnd1Dt) + ','
        + diff48Time('1900-01-01',Info.restStart2Dt) + ','
        + diff48Time('1900-01-01',Info.restEnd2Dt) + ','
        + diff48Time('1900-01-01',Info.restStart3Dt) + ','
        + diff48Time('1900-01-01',Info.restEnd3Dt) + ','

        + Info.wagesType + ','
        + Info.wagesTypeName + ','

        + Info.rateDivision + ','

        + Info.normalPrice + ','
        + Info.overPrice + ','
        + Info.nightPrice + ','
        + Info.holNormalPrice + ','
        + Info.holOverPrice + ','
        + Info.holNightPrice + ','
        + Info.lawNormalPrice + ','
        + Info.lawOverPrice + ','
        + Info.lawNightPrice + ','
 
        + Info.billingType + ','
        + Info.billingTypeName + ','
        + Info.normalBillingPrice + ','
        + Info.overBillingPrice + ','
        + Info.nightBillingPrice + ','
        + Info.holNormalBillingPrice + ','
        + Info.holOverBillingPrice + ','
        + Info.holNightBillingPrice + ','
        + Info.lawNormalBillingPrice + ','
        + Info.lawOverBillingPrice + ','
        + Info.lawNightBillingPrice + ','
 
        + Info.dailyTransportExpenses + ','
        + Info.monthlyTransportExpenses + ','
        + Info.remark
  
        lineResult = lineResult.replaceAll('null','')
        result += lineResult
        
        result += '\r\n'
      })
      result = Header + '\r\n' + result
      return result
    }
/**
 * 引当管理CSV出力用データ追加
 * @param {dailyList} InfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
export function makeAllocationErrorCSV(InfoList,header) 
{
  let Header = header+',Error'
  let result = '';
  InfoList.map((Info)=>{
    var lineResult =   
    Info.orderId + ','
    + Info.occupation + ','
    + Info.staffId + ','
    + Info.staffName + ','

    + Info.employmentStartDt + ','
    + Info.employmentEndDt + ','
    + Info.retireDt + ','
    
    + Info.workType + ','
    + Info.workTypeName + ','

    + diff48Time('1900-01-01',Info.workStartDt) + ','
    + diff48Time('1900-01-01',Info.workEndDt) + ','
    + diff48Time('1900-01-01',Info.restStart1Dt) + ','
    + diff48Time('1900-01-01',Info.restEnd1Dt) + ','
    + diff48Time('1900-01-01',Info.restStart2Dt) + ','
    + diff48Time('1900-01-01',Info.restEnd2Dt) + ','
    + diff48Time('1900-01-01',Info.restStart3Dt) + ','
    + diff48Time('1900-01-01',Info.restEnd3Dt) + ','

    + Info.wagesType + ','
    + Info.wagesTypeName + ','

    + Info.rateDivision + ','

    + Info.normalPrice + ','
    + Info.overPrice + ','
    + Info.nightPrice + ','
    + Info.holNormalPrice + ','
    + Info.holOverPrice + ','
    + Info.holNightPrice + ','
    + Info.lawNormalPrice + ','
    + Info.lawOverPrice + ','
    + Info.lawNightPrice + ','

    + Info.billingType + ','
    + Info.billingTypeName + ','
    + Info.normalBillingPrice + ','
    + Info.overBillingPrice + ','
    + Info.nightBillingPrice + ','
    + Info.holNormalBillingPrice + ','
    + Info.holOverBillingPrice + ','
    + Info.holNightBillingPrice + ','
    + Info.lawNormalBillingPrice + ','
    + Info.lawOverBillingPrice + ','
    + Info.lawNightBillingPrice + ','

    + Info.dailyTransportExpenses + ','
    + Info.monthlyTransportExpenses + ','
    + Info.remark + ','
    + Info.Error

    lineResult = lineResult.replaceAll('null','')
    result += lineResult
    
    result += '\r\n'
  })
  result = Header + '\r\n' + result
  return result
}

    /**
 * 有給管理CSV出力用データ追加
 * @param {dailyList} InfoList DBに登録されているデータ 
 * @param {String} header ヘッダー行
 * @return {csvExport} エクスポートデータ
 */
    export function makeSalariedCSV(InfoList,header) 
    {
      
      let Header = header
      let result = '';
      InfoList.map((Info)=>{

        //勤続年数
        let nowDate = new Date()
        let enterDt = new Date(Info.enterDt)
        let d1Month = enterDt.getFullYear() * 12 + enterDt.getMonth()
        let d2Month = nowDate.getFullYear() * 12 + nowDate.getMonth()
        let diffMonth = d2Month - d1Month
        let diffYear = Math.floor((10.0*diffMonth) / 12.0)/10.0
        
        //有給取得率
        let actualDays = Info.actualSalariedDates.length
        let salariedDays = Info.acquiredDays + Info.remaingDaysPreviousYear
        let persent = 0.0
        if(salariedDays <= 0 || actualDays<=0){
          persent = 0.0
        }else{
          persent = Math.floor((1000.0*actualDays) / salariedDays)/10.0
        }
        
        let remaingDay = salariedDays - actualDays
        var lineResult =   
        Info.staffId + ','
        + Info.staffName + ','
        + Info.enterDt + ','
        + diffYear + ','
        + persent.toFixed(2) + ','
        + actualDays + ','
        + remaingDay + ','
        + Info.acquiredDays + ','
        + Info.remaingDaysPreviousYear + ','
        + Info.acquisitionDt + ','
        + Info.limitDt + ','
        + Info.expirationDt
  
        lineResult = lineResult.replaceAll('null','')
        result += lineResult
        
        result += '\r\n'
      })
      result = Header + '\r\n' + result
      return result
    }