import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CellWrap = styled.div`
  ${props => props.addStyle}
  height:3rem;
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
`

const Column = styled.td`
  height:1.5rem;
  vertical-align:middle;
  padding: 0.25rem;
  text-align:right;
  color:${props => props.color || 'black'};
`

const TableCell = ({ shiftDateCount, attendanceDateCount,color }) => {

  return (
    <CellWrap>
        <CellPanel>
            <Column >{shiftDateCount}</Column>
            <Column color={color}>{attendanceDateCount}</Column>
        </CellPanel>
    </CellWrap>
  )
}

TableCell.propTypes = {
    shiftDateCount: PropTypes.string,
    attendanceDateCount: PropTypes.string
}

export default TableCell
