import React,{useState} from 'react'
import PropTypes from 'prop-types'

import {DashboardBaseFormRow} from '../../../Wrap/DashboardBaseWrap'
import SalariedBarGraph from './SalariedBarGraph'
import SalariedGraph from './SalariedGraph'
const GraphBody = ({ dateDataList, targetDt,staffWorkInfoList,limitList, selectedIndex, selectFunction, updatedShiftFunc, defaultUrl }) => {

//visible={staffWorkInfoList.length ? true : false}
  return (
    <DashboardBaseFormRow>
        <div>
        <SalariedGraph title={'月別取得日数'} salariedList={dateDataList.monthlySalaried}></SalariedGraph>
        <SalariedBarGraph title={'当月取得日数別人数'} salariedList={dateDataList.monthlySalariedStaffCount}></SalariedBarGraph>
        </div>
        <div>
        <SalariedGraph title={'当月曜日別取得日数'} salariedList={dateDataList.weeklySalaried}></SalariedGraph>
        <SalariedBarGraph title={'12ヶ月合計取得日数別人数'} salariedList={dateDataList.yearlySalariedStaffCount}></SalariedBarGraph>
        </div>
    </DashboardBaseFormRow>
  )
}

GraphBody.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  limitList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  onSalariedSearch: PropTypes.func,
  defaultUrl: PropTypes.string,
  targetDt: PropTypes.any,
}

export default GraphBody
