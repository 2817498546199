import React,{useState, useEffect, useRef ,useCallback} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector,useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import FUNC from '../../../Common/FunctionEnum'
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { MultipartApi,GetApi } from '../../../Common/ApiAxios'
import ErrorHandling from '../../../Common/ErrorHandling'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import { AttendancePageButtonWrap } from '../../Wrap/PaymentDuductionWrap'
import  { AttendanceCountPanelWrap,AttendancePagingPanelWrap,PagingIconImg,AttendancePagingIconPanelWrap,PagingIconNextImg } from '../../Wrap/AttendancePanelWrap'
import DailyTable from  './PaymentDuductionTable'
import Search from './Search'
import IconPageNextImg from '../../../../image/PageNext.png'
import IconPageBackImg from '../../../../image/PageBack.png'
import IconPageLastImg from '../../../../image/PageLast.png'
import IconPageTopImg from '../../../../image/PageTop.png'
export const defaultUrl = '/api/paymentDuductionManagement/paymentDuduction'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  font-size: 18px;
  display: flex;
  padding: 1vw;
`
const FormWrap = styled.form`
  width: 80vw;
  height: 80vh;
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
  padding: 1vw;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

const PaymentDuductionModal = ({ open, onCloseFunc}) => {
  const { register, handleSubmit, getValues, setError, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();
  const alert = useAlert()
  const dispatch = useDispatch()

  const fromDateRef = useRef()
  const toDateRef = useRef()
  const staffIdRef = useRef()
  const staffNameRef = useRef()
  const [searchOption, setSearchOption] = useState({ shiftDt: '', staffId: '', staffName: '',postType:'',postId:'',departmentId:'' })
  const { loginUserInfo } = useSelector(state => state)
  const [staffWorkInfoList, setStaffWorkInfoList] = useState([])
  const [staffWorkInfoAllList, setStaffWorkInfoAllList] = useState([])
  const [dateDataList, setDateDataList] = useState([])
  const [pagenation, setPagenation] = useState({})
  const [staffCount, setStaffCount] = useState(0)
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.ATTENDANCE)
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [viewFunction,setViewFunction] = useState(false)
  const pageRecord = 13
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()
  
  const [passDivisions, setPassDivisions] = useState([])
  const [transportationPublic, setTransportationPublics] = useState([])
  const [transportationPraivate, setTransportationPraivates] = useState([])
  const [gasCost, setGasCost] = useState(0)
  
  const onSearch = async (createId, page) => {

    if(createId==null)
    {
      var updateFlag = false
      staffWorkInfoAllList.forEach(workInfo => 
      {
        var dailyRow = {}
        dailyRow.privateTransportationExpensesList = new Array()
        workInfo.privateTransportationExpensesList.map((info)=>{
          console.log(info)
          if(info.isDiff){
            dailyRow.privateTransportationExpensesList.push(info)
          }
        })
        dailyRow.publicTransportationExpensesList  = new Array()
        workInfo.publicTransportationExpensesList.map((info)=>{
          if(info.isDiff){
            dailyRow.publicTransportationExpensesList.push(info)
          }
        })
        if(dailyRow.publicTransportationExpensesList.length != 0) updateFlag = true
    
        if(dailyRow.privateTransportationExpensesList.length != 0) updateFlag = true
    
        if(workInfo.isDiff) updateFlag = true
      })
  
  
      if(updateFlag){
        const is_ok = window.confirm('編集中のデータを破棄しますか');
        if(!is_ok)
        {
          console.log( fromDateRef.current,searchOption.targetDt)
          fromDateRef.current = searchOption.targetDt
          return         
        }
      }
    }

    dispatch(SetLoadSpinner(true))
    // await getWorkDivisions()
    setSearchOption(
      {
        targetDt: fromDateRef.current,
        staffId: (staffIdRef.current)?staffIdRef.current.value:'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        postType:postType,
        postId:postId,
        departmentId:departmentId
      }
    )
    const data = {
      targetDt: fromDateRef.current,
      staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : (staffIdRef.current)?staffIdRef.current.value:''):'',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      page: page
    }
    const result = await GetApi(defaultUrl, data)

    if (result.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert)
      return null
    } else {

      //setStaffWorkInfoAllList(JSON.parse(JSON.stringify(result.data.responseList)))
      setStaffWorkInfoAllList(result.data.responseList)

      setStaffCount(result.data.responseList.length)
      setPageLast(Math.ceil(result.data.responseList.length/pageRecord))
      console.log(result)
      setPageNum(page)
      setPagenation(result.data)
      var tmp = result.data.responseList.slice()
      makePage(tmp,page)

      onClass(76,setPassDivisions)
      onClass(74,setTransportationPublics)
      onClass(75,setTransportationPraivates)
      onGasCost()
      dispatch(SetLoadSpinner(false))
    }
    
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
  }
  //ページング設定
  const makePage = (data,page) =>{
      if(page!=0){
        console.log(data)
        console.log(page)
        var minRecord = (page-1)*pageRecord
        var maxRecord = (page*pageRecord)
        console.log(minRecord,maxRecord)
        var tmpCount = 0
        var tmpList = new Array()
        data.forEach((staffWorkInfo)=>{
          if(tmpCount>=minRecord && tmpCount<maxRecord){
            tmpList.push(staffWorkInfo)
          }
          tmpCount+=1
        })
        setStaffWorkInfoList(tmpList)
        console.log(tmpList)
      }else{
        setStaffWorkInfoList(data)
      }
      
  }
  const onSubmit=()=>{}
  // POST・PUT処理
  const onUpdate = () => {
    function onCheckDiff(workInfo,dailyRow){
      dailyRow.privateTransportationExpensesList = new Array()
      workInfo.privateTransportationExpensesList.map((info)=>{
        console.log(info)
        if(info.isDiff){
          dailyRow.privateTransportationExpensesList.push(info)
        }
      })
      dailyRow.publicTransportationExpensesList  = new Array()
      workInfo.publicTransportationExpensesList.map((info)=>{
        if(info.isDiff){
          dailyRow.publicTransportationExpensesList.push(info)
        }
      })
      if(dailyRow.publicTransportationExpensesList.length != 0) return true

      if(dailyRow.privateTransportationExpensesList.length != 0) return true
 
      if(workInfo.isDiff) return true
      // if(dailyRow.monthly!=workInfo.monthly) return true

      // if(dailyRow.daily!=workInfo.daily) return true
 
      // if(dailyRow.housingAllowance!=workInfo.housingAllowance) return true

      // if(dailyRow.familyAllowance!=workInfo.familyAllowance) return true
  
      // if(dailyRow.jobAllowance!=workInfo.jobAllowance) return true

      // if(dailyRow.specialAllowance!=workInfo.specialAllowance) return true
  
      // if(dailyRow.transportationExpenses!=workInfo.transportationExpenses) return true

      return false
    }

    async function sendPost (requestBody) {
      var result
      dispatch(SetLoadSpinner(true))
      result = await MultipartApi(`${defaultUrl}`, 'POST', requestBody)
      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(result, alert)) {
        alert.success('保存しました')
        onSearch('admin',1)
      }
    }


    var dailyList= new Array();
    staffWorkInfoAllList.forEach(workInfo => {
      let id = workInfo.staffId

      let dailyRow ={
        dateValue: workInfo.dateValue,
        staffId: workInfo.staffId,
        
        monthly: workInfo.monthly,//parseInt(getValues('monthly_'+id).replace(/,/g, '')),
        daily: workInfo.daily,//parseInt(getValues('daily_'+id).replace(/,/g, '')),
        housingAllowance: workInfo.housingAllowance,//parseInt(getValues('housingAllowance_'+id).replace(/,/g, '')),
        fullTimeAllowance: 0,
        familyAllowance: workInfo.familyAllowance,//parseInt(getValues('familyAllowance_'+id).replace(/,/g, '')),
        jobAllowance: workInfo.jobAllowance,//parseInt(getValues('jobAllowance_'+id).replace(/,/g, '')),
        specialAllowance: workInfo.specialAllowance,//parseInt(getValues('specialAllowance_'+id).replace(/,/g, '')),
        transportationExpenses: workInfo.transportationExpenses,//parseInt(getValues('transportationExpenses_'+id).replace(/,/g, '')),

        secondedAllowanceRate: 0.25,

        privateTransportationExpensesList:workInfo.privateTransportationExpensesList,
        publicTransportationExpensesList:workInfo.publicTransportationExpensesList,

        updatedAt: workInfo.updatedAt,
        updatedId: workInfo.updatedId
      }

      //差異チェック
      if(onCheckDiff(workInfo,dailyRow))
      {
        dailyList.push(dailyRow)
      }
     
    })

    console.log(dailyList)

    if(dailyList.length == 0){
      alert.error("更新するデータがありません")
      return
    }
    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(dailyList)], { type: 'application/json' }))
    sendPost(submitData)
  }
  const onClass = async (classificationId,setList) => {
    dispatch(SetLoadSpinner(true))

    const data = {
      classificationId:classificationId
    }
    const result = await GetApi(defaultUrl+'/Class', data)

    if (result.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert)
      return null
    } else {
      const options = result.data.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setList(options)
    }
    dispatch(SetLoadSpinner(false))

  }
  const onGasCost = async () => {
    dispatch(SetLoadSpinner(true))

    const data = {
      targetDt:fromDateRef.current,
    }
    const result = await GetApi(defaultUrl+'/GasCost', data)

    if (result.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert)
      return null
    } else {
      setGasCost(Math.floor(result.data*100)/100)
    }
    dispatch(SetLoadSpinner(false))

  }
  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
    console.log(viewSubSearch)
  }
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }
  const onPageSeletClick = (pageId) => {
    console.log(pageId)
    //onSearch(null,pageId)
    //makePage(JSON.parse(JSON.stringify(staffWorkInfoAllList)),pageId)
    makePage(staffWorkInfoAllList,pageId)
    setPageNum(pageId)
}
const onPageNextSeletClick = (pageId) => {
  if(pageNum!=pageLast){
    onPageSeletClick(pageId)
  }
  //setStaffWorkInfoList(staffWorkInfoAllList)
  //makePage(new Array(),pageId)
}
const onPageBackSeletClick = (pageId) => {
  if(pageNum!=1){
    onPageSeletClick(pageId)
  }
  //console.log(staffWorkInfoAllList)
  //setStaffWorkInfoList(staffWorkInfoAllList)
  //makePage(new Array(),pageId)
}
const onPageChangeLabelClick = () => {
  if(pageType==0){
    setPageType(1)
    setPageNum(0)
    makePage(staffWorkInfoAllList,0)
  }else{
    setPageType(0)
    setPageNum(1)
    makePage(staffWorkInfoAllList,1)
  }
}
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  const setPost=()=>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      }
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.companyId,
          name: item.companyName+'('+item.companyId+')',
        }
      })
      setPostList(resultList)
      return true
    }
    const serachCustomer = async()=>{
      const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } 
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.customerId,
          name: item.customerName+'('+item.customerId+')',
        }
      })
      
      setPostList(resultList)
      return true
    }
    if(postType==0){
      serachCompany()
    }else{
      serachCustomer()
    }
  }
 
  //会社顧客変更処理
  useEffect(() => {
    setPost()
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postType])

  //閉じる処理
  const makeCloseFlag=()=>{

    {
      var updateFlag = false
      staffWorkInfoAllList.forEach(workInfo => 
      {
        var dailyRow = {}
        dailyRow.privateTransportationExpensesList = new Array()
        workInfo.privateTransportationExpensesList.map((info)=>{
          console.log(info)
          if(info.isDiff){
            dailyRow.privateTransportationExpensesList.push(info)
          }
        })
        dailyRow.publicTransportationExpensesList  = new Array()
        workInfo.publicTransportationExpensesList.map((info)=>{
          if(info.isDiff){
            dailyRow.publicTransportationExpensesList.push(info)
          }
        })
        if(dailyRow.publicTransportationExpensesList.length != 0) updateFlag = true
    
        if(dailyRow.privateTransportationExpensesList.length != 0) updateFlag = true
    
        if(workInfo.isDiff) updateFlag = true
      })
  
  
      if(updateFlag){
        const is_ok = window.confirm('編集中のデータを破棄しますか');
        if(is_ok){
          setStaffWorkInfoAllList([])
        }
        return !is_ok
      }
    }

    return false
  }

  //ページ遷移前の更新破棄の確認
  //更新キャンセル処理
  useEffect(() => {
    // イベントの設定
    window.addEventListener('beforeunload', onUnload);
  
    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload);
    }
  })

  const onUnload = (e) => {
    var updateFlag = false
    staffWorkInfoAllList.forEach(workInfo => 
    {
      var dailyRow = {}
      dailyRow.privateTransportationExpensesList = new Array()
      workInfo.privateTransportationExpensesList.map((info)=>{
        console.log(info)
        if(info.isDiff){
          dailyRow.privateTransportationExpensesList.push(info)
        }
      })
      dailyRow.publicTransportationExpensesList  = new Array()
      workInfo.publicTransportationExpensesList.map((info)=>{
        if(info.isDiff){
          dailyRow.publicTransportationExpensesList.push(info)
        }
      })
      if(dailyRow.publicTransportationExpensesList.length != 0) updateFlag = true
  
      if(dailyRow.privateTransportationExpensesList.length != 0) updateFlag = true
  
      if(workInfo.isDiff) updateFlag = true
    })
    if(updateFlag){
      e.preventDefault();
      e.returnValue = '';
    }
  }


  //ダイアログ表示時の処理
  useEffect(() => {
      if(open) onSearch(null,1)
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [open])
  if (!fromDateRef.current) {
    var today = new Date();
    fromDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
    toDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
  }

  // POST・PUT処理
  const onDataCopy = async() => {
    const is_ok = window.confirm('上書き保存されますがいいですか？');
    if(!is_ok)
    {
      return         
    }
    dispatch(SetLoadSpinner(true))

    const data = {
      targetDt: fromDateRef.current,
      staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : (staffIdRef.current)?staffIdRef.current.value:''):'',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      page: 0
    }    
    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify([])], { type: 'application/json' }))
    const result = await GetApi(defaultUrl+'/dataCopy',data)

    if (result.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert)
      return null
    } else {
      alert.success('保存しました')
      onSearch('admin',1)
    }
    
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);

  }
  return (
    <Modal
      open={open}
      onClose={()=>onCloseFunc(makeCloseFlag())}
      aria-labelledby='手当設定編集'
      aria-describedby='手当設定編集ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap  onSubmit={handleSubmit(onSubmit)}  className='h-dsm'> 
    <div className={classes.paper}>
      <ContentWrap>
      <Search
          title= {'手当設定'}
          onClick={() => onSearch(null, pageType==0?1:0)}
          onClickOption={(e) => onClickOptionBtn(e)}
          onKeyDown={(e) => onKeyDown(e)}
          fromDateRef={fromDateRef}
          toDateRef={toDateRef}
          staffIdRef={staffIdRef}
          staffNameRef={staffNameRef}
          viewSubSearch={viewSubSearch}
          grants={grants}
          postList={postList}
          postId={postId}
          setPostId={setPostId}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          postType={postType}
          setPostType={setPostType}
          viewFunction={viewFunction}
          onUpadate={()=>onUpdate()}
          onClose={()=>onCloseFunc(makeCloseFlag())}
          onClickFunction={()=>onClickFunction()}
          onPageTypeChangeClick={() => onPageChangeLabelClick()}
          onDataCopy={()=>onDataCopy()}
        >
        </Search>
        <AttendanceCountPanelWrap>
          <label>検索ヒット件数:{staffCount}件</label>
        </AttendanceCountPanelWrap>
      <TableWrap>
        <DailyTable
          register={register}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          reset={reset}
          errors={errors}
          defaultUrl={defaultUrl}
          workInfoList={staffWorkInfoList}

          passDivisions={passDivisions}
          transportationPublic={transportationPublic}
          transportationPraivate={transportationPraivate}
          gasCost={gasCost}
        >
        </DailyTable>
      </TableWrap>
      </ContentWrap>
      {
          staffWorkInfoList.length>0 &&
          <AttendancePagingPanelWrap>
          {/* <AttendancePagingChangePanelWrap onClick={() => onPageChangeLabelClick()}>表示切替</AttendancePagingChangePanelWrap> */}
          { pageType==0 &&
            <AttendancePageButtonWrap>       
            <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
            <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
            <AttendancePagingIconPanelWrap>{pageNum}</AttendancePagingIconPanelWrap>
            <AttendancePagingIconPanelWrap>/</AttendancePagingIconPanelWrap>
            <AttendancePagingIconPanelWrap>{pageLast}</AttendancePagingIconPanelWrap>
            <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
            <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
            
            </AttendancePageButtonWrap>
          }
          </AttendancePagingPanelWrap>
        }
      {/* <ButtonsWrap>
        <Button name='更新' padding='0 0 12px 0' type='submit'/>
        <Button name='閉じる' padding='0px' onClick={onCloseFunc} />
      </ButtonsWrap> */}
    </div>
    </FormWrap>
  </Modal>
  )
}

PaymentDuductionModal.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedShiftFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    dailyWorkInfo: PropTypes.object,
    workDivisions: PropTypes.array,
}

export default PaymentDuductionModal
