import React,{ useRef} from 'react'
import PropTypes from 'prop-types'

import MonthTableBody from './ShiftGanttChartMonthTableBody'
import SalariedTableWrap,{TableDiv}from '../../Wrap/ShiftGanttChartTableWrap'
import RowTableHeader from './ShiftGanttChartTableMonthHeader'

const MonthTable = ({ dateDataList, targetDt,staffWorkInfoList, selectedIndex, selectFunction, updatedShiftFunc, defaultUrl,workDivisions }) => {
  
  const wrapRef = useRef()
  return (
    <TableDiv ref={wrapRef} id={'chartTable'}>
    <SalariedTableWrap visible={staffWorkInfoList.length ? true : false}>
      <RowTableHeader/>
      <MonthTableBody
        staffWorkInfoList={staffWorkInfoList}
        workDivisions={workDivisions}
        wrapRef={wrapRef}
      >
      </MonthTableBody>
    </SalariedTableWrap>
    </TableDiv>
  )
}

MonthTable.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  onSalariedSearch: PropTypes.func,
  defaultUrl: PropTypes.string,
  targetDt: PropTypes.any,
}

export default MonthTable
