import React from 'react'
import PropTypes from 'prop-types'

import { ColumnDateTH, ColumnStatusTH, ColumnButtonsTH } from '../../Wrap/PunchTableWrap'

const PunchTableHeader = ({  }) => {

  return (
    <thead>
      <tr>
        <ColumnStatusTH>打刻</ColumnStatusTH>
        <ColumnDateTH>時刻</ColumnDateTH>
        <ColumnButtonsTH></ColumnButtonsTH>
      </tr>
    </thead>
  )
}

PunchTableHeader.propTypes = {
}

export default PunchTableHeader
