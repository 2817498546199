function sleep(waitSec, callbackFunc) {
  // 経過時間（秒）
  var spanedSec = 0;

  var id = setInterval(function () {

      spanedSec++;

      if (spanedSec >= waitSec) {

          clearInterval(id);

          if (callbackFunc) callbackFunc();
      }
  }, 1000);
}

// APIレスポンスエラーハンドリング
const ErrorHandling = (result, alert) => {
    if ('errorDetail' in result) {
      const errorResponse = result.errorDetail.response
      if (!errorResponse) {
        alert.error('error')
        return true
      }
      if (!errorResponse.status) {
        alert.error('error')
        return true
      }
      if (errorResponse.data && errorResponse.data.message) {
        alert.error(errorResponse.data.message)
        return true
      }
      switch (errorResponse.status) {
        case 209:
          alert.error('別ユーザーが更新済みです。')
          break
        case 400: // BAD REQUEST 不正操作
          alert.error('サーバーで問題が発生しました。エラーコード:' + errorResponse.status + ' システム管理者に問い合わせてください。')

          sleep(1, function () {
            window.location.href = '/login'
          });
          break
        case 401: // UNAUTHORIZED 認証失敗
          alert.error('認証の有効期限が切れました。')
          
          sleep(1, function () {
            window.location.href = '/login'
          });
          break
        case 403: // FORBIDDEN アクセス拒否
          alert.error('サーバーで問題が発生しました。エラーコード:' + errorResponse.status + ' システム管理者に問い合わせてください。')

          sleep(1, function () {
            window.location.href = '/login'
          });
          break
        case 404: // NOT_FOUND ページがない
          alert.error('該当するデータがありません。')
          break
        case 500: // INTERNAL_SERVER_ERROR
          alert.error('サーバーで問題が発生しました。エラーコード:' + errorResponse.status + ' システム管理者に問い合わせてください。')

          sleep(1, function () {
            window.location.href = '/login'
          });
          break
        case 503: // SERVICE_UNAVAILABLE
        alert.error('サーバーで問題が発生しました。エラーコード:' + errorResponse.status + ' システム管理者に問い合わせてください。')

        sleep(1, function () {
          window.location.href = '/login'
        });
          break
        default:
          alert.error('サーバーで問題が発生しました。エラーコード:' + errorResponse.status + ' システム管理者に問い合わせてください。')

          sleep(5, function () {
            window.location.href = '/login'
          });
      }
      return true
    }
    return false
  }
  
  export default ErrorHandling
  