import React from 'react'
import PropTypes from 'prop-types'
import { makeElementId } from '../../../Common/Utilties/LaborUtility'

import DailyTableRow from './EmploymentTableRow'

const DailyTableBody = ({
    register, getValues, setValue, watch, reset, errors,
    defaultUrl, workInfoList,workDivisions,restFlag,dailyWorkInfo
  }) => {

  return (
    <tbody>
      {workInfoList &&
          <DailyTableRow key={"top_" + makeElementId(workInfoList)}
            register={register}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            reset={reset}
            errors={errors}
            workDivisions={workDivisions}
            defaultUrl={defaultUrl}
            workInfo={workInfoList}
            restFlag={restFlag}
            dailyWorkInfo={dailyWorkInfo}
          >
          </DailyTableRow>
      }
    </tbody>
  )
}

DailyTableBody.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array
}

export default DailyTableBody
