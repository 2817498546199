import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { GetApi } from '../../../../Common/ApiAxios'
import ErrorHandling from '../../../../Common/ErrorHandling'

import FunctionTableRow from './FunctionTableRow'

const FunctionTableBody = ({ 
  register, getValues, setValue, watch, reset, errors, 
  grantDetail, selectFunction, updatedDateFunc, defaultUrl,isDeleted
   }) => {

  // 初期化メソッド
  useEffect(() => {
      
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])


  return (
    <tbody>
      {grantDetail.functionItems && grantDetail.functionItems.map((functionItem,index) => {
        return (
          <FunctionTableRow key={functionItem.functionMenuId}
            register={register}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            reset={reset}
            errors={errors}
            index={index}
            isDeleted={isDeleted}
            functionItem={functionItem}
            >
          </FunctionTableRow>
        )
      })}
    </tbody>
  )
}

FunctionTableBody.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  grantDetail: PropTypes.array,
  selectFunction: PropTypes.func,
}

export default FunctionTableBody
