import React,{useState, useEffect, useRef ,useCallback} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector,useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import { InputGroup, SelectGroup, HiddenInput,Input } from '../../../Common/CommonInputGroup'
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../../../Common/Atoms/NormalButton'
import RangeCalendar from '../../../Common/Atoms/RangeCalendar'
export const defaultUrl = '/api/paymentDuductionManagement/paymentDuduction'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 30rem;
  width: 50rem;
`
const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 3rem;
  text-align: right;
  padding: 1vw;
`
const ContentCalender = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 3rem;
  text-align: right;
  padding: 1vw;
  line-height:32px;
`

const ContentLabel = styled.div`
  display: flex;
  flex-wrap: wrap;
  line-height:32px;
  height: 2rem;
  width:6rem;
`
const ContentGasLabel = styled.div`
  display: flex;
  flex-wrap: wrap;
  line-height:32px;
  height: 2rem;
  width:10rem;
`

const ContentRangeLabel = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 2rem;
  width:5rem;
`

const TitleWrap = styled.div`
  font-size: 24px;
  display: flex;
  font-weight: bold;
  padding: 1vw;
`
const FormWrap = styled.form`
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
  padding: 1vw;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

const PublicTransportationEditDialog = ({ open, onCloseFunc,workInfo,passDivisions,transportationPrivate,type,transport,gasCost,onSearch}) => {
  const { register, handleSubmit, getValues, setError, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();
  const [passDivision, setPassDivision] = useState(0)
  const [transportation, setTransportation] = useState(0)
  const fromDateRef = useRef()
  const toDateRef = useRef()
  const alert = useAlert()
  // 期間指定
  const [rangeMonth, setRangeMonth] = useState({ fromDate: new Date(workInfo.dateValue), toDate: new Date(workInfo.dateValue) })
  const onChangeRangeCalendar = useCallback((fromDate, toDate) => {
    fromDateRef.current = fromDate;
    toDateRef.current = toDate;
    setRangeMonth({ fromDate:new Date(fromDate), toDate:new Date(toDate)})
  }, [setRangeMonth])

  useEffect(() => {
    if (!workInfo) {
        return
    }
  }, [workInfo])

  useEffect(() => {
    async function getOptions () {
      console.log(transport)
      setPassDivision(transport.passDivision)
      setTransportation(transport.transportation)
      //onChangeRangeCalendar(new Date(transport.startDt), new Date(transport.endDt) )
      setRangeMonth({ fromDate: new Date(transport.startDt), toDate: new Date(transport.endDt) })

      var today = new Date(transport.startDt);
      fromDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
      today = new Date(transport.endDt);
      toDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)

      
      //setValue('passPrice_'+ workInfo.staffId,String(transport.passPrice))
      //setValue('runningDistance_'+ workInfo.staffId,String(transport.runningDistance))
    }

    if (transport.length == 0) {
        setRangeMonth({ fromDate: new Date(workInfo.dateValue), toDate: new Date(workInfo.dateValue) })
        return
    }
    getOptions()

  }, [transport])
  // POST・PUT処理
  const onSubmit = () => {
    if (!workInfo) {
      return
    }

    var transInfo={
      endDt: toDateRef.current,
      passDivision: passDivision,
      passDivisionName: onSerachDivisionName(passDivision,passDivisions),
      passPrice: getValues('passPrice_'+ workInfo.staffId),
      runningDistance: getValues('runningDistance_'+ workInfo.staffId),
      staffId: workInfo.staffId,
      startDt:  fromDateRef.current,
      transportNum: transport.transportNum!=undefined?transport.transportNum:onMaxNum(workInfo.privateTransportationExpensesList),
      transportation: transportation,
      transportationName: onSerachDivisionName(transportation,transportationPrivate),
      delFlag: true,
      isDiff: true
    }
    if (!transInfo.passPrice) {
      alert.error("料金を入力してください")
      return
    }

    if(type==2){
      transport.startDt = transInfo.startDt;
      transport.endDt = transInfo.endDt;
      transport.passDivision = transInfo.passDivision;
      transport.passDivisionName = transInfo.passDivisionName;
      transport.runningDistance = transInfo.runningDistance;
      transport.passPrice = transInfo.passPrice;
      transport.transportation = transInfo.transportation;
      transport.transportationName = transInfo.transportationName;
      transport.transportNum = transInfo.transportNum;
      transport.isDiff = true
      onSearch(transport)
    }
    else{
      workInfo.privateTransportationExpensesList.push(transInfo)
      onSearch(transInfo)
    }

    onCloseFunc()
  }
  const onDelete = () => {
    transport.delFlag = false;
    transport.isDiff = true
    if(!transport.updatedAt){
      transport.isDiff = false
    }
    onCloseFunc()
  }
  const onChange = (event,setDivision) => {
    event.persist()
    setDivision(event.target.value)
  }
  const onSerachDivisionName = (num,divisionList) => {
    return divisionList[num].props.children
  }
  const onMaxNum = (transList) => {
    var max = 0;
    transList.map((item)=>{
      if(item.transportNum>=max){
        max = item.transportNum
        max = max + 1
      }
    })
    return max
  }
  const makeYM=(date)=>
  {
    var dateYM = date.split('-')
    return dateYM[0] + '/' + dateYM[1]
  }

    //ページ遷移前の更新破棄の確認
  //更新キャンセル処理
  useEffect(() => {
    // イベントの設定
    window.addEventListener('beforeunload', onUnload);
  
    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload);
    }
  })

  const onUnload = (e) => {
    var updateFlag = true
    if(updateFlag){
      e.preventDefault();
      e.returnValue = '';
    }
  }
  
  const onChangeDistance=(event)=>{
    event.persist()
    if(transportation==3 || transportation==4)
    setValue('passPrice_'+ workInfo.staffId,parseInt(event.target.value*2*gasCost))
  }
  const onChangeTransportion=(event,setDivision)=>{
    event.persist()
    setDivision(event.target.value)
    if(event.target.value==3 || event.target.value==4)
    setValue('passPrice_'+ workInfo.staffId,parseInt(getValues('runningDistance_'+ workInfo.staffId)*2*gasCost))
  }

 if (!fromDateRef.current && workInfo) {
    var today = new Date(workInfo.dateValue);
    fromDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
    toDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
  }
  return ( 
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='私用交通費編集'
      aria-describedby='私用交通費編集ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap className='h-dsm'> 
    <div className={classes.paper}>
      <ContentWrap>
        <TitleWrap>{'私用交通費'}</TitleWrap>
        <Content>
          <ContentLabel>対象年月</ContentLabel>
          <InputGroup                 
                name={'dateValue_'+ workInfo.staffId}
                width='6rem'
                placeholder=''
                margin='auto'
                register={register}
                errors={errors}
                value={makeYM(workInfo.dateValue)}
                style={{ textAlign: 'center', paddingRight: '0.5rem' }}
                disabled={true}/>
        </Content>
        <Content>
          <ContentLabel>スタッフID</ContentLabel>
          <InputGroup                 
                name={'staffId_'+ workInfo.staffId}
                width='8rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                value={workInfo.staffId}
                style={{ textAlign: 'left', paddingRight: '0.5rem' }}
                disabled={true}/>
          <div style={{width:'20px'}}/>
          <ContentLabel>スタッフ名</ContentLabel>
          <InputGroup                 
                name={'staffName_'+ workInfo.staffId}
                width='18rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                value={workInfo.staffName}
                style={{ textAlign: 'left', paddingRight: '0.5rem' }}
                disabled={true}/>
        </Content>
        <div style={{height:'20px'}}/>
        <Content>
          <ContentLabel>支払区分</ContentLabel>
          <SelectGroup
              name={'passDivision_'+ workInfo.staffId}
              width='10rem'
              margin='auto'
              options={passDivisions}
              register={register}
              errors={errors}
              disabled={type==0}
              selectValue = {passDivision}
              onChange={(e)=>onChange(e,setPassDivision)}
            />
        </Content>
        <ContentCalender>
          <ContentRangeLabel>期間</ContentRangeLabel>
          <RangeCalendar value={rangeMonth} onChange={onChangeRangeCalendar} isViewIcon={true} placeholderStart={'開始日'} placeholderEnd={'終了日'} disabled={type==0}/>
        </ContentCalender>
        <Content>
          <ContentLabel>移動手段</ContentLabel>
          <SelectGroup
              name={'transportation_'+ workInfo.staffId}
              width='6rem'
              margin='auto'
              options={transportationPrivate}
              register={register}
              errors={errors}
              disabled={type==0}
              selectValue = {transportation}
              onChange={(e)=>onChangeTransportion(e,setTransportation)}
            />
        </Content>
        <Content>
          <ContentGasLabel>実走距離(片道)km</ContentGasLabel>
          <InputGroup                 
                name={'runningDistance_'+ workInfo.staffId}
                width='15rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                style={{ textAlign: 'left', paddingRight: '0.5rem' }}
                disabled={type==0}
                defaultValue={transport.runningDistance}
                onChange={(e)=>onChangeDistance(e)}/>
          <div style={{width:'20px'}}/>
          <ContentGasLabel>ガソリン単価(1km)</ContentGasLabel>
          <InputGroup            
                name={'gasCost_'+ workInfo.staffId}     
                width='4rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                value={gasCost}
                style={{ textAlign: 'left', paddingRight: '0.5rem' }}
                disabled={true}/>
        </Content>
        <Content>
          <ContentLabel>料金</ContentLabel>
          <InputGroup                 
                name={'passPrice_'+ workInfo.staffId}
                width='8rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                style={{ textAlign: 'left', paddingRight: '0.5rem' }}
                disabled={type==0}
                defaultValue={transport.passPrice}/>
        </Content>
      </ContentWrap>
      <ButtonsWrap>
        <Button name='更新' padding='0 0 12px 0' type='button' onClick={onSubmit} disabled={type==0}/>
        <Button name='削除' padding='0 0 12px 0' type='button' onClick={onDelete} disabled={type!=2}/>
        <Button name='閉じる' type='button' padding='0px' onClick={onCloseFunc} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

PublicTransportationEditDialog.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedShiftFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    dailyWorkInfo: PropTypes.object,
    workDivisions: PropTypes.array,
}

export default PublicTransportationEditDialog
