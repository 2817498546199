import React from 'react'
import PropTypes from 'prop-types'

import { ColumnStaffTH, ColumnOccupationTH, ColumnDateTH, ColumnWorkTypeTH, ColumnTimeTH, ColumnRemarkTH, ColumnButtonsTH} from '../../Wrap/DailyTableWrap'

const DailyTableHeader = ({ staffWorkInfoList, selectFunction ,restFlag}) => {

  return (
    <thead>
      <tr>
        <ColumnStaffTH>スタッフ名</ColumnStaffTH>
        <ColumnOccupationTH>業務</ColumnOccupationTH>
        <ColumnDateTH>日付</ColumnDateTH>
        <ColumnWorkTypeTH>出勤区分</ColumnWorkTypeTH>
        <ColumnTimeTH>勤怠時間</ColumnTimeTH>
        <ColumnTimeTH>休憩時間</ColumnTimeTH>
        <ColumnTimeTH>残業時間</ColumnTimeTH>
        <ColumnTimeTH>出勤</ColumnTimeTH>
        <ColumnTimeTH>退勤</ColumnTimeTH>
        <ColumnTimeTH>休始1</ColumnTimeTH>
        <ColumnTimeTH>休終1</ColumnTimeTH>
        <ColumnTimeTH>休始2</ColumnTimeTH>
        <ColumnTimeTH>休終2</ColumnTimeTH>
        <ColumnTimeTH>休始3</ColumnTimeTH>
        <ColumnTimeTH>休終3</ColumnTimeTH>
        <ColumnTimeTH>休憩</ColumnTimeTH>
        <ColumnRemarkTH>備考</ColumnRemarkTH>
        <ColumnButtonsTH></ColumnButtonsTH>
      </tr>
    </thead>
  )
}

DailyTableHeader.propTypes = {
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
}

export default DailyTableHeader
