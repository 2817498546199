import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import { formatHour } from '../../../Common/Utilties/LaborUtility'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'

import { ColumnStaffTD } from '../../Wrap/MonthTableWrap'
import MonthTableDataShiftCell from './MonthTableDataShiftCell'
import MonthTableDailySummaryCell from './MonthTableDailySummaryCell'
import MonthTableTimeCell from './MonthTableTimeCell'

const MonthTableBody = ({ staffWorkInfoList, selectFunction, updatedShiftFunc, defaultUrl,shiftFilter,workDivisions }) => {

  const countWork = (staff) => {
    let count = 0;

    staff.dailyWorkInfoList.forEach(day => {
      if (day.shiftWork)
        count++;
    })

    return count.toString();
  }
  const countAttendanceWork = (staff) => {
    let count = 0;

    staff.dailyWorkInfoList.forEach(day => {
      if (day.attendanceWork)
        count++;
    })

    return count.toString();
  }
  const calcWorkTime = (staff) => {
    let time = 0;
    staff.dailyWorkInfoList.forEach(day => {
      if (day.shiftWork) {
        day.workInfoList.forEach(workInfo => {
          if (workInfo.shiftWork)
            time += workInfo.shiftWorkTotalDt;
        })
      }
    })

    return formatHour(time);
  }
  const calcAttendanceWorkTime = (staff) => {
    let time = 0;
    staff.dailyWorkInfoList.forEach(day => {
      if (day.attendanceWork) {
        day.workInfoList.forEach(workInfo => {
          if (workInfo.attendanceWork)
            time += workInfo.attendanceWorkTotalDt;
        })
      }
    })

    return formatHour(time);
  }
  const createElementStaff = (staff) => {
    if (!staff) {
      return <><br/><span>( - )</span></>
    }
    let text = staff.staffName
    let textPx = measurePxText(text)
    let textRem = convertPxToRem(textPx)

    let checkRem = 9
    if (textRem < checkRem)
    {
      return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></>
    }

    let editedText = text
    while (textRem > checkRem) {
      editedText = editedText.slice(0, -1)
      textPx = measurePxText(editedText + '...')
      textRem = convertPxToRem(textPx)
    }

    editedText = editedText + '...'

    return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({staff.staffId})</span></span></Tooltip>
  }

  return (
    <tbody>
      {staffWorkInfoList && staffWorkInfoList.map((staff) => {
        return (
          <tr key={staff.staffId}>
            <ColumnStaffTD>{createElementStaff(staff)}</ColumnStaffTD>
            <MonthTableDailySummaryCell 
            shiftDateCount = {countWork(staff)}
            attendanceDateCount = {countAttendanceWork(staff)} >
            </MonthTableDailySummaryCell>
            <MonthTableTimeCell 
            shiftWorkTime = {calcWorkTime(staff)}
            attendanceWorkTime = {calcAttendanceWorkTime(staff)} >
            </MonthTableTimeCell>
            {staff && staff.dailyWorkInfoList.map((dailyWorkInfo) => {
              return (
                <MonthTableDataShiftCell
                  key={'data_shift_' +dailyWorkInfo.staffId + dailyWorkInfo.baseDt}
                  dailyWorkInfo={dailyWorkInfo}
                  updatedShiftFunc={updatedShiftFunc}
                  defaultUrl={defaultUrl}
                  shiftFilter={shiftFilter}
                  workDivisions={workDivisions}
                  restFlag={staff.restFlag}
                >
                </MonthTableDataShiftCell>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

MonthTableBody.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
  workDivisions: PropTypes.array,
}

export default MonthTableBody
