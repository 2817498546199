import React, { useState, useEffect, useRef,useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { BrowserRouter as Router, Link } from 'react-router-dom'

//import Select from '../Common/Atoms/Select'
import { GetApi,MultipartApi } from '../Common/ApiAxios'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../Common/ErrorHandling'
import FUNC from '../Common/FunctionEnum'
import ShiftPanelWrap, { FunctionLinkContent,FunctionLinkContentRow,ShiftFlexWrap,FilterPanelWrap,ShiftCountPanelWrap,IconImg,ShiftPagingIconPanelWrap,PagingIconImg ,ShiftFiltersPanelWrap,PagingIconNextImg,ShiftPagingPanelWrap,ShiftPageButtonWrap,FunctionLegendContent,FunctionContentRow} from './Wrap/ShiftPanelWrap'
import Search from './Search'
import MonthTable from './Molcules/MonthTable/MonthTable'
import { makeShiftCSV,checkNomalShift,calcRestHours,calcWorkingRestFreeSeconds } from '../Common/Utilties/LaborUtility'
//import ImportDialog from './Molcules/ImportDialog'
//import FilterDialog from './Molcules/FilterDialog'

import IconShiftImg from '../../image/shift.png'
import IconShiftNormalImg from '../../image/shift-nomal.png'
import IconShiftDiffImg from '../../image/shift-diff.png'
import IconShiftAbsenceImg from '../../image/shift-absence.png'

import IconPageNextImg from '../../image/PageNext.png'
import IconPageBackImg from '../../image/PageBack.png'
import IconPageLastImg from '../../image/PageLast.png'
import IconPageTopImg from '../../image/PageTop.png'

import IconFilter from '../../image/filter.png'
import { Tooltip } from '@material-ui/core'
export const DefaultUrl = '/api/shiftManagement/shift'

const Shift = (props) => {
  const shiftDateRef = useRef()
  const staffIdRef = useRef()
  const staffNameRef = useRef()
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [viewFunction,setViewFunction] = useState(false)
 
  const [searchOption, setSearchOption] = useState({ shiftDt: '', staffId: '', staffName: '',postType:'',postId:'',departmentId:'' })
  const { loginUserInfo } = useSelector(state => state)
  const [staffWorkInfoList, setStaffWorkInfoList] = useState([])
  const [staffWorkInfoAllList, setStaffWorkAllInfoList] = useState([])
  const [staffWorkInfoHeaderList, setStaffWorkInfoHeaderList] = useState([])
  const [dateDataList, setDateDataList] = useState([])
  const [pagenation, setPagenation] = useState({})
  const [staffCount, setStaffCount] = useState(0)
  const [shiftFilter, setShiftFilter] = useState('all')
  const dispatch = useDispatch()
  const alert = useAlert()
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.SHIFT)
  const [icons,setIcons] = useState(<></>)
  const [iconImages,setIconImages] = useState(new Array())

  const pageRecord = 12
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  
  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
  }
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }
  // 検索APIの実行
  const onSearch = async (createId, page) => {
    dispatch(SetLoadSpinner(true))
    await getWorkDivisions()
    setSearchOption(
      {
        targetDt: shiftDateRef.current,
        staffId: (staffIdRef.current)?staffIdRef.current.value:'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        postType:postType,
        postId:postId,
        departmentId:departmentId
      }
    )
    const data = {
      targetDt: shiftDateRef.current,
      staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      page: page
    }
    console.log(data.postId)
    const resultDate = await GetApi('/api/masterManagement/calendar/month', { targetDt: shiftDateRef.current })

    if (resultDate.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(resultDate, alert)
      return null
    } else {
      //setDataDateList(resultDate.data.responseList)
    }
    const resultShift = await GetApi(DefaultUrl, data)

    if (resultShift.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(resultShift, alert)
      return null
    } else {
      setDateDataList(resultDate.data.responseList)
      setPageNum(page)
      setStaffWorkAllInfoList(resultShift.data.responseList)
      resultShift.data.responseList = checkShift(resultShift.data.responseList,shiftFilter)
      setStaffWorkInfoHeaderList(resultShift.data.responseList)
      makePage(resultShift.data.responseList.slice(),page)
      setStaffCount(resultShift.data.responseList.length)
      setPageLast(Math.ceil(resultShift.data.responseList.length/pageRecord))
      setPagenation(resultShift.data)
    }
    dispatch(SetLoadSpinner(false))
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
    setViewFunction(false);
    oncloseLink();
  }
  //ページング設定
  const makePage = (data,page) =>{
    if(page!=0){
      console.log(data)
      console.log(page)
      var minRecord = (page-1)*pageRecord
      var maxRecord = (page*pageRecord)
      console.log(minRecord,maxRecord)
      var tmpCount = 0
      var tmpList = new Array()
      data.forEach((staffWorkInfo)=>{
        if(tmpCount>=minRecord && tmpCount<maxRecord){
          tmpList.push(staffWorkInfo)
        }
        tmpCount+=1
      })
      setStaffWorkInfoList(tmpList)
      console.log(tmpList)
    }else{
      setStaffWorkInfoList(data)
    }
    
  }
  const checkShift = (data,Filter) => {
    if(Filter=='all') return data 
    var fData = new Array()
    data.forEach((staffWorkInfo)=>{
      var rbet = false
      staffWorkInfo.dailyWorkInfoList.forEach((dailyWorkInfo)=>{
        if (dailyWorkInfo.shiftWork) {
          if (!dailyWorkInfo.attendanceWork) {
            if (dailyWorkInfo.baseDt < new Date().toISOString().slice(0,10)) {
              // 当日までの場合でシフト有、勤怠無しは欠席とする
              if(Filter==IconShiftAbsenceImg){
                rbet = true
              }
            }
            else {
              // 当日以降の場合は、シフト有とする
              if(Filter==IconShiftImg){
                rbet = true
              }
            }
          } else {
            if(checkNomalShift(dailyWorkInfo.workInfoList)){
              if(Filter==IconShiftNormalImg){
                rbet = true
              }
            }else{
              if(Filter==IconShiftDiffImg){
                rbet = true
              }
            }
          }
        } else {
          if (dailyWorkInfo.attendanceWork) {
            if(Filter==IconShiftDiffImg){
              rbet = true
            }
          } 
        }
      })
      console.log(rbet)
      if(rbet) fData.push(staffWorkInfo)
    })
    return fData
  }
  // 再検索用の検索APIを実施
  const onReSearch = async (createId, page) => {
    dispatch(SetLoadSpinner(true))
    setSearchOption(
      {
        targetDt: shiftDateRef.current,
        staffId: staffIdRef.current.value,
        staffName: staffNameRef.current.value,
        postType:postType,
        postId:postId,
        departmentId:departmentId
      }
    )
    const data = {
      targetDt: searchOption.targetDt,
      staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:searchOption.postType,
      postId:(searchOption.postId!=undefined && searchOption.postId != -1) ? searchOption.postId:'',
      departmentId:(searchOption.departmentId != null && searchOption.departmentId!=undefined)? searchOption.departmentId:'',
      page: page
    }
    console.log(data.postId)
    const resultDate = await GetApi('/api/masterManagement/calendar/month', { targetDt: data.targetDt })

    if (resultDate.errorDetail) {
      ErrorHandling(resultDate, alert)
      return null
    } else {
      //setDataDateList(resultDate.data.responseList)
    }
    const resultShift = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (resultShift.errorDetail) {
      ErrorHandling(resultShift, alert)
      return null
    } else {
      setDateDataList(resultDate.data.responseList)
      setPageNum(page)
      setStaffWorkAllInfoList(resultShift.data.responseList)
      resultShift.data.responseList = checkShift(resultShift.data.responseList,shiftFilter)
      setStaffWorkInfoHeaderList(resultShift.data.responseList)
      makePage(resultShift.data.responseList.slice(),page)
      setStaffCount(resultShift.data.responseList.length)
      setPageLast(Math.ceil(resultShift.data.responseList.length/pageRecord))
      setPagenation(resultShift.data)
    }
  }

    // エクスポートAPIの実行
  const onExport = async () => {
    setViewFunction(false);
    const data = {
      targetDt: shiftDateRef.current,
      staffId: grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value,
      staffName: staffNameRef.current.value,
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
    }
    console.log(data)
    const resultDate = await GetApi('/api/masterManagement/calendar/month', { targetDt: shiftDateRef.current })
    dispatch(SetLoadSpinner(false))
    if (resultDate.errorDetail) {
      ErrorHandling(resultDate, alert)
      return null
    } else {
    }
    const resultShift = await GetApi(DefaultUrl + '/export', data)
    if (resultShift.errorDetail) {
      ErrorHandling(resultShift, alert)
      return null
    }
    console.log(resultShift.data)
    console.log(resultShift.data.csvOutputWorkInfoList)
    console.log(resultShift.data.header)

    //休憩データ追加
    resultShift.data.csvOutputWorkInfoList.forEach((dailyRow)=>{
      dailyRow.restList = []
      resultShift.data.restInfoList.forEach((restInfo)=>{
        if(dailyRow.staffId !=  restInfo.staffId) return
        if(dailyRow.orderId !=  restInfo.orderId) return
        if(dailyRow.baseDt !=  restInfo.baseDt) return
          
        let restRow = {
            restStartDt:restInfo.restStartDt,
            restEndDt:restInfo.restEndDt
        }
        dailyRow.restList.push(restRow)
      })
    })
    const nowDate = resultShift.data.nowDate
    {
      const exportData = makeShiftCSV(resultShift.data.csvOutputWorkInfoList,resultShift.data.header)

      //BOM情報付与(utf-8 文字コードでcsvが開く情報付与)
      let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([bom,exportData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "シフト管理("+nowDate+").csv"; 
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
    // {
    //   const exportData = makeShiftRestCSV(resultShift.data.restInfoList,resultShift.data.header)

    //   //BOM情報付与(utf-8 文字コードでcsvが開く情報付与)
    //   let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
    //   const blob = new Blob([bom,exportData], { type: 'text/csv' });
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement("a");
    //   a.href = url;
    //   a.download = "シフト管理休憩情報("+nowDate+").csv"; 
    //   a.click();
    //   a.remove();
    //   window.URL.revokeObjectURL(url);
    // }
    setPost()
  }

  // 検索項目でキーボードの押下イベント
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }

  const pageSelectFunc = (pageId) => {
    onSearch(null, pageId)
  }

  const updatedShiftFunc = () => {
    onReSearch(null, pageNum)
  }

  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud'
    else
      document.title = 'Core Time Cloud'
  }, [grants])

  useEffect(() => {
    console.log(departmentId)
    console.log(postId)
  }, [departmentId])

  useEffect(() => {
    const options = new Array()
    options.push(<><option value={'all'} >{"全て"}</option></>)
    options.push(<><option value={IconShiftNormalImg} >{"シフトと実績に差異なし"}</option></>)
    options.push(<><option value={IconShiftDiffImg} >{"シフトと実績に差異あり"}</option></>)
    options.push(<><option value={IconShiftAbsenceImg} >{"シフトのみ（実績なし）"}</option></> )
    options.push(<><option value={IconShiftImg} >{"シフトの予定"}</option></>)
    setIcons(options)
    const iconList = new Array()
    iconList.push({image:null,text:'全て'})
    iconList.push({image:IconShiftNormalImg,text:'シフトと実績に差異なし'})
    iconList.push({image:IconShiftDiffImg,text:'シフトと実績に差異あり'})
    iconList.push({image:IconShiftAbsenceImg,text:'シフトのみ（実績なし）'})
    iconList.push({image:IconShiftImg,text:'シフトの予定'})
    setIconImages(iconList)

    setPost()
    onSearch(null,1)
  }, [])

  const handleChange =  (event) => {
    event.persist()
    console.log(event.target.options[event.target.selectedIndex].text)
    console.log(event.target.value)
    setShiftFilter(event.target.value)
    setFilterText(event.target.options[event.target.selectedIndex].text)
  }

  const imgeBoxClick =  (image,text) => {
    setShiftFilter(image)
    setFilterText(text)

    var tmpList = checkShift(staffWorkInfoAllList,image)
    setStaffWorkInfoHeaderList(tmpList)
    makePage(tmpList.slice(),(pageNum==0?0:1))
    setStaffCount(tmpList.length)
    setPageLast(Math.ceil(tmpList.length/pageRecord))

    setCanOpenDialog(false)
  }
  const [canOpenDialog, setCanOpenDialog] = useState(false);
  const [filterText, setFilterText] = useState('全て');

  const handleClose = () => {
    setCanOpenDialog(false);
  }

  const onClickLabel =  (event) => {
    console.log('Ok')
    setCanOpenDialog(!canOpenDialog)
  }

  if (!shiftDateRef.current) {
    var today = new Date();
    shiftDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-01'
  }

  const setPost=()=>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      }
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.companyId,
          name: item.companyName+'('+item.companyId+')',
        }
      })
      setPostList(resultList)
      return true
    }
    const serachCustomer = async()=>{
      const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } 
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.customerId,
          name: item.customerName+'('+item.customerId+')',
        }
      })
      setPostList(resultList)
      return true
    }

    if(postType==0){
      serachCompany()
    }else{
      serachCustomer()
    }
  }
  //会社顧客変更処理
  useEffect(() => {
    setPost()
  // 必要なデータが更新された場合のみ実行
// eslint-disable-next-line
  }, [postType])

  const [workDivisions, setWorkDivisions] = useState(<></>)
  //出勤区分取得
  const getWorkDivisions = useCallback(async () => {
    //dispatch(SetLoadSpinner(true))
    const result = await GetApi('/api/masterManagement/classificationValues/workDivisions')
    //dispatch(SetLoadSpinner(false))
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setWorkDivisions(options)
    }
  }, [setWorkDivisions, alert])

  const onPageSeletClick = (pageId) => {
    console.log(pageId)
    makePage(staffWorkInfoHeaderList,pageId)
    setPageNum(pageId)
  }
  const onPageNextSeletClick = (pageId) => {
    if(pageNum!=pageLast){
      onPageSeletClick(pageId)
    }
  }
  const onPageBackSeletClick = (pageId) => {
    if(pageNum!=1){
      onPageSeletClick(pageId)
    }
  }
  const onPageChangeLabelClick = () => {
    setViewFunction(false);
    if(pageType==0){
      setPageType(1)
      setPageNum(0)  
      makePage(staffWorkInfoHeaderList,0)

    }else{
      setPageType(0)
      setPageNum(1)
      makePage(staffWorkInfoHeaderList,1)
    }
  }

  //リンクダイアログの開閉処理
  const [LinkNumber, setLinkNumber] = useState(0);
  const [canOpenLinks, setCanOpenLink] = useState(false);
  const [LinkDate, setLinkDate] = useState(null);
  const selectFunction=(e,data)=>{
    e.persist()
    var count = 0
    dateDataList.map((date)=>{
      if(date.dateValue == data.dateValue) {
        setLinkNumber(count)
        setLinkDate(date.dateValue)
        if(LinkNumber==count)setCanOpenLink(!canOpenLinks)
        else setCanOpenLink(true)
      }
      count++
    })
  }
  const oncloseLink=()=>{
    setCanOpenLink(false)
  }

  const [openUpload, setOpenUpload] = useState(false);
  const handleCloseUpload = () => {
    setOpenUpload(false)
  }
  const onImport=()=>{
    setOpenUpload(true);
  }
  //インポート処理
  const onUpload=(uploadData,uploadRestData)=>{
    async function sendPost (requestBody, data) {
      var resultAttendance
      dispatch(SetLoadSpinner(true))
      console.log(data)
      resultAttendance = 
        await MultipartApi(`${DefaultUrl}/Import`,'POST', requestBody, data)
      
      if (!ErrorHandling(resultAttendance, alert)) {
        alert.success('保存しました')
        onSearch(null, pageType==0?1:0)
        handleCloseUpload()
      }else{
        dispatch(SetLoadSpinner(false))
      }
      
    }

    var dailyList= new Array();
    console.log(uploadData,uploadRestData)
    uploadData.forEach((workInfo)=>{
      let dailyRow = {
        orderId: workInfo["受注ID"],
        staffId: workInfo["スタッフID"], 
        shiftDt: workInfo["日付"],
        workDivision: workInfo["出勤区分"],
        workStartDt: workInfo["出勤時間"],
        workEndDt: workInfo["退勤時間"],
        restStart1Dt: (workInfo["休憩開始時間1"])?(workInfo["休憩開始時間1"]):'',
        restEnd1Dt: (workInfo["休憩終了時間1"])?(workInfo["休憩終了時間1"]):'',
        restStart2Dt: (workInfo["休憩開始時間2"])?(workInfo["休憩開始時間2"]):'',
        restEnd2Dt: (workInfo["休憩終了時間2"])? (workInfo["休憩終了時間2"]):'',
        restStart3Dt: (workInfo["休憩開始時間3"])?(workInfo["休憩開始時間3"]):'',
        restEnd3Dt: (workInfo["休憩終了時間3"])?(workInfo["休憩終了時間3"]):'',
        
        deleteRestList: new Array(),
        updateRestList: new Array(),
        addRestList: new Array(),

        updatedAt: '1900-01-01 00:00:00',
        remark: (workInfo["備考"]),
      }
      
      // var count = 0
      // uploadRestData.forEach((restInfo)=>{
      //   if(dailyRow.staffId !=  restInfo["スタッフID"]) return
      //   if(dailyRow.orderId !=  restInfo["受注ID"]) return
      //   if(dailyRow.shiftDt !=  restInfo["日付"]) return
      //   let restRow = {
      //     orderId: workInfo["受注ID"],
      //     staffId: workInfo["スタッフID"], 
      //     baseDt: workInfo["日付"],
      //     restNum: count,
      //     restStartDt:restInfo["休憩開始日時"],
      //     restEndDt:restInfo["休憩終了日時"],
      //     restTotalDt:parseFloat(calcRestHours( [restInfo["休憩開始日時"], restInfo["休憩終了日時"]]))*3600
      //   }
      //   restList.push(restRow.restStartDt)
      //   restList.push(restRow.restEndDt)
      //   count++;
      //   dailyRow.addRestList.push(restRow)
      // })
      var count = 0
      var restList = new Array();
      while(true){
        count++;
        console.log("休憩開始時間"+(count))
        if(!workInfo["休憩開始時間"+(count)] || !workInfo["休憩終了時間"+(count)])break
        if(workInfo["休憩開始時間"+(count)] =="" || workInfo["休憩終了時間"+(count)]=="")break
        
        let restRow = {
          orderId: workInfo["受注ID"],
          staffId: workInfo["スタッフID"], 
          baseDt: workInfo["日付"],
          restNum: count-1,
          restStartDt:workInfo["休憩開始時間"+(count)],
          restEndDt:workInfo["休憩終了時間"+(count)],
          restTotalDt:parseFloat(calcRestHours( [workInfo["休憩開始時間"+(count)], workInfo["休憩終了時間"+(count)]]))*3600
        }
        restList.push(restRow.restStartDt)
        restList.push(restRow.restEndDt)
        
        dailyRow.addRestList.push(restRow)
      }
      {
          
          if(dailyRow.addRestList.length==0) restList = [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, 
            dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt]
          //勤務時間,休憩時間計算
          let result = calcWorkingRestFreeSeconds([dailyRow.workStartDt, dailyRow.workEndDt], restList, 28800)

          dailyRow.workTotalDt = result[0]
          dailyRow.restTotalDt = result[1]

          dailyRow.overTotalDt = result[2]
          dailyRow.nightTime = result[3]
  
          dailyList.push(dailyRow)
      }
    })
    const submitData = new window.FormData()
    var updateData =
    {
      shiftDailyList: dailyList
    }
    if(dailyList.length==0)
    {
        alert.error("更新するデータがありません")
        return
    }
    console.log(dailyList)
    submitData.append('formData', new window.Blob([JSON.stringify(updateData)], { type: 'application/json' }))
    sendPost(submitData, updateData)
  }

  if(!postList){
    setPost()
  }
  if (!grants) return null
  return (
      <ShiftPanelWrap id='ShiftWrap' viewFunction={canOpenDialog} viewLinkFunction={canOpenLinks} viewLinkNumber={LinkNumber}>
        <Search
          title= {grants.functionMenuName}
          showButton
          onClick={() => onSearch(null, pageType==0?1:0)}
          onClickOption={(e) => onClickOptionBtn(e)}
          onClickFunction={(e)=>onClickFunction(e)}
          onKeyDown={(e) => onKeyDown(e)}
          shiftDateRef={shiftDateRef}
          staffIdRef={staffIdRef}
          staffNameRef={staffNameRef}
          viewSubSearch={viewSubSearch}
          viewFunction={viewFunction}
          grants={grants}
          onExport={() => onExport()}
          postList={postList}
          postId={postId}
          setPostId={setPostId}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          postType={postType}
          setPostType={setPostType}
          onPageTypeChangeClick={() => onPageChangeLabelClick()}
          onImport={(uploadData)=>onUpload(uploadData)}
        >
        </Search>
        <ShiftFiltersPanelWrap>
        <IconImg src={IconFilter}/>
        {/* <Select
                name={'shift_filter'}
                width='15rem'
                onChange={(e) => handleChange(e)}
              >{icons}</Select> */}
        <Tooltip title={'フィルタメニュー'}>
        <FilterPanelWrap onClick={onClickLabel}>
        {shiftFilter!='all' &&
            <img src={shiftFilter} width="20px" height="20px" style={{marginLeft: '12px',marginTop: '8px'}}/>
        }
        {shiftFilter=='all' &&
            <div 
            style={{width:"20px" ,height:"20px" ,marginLeft: '12px',marginTop: '12px',textAlign:'center'}}>-</div>
        }
            <div style={{width:'11rem',marginLeft: '12px',marginTop: '12px'}}>{filterText}</div>
        </FilterPanelWrap>
        </Tooltip>
        {/* <FilterDialogWrap onClick={onClickLabel}>凡例</FilterDialogWrap> */}
        <div></div>
        <ShiftCountPanelWrap>
              <label>検索ヒット件数:{staffCount}件</label>
        </ShiftCountPanelWrap>
        </ShiftFiltersPanelWrap>
        <ShiftFlexWrap>
            <MonthTable
              dateDataList={dateDataList}
              staffWorkInfoList={staffWorkInfoList}
              staffWorkInfoAllList={staffWorkInfoHeaderList}
              updatedShiftFunc={updatedShiftFunc}
              defaultUrl={DefaultUrl}
              shiftFilter={shiftFilter}
              workDivisions={workDivisions}
              selectFunction={(e,data)=>selectFunction(e,data)}
            >
            </MonthTable>
        </ShiftFlexWrap>
        {
          staffWorkInfoList.length>0 &&
          <ShiftPagingPanelWrap>
          {/* <ShiftPagingChangePanelWrap onClick={() => onPageChangeLabelClick()}>表示切替</ShiftPagingChangePanelWrap> */}
          { pageType==0 &&
            <ShiftPageButtonWrap>       
            <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
            <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
            <ShiftPagingIconPanelWrap>{pageNum}</ShiftPagingIconPanelWrap>
            <ShiftPagingIconPanelWrap>/</ShiftPagingIconPanelWrap>
            <ShiftPagingIconPanelWrap>{pageLast}</ShiftPagingIconPanelWrap>
            <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
            <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
            
            </ShiftPageButtonWrap>
          }
          </ShiftPagingPanelWrap>
        }
        {/* <FilterDialog
        open={canOpenDialog}
        onCloseFunc={handleClose}
        values={iconImages}
        >
        </FilterDialog> */}
        <li className='legend'>
          <FunctionLegendContent>
            <label style={{height: '12px'}}></label>
            <FunctionContentRow onClick={()=>imgeBoxClick('all','全て')}>
              <div style={{textAlign:'center',width:"20px", height:"20px", marginLeft: '12px'}}>-</div>
              <label style={{width:'11rem',marginLeft: '12px',marginTop: '1px'}}>全て</label>
            </FunctionContentRow>
            <FunctionContentRow onClick={()=>imgeBoxClick(IconShiftNormalImg,'シフトと実績に差異なし')}>
              <img src={IconShiftNormalImg} width="20px" height="20px" style={{marginLeft: '12px'}}/>
              <label style={{width:'11rem',marginLeft: '12px',marginTop: '1px'}}>シフトと実績に差異なし</label>
            </FunctionContentRow>
            <FunctionContentRow onClick={()=>imgeBoxClick(IconShiftDiffImg,'シフトと実績に差異あり')}>
              <img src={IconShiftDiffImg} width="20px" height="20px" style={{marginLeft: '12px'}}/>
              <label style={{width:'11rem',marginLeft: '12px',marginTop: '1px'}}>シフトと実績に差異あり</label>
            </FunctionContentRow>
            <FunctionContentRow onClick={()=>imgeBoxClick(IconShiftAbsenceImg,'シフトのみ（実績なし）')}>
              <img src={IconShiftAbsenceImg} width="20px" height="20px" style={{marginLeft: '12px'}}/>
              <label style={{width:'11rem',marginLeft: '12px',marginTop: '1px'}}>シフトのみ（実績なし）</label>
            </FunctionContentRow>
            <FunctionContentRow onClick={()=>imgeBoxClick(IconShiftImg,'シフトの予定')}>
              <img src={IconShiftImg} width="20px" height="20px" style={{marginLeft: '12px'}}/>
              <label style={{width:'11rem',marginLeft: '12px',marginTop: '1px'}}>シフトの予定</label>
            </FunctionContentRow>
            <label style={{height: '12px'}}></label>
          </FunctionLegendContent> 
        </li>
        <li className='links' onMouseLeave={oncloseLink}>
          <FunctionLinkContent>
            <label style={{height: '12px'}}></label>
            <FunctionLinkContentRow>
            <Link to={{ pathname: "/shiftGanttChart", state: LinkDate }}><div>{'シフトガントチャート'}</div></Link>
            </FunctionLinkContentRow>
            <FunctionLinkContentRow>
            </FunctionLinkContentRow>
            <label style={{height: '12px'}}></label>
          </FunctionLinkContent> 
        </li>
        {/* <ImportDialog open={openUpload} onCloseFunc={()=>handleCloseUpload()} onUpload={(uploadData,uploadRestData)=>onUpload(uploadData,uploadRestData)}/> */}
      </ShiftPanelWrap>
  )
}

export default Shift
