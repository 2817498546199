import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import DailyTableRow from './PaymentDuductionTableRow'
const TableBody = ({
  register, getValues, setValue, watch, reset, errors,
  defaultUrl, workInfoList,workDivisions,handleOpenPunchHistoryFunc,
  passDivisions,
  transportationPublic,
  transportationPraivate,
  gasCost
}) => {

  return (
    <tbody>
      {workInfoList && workInfoList.map((workInfo) => {
        return (
          <DailyTableRow key={"top_" + workInfo.staffId}
            register={register}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            reset={reset}
            errors={errors}
            workDivisions={workDivisions}
            defaultUrl={defaultUrl}
            workInfo={workInfo}
            passDivisions={passDivisions}
            transportationPublic={transportationPublic}
            transportationPraivate={transportationPraivate}
            gasCost={gasCost}
          >
          </DailyTableRow>
        )
      })}
    </tbody>
  )
}

TableBody.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
}

export default TableBody