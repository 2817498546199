import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {ColumnStaffTH,ColumnTimeTH,RowHeaderTop,ColumnSpan,ColumnCountTH}from '../../Wrap/SalariedTableWrap'

const CellWrap = styled.th`
  width: calc(60rem + 11px);
  height: 3rem;
  ${props => props.addStyle}
`

const CellPanel = styled.th`
  display: flex;
  flex-direction: column;
`
const CellMonthPanel = styled.th`
  flex-direction: column;
`
const Column = styled.th`
  text-align: center;
  vertical-align:middle;
  line-height:1.5rem; 
  height: 1.5rem;
`
const ColumnMonth = styled.th`
  text-align: center;
  vertical-align:middle;
  width: 5rem;
  height: 1.5rem;
`
const SalariedTableHeader = ({ staffWorkInfoList, selectFunction }) => {

  const enterDt = '入社年月日\n(勤続年数)'
  const persent = '有給取得率\n(%)'
  return (
    <thead>
      <tr>
        <RowHeaderTop>
        <ColumnStaffTH>スタッフ名</ColumnStaffTH>
        <ColumnTimeTH><ColumnSpan>{enterDt}</ColumnSpan></ColumnTimeTH>
        <ColumnCountTH><ColumnSpan>{persent}</ColumnSpan></ColumnCountTH>
        <ColumnCountTH>有給取得日数</ColumnCountTH>
        <ColumnCountTH>有給残日数</ColumnCountTH>
        <ColumnTimeTH>有給基準日</ColumnTimeTH>
        <ColumnTimeTH>有給期限日</ColumnTimeTH>
        <CellWrap>
            <CellPanel>
                <Column>有給基準日からの経過月数</Column>
            </CellPanel>
            <CellMonthPanel>
                <ColumnMonth>1ヶ月</ColumnMonth>
            </CellMonthPanel>
            <CellMonthPanel>
                <ColumnMonth>2ヶ月</ColumnMonth>
            </CellMonthPanel>
            <CellMonthPanel>
                <ColumnMonth>3ヶ月</ColumnMonth>
            </CellMonthPanel>
            <CellMonthPanel>
                <ColumnMonth>4ヶ月</ColumnMonth>
            </CellMonthPanel>
            <CellMonthPanel>
                <ColumnMonth>5ヶ月</ColumnMonth>
            </CellMonthPanel>
            <CellMonthPanel>
                <ColumnMonth>6ヶ月</ColumnMonth>
            </CellMonthPanel>
            <CellMonthPanel>
                <ColumnMonth>7ヶ月</ColumnMonth>
            </CellMonthPanel>
            <CellMonthPanel>
                <ColumnMonth>8ヶ月</ColumnMonth>
            </CellMonthPanel>
            <CellMonthPanel>
                <ColumnMonth>9ヶ月</ColumnMonth>
            </CellMonthPanel>
            <CellMonthPanel>
                <ColumnMonth>10ヶ月</ColumnMonth>
            </CellMonthPanel>
            <CellMonthPanel>
                <ColumnMonth>11ヶ月</ColumnMonth>
            </CellMonthPanel>
            <CellMonthPanel>
                <ColumnMonth>12ヶ月</ColumnMonth>
            </CellMonthPanel>
        </CellWrap>
        </RowHeaderTop>
      </tr>
    </thead>
  )
}

SalariedTableHeader.propTypes = {
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
}

export default SalariedTableHeader
