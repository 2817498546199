import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CellWrap = styled.div`
  vertical-align:middle;
  ${props => props.addStyle}
  height:2rem;
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
`

const Column = styled.th`
  text-align: right;
  padding-right: 1rem;
  height:1rem;
`

const MonthTableHeaderTimeCell = ({ shiftWorkTime, attendanceWorkTime }) => {

  return (
    <CellWrap>
      <CellPanel>
        <Column>{shiftWorkTime}</Column>
        <Column>{attendanceWorkTime}</Column>
      </CellPanel>
    </CellWrap>
  )
}

MonthTableHeaderTimeCell.propTypes = {
    shiftWorkTime: PropTypes.string,
    attendanceWorkTime: PropTypes.string
}

export default MonthTableHeaderTimeCell
