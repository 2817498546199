import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

//import Tooltip from '@material-ui/core/Tooltip'

import IconExpendImg from '../../../../image/expand-icon.png'
import { InputGroup, HiddenInput } from '../../../Common/CommonInputGroup'
import { ColumnDateTD, ColumnWeekTD, ColumnPublicHolidayTD, ColumnNoteTD, ColumnButtonsTD } from '../Wrap/CalendarTableWrap'

const CalendarTableRow = ({ 
    register, getValues, setValue, watch, reset, errors, 
    dateData, selectFunction,
     }) => {

  const elementId = dateData.dateValue

  const [imgVisible,setImgVisible] = useState(false)
  const [color,setColor] = useState('')
  // 勤怠情報切り替え時
  useEffect(() => {
    // 勤怠情報が変更された場合のみ実行

    if (dateData) {
        /*
      if (dailyWorkInfo.workInfoList.length === 1) {
        var workInfo = dailyWorkInfo.workInfoList[0]
        setValue('orderId_' + elementId, dateData.orderId)
        setValue('staffId_' + elementId, workInfo.staffId)
        setValue('baseDt_' + elementId, workInfo.baseDt)
        setValue('attendanceUpdatedAt_' + elementId, workInfo.attendanceUpdatedAt)
    
        setValue('attendanceWorkDivision_' + elementId, workInfo.attendanceWorkDivision)
        setValue('attendanceRemark_' + elementId, workInfo.attendanceRemark)
      }
      */
      //console.log(dateData)
      setColor(weekColor(dateData))
      setImgVisible(dateData.publicHoliday==1)
      setValue('publicHoliday_'+elementId, dateData.publicHoliday)
      setValue('note_' + elementId, dateData.note)
    }
  // eslint-disable-next-line
  }, [dateData])

  const formatDate = (dateData) => {
    var date = new Date(dateData.dateValue);
    return ('00' + (date.getMonth() + 1)).slice(-2) + '/' + ('00' + date.getDate()).slice(-2)
  }

  const weekColor = (dateData) => {
    switch(dateData && dateData.publicHoliday) {
        case '0':
            switch(dateData.week)
            {
                case '土':
                    return '#00F'
                case '日':
                    return '#F00'
            }
            return '#000'
        case '1':
            return '#F00'
    }

    return '#000'
  }


  const createElementDate = (dateData) => {

    return <>{formatDate(dateData)}</>

  }

  const createElementWeek = (dateData) => {
    //return <><span style={{ color: weekColor(dateData) }}>{dateData.week}</span></>
    return <><span style={{ color: color }}>{dateData.week}</span></>
  }

  const createElementPublicHoliday = (dateData) => {
    return <><img src={IconExpendImg} width="24px" height="24px" style={{display:(imgVisible)?'':'none'}}  /></>

    //return <></>
  }

  const createElementButtons = (dateData) => {
      /*
    if (workInfoList && workInfoList.length === 1) {
        return <>
            <Button margin='auto' padding='0' width='3rem' name='履歴' onClick={() => onShowPunchHistoryModal(workInfoList[0])}/>
           </>
    }
    else if (workInfoList && workInfoList.length > 1) {
        return <>
            <Button margin='auto' padding='0' width='3rem' name='詳細' onClick={() => onShowDailyModal(workInfoList)}/>
           </>
    }
    */
    return <></>
  }

  const onClickImg =(dateData)=>{
    setImgVisible(!imgVisible)
    setColor(weekChengeColor(dateData))
    setValue('publicHoliday_'+elementId, imgVisible?0:1)
  }
  const weekChengeColor = (dateData) => {
    switch(dateData && (imgVisible?0:1)) {
        case 0:
            switch(dateData.week)
            {
                case '土':
                    return '#00F'
                case '日':
                    return '#F00'
            }
            return '#000'
        case 1:
            return '#F00'
    }

    return '#000'
  }

   const createElementNote = (dateData) => {
    if (dateData) {
      return <>
          <InputGroup
              name={'note_'+ elementId}
              width='88rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'left', paddingRight: '0.5rem' }}
              disabled={false}  
          />
         </>
    }

    return <></>
  }

  return (
    <tr>
      <ColumnDateTD>{createElementDate(dateData)}</ColumnDateTD>
      <ColumnWeekTD>{createElementWeek(dateData)}</ColumnWeekTD>
      <ColumnPublicHolidayTD onClick={()=>onClickImg(dateData)}>{createElementPublicHoliday(dateData)}</ColumnPublicHolidayTD>
      <ColumnNoteTD>{createElementNote(dateData)}</ColumnNoteTD>
      <ColumnButtonsTD>{createElementButtons(dateData)}</ColumnButtonsTD>

      <HiddenInput name={'publicHoliday_'+elementId} register={register} defaultValue={dateData.publicHoliday}></HiddenInput>
    </tr>
  )
}

CalendarTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  dateData: PropTypes.object,
  selectFunction: PropTypes.func,
}

export default CalendarTableRow
