import React from 'react'
import {formatRound48Time,secToTime} from '../../Common/Utilties/LaborUtility'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/Atoms/NormalButton'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const FormWrap = styled.form`
  display: flex;
`


const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 1vw;
`

export const IconImg = styled.img`
  align-items: left;
  width: 20px;
  height: 20px;
`
export const NotIconImg = styled.div`
  align-items: center;
  width: 20px;
  height: 20px;
  text-align:center;
`


const TableWrap = styled.table`
  font-size: 14px;
  line-height: 32px;
  justify-content: center;
  border-collapse:separate;
  border-spacing: 1px;
  margin-top: 1rem;
  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(21rem + 3px);
  }

  tbody {
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(28Vh);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
    height 2rem;
    text-align:center;
    background-color: #649cdf;    
  }

  td {
    height 2rem;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

const EmploymentDialog = ({ open,onCloseFunc, employment }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='雇用契約'
      aria-describedby='雇用契約ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap>
    <div className={classes.paper}>
      <TableWrap visible={true}>
        <thead>
            <tr>
                <th style={{width:'21rem'}}>雇用契約</th>
            </tr>
        </thead>
        <thead>
            <tr>
                <th style={{width:'7rem'}}>勤続年数</th>
                <th style={{width:'7rem'}}>週所定労働時間</th>
                <th style={{width:'7rem'}}>週所定労働日数</th>
            </tr>
        </thead>
        <tbody>
        {employment.employment && employment.employment.map((employ) => {
        return (
            <tr key={employ.staffId+'_'+employ.lengthServiceYears}>
                <td style={{width:'7rem',textAlign:'center'}}>{employ.lengthServiceYears}年</td>
                <td style={{width:'7rem',textAlign:'center'}}>{formatRound48Time(secToTime(employ.scheduleWeekTotalDt),1)}</td>
                <td style={{width:'7rem',textAlign:'center'}}>{employ.scheduleWeekWorkCt}日</td>
            </tr>
        )
        })}
        </tbody>
      </TableWrap>
      <ButtonsWrap>
        <Button name='閉じる' padding='0px' onClick={onCloseFunc} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

EmploymentDialog.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedAttendanceFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    iconName: PropTypes.string,
    text: PropTypes.string,
    dailyWorkInfoList: PropTypes.array
}

export default EmploymentDialog