import styled from 'styled-components'

const MasterDetailWrap = styled.div` 
  display: ${props => props.isView ? 'block' : 'none'};
`

export const MasterDetailFormWrap = styled.div` 
  width: 80vw;
  margin: ${props => props.margin || '0 0 12px 0'};
  ul {
    width: 72vw;
    margin: ${props => props.ulMargin || '0 0 4px 0'};
    position: ${props => props.position || 'relative'};
    >li {
      display: flex;
      height: 60px;
      >label {
        width: 10vw;
        min-width: 10vw;
        background: #CCC;
        display: block;
        font-weight: bold;
        text-align: center;
        line-height: 60px;
      }
      >label.required:after {
        margin-left: 0.5em;
        padding: 2px 4px 2px 4px;
        border-radius: 4px;
        font-size: 0.6em;
        color: white;
        background-color: #C44;
        content: "必須";
      }
    
      >div {
        display: flex;
        width: 64vw;
        background: #EEEEEE;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
`

export default MasterDetailWrap
