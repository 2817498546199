import styled from 'styled-components'

export const DashboardBaseFormRow = styled.form`
  display: flex;
`

export const DashboardBaseFormRowScroll = styled.form`
  display: flex;
  width:1870px;
  height:750px;
  :hover {
    overflow-y: auto;
    overflow-x: auto;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-track {
    border:none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }
`

export const DashboardTitle = styled.div`
text-align:center;
font-weight:bold;
font-size:20px;
`

const DashboardBaseFormWrap = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: 5% top;
`

export const DashboardFlexWrap = styled.div`
    display: flex;
    padding: 1vw;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 20%;
    background-position: 5% top;
`

export default DashboardBaseFormWrap