import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {RowHeaderTop}from '../../Wrap/BulkEditTableWrap'
import { InputGroup } from '../../../Common/CommonInputGroup'
import Button from '../../../Common/Atoms/NormalButton'
import { diff48Time, calcWorkingSeconds ,formatRound48Time} from '../../../Common/Utilties/LaborUtility'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  display: flex;
  padding: 1vw;
  font-size: 18px;
  justify-content: center;
`
const FormWrap = styled.form`
  display: flex;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 1vw;
`

const TableWrap = styled.table`
  font-size: 16px;
  line-height: 32px;
  justify-content: center;
  border-collapse:separate;
  border-spacing: 1px;
  margin-top: 1rem;
  thead, tbody {
    display:block;
  }

  thead {
    width: calc(20rem + 3px);
  }

  tbody {
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(36Vh);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
    height 2rem;
    text-align:center;
  }

  td {
    height 2.5rem;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

const BulkEditDialog = ({ open, values,onCloseFunc, updatedAttendanceFunc, defaultUrl, staffInfoList,setStaffInfoList }) => {
  const [date, setData] = useState('')
  const [staffId, seStaffId] = useState('')
  const [staffName, seStaffName] = useState('')
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();
  const [rows, setRows] = useState(<></>)
  const alert = useAlert()
  const dispatch = useDispatch()

  const [val, setVal] = useState('agreement');

  const initialVal = 
  { shiftWorkStartDt: false, shiftWorkEndDt: false, 
    shiftRestStart1Dt: false , shiftRestEnd1Dt: false,
    shiftRestStart2Dt: false , shiftRestEnd2Dt: false,
    shiftRestStart3Dt: false , shiftRestEnd3Dt: false,};
  const [checkVal, setCheckVal] = useState(initialVal);
  const handleCheckChange = e => {
    e.persist()
    // const newVal = Object.assign({}, val, {
    //    [e.target.name]: !checkVal[e.target.name]
    // });
    const newVal=JSON.parse(JSON.stringify(checkVal)) 
    newVal[e.target.name] = !newVal[e.target.name]

    setCheckVal(newVal);
  };

  const handleChange = e => {
    setVal(e.target.value)
    
    setValue('shiftWorkStartDt', '')
    setValue('shiftWorkEndDt', '')

    setValue('shiftRestStart1Dt', '')
    setValue('shiftRestEnd1Dt', '')
    setValue('shiftRestStart2Dt', '')
    setValue('shiftRestEnd2Dt', '')
    setValue('shiftRestStart3Dt', '')
    setValue('shiftRestEnd3Dt', '')

  };
  const createElementCheck = (prefix) => {
    return (          
      <div style={{textAlign:'center'}}>
      <input
        type="checkbox"
        name={prefix}
        checked={val[prefix]}
        onChange={handleCheckChange}
      />
      </div>
    )
  }
  const createElementTime = (prefix, workInfoList) => {
        return <>
            <InputGroup
                name={prefix}
                width='3.5rem'
                margin='auto'
                placeholder=''
                register={register}
                errors={errors}
                onBlur={onBlurTime}
                style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                disabled={val === 'agreement' || !checkVal[prefix]}
            />
           </>
  }
  const onBlurTime = ( event ) => {
    let value = event.target.value
    //日時の分単位を指定分で丸める
    let fractionUnit = parseInt(1)
    //value = format48Time(value)
    let name = event.target.name 
    if(name.indexOf('WorkEnd') != -1 || name.indexOf('RestStart') != -1)
    {
      value = formatRound48Time(value,fractionUnit*(-1))
    }
    else
    {
      value = formatRound48Time(value,fractionUnit)
    }
    event.target.value = value
  }

    //ダイアログ反映ボタン処理
    const handleAgreement = () => {

        var tmp = staffInfoList.map((staff)=>
        {
          if(staff.checked){ 
            staff.isDiff=true
            if(val === 'agreement'){          
              let fractionUnit = parseInt(staff.shiftBulkList[0].attendanceFractionUnit)
              var newList =
              {
                shiftWorkStartDt:checkVal['shiftWorkStartDt']?diff48Time('1900-01-01',staff.shiftBulkList[0].agreementWorkStartDt):null,
                shiftWorkEndDt:checkVal['shiftWorkEndDt']?diff48Time('1900-01-01',staff.shiftBulkList[0].agreementWorkEndDt):null,
                shiftRestStart1Dt:checkVal['shiftRestStart1Dt']?diff48Time('1900-01-01',staff.shiftBulkList[0].agreementRestStart1Dt):null,
                shiftRestEnd1Dt:checkVal['shiftRestEnd1Dt']?diff48Time('1900-01-01',staff.shiftBulkList[0].agreementRestEnd1Dt):null,
                shiftRestStart2Dt:checkVal['shiftRestStart2Dt']?diff48Time('1900-01-01',staff.shiftBulkList[0].agreementRestStart2Dt):null,
                shiftRestEnd2Dt:checkVal['shiftRestEnd2Dt']?diff48Time('1900-01-01',staff.shiftBulkList[0].agreementRestEnd2Dt):null,
                shiftRestStart3Dt:checkVal['shiftRestStart3Dt']?diff48Time('1900-01-01',staff.shiftBulkList[0].agreementRestStart3Dt):null,
                shiftRestEnd3Dt:checkVal['shiftRestEnd3Dt']?diff48Time('1900-01-01',staff.shiftBulkList[0].agreementRestEnd3Dt):null,
                baseDt:'1900-01-01',
                shiftWorkTotalDt:0,
                shiftRestTotalDt:0,
                shifOverTotalDt:0,
              }
              newList.shiftWorkStartDt = formatRound48Time(newList.shiftWorkStartDt,fractionUnit)
              newList.shiftWorkEndDt = formatRound48Time(newList.shiftWorkEndDt,fractionUnit*(-1))

              newList.shiftRestStart1Dt = formatRound48Time(newList.shiftRestStart1Dt ,fractionUnit*(-1))
              newList.shiftRestEnd1Dt = formatRound48Time(newList.shiftRestEnd1Dt ,fractionUnit)
              newList.shiftRestStart2Dt = formatRound48Time(newList.shiftRestStart2Dt ,fractionUnit*(-1))
              newList.shiftRestEnd2Dt = formatRound48Time(newList.shiftRestEnd2Dt ,fractionUnit)
              newList.shiftRestStart3Dt = formatRound48Time(newList.shiftRestStart3Dt ,fractionUnit*(-1))
              newList.shiftRestEnd3Dt = formatRound48Time(newList.shiftRestEnd3Dt ,fractionUnit)

              console.log(staff.shiftBulkList)
              //差異チェック
              //if(checkUpdate(staff,newList))
              {
                //勤務時間,休憩時間計算
                let result = calcWorkingSeconds([newList.shiftWorkStartDt,newList.shiftWorkEndDt ], 
                  [newList.shiftRestStart1Dt,newList.shiftRestEnd1Dt,
                  newList.shiftRestStart2Dt,newList.shiftRestEnd2Dt,
                  newList.shiftRestStart3Dt,newList.shiftRestEnd3Dt,], 
                  staff.shiftBulkList[0].attendanceBaseTime)
                newList.shiftWorkTotalDt = result[0]
                newList.shiftRestTotalDt = result[1]
                newList.shifOverTotalDt = result[2]
              
                 staff.shiftNewList=newList
              }
            }
            else{          
              let fractionUnit = parseInt(staff.shiftBulkList[0].attendanceFractionUnit)
              var newList =
              {
                shiftWorkStartDt:checkVal['shiftWorkStartDt']?getValues('shiftWorkStartDt'):null,
                shiftWorkEndDt:checkVal['shiftWorkEndDt']?getValues('shiftWorkEndDt'):null,
                shiftRestStart1Dt:checkVal['shiftRestStart1Dt']?getValues('shiftRestStart1Dt'):null,
                shiftRestEnd1Dt:checkVal['shiftRestEnd1Dt']?getValues('shiftRestEnd1Dt'):null,
                shiftRestStart2Dt:checkVal['shiftRestStart2Dt']?getValues('shiftRestStart2Dt'):null,
                shiftRestEnd2Dt:checkVal['shiftRestEnd2Dt']?getValues('shiftRestEnd2Dt'):null,
                shiftRestStart3Dt:checkVal['shiftRestStart3Dt']?getValues('shiftRestStart3Dt'):null,
                shiftRestEnd3Dt:checkVal['shiftRestEnd3Dt']?getValues('shiftRestEnd3Dt'):null,
                baseDt:staff.dateValue,
                shiftWorkTotalDt:0,
                shiftRestTotalDt:0,
                shifOverTotalDt:0,
              }
              
              newList.shiftWorkStartDt = formatRound48Time(newList.shiftWorkStartDt,fractionUnit)
              newList.shiftWorkEndDt = formatRound48Time(newList.shiftWorkEndDt,fractionUnit*(-1))

              newList.shiftRestStart1Dt = formatRound48Time(newList.shiftRestStart1Dt ,fractionUnit*(-1))
              newList.shiftRestEnd1Dt = formatRound48Time(newList.shiftRestEnd1Dt ,fractionUnit)
              newList.shiftRestStart2Dt = formatRound48Time(newList.shiftRestStart2Dt ,fractionUnit*(-1))
              newList.shiftRestEnd2Dt = formatRound48Time(newList.shiftRestEnd2Dt ,fractionUnit)
              newList.shiftRestStart3Dt = formatRound48Time(newList.shiftRestStart3Dt ,fractionUnit*(-1))
              newList.shiftRestEnd3Dt = formatRound48Time(newList.shiftRestEnd3Dt ,fractionUnit)

              console.log(staff.shiftBulkList)
              //差異チェック
              //if(checkUpdate(staff,newList))
              {
                //勤務時間,休憩時間計算
                let result = calcWorkingSeconds([newList.shiftWorkStartDt,newList.shiftWorkEndDt ], 
                    [newList.shiftRestStart1Dt,newList.shiftRestEnd1Dt,
                    newList.shiftRestStart2Dt,newList.shiftRestEnd2Dt,
                    newList.shiftRestStart3Dt,newList.shiftRestEnd3Dt,], 
                    staff.shiftBulkList[0].attendanceBaseTime)
                newList.shiftWorkTotalDt = result[0]
                newList.shiftRestTotalDt = result[1]
                newList.shifOverTotalDt = result[2]
              
                 staff.shiftNewList=newList
              }
            }
            console.log(newList)
          } 
          return staff
        })
        setCheckVal(initialVal);
        setStaffInfoList(tmp)
        onCloseFunc()
      }
    //ダイアログ無反映ボタン処理
    const handleUnAgreement = () => {

      var tmp = staffInfoList.map((staff)=>
      {
        if(staff.checked){ 
          let fractionUnit = parseInt(staff.shiftBulkList[0].attendanceFractionUnit)
          var newList =
          {
            shiftWorkStartDt:null,
            shiftWorkEndDt:null,
            shiftRestStart1Dt:null,
            shiftRestEnd1Dt:null,
            shiftRestStart2Dt:null,
            shiftRestEnd2Dt:null,
            shiftRestStart3Dt:null,
            shiftRestEnd3Dt:null,
            baseDt:'1900-01-01',
            shiftWorkTotalDt:undefined,
            shiftRestTotalDt:undefined,
            shifOverTotalDt:undefined,
          }
          newList.shiftWorkStartDt = formatRound48Time(newList.shiftWorkStartDt,fractionUnit)
          newList.shiftWorkEndDt = formatRound48Time(newList.shiftWorkEndDt,fractionUnit*(-1))

          newList.shiftRestStart1Dt = formatRound48Time(newList.shiftRestStart1Dt ,fractionUnit*(-1))
          newList.shiftRestEnd1Dt = formatRound48Time(newList.shiftRestEnd1Dt ,fractionUnit)
          newList.shiftRestStart2Dt = formatRound48Time(newList.shiftRestStart2Dt ,fractionUnit*(-1))
          newList.shiftRestEnd2Dt = formatRound48Time(newList.shiftRestEnd2Dt ,fractionUnit)
          newList.shiftRestStart3Dt = formatRound48Time(newList.shiftRestStart3Dt ,fractionUnit*(-1))
          newList.shiftRestEnd3Dt = formatRound48Time(newList.shiftRestEnd3Dt ,fractionUnit)
          console.log(staff.shiftBulkList)
          {
             staff.shiftNewList=newList
          }
          staff.isDiff=true
        } 
        return staff
      })
      setCheckVal(initialVal)
      setStaffInfoList(tmp)
      onCloseFunc()
    }
    
    //ダイアログキャンセルボタン処理
    const onClose = () => {
      setCheckVal(initialVal);
      onCloseFunc()
    }

  return (
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='凡例'
      aria-describedby='凡例表示ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap>
    <div className={classes.paper}>
      <TitleWrap>
        <input
          type="radio"
          value="agreement"
          onChange={handleChange}
          checked={val === 'agreement'}
        />
        契約
        <div style={{marginLeft:'3rem'}}></div>
        <input
          type="radio"
          value="input"
          onChange={handleChange}
          checked={val === 'input'}
        />
        手入力
      </TitleWrap>
      <TableWrap>
        <thead>
            <tr>
                <RowHeaderTop>
                <th style={{width:'6.5rem'}}>更新対象</th>
                <th style={{width:'6.5rem'}}>項目</th>
                <th style={{width:'6.5rem'}}>値</th>
                </RowHeaderTop>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style={{width:'6.5rem'}}>{createElementCheck('shiftWorkStartDt')}</td>
                <td style={{width:'6.5rem',textAlign:'center'}}>出勤</td>
                <td style={{width:'6.5rem'}}>{createElementTime('shiftWorkStartDt')}</td>
            </tr>
            <tr>
                <td style={{width:'6.5rem'}}>{createElementCheck('shiftWorkEndDt')}</td>
                <td style={{width:'6.5rem',textAlign:'center'}}>退勤</td>
                <td style={{width:'6.5rem'}}>{createElementTime('shiftWorkEndDt')}</td>
            </tr>
            <tr>
                <td style={{width:'6.5rem'}}>{createElementCheck('shiftRestStart1Dt')}</td>
                <td style={{width:'6.5rem',textAlign:'center'}}>休始1</td>
                <td style={{width:'6.5rem'}}>{createElementTime('shiftRestStart1Dt')}</td>
            </tr>
            <tr>
                <td style={{width:'6.5rem'}}>{createElementCheck('shiftRestEnd1Dt')}</td>
                <td style={{width:'6.5rem',textAlign:'center'}}>休終1</td>
                <td style={{width:'6.5rem'}}>{createElementTime('shiftRestEnd1Dt')}</td>
            </tr>
            <tr>
                <td style={{width:'6.5rem'}}>{createElementCheck('shiftRestStart2Dt')}</td>
                <td style={{width:'6.5rem',textAlign:'center'}}>休始2</td>
                <td style={{width:'6.5rem'}}>{createElementTime('shiftRestStart2Dt')}</td>
            </tr>
            <tr>
                <td style={{width:'6.5rem'}}>{createElementCheck('shiftRestEnd2Dt')}</td>
                <td style={{width:'6.5rem',textAlign:'center'}}>休終2</td>
                <td style={{width:'6.5rem'}}>{createElementTime('shiftRestEnd2Dt')}</td>
            </tr>
            <tr>
                <td style={{width:'6.5rem'}}>{createElementCheck('shiftRestStart3Dt')}</td>
                <td style={{width:'6.5rem',textAlign:'center'}}>休始3</td>
                <td style={{width:'6.5rem'}}>{createElementTime('shiftRestStart3Dt')}</td>
            </tr>
            <tr>
                <td style={{width:'6.5rem'}}>{createElementCheck('shiftRestEnd3Dt')}</td>
                <td style={{width:'6.5rem',textAlign:'center'}}>休終3</td>
                <td style={{width:'6.5rem'}}>{createElementTime('shiftRestEnd3Dt')}</td>
            </tr>
        </tbody>
      </TableWrap>
      <ButtonsWrap>
        <Button name='反映' type='button' padding='0px' width='5rem' onClick={handleAgreement} />
        <Button name='稼働無反映' type='button' padding='0px' width='5rem' onClick={handleUnAgreement} />
        <Button name='キャンセル' padding='0px' width='5rem' onClick={onClose} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

BulkEditDialog.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedAttendanceFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    iconName: PropTypes.string,
    text: PropTypes.string,
    dailyWorkInfoList: PropTypes.array
}

export default BulkEditDialog