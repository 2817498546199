import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/Atoms/NormalButton'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  text-align:center;
  font-size:24px;
`
const TitleWrap = styled.div`
  display: flex;
  padding: 1vw;
`
const FormWrap = styled.form`
  display: flex;
`


const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 1vw;
`

export const IconImg = styled.img`
  align-items: left;
  width: 20px;
  height: 20px;
`
export const NotIconImg = styled.div`
  align-items: center;
  width: 20px;
  height: 20px;
  text-align:center;
`
const ContentNameWrap = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  border: solid 0.5px;
  border-right-style: ${props => props.borderRightStyle};
  border-bottom-style: ${props => props.borderBottomStyle};
  border-color: #808080;
`
const ContentNoneWrap = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
`
const TableWrap = styled.table`
  font-size: 16px;
  line-height: 32px;
  justify-content: center;
  border-collapse:separate;
  border-spacing: 1px;
  margin-top: 1rem;
  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(15rem + 3px);
  }

  tbody {
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(28Vh);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
    height 2rem;
    text-align:center;
  }

  td {
    height 2rem;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

const TitleTD =
styled.td`
    width:7rem;
    text-align:center;
`
const CellTD = styled.td`
    width:4rem;
    text-align:right;
`

const FilterDialog = ({ open, values,onCloseFunc, updatedAttendanceFunc, defaultUrl, dailyWorkInfoList }) => {
  const [date, setData] = useState('')
  const [staffId, seStaffId] = useState('')
  const [staffName, seStaffName] = useState('')
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();
  const [rows, setRows] = useState(<></>)
  const alert = useAlert()
  const dispatch = useDispatch()
  const [limitNote, setLimitNote] = useState({})
  const [limitWarning, setLimitWarning] = useState({})
  useEffect(() => {
    values.map((limit)=>{
        if(limit.limitWatchType == 1) setLimitNote(limit)
        if(limit.limitWatchType == 2) setLimitWarning(limit)
        console.log(limit)
    })
    
  }, [values])

  return (
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='凡例'
      aria-describedby='凡例表示ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap>
    <div className={classes.paper}>
      <ContentNoneWrap>
        判定の基準値の時間は、以下の通り。
      </ContentNoneWrap>
      <TableWrap visible={true}>
        <thead>
            <tr>
                <th style={{width:'7rem'}}></th>
                <th style={{width:'4rem',backgroundColor:'red'}}>注意</th>
                <th style={{width:'4rem',backgroundColor:'yellow'}}>警告</th>
            </tr>
        </thead>
        {limitNote.monthLimit &&
        <tbody>
            <tr>
                <TitleTD>当月</TitleTD>
                <CellTD>{limitNote.monthLimit.toFixed(2)}</CellTD>
                <CellTD>{limitWarning.monthLimit.toFixed(2)}</CellTD>
            </tr>
            <tr>
                <TitleTD>2ヶ月平均</TitleTD>
                <CellTD>{limitNote.twoMonthsAvgLimit.toFixed(2)}</CellTD>
                <CellTD>{limitWarning.twoMonthsAvgLimit.toFixed(2)}</CellTD>
            </tr>
            <tr>
                <TitleTD>3ヶ月平均</TitleTD>
                <CellTD>{limitNote.threeMonthsAvgLimit.toFixed(2)}</CellTD>
                <CellTD>{limitWarning.threeMonthsAvgLimit.toFixed(2)}</CellTD>
            </tr>
            <tr>
                <TitleTD>4ヶ月平均</TitleTD>
                <CellTD>{limitNote.fourMonthsAvgLimit.toFixed(2)}</CellTD>
                <CellTD>{limitWarning.fourMonthsAvgLimit.toFixed(2)}</CellTD>
            </tr>
            <tr>
                <TitleTD>5ヶ月平均</TitleTD>
                <CellTD>{limitNote.fiveMonthsAvgLimit.toFixed(2)}</CellTD>
                <CellTD>{limitWarning.fiveMonthsAvgLimit.toFixed(2)}</CellTD>
            </tr>
            <tr>
                <TitleTD>6ヶ月平均</TitleTD>
                <CellTD>{limitNote.sixMonthsAvgLimit.toFixed(2)}</CellTD>
                <CellTD>{limitWarning.sixMonthsAvgLimit.toFixed(2)}</CellTD>
            </tr>
            <tr>
                <TitleTD>年度</TitleTD>
                <CellTD>{limitNote.yearLimit.toFixed(2)}</CellTD>
                <CellTD>{limitWarning.yearLimit.toFixed(2)}</CellTD>
            </tr>
        </tbody>
      }
      </TableWrap>
      <ButtonsWrap>
        <Button name='閉じる' padding='0px' onClick={onCloseFunc} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

FilterDialog.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedAttendanceFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    iconName: PropTypes.string,
    text: PropTypes.string,
    dailyWorkInfoList: PropTypes.array
}

export default FilterDialog