import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import FUNC from '../../Common/FunctionEnum'
import { useForm } from 'react-hook-form'
import { GetApi,MultipartApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
import Select from '../../Common/Atoms/SelectAddValue'

import IconFilter from '../../../image/filter.png'

import IconPageNextImg from '../../../image/PageNext.png'
import IconPageBackImg from '../../../image/PageBack.png'
import IconPageLastImg from '../../../image/PageLast.png'
import IconPageTopImg from '../../../image/PageTop.png'
import IconQuestionImg from '../../../image/question-icon.png'
import Search from './Search'
import DashboardBaseWrap,{DashboardBaseFormRow,DashboardFlexWrap} from '../Wrap/DashboardBaseWrap'
import {DashboardBaseForm,CountPanelWrap,PagingPanelWrap,PagingChangePanelWrap,PagingIconImg,PageButtonWrap,PagingIconNextImg,PagingIconPanelWrap} from '../Wrap/DashboardTableWrap'
import OverTimeTable from './OverTimeTable/OverTimeTable'
import DashBoardSelect from '../DashboardSelect'
import OverTimeGraph from './OverTimeGraph/OverTimeGraph'
import FilterDialog from './FilterDialog'
import { Tooltip } from '@material-ui/core'
// import StaffBiometricPanelWrap,{StaffBiometricFlexWrap,CountPanelWrap,StaffBiometricPageButtonWrap,StaffBiometricPagingPanelWrap,StaffBiometricPagingChangePanelWrap,PagingIconImg,PagingIconNextImg,StaffBiometricPagingIconPanelWrap} from './Wrap/StaffBiometricPanelWrap'
// import StaffBiometricTable from './Molcules/StaffBiometricModel/StaffBiometricTable'
export const DefaultUrl = '/api/dashboardManagement/overTimeWork'

const OverTimeWork = (props) => {
  const [searchOption, setSearchOption] = useState({targetDt:'',postType:'',postId:'',departmentId:''})
  const { loginUserInfo } = useSelector(state => state)
  const targetDateRef = useRef()
  const [authStatus, setAuthStatus] = useState(0)
  const [staffInfoList, setStaffInfoList] = useState([])
  const [staffInfoAllList, setStaffInfoAllList] = useState([])
  const [LimitList, setLimitList] = useState([])
  const [dateDataList, setDateDataList] = useState([])
  const [pagenation, setPagenation] = useState({})
  const [staffCount, setStaffCount] = useState(0)
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const pageRecord = 12
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()
  const [serachType,setSerachType] = useState(1)//0:グラフ 1:リスト
  const [canOpenDialog, setCanOpenDialog] = useState(false);
  const limitTypeRef = useRef() //0:グラフ 1:リスト

  const dispatch = useDispatch()
  const alert = useAlert()
  const [dashboardDataList, setDashboardDataList] = useState([])
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.OVER_TIME_WORK)

  // 検索APIの実行
  const onSearch = async (createId, page) => {
    //グラフ
    const serachGraph = async(data)=>{
      console.log(data)
      var resultDepartment = null
      if(postType==0){
        resultDepartment = await GetApi(`/api/masterManagement/company/${data.postId}`)
      }
      else
      {
        resultDepartment = await GetApi(`/api/masterManagement/customer/${data.postId}`)
      }
      if (resultDepartment.errorDetail) {
        dispatch(SetLoadSpinner(false))
        ErrorHandling(resultDepartment, alert)
        return null
      }

      var hierarchyNo = 1
      if(data.departmentId != ''){
        hierarchyNo =Math.ceil(data.departmentId.replace(new RegExp(0 + "+$", "g"),'').length/3)
      }
      const dapartmentList =MakeItems(resultDepartment.data.departments,departmentId,hierarchyNo)
      if(dapartmentList.length==0){
        dapartmentList.push(data.departmentId)
      }
      const sendData = {
        targetDt: data.targetDt,
        postType:postType,
        postId:data.postId,
        departmentIds:dapartmentList,
        hierarcyNo:hierarchyNo,
      }
      console.log(sendData)

      const submitData = new window.FormData()
      submitData.append('formData', new window.Blob([JSON.stringify(sendData)], { type: 'application/json' }))
      const resultDashBoardData = await MultipartApi(`${DefaultUrl}/attendance/graph`,'PUT',submitData,sendData)
      if (resultDashBoardData.errorDetail) {
        dispatch(SetLoadSpinner(false))
        console.log(resultDashBoardData.errorDetail)
        ErrorHandling(resultDashBoardData, alert)
        return null
      }else{
        console.log(resultDashBoardData)
        makeDashboardData(resultDashBoardData.data.responseList,data.targetDt)
        dispatch(SetLoadSpinner(false))
      }
    }
    //グラフ(会社名が"全て"の場合)
    const serachGraphALL = async(data)=>{
      const sendData = {
        targetDt: data.targetDt,
        postType:postType,
        postId:'',
        departmentId:'',
        page:1,
      }
      console.log(sendData)

      const resultDashBoardData = await GetApi(`${DefaultUrl}/graph`,sendData)
      if (resultDashBoardData.errorDetail) {
        dispatch(SetLoadSpinner(false))
        ErrorHandling(resultDashBoardData, alert)
        return null
      }else{
        console.log(resultDashBoardData)
        makeDashboardData(resultDashBoardData.data.responseList,data.targetDt)
        dispatch(SetLoadSpinner(false))
      }
    }
    //テーブル
    const serachTable = async(data)=>{
      const result = await GetApi(DefaultUrl, data)
      
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else {
        const resultMap = result.data.responseList
        const limitMap = resultLimit.data.responseList
        console.log(resultMap)
        //const dataMap = resultMap.map((item)=>{return VerifyLimitOver(item)})
        setPageNum(page)
        var resultList = makeResult(result.data.responseList,data.targetDt,limitMap)
        setStaffInfoAllList(resultList)
        console.log(limitTypeRef.current.value)
        if(limitTypeRef.current.value != 0){
          var tmpList= new Array()
          resultList.map((staff)=>{
            if(staff.filiterType==limitTypeRef.current.value) tmpList.push(staff)
         })
         resultList = tmpList
        }
        setDateDataList(resultList)
        makePage(resultList.slice(),page)
        setLimitList(limitMap)
        console.log(resultList)
        setStaffCount(resultList.length)
        setPageLast(Math.ceil(resultList.length/pageRecord))
      }
    }
    dispatch(SetLoadSpinner(true))
    setSearchOption(
      {
        targetDt: targetDateRef.current,
        postType:postType,
        postId:(postId!=undefined && postId != -1) ? postId:'',
        departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      }
    )
    
    const data = {
      targetDt: targetDateRef.current,
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      page: page
    }
    const resultLimit = await GetApi(`${DefaultUrl}/limit`)
    if (resultLimit.errorDetail) {
      ErrorHandling(resultLimit, alert)
      return null
    }

    serachTable(data)
    if((!postId) || postId==-1){
        serachGraphALL(data)
    }else{
        serachGraph(data)
    }
    

    setViewSubSearch(false);
  }
  const DepartmentIdPartLength = 3;
  /// <summary>
  /// 部門の下層のデータを作成(X軸)
  /// </summary>
  /// <param name="dt">ツリーを作成する部門情報のテーブル</param>
  /// <param name="departmentIds">ツリー項目の部門IDの配列</param>
  const MakeItems =(departmentData,departmentId,hierarchyNo)=>
  {
    var searchId = '';
    if (departmentId != null && departmentId!= '全て')
    {
        searchId = departmentId.substring(0, DepartmentIdPartLength * (hierarchyNo - 1));
        //searchId = departmentId.replace(new RegExp(0 + "+$", "g"),'')
    }
    const dataList = new Array()
    console.log(departmentData)
    departmentData.map((data)=>{
        var departmentId = (postType==0?data.comDepartmentId:data.cusDepartmentId)
        var departmentName = (postType==0?data.comDepartmentName:data.cusDepartmentName)
        if(hierarchyNo != data.hierarchyNo){
            return
        }
        if(!departmentId.startsWith(searchId)){
            console.log(searchId)
            return
        }
        
        dataList.push(departmentId)
    })
    return dataList
  }
 
  //ページング設定
  const makePage = (data,page) =>{
    if(page!=0){
      console.log(data)
      console.log(page)
      var minRecord = (page-1)*pageRecord
      var maxRecord = (page*pageRecord)
      console.log(minRecord,maxRecord)
      var tmpCount = 0
      var tmpList = new Array()
      data.forEach((staffWorkInfo)=>{
        if(tmpCount>=minRecord && tmpCount<maxRecord){
          tmpList.push(staffWorkInfo)
        }
        tmpCount+=1
      })
      setStaffInfoList(tmpList)
    }
    else
    {
      setStaffInfoList(data)
    }
  }
  //リストデータ作成
  const makeResult = (data,targetDate,limitMap) =>{
    const date = new Date(targetDate)
    console.log(date)
    var targetYM = targetDate
    console.log(targetYM)
    var limitRows = limitMap.map((limit)=>{
      if(limit.limitWatchType == 2)
        return limit
    })
    var limitRow = limitRows[0]    
    console.log(data)
    return data.map((depart)=>{
      const tmp = {
        staffId:depart.staffId,
        staffName:depart.staffName,
        overTimeWeek:(depart.overTimeWeek==null?0:depart.overTimeWeek),
        twoMonth:AvgMonth(depart.overTimeMonthInfoList,2,targetYM,depart.enterDt),
        threeMonth:AvgMonth(depart.overTimeMonthInfoList,3,targetYM,depart.enterDt),
        fourMonth:AvgMonth(depart.overTimeMonthInfoList,4,targetYM,depart.enterDt),
        fiveMonth:AvgMonth(depart.overTimeMonthInfoList,5,targetYM,depart.enterDt),
        sixMonth:AvgMonth(depart.overTimeMonthInfoList,6,targetYM,depart.enterDt),
        thisMonth:ThisMonth(depart.overTimeMonthInfoList,targetYM.slice(0,7)),
        thisYear:ThisYear(depart.overTimeMonthInfoList),
        specialMonth:SpecialMonth(depart.overTimeMonthInfoList,limitRow.monthLimit),
        thisMonthLimitWatch:0,
        twoMonthLimitWatch:0,
        threeMonthLimitWatch:0,
        fourMonthLimitWatch:0,
        fiveMonthLimitWatch:0,
        sixMonthLimitWatch:0,
        thisYearLimitWatch:0,
        filiterType:0,
      }
      makeLimit(tmp,limitMap)
      console.log(tmp)
      return tmp
    })
  }
  //警告・注意確認
  const makeLimit =(tmp,limitMap)=>{
    limitMap.map((limit)=>{
      if(tmp.thisMonthLimitWatch < limit.limitWatchType && tmp.thisMonth >= limit.monthLimit ) {
        tmp.thisMonthLimitWatch = limit.limitWatchType
        tmp.filiterType = limit.limitWatchType
      }
      if(tmp.twoMonthLimitWatch < limit.limitWatchType && tmp.twoMonth >= limit.twoMonthsAvgLimit){
        tmp.twoMonthLimitWatch = limit.limitWatchType
        tmp.filiterType = limit.limitWatchType
      }
      if(tmp.threeMonthLimitWatch < limit.limitWatchType && tmp.threeMonth >= limit.threeMonthsAvgLimit ) {
        tmp.threeMonthLimitWatch = limit.limitWatchType
        tmp.filiterType = limit.limitWatchType
      }
      if(tmp.fourMonthLimitWatch < limit.limitWatchType && tmp.fourMonth >= limit.fourMonthsAvgLimit ) {
        tmp.fourMonthLimitWatch = limit.limitWatchType
        tmp.filiterType = limit.limitWatchType
      }
      if(tmp.fiveMonthLimitWatch < limit.limitWatchType && tmp.fiveMonth >= limit.fiveMonthsAvgLimit ) {
        tmp.fiveMonthLimitWatch = limit.limitWatchType
        tmp.filiterType = limit.limitWatchType
      }
      if(tmp.sixMonthLimitWatch < limit.limitWatchType && tmp.sixMonth >= limit.sixMonthsAvgLimit ){ 
        tmp.sixMonthLimitWatch = limit.limitWatchType
        tmp.filiterType = limit.limitWatchType
      }
      if(tmp.thisYearLimitWatch < limit.limitWatchType && tmp.thisYear >= limit.yearLimit ) {
        tmp.thisYearLimitWatch = limit.limitWatchType
        tmp.filiterType = limit.limitWatchType
      }
    })
  }

  /**
  * ダッシュボード用データ作成
  * @param {List<dailyList>} data 検索されたデータ
  * @param {String>} targetDate 検索日 
  * @return {List} ダッシュボード表示用データ
  */
  const makeDashboardData = (data,targetDate) =>{
    const date = new Date(targetDate)
    console.log(date)
    var targetYM = targetDate.slice(0,7)
    console.log(targetYM)
    
    setDashboardDataList(
    data.map((depart)=>{
        console.log(depart)
        const tmp = {
          "name":depart.postName,
          "2ヶ月":AvgMonth(depart.overTimeGraphMonthInfoList,2,targetYM,depart.dateym),
          "3ヶ月":AvgMonth(depart.overTimeGraphMonthInfoList,3,targetYM,depart.dateym),
          "4ヶ月":AvgMonth(depart.overTimeGraphMonthInfoList,4,targetYM,depart.dateym),
          "5ヶ月":AvgMonth(depart.overTimeGraphMonthInfoList,5,targetYM,depart.dateym),
          "6ヶ月":AvgMonth(depart.overTimeGraphMonthInfoList,6,targetYM,depart.dateym),
          "当月":ThisMonth(depart.overTimeGraphMonthInfoList,targetYM),
          "今年度":ThisOverTime(depart.overTimeGraphMonthInfoList),
          "1人当たりの平均":ThisYear(depart.overTimeGraphMonthInfoList)
        }
        return tmp

      })
    )
  }
  const AvgMonth=(data,monthCt,targetDate,startDt)=>{
    var overTime = 0
    var holTime = 0 
    var count=0
    var yearStartDt = new Date(startDt)
    //console.log(startDt)
    for (let i = 0; i < monthCt; i++){
      var tmpTarget = new Date(targetDate)
      tmpTarget.setMonth(tmpTarget.getMonth()-i)
      var tmpMonth = ((tmpTarget.getMonth())==0?12:tmpTarget.getMonth())
      var tmpYear = ((tmpTarget.getMonth())<=0?tmpTarget.getFullYear()-1:tmpTarget.getFullYear())
      var targetYM = tmpYear + '-' + ('00' + tmpMonth).slice(-2)
      //console.log(targetYM+'-01')
      if(yearStartDt.getTime() > new Date(targetYM+'-01').getTime()){
          continue
      }
      count++
      data.map((YMData)=>{
        if(YMData.dateym==targetYM){
          overTime += YMData.overTime
          holTime += YMData.holTime
        }
      })
    }
    overTime = (count==0?0:overTime/count+holTime/count)
    return Math.round(overTime*100)/100.0
  }
  const ThisMonth=(data,targetDate)=>{
    var overTime = 0
    data.map((YMData)=>{
      if(YMData.dateym==targetDate){
        overTime = YMData.overTime 
      }
    })
    return Math.round(overTime*100)/100.0
  }
  const ThisYear=(data)=>{
    var overTime = 0
    data.map((YMData)=>{
        overTime += YMData.overTime 
    })
    return Math.round(overTime*100)/100.0
  }
  const ThisOverTime=(data)=>{
    var overTime = 0
    data.map((YMData)=>{
        overTime += (YMData.overTime*parseInt(YMData.sumStaff))
    })
    return Math.round(overTime*100)/100.0
  }
  const SpecialMonth=(data,limit)=>{
    var count = 0
    data.map((YMData)=>{
      if(YMData.overTime > limit){
        count++
      }
    })
    return count
  }
  // 検索項目でキーボードの押下イベント
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }
  const onClickImg =  (event) => {
    console.log('Ok')
    setCanOpenDialog(true)
  }

  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
  }
  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud'
    else
      document.title = 'Core Time Cloud'
  }, [grants])

  useEffect(() => {
    setPost()
    onSearch(null,1)
  }, [])
  
  const setPost=()=>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      }
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.companyId,
          name: item.companyName+'('+item.companyId+')',
        }
      })
      setPostList(resultList)
      return true
    }
    const serachCustomer = async()=>{
      const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } 
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.customerId,
          name: item.customerName+'('+item.customerId+')',
        }
      })
      setPostList(resultList)
      return true
    }

    if(postType==0){
      serachCompany()
    }else{
      serachCustomer()
    }
  }

  const handleClose = () => {
    setCanOpenDialog(false);
  }
  //会社顧客変更処理
  useEffect(() => {
    setPost()
    // 必要なデータが更新された場合のみ実行
    // eslint-disable-next-line
    }, [postType])

  if(!postList){
    setPost()
  }
  const onChangeClick = () => {
    setSerachType(serachType==0?1:0)
  }

  const onPageSeletClick = (pageId) => {
    console.log(pageId)
    makePage(dateDataList,pageId)
    setPageNum(pageId)
  }
  const onPageNextSeletClick = (pageId) => {
    if(pageNum!=pageLast){
      onPageSeletClick(pageId)
    }
  }
  const onPageBackSeletClick = (pageId) => {
    if(pageNum!=1){
      onPageSeletClick(pageId)
    }
  }
  const onPageChangeLabelClick = () => {
    setViewFunction(false);
    if(pageType==0){
      setPageType(1)
      setPageNum(0)  
      makePage(dateDataList,0)
    }else{
      setPageType(0)
      setPageNum(1)
      makePage(dateDataList,1)
    }
  }
  // 検索APIの実行
  const onSearchLimit = async () => {
    const resultLimit = await GetApi(`${DefaultUrl}/limit`)
    if (resultLimit.errorDetail) {
      ErrorHandling(resultLimit, alert)
      return null
    }
    const limitMap = resultLimit.data.responseList
    setLimitList(limitMap)
  }
  const [viewFunction,setViewFunction] = useState(false)
    
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  const onChangeLimitType = (e)=>{
    e.persist()

    var resultList = staffInfoAllList
    if(limitTypeRef.current.value != 0){
      var tmpList= new Array()
      resultList.map((staff)=>{
        if(staff.filiterType==e.target.value) tmpList.push(staff)
     })
     resultList = tmpList
    }
    
    makePage(resultList.slice(),(pageNum==0?0:1))
    setStaffCount(resultList.length)
    setPageLast(Math.ceil(resultList.length/pageRecord))
    
  }

  if (!targetDateRef.current) {
    var today = new Date();
    targetDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-01'
  }
  if(!limitTypeRef.current){
    limitTypeRef.current=0
  }
  if(LimitList.length==0){
    onSearchLimit()
  }
  if (!grants) return null
  return (
    <DashboardBaseWrap>
      <DashboardBaseFormRow>
        <Search
            showButton
            onClick={() => onSearch(null, pageType==0?1:0)}
            onClickOption={(e) => onClickOptionBtn(e)}
            onKeyDown={(e) => onKeyDown(e)}
            shiftDateRef={targetDateRef}
            viewSubSearch={viewSubSearch}
            grants={grants}
            postList={postList}
            postId={postId}
            setPostId={setPostId}
            departmentId={departmentId}
            setDepartmentId={setDepartmentId}
            postType={postType}
            setPostType={setPostType}
            onChangeClick={() =>onChangeClick()}
            serachType={serachType}
            viewFunction={viewFunction}
            onClickFunction={()=>onClickFunction()}
            onPageTypeChangeClick={() => onPageChangeLabelClick()}>
        </Search>
      </DashboardBaseFormRow>
      <DashboardBaseFormRow>
        {/* <DashBoardSelect/> */}
        {serachType==0 && dashboardDataList.length != 0 &&
          <OverTimeGraph workInfoList={dashboardDataList}></OverTimeGraph>
          //<OverTimeTable></OverTimeTable>
        }
        {serachType==1 &&
          //<OverTimeGraph workInfoList={dashboardDataList}></OverTimeGraph>
          <div>
          <DashboardBaseForm>
            {/* <label style={{lineHeight:'34px',textAlign:'center',marginLeft:'16px',fontSize:'16px',backgroundColor:'yellow',width:'40px',height:'32px'}}>注意</label>
            <label style={{lineHeight:'34px',textAlign:'center',marginLeft:'8px' ,fontSize:'16px',backgroundColor:'red',width:'40px',height:'32px'}}>警告</label> */}
            <img src={IconFilter} style={{lineHeight:'32px',marginLeft:'1rem' ,width:'32px',height:'32px'}}></img>
            <Select
              margin={'0 0 0 4px'}
              name={'postId'}
              width='7rem'
              selectRef={limitTypeRef}
              style={{backgroundColor:'yellow'}}
              onChange={(e)=>onChangeLimitType(e)}
              >
              <option value={0} >{'全て'}</option>
              <option value={1} style={{backgroundColor:'yellow'}}>{'注意'}</option>
              <option value={2} style={{backgroundColor:'red'}}>{'警告'}</option>
          </Select>
          <Tooltip title={'凡例'}>
            <img src={IconQuestionImg} onClick={()=>onClickImg()} style={{lineHeight:'32px',marginLeft:'16px',width:'32px',height:'32px'}}></img>
          </Tooltip>
          <div></div>
          { staffInfoList.length > 0 &&
          <CountPanelWrap>
            <label style={{marginLeft:'auto'}}>検索ヒット件数:{staffCount}件</label>
          </CountPanelWrap>
          }
          </DashboardBaseForm>
          <DashboardFlexWrap>
            <OverTimeTable staffWorkInfoList={staffInfoList} dateDataList={dateDataList} limitList={LimitList} targetDt={searchOption}></OverTimeTable>
          </DashboardFlexWrap>
          { staffInfoList.length > 0 &&
          <PagingPanelWrap>
          {/* <PagingChangePanelWrap onClick={() => onPageChangeLabelClick()}>表示切替</PagingChangePanelWrap> */}
          { pageType==0 &&
              <PageButtonWrap>       
              <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
              <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
              <PagingIconPanelWrap>{pageNum}</PagingIconPanelWrap>
              <PagingIconPanelWrap>/</PagingIconPanelWrap>
              <PagingIconPanelWrap>{pageLast}</PagingIconPanelWrap>
              <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
              <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
              </PageButtonWrap>
            }
          </PagingPanelWrap>
          }
          </div>
          }
     
      </DashboardBaseFormRow>
      <FilterDialog
        open={canOpenDialog}
        onCloseFunc={handleClose}
        values={LimitList}
      />
    </DashboardBaseWrap>
  )
}

export default OverTimeWork
