import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'
import ErrorHandling from '../../../Common/ErrorHandling'

import DailyTableRow from './DailyTableRow'

const DailyTableBody = ({ 
  register, getValues, setValue, watch, reset, errors,workDivisions,
  dateDataList, staffWorkInfoList, selectFunction, updatedAttendanceFunc, defaultUrl,
  handleOpenPunchHistoryFunc, handleClosePunchHistoryFunc, handleOpenDailyAttendanceFunc, handleCloseDailyAttendanceFunc,restFlag }) => {

  // 初期化メソッド
  useEffect(() => {
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])


  return (
    <tbody>
    {staffWorkInfoList && staffWorkInfoList.map((staff) => {
      return (
            <DailyTableRow key={staff.orderId}
              register={register}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              reset={reset}
              errors={errors}

              workDivisions={workDivisions}
              staff={staff}

            >
            </DailyTableRow>
          )
    })}
    </tbody>
  )
}

DailyTableBody.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedAttendanceFunc: PropTypes.func,
  defaultUrl: PropTypes.string,

  handleOpenPunchHistoryFunc: PropTypes.func,
  handleClosePunchHistoryFunc: PropTypes.func,
  handleOpenDailyAttendanceFunc: PropTypes.func,
  handleCloseDailyAttendanceFunc: PropTypes.func,
}

export default DailyTableBody
