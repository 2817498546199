import React,{useState,useEffect} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { InputGroup, SelectGroup } from '../../../Common/CommonInputGroup'
import { diff48Time, format48Time, formatHour, makeElementIdByDailyWorkInfo, calcRestHours ,formatRound48Time} from '../../../Common/Utilties/LaborUtility'
import Button from '../../../Common/Atoms/NormalButton'
export const ColumnTD = styled.td`
width: 4rem;
vertical-align:middle;
text-align:center;
`
const RestTableRow = ({
  register, getValues, setValue, watch, reset, errors,restData,workInfo,onDelete
  }) => {

  // 初期化メソッド
  useEffect(() => {
    if(restData){
      setValue('attendanceRestTotalDt_' + restData.restNum, formatHour(restData.attendanceRestTotalDt))

      if(restData.isDiff==undefined){
        setValue('restStart_' + restData.restNum, diff48Time(workInfo.baseDt, restData.attendanceRestStartDt))
        setValue('restEnd_' + restData.restNum, diff48Time(workInfo.baseDt, restData.attendanceRestEndDt))
        restData.isDiff=false
        restData.attendanceRestStartDt = diff48Time(workInfo.baseDt, restData.attendanceRestStartDt)
        restData.attendanceRestEndDt = diff48Time(workInfo.baseDt, restData.attendanceRestEndDt)

        if(restData.clockRestStartDt != null)restData.clockRestStartDt = diff48Time(workInfo.baseDt, restData.clockRestStartDt)
        if(restData.clockRestEndDt != null)restData.clockRestEndDt = diff48Time(workInfo.baseDt, restData.clockRestEndDt)
      }
      else{
        setValue('restStart_' + restData.restNum, (workInfo.baseDt, restData.attendanceRestStartDt))
        setValue('restEnd_' + restData.restNum, (workInfo.baseDt, restData.attendanceRestEndDt))
      }
    }


  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  const onBlurTime = ( event ) => {
    let value = event.target.value
    //日時の分単位を指定分で丸める
    let fractionUnit = parseInt(workInfo.attendanceFractionUnit)
    //value = format48Time(value)
    let name = event.target.name 
    if(name.indexOf('restStart') != -1)
    {
      value = formatRound48Time(value,fractionUnit*(-1))
    }
    else
    {
      value = formatRound48Time(value,fractionUnit)
    }
    event.target.value = value

    let restStart1 = getValues("restStart_" + restData.restNum)
    let restEnd1 = getValues("restEnd_" + restData.restNum)
    
    //日時の分単位を指定分で丸める
    restStart1 = formatRound48Time(restStart1,fractionUnit*(-1))
    restEnd1 = formatRound48Time(restEnd1,fractionUnit)

    let result = calcRestHours( [restStart1, restEnd1])

    setValue('attendanceRestTotalDt_' + restData.restNum, result)

    {
      restData.attendanceRestTotalDt = parseFloat(result)*3600
      restData.attendanceRestStartDt = restStart1
      restData.attendanceRestEndDt = restEnd1

      console.log(workInfo)
      //workInfo.isDiff = true
      restData.isDiff =true
    }
  }
    const createElementRestTime = (workInfoList) => {
      if (workInfoList) {
          return <>
              <InputGroup
                  name={'attendanceRestTotalDt_' + restData.restNum}
                  width='3.5rem'
                  margin='auto'
                  placeholder=''
                  register={register}
                  errors={errors}
                  style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                  disabled={true}
              />
             </>
      }
  
      return <></>
    }  
    const createElementTime = (prefix, workInfoList) => {
      if (workInfoList) {
          return <>
              <InputGroup
                  name={prefix + restData.restNum}
                  width='3.5rem'
                  margin='auto'
                  placeholder=''
                  register={register}
                  errors={errors}
                  onBlur={onBlurTime}
                  style={{ textAlign: 'right', paddingRight: '0.5rem' }}
                  disabled={false}
              />
             </>
      }
    }
    return (
      <tr>
      <ColumnTD style={{ width: '4rem' }}> {createElementRestTime(restData)}</ColumnTD>
      <ColumnTD style={{ width: '4rem' }}> {createElementTime('restStart_',restData)}</ColumnTD>
      <ColumnTD style={{ width: '4rem' }}> {createElementTime('restEnd_',restData)}</ColumnTD>
      <ColumnTD><Button margin='auto' padding='0' type='button' width='3rem' name='削除' onClick={() => onDelete(restData.restNum)}/></ColumnTD>
      </tr>
    )
}

RestTableRow.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func
}

export default RestTableRow