import React from 'react'
import PropTypes from 'prop-types'

// import MonthTableBody from './SalariedMonthTableBody'
// import SalariedTableHeader from './SalariedTableMonthHeader'
// import SalariedTableWrap,{ColumnTimeTH}from '../../Wrap/SalariedTableWrap'
// import SalariedManagementDialog from '../SalariedDialog/SalariedManagementDialog'
import DashboardTableWrap from '../../Wrap/DashboardTableWrap'
import OverTimeTableHeader from './OverTimeHeader'
import OverTimeTableBody from './OverTimeBody'
const MonthTable = ({ dateDataList, targetDt,staffWorkInfoList,limitList, selectedIndex, selectFunction, updatedShiftFunc, defaultUrl }) => {

  //仮置き
  //start
  // const [canOpenDialog, setCanOpenDialog] = useState(false);
  // const [targetId, setTargetId] = useState('')
  // //ダイアログ閉じるボタン処理
  // const handleClose = () => {
  //   setCanOpenDialog(false);
  // }
  //end
  //visible={dateDataList.length ? true : false} width={calcTableWidth(dateDataList.length)}
  return (
    <DashboardTableWrap visible={dateDataList.length ? true : false}>
        <OverTimeTableHeader staffWorkInfoList={dateDataList} targetDt={targetDt}></OverTimeTableHeader>
        <OverTimeTableBody workInfoList={staffWorkInfoList}></OverTimeTableBody>
    </DashboardTableWrap>
  )
}

MonthTable.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  limitList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  onSalariedSearch: PropTypes.func,
  defaultUrl: PropTypes.string,
  targetDt: PropTypes.any,
}

export default MonthTable
