import React from 'react'
import PropTypes from 'prop-types'
//import { makeElementId } from '../../../Common/Utilties/LaborUtility'

import {ColumnDateTD,ColumnTimeTD,ColumnSpan}from '../../Wrap/SalariedTableWrap'

const SalariedTableBody = ({
    salariedDt,
    workInfoList
  }) => {
  const makeSalariedDt = (dt) =>{
    //setSalariedDt(salariedDt - dt)
    salariedDt = salariedDt - dt
    return salariedDt
  }
  return (
    <tbody>
      {workInfoList && workInfoList.map((workInfo) => {
        return (
            <tr key={workInfo.staffId+'_'+workInfo.salariedDate}>
                <ColumnDateTD style={{textAlign:'center'}}>{workInfo.salariedDate} ～ {workInfo.salariedDate}</ColumnDateTD>
                <ColumnTimeTD><ColumnSpan>{1}</ColumnSpan></ColumnTimeTD>
                <ColumnTimeTD><ColumnSpan>{makeSalariedDt(1)}</ColumnSpan></ColumnTimeTD>
                <ColumnTimeTD><ColumnSpan>{workInfo.salariedDivisionName}</ColumnSpan></ColumnTimeTD>
                <ColumnDateTD><ColumnSpan>{workInfo.remark}</ColumnSpan></ColumnDateTD>
            </tr>
        )
      })}
    </tbody>
  )
}

SalariedTableBody.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,
  salariedDt: PropTypes.string,
  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array
}

export default SalariedTableBody
