import React,{useState} from 'react'
import PropTypes from 'prop-types'

import MonthTableBody from './SalariedMonthTableBody'
import SalariedTableHeader from './SalariedTableMonthHeader'
import SalariedTableWrap from '../../Wrap/SalariedTableWrap'
import SalariedManagementDialog from '../SalariedDialog/SalariedManagementDialog'

const MonthTable = ({ dateDataList, targetDt,staffWorkInfoList, selectedIndex, selectFunction, updatedShiftFunc, defaultUrl }) => {

  const calcTableWidth = (count) => {
    return (count * 2).toString() + 'rem' + ' + ' + count.toString() + 'px';
  }
    //仮置き
  //start
  const [canOpenDialog, setCanOpenDialog] = useState(false);
  const [targetId, setTargetId] = useState('')
    //ダイアログ閉じるボタン処理
    const handleClose = () => {
      setCanOpenDialog(false);
    }
  //end
  //visible={dateDataList.length ? true : false} width={calcTableWidth(dateDataList.length)}
  return (
    <SalariedTableWrap visible={staffWorkInfoList.length ? true : false}>
      <SalariedTableHeader/>
      <MonthTableBody
        staffWorkInfoList={staffWorkInfoList}
        setOpenDialog={setCanOpenDialog}
        setTargetId={setTargetId}
        //defaultUrl={defaultUrl}
        //shiftFilter={shiftFilter}
      >
      </MonthTableBody> 
      <SalariedManagementDialog
        open={canOpenDialog}
        onCloseFunc={handleClose}
        targetDt={targetDt}
        targetId={targetId}
        defaultUrl={defaultUrl}
        >
      </SalariedManagementDialog>
    </SalariedTableWrap>
  )
}

MonthTable.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  onSalariedSearch: PropTypes.func,
  defaultUrl: PropTypes.string,
  targetDt: PropTypes.any,
}

export default MonthTable
