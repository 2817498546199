import React from 'react'
import PropTypes from 'prop-types'

//import Tooltip from '@material-ui/core/Tooltip'

//import { formatHour } from '../../../Common/Utilties/LaborUtility'
//import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
//import styled from 'styled-components'
//import { ColumnStaffTD, ColumnWorkTD, ColumnTimeTD, ColumnOccupationTD,ColumnSalariedCountTD,ColumnStaffIdTD, RowHeaderTop} from '../../Wrap/ShiftGanttChartTableWrap'
//import Canvas from '../../Molcules/Labor/TableCanvas'
//import { CompassCalibrationOutlined } from '@material-ui/icons'
import TableRow from './ShiftGanttChartMonthTableRow'
// styled
/*
const LaborRow = styled.td`
  display: flex;
  width:2220px;
  min-height:50px;
  max-height:50px;
  background:#fff;
  margin-bottom: 3px;
  canvas{
      margin:0;
      padding:0;
      vertical-align:bottom;
  }
`
*/
const MonthTableBody = ({ staffWorkInfoList, setOpenDialog,selectFunction, updatedShiftFunc, defaultUrl,setTargetId,workDivisions ,wrapRef}) => {

  var count = 0
  return (
    <tbody>
      {staffWorkInfoList && staffWorkInfoList.map((dailyData) => {     
      return (        
        dailyData.dailyWorkInfoList.map((staff) => {  
        return (  
          staff.workInfoList.map((workInfoList)=>{
            return(
              <TableRow key={staff.staffId+'_'+workInfoList.orderId} staff={staff} restFlag={dailyData.restFlag} wrapRef={wrapRef} workDivisions={workDivisions} workInfoList={workInfoList} count={count++}/>
            )
          }
          )
        )
        })
        )}
      )}
    </tbody>
  )
}

MonthTableBody.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  defaultUrl: PropTypes.string,
}

export default MonthTableBody
