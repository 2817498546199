import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { TransportationTableWrap } from '../../Wrap/PaymentDuductionWrap'

import DailyTableHeader from './TransportationPublicHeader'
import DailyTableBody from './TransportationPublicTableBody'

const TransportationTable = ({
    register, getValues, setValue, watch, reset, errors,
    defaultUrl, workInfoList, selectedIndex, selectFunction,workDivisions,open
  }) => {

  return (
    <TransportationTableWrap visible={true}>
      <DailyTableHeader open={open} />
      <DailyTableBody
        workInfoList={workInfoList}
        open={open}
      >
      </DailyTableBody>
    </TransportationTableWrap>
  )
}

TransportationTable.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfoList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func
}

export default TransportationTable
