import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ListUl = styled.ul`
  width: 16.5vw;
  ${props => props.addStyle}
`

const ListLi = styled.li`
  height: 35px;
  width: 15.5vw;
  line-height: 35px;
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId {
    margin-right: 1rem;
  }
  :nth-child(even) {
    background: ${props => props.selected ? '#048b63' : '#eee'};
  }
  :hover {
    ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
  }

  .no-badge {
    padding: 3px 8px;
    margin: auto;
    font-size: 6px;
    font-weight: 700;
  }

  .new-badge, .update-badge, .delete-badge {
    padding: 3px 6px;
    margin: auto;
    font-size: 6px;
    font-weight: 700;
    color: white;
    border-radius: 6px;
    box-shadow: 0 0 2px #ddd;
  }
  
  .new-badge {
    background-color: #f07b0e;
  }
  
  .update-badge {
    background-color: #08a33c;
  }

  .delete-badge {
    background-color: #3d3c3c;
  }
`

const ListView = ({ dataList, selectedId, selectFunction, isIdView, isBadgeView, addStyle }) => {

  const isCreateBadge = (data) => {
    if (data.delFlag)
      return false;
    
    if (!data.createdAt)
      return false;
    
    var diffDay = (Date.now() - new Date(data.createdAt)) / 86400000;

    return diffDay <= 7; // 7日以内なら該当する
  }

  const isUpdateBadge = (data) => {
    if (data.delFlag)
      return false;

    if (!data.updatedAt)
      return false;
    
    var diffDay = (Date.now() - new Date(data.updatedAt)) / 86400000;

    return diffDay <= 7; // 7日以内なら該当する
  }

  const isDeleteBadge = (data) => {
    return data.delFlag;
  }

  return (
    <ListUl addStyle={addStyle}>
      {dataList && dataList.map((data, index) => {
        return (
          <ListLi
            onClick={() => selectFunction(data.id)}
            selected={data.id === selectedId}
            key={index}
            value={data.index}
          >
            {isBadgeView ?
                isCreateBadge(data) ? <span className="new-badge">N</span>
                  : isUpdateBadge(data) ? <span className="update-badge">U</span>
                  : isDeleteBadge(data) ? <span className="delete-badge">D</span>
                  : <span className="no-badge">&nbsp;</span>
                : null
            }
            {isIdView ? <span className='listId'> {data.id} </span> : null}
            {data.name}
          </ListLi>
        )
      })}
    </ListUl>
  )
}

ListView.propTypes = {
  dataList: PropTypes.array,
  selectedId: PropTypes.any,
  selectFunction: PropTypes.func,
  isIdView: PropTypes.bool,
  isBadgeView: PropTypes.bool,
  addStyle: PropTypes.string
}

export default ListView
