import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import * as regex from '../../../Common/Regex'
import { InputGroup, SelectGroup, HiddenInput } from '../../../Common/CommonInputGroup'
//import { GetApi } from '../../../Common/ApiAxios'
//import ErrorHandling from '../../../Common/ErrorHandling'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
import { diff48Time, formatRound48Time, formatHour, makeElementId, calcWorkingHours,calcWorkingRestFreeHoursTime } from '../../../Common/Utilties/LaborUtility'
//import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import Button from '../../../Common/Atoms/NormalButtonAttendance'
import RestDialog from '../ShiftRestDialog/RestModel'

const ColumnWrap = styled.td`
  text-align:center;
  vertical-align:middle;
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const CellTop = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
`

const CellBottom = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
  border-top: 1px solid #DDD;
`

const ColumnOccupationTD = styled.td`
  font-size: 16px;
  width: 8rem;
  max-width: 8rem;
  vertical-align: middle;
  text-align: left;
  padding-left: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const DailyTableRow = ({
    register, getValues, setValue, watch, reset, errors, 
    defaultUrl, workInfo,workDivisions,restFlag
  }) => {

  const alert = useAlert()
  //const [workDivisions, setWorkDivisions] = useState(<></>)
  const [elementId, setElementId] = useState(makeElementId(workInfo))
  const [workDivision, setWorkDivision] = useState(0)
  const dispatch = useDispatch()
  const [restStart1DtError,setRrestStart1DtError] = useState(false)
  const [restEnd1Dt,setRrestEnd1Dt] = useState(false)
  const [restStart2Dt,setRrestStart2Dt] = useState(false)
  const [restEnd2Dt,setRrestEnd2Dt] = useState(false)
  const [restStart3Dt,setRrestStart3Dt] = useState(false)
  const [restEnd3Dt,setRrestEnd3Dt] = useState(false)

  // 勤務情報切り替え時
  useEffect(() => {
  // 勤務情報が変更された場合のみ実行
    if (workInfo) {
      setElementId(makeElementId(workInfo))
      setValue('workDivision_' + elementId, workInfo.workDivision)
      setWorkDivision(workInfo.shiftWorkDivision)
      if(workInfo.restStart1DtError==undefined)
      {
        setRrestStart1DtError(false)
        console.log(setRrestStart1DtError)
      }
      else
      {
        setRrestStart1DtError(workInfo.restStart1DtError)
      }
    }
  // eslint-disable-next-line
  }, [workInfo])

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
      //await getWorkDivisions()
    
      setValue('orderId_' + elementId, elementId)
      setValue('staffId_' + elementId, workInfo.staffId)
      setValue('baseDt_' + elementId, workInfo.baseDt)
      setValue('shiftUpdatedAt_' + elementId, workInfo.updatedAt)

      setValue('shiftWorkDivision_' + elementId, workInfo.shiftWorkDivision)
      setValue('shiftWorkStart_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftWorkStartDt))
      setValue('shiftWorkEnd_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftWorkEndDt))
      setValue('shiftWorkTotalDt_' + elementId, formatHour(workInfo.shiftWorkTotalDt))
      setValue('shiftRestTotalDt_' + elementId, formatHour(workInfo.shiftRestTotalDt))
      let overTotal = 0.0
      if (workInfo.shiftWorkTotalDt > workInfo.attendanceBaseTime) {
        overTotal = workInfo.shiftWorkTotalDt - workInfo.attendanceBaseTime
      }
      setValue('shiftOverTotalDt_' + elementId, formatHour(overTotal))
      if(restFlag){
        let restStartList = new Array()
        let restEndList = new Array()

        workInfo.shiftRestList.map((restData)=>{
          if(restData.restNum==null) return
          restStartList.push(diff48Time(workInfo.baseDt, restData.restStartDt))
          restEndList.push(diff48Time(workInfo.baseDt, restData.restEndDt))
        })
        for(var i=restStartList.length;i<3;i++){
          restStartList.push('')
        }
        for(var i=restEndList.length;i<3;i++){
          restEndList.push('')
        }

        setValue('shiftRestStart1_' + elementId, restStartList[0])
        setValue('shiftRestEnd1_' + elementId, restEndList[0])
        setValue('shiftRestStart2_' + elementId, restStartList[1])
        setValue('shiftRestEnd2_' + elementId, restEndList[1])
        setValue('shiftRestStart3_' + elementId, restStartList[2])
        setValue('shiftRestEnd3_' + elementId, restEndList[2])

        console.log(restStartList,restEndList)
      }else{
        setValue('shiftRestStart1_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftRestStart1Dt))
        setValue('shiftRestEnd1_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftRestEnd1Dt))
        setValue('shiftRestStart2_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftRestStart2Dt))
        setValue('shiftRestEnd2_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftRestEnd2Dt))
        setValue('shiftRestStart3_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftRestStart3Dt))
        setValue('shiftRestEnd3_' + elementId, diff48Time(workInfo.baseDt, workInfo.shiftRestEnd3Dt))
      }

      
      setValue('shiftRemark_' + elementId, workInfo.shiftRemark)

      setValue('attendanceWorkDivision_' + elementId, workInfo.attendanceWorkDivision)
      setValue('attendanceWorkStart_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceWorkStartDt))
      setValue('attendanceWorkEnd_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceWorkEndDt))
      setValue('attendanceWorkTotalDt_' + elementId, formatHour(workInfo.attendanceWorkTotalDt))
      setValue('attendanceRestTotalDt_' + elementId, formatHour(workInfo.attendanceRestTotalDt))
      overTotal = 0.0
      if (workInfo.attendanceWorkTotalDt > workInfo.attendanceBaseTime) {
        overTotal = workInfo.attendanceWorkTotalDt - workInfo.attendanceBaseTime
      }
      setValue('attendanceOverTotalDt_' + elementId, formatHour(overTotal))

      if(restFlag){

        let restStartList = new Array()
        let restEndList = new Array()

        workInfo.attendanceRestList.map((restData)=>{
          if(restData.restNum==null) return
          restStartList.push(diff48Time(workInfo.baseDt, restData.restStartDt))
          restEndList.push(diff48Time(workInfo.baseDt, restData.restEndDt))
        })
        for(var i=restStartList.length;i<3;i++){
          restStartList.push('')
        }
        for(var i=restEndList.length;i<3;i++){
          restEndList.push('')
        }

        setValue('attendanceRestStart1_' + elementId, restStartList[0])
        setValue('attendanceRestEnd1_' + elementId, restEndList[0])
        setValue('attendanceRestStart2_' + elementId, restStartList[1])
        setValue('attendanceRestEnd2_' + elementId, restEndList[1])
        setValue('attendanceRestStart3_' + elementId, restStartList[2])
        setValue('attendanceRestEnd3_' + elementId, restEndList[2])
      }else{
        setValue('attendanceRestStart1_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart1Dt))
        setValue('attendanceRestEnd1_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd1Dt))
        setValue('attendanceRestStart2_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart2Dt))
        setValue('attendanceRestEnd2_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd2Dt))
        setValue('attendanceRestStart3_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestStart3Dt))
        setValue('attendanceRestEnd3_' + elementId, diff48Time(workInfo.baseDt, workInfo.attendanceRestEnd3Dt))
      }

      setValue('attendanceRemark_' + elementId, workInfo.attendanceRemark)

      if(restFlag){
        var tmp = JSON.parse(JSON.stringify(workInfo.shiftRestList))
        tmp.map((restData)=>{
          restData.restStartDt = diff48Time(workInfo.baseDt, restData.restStartDt)
          restData.restEndDt = diff48Time(workInfo.baseDt, restData.restEndDt)
        })
        workInfo.restList = tmp
      }
     
    }
    getOptions()
    console.log(workInfo)
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  const createElementOccupation = (workInfo) => {
    let text = workInfo.occupation
    let textPx = measurePxText(text)
    let textRem = convertPxToRem(textPx)

    let checkRem = 7
    if (textRem < checkRem)
    {
      return <>{text}<br/><span style={{ paddingLeft: '0.5rem' }}>({workInfo.orderId})</span></>
    }

    let editedText = text
    while (textRem > checkRem) {
      editedText = editedText.slice(0, -1)
      textPx = measurePxText(editedText + '...')
      textRem = convertPxToRem(textPx)
    }

    editedText = editedText + '...'

    return <Tooltip title={text}><span>{editedText && editedText}<br/><span style={{ paddingLeft: '0.5rem' }}>({workInfo.orderId})</span></span></Tooltip>
  }
  const [openAttendanceRest, setOpenAttendanceRest] = useState(false);
  const [openShiftRest, setOpenShiftRest] = useState(false);

  // 出勤区分取得
  // const getWorkDivisions = useCallback(async () => {
  //   dispatch(SetLoadSpinner(true))
  //   const result = await GetApi('/api/masterManagement/classificationValues/workDivisions')
  //   dispatch(SetLoadSpinner(false))
  //   if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
  //     const options = result.data.values.map(value =>
  //       <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
  //     )
  //     setWorkDivisions(options)
  //   }
  // }, [setWorkDivisions, alert])

  const onBlurTime = ( event ) => {
    let value = event.target.value
    //日時の分単位を指定分で丸める
    let fractionUnit = parseInt(workInfo.attendanceFractionUnit)
    //value = format48Time(value)
    let name = event.target.name 
    if(name.indexOf('WorkEnd') != -1 || name.indexOf('RestStart') != -1)
    {
      value = formatRound48Time(value,fractionUnit*(-1))
    }
    else
    {
      value = formatRound48Time(value,fractionUnit)
    }
    event.target.value = value

    let workStart = getValues("shiftWorkStart_" + elementId)
    let workEnd = getValues("shiftWorkEnd_" + elementId)
    let restStart1 = getValues("shiftRestStart1_" + elementId)
    let restEnd1 = getValues("shiftRestEnd1_" + elementId)
    let restStart2 = getValues("shiftRestStart2_" + elementId)
    let restEnd2 = getValues("shiftRestEnd2_" + elementId)
    let restStart3 = getValues("shiftRestStart3_" + elementId)
    let restEnd3 = getValues("shiftRestEnd3_" + elementId)

    //日時の分単位を指定分で丸める
    workStart = formatRound48Time(workStart,fractionUnit)
    workEnd = formatRound48Time(workEnd,fractionUnit*(-1))
    restStart1 = formatRound48Time(restStart1,fractionUnit*(-1))
    restEnd1 = formatRound48Time(restEnd1,fractionUnit)
    restStart2 = formatRound48Time(restStart2,fractionUnit*(-1))
    restEnd2 = formatRound48Time(restEnd2,fractionUnit)
    restStart3 = formatRound48Time(restStart3,fractionUnit*(-1))
    restEnd3 = formatRound48Time(restEnd3,fractionUnit)
    
    let result = calcWorkingHours([workStart, workEnd], [restStart1, restEnd1, restStart2, restEnd2, restStart3, restEnd3], workInfo.attendanceBaseTime)
    //追加休憩時間開放version
    if(restFlag){
      let restList = workInfo.restList
      result = calcWorkingRestFreeHoursTime([workStart, workEnd], restList, workInfo.attendanceBaseTime)
      console.log(restList)
    }

    setValue('shiftWorkTotalDt_' + elementId, result[0])
    setValue('shiftRestTotalDt_' + elementId, result[1])
    setValue('shiftOverTotalDt_' + elementId, result[2])
  }
  
  const onChange = ( event ) =>
  {
    let value = event.target.value
    if(2 > value || value > 4)
    {
      setValue('shiftWorkStart_' + elementId, '')
      setValue('shiftWorkEnd_' + elementId, '')
      setValue('shiftWorkTotalDt_' + elementId, '0.00')
      setValue('shiftRestTotalDt_' + elementId, '0.00')
      setValue('shiftOverTotalDt_' + elementId, '0.00')
      setValue('shiftRestStart1_' + elementId, '')
      setValue('shiftRestEnd1_' + elementId, '')
      setValue('shiftRestStart2_' + elementId, '')
      setValue('shiftRestEnd2_' + elementId, '')
      setValue('shiftRestStart3_' + elementId, '')
      setValue('shiftRestEnd3_' + elementId, '')
    }
    setWorkDivision(value)
  }

  const onOpenShift=()=>{
    if(2 > workDivision || workDivision > 4){
      alert.error("出勤区分が出勤・休出・法出ではありません")
    }else{
      setOpenShiftRest(true)
    }
  }
  const onOpenAttendance=()=>{
    if(2 > getValues('attendanceWorkDivision_' + elementId) || getValues('attendanceWorkDivision_' + elementId) > 4){
      alert.error("出勤区分が出勤・休出・法出ではありません")
    }else{
      setOpenAttendanceRest(true)
    }  
  }

    //ダイアログ閉じる処理
    const onClose=(restList)=>{
      //日時の分単位を指定分で丸める
      let fractionUnit = parseInt(workInfo.attendanceFractionUnit)

      let workStart = getValues("shiftWorkStart_" + elementId)
      let workEnd = getValues("shiftWorkEnd_" + elementId)
      let restStart1 = getValues("shiftRestStart1_" + elementId)
      let restEnd1 = getValues("shiftRestEnd1_" + elementId)
      let restStart2 = getValues("shiftRestStart2_" + elementId)
      let restEnd2 = getValues("shiftRestEnd2_" + elementId)
      let restStart3 = getValues("shiftRestStart3_" + elementId)
      let restEnd3 = getValues("shiftRestEnd3_" + elementId)
      
      //休憩を新たにセット
      let restStartList = new Array()
      let restEndList = new Array()
  
      restList.map((restData)=>{
        if(restData.restNum==null || restData.isDel) return
        restStartList.push(restData.restStartDt)
        restEndList.push(restData.restEndDt)
      })
      for(var i=restStartList.length;i<3;i++){
        restStartList.push('')
      }
      for(var i=restEndList.length;i<3;i++){
        restEndList.push('')
      }
  
      //日時の分単位を指定分で丸める
      workStart = formatRound48Time(workStart,fractionUnit)
      workEnd = formatRound48Time(workEnd,fractionUnit*(-1))
      restStart1 = formatRound48Time(restStartList[0],fractionUnit*(-1))
      restEnd1 = formatRound48Time(restEndList[0],fractionUnit)
      restStart2 = formatRound48Time(restStartList[1],fractionUnit*(-1))
      restEnd2 = formatRound48Time(restEndList[1],fractionUnit)
      restStart3 = formatRound48Time(restStartList[2],fractionUnit*(-1))
      restEnd3 = formatRound48Time(restEndList[2],fractionUnit)
      
      let result = calcWorkingRestFreeHoursTime([workStart, workEnd], restList, workInfo.attendanceBaseTime)
      
      setValue('shiftWorkTotalDt_' + elementId, result[0])
      setValue('shiftRestTotalDt_' + elementId, result[1])
      setValue('shiftOverTotalDt_' + elementId, result[2])
      setValue('shiftNightTotalDt_' + elementId, 0)

      setValue('shiftRestStart1_' + elementId, restStartList[0])
      setValue('shiftRestEnd1_' + elementId, restEndList[0])
      setValue('shiftRestStart2_' + elementId, restStartList[1])
      setValue('shiftRestEnd2_' + elementId, restEndList[1])
      setValue('shiftRestStart3_' + elementId, restStartList[2])
      setValue('shiftRestEnd3_' + elementId, restEndList[2])

      workInfo.restList= restList

      setOpenShiftRest(false)
    }

  return (
    <tr>
      <ColumnOccupationTD>
        {createElementOccupation(workInfo)}
        <HiddenInput name={'staffId_'+ elementId} register={register} />
        <HiddenInput name={'orderId_'+ elementId} register={register} />
        <HiddenInput name={'baseDt_'+ elementId} register={register} />
        <HiddenInput name={'updatedAt_'+ elementId} register={register} />
      </ColumnOccupationTD>
      <ColumnWrap style={{ width: '2rem' }}>
        <CellPanel>
          <CellTop>
            <p>予定</p>
          </CellTop>
          <CellBottom>
          <span>実績</span>
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '7rem' }}>
        <CellPanel>
          <CellTop>
            <SelectGroup
              name={'shiftWorkDivision_' + elementId}
              width='6rem'
              margin='auto'
              options={workDivisions}
              register={register}
              errors={errors}
              onChange={onChange}
            />
          </CellTop>
          <CellBottom>
            <SelectGroup
              name={'attendanceWorkDivision_' + elementId}
              width='6rem'
              margin='auto'
              options={workDivisions}
              register={register}
              errors={errors}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftWorkTotalDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellTop>
          <CellBottom>
          <InputGroup
              name={'attendanceWorkTotalDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestTotalDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellTop>
          <CellBottom>
          <InputGroup
              name={'attendanceRestTotalDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftOverTotalDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellTop>
          <CellBottom>
          <InputGroup
              name={'attendanceOverTotalDt_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftWorkStart_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
          <CellBottom>
          <InputGroup
              name={'attendanceWorkStart_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftWorkEnd_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={2 > workDivision || workDivision > 4}
            />
          </CellTop>
          <CellBottom>
          <InputGroup
              name={'attendanceWorkEnd_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestStart1_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ lign: 'right', paddingRight: '0.5rem' }}
              disabled={(2 > workDivision || workDivision > 4) || restFlag }
              rule ={(restStart1DtError)?{required: regex.onError}:{}}
            />
          </CellTop>
          <CellBottom>
          <InputGroup
              name={'attendanceRestStart1_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestEnd1_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={(2 > workDivision || workDivision > 4) || restFlag }
            />
          </CellTop>
          <CellBottom>
          <InputGroup
              name={'attendanceRestEnd1_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestStart2_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={(2 > workDivision || workDivision > 4) || restFlag }
            />
          </CellTop>
          <CellBottom>
          <InputGroup
              name={'attendanceRestStart2_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestEnd2_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={(2 > workDivision || workDivision > 4) || restFlag }
            />
          </CellTop>
          <CellBottom>
          <InputGroup
              name={'attendanceRestEnd2_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestStart3_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={(2 > workDivision || workDivision > 4) || restFlag }
            />
          </CellTop>
          <CellBottom>
          <InputGroup
              name={'attendanceRestStart3_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRestEnd3_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={(2 > workDivision || workDivision > 4) || restFlag } 
            />
          </CellTop>
          <CellBottom>
          <InputGroup
              name={'attendanceRestEnd3_' + elementId}
              width='3.5rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              onBlur={onBlurTime}
              style={{ textAlign: 'right', paddingRight: '0.5rem' }}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '4rem' }}>
        <CellPanel>
          <CellTop>
            <Button type='button' margin='auto'  padding='0' width='3rem' name='休憩' disabled={!restFlag} onClick={()=>onOpenShift()}/>
          </CellTop>
          <CellBottom>
            <Button type='button' margin='auto'  padding='0' width='3rem' name='休憩' disabled={!restFlag} onClick={()=>onOpenAttendance()}/>
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <ColumnWrap style={{ width: '10rem' }}>
        <CellPanel>
          <CellTop>
          <InputGroup
              name={'shiftRemark_' + elementId}
              width='8rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
            />
          </CellTop>
          <CellBottom>
          <InputGroup
              name={'attendanceRemark_' + elementId}
              width='8rem'
              margin='auto'
              placeholder=''
              register={register}
              errors={errors}
              disabled={true}
            />
          </CellBottom>
        </CellPanel>
      </ColumnWrap>
      <RestDialog open={openShiftRest} onCloseFunc={(restList)=>onClose(restList)} workInfo={workInfo}
      setRest={setValue} elementId={elementId} canWrite={true}></RestDialog>
      <RestDialog open={openAttendanceRest} onCloseFunc={()=>setOpenAttendanceRest(false)} workInfo={workInfo}
      setRest={setValue} elementId={elementId} canWrite={false}></RestDialog>
      <HiddenInput name={"restList_"+elementId} register={register}></HiddenInput>
    </tr>
  )
}

DailyTableRow.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,

  defaultUrl: PropTypes.string,
  workInfo: PropTypes.object
}

export default DailyTableRow
