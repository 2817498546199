import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'


import {onCheckAllcation} from '../../Common/Utilties/ImportUtility'
import { GetApi,MultipartApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
import FUNC from '../../Common/FunctionEnum'
import { createAllocationInfo } from '../../Common/Utilties/ObjectUtitlity'

import MasterPanelWrap from '../Wrap/MasterPanelWrap'
import MasterFlexWrap from '../Wrap/MasterFlexWrap'
import MasterListView from '../Molcules/MasterListView'
import Search from './Search'
import AllocationDetail from './AllocationDetail'

import MasterSelect from '../MasterSelect'

import {onExportCsvFile,makeAllocationCSV,makeAllocationErrorCSV} from '../../Common/Utilties/ExportUtility'
import {calcWorkingRestFreeSeconds} from '../../Common/Utilties/LaborUtility'

export const DefaultUrl = '/api/masterManagement/allocation'

const Header = "受注ID,業務名,スタッフID,スタッフ名称,雇用開始日付,雇用終了日付,リタイア日,"
+"就業形態,就業形態名,出勤時間,退勤時間,休憩開始時間1,休憩終了時間1,休憩開始時間2,休憩終了時間2,休憩開始時間3,休憩終了時間3,"
+"給与形態,給与形態名,料率区分,時間内支給,時間外支給,深夜支給,休日時間内支給,休日時間外支給,休日深夜支給,法休時間内支給,法休時間外支給,法休深夜支給,"
+"請求形態,請求形態名,時間内請求,時間外請求,深夜請求,休日時間内請求,休日時間外請求,休日深夜請求,法休時間内請求,法休時間外請求,法休深夜請求,日額交通費,月額交通費,備考"

const Allocation = (props) => {
    const orderIdRef = useRef()
    const [dataList, setDataList] = useState([])
    const [detail, setDetail] = useState(null) // 未選択状態
    const [selectedId, setSelectedId] = useState(null)
    const [searchOption, setSearchOption] = useState({ orderId: '' })
    const { loginUserInfo } = useSelector(state => state)
    const [pagenation, setPagenation] = useState({})
    const dispatch = useDispatch()
    const alert = useAlert()
    const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.ALLOCATION)

    const selectItem = async (id) => {
      dispatch(SetLoadSpinner(true))
      //setSelectedId(id)
      console.log(id)
      //var tokens = id.split('_');
      setSelectedId(id)
      const result = await GetApi(`${DefaultUrl}/${id}`)
      console.log(result)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert) || setDetail(result.data)
    }

    // 新規登録用初期化処理
    const createNew = (e) => {
      setDetail(createAllocationInfo()) // 新規作成時の値
      setSelectedId(null)
    }

    // 検索APIの実行
    const onSearch = async (createId, page) => {
      dispatch(SetLoadSpinner(true))
      setSearchOption(
        {
          orderId: (orderIdRef.current)?orderIdRef.current.value:'',
        }
      )
      const data = {
        orderId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.orderId : (orderIdRef.current)?orderIdRef.current.value:''):'',
        page: page
      }
      const result = await GetApi(DefaultUrl, data)
      dispatch(SetLoadSpinner(false))
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else {
        const resultList = result.data.responseList.map(item => {
          return {
            id: item.staffId + '/' + item.orderId + '/' + item.employmentStartDt,
            name: item.staffName +'【' +item.employmentStartDt + '】',
            delFlag: false,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          }
        })
        setDataList(resultList)
        setPagenation(result.data)
        const isSelected = resultList.find(item => item.id === (createId || selectedId))
        if (!isSelected) {
          setSelectedId(null)
          setDetail(null) // 未選択状態
        }
      }
    }
    const onKeyDown = (event) => {
      if (event.keyCode === 13) {
        onSearch(null, 1)
      }
    }

    const pageSelectFunc = (pageId) => {
      onSearch(null, pageId)
    }
    const onClickFunction=()=>{
      setViewFunction(!viewFunction)
    }
    const [viewFunction,setViewFunction] = useState(false)

    //ダウンロード処理
    const onExport=async()=>{
      dispatch(SetLoadSpinner(true))
      const data = {
        orderId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.orderId : (orderIdRef.current)?orderIdRef.current.value:''):'',
      }
      const result = await GetApi(DefaultUrl+'/export', data)
      console.log(result)
      dispatch(SetLoadSpinner(false))
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else{
        var today = new Date();
        var day = (''+today.getFullYear()).slice(-2) + ('00' + (today.getMonth())).slice(-2) + ('00' + today.getDate()).slice(-2)
                + ('00' + (today.getHours())).slice(-2) + ('00' + today.getMinutes()).slice(-2) + ('00' + today.getSeconds()).slice(-2)
        var fileName = '引当管理('+day+')'
        onExportCsvFile(makeAllocationCSV(result.data,Header),fileName)
      }
    }

    useEffect(() => {
      if (grants)
        document.title = grants.functionMenuName + ' | Core Time Cloud'
      else
        document.title = 'Core Time Cloud'
    }, [grants])
    //スキーマ取得処理
    const [schema,setSchema] = useState([])
    const onSchema=async()=>{
      const data = {
        tableName: 't_staff_agreement',
      }
      const result = await GetApi('/api/schema', data)
      console.log(result)
      if (result.errorDetail) {
        return null
      } else{        
        setSchema(
          result.data.map((schema)=>{
            let fieldName = schema.columnName.replace(/_[a-z]/g, function(match){return match.toUpperCase().slice(1)});
            
            let [type,maxLength] = schema.dataType.split('(')
            if(maxLength)
            {
              maxLength = maxLength.slice(0,maxLength.length-1)
            }      

            return{
              fieldName:fieldName,
              notNull:schema.notNull,
              maxLength:maxLength,
              type:type,
              comment:schema.comment,
            }
          })
        )
      }
    }

    //画面表示の時
    useEffect(()=>{
      onSearch(null,1)
      onSchema()
    },[])
    
    //インポート処理
    const onImport=(uploadData)=>{
      async function sendPost (requestBody, data) {
        var result
        dispatch(SetLoadSpinner(true))
        console.log(data)
        result = 
           await MultipartApi(`${DefaultUrl}/import`,'POST', requestBody, data)
        
        if (!ErrorHandling(result, alert)) {
          alert.success('保存しました')
   
          onSearch(null,1)
        }else{
          dispatch(SetLoadSpinner(false))
        }
        
      }

      var uploadDataList= new Array();
      console.log(uploadData)
      uploadData.forEach((data)=>{
        let dataRow = {
          orderId: data["受注ID"], 
          staffId: data["スタッフID"], 

          employmentStartDt: data["雇用開始日付"],
          employmentEndDt: data["雇用終了日付"],
          
          retireDt: data["リタイア日"], 
          workType: data["就業形態"], 

          occupation: data["業務名"], 

          workStartDt: data["出勤時間"],
          workEndDt: data["退勤時間"],

          restStart1Dt:data["休憩開始時間1"],
          restEnd1Dt: data["休憩終了時間1"],
          restStart2Dt: data["休憩開始時間2"],
          restEnd2Dt: data["休憩終了時間2"],
          restStart3Dt: data["休憩開始時間3"],
          restEnd3Dt: data["休憩終了時間3"],

          wagesType: data["給与形態"], 
          normalPrice: data["時間内支給"], 
          overPrice: data["時間外支給"], 
          nightPrice: data["深夜支給"], 
          holNormalPrice: data["休日時間内支給"], 
          holOverPrice: data["休日時間外支給"], 
          holNightPrice: data["休日深夜支給"], 
          lawNormalPrice: data["法休時間内支給"], 
          lawOverPrice: data["法休時間外支給"], 
          lawNightPrice: data["法休深夜支給"], 
          billingType: data["請求形態"], 
          normalBillingPrice: data["時間内請求"], 
          overBillingPrice: data["時間外請求"], 
          nightBillingPrice: data["深夜請求"], 
          holNormalBillingPrice: data["休日時間内請求"], 
          holOverBillingPrice: data["休日時間外請求"], 
          holNightBillingPrice: data["休日深夜請求"], 
          lawNormalBillingPrice: data["法休時間内請求"], 
          lawOverBillingPrice: data["法休時間外請求"], 
          lawNightBillingPrice: data["法休深夜請求"], 

          rateDivision: data["料率区分"], 
          dailyTransportExpenses: data["日額交通費"],
          monthlyTransportExpenses: data["月額交通費"],

          remark: data["備考"], 
        }

        {
          let result = calcWorkingRestFreeSeconds([dataRow.workStartDt, dataRow.workEndDt], 
            [dataRow.restStart1Dt, dataRow.restEnd1Dt, dataRow.restStart2Dt, dataRow.restEnd2Dt, dataRow.restStart3Dt, dataRow.restEnd3Dt],28800)
          dataRow.workTotalDt = result[0]
          dataRow.restTotalDt = result[1]
        }
        uploadDataList.push(dataRow)
      })
      
      
      var errorList = new Array();
       [uploadDataList,errorList]=onCheckAllcation(uploadDataList,schema)
      console.log(uploadDataList,errorList)
      if(errorList.length!=0)
      {
          alert.error(<>エラーレコードがあります。<br/>エラーレコードをCSV出力します。</>)
          var today = new Date();
          var day = (''+today.getFullYear()).slice(-2) + ('00' + (today.getMonth())).slice(-2) + ('00' + today.getDate()).slice(-2)
                  + ('00' + (today.getHours())).slice(-2) + ('00' + today.getMinutes()).slice(-2) + ('00' + today.getSeconds()).slice(-2)
          var fileName = '【Error】引当管理マスタデータ_'+day+''
          onExportCsvFile(makeAllocationErrorCSV(errorList,Header),fileName)
      }
      if(uploadDataList.length==0)
      {
          alert.error("更新するデータがありません")
          return
      }

      
      const submitData = new window.FormData()
      submitData.append('formData', new window.Blob([JSON.stringify(uploadDataList)], { type: 'application/json' }))

      sendPost(submitData, uploadDataList)
    }
    if (!grants) return null
    return (
        <MasterPanelWrap id='AllocationWrap'>
        <Search
          title={grants.functionMenuName}
          showButton
          onClick={() => onSearch(null, 1)}
          onKeyDown={(e) => onKeyDown(e)}
          orderIdRef={orderIdRef}
          grants={grants}
          viewFunction={viewFunction}
          onClickFunction={()=>onClickFunction()}
          onExport={()=>onExport()}
          onImport={(uploadData)=>onImport(uploadData)}
        >
        </Search>
        <MasterFlexWrap>
          <MasterSelect/>
            <MasterListView
              dataList={dataList}
              setSelectedId={setSelectedId}
              selectFunction={(id) => selectItem(id)}
              createFunction={() => createNew()}
              canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
              pageSelectFunc={pageSelectFunc}
              pagenation={pagenation}
              isIdView={false}
            />
            <AllocationDetail
              onSearch={onSearch}
              setSelectedId={setSelectedId}
              setDetail={setDetail}
              allocationDetail={detail}
              grants={grants}
            >
            </AllocationDetail>
        </MasterFlexWrap>
        </MasterPanelWrap>
    )
}

export default Allocation
