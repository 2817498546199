import React,{useState} from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import DashboardMain from './DashboardMain'
import OverTimeWork from './OverTimeWork/OverTimeWork'

import IconCollapseImg from '../../image/Collapse.png'

const DashBoardModel = styled.div`
  display:${props => props.display || 'flex'};
  flex-wrap: wrap;
  height: 85vh;
  img {
  }
  ul.mainSearch {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    > li {
      display: flex;
      margin: 0 0 0 1rem;
      label {
        line-height: 32px;
        text-align: right;
        font-size: 12px;
        margin: 0 0.5rem 0 0;
    }
  }
    li.subSearch {
      display: ${props => props.visible ? 'block' : 'none' };
      position: absolute;
      font-size: 16px;
      margin: 0;
      top: 0px;
      z-index: 3;
      left: 0;
    }
  }
`

const DashBoardSelect = styled.div`
    flex-direction: column;
    background-repeat: no-repeat;
    height: 85vh;
    width: 10vw;
    background-color: #cfe2f3;
    overflow-y: none;
`

const DashBoardSelectNone = styled.div`
    flex-direction: column;
    background-repeat: no-repeat;
    overflow-y: none;
    background-color: #FFF;
`

const DashBoardSelectRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1vw;
    background-repeat: no-repeat;
    width: 10vw;
    overflow-y: none;
`

const DashBoardSelectImg = styled.div`
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    overflow-y: none;
`

const DashBoardNoneRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1vw;
    background-repeat: no-repeat;
    overflow-y: none;
`
const DashBoardSelectBody = () => {
    const [viewDasdboardLink,setViewDasdboardLink] = useState(false)
    const [title,setTitle] = useState('')
    const onClick =()=>{    
        setViewDasdboardLink(!viewDasdboardLink)
    }

return (
    <DashBoardModel  visible={viewDasdboardLink}>
    <ul className='mainSearch'>
    <img src={IconCollapseImg} width="24px" height="24px" style={{cursor: 'pointer' }} onClick={()=>onClick()}/>
    <li  className='subSearch'>
    <DashBoardSelect>
        <DashBoardSelectImg>
            <img src={IconCollapseImg} width="24px" height="24px" style={{transform: 'rotate(180deg)' ,cursor: 'pointer' }} onClick={()=>onClick()}/>
        </DashBoardSelectImg>
        <DashBoardSelectRow>
        <Link to="/dashboard/AttendanceMonthly">
        <Tooltip title={'勤怠（月別）'}>
            <div>{'勤怠（月別）'}</div>
        </Tooltip>
        </Link>
        </DashBoardSelectRow>
        <DashBoardSelectRow>
        <Link to="/dashboard/AttendanceWeekly" >
        <Tooltip title={'勤怠（曜日別）'}>
            <div>{'勤怠（曜日別）'}</div>
        </Tooltip>
        </Link>
        </DashBoardSelectRow>
        <DashBoardSelectRow>
        <Link to="/dashboard/Salaried">
        <Tooltip title={'有給休暇'}>
            <div>{'有給休暇'}</div>
        </Tooltip>
        </Link>
        </DashBoardSelectRow>
        <DashBoardSelectRow>
        <Link to="/dashboard/StaffComposition">
        <Tooltip title={'スタッフ構成'}>
            <div>{'スタッフ構成'}</div>
        </Tooltip>
        </Link>
        </DashBoardSelectRow>
        {/* 非表示 
        <DashBoardSelectRow>
        <Link to="/dashboard/OverTimeWork">
        <Tooltip title={'時間外労働'}>
            <div>{'時間外労動'}</div>
        </Tooltip>
        </Link>
        </DashBoardSelectRow> */}
        {/* 非表示 
        <DashBoardSelectRow>
        <Link to="/dashboard/PalDashboard">
        <Tooltip title={'PAL(社内向け)'}>
            <div>{'PAL(社内向け)'}</div>
        </Tooltip>
        </Link>
        </DashBoardSelectRow> */}
    </DashBoardSelect>
    </li>
    </ul>
    </DashBoardModel>
)
}

export default DashBoardSelectBody
