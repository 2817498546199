import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ButtonWrap = styled.button.attrs(props => ({
  // 初期値
  margin: props.margin || '10px',
  width: props.width || '95px',
  display: props.display || 'block'
}))`
    margin: ${props => props.margin};
    width: ${props => props.width};
    min-width: ${props => props.width};
    display: ${props => props.display};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.05em;
    background:${props => props.disabled?'#808080':'#0036ff'};
    height: 36px;
    border-radius: 5px;
    border: none;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    color: white;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
    &:hover{
      background: ${props => props.disabled?'#808080':'#0026b3'};
    }
`
const NormalButton = ({ disabled, onClick, margin, width, name, type, display }) => (
  <ButtonWrap
    disabled={disabled}
    onClick={onClick}
    margin={margin}
    width={width}
    type={type}
    display={display}
  >
    {name}
  </ButtonWrap>
)

NormalButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  margin: PropTypes.string,
  width: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  display: PropTypes.string
}

export default NormalButton
