import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

import { DeleteApi, MultipartApi } from '../../Common/ApiAxios'
import Button from '../../Common/Atoms/NormalButton'
import FUNC from '../../Common/FunctionEnum'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
import { grantDecision } from '../../Common/Utilties/CommonUtility'

import { timeToSec } from '../../Common/Utilties/LaborUtility'

import MasterBaseFormWrap from '../Wrap/MasterBaseFormWrap'
import MasterEditButtonsWrap from '../Wrap/MasterEditButtonsWrap'
import { DefaultUrl } from './Customer'
import CustomerMasterForm from './CustomerMasterForm'

const CustomerDetail = ({ onSearch, setDetail, setSelectedId, customerDetail, reSerach }) => {
  const { register, handleSubmit, setValue, watch, errors, reset, getValues, control } = useForm({ validateCriteriaMode: 'all' })
  const [actionType, setActionType] = useState('POST')
  const [isView, setIsView] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [canWriteStaffBasic, setCanWriteStaffBasic] = useState(false)
  const loginUserInfo = useSelector(state => state.loginUserInfo)
  const alert = useAlert()
  const dispatch = useDispatch()

  // 情報をFormに反映
  useEffect(() => {
    if (customerDetail === null) {
      // 未選択
      setIsView(false)
      return
    }

    if (customerDetail.customerId === undefined) {
      // 新規作成
      setActionType('POST')
      reset()
      setIsView(true)
      setIsDeleted(false)
      setCanWriteStaffBasic(grantDecision('write', FUNC.CUSTOMER, loginUserInfo, null, null))
    } else {
      // 編集
      setActionType('PUT')
      setIsView(true)
      setIsDeleted(customerDetail.delFlag)
      setCanWriteStaffBasic(grantDecision('write', FUNC.CUSTOMER, loginUserInfo, null, null))
    }
  // 顧客情報の変更検知時のみ実行
  // eslint-disable-next-line
  }, [customerDetail])

  // POST・PUT処理
  const onSubmit = (data) => {
    async function sendPost (requestBody, data) {
      console.log(data)
      var result
      dispatch(SetLoadSpinner(true))
      switch (actionType) {
        case 'POST':
          result = await MultipartApi(`${DefaultUrl}`, actionType, requestBody, data)
          break
        case 'PUT':
          result = await MultipartApi(`${DefaultUrl}/${customerDetail.customerId}`, actionType, requestBody, data)
          break
        default:
          result = false
          break
      }
      //部門なしの場合　部門別計算条件マスタ追加
      if (!ErrorHandling(result, alert) && data.departmentFlag) 
      {
        if(customerDetail.departments && customerDetail.departments.length!=0 && customerDetail.departments[0].cusdepartmentId != data.customerId){
          result = await MultipartApi(`${DefaultUrl}/deprtmentCalc`, 'PUT', requestBody, data)
        }else{
          result = await MultipartApi(`${DefaultUrl}/deprtmentCalc`, 'POST', requestBody, data)
        }
      }
      
      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(result, alert)) {
        isDeleted ? alert.success('有効化しました') : alert.success('保存しました')
        setDetail(null)
        setSelectedId(null)
        onSearch(null, 1)
        setActionType('POST')
      }
    }
    //基準時間をhh:mm=>秒に変更
    data.attendanceBaseTime =  timeToSec(data.attendanceBaseTime)
    //更新データの削除フラグ無効
    data.delFlag = false

    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    sendPost(submitData, data)
  }

  // DELETEはForm状態に影響されないため個別で定義
  const onClickHandleDelete = () => {
    async function sendDelete (customerId, data) {
      dispatch(SetLoadSpinner(true))
      const result = await DeleteApi(`${DefaultUrl}/${customerId}/${data.lastUpdatedAt}`, data, true)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert)
      if (result.data){        
        alert.info('無効化しました')
        setDetail(null)
        setSelectedId(null)
        onSearch(null, 1)
        setActionType('POST')}
      }
    if (customerDetail.customerId !== undefined) {
      // const lastUpdatedAt = moment(customerDetail.updatedAt, 'YYYY/MM/DD HH:mm:ss')
      const data = { lastUpdatedAt: customerDetail.updatedAt }
      sendDelete(customerDetail.customerId, data)
    }
  }


  return (
    <>
      <MasterBaseFormWrap onSubmit={handleSubmit(onSubmit)} className='h-adr' style={{display: isView ? 'block' : 'none'}}>
        <CustomerMasterForm
          register={register}
          errors={errors}
          isDeleted={isDeleted}
          getValues={getValues}
          control={control}
          setValue={setValue}
          customerDetail={customerDetail}
          watch={watch}
          canWrite={canWriteStaffBasic}
          actionType={actionType}
          isView={isView}
          reSerach={reSerach}
        />
        <MasterEditButtonsWrap>
          <Button
            display={canWriteStaffBasic ? 'block' : 'none'}
            name={actionType === 'PUT' ? (isDeleted ? '有効化' : '更新') : '登録'}
            height='20px'
            width='90px'
            padding='0'
            margin='0 0 12px 0'
            type='submit'
          />
          {!isDeleted && actionType === 'PUT' &&
            <Button
              //display='none' // TODO 一旦非表示
              height='20px'
              width='90px'
              padding='0'
              name='削除'
              type='button'
              margin='0 0 12px 10px'
              onClick={(e) => onClickHandleDelete(e)}
            />}
        </MasterEditButtonsWrap>
      </MasterBaseFormWrap>
    </>
  )
}

CustomerDetail.propTypes = {
  customerDetail: PropTypes.object,
  onSearch: PropTypes.func,
  reSerach: PropTypes.func,
}

export default CustomerDetail
