import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CellWrap = styled.div`
  ${props => props.addStyle}
  height:5rem;
`

const CellPanel = styled.div`
  display: flex;
  flex-direction: column;
`

const Column = styled.td`
  height:calc(2.5rem);
  vertical-align:middle;
  padding: ${props => props.padding || '0.75rem'};
  text-align:center;
  color:${props => props.color || 'black'};
  font-size:12px;
`

const TableCell = ({ shiftDateCount, attendanceDateCount,color,cellType }) => {

  return (
    <CellWrap>
        <CellPanel>
            <Column padding={cellType==2?'0':'0.75rem'}>{shiftDateCount}</Column>
            <Column color={color} padding={cellType?'0':'0.75rem'}>{attendanceDateCount}</Column>
        </CellPanel>
    </CellWrap>
  )
}

TableCell.propTypes = {
    shiftDateCount: PropTypes.string,
    attendanceDateCount: PropTypes.string
}

export default TableCell
