import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
//import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { diff48Time} from '../../../Common/Utilties/LaborUtility'
import Button from '../../../Common/Atoms/NormalButton'

import Table from './RestTable'
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`
/*
const TitleWrap = styled.div`
  display: flex;
  padding: 1vw;
`
*/
const FormWrap = styled.form`
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
`
const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

export const IconImg = styled.img`
  align-items: left;
  width: 20px;
  height: 20px;
`
export const NotIconImg = styled.div`
  align-items: left;
  width: 20px;
  height: 20px;
`

const RestDialog = ({ open,onCloseFunc,workInfo,elementId,setRest,canWrite}) => {
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();

  const alert = useAlert()
  //const dispatch = useDispatch()
  const [restList,setRestList]=useState(new Array())
  const [attendanceUpdateRestList,setAttendanceUpdateRestList]=useState(new Array())

  useEffect(() => {
    if(workInfo)
    {
        var attendanceRestList =JSON.parse(JSON.stringify(workInfo.attendanceRestList))
        setAttendanceUpdateRestList(attendanceRestList)
        var shiftRestList =JSON.parse(JSON.stringify(workInfo.shiftRestList))

        attendanceRestList.map((restData)=>{
          restData.restStartDt = diff48Time(workInfo.baseDt, restData.restStartDt)
          restData.restEndDt = diff48Time(workInfo.baseDt, restData.restEndDt)    
        })
        shiftRestList.map((restData)=>{
            restData.restStartDt = diff48Time(workInfo.baseDt, restData.restStartDt)
            restData.restEndDt = diff48Time(workInfo.baseDt, restData.restEndDt)
        })

        if(attendanceRestList[0].restNum==null) attendanceRestList.pop()
        if(shiftRestList[0].restNum==null) shiftRestList.pop()

        var maxLength = attendanceRestList.length>=shiftRestList.length?attendanceRestList.length:shiftRestList.length

        var restList = new Array()
        console.log(maxLength,attendanceRestList,shiftRestList)
        for(var index=0;index<maxLength;index++){
          var restData = {isDiffShift:false}
          if(index<shiftRestList.length && index<attendanceRestList.length){
            if(attendanceRestList[index].restStartDt != shiftRestList[index].restStartDt)restData.isDiffShift=true
            if(attendanceRestList[index].restEndDt != shiftRestList[index].restEndDt)restData.isDiffShift=true
          }else{
            restData.isDiffShift=true
          }
          if(index<shiftRestList.length){
            restData.shiftRest = shiftRestList[index]
          }
          if(index<attendanceRestList.length){
            restData.attendanceRest = attendanceRestList[index]
          }
          restData.restNum = index
          restList.push(restData)
        }
        setRestList(restList)
    }
  }, [workInfo])

  const onDelete=(num)=>{
    console.log(num)
    var restNum = null
    restList.map((restData)=>{
        if(restData.restNum == num) {
            restNum = restData.attendanceRest.restNum
        }
    })
    var tmpList = [] 
    var tmp = attendanceUpdateRestList.map((restData)=>{
      if(restData.restNum == restNum) {
        if(restData.isNew){
            restData.isDel = true
            restData.isDiff = false
        }else{
            restData.isDel = true
            restData.isDiff = true
        }
      }else{
        if(!restData.isDel)tmpList.push(restData)
      }
      return restData
    })
    setAttendanceUpdateRestList(JSON.parse(JSON.stringify(tmp)))

    console.log(tmp,tmpList)
    var attendanceRestList =JSON.parse(JSON.stringify(tmpList))
    var shiftRestList = []
    if(workInfo)
    {
      shiftRestList =JSON.parse(JSON.stringify(workInfo.shiftRestList))
      if(shiftRestList[0].restNum==null) shiftRestList.pop()
    }
    shiftRestList.map((restData)=>{
      restData.restStartDt = diff48Time(workInfo.baseDt, restData.restStartDt)
      restData.restEndDt = diff48Time(workInfo.baseDt, restData.restEndDt)
    })

    var maxLength = attendanceRestList.length>=shiftRestList.length?attendanceRestList.length:shiftRestList.length

    var tmpRestList = new Array()
    for(var index=0;index<maxLength;index++){
      var restData = {isDiffShift:false}
      if(index<shiftRestList.length && index<attendanceRestList.length){
        if(attendanceRestList[index].restStartDt != shiftRestList[index].restStartDt)restData.isDiffShift=true
        if(attendanceRestList[index].restEndDt != shiftRestList[index].restEndDt)restData.isDiffShift=true
      }else{
        restData.isDiffShift=true
      }
      if(index<shiftRestList.length){
        restData.shiftRest = shiftRestList[index]
      }
      if(index<attendanceRestList.length){
        restData.attendanceRest = attendanceRestList[index]
      }
      restData.restNum = index
      tmpRestList.push(restData)
    }
    setRestList(tmpRestList)
  }

  const onClose=()=>{
    if(canWrite){
      var sum = 0
      var tmpList = new Array()
      attendanceUpdateRestList.map((restData)=>{
        restList.map((tmp)=>{
          if(tmp.attendanceRest && tmp.attendanceRest.restNum == restData.restNum){
            restData.restStartDt = tmp.attendanceRest.restStartDt
            restData.restEndDt = tmp.attendanceRest.restEndDt
            restData.restTotalDt = tmp.attendanceRest.restTotalDt
          }
        })
      }
      )

      attendanceUpdateRestList.map((restData)=>{
        if(!restData.isDel){
          sum += restData.restTotalDt
        }
        if(restData.isDiff)
        {
          workInfo.isDiff=true
        }
        restData.isDiff=false
      })
      onCloseFunc(attendanceUpdateRestList)
    }else{
      onCloseFunc()
    }
    
    
  }
  const onAdd=()=>{
    var [restNum,isAdd] = lastNum()
    var list = {
        restEndDt: null,
        restStartDt: null,
        restTotalDt: null,
        updatedAt: null,
        updatedId: null,
        baseDt: workInfo.baseDt,
        isDiff: false,
        orderId: workInfo.orderId,
        staffId: workInfo.staffId,
    }
    var tmp = JSON.parse(JSON.stringify(attendanceUpdateRestList))
    if(isAdd){
      list.restNum=restNum
      list.isNew = true
      tmp.push(list)
    }else{
      var tmpData = tmp[restNum]
      list.isNew = tmpData.isNew
      list.restNum = tmpData.restNum
      tmp[restNum] = list
    }
    setAttendanceUpdateRestList(tmp)
    console.log(tmp)

    var tmpList = []
    tmp.map((restData)=>{
      if(!restData.isDel) 
        tmpList.push(restData)
    })
    var attendanceRestList =JSON.parse(JSON.stringify(tmpList))
    var shiftRestList = []
    if(workInfo)
    {
      shiftRestList =JSON.parse(JSON.stringify(workInfo.shiftRestList))
      if(shiftRestList[0].restNum==null) shiftRestList.pop()
    }
    shiftRestList.map((restData)=>{
      restData.restStartDt = diff48Time(workInfo.baseDt, restData.restStartDt)
      restData.restEndDt = diff48Time(workInfo.baseDt, restData.restEndDt)
    })

    var maxLength = attendanceRestList.length>=shiftRestList.length?attendanceRestList.length:shiftRestList.length

    var tmpRestList = new Array()
    console.log(maxLength,attendanceRestList,shiftRestList)
    for(var index=0;index<maxLength;index++){
      var restData = {isDiffShift:false}
      if(index<shiftRestList.length && index<attendanceRestList.length){
        if(attendanceRestList[index].restStartDt != shiftRestList[index].restStartDt)restData.isDiffShift=true
        if(attendanceRestList[index].restEndDt != shiftRestList[index].restEndDt)restData.isDiffShift=true
      }else{
        restData.isDiffShift=true
      }
      if(index<shiftRestList.length){
        restData.shiftRest = shiftRestList[index]
      }
      if(index<attendanceRestList.length){
        restData.attendanceRest = attendanceRestList[index]
      }
      restData.restNum = index
      tmpRestList.push(restData)
    }
    setRestList(tmpRestList)
  }
  const lastNum=()=>{
    var maxNum = -1
    var delNum = -1
    var isAdd = true
    attendanceUpdateRestList.map((restData,index)=>{
        if(restData.isDel && delNum == -1){
          delNum = index
          isAdd = false
        }
        if(restData.restNum>maxNum && restData.restNum != null) maxNum=restData.restNum
    })
    if(delNum != -1) return [delNum,isAdd]
    else return [parseInt(maxNum)+1,isAdd]
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='休憩'
      aria-describedby='休憩ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap>
    <div className={classes.paper}>
      <ContentWrap>
      <TableWrap>
      <Table restList={restList}
        register={register}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        reset={reset}
        errors={errors}
        workInfo={workInfo}
        onDelete={(num)=>onDelete(num)}
        canWrite={canWrite}/>
      </TableWrap>
      </ContentWrap>
      <ButtonsWrap>
        {canWrite && <Button name='追加' type='button' padding='0px' onClick={onAdd} />}
        <Button name='閉じる' type='button' padding='0px' onClick={onClose} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

RestDialog.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedAttendanceFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    iconName: PropTypes.string,
    text: PropTypes.string,
    dailyWorkInfoList: PropTypes.array
}

export default RestDialog