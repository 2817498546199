import React, { useState, useEffect, useRef ,useCallback} from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import { useHistory } from 'react-router'
import { Prompt } from 'react-router-dom';
import { GetApi ,MultipartApi} from '../Common/ApiAxios'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../Common/ErrorHandling'
import FUNC from '../Common/FunctionEnum'
import AttendancePanelWrap, { AttendanceFlexWrap,AttendanceCountPanelWrap,AttendancePagingPanelWrap,PagingIconImg,AttendancePagingIconPanelWrap,PagingIconNextImg,AttendancePageButtonWrap } from './Wrap/AttendancePanelWrap'
import Search from './Search'
import DailyTable from './Molcules/DailyTable/DailyTable'

import { formatRound48Time, ValidUpdateShift,dateFormat,calcWorkingRestFreeSeconds} from '../Common/Utilties/LaborUtility'

import IconPageNextImg from '../../image/PageNext.png'
import IconPageBackImg from '../../image/PageBack.png'
import IconPageLastImg from '../../image/PageLast.png'
import IconPageTopImg from '../../image/PageTop.png'

export const DefaultUrl = '/api/diffAttendanceManagement/diffAttendance'

const DiffAttendance = (props) => {
  const fromDateRef = useRef()
  const toDateRef = useRef()
  const staffIdRef = useRef()
  const staffNameRef = useRef()
  const [searchOption, setSearchOption] = useState({ shiftDt: '', staffId: '', staffName: '',postType:'',postId:'',departmentId:'' })
  const { loginUserInfo } = useSelector(state => state)
  const [staffWorkInfoList, setStaffWorkInfoList] = useState([])
  const [staffWorkInfoAllList, setStaffWorkInfoAllList] = useState([])
  const [dateDataList, setDateDataList] = useState([])
  const [pagenation, setPagenation] = useState({})
  const [staffCount, setStaffCount] = useState(0)
  const dispatch = useDispatch()
  const alert = useAlert()
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.DIFF_ATTENDANCE)
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [viewFunction,setViewFunction] = useState(false)
  const pageRecord = 7
  const [pageLast, setPageLast] = useState(999)
  const [pageNum, setPageNum] = useState(1)
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()

  const [workDivisions, setWorkDivisions] = useState(<></>)

  // 遷移可能かどうか判定
  const handleBlockedNavigation = () => {
    var updateFlag = false
    staffWorkInfoAllList.forEach((staffWorkInfo)=>{
      staffWorkInfo.dailyWorkInfoList.forEach(dailyWorkInfo => {
        if(dailyWorkInfo.workInfoList.length != 1) return
        dailyWorkInfo.workInfoList.forEach((workInfo)=>{
          if(workInfo.isDiff) updateFlag = true
        })
    })})
    if (updateFlag) {
      const is_ok = window.confirm('編集中のデータを破棄しますか');
      return is_ok;
    }
    return true;
  };


  //出勤区分取得
  const getWorkDivisions = useCallback(async () => {
    //dispatch(SetLoadSpinner(true))
    const result = await GetApi('/api/masterManagement/classificationValues/workDivisions')
    //dispatch(SetLoadSpinner(false))
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setWorkDivisions(options)
    }
  }, [setWorkDivisions, alert])

  // 検索APIの実行
  const onSearch = async (createId, page) => {
    var updateFlag = false
    staffWorkInfoAllList.forEach((staffWorkInfo)=>{
      staffWorkInfo.dailyWorkInfoList.forEach(dailyWorkInfo => {
        if(dailyWorkInfo.workInfoList.length != 1) return
        dailyWorkInfo.workInfoList.forEach((workInfo)=>{
          if(workInfo.isDiff) updateFlag = true
        })
    })})

    if(updateFlag){
      const is_ok = window.confirm('編集中のデータを破棄しますか');
      if(!is_ok)
      {
        console.log( fromDateRef.current,searchOption.startDt,toDateRef.current,searchOption.endDt)
        fromDateRef.current = searchOption.startDt
        toDateRef.current = searchOption.endDt
        return searchOption
      }
    }

    dispatch(SetLoadSpinner(true))
    await getWorkDivisions()
    setSearchOption(
      {
        startDt: fromDateRef.current,
        endDt: toDateRef.current,
        staffId: (staffIdRef.current)?staffIdRef.current.value:'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        postType:postType,
        postId:postId,
        departmentId:departmentId
      }
    )
    const data = {
      startDt: fromDateRef.current,
      endDt: toDateRef.current,
      staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      page: page
    }
    console.log(data)
    //const resultDate = await GetApi('/api/masterManagement/calendar/month', { targetDt: attendanceDateRef.current })
    const resultDate = await GetApi('/api/masterManagement/calendar/range', { startDt: fromDateRef.current, endDt: toDateRef.current })
    if (resultDate.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(resultDate, alert)
      return null
    } else {
      //setDataDateList(resultDate.data.responseList)
    }
    const resultAttendance = await GetApi(DefaultUrl + '/daily', data)

    if (resultAttendance.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(resultAttendance, alert)
      return null
    } else {
      console.log(resultAttendance.data.responseList)
      resultAttendance.data.responseList.forEach((staffWorkInfo)=>{
        var tmpList = new Array()
        staffWorkInfo.dailyWorkInfoList.map((daily)=>{
            var flag = false
            daily.workInfoList.map((info)=>{
                if(IsDiffShiftAndAttedance(info)) flag = true  
            })
            if(flag) tmpList.push(daily)
        })
        staffWorkInfo.dailyWorkInfoList = tmpList
      })
      
      setDateDataList(resultDate.data.responseList)
      setStaffWorkInfoAllList(resultAttendance.data.responseList.slice())
      var count = 0
      resultAttendance.data.responseList.forEach((staffWorkInfo)=>{
        count += staffWorkInfo.dailyWorkInfoList.length
      })
      setStaffCount(count)
      if(count==0){
        alert.error("検索条件に該当するデータがありません")
      }
      setPageLast(Math.ceil(count/pageRecord))
      setPageNum(page)
      setPagenation(resultAttendance.data)
      var tmp = resultAttendance.data.responseList.slice()
      makePage(tmp,page)
    }
    dispatch(SetLoadSpinner(false))
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
    setViewFunction(false);
  }
  /// <summary>
  /// シフトと勤怠に差異があるか判定
  /// </summary>
  /// <param name="row">シフトと勤怠のデータ</param>
  /// <returns>true:差異あり false:差異なし</returns>
  const IsDiffShiftAndAttedance=(row)=>
  {
    var keys = new Array();
    keys.push("WorkDivision");
    keys.push("WorkStartDt");
    keys.push("WorkEndDt");
    keys.push("WorkTotalDt");
    keys.push("RestTotalDt");
    if(!row["restFlag"]){
      keys.push("RestStart1Dt");
      keys.push("RestStart2Dt");
      keys.push("RestEnd2Dt");
      keys.push("RestStart3Dt");
      keys.push("RestEnd3Dt");
      keys.push("TransferDt");
    }
  
    // 以下は不要のはず
    // keys.push("normal_time");
    // keys.push("over_time");
    // keys.push("night_time");
    // keys.push("hol_normal_time");
    // keys.push("hol_over_time");
    // keys.push("hol_night_time");
    // keys.push("law_normal_time");
    // keys.push("law_over_time");
    // keys.push("law_night_time");
    var rFlag = false
    keys.forEach( key =>
    {
        var shift = row["shift" + key];
        var attendance = row["attendance" + key];
        if (shift == null && attendance == null) {
            return;
        }
        else if (shift != null && attendance != null) {
            if (shift != attendance) rFlag = true;
        }
        else
        {
            //return false;
        }
    })
  
    if(row["restFlag"]){
      if(row["attendanceRestList"].length != row["shiftRestList"].length){
        rFlag = false
      }else{
        row["attendanceRestList"].map((rest,index)=>{
          if(rest.startDt != row["shiftRestList"][index].startDt)rFlag = false
          if(rest.endDt != row["shiftRestList"][index].endDt)rFlag = false
        })
      }
    }

    return rFlag;
  }

  //ページング設定
  const makePage = (data,page) =>{
    if(page!=0){
      console.log(data)
      console.log(page)
      var minRecord = (page-1)*pageRecord
      var maxRecord = (page*pageRecord)
      var count = 0 
      var tmpList = new Array()
      data.forEach((staffWorkInfo)=>{
        var recordLen = staffWorkInfo.dailyWorkInfoList.length
        //console.log(staffWorkInfo.dailyWorkInfoList)
        var beforeCount = count
        count += recordLen
        var start = -1
        var end = -1
        if(count>minRecord && beforeCount<maxRecord){
          if(beforeCount < minRecord){
            start = minRecord - beforeCount
          }else{
            start = 0
          }

          if(count < maxRecord){
            end = recordLen
            //console.log(start,end)
          }else{
            end = maxRecord - beforeCount
          }
          
        }
        //console.log(beforeCount,count)
        var tmp = new Array() 
        if(start != -1 && end != -1){
            var tmpCount = 0
            staffWorkInfo.dailyWorkInfoList.forEach((dailyList)=>{
              if(tmpCount>=start && tmpCount<end)
              tmp.push(dailyList)
              tmpCount+=1
            }
          )
        }
        tmpList.push({dailyWorkInfoList:tmp,
          staffId: staffWorkInfo.staffId,
          staffName: staffWorkInfo.staffName,
          restFlag:staffWorkInfo.restFlag
        })
      })
      setStaffWorkInfoList(tmpList)
    }else{
      setStaffWorkInfoList(data)
    }
    
  }

  const [actionType, setActionType] = useState('POST')
  // POST・PUT処理
  const onSubmit = (data) => {
    async function sendPost (requestBody, data) {
      var resultAttendance
      dispatch(SetLoadSpinner(true))
      console.log(data)
      resultAttendance = 
        await MultipartApi(`${DefaultUrl}`,actionType, requestBody, data)
      
      if (!ErrorHandling(resultAttendance, alert)) {
        alert.success('保存しました')
        onReSearch(0,pageNum)
      }
      dispatch(SetLoadSpinner(false))
    }
    if(staffWorkInfoList.length==0){
      alert.error("更新するデータがありません")
      return
    }
    const submitData = new window.FormData()
    console.log(staffWorkInfoList)
  
    data = makeSendData(data)
    console.log(data)
    if(ValidUpdateShift(data))
    {
      alert.error("入力項目にエラーがあります")
      return
    }

    //再検索用情報付与
    data = {'formDatas': data,
            'startDt': searchOption.startDt,
            'endDt': searchOption.endDt,
            'staffId': searchOption.staffId,
            'staffName': searchOption.staffName,
            'postType':searchOption.postType,
            'postId':(searchOption.postId!=undefined && searchOption.postId != -1) ? searchOption.postId:'',
            'departmentId':(searchOption.departmentId != null && searchOption.departmentId!=undefined)? searchOption.departmentId:'',
            'page': 1}
    console.log(data.startDt)
    if(data.startDt=='' || data.formDatas.length==0)
    {
        alert.error("更新するデータがありません")
        return
    }
    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    sendPost(submitData, data)
  }
  //更新データ作成処理
  const makeSendData = (data) =>
  {
    var dailyList= new Array();
    staffWorkInfoAllList.forEach((staffWorkInfo)=>{
      staffWorkInfo.dailyWorkInfoList.forEach(dailyWorkInfo => {
        if(dailyWorkInfo.workInfoList.length != 1) return
        dailyWorkInfo.workInfoList.forEach((workInfo)=>{
          let dailyRow = {
              orderId: workInfo.orderId, //getValues('orderId_'+id),
              staffId: workInfo.staffId, //getValues('staffId_'+id),
              baseDt: workInfo.baseDt, //getValues('baseDt_'+id),
              workDivision: workInfo.attendanceWorkDivision,
              workStartDt: (workInfo.attendanceWorkStartDt),
              workEndDt: (workInfo.attendanceWorkEndDt),
              restStart1Dt: (workInfo.attendanceRestStart1Dt),
              restEnd1Dt: (workInfo.attendanceRestEnd1Dt),
              restStart2Dt: (workInfo.attendanceRestStart2Dt),
              restEnd2Dt: (workInfo.attendanceRestEnd2Dt),
              restStart3Dt: (workInfo.attendanceRestStart3Dt),
              restEnd3Dt: (workInfo.attendanceRestEnd3Dt),

              remark: null,
              updatedAt: dateFormat(workInfo.attendanceUpdatedAt)
          }
          //差異チェック
          if(workInfo.isDiff)
          {
              //勤務時間,休憩時間計算
              // let result = calcWorkingSeconds([dailyRow.workStartDt, dailyRow.workEndDt], 
              //     [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt, 
              //       dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt], workInfo.attendanceBaseTime)

              //休憩データ算出
              var restList = new Array()
              var addRestList = new Array()
              var deleteRestList = new Array()
              var updateRestList = new Array()
              workInfo.attendanceRestList.map((restData)=>{
                if(restData.restNum == null) return
                restData.restStartDt = formatRound48Time(restData.restStartDt,workInfo.fractionUnit*(-1))
                restData.restEndDt = formatRound48Time(restData.restEndDt,workInfo.fractionUnit)
                if(!restData.isDel){
                  restList.push(restData.restStartDt)
                  restList.push(restData.restEndDt)
                }
                if(restData.isNew){
                  addRestList.push(restData)
                }else if(restData.isDel){
                  deleteRestList.push(restData)
                }else{
                  updateRestList.push(restData)
                }
              })
              dailyRow.addRestList = addRestList
              dailyRow.deleteRestList = deleteRestList
              dailyRow.updateRestList = updateRestList

              if(!staffWorkInfo.restFlag) restList = [dailyRow.restStart1Dt, dailyRow.restEnd1Dt, dailyRow.restStart2Dt,dailyRow.restEnd2Dt, dailyRow.restStart3Dt, dailyRow.restEnd3Dt]
              let result = calcWorkingRestFreeSeconds([dailyRow.workStartDt, dailyRow.workEndDt], restList, workInfo.attendanceBaseTime)

              dailyRow.workTotalDt = result[0]
              dailyRow.restTotalDt = result[1]
              dailyRow.overTotalDt = result[2]
              dailyRow.nightTime = result[3]
              dailyList.push(dailyRow)

              console.log(workInfo)
          }
        })
      })
    })
    console.log(dailyList)
    return dailyList
  }

  // 検索APIの実行
  const onReSearch = async (createId, page) => {

    dispatch(SetLoadSpinner(true))
    await getWorkDivisions()
    setSearchOption(
      {
        startDt: fromDateRef.current,
        endDt: toDateRef.current,
        staffId: (staffIdRef.current)?staffIdRef.current.value:'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        postType:postType,
        postId:postId,
        departmentId:departmentId
      }
    )
    const data = {
      startDt: fromDateRef.current,
      endDt: toDateRef.current,
      staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
      staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      postType:postType,
      postId:(postId!=undefined && postId != -1) ? postId:'',
      departmentId:(departmentId != null && departmentId!=undefined)? departmentId:'',
      page: page
    }
    console.log(data)
    //const resultDate = await GetApi('/api/masterManagement/calendar/month', { targetDt: attendanceDateRef.current })
    const resultDate = await GetApi('/api/masterManagement/calendar/range', { startDt: fromDateRef.current, endDt: toDateRef.current })
    if (resultDate.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(resultDate, alert)
      return null
    } else {
      //setDataDateList(resultDate.data.responseList)
    }
    const resultAttendance = await GetApi(DefaultUrl + '/daily', data)

    if (resultAttendance.errorDetail) {
      dispatch(SetLoadSpinner(false))
      ErrorHandling(resultAttendance, alert)
      return null
    } else {
      console.log(resultAttendance.data.responseList)
      resultAttendance.data.responseList.forEach((staffWorkInfo)=>{
        var tmpList = new Array()
        staffWorkInfo.dailyWorkInfoList.map((daily)=>{
            var flag = false
            daily.workInfoList.map((info)=>{
                if(IsDiffShiftAndAttedance(info)) flag = true  
            })
            if(flag) tmpList.push(daily)
        })
        staffWorkInfo.dailyWorkInfoList = tmpList
      })
      
      setDateDataList(resultDate.data.responseList)
      setStaffWorkInfoAllList(resultAttendance.data.responseList.slice())
      var count = 0
      resultAttendance.data.responseList.forEach((staffWorkInfo)=>{
        count += staffWorkInfo.dailyWorkInfoList.length
      })
      setStaffCount(count)
      if(count==0){
        alert.error("検索条件に該当するデータがありません")
      }
      setPageLast(Math.ceil(count/pageRecord))
      setPageNum(page)
      setPagenation(resultAttendance.data)
      var tmp = resultAttendance.data.responseList.slice()
      makePage(tmp,page)
    }
    dispatch(SetLoadSpinner(false))
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
    setViewFunction(false);
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType==0?1:0)
    }
  }

  const pageSelectFunc = (pageId) => {
    onSearch(null, pageId)
  }
  
  const updatedAttendanceFunc = () => {
    onSearch(null, pageNum)
  }

  useEffect(() => {
    if (grants)
      document.title = grants.functionMenuName + ' | Core Time Cloud'
    else
      document.title = 'Core Time Cloud'
  }, [grants])

  useEffect(() => {
    setPost()
    onSearch(null,1)
  }, [])



  const setPost=()=>{
    const serachCompany = async()=>{
      const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      }
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.companyId,
          name: item.companyName+'('+item.companyId+')',
        }
      })
      setPostList(resultList)
      return true
    }
    const serachCustomer = async()=>{
      const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } 
      const resultList = result.data.responseList.map(item => {
        return {
          index: item.customerId,
          name: item.customerName+'('+item.customerId+')',
        }
      })
      
      setPostList(resultList)
      return true
    }
    if(postType==0){
      serachCompany()
    }else{
      serachCustomer()
    }
  }
  
  //会社顧客変更処理
  useEffect(() => {
    setPost()
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postType])
  
  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
    console.log(viewSubSearch)
  }
  
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  const onPageSeletClick = (pageId) => {
      console.log(pageId)
      //onSearch(null,pageId)
      makePage(staffWorkInfoAllList,pageId)
      setPageNum(pageId)
  }
  const onPageNextSeletClick = (pageId) => {
    if(pageNum!=pageLast){
      onPageSeletClick(pageId)
    }
    //setStaffWorkInfoList(staffWorkInfoAllList)
    //makePage(new Array(),pageId)
  }
  const onPageBackSeletClick = (pageId) => {
    if(pageNum!=1){
      onPageSeletClick(pageId)
    }
    //console.log(staffWorkInfoAllList)
    //setStaffWorkInfoList(staffWorkInfoAllList)
    //makePage(new Array(),pageId)
  }
  const onPageChangeLabelClick = () => {
    if(pageType==0){
      setPageType(1)
      setPageNum(0)
      makePage(staffWorkInfoAllList,0)
    }else{
      setPageType(0)
      setPageNum(1)
      makePage(staffWorkInfoAllList,1)
    }
    setViewFunction(false);
  }
  
  //ページ遷移前の更新破棄の確認
  //更新キャンセル処理
  useEffect(() => {
    // イベントの設定
    window.addEventListener('beforeunload', onUnload);
  
    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload);
    }
  })
  const onUnload = (e) => {
    var updateFlag = false
    staffWorkInfoAllList.forEach((staffWorkInfo)=>{
      staffWorkInfo.dailyWorkInfoList.forEach(dailyWorkInfo => {
        if(dailyWorkInfo.workInfoList.length != 1) return
        dailyWorkInfo.workInfoList.forEach((workInfo)=>{
          if(workInfo.isDiff) updateFlag = true
        })
    })})
    if(updateFlag){
      e.preventDefault();
      e.returnValue = '';
    }
  }

  if (!fromDateRef.current) {
    var today = new Date();
    fromDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
    toDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)
  }

  if (!grants) return null
  return (
      <AttendancePanelWrap id='AttendanceWrap'>
        <Prompt when={true} message={handleBlockedNavigation} />
        <Search
          title= {grants.functionMenuName}
          onClick={() => onSearch(null, pageType==0?1:0)}
          onClickOption={(e) => onClickOptionBtn(e)}
          onKeyDown={(e) => onKeyDown(e)}
          fromDateRef={fromDateRef}
          toDateRef={toDateRef}
          staffIdRef={staffIdRef}
          staffNameRef={staffNameRef}
          viewSubSearch={viewSubSearch}
          grants={grants}
          postList={postList}
          postId={postId}
          setPostId={setPostId}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          postType={postType}
          setPostType={setPostType}
          onClickFunction={(e)=>onClickFunction(e)}
          viewFunction={viewFunction}
          onUpadate={()=>onSubmit()}
          onPageTypeChangeClick={() => onPageChangeLabelClick()}
        >
        </Search>
        <AttendanceCountPanelWrap>
          <label>検索ヒット件数:{staffCount}件</label>
        </AttendanceCountPanelWrap>
        <AttendanceFlexWrap>
            <DailyTable
              register={register}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              reset={reset}
              errors={errors}

              workDivisions={workDivisions}
              dateDataList={dateDataList}
              staffWorkInfoList={staffWorkInfoList}
              updatedAttendanceFunc={updatedAttendanceFunc}
              defaultUrl={DefaultUrl}
              count={staffCount}
            >
            </DailyTable>
        </AttendanceFlexWrap>
        {
          staffWorkInfoList.length>0 && staffCount!=0 &&
          <AttendancePagingPanelWrap>
          {/* <AttendancePagingChangePanelWrap onClick={() => onPageChangeLabelClick()}>表示切替</AttendancePagingChangePanelWrap> */}
          { pageType==0 &&
            <AttendancePageButtonWrap>       
            <PagingIconImg src={IconPageTopImg} onClick={() => onPageBackSeletClick(1)}></PagingIconImg>
            <PagingIconNextImg src={IconPageBackImg} onClick={() => onPageBackSeletClick(pageNum-1)}></PagingIconNextImg>
            <AttendancePagingIconPanelWrap>{pageNum}</AttendancePagingIconPanelWrap>
            <AttendancePagingIconPanelWrap>/</AttendancePagingIconPanelWrap>
            <AttendancePagingIconPanelWrap>{pageLast}</AttendancePagingIconPanelWrap>
            <PagingIconNextImg src={IconPageNextImg} onClick={() => onPageNextSeletClick(pageNum+1)}></PagingIconNextImg>
            <PagingIconImg src={IconPageLastImg} onClick={() => onPageNextSeletClick(pageLast)}></PagingIconImg>
            
            </AttendancePageButtonWrap>
          }
          </AttendancePagingPanelWrap>
        }
      </AttendancePanelWrap>
  )
}

export default DiffAttendance
