import React from 'react'
import styled from 'styled-components'

const LabelStyle = styled.label`
  width: 148px;
  height: 12px;
  color: #444;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.8px;
`

const FooterWrap = styled.footer`
  position: absolute;
  bottom: 5px;
  right: 15px;
`

const Footer = () => (
  <FooterWrap>
    <LabelStyle>&copy; 2020 PAL Co., Ltd.</LabelStyle>
  </FooterWrap>
)

export default Footer
