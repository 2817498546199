import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { Prompt } from 'react-router-dom';

import { GetApi,MultipartApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
import FUNC from '../../Common/FunctionEnum'
import { createStaffInfo } from '../../Common/Utilties/ObjectUtitlity'

import {onExportCsvFile,makeStaffCSV,makeStaffCSVError} from '../../Common/Utilties/ExportUtility'
import {onCheckStaff} from '../../Common/Utilties/ImportUtility'

import MasterPanelWrap from '../Wrap/MasterPanelWrap'
import MasterFlexWrap from '../Wrap/MasterFlexWrap'
import MasterListView from '../Molcules/MasterListView'
import Search from './Search'
import StaffDetail from './StaffDetail'

import MasterSelect from '../MasterSelect'

export const DefaultUrl = '/api/masterManagement/staff'

const Header = 'スタッフID,スタッフ名称,スタッフ名称（カナ）,スタッフ区分,会社ID,部門ID,'
  +'ログインフラグ,入社日,退職日,性別,生年月日,郵便番号,都道府県,市区町村,町域名,建物名,電話番号（自宅）,電話番号（携帯）,E - MAIL（PC）,E - MAIL（携帯）,連携用ID,'
  +'勤務希望開始時間,勤務希望終了時間,時間外勤務,基本シフト（月）,基本シフト（火）,基本シフト（水）,基本シフト（木）,基本シフト（金）,基本シフト（土）,基本シフト（日）,基本シフト（祝）,'
  +'HOPE_UPDATED_AT,権限名称ID,GRANT_UPDATED_AT'
const Staff = (props) => {
    const staffIdRef = useRef()
    const staffNameRef = useRef()
    const [dataList, setDataList] = useState([])
    const [detail, setDetail] = useState(null) // 未選択の状態
    const [selectedId, setSelectedId] = useState(null)
    const [searchOption, setSearchOption] = useState({ staffId: '', staffName: '' })
    const { loginUserInfo } = useSelector(state => state)
    const [pagenation, setPagenation] = useState({})
    const dispatch = useDispatch()
    const alert = useAlert()
    const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.STAFF)
    
    const [updateFlag, setUpdateFlag] = useState(false)
    
    const selectItem = async (id) => {
      if (updateFlag) {
        const is_ok = window.confirm('編集中のデータを破棄しますか');
        if(!is_ok)
        {
          return null
        }
        setUpdateFlag(false)
      }

      dispatch(SetLoadSpinner(true))
      setSelectedId(id)
      const result = await GetApi(`${DefaultUrl}/${id}`)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert) || setDetail(result.data)
    }

    // 新規登録用初期化処理
    const createNew = (e) => {
      setDetail(createStaffInfo()) // 新規作成時の値
      setSelectedId(null)
    }

    // 検索APIの実行
    const onSearch = async (createId, page) => {
      if (updateFlag) {
        const is_ok = window.confirm('編集中のデータを破棄しますか');
        if(!is_ok)
        {
          return null
        }
        setUpdateFlag(false)
      }

      dispatch(SetLoadSpinner(true))
      setSearchOption(
        {
          staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
          staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        }
      )
      const data = {
        staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        page: page
      }
      const result = await GetApi(DefaultUrl, data)
      dispatch(SetLoadSpinner(false))
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else {
        const resultList = result.data.responseList.map(item => {
          return {
            index: item.staffId,
            id: item.staffId,
            name: item.staffName,
            delFlag: item.delFlag,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          }
        })
        setDataList(resultList)
        setPagenation(result.data)
        const isSelected = resultList.find(item => item.index === (createId || selectedId))
        if (!isSelected) {
          setSelectedId(null)
          setDetail(null) // 未選択の状態
        }
      }
    }
    // 検索APIの実行
    const onReSearch = async (createId, page) => {
      setUpdateFlag(false)

      dispatch(SetLoadSpinner(true))
      setSearchOption(
        {
          staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
          staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        }
      )
      const data = {
        staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        page: page
      }
      const result = await GetApi(DefaultUrl, data)
      dispatch(SetLoadSpinner(false))
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else {
        const resultList = result.data.responseList.map(item => {
          return {
            index: item.staffId,
            id: item.staffId,
            name: item.staffName,
            delFlag: item.delFlag,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          }
        })
        setDataList(resultList)
        setPagenation(result.data)
        const isSelected = resultList.find(item => item.index === (createId || selectedId))
        if (!isSelected) {
          setSelectedId(null)
          setDetail(null) // 未選択の状態
        }
      }
    }
    const onKeyDown = (event) => {
      if (event.keyCode === 13) {
        onSearch(null, 1)
      }
    }

    const pageSelectFunc = (pageId) => {
      onSearch(null, pageId)
    }

    const [postList,setPostList] = useState(new Array())
    const [departmentList,setDepartmentList] = useState(null)

    const [comPostList,setComPostList] = useState(new Array())
    const [comDepartmentList,setComDepartmentList] = useState(new Array())
    const [cusPostList,setCusPostList] = useState(new Array())
    const [cusDepartmentList,setCusDepartmentList] = useState(new Array())
    //会社・会社部門取得処理
    const setPost=()=>{
      const serachCompany = async()=>{
        const result = await GetApi('/api/masterManagement/company/all')
        console.log(result)
        if (result.errorDetail || !result.data) {
          ErrorHandling(result, alert)
          return null
        }else{
            var  companyList = new Array()
            var  comDepartmentList = new Array()
            const resultList = result.data.map(item => {
              companyList.push(item.companyId)
              item.departments.map((deapartment)=>{
                comDepartmentList.push(deapartment.comDepartmentId)
              })
              return {
                index: item.companyId,
                name: item.companyName+'('+item.companyId+')',
                departments: item.departments
              }
            })
            setPostList(resultList)
            setComPostList(companyList)
            setComDepartmentList(comDepartmentList)
            console.log(companyList)
            console.log(comDepartmentList)
            // var postRows = 
            // resultList.map(post=>{
            //   return <><option value={post.index} >{post.name}</option></> 
            // })
            // setRows(postRows)
      
            if(resultList.length>0){
              //setCompanyId(resultList[0].index)
              //setCompanyName(resultList[0].name)
              setDepartmentList(result.data[0].departments)
            }
            return resultList[0].index
        }        
      }
      const serachCustomer = async()=>{
        const result = await GetApi('/api/masterManagement/customer/all')
        console.log(result)
        if (result.errorDetail || !result.data) {
          ErrorHandling(result, alert)
          return null
        }else{
          var  customerList = new Array()
          var  cusDepartmentList = new Array()
          const resultList = result.data.map(item => {
            customerList.push(item.customerId)
            item.departments.map((deapartment)=>{
              cusDepartmentList.push(deapartment.cusDepartmentId)
            })
              return {
                index: item.customerId,
                name: item.customerName+'('+item.customerId+')',
                departments: item.departments
              }
            })
            //setPostList(resultList)
      
            setCusPostList(customerList)
            setCusDepartmentList(cusDepartmentList)
            // var postRows = 
            // resultList.map(post=>{
            //   return <><option value={post.index} >{post.name}</option></> 
            // })
            // setRows(postRows)
      
            return resultList[0].index
        }        
      }
      serachCompany()
      serachCustomer()
    }
    const [grantList,setGrantList] = useState(new Array())
    // 検索APIの実行
    const onGrant = async (createId, page) => {

        const result = await GetApi('/api/masterManagement/grant/all', {})
        console.log(result)
        if (result.errorDetail) {
          ErrorHandling(result, alert)
          return null
        } else {

          const resultList = result.data.values.map(item => {
            return item.grantNameId+''
          })
          resultList.push('0')
          setGrantList(resultList)
        }
    }
    useEffect(() => {
      if (grants)
        document.title = grants.functionMenuName + ' | Core Time Cloud'
      else
        document.title = 'Core Time Cloud'
    }, [grants])

    //画面表示の時
    useEffect(()=>{
      onSearch(null,1)
      setPost()
      onGrant()
      onSchema()
    },[])

    //スキーマ取得処理
    const [schema,setSchema] = useState([])
    const onSchema=async()=>{
      const data = {
        tableName: 'm_staff',
      }
      const result = await GetApi('/api/schema', data)
      console.log(result)
      if (result.errorDetail) {
        return null
      } else{        
        setSchema(
          result.data.map((schema)=>{
            let fieldName = schema.columnName.replace(/_[a-z]/g, function(match){return match.toUpperCase().slice(1)});
            
            let [type,maxLength] = schema.dataType.split('(')
            if(maxLength)
            {
              maxLength = maxLength.slice(0,maxLength.length-1)
            }      

            return{
              fieldName:fieldName,
              notNull:schema.notNull,
              maxLength:maxLength,
              type:type,
              comment:schema.comment,
            }
          })
        )
      }
    }
    //ページ遷移前の更新破棄の確認
    //更新キャンセル処理
    useEffect(() => {
      // イベントの設定
      window.addEventListener('beforeunload', onUnload);
    
      return () => {
        // イベントの設定解除
        window.removeEventListener('beforeunload', onUnload);
      }
    })
    const onUnload = (e) => {
      if(updateFlag){
        e.preventDefault();
        e.returnValue = '';
      }
    }
    // 遷移可能かどうか判定
    const handleBlockedNavigation = () => {
      if (updateFlag) {
        const is_ok = window.confirm('編集中のデータを破棄しますか');
        return is_ok;
      }
      return true;
    };
    const [viewFunction,setViewFunction] = useState(false)
    const onClickFunction=()=>{
      setViewFunction(!viewFunction)
    }
    

    //ダウンロード処理
    const onExport=async()=>{
      dispatch(SetLoadSpinner(true))
      setSearchOption(
        {
          staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
          staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        }
      )
      const data = {
        staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
      }
      const result = await GetApi(DefaultUrl+'/export', data)
      console.log(result)
      dispatch(SetLoadSpinner(false))
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else{
        var today = new Date();
        var day = (''+today.getFullYear()).slice(-2) + ('00' + (today.getMonth())).slice(-2) + ('00' + today.getDate()).slice(-2)
                + ('00' + (today.getHours())).slice(-2) + ('00' + today.getMinutes()).slice(-2) + ('00' + today.getSeconds()).slice(-2)
        var fileName = 'スタッフマスタ('+day+')'
        onExportCsvFile(makeStaffCSV(result.data,Header),fileName)
      }
    }
    //インポート処理
    const onImport=(uploadData)=>{
      async function sendPost (requestBody, data) {
        var resultAttendance
        dispatch(SetLoadSpinner(true))
        console.log(data)
        resultAttendance = 
          await MultipartApi(`${DefaultUrl}/import`,'POST', requestBody, data)
        
        if (!ErrorHandling(resultAttendance, alert)) {
          alert.success('保存しました')
   
          onReSearch(null,1)
        }else{
          dispatch(SetLoadSpinner(false))
        }
        
      }

      var staffList= new Array();
      console.log(uploadData)
      uploadData.forEach((data)=>{
        let staffRow = {
          staffId: data["スタッフID"], 
          staffName: data["スタッフ名称"], 
          staffKana: data["スタッフ名称（カナ）"], 
          staffDivision: data["スタッフ区分"],
          companyId: data["会社ID"],  
          departmentId: data["部門ID"], 
          loginFlag: data["ログインフラグ"], 
          enterDt: data["入社日"], 
          retireDt: data["退職日"], 
          sex: data["性別"], 
          birthDt: data["生年月日"], 
          zipCode: data["郵便番号"], 
          address1: data["都道府県"], 
          address2: data["市区町村"], 
          address3: data["町域名"], 
          address4: data["建物名"], 
          phoneNum: data["電話番号（自宅）"], 
          mobileNum: data["電話番号（携帯）"], 
          pcMailAddress: data["E - MAIL（PC）"], 
          mobileMailAddress: data["E - MAIL（携帯）"], 
          cooperationId: data["連携用ID"], 
          delFlag: 'FALSE'
        }
        staffRow.hope ={
          hopeStartTime: data["勤務希望開始時間"], 
          hopeEndTime: data["勤務希望終了時間"], 
          overtimePropriety: data["時間外勤務"], 
          shiftMon: data["基本シフト（月）"], 
          shiftTue: data["基本シフト（火）"], 
          shiftWed: data["基本シフト（水）"], 
          shiftThu: data["基本シフト（木）"], 
          shiftFri: data["基本シフト（金）"], 
          shiftSat: data["基本シフト（土）"], 
          shiftHol: data["基本シフト（日）"], 
          shiftSun: data["基本シフト（祝）"], 
          //updatedAt: data["HOPE_UPDATED_AT"]
        }
        staffRow.grant ={
          grantNameId: data["権限名称ID"], 
          //updatedAt: data["GRANT_UPDATED_AT"]
        }
        staffList.push(staffRow)
      })
      var errorList = new Array();
      [staffList,errorList]=onCheckStaff(staffList,schema,grantList,
        comPostList,comDepartmentList,cusPostList,cusDepartmentList)
      const submitData = new window.FormData()
      if(errorList.length!=0)
      {
          alert.error(<>エラーレコードがあります。<br/>エラーレコードをCSV出力します。</>)
          var today = new Date();
          var day = (''+today.getFullYear()).slice(-2) + ('00' + (today.getMonth())).slice(-2) + ('00' + today.getDate()).slice(-2)
                  + ('00' + (today.getHours())).slice(-2) + ('00' + today.getMinutes()).slice(-2) + ('00' + today.getSeconds()).slice(-2)
          var fileName = '【Error】スタッフマスタデータ_'+day+''
          onExportCsvFile(makeStaffCSVError(errorList,Header),fileName)
      }
      
      if(staffList.length==0)
      {
          alert.error("更新するデータがありません")
          return
      }

      
      submitData.append('formData', new window.Blob([JSON.stringify(staffList)], { type: 'application/json' }))

      sendPost(submitData, staffList)
    }

    if (!grants) return null
    return (
        <MasterPanelWrap id='StaffWrap'>
        <Prompt when={true} message={handleBlockedNavigation} />
        <Search
          title= {grants.functionMenuName}
          showButton
          onClick={() => onSearch(null, 1)}
          onKeyDown={(e) => onKeyDown(e)}
          staffIdRef={staffIdRef}
          staffNameRef={staffNameRef}
          grants={grants}
          firstSearch={true}
          viewFunction={viewFunction}
          onClickFunction={()=>onClickFunction()}
          onExport={()=>onExport()}
          onImport={(uploadData)=>onImport(uploadData)}
        >
        </Search>
        <MasterFlexWrap>
            <MasterSelect/>
            <MasterListView
              dataList={dataList}
              setSelectedId={setSelectedId}
              selectFunction={(id) => selectItem(id)}
              createFunction={() => createNew()}
              canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
              pageSelectFunc={pageSelectFunc}
              pagenation={pagenation}
              isIdView
            />
            <StaffDetail
              onSearch={onReSearch}
              setSelectedId={setSelectedId}
              setDetail={setDetail}
              staffDetail={detail}
              grants={grants}
              setUpdateFlag={setUpdateFlag}
            >
            </StaffDetail>
        </MasterFlexWrap>
        </MasterPanelWrap>
    )
}

export default Staff
