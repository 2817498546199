import styled from 'styled-components'

export const ColumnIdTH = styled.th`
  width: 6rem;
  vertical-align:middle;
`

export const ColumnDateTH = styled.th`
  width: 9rem;
  vertical-align:middle;
`

export const ColumnStatusTH = styled.th`
  width: 5rem;
  vertical-align:middle;
`

export const ColumnPublicHolidayTH = styled.th`
  width: 6rem;
  vertical-align:middle;
`

export const ColumnLocationTH = styled.th`
  width: 48rem;
  vertical-align:middle;
`

export const ColumnButtonsTH = styled.th`
  width: 8rem;
  vertical-align:middle;
`

export const ColumnIdTD = styled.td`
  width: 6rem;
  vertical-align:middle;
  text-align:center;

  background-color: ${props => props.backgroundColor ? '#fff':'coral' };
`

export const ColumnDateTD = styled.td`
  width: 9rem;
  vertical-align:middle;
  text-align:center;

  background-color: ${props => props.backgroundColor ? '#fff':'coral' };
`

export const ColumnStatusTD = styled.td`
  width: 5rem;
  vertical-align:middle;
  text-align:center;

  background-color: ${props => props.backgroundColor ? '#fff':'coral' };
`

export const ColumnPublicHolidayTD = styled.td`
  width: 6rem;
  vertical-align:middle;
  text-align:center;

`

export const ColumnLocationTD = styled.td`
  width: 48rem;
  vertical-align:middle;
  text-align:center;

  background-color: ${props => props.backgroundColor ? '#fff':'coral' };
`

export const ColumnButtonsTD = styled.td`
  width: 8rem;
  vertical-align:middle;
  text-align:center;
`

export const PageButtonWrap = styled.div`
display: flex;
align: right;
margin-left:calc(50vw - 7rem);
`
export const PagingPanelWrap = styled.div`
display: flex;
align: right;
margin-left: 5rem;
margin-bottom: 0.5rem;
z-index: 8;
`
export const PagingIconPanelWrap = styled.div`
text-align:center;
line-height:32px;
justify-content: center;
font-size: 12px;
width: 22px;
height: 36px;
`
export const PagingIconImg = styled.img`
  align-items: left;
  width: 44px;
  height: 36px;
`
export const PagingIconNextImg = styled.img`
  align-items: left;
  width: 22px;
  height: 36px;
`
export const PagingChangePanelWrap = styled.div`
  text-align:center;
  line-height:32px;
  justify-content: center;
  font-size: 16px;
  width: 70px;
  height: 36px;
  color:blue;
  margin-right:calc(50vw - 14rem);
`
export const CalendarFlexWrap = styled.div`
display:flex;
`
export const CountPanelWrap = styled.div`
font-size: 20px;
display: flex;
align: right;
align-items: right;
text-align: right;
margin-right: 1rem;
margin-top: 8px;
margin-left: auto;
overflow-x: hidden;
overflow-y: hidden;
background-position: right;
justify-content: right;
flex-flow: column;
`


const CalendarTableWrap = styled.table`
  font-size: 12px;
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(116rem + 5px + 12px)
  }

  tbody {
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(80vh - 7rem + 7px);
  }

  tbody:hover {
    overflow-y: auto;
  }

  tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  tbody::-webkit-scrollbar-track {
    border:none;
  }

  tbody::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  tbody::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
    height: 2rem;
    background-color: #649cdf;
  }

  td {
    height: 3rem;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

export default CalendarTableWrap
